export const VERSION_UPDATE = "VERSION_UPDATE";
export const VERSION_TEST = "VERSION_TEST";

// Roles
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE = "GET_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const GET_ROLES_FOR_USER = "GET_ROLES_FOR_USER";
export const GET_ROLES_FOR_LOGGED_IN_USER = "GET_ROLES_FOR_LOGGED_IN_USER";
export const ASSIGN_USERS_TO_ROLE = "ASSIGN_USERS_TO_ROLE";
export const REMOVE_USERS_FROM_ROLE = "REMOVE_USERS_FROM_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// Users
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_USER_BY_EMAIL = "GET_USER_BY_EMAIL";
export const GET_LOGGED_IN_USER_BY_EMAIL = "GET_LOGGED_IN_USER_BY_EMAIL";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const ASSIGN_USER_TO_ROLES = "ASSIGN_USER_TO_ROLES";
export const REMOVE_USER_FROM_ROLES = "REMOVE_USER_FROM_ROLES";
export const GET_USERS_FOR_ROLE = "GET_USERS_FOR_ROLE";

// Content
export const GET_ALL_CONTENT = "GET_ALL_CONTENT";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_CONTENT_BY_ID = "GET_CONTENT_BY_ID";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const MASS_UPLOAD_IMAGES = "MASS_UPLOAD_IMAGES";
export const CREATE_NEW_CONTENT = "CREATE_NEW_CONTENT";
export const MASS_UPLOAD_CONTENT = "MASS_UPLOAD_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const GET_CONTENT_LOOKUP_DATA = "GET_CONTENT_LOOKUP_DATA";
export const CREATE_TARGETED_CONTENT = "CREATE_TARGETED_CONTENT";
export const GET_ALL_TARGETED_CONTENT = "GET_ALL_TARGETED_CONTENT";
export const GET_ALL_TARGETED_CONTENT_BY_TYPE =
  "GET_ALL_TARGETED_CONTENT_BY_TYPE";
export const GET_ALL_TARGETED_CONTENT_FOR_POS =
  "GET_ALL_TARGETED_CONTENT_FOR_POS";
export const GET_TARGETED_CONTENT_BY_ID = "GET_TARGETED_CONTENT_BY_ID";
export const RESET_CURRENT_TARGETED_CONTENT = "RESET_CURRENT_TARGETED_CONTENT";
export const UPDATE_TARGETED_CONTENT = "UPDATE_TARGETED_CONTENT";
export const GET_3D_POS_ITEM_BY_ID = "GET_3D_POS_ITEM_BY_ID";
export const SET_CURRENT_POS_ITEM = "SET_CURRENT_POS_ITEM";
export const GET_SIZE_METADATA = "GET_SIZE_METADATA";

// Resource Images
export const CLEAR_RESOURCE_IMAGES = "CLEAR_RESOURCE_IMAGES";
export const GET_RESOURCE_IMAGES = "GET_RESOURCE_IMAGES";
export const GET_RESOURCE_IMAGE_URLS = "GET_RESOURCE_IMAGE_URLS";
export const SET_CURRENT_RESOURCE_IMAGE = "SET_CURRENT_RESOURCE_IMAGE";

//Targeting
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
export const GET_CAMPAIGNS_BY_TARGET_GROUP_ID =
  "GET_CAMPAIGNS_BY_TARGET_GROUP_ID";
export const GET_CURRENT_CAMPAIGN = "GET_CURRENT_CAMPAIGN";
export const RESET_CURRENT_CAMPAIGN = "RESET_CURRENT_CAMPAIGN";
export const UPDATE_CURRENT_CAMPAIGN = "UPDATE_CURRENT_CAMPAIGN";

export const GET_TARGET_GROUPS = "GET_TARGET_GROUPS";
export const GET_TARGET_GROUP = "GET_TARGET_GROUP";
export const GET_TARGET_RULES = "GET_TARGET_RULES";
export const GET_TARGET_GROUPS_BY_IDS = "GET_TARGET_GROUPS_BY_IDS";
export const CREATE_TARGET_GROUP = "CREATE_TARGET_GROUP";
export const UPDATE_TARGET_GROUP = "UPDATE_TARGET_GROUP";
export const UPDATE_CURRENT_TARGET_GROUP = "UPDATE_CURRENT_TARGET_GROUP";
export const RESET_CURRENT_TARGET_GROUP = "RESET_CURRENT_TARGET_GROUP";
export const DELETE_TARGET_GROUP = "DELETE_TARGET_GROUP";
export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const GET_APPLICATION_BY_ID = "GET_APPLICATION_BY_ID";

//Activities
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const CREATE_ACTIVITY_GROUP = "CREATE_ACTIVITY_GROUP";
export const CREATE_CHOICE = "CREATE_CHOICE";
export const UPDATE_CHOICE = "UPDATE_CHOICE";
export const CREATE_REWARD = "CREATE_REWARD";
export const CREATE_TARGET = "CREATE_TARGET";
export const GET_ALL_ACTIVITY_GROUPS = "GET_ALL_ACTIVITY_GROUPS";
export const GET_ACTIVITY_BY_ID = "GET_ACTIVITY_BY_ID";
export const GET_ACTIVITY_GROUP_BY_ID = "GET_ACTIVITY_GROUP_BY_ID";
export const GET_CHOICE_BY_ID = "GET_CHOICE_BY_ID";
export const GET_REWARD_BY_ID = "GET_REWARD_BY_ID";
export const GET_TARGET_BY_ID = "GET_TARGET_BY_ID";
export const GET_DROPDOWN_LOOKUPS = "GET_DROPDOWN_LOOKUPS";
export const PPP_MASS_UPLOAD_SUCCESS = "PPP_MASS_UPLOAD_SUCCESS";
export const PPP_MASS_UPLOAD_FAILURE = "PPP_MASS_UPLOAD_FAILURE";
export const GET_PPP_AS_CSV = "GET_PPP_AS_CSV";
export const REPLACE_PPP_TARGETS = "REPLACE_PPP_TARGETS";
export const REPLACE_ACTIVITY_TARGETS = "REPLACE_ACTIVITY_TARGETS";
export const GET_ACTIVITYTARGETS_AS_CSV = "GET_ACTIVITYTARGETS_AS_CSV";

// MYCCA Resource/Content Actions.
export const GET_ALL_MYCCA_CONTENT = "GET_ALL_MYCCA_CONTENT";
export const GET_MYCCA_CONTENT_BY_ID = "GET_MYCCA_CONTENT_BY_ID";
export const GET_MYCCA_LOOKUPS = "GET_MYCCA_LOOKUPS";
export const UPLOAD_MYCCA_CONTENT = "UPLOAD_MYCCA_CONTENT";
export const GET_MYCCA_RESOURCE = "GET_MYCCA_RESOURCE";
export const UPDATE_MYCCA_CONTENT = "UPDATE_MYCCA_CONTENT";
export const GET_ALL_TYPES = "GET_ALL_TYPES";
export const GET_TYPE_BY_ID = "GET_TYPE_BY_ID";

//SFE Activation
export const GET_ALL_SFE_ACTIVATIONS = "GET_ALL_SFE_ACTIVATIONS";
export const GET_SFE_ACTIVATION_CALCULATION_BY_ID =
  "GET_SFE_ACTIVATION_CALCULATION_BY_ID";
export const CREATE_SFE_ACTIVATION_CALCULATION =
  "CREATE_SFE_ACTIVATION_CALCULATION";

// Core Range
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_CORERANGE_LIST = "GET_CORERANGE_LIST"
export const GET_TARGETING_DATA = "GET_TARGETING_DATA";
export const GET_TARGET_GROUPS_ALLDATA = "GET_TARGET_GROUPS_ALLDATA";
export const UPLOAD_CUSTOMER_DOORS = "UPLOAD_CUSTOMER_DOORS";
export const DOWNLOAD_CUSTOMER_DOORS = "DOWNLOAD_CUSTOMER_DOORS";
export const UPSERT_CORERANGE = "UPSERT_CORERANGE";
export const VIEW_CORERANGE = "VIEW_CORERANGE";
export const GET_CUSTOMER_ATTRIBUTES = "GET_CUSTOMER_ATTRIBUTES";

// My Displays
export const GET_DISPLAY_GROUPS = "GET_DISPLAY_GROUPS";
export const GET_DISPLAY_GROUP = "GET_DISPLAY_GROUP";
export const UPSERT_DISPLAY_GROUP = "UPSERT_DISPLAY_GROUP";
export const GET_DISPLAY_ACTIVITY_BY_ID = "GET_DISPLAY_ACTIVITY_BY_ID";
export const GET_DISPLAY_ACTIVITIES_BY_ID = "GET_DISPLAY_ACTIVITIES_BY_ID";
export const CREATE_DISPLAY_ACTIVITY = "CREATE_DISPLAY_ACTIVITY";
export const DOWNLOAD_MYDISPLAYS_LIST = "DOWNLOAD_MYDISPLAYS_LIST";
export const GET_ACTIVITY_GROUP_ID_FOR_SITE = "GET_ACTIVITY_GROUP_ID_FOR_SITE";
export const ADD_TARGET_TO_DISPLAY_GROUP = "ADD_TARGET_TO_DISPLAY_GROUP";
export const RESET_DISPLAY_TARGET = "RESET_DISPLAY_TARGET";

export const UPSERT_DISPLAY_PLAN = "UPSERT_DISPLAY_PLAN";
export const UPSERT_DISPLAY_PLAN_SKU = "UPSERT_DISPLAY_PLAN_SKU";
export const REMOVE_DISPLAY_PLAN_SKU = "REMOVE_DISPLAY_PLAN_SKU";

export const GET_PLANNED_DISPLAYS = "GET_PLANNED_DISPLAYS";
export const GET_PLANNED_DISPLAY_DETAILS = "GET_PLANNED_DISPLAY_DETAILS";
export const GET_PRODUCT_FILTER_RULES = "GET_PRODUCT_FILTER_RULES";
export const UPSERT_PRODUCT_FILTERS = "UPSERT_PRODUCT_FILTERS";
export const RESET_DISPLAY_GROUP_ACTIVITES = "RESET_DISPLAY_GROUP_ACTIVITES";
export const  DOWNLOAD_MD_STOCKWEIGHT_TARGETS = "DOWNLOAD_MD_STOCKWEIGHT_TARGETS"
export const  UPLOAD_MD_STOCKWEIGHT_TARGETS = "UPLOAD_MD_STOCKWEIGHT_TARGETS"

// SEC
export const UPLOAD_SEC_CUSTOMERS = "UPLOAD_SEC_CUSTOMERS";
export const DOWNLOAD_SEC_CUSTOMERS = "DOWNLOAD_SEC_CUSTOMERS";
export const DOWNLOAD_SEC_POINTS = "DOWNLOAD_SEC_POINTS";
export const UPLOAD_SEC_POINTS = "UPLOAD_SEC_POINTS";
export const DOWNLOAD_SEC_ORDER_PERIODS = "DOWNLOAD_SEC_ORDER_PERIODS";
export const UPLOAD_SEC_ORDER_PERIODS = "UPLOAD_SEC_ORDER_PERIODS";
export const GET_ALL_SEC_ORDER_PERIODS = "GET_ALL_SEC_ORDER_PERIODS";

export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const UPDATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const GET_CONFIGURATION_BY_ID = "GET_CONFIGURATION_BY_ID";
export const GET_ALL_CONFIGURATIONS = "GET_ALL_CONFIGURATIONS";
export const CREATE_NEW_CONFIGURATION = "CREATE_NEW_CONFIGURATION";