export const SFEActivationModel = {
  activity: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "activity",
    label: "Activity"
  },
  activityGroup: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "activityGroup",
    label: "Activity Group"
  },
  calculationType: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "calculationType",
    label: "Calculation Type"
  },
  redChannel: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "redChannel",
    label: "Red Channel"
  },
  active: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "active",
    label: "Active"
  }
};
