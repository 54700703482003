import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { format } from "date-fns";

import "../../styles/style.css";

import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import { getActivitiesById } from "../../../../state/actions/MyDisplaysActions";
import { LocationActivityRow } from "./LocationActivityRow";
import { ProductActivityRow } from "./ProductActivityRow";
import { ComplianceActivityRow } from "./ComplianceActivityRow";

export const ActivityTable = ({
  currentActivity,
  displayGroup,
  displayIndex,
  displayGroupId,
  isNew,
  isEditing,
  displayActivities,
}) => {
  const history = useHistory();

  const [selectedType, setSelectedType] = useState("");
  const [typesList, setTypesList] = useState([]);

  const activeRewards = currentActivity?.Rewards?.filter(
    (item) => item.IsActive === true
  );

  useEffect(() => {
    if (displayGroup.Displays[displayIndex].Activities.length >= 2) {
      setTypesList(["Compliance"]);
    } else if (
      displayGroup.Displays[displayIndex].DisplayTypeActivityId ===
      displayGroup.Displays[displayIndex].Activities[0].MyDisplaysActivityId
    ) {
      setTypesList(["Location"]);
    } else if (displayGroup.Displays[displayIndex].Activities.length === 1) {
      setTypesList(["Product"]);
    }
  }, []);

  const handleChange = (e) => {
    setSelectedType(e.target.value);
    if (e.target.value === "Display") {
      history.push(
        `/my-displays-maintenance/${displayGroupId}/${e.target.value.toLowerCase()}/new`
      );
    } else {
      history.push(
        `/my-displays-maintenance/${displayGroupId}/${displayIndex}/new`
      );
    }
  };

  return (
    <div
      style={{
        marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      <div className="create-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Activity Types</h2>
          <FormControl style={{ width: "35%" }}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ paddingLeft: "1rem" }}
            >
              New Activity Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              label="Actvity Type"
              onChange={handleChange}
            >
              {typesList.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {displayIndex && (
          <Fragment>
            <Grid container spacing={6} style={{ marginTop: "1.5rem" }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  <strong>Activity ID</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">
                  <strong>Name</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">
                  <strong>Start Date</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">
                  <strong>End Date</strong>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  <strong>Active</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">
                  <strong>Data Type</strong>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  <strong>Max RED Points</strong>
                </Typography>
              </Grid>
            </Grid>

            <Divider />

            {/* Display */}
            <Grid
              container
              spacing={6}
              style={{
                marginTop: "1px",
                marginBottom: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item xs={1}>
                <Typography variant="body1">
                  <strong>Display</strong>
                </Typography>
              </Grid>
              {displayIndex && (
                <>
                  <Grid item xs={1}>
                    <Typography
                      variant="body2"
                      className="activity-link"
                      onClick={() =>
                        history.push(
                          `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${displayGroup.Displays[displayIndex].DisplayTypeActivity.Id}`
                        )
                      }
                    >
                      {
                        displayGroup.Displays[displayIndex]
                          .DisplayTypeActivityId
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {
                        displayGroup.Displays[displayIndex].DisplayTypeActivity
                          .Name
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {" "}
                      {format(
                        new Date(
                          displayGroup.Displays[
                            displayIndex
                          ].DisplayTypeActivity.StartDate
                        ),
                        "dd/MM/yyyy"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {" "}
                      {format(
                        new Date(
                          displayGroup.Displays[
                            displayIndex
                          ].DisplayTypeActivity.EndDate
                        ),
                        "dd/MM/yyyy"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <span
                      className={`${
                        displayGroup.Displays[displayIndex].DisplayTypeActivity
                          .IsActive
                          ? "active-tag-text"
                          : "inactive-tag-text"
                      }`}
                    >
                      {displayGroup.Displays[displayIndex].DisplayTypeActivity
                        .IsActive
                        ? "Active"
                        : "Inactive"}
                    </span>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {currentActivity?.ActivityDataType?.Value}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography
                      variant="body2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {currentActivity !== undefined &&
                      activeRewards.length === 0
                        ? 0
                        : Math.max(
                            ...activeRewards.map((reward) => reward.Value)
                          )}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider />

            {/* Location */}
            {currentActivity.Activity.Id ===
            displayActivities[0].Activity.Id ? null : (
              <LocationActivityRow
                displayActivities={displayActivities}
                displayIndex={displayIndex}
                displayGroupId={displayGroupId}
              />
            )}

            <Divider />

            {/* Product */}
            {displayActivities.length > 1 && (
              <ProductActivityRow
                displayActivities={displayActivities}
                displayIndex={displayIndex}
                displayGroupId={displayGroupId}
              />
            )}

            <Divider />

            {/* Compliance */}
            {displayActivities.slice(2).map((activity) => (
              <>
                <ComplianceActivityRow
                  key={activity.Activity.Id}
                  activity={activity}
                  displayIndex={displayIndex}
                  displayGroupId={displayGroupId}
                />
                <Divider />
              </>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};
