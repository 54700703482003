export const MyCCATypeModel = {
  name: {
    type: "string",
    required: true,
    errorMessage: "",
    label: "Name"
  },
  multipart: {
    type: "bool",
    required: true,
    errorMessage: "",
    label: "Enforce 3 Image Upload?"
  },

  sourceWidth: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Source Image Width"
  },
  sourceHeight: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Source Image Height"
  },
  desktopWidth: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Desktop Image Width"
  },  
  desktopHeight: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Desktop Image Height"
  },  
  tabletWidth: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Tablet Image Width"
  },  
  tabletHeight: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Tablet Image Height"
  },  
  mobileWidth: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Mobile Image Width"
  },  
  mobileHeight: {
    type: "int",
    required: true,
    errorMessage: "",
    label: "Mobile Image Height"
  }
};
