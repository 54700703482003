import { AnyAction } from "redux";
import {
    CREATE_CONFIGURATION,
    UPDATE_CONFIGURATION,
    GET_CONFIGURATION_BY_ID,
    GET_ALL_CONFIGURATIONS,
    CREATE_NEW_CONFIGURATION
  } from "../constants/action-types";
  
  export interface configurationReducerState {
    currentConfiguration: any
    configurations: any
  }
  
  // INITIALIZE STATE
  const initialState = {
    currentConfiguration: {},
    configurations: []
  };

  const createConfigurationFromResponse = (response: any, site: string) => {
    let configuration: any = {};
    if (Object.keys(response).length > 0) {
      configuration.site = response.data.Site;
      configuration.application = response.data.Application;
      configuration.mainkey = response.data.Mainkey;
      configuration.subkey = response.data.Subkey;
      configuration.code = response.data.Code;
      configuration.value = response.data.Value;
      configuration.valueDescription = response.data.ValueDescription;

    } else {
        configuration = {
        site: site,
        application: null,
        mainkey: "",
        subkey: "",
        code: "",
        value: "",
        valueDescription: "",
      };
    }
  
    return configuration;
  };

  const createConfigurationFromSingleResponse = (response: any, site: string) => {
    let configuration: any = {};
    
    if (Object.keys(response).length > 0) {
      configuration.site = response.site;
      configuration.application = response.application;
      configuration.mainkey = response.mainkey;
      configuration.subkey = response.subkey;
      configuration.code = response.code;
      configuration.value = response.value;
      configuration.valueDescription = response.valueDescription;

    } else {
        configuration = {
        site: site,
        application: null,
        mainkey: "",
        subkey: "",
        code: "",
        value: "",
        valueDescription: "",
      };
    }
  
    return configuration;
  };

// REDUCER
export const ConfigurationReducer = (state = initialState, action: AnyAction): configurationReducerState => {
    switch (action.type) {
      case CREATE_NEW_CONFIGURATION: {
        const currentConfiguration = {
          site: action.payload.site,
          application: null,
          mainkey: "",
          subkey: "",
          code: "",
          value: "",
          valueDescription: "",
        };
  
        return {
          ...state,
          currentConfiguration,
        };
      }
      case CREATE_CONFIGURATION: {
        const currentConfiguration = createConfigurationFromResponse(action.payload.data, action.payload.site);
  
        return {
          ...state,
          currentConfiguration,
        };
      }
      case UPDATE_CONFIGURATION: {
        return {
          ...state,
          currentConfiguration: action.payload,
        };
      }
  
      case GET_ALL_CONFIGURATIONS: {
        const configurations = action.payload.data;
  
        return {
          ...state,
          configurations,
        };
      }
  
      case GET_CONFIGURATION_BY_ID: {
        
        let config = action.payload.data.find( (configuration: any) => {
            return configuration.id === action.payload.configurationId
        })
        
      const currentConfiguration = createConfigurationFromSingleResponse(config, action.payload.site);
  
        return {
          ...state,
          currentConfiguration,
        };
      }
  
      default:
        return state;
    }
  };