// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";

// UI and Styling
import {
  Button,
  Box,
  ButtonGroup,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "../../components/dialog/Modal";
import styles from "./NewTargetGroupModal.module.scss";

//Actions
import {
  createTargetGroup,
  getTargetGroups,
  getTargetRules,
} from "../../state/actions/TargetGroupActions";
import { getDropdownLookups } from "../../state/actions/ActivityActions";

//Components
import CustomCard from "../../components/custom-card/CustomCard";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import { ListableRulesCard } from "../../components/listable-rules-card/ListableRulesCard";

//Other
import copy from "copy-to-clipboard";
import { TargetFields } from "../../state/constants/TargetFields";
import { UUID } from "../../helpers/uuid";
import { Territories } from "../../state/constants/Territories";

export const NewProductGroupModal = ({
  defaultModel,
  addProductGroupOpen,
  setAddProductGroupOpen,
}) => {
  const dispatch = useDispatch();
  //State
  const [targetGroup, setTargetGroup] = useState({});
  const [targetGroupRules, setTargetGroupRules] = useState({});
  const [modelTypeData, setModelTypeData] = useState(TargetFields);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [lookupsCallMade, setLookupsCallMade] = useState(false);

  //Redux
  const targetGroupList = useSelector(
    (state) => state.TargetGroupReducer.targetGroups
  );

  const currentTargetGroup = useSelector(
    (state) => state.TargetGroupReducer.currentTargetGroup
  );
  const currentTargetGroupRules = useSelector(
    (state) => state.TargetGroupReducer.targetGroupRules
  );
  const campaignsRelatedToTargetGroup = useSelector(
    (state) => state.CampaignReducer.campaignsRelatedToTargetGroup
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );
  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);

  //UseEffect
  useEffect(() => {
    dispatch(
      getDropdownLookups(loggedInUser.territory === "nz" ? 2 : 1, () => {
        setLookupsCallMade(true);
      })
    );
    dispatch(getTargetRules());
  }, []);

  useEffect(() => {
    setTargetGroupRules(currentTargetGroupRules);
  }, [currentTargetGroupRules]);

  useEffect(() => {
    const localTargetGroup = { ...targetGroup };
    localTargetGroup.Counter = targetGroupList.length + 1;
    localTargetGroup.ModelType = defaultModel;
    localTargetGroup.Rules = [];
    localTargetGroup.Site = loggedInUser.territory || "au";
    setTargetGroup(localTargetGroup);
  }, [addProductGroupOpen]);

  //Functions
  const copyToClipboard = (e) => {
    copy(targetGroup.TargetGroupId);
  };

  const handleDeleteRule = (data) => {
    var localTargetGroup = Object.assign({}, targetGroup);

    const dataReversed = data.reverse();

    dataReversed.forEach((index) => {
      localTargetGroup.Rules.splice(index, 1);
    });

    setTargetGroup(localTargetGroup);
  };

  const handleAddRule = () => {
    if (!targetGroup.ModelType) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please select a Model Type before adding Rules");
    } else {
      let localTargetGroup = Object.assign({}, targetGroup);
      localTargetGroup.Rules.push({
        Field: undefined,
        FilterType: undefined,
      });

      setTargetGroup(localTargetGroup);
    }
  };

  const setupRulesData = () => {
    if (!targetGroup || !targetGroup.Rules || !targetGroupRules) return [];

    const modelType = targetGroup.ModelType.toUpperCase();

    let targetGroupRulesKeys = Object.keys(targetGroupRules);

    let targetRules = [];

    let mtype = modelType;
    if (modelType == "CORERANGE") mtype = "MATERIAL";

    for (var index in targetGroupRulesKeys) {
      if (targetGroupRulesKeys[index].toUpperCase().includes(mtype)) {
        targetRules = targetGroupRules[targetGroupRulesKeys[index]];
      }
    }

    //Display each rule attribute and value in its own row on the form
    return targetGroup.Rules.map((rule, index) => {
      //debugger;
      return {
        id: index,
        fieldNameValue: rule.RuleName,
        handleFieldNameChange: (item) => {
          let localTargetGroup = Object.assign({}, targetGroup);
          if (item && item.key) {
            const value = item.key;
            localTargetGroup.Rules[index].Field = value;
            localTargetGroup.Rules[index].FilterType =
              TargetFields[modelType.replace(" ", "")].RULE[value].FilterType;
            localTargetGroup.Rules[index].RuleName = value;
            localTargetGroup.Rules[index].RuleValues = [];
          } else {
            localTargetGroup.Rules[index].Field = undefined;
            localTargetGroup.Rules[index].FilterType = undefined;
            localTargetGroup.Rules[index].RuleName = undefined;
          }
          setTargetGroup(localTargetGroup);
        },
        possibleRuleValues: targetRules.filter(
          (tr) => tr.RuleName === rule.RuleName
        ),
        selectedRuleValues: rule.RuleValues,
        handleAddValue: (values) => {
          let localTargetGroup = { ...targetGroup };
          const ruleValues = values.map((value) => {
            if (value !== undefined && typeof value === "string") {
              return value;
            }

            return value.Id;
          });
          localTargetGroup.Rules[index].RuleValues = ruleValues;

          setTargetGroup(localTargetGroup);
        },
      };
    });
  };

  const submitTargetGroup = () => {
    //Ensure the data being submitted is valid before submitting
    const rules = [];

    if (targetGroup.Rules != null && targetGroup.Rules.length > 0) {
      targetGroup.Rules.forEach((rule) => {
        if (!rule.RuleName || !rule.RuleValues.length) return;
        rules.push({
          Name: rule.RuleName,
          Operator: rule.RuleValues.length > 1 ? "||" : "eq",
          Values: rule.RuleValues,
        });
      });
    }

    // Need to add a rule for site as it is required by the backend
    rules.push({
      Name: `${targetGroup.ModelType[0].toUpperCase()}${targetGroup.ModelType.slice(
        1
      )}_Site`,
      Operator: "eq",
      Values: [targetGroup.Site],
    });

    // Create the target group then redirect user to the target groups list page
    dispatch(
      createTargetGroup(targetGroup, rules, loggedInUser.userId, (success) => {
        if (success) {
          console.info("☑️ Target Group created");
          dispatch(getTargetGroups());
          setAddProductGroupOpen(false);
        }
      })
    );

    setTargetGroup({});

    // Get the updated Target Groups list
    dispatch(getTargetGroups());
  };

  const modelType = defaultModel;

  return (
    <Modal
      title={"Add new Product Group"}
      open={addProductGroupOpen}
      fullWidth={true}
      fixedHeight={true}
      actions={
        <Box>
          <Button
            color="secondary"
            onClick={() => {
              //Create new Target Group
              submitTargetGroup();
              setAddProductGroupOpen(false);
            }}
          >
            Submit
          </Button>
          <Button
            color="secondary"
            onClick={() => setAddProductGroupOpen(false)}
          >
            Cancel
          </Button>
        </Box>
      }
    >
      <div className={styles.contentGrid}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomCard title="Main Details" actionButton={[]}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "none",
                    // transition:
                    padding: 16,
                  }}
                >
                  <Grid container>
                    <Grid item xs={9}>
                      <TextField
                        classes={{ root: styles.textField }}
                        label={"Target Group Id"}
                        disabled={true}
                        value=""
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        size="medium"
                        style={{ marginLeft: 20, marginTop: 10 }}
                        onClick={() => copyToClipboard()}
                      >
                        Copy To Clipboard
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: styles.textField }}
                    label={"Target Group Short Id"}
                    disabled={true}
                    value={targetGroup.Counter ? targetGroup.Counter : ""}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: styles.textField }}
                    label={"Target Group Name"}
                    placeholder="Enter target group name"
                    value={targetGroup.Name}
                    onChange={(event) => {
                      var localTargetGroup = Object.assign({}, targetGroup);
                      localTargetGroup.Name = event.target.value;

                      setTargetGroup(localTargetGroup);
                    }}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: styles.textField }}
                    label={"Description"}
                    placeholder="Enter description"
                    required
                    multiline={true}
                    rows={4}
                    value={targetGroup.Description}
                    onChange={(event) => {
                      var localTargetGroup = Object.assign({}, targetGroup);
                      localTargetGroup.Description = event.target.value;

                      setTargetGroup(localTargetGroup);
                    }}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    disabled
                    fullWidth={true}
                    label="Model Type"
                    handleChange={(value) => {
                      var localTargetGroup = Object.assign({}, targetGroup);
                      localTargetGroup.ModelType = value;
                      localTargetGroup.TokenField = "";
                      localTargetGroup.FilterRules = [];
                      localTargetGroup.Rules = [];

                      setTargetGroup(localTargetGroup);
                    }}
                    data={Object.values(modelTypeData).map(
                      (modelType) => modelType.NAME
                    )}
                    value={
                      modelType.charAt(0).toUpperCase() + modelType.slice(1)
                    }
                    valueName={"ModelType"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    fullWidth={true}
                    label="Site"
                    handleChange={(value) => {
                      var localTargetGroup = Object.assign({}, targetGroup);
                      localTargetGroup.Site = value;

                      setTargetGroup(localTargetGroup);
                    }}
                    data={Object.keys(Territories).map((key) => {
                      return { key: key, value: Territories[key] };
                    })}
                    value={targetGroup.Site}
                    valueName={"territory"}
                  ></DropdownSelect>
                </Grid>
              </Grid>
            </CustomCard>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <ListableRulesCard
                actionButton={[
                  <Button
                    key={UUID()}
                    onClick={() => handleAddRule()}
                    variant="outlined"
                    color="secondary"
                  >
                    Add Rule
                  </Button>,
                ]}
                addLabel={"Add Rule"}
                isLoading={false}
                title={"Rules"}
                emptyPlaceholder={"No Rules added"}
                handleDelete={(data) => {
                  handleDeleteRule(data);
                }}
                data={setupRulesData() === null ? [] : setupRulesData()}
                modelType={modelType.toUpperCase()}
                subheading={
                  "Add rules to select a target segmentation. All Rules are AND rules"
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
