export const ActivityModel = {
  name: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "name",
    label: "Name"
  },
  activityTypeId: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "activityTypeId",
    label: "Activity Type"
  },
  startDate: {
    type: "date",
    required: true,
    errorMessage: "",
    value: "startDate",
    label: "Effective From"
  },
  endDate: {
    type: "date",
    required: true,
    errorMessage: "",
    value: "endDate",
    label: "Effective To"
  },
  activityFrequencyId: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "activityFrequencyId",
    label: "Frequency"
  },
  activityDataTypeId: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "activityDataTypeId",
    label: "Data Type"
  },
  min: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "min",
    label: "Min"
  },
  max: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "max",
    label: "Max"
  },
  sequence: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "sequence",
    label: "Sequence"
  },
  required: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "required",
    label: "Required"
  },
  oneTimeAnswer: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "oneTimeAnswer",
    label: "One Time Answer"
  },
  active: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "active",
    label: "Active"
  }
};
