import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
  CREATE_TARGET_GROUP,
  GET_TARGET_GROUPS,
  GET_TARGET_GROUP,
  GET_TARGET_RULES,
  RESET_CURRENT_TARGET_GROUP,
  UPDATE_TARGET_GROUP,
} from "../constants/action-types";
import fileDownload from "js-file-download";

const API_CONFIG = getApiConfig();

/* 
    Get Target Groups
*/
export const getTargetGroups = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/GetAllTargetGroups`,
        {
          method: "get",
        }
      );

      dispatch({
        type: GET_TARGET_GROUPS,
        payload: {
          data: response.data,
        },
      });
      console.info(
        `🎯 Target Groups updated. Total count: ${response.data.length}`
      );
      callback && callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback && callback(false);
    }
  };
};

/*
    Get an Individual Target Group by its Id
*/
export const getTargetGroup = (targetGroupId, callback) => {
  return async (dispatch) => {
    if (!targetGroupId) {
      dispatch({
        type: GET_TARGET_GROUP,
        payload: {},
      });
      callback(true);
      return;
    }

    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/GetTargetGroupById?TargetGroupId=${targetGroupId}`,
        {
          method: "get",
        }
      );
      dispatch({
        type: GET_TARGET_GROUP,
        payload: response,
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const getTargetRules = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/GetFilters?FilterType=all`,
        {
          method: "get",
        }
      );

      dispatch({
        type: GET_TARGET_RULES,
        payload: response,
      });

      callback && callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback && callback(false);
    }
  };
};
/* 
    Create a New Target Group
*/
export const createTargetGroup = (targetGroup, rules, createdBy, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      await adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/CreateTargetGroup?Name=${
          targetGroup.Name
        }&Description=${
          targetGroup.Description
        }&CreatedBy=${createdBy}&ModelType=${targetGroup.ModelType.toLowerCase()}`,
        {
          method: "post",
          headers: headers,
          data: rules,
        }
      );

      dispatch({
        type: CREATE_TARGET_GROUP,
        payload: targetGroup,
      });

      callback && callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Update an individual target group
*/
export const updateTargetGroup = (
  targetGroup,
  rules,
  targetGroupId,
  callback
) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/UpdateTargetGroupById?Name=${targetGroup.Name}&Description=${targetGroup.Description}&TargetGroupId=${targetGroupId}`,
        {
          method: "patch",
          headers: headers,
          data: rules,
        }
      );
      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
  Preview Sync based on a route and a target group
*/
export const previewSync = (routeId, targetGroupId, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      const response = await adalApiFetch(
        Axios,
        `${
          API_CONFIG.SYNC
        }/PreviewFetchData?targetGroupId=${targetGroupId}&route=${encodeURIComponent(
          routeId
        )}`,
        {
          method: "get",
          headers: headers,
          responseType: "blob",
        }
      );

      fileDownload(response.data, "sync-preview.csv");

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
  Reset local state for current Target Group
*/
export const resetLocalTargetGroup = (callback) => (dispatch) => {
  dispatch({
    type: RESET_CURRENT_TARGET_GROUP,
    payload: null,
  });

  callback(true);
};

/*
    Delete an Existing Target group
*/
export const deleteTargetGroup = (targetGroupId, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/DeleteTargetGroupById?TargetGroupId=${targetGroupId}`,
        {
          method: "delete",
        }
      );

      callback(response.data);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(error.response.data);
    }
  };
};
