// React
import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import styles from "./ListTargetContentPage.module.scss";

// Actions
import { getAllTargetedContent } from "../../../state/actions/TargetedContentActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import { OutlinedIconButton } from "../../../components/buttons/OutlinedIconButton";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import ScrollableTabs from "../../../components/tabs/Tabs";
import Search from "../../../components/search/Search";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";
import {
  TargetedContentType,
  targetedContentTypeToIndex
} from "../../../state/constants/TargetedContentType";
import { Territories } from "../../../state/constants/Territories";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  }
}));

const createData = (content) => {
  return content.map((tc) => {
    return {
      id: tc.ContentId,
      name: tc.ContentTitle,
      description: tc.ContentDescription,
      contentType: tc.ContentType,
      category: tc.Category,
      site: tc.Territory.toLowerCase() === "au" ? "Australia" : "New Zealand"
    };
  });
};

// Component entry point
const ListTargetContentPage = (props) => {
  const searchQuery = new URLSearchParams(props.location.search).get("search");

  const classes = useStyles();

  const { targetedContent } = useSelector(
    (state) => state.TargetedContentReducer
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentContentType, setCurrentContentType] = useState(TargetedContentType.FILE);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  const originalData = useMemo(()=> {
    const filteredContent = targetedContent.filter((tc) =>
      currentContentType === "Link"
      ? tc.ContentType === "Links" &&
      tc.ContentType !== "SaMC" &&
      tc.ContentType !== "New Leads"
      : tc.ContentType !== "Links" &&
      tc.ContentType !== "SaMC" &&
      tc.ContentType !== "New Leads"
      );
    return createData(filteredContent);
  }, [targetedContent, currentContentType])

  useEffect(() => {
    setLoading(true);

    dispatch(
      getAllTargetedContent(() => {
        setLoading(false);
      })
      );
      setSearchText(searchQuery);
  }, []);


  const dataToRender = useMemo(() => {
    let text = searchText ? searchText.toLowerCase() : '';
    if(!originalData || originalData.length === 0) return [];

    if(!text || text === "") {
      return originalData;
    }

    const territories = [];
    Object.keys(Territories).forEach((key, index) => {
      if (filters[key]) {
        territories.push(Territories[key]);
      }
    });

    // Search & filter data
    const foundData = originalData.filter((content) => {
      debugger
      return (
        (content.name.toLowerCase().includes(text) ||
          content.description.toLowerCase().includes(text) ||
          content.contentType.toLowerCase().includes(text) ||
          content.site.toLowerCase().includes(text) ||
          content.category.toLowerCase().includes(text)) &&
        (territories.length === 0 || territories.includes(content.site))
      );
    });

    return foundData;
  }, [searchText, originalData, filters]);

  const createHeaders = () => {
    return [
      {
        id: "name",
        clickable: true,
        action: {
          path: "/content-upload/{id}",
          identifier: "id"
        },
        align: "left",
        label: "Content Name"
      },
      {
        id: "description",
        align: "left",
        label: "Description"
      },
      {
        id: "contentType",
        align: "left",
        label: "Content Type"
      },
      {
        id: "category",
        align: "left",
        label: "Category"
      },
      {
        id: "site",
        align: "left",
        label: "Site"
      }
    ];
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const onTabChange = (value) => {
    switch (value) {
      case targetedContentTypeToIndex(TargetedContentType.FILE):
        setCurrentContentType(TargetedContentType.FILE);
        break;
      case targetedContentTypeToIndex(TargetedContentType.LINK):
        setCurrentContentType(TargetedContentType.LINK);
        break;
      default:
        setCurrentContentType(TargetedContentType.FILE);
    }
  };

  const handleChange = (event) => {
    let localFilters = {...filters};
    if (filters[event.target.name]) {
      localFilters[event.target.name] = !localFilters[event.target.name];
    } else {
      localFilters[event.target.name] = true;
    }
    setFilters(localFilters);
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data = (
      <Fragment>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="flex-end"
          style={{
            display: showFilters ? "block" : "none",
            // transition:
            padding: 16
          }}
          >
            <Grid item xs={12} style={{whiteSpace:'nowrap', display:'flex'}}  justifyContent='flex-end' alignItems='center'>
              <Typography align="right">Filter by territory: </Typography>
              <FormGroup row>
                {Object.keys(Territories).map((key, index) => {
                  return (
                    <FormControlLabel
                      labelPlacement="start"
                      key={index}
                      label={Territories[key]}
                      control={
                        <Checkbox
                        checked={filters[key] || false}
                        onChange={handleChange}
                        name={key}
                        color="primary"
                        />
                      }
                    />
                  );
                })}
              </FormGroup>
            </Grid>
          </Grid>

          { dataToRender.length === 0 ? 
            <PagePlaceholder
              image={getIcon("frame-expand.svg")}
              text="No Content found."
            /> :
            <SimpleTable
                handleSelection={null}
                isSelectable={false}
                dataId={"targetedContentId"}
                cells={cells}
                headers={headers}
                rows={dataToRender}
              />
          }
      </Fragment>
    );

    return (
      <ScrollableTabs
        handleTabChange={(newValue) => onTabChange(newValue)}
        value={targetedContentTypeToIndex(currentContentType)}
        tabs={[
          {
            name: "Files",
            key: "files",
            content: loading ? <Loading /> : data
          },
          {
            name: "Links",
            key: "links",
            content: loading ? <Loading /> : data
          }
        ]}
      />
    );
  };

  return (
    <div>
      <HeaderBlock
        title={"Content"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                className={styles.roleField}
                id="outlined-name"
                label="Search Content"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <OutlinedIconButton
                clickHandler={() => setShowFilters(!showFilters)}
                icon="funnel.svg"
              />
            </Grid>
            <Grid item>
              <ExportExcel
                csvData={dataToRender}
                fileName="content"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("content-upload/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Add New
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ListTargetContentPage);

// EXPORT COMPONENT
export { hoc as ListTargetContentPage };
