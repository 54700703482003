// React
import React, { Fragment, useState, useEffect, useRef, ChangeEvent } from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
// UI and Styling
import { Autocomplete } from "@material-ui/lab";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CloseIcon from '@material-ui/icons/Close';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ErrorIcon from "@material-ui/icons/Error";

import AlertDialog from "../../components/alert-dialog/AlertDialog";

import RefreshIcon from "@material-ui/icons/Refresh";
import ArrowBack from "@material-ui/icons/ArrowBack";
import '../../styles/style.css';
import { makeStyles } from "@material-ui/core";

import { AppBar, Toolbar, Box, Tooltip, Grid, IconButton, Button, TextField, Typography, Modal } from "@material-ui/core";
import styles from "../../components/layout/SplitView.module.scss";

// Segments
import Axios from "axios";
import { wmdAdalApiFetch } from "../../config/wmdConfig";
import { getApiConfig } from "../../config/apiConfig";
import useExternalScripts from "../../hooks/useExternalScripts";


const API_CONFIG = getApiConfig();


const useStyles = makeStyles((theme) => ({
    map: {
        width: "100%",
        height: "600px",
        marginTop: '150px',
    },
    hideClearIndicator: {
        "& .MuiAutocomplete-clearIndicator": {
            display: "none",
        },
    },
    noFieldset: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid white",
            top: '-4px',
            bottom: '5px',
        },
    },
    svgIcon: {
        "& .MuiSvgIcon-root": {
            color: 'white',
        }
    },
    inputNum: {
        "& .MuiAutocomplete-input": {
            color: 'white',
            fontSize: '0.875rem',
            fontWeight: 400
        }
    },
    inpField: {
        "& .MuiInputBase-fullWidth": {
            width: '74%',
        }
    },
    icons: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    root: {
        "& .MuiFormLabel-root": {
            color: "white",
        }
    },
    list: {
        border: "1px solid black",
        width: '100%',
        fontSize: '0.735rem',
        fontWeight: 400,
        position: 'fixed',
    },
    option: {
        "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white !important"
            }
        }
    }
}));

const Map = () => {
    const [mapkitLoaded, setMapkitLoaded] = useState(0);
    const [mapkit, setMapkit] = useState(null);
    const [map, setMap] = useState(null);
    useExternalScripts({ url: "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js" });
    const location = useLocation();
    const classes = useStyles();
    const history = useHistory();
    if (!location?.state?.res) {
        history.push({ pathname: '/wmd' });
    }
    const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showSearchAlert, setShowSearchAlert] = useState(false);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orders, setOrders] = useState([]);
    const [mapResponse, setMapResponse] = useState(location?.state?.res);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [openDropDown, setDropDown] = useState(false);

    const handleMapClick = (event) => {
        if (
            autocompleteRef.current &&
            !autocompleteRef.current.contains(event.target)
        ) {
            setDropDown(false);
        }
    };

    useEffect(() => {
        window.addEventListener("click", handleMapClick);

        return () => {
            window.removeEventListener("click", handleMapClick);
        };
    }, []);

    const fetchApiData = async (customerNumber, invoiceNumber) => {
        try {
            const headers = { "Content-Type": "application/json" };
            const response = await wmdAdalApiFetch(
                Axios, `${API_CONFIG.WMD}/GetOrderDetailsByCustomer?customerId=${customerNumber ? customerNumber : null}&invoiceNumber=${invoiceNumber ? invoiceNumber : null}`,
                {
                    method: "get",
                    headers: headers
                }
            );
            if (response.status === 200) {
                setMapResponse(response.data)
                setOrders(response.data.Orders);
                const order = response.data.Orders.find(item => item.OrderNumber === selectedOrder.OrderNumber && item.ShipmentId === selectedOrder.ShipmentId)
                setSelectedOrder(order)
            } else {
                console.error(`API request failed with status code: ${response.status}`);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const UpdateOrder = () => {
        setOrders(mapResponse.Orders);
        setSelectedOrderNumber(`Proforma : ${mapResponse.Orders[0].OrderNumber}`)
        setSelectedOrder(mapResponse.Orders[0])
    }

    const setExternalScripts = () => {
        const url = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";
        const head = document.querySelector("head");
        const script = document.createElement("script");

        script.setAttribute("src", url);
        head.appendChild(script);
    }
    
    //Use Effects
    useEffect(() => {
        if (!window.mapkit) {
            setExternalScripts();
        }
        wmdAdalApiFetch(Axios, `${API_CONFIG.WMD}/GetTokenForMap`,
            {
                method: "get",
            }).then((res) => {
                if (window.mapkit) {
                    setMapkit(window.mapkit)
                    const mapkit = window.mapkit
                    const jwt = res.data.mainkey;
                    mapkit.init({
                        authorizationCallback: (done) => { done(jwt); }
                    });
                    setMapkitLoaded(1);
                    let map = new mapkit.Map("map-container");
                    setMap(map)
                    UpdateOrder();
                } else {
                    const count = localStorage.getItem("reloadingCount")
                    if (count > 3) {
                        setShowSearchAlert(true)
                        setShowAlert(false)
                    } else {
                        setShowAlert(true)
                    }

                }
            })
            .catch((error) => {
                console.log(`Exception Occurred: ${error}`);
            });
    }, []);

    useEffect(() => {
        if (mapkitLoaded == 0) {
            return;
        }
        if (mapkit === undefined) {
            return;
        }

        if (mapRef.current != undefined) {
            return;
        }
        let CompletedDelivery = selectedOrder?.Legends.CompletedDelivery;
        let ScheduledDelivery = selectedOrder?.Legends.ScheduledDelivery;
        let SearchOutletsCount = selectedOrder?.Legends.SearchOutletsCount;
        // Loop through the array and create marker for each  
        map.annotations.map((item) => {
            map.removeAnnotation(item)
        })
        map.removeOverlays(map.overlays);

        if (selectedOrder.Legends?.LastTruckPosition.Breadcrumb.length > 0) {
            getTruck(selectedOrder);
            getTrackRoute(selectedOrder);
        }

        getCoordinates(CompletedDelivery, '#2dc937');
        getCoordinates(ScheduledDelivery, '#db7b2b');
        getCoordinates(SearchOutletsCount, '#000');
        const currentLat = mapResponse?.Orders[0].Legends?.SearchOutletsCount?.[0]['Latitude'];
        const currentLon = mapResponse?.Orders[0].Legends?.SearchOutletsCount?.[0]['Longitude'];
        const newCenter = new mapkit.Coordinate(currentLat, currentLon);
        const span = new mapkit.CoordinateSpan(0.01);
        const region = new mapkit.CoordinateRegion(newCenter, span);
        map.setRegionAnimated(region)
        mapContainerRef.current = map;
    }, [selectedOrder]);

    const getCoordinates = (waypoints, color) => {
        let myAnnotations = [];
        // lastWaypoint variable is 'unset' initially so the map doesn't try and find a route to the lastWaypoint for the first point of the route

        waypoints?.forEach(function (data) {
            var myAnnotation = new mapkit.MarkerAnnotation(new mapkit.Coordinate(data['Latitude'], data['Longitude']), {
                callout: landmarkAnnotationCallout,
                color: color,
                showsUserLocation: true,
                showsUserLocationControl: true
            });
            myAnnotation.landmark = data;
            myAnnotations.push(myAnnotation);
        });
        map.showItems(myAnnotations);
    }
    const getTruck = (order) => {
        const breadcumTrail = order.Legends?.LastTruckPosition.Breadcrumb;
        const currentLat = breadcumTrail?.[breadcumTrail.length - 1]?.['Latitude'];
        const currentLon = breadcumTrail?.[breadcumTrail.length - 1]?.['Longitude'];
        var coordinate = new mapkit.Coordinate(currentLat, currentLon);
        // Create a custom annotation
        let customAnnotation = new mapkit.Annotation(coordinate, (coordinate, options) => createCustomAccessoryView(), {})

        // Add the annotation to the map
        map.addAnnotation(customAnnotation);
    }
    const createCustomAccessoryView = () => {
        const accessoryView = document.createElement("div");
        accessoryView.innerHTML = `
        <i class="material-icons" style="font-size: 40px; color: #bc0201;">local_shipping</i>
        `;
        return accessoryView;
    }

    const getTrackRoute = (order) => {
        var lastWaypoint = "unset";
        let directions = new mapkit.Directions();
        let breadCumsTrail = order?.Legends.LastTruckPosition.Breadcrumb;
        breadCumsTrail?.forEach(function (data) {
            if (lastWaypoint != "unset") {
                computeDirections(lastWaypoint, new mapkit.Coordinate(data['Latitude'], data['Longitude']));
            }
            lastWaypoint = new mapkit.Coordinate(data['Latitude'], data['Longitude']);
        })

        //This asks MapKit for directions and when it gets a response sends it to directionHandler
        function computeDirections(origin, destination) {
            let directionsOptions = {
                origin: origin,
                destination: destination,
                transportType: mapkit.Directions.Transport.Automobile
            };
            directions.route(directionsOptions, directionHandler);
        }

        function directionHandler(error, data) {
            data?.["routes"].forEach(function (route, routeIdx) {
                if (routeIdx !== 0) { return; }
                let overlays = [];
                route?.['path'].forEach(function (path) {
                    // This styles the line drawn on the map
                    let overlayStyle = new mapkit.Style({
                        lineWidth: 3,
                        lineDash: str(),
                        lineCap: "butt",
                        strokeColor: "#bc0201",

                    });
                    let overlay = new mapkit.PolylineOverlay(path, {
                        style: overlayStyle
                    });
                    if (path) {
                        overlays.push(overlay)
                    }
                });
                map.addOverlays(overlays);
            });
        }
        setMap(map);
    }



    const setRoute = (e, newValue) => {
        const arr = newValue.split("|");
        const findOrder = mapResponse.Orders.find((order) => order.OrderNumber === arr[0].trimEnd() && order.ShipmentId === arr[1].trimStart().trimEnd());
        setSelectedOrderNumber(`Proforma : ${findOrder?.OrderNumber}`);
        setSelectedOrder(findOrder);
        mapContainerRef.current = map;
        setMap(map)
    };

    function str() {
        let arr = [];
        arr.push(15)
        arr.push(5)
        return arr
    }
    // Landmark annotation custom callout
    function calloutForLandmarkAnnotation(annotation) {
        let result = Object.entries(annotation.landmark);
        var parentElement = document.createElement("div");
        parentElement.className = "landmark";
        const divElement = document.createElement("div");
        const title = divElement.appendChild(document.createElement("h2"));
        title.textContent = 'Name';
        const valueElement = divElement.appendChild(document.createElement("h2"));
        const getCustomerName = result.find(item => item[0].toString() === 'CustomerName')
        valueElement.textContent = getCustomerName?.[1].toString()
        divElement.classList.add('title-element');
        parentElement.appendChild(divElement)
        const finalResult = result.filter(item => item[0].toString() !== 'Latitude' && item[0].toString() !== 'Longitude' && item[0].toString() !== 'name' && item[0].toString() !== 'CustomerName')
        for (let i = 0; i < finalResult.length; i++) {
            var div = document.createElement("div");
            let key = finalResult[i][0];
            let value = finalResult[i][1];
            const keyTttle = div.appendChild(document.createElement("h2"));
            keyTttle.textContent = key.replace(/([a-z])([A-Z])/g, '$1 $2');
            const valueContent = div.appendChild(document.createElement("h1"));
            valueContent.textContent = value;
            div.className = "landmarkPopup";
            if (i % 2 == 1) {
                div.classList.add('element-background')
            } else {
                div.classList.add('element-white-background')
                div.classList.remove('element-background')
            }
            parentElement.appendChild(div)
        }
        return parentElement;
    }

    // Landmark annotation callout delegate
    let landmarkAnnotationCallout = {
        calloutContentForAnnotation: function (annotation) {
            return calloutForLandmarkAnnotation(annotation);
        },
        calloutAppearanceAnimationForAnnotation: function (annotation) {
            return ".4s cubic-bezier(0.4, 0, 0, 1.5) 0s 1 normal scale-and-fadein";
        }
    };


    const backbtnHandler = () => {
        history.push('/wmd');
    }
    const handleRefreshClick = async () => {
        const { ShipToCustomerNumber, InvoiceNumber } = mapResponse;
        fetchApiData(ShipToCustomerNumber, InvoiceNumber)
    }
    const getError = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="subtitle1" gutterBottom>
                    <Tooltip title="Error">
                        <IconButton style={{ color: '#c31d1d' }}>
                            <ErrorIcon />
                        </IconButton>
                    </Tooltip>Map loading Error
                </Typography>
            </div>
        )
    }

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                window.location.reload()
                const count = localStorage.getItem("reloadingCount")
                localStorage.setItem("reloadingCount", count + 1)
            }, 2000);
        }
    }, [showAlert]);

    const showErrorAlert = () => {
        return (
            <AlertDialog
                title={getError()}
                description={"Map loading error occured. Reloading the map screen again..."}
                options={[]}
                isOpen={showAlert}
            />
        );
    }

    const showErrorSearchAlert = () => {
        return (
            <AlertDialog
                title={getError()}
                description={"Auto reload limit exceeded, Please click on Back to search to Redirect search screen"}
                options={[{
                    label: "Back To Search",
                    action: () => {
                        history.push({ pathname: '/wmd' });
                        localStorage.setItem("reloadingCount", 0)
                    }
                }]}
                isOpen={showSearchAlert}
            />
        );
    };

    return (
        <Box>
            <Fragment>
                <Grid
                    container
                    spacing={0}
                    style={{ display: "flex", margin: "0 0 0 0" }}
                >
                    <Grid>
                        <div className='container' >
                            <AppBar position="static" className="appBar">
                                <Toolbar className="toolbar">
                                    <Typography variant="h6" className="left-text">
                                        <span className='inner-text' style={{ color: 'grey' }}>CUSTOMER NUMBER</span> <span className='inner-text' style={{ color: 'black', marginLeft: 8 }}>{mapResponse?.ShipToCustomerNumber}</span>
                                    </Typography>
                                    <Typography variant="h6" className="right-text">
                                        <span className='inner-text' style={{ color: 'grey' }}>CUSTOMER NAME</span> <span className='inner-text' style={{ color: 'black', marginLeft: 8 }}>{mapResponse?.CustomerName}</span>
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </div>
                    </Grid>
                    <div className={`${styles.appBar} outer-container`}>
                        <div className='inner-container'>
                            <Grid id='map-header'
                                container
                                item xs={12}
                                spacing={0}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                                <Grid item xs={1} style={{ paddingLeft: 6 }}>
                                    <Tooltip id='refresh' title="Refresh" style={{ color: 'white' }}>
                                        <IconButton>
                                            <RefreshIcon onClick={handleRefreshClick} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        ref={autocompleteRef}
                                        open={openDropDown}
                                        onOpen={() => setDropDown(true)}
                                        onClose={() => setDropDown(false)}
                                        disableClearable={true}
                                        id='Orders'
                                        options={mapResponse ? mapResponse.Orders.map((order) => `${order.OrderNumber} | ${order.ShipmentId} | ${order.Route}`) : []}
                                        value={selectedOrderNumber ? selectedOrderNumber : ''}
                                        onChange={setRoute}
                                        inputValue={selectedOrderNumber ? selectedOrderNumber : ''}
                                        classes={{ paper: classes.list }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                margin="normal"
                                                fullWidth
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                size="small"
                                                className={`${classes.hideClearIndicator} ${classes.noFieldset} ${classes.svgIcon} ${classes.inputNum} ${classes.inpField} ${classes.root} ${classes.option}`}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={1} style={{ color: '#fff' }}>
                                    <h1>
                                        {`Route: ${selectedOrder?.Route ? selectedOrder?.Route : ''}`}
                                    </h1>
                                </Grid>
                                <Grid item xs={2} style={{ color: '#fff' }}>
                                    <h1>
                                        {`Shipment: ${selectedOrder?.ShipmentId ? selectedOrder?.ShipmentId : ''}`}
                                    </h1>
                                </Grid>
                                <Grid item xs={2} style={{ color: '#fff' }}>
                                    <label>
                                        {`(${selectedOrder?.CompletedStops ? selectedOrder?.CompletedStops : ''}/${selectedOrder?.TotalStops ? selectedOrder?.TotalStops : ''} stops completed)`}
                                    </label>
                                </Grid>
                                <Grid item xs={2} style={{ color: '#fff' }}>
                                    <h1>
                                        {`Last GPS Ping: ${selectedOrder?.LastGpsPing}`}
                                    </h1>
                                </Grid>
                                <Grid item xs={1} style={{ paddingLeft: 6 }}>
                                    <Tooltip id='refresh' title="Back to Search" style={{ color: 'white' }}>
                                        <IconButton>
                                            <ArrowBack onClick={backbtnHandler} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Grid item xs={12}>
                        <div>
                            <div className={classes.map} ref={mapContainerRef} id="map-container"></div>
                            {!open ?
                                (<Grid className="legend" onClick={handleOpen}>
                                    <Tooltip title="key" style={{ color: 'black' }}>
                                        <IconButton>
                                            <VpnKeyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>) :
                                (<Modal
                                    open={open}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    className="custom-modal"
                                >
                                    <Box className="modal modal-container">
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography className="row title">Map Legend<span>
                                                    <CloseIcon onClick={handleClose} style={{ float: 'right', marginTop: 2, cursor: 'pointer' }} />
                                                </span></Typography>
                                                <Typography className="row">
                                                    <LocalShippingIcon className={classes.icons} style={{ color: '#c31d1d', marginRight: 13 }} />
                                                    Last known truck position</Typography>
                                                <Typography className="row">
                                                    <FiberManualRecordIcon className={classes.icons} style={{ color: 'green', marginRight: 12 }} />
                                                    Completed Deliveries</Typography>
                                                <Typography className="row">
                                                    <FiberManualRecordIcon className={classes.icons} style={{ color: 'orange', marginRight: 12 }} />
                                                    Scheduled Stop</Typography>
                                                <Typography className="row">
                                                    <FiberManualRecordIcon className={classes.icons} style={{ color: 'black', marginRight: 12 }} />
                                                    Searched for outlet</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Modal>)}
                        </div>
                    </Grid>
                </Grid>
                {showErrorAlert()}
                {showErrorSearchAlert()}
            </Fragment>
        </Box>
    );
}

const hoc = withRouter(Map);

// EXPORT COMPONENT
export { hoc as Map };
