export function getActivityRulesDropdownData(lookupData) {
  return {
    blackChannels: {
      label: "Black Channel",
      lookUpData: lookupData !== null ? lookupData.BlackChannels : null,
      lookupDataListName: "BlackChannels",
      isLookup: true,
      requestFieldName: "BlackCodeId"
    },
    businessOwners: {
      label: "Business Owner",
      lookUpData: lookupData !== null ? lookupData.BusinessOwners : null,
      lookupDataListName: "BusinessOwners",
      isLookup: true,
      requestFieldName: "BusinessOwnerId"
    },
    businessTypes: {
      label: "Business Type",
      lookUpData: lookupData !== null ? lookupData.BusinessTypes : null,
      lookupDataListName: "BusinessTypes",
      isLookup: true,
      requestFieldName: "BusinessTypeId"
    },
    city: {
      label: "City",
      lookUpData: null,
      lookupDataListName: null,
      isLookup: false,
      requestFieldName: "City"
    },
    clocCodes: {
      label: "CLOC Code",
      lookUpData: lookupData !== null ? lookupData.ClocCodes : null,
      lookupDataListName: "ClocCodes",
      isLookup: true,
      requestFieldName: "ClocCodeId"
    },
    customerTypes: {
      label: "Customer Type",
      lookUpData: lookupData !== null ? lookupData.CustomerTypes : null,
      lookupDataListName: "CustomerTypes",
      isLookup: true,
      requestFieldName: "CustomerTypeId"
    },
    distributionChannels: {
      label: "Distribution Channel",
      lookUpData: lookupData !== null ? lookupData.DistributionChannels : null,
      lookupDataListName: "DistributionChannels",
      isLookup: true,
      requestFieldName: "DistributionChannelId"
    },
    globalCustomerNumber: {
      label: "Global Customer Number",
      lookUpData: lookupData !== null ? lookupData.GlobalCustomerNumber : null,
      lookupDataListName: "GlobalCustomerNumber",
      isLookup: true,
      requestFieldName: "GlobalCustomerNumberId"
    },
    level2Customer: {
      label: "Level 2 Customer",
      lookUpData: lookupData !== null ? lookupData.L2Customer : null,
      lookupDataListName: "L2Customer",
      isLookup: true,
      requestFieldName: "L2CustomerNumber"
    },
    level3Customer: {
      label: "Level 3 Customer",
      lookUpData: lookupData !== null ? lookupData.L3Customer : null,
      lookupDataListName: "L3Customer",
      isLookup: true,
      requestFieldName: "L3CustomerNumber"
    },
    level4Customer: {
      label: "Level 4 Customer",
      lookUpData: lookupData !== null ? lookupData.L4Customer : null,
      lookupDataListName: "L4Customer",
      isLookup: true,
      requestFieldName: "L4CustomerNumber"
    },
    level5Customer: {
      label: "Level 5 Customer",
      lookUpData: lookupData !== null ? lookupData.L5Customer : null,
      lookupDataListName: "L5Customer",
      isLookup: true,
      requestFieldName: "L5CustomerNumber"
    },
    licenseTypes: {
      label: "License Type",
      lookUpData: lookupData !== null ? lookupData.LicenseTypes : null,
      lookupDataListName: "LicenseTypes",
      isLookup: true,
      requestFieldName: "LicenseTypeId"
    },
    marketTypes: {
      label: "Market Type",
      lookUpData: lookupData !== null ? lookupData.MarketTypes : null,
      lookupDataListName: "MarketTypes",
      isLookup: true,
      requestFieldName: "MarketTypeId"
    },
    operationalRegions: {
      label: "Operational Region",
      lookUpData: lookupData !== null ? lookupData.OperationalRegions : null,
      lookupDataListName: "OperationalRegions",
      isLookup: true,
      requestFieldName: "OperationalRegionId"
    },
    postalCode: {
      label: "Postal Code",
      lookUpData: null,
      lookupDataListName: null,
      isLookup: false,
      requestFieldName: "PostalCode"
    },
    precincts: {
      label: "Precinct",
      lookUpData: lookupData !== null ? lookupData.Precincts : null,
      lookupDataListName: "Precincts",
      isLookup: true,
      requestFieldName: "PrecinctId"
    },
    preferredOrderMethods: {
      label: "Preferred Order Method",
      lookUpData: lookupData !== null ? lookupData.PreferredOrderMethods : null,
      lookupDataListName: "PreferredOrderMethods",
      isLookup: true,
      requestFieldName: "PreferredOrderMethodId"
    },
    priceBooks: {
      label: "Price Book",
      lookUpData: lookupData !== null ? lookupData.PriceBooks : null,
      lookupDataListName: "PriceBooks",
      isLookup: true,
      requestFieldName: "PriceBookId"
    },
    redChannels: {
      label: "Red Channel",
      lookUpData: lookupData !== null ? lookupData.RedChannels : null,
      lookupDataListName: "RedChannels",
      isLookup: true,
      requestFieldName: "RedCodeId"
    },
    regionCode: {
      label: "Region Code",
      lookUpData: null,
      lookupDataListName: null,
      isLookup: false,
      requestFieldName: "RegionCode"
    },
    salesOffices: {
      label: "Sales Office",
      lookUpData: lookupData !== null ? lookupData.SalesOffices : null,
      lookupDataListName: "SalesOffices",
      isLookup: true,
      requestFieldName: "SalesOfficeId"
    },
    salesPolicies: {
      label: "Sales Policy",
      lookUpData: lookupData !== null ? lookupData.SalesPolicies : null,
      lookupDataListName: "SalesPolicies",
      isLookup: true,
      requestFieldName: "SalesPolicyId"
    },
    servicePolicies: {
      label: "Service Policy",
      lookUpData: lookupData !== null ? lookupData.ServicePolicies : null,
      lookupDataListName: "ServicePolicies",
      isLookup: true,
      requestFieldName: "ServicePolicyId"
    },
    subTradeChannels: {
      label: "Sub-Trade Channel",
      lookUpData: lookupData !== null ? lookupData.SubTradeChannels : null,
      lookupDataListName: "SubTradeChannels",
      isLookup: true,
      requestFieldName: "SubTradeChannelId"
    },
    tradeChannels: {
      label: "Trade Channel",
      lookUpData: lookupData !== null ? lookupData.TradeChannels : null,
      lookupDataListName: "TradeChannels",
      isLookup: true,
      requestFieldName: "TradeChannelId"
    },
    tradeNames: {
      label: "Trade Name",
      lookUpData: lookupData !== null ? lookupData.TradeNames : null,
      lookupDataListName: "TradeNames",
      isLookup: true,
      requestFieldName: "TradeNameId"
    }
  };
}
