import React from "react";

import { Button } from "@material-ui/core";

import styles from "./TitledCard.module.scss";
import { CustomCardContent } from "../card-content/CardContent";
import { SimpleCardHeader } from "../custom-card/card-header/CardHeader";

export default function TitledCard(props) {
  return (
    <CustomCardContent>
      <SimpleCardHeader>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderLeft}>
            <div className={styles.cardTitle}>{props.title}</div>
          </div>
        </div>
        <div className={styles.actionButton}>
          {props.actionButton ? props.actionButton : null}
        </div>
        {props.editable ? (
          <Button onClick={props.handleEditClick} variant="outlined">
            {props.isEditing ? "Done" : "Edit"}
          </Button>
        ) : null}
      </SimpleCardHeader>
      {props.children}
    </CustomCardContent>
  );
}
