//React
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Redux
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

import { RootState } from "../../../state/reducers/AppReducer";
import { UserReducerState } from "../../../state/reducers/UserReducer";

//Styling
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import "../styles/style.css";

//API
import {
  getActivityGroupIdForSite,
  UpsertDisplayGroup,
} from "../../../state/actions/MyDisplaysActions";

//Components
import HeaderBlock from "../../../components/header-block/HeaderBlock";

import {
  createActivity,
  createTarget,
  getActivityById,
} from "../../../state/actions/ActivityActions";
import { Loading } from "../../../components/loading/Loading";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getApiConfig } from "../../../config/apiConfig";
import { ActivityReducerState } from "../../../state/reducers/ActivityReducer";
import { MyDisplaysReducer, MyDisplaysReducerState } from "../../../state/reducers/MyDisplaysReducer";
import { DisplayGroup } from "./MyDisplaysRedView";

const API_CONFIG = getApiConfig();

// FIXME: history should be a string[] right?
const MakeNewMyDisplaysRedView = (props: { match: { params: { DisplayGroupId: any; }; }; location: { pathname: string; }; history: any; }) => {
  const displayGroupId = props.match.params.DisplayGroupId;
  const path = props.location.pathname.split("/")[2];
  const dispatch = useDispatch();

  //Selector
  const { loggedInUser } = useSelector<RootState, UserReducerState>((state) => state.UserReducer);

  let createdUser = "";
  if (loggedInUser != null) {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const { lookupData } = useSelector<RootState, ActivityReducerState>((state) => state.ActivityReducer);

  const { displayGroup } = useSelector<RootState, MyDisplaysReducerState>((state) => state.MyDisplaysReducer);

  const { currentActivity } = useSelector<RootState, ActivityReducerState>((state) => state.ActivityReducer);

  const { displayActivities } = useSelector<RootState, MyDisplaysReducerState>((state) => state.MyDisplaysReducer);

  const {activityGroupId} = useSelector<RootState, MyDisplaysReducerState>((state) => state.MyDisplaysReducer);

  //State
  const [displayGroupDescriptionError, setDisplayGroupDescriptionError] = useState(false);
  const [loading, setLoading] = useState(true);
  //@ts-ignore
  const [newDisplayGroup, setNewDisplayGroup] = useState<DisplayGroup>({});

  const [upsertError, setUpsertError] = useState(false);
  const [activityGroupIdError, setActivityGroupIdError] = useState(false);

  //Activity State
  const [newActivity, setNewActivity] = useState<any>({});

  const [newActivityId, setNewActivityId] = useState("");

  const [activitySuccess, setActivitySuccess] = useState(false);
  const [targetSuccess, setTargetSuccess] = useState(false);

  const setUpData = async () => {
    await dispatch(getActivityGroupIdForSite(loggedInUser?.territory === "au" ? 1 : 2, (success: boolean) => {
      if(success) {
        setLoading(false);
      }
      else {
        setActivityGroupIdError(true);
        setLoading(false);
      }
    }))
    setLoading(false);
  }

  const getUserSiteByTerritory = (user: any) => {
    if(user.territory === 'au') {
      return 1;
    }
    else if(user.territory === 'nz') {
      return 2;
    }
    else {
      throw new Error("loggedInUser is undefined.")
    }
  }

  useEffect(() => {
    const activityData: any = {
      Activity: {
        ActivityDataTypeId: 15,
        ActivityFrequencyId: 2,
        ActivityGroupId: activityGroupId,
        ActivityTypeId: API_CONFIG.displayActivityType,
        ClientId: null,
        EndDate: new Date(),
        IsActive: true,
        IsOneTimeAnswer: false,
        IsRequired: false,
        Max: 99,
        Min: 0,
        ModifiedBy: createdUser,
        Name: "New Display",
        Sequence: 1,
        StartDate: new Date(),
        CreatedBy: createdUser,
      },
    };

    setNewActivity(activityData);
  }, [activityGroupId])

  useEffect(() => {
    if (activitySuccess) {
      dispatch(
        createTarget(newActivityId, createRequestBody(), (success: boolean) => {
          // FIXME: How to know if this API fails.
          setTargetSuccess(true);
        })
      );
    }
  }, [activitySuccess]);

  useEffect(() => {
    if (targetSuccess) {
      var newAct = {...newActivity};
      newAct.Activity.Id = newActivityId;
      setNewActivity(newAct)
      const flattenedObj = flatten(newActivity);
      let localGroup = { ...newDisplayGroup };
      localGroup?.Displays?.push({
        Activities: [
          //@ts-ignore
          {
            MyDisplaysActivityId: newActivityId,
          },
        ],
        DisplayTypeActivity: { ...flattenedObj },
        DisplayTypeActivityId: newActivityId,
      });
      setNewDisplayGroup(localGroup);
      dispatch(
        UpsertDisplayGroup(localGroup, (success: any, displayGroupId: string) => {
          if (success) {
            props.history.push(`/my-displays-maintenance/${displayGroupId}`);
          } else {
            setUpsertError(true);
          }
        })
      );
    }
  }, [targetSuccess]);

  useEffect(() => {
    setLoading(true);
      dispatch(
        getLoggedInUserByEmail(() => {
          setNewDisplayGroup({
            ActivityTargets: [],
            Description: "",
            CreatedBy: createdUser,
            CreatedDate: new Date().toISOString(),
            ModifiedBy: createdUser,
            ModifiedDate: new Date().toISOString(),
            Site: getUserSiteByTerritory(loggedInUser),
            Displays: [],
          });
          setUpData();
        })
      );
  }, []);

  useEffect(() => {
    if (
      displayGroup !== undefined &&
      displayGroup.Displays !== undefined &&
      displayGroup.Displays.length > 0
    ) {
      dispatch(
        getActivityById(
          displayGroup.Displays[0].DisplayTypeActivityId,
          () => {}
        )
      );
    }

  }, [displayGroup]);

  const handleDisplayGroupDescription = (e: any) => {
    if (e.target.value.length >= 1) {
      setDisplayGroupDescriptionError(false);
    }
    let localData = { ...newDisplayGroup };
    localData.Description = e.target.value;
    setNewDisplayGroup(localData);
  };

  const flatten = (item: any) => {
    let output: any = {};
    for (const i in item) {
      if (typeof item[i] === "object" && !Array.isArray(item[i])) {
        const temp = flatten(item[i]);
        for (const j in temp) {
          output[j] = temp[j];
        }
      } else {
        output[i] = item[i];
      }
    }
    return output;
  };

  const submitData = () => {
    if (!newDisplayGroup.Description) {
      setDisplayGroupDescriptionError(true);
    }
    dispatch(
      createActivity(newActivity, (success: any, activityId: string) => {
        if (success) {
          setNewActivityId(activityId);
          setActivitySuccess(true);
        }
      })
    );
  };

  // Target Functions
  const createRequestBody = () => {
    let requestBody: any = {
      OpportunityValue: 0,
      TargetValue: 0,
      Site: getUserSiteByTerritory(loggedInUser),
      IsActive: true,
      Sequence: 1,
      CustomerNumbers: [],
      RegionCode: "",
      City: "",
      PostalCode: "",
      PrecinctId: "",
      MarketTypeId: "",
      TradeChannelId: "",
      SubTradeChannelId: "",
      BusinessOwnerId: "",
      TradeNameId: "",
      CustomerTypeId: "",
      BusinessTypeId: "",
      OperationalRegionId: "",
      LicenseTypeId: "",
      GlobalCustomerNumberId: "",
      ServicePolicyId: "",
      SalesPolicyId: "",
      PreferredOrderMethodId: "",
      SalesOfficeId: "",
      PriceBookId: "",
      DistributionChannelId: "",
      BlackCodeId: null,
      RedCodeId: null,
      L2CustomerNumber: null,
      L3CustomerNumber: null,
      L4CustomerNumber: null,
      L5CustomerNumber: null,
      ClocCodeId: "",
    };

    requestBody.CreatedBy = createdUser;
    requestBody.ModifiedBy = createdUser;

    return requestBody;
  };

  const buttonGroupElement =
    <ButtonGroup style={{ marginRight: "35px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => props.history.push("/my-displays-maintenance")}
      >
        Cancel
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        disabled={upsertError}
        onClick={submitData}
      >
        Submit
      </Button>
    </ButtonGroup>
  ;

  const upsertAlertElement = upsertError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Failed to create new display group<strong> Please contact support</strong>
    </Alert>
  ) : null;

  const activityGroupIdErrorElement = activityGroupIdError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Activity Group Id is undefined<strong> Please contact support or refresh the page</strong>
    </Alert>
  ) : null;

  if(loading) {
    return <Loading />
  }

  return (
    <div className="red-page">
        <>
          <HeaderBlock
            title={"New Display Group"}
            right={
              <Grid
                container
                spacing={3}
                alignItems="center"
                justify="flex-end"
              >
                {buttonGroupElement}
              </Grid>
            }
          />

          <div style={{ marginTop: "100px" }}>
            {upsertAlertElement}
            {activityGroupIdErrorElement}
            <div style={{ marginTop: "150px" }}>
              <div className="create-container">
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <FormControl>
                        <TextField
                          style={{ marginTop: "20px" }}
                          variant="outlined"
                          disabled
                          label={"Id"}
                          InputLabelProps={{ shrink: true }}
                          // value={isNew ? "" : displayGroup.GroupId}
                          placeholder="Display Group Id" 
                        />
                        <TextField
                          required
                          style={{ marginTop: "20px" }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          placeholder={"Enter Display Group Description"}
                          label="Description"
                          helperText={
                            displayGroupDescriptionError
                              ? "Group Description is Required."
                              : ""
                          }
                          error={displayGroupDescriptionError}
                          onChange={(e) => handleDisplayGroupDescription(e)}
                          value={newDisplayGroup.Description}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </>
    </div>
  );
};


const hoc = withRouter(connect()(MakeNewMyDisplaysRedView));

// EXPORT COMPONENT
export { hoc as MakeNewMyDisplaysRedView };
