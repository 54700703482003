import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderBlock.module.scss'; // Import css modules stylesheet as styles
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { ArrowLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginLeft: '10px',
    marginTop: '10px',
  },
  leftContainer: {
    maxWidth: "60%",
    fontSize: '26px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    marginTop: '5px'
  },
}));

function HeaderBlock(props) {
  const classes = useStyles();
  const { title, showBackLink, backText= 'Back', right } = props;

  return (
    <Grid container spacing={2} wrap="nowrap">
      <div className={styles.headerBlock}>
        <Grid item xs={6} zeroMinWidth className={classes.leftContainer}>
          {showBackLink && (
            <Link to="." className={styles.backLink}>
              <ArrowLeft size="sm"/> {backText}
            </Link>
          )}
          <Typography noWrap variant="h6" >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <div className={styles.rightContainer}>
            {right}
          </div>
        </Grid>
      </div>
    </Grid>
  )
}

HeaderBlock.propTypes = {
  title: PropTypes.string,
  right: PropTypes.element,
  showBackLink: PropTypes.bool,
  backText: PropTypes.string
};
export default HeaderBlock;