import {
  GET_PRODUCTS,
  GET_CORERANGE_LIST,
  GET_TARGETING_DATA,
  GET_TARGET_GROUPS_ALLDATA,
  UPLOAD_CUSTOMER_DOORS,
  UPSERT_CORERANGE,
  VIEW_CORERANGE,
  GET_CUSTOMER_ATTRIBUTES,
} from "../constants/action-types";

//loggedInUser.userName.split("\\")[1]

// INITIALIZE STATE
const initialState = {
  counter: 0,
  Products: [],
  TargetingData: {
    Products: [],
    Markets: [],
    TradeChannels: [],
    SubTradeChannels: [],
    CustomerTradeNames: [],
    CustomerBusinessOwners: [],
    Customers: [],
  },
  TargetGroups: [],
  CoreRangeList: [],
  CoreRange: {},
  UploadedCustomerDoorsResponse: { updated: 0, Created: 0, deleted: 0 },
  customerAttributes: []
};


// REDUCER
export const CoreRangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS: {
      return {
        ...state,
        Products: action.payload
      };
    }
    case GET_TARGETING_DATA: {
      return {
        ...state,
        TargetingData: action.payload
      };
    }
    case GET_TARGET_GROUPS_ALLDATA: {
      return {
        ...state,
        TargetGroups: action.payload
      };
    }
    case GET_CORERANGE_LIST: {
      return {
        ...state,
        CoreRangeList: action.payload
      }
    }
    case UPSERT_CORERANGE: {
      return {
        ...state,
        CoreRange: action.payload
      }
    }
    case VIEW_CORERANGE: {
      return {
        ...state,
        CoreRange: action.payload
      }
    }
    case GET_CUSTOMER_ATTRIBUTES: {
      return {
        ...state,
        customerAttributes: action.payload
      }
    }
    case UPLOAD_CUSTOMER_DOORS:
      return {
        ...state,
        UploadedCustomerDoorsResponse: action.payload
      };

    default:
      return state;
  }
};
