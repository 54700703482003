// React
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

// UI and Styling
import { IconButton, Tooltip } from "@material-ui/core";

// Actions
import { GetDisplayGroups, getMyDisplaysAsCSV } from "../../../state/actions/MyDisplaysActions";
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

// Other
import MUIDataTable from "mui-datatables";
import PageContainer from "../../../components/page-container/PageContainer";
import { AddCircleRounded } from "@material-ui/icons";
import { format } from "date-fns";
import { Loading } from "../../../components/loading/Loading";

// Component entry point
const MyDisplaysList = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);

  const displayGroupList = useSelector(
    (state) => state.MyDisplaysReducer.displayGroupList
  );

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const getSiteByTerritory = (user) => {
    if (!user) {
      throw 'User undefined';
    }

    if (user.territory === "au") {
      return 1;
    }
    if (user.territory === "nz") {
      return 2;
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      getLoggedInUserByEmail((success) => {
        if (success) {
          setIsLoggedInUser(true);
        } else {
          throw 'Unable to get logged in user.';
        }
      })
    );
  }, []);

  useEffect(() => {
    if (isLoggedInUser) {
      dispatch(
        GetDisplayGroups(getSiteByTerritory(loggedInUser), () => {
          setLoading(false);
        })
      );
    }
  }, [isLoggedInUser]);

  const handleDownloadCSV = () => {
    dispatch(getMyDisplaysAsCSV(loggedInUser.territory === "au" ? 1 : 2, () => {

    }))
  }

  const columns = [
    {
      name: "GroupId",
      label: "Group Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <span>{value}</span>
        ),
      },
    },
    {
      name: "CreatedBy",
      label: "Created By",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "ModifiedDate",
      label: "Last Modified",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>{value ? format(new Date(value), "dd/MM/yyyy") : ""}</>
        ),
      },
    },
    {
      name: "ModifiedBy",
      label: "Modified By",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    download: false,
    filterType: "textField",
    onRowClick: (a, b) => {
      //Retrieve the original object ID from the data list.
      var displayGroupId = displayGroupList[b.dataIndex].GroupId;
      props.history.push(`/my-displays-maintenance/${displayGroupId}`);
    },
    pagination: false,
    print: false,
    searchPlaceholder: "Search By Any Field",
    selectableRows: "none",
  };

  options.customToolbar = () => {
    return (
      <Fragment>
        <Tooltip title="Download CSV">
          <IconButton onClick={handleDownloadCSV}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={(event) => {
            props.history.push("/my-displays-maintenance/new");
          }}
        >
          <AddCircleRounded />
        </IconButton>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <PageContainer>
        <MUIDataTable
          stickyHeader
          title={"My Displays Maintenance"}
          data={displayGroupList}
          columns={columns}
          options={options}
        />
      </PageContainer>
    );
  }
};

const hoc = withRouter(MyDisplaysList);

// EXPORT COMPONENT
export { hoc as MyDisplaysList };
