// React
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// UI and Styling
import {
  Checkbox,
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import styles from "./ListableRulesItem.module.scss";

// Components
import DropdownSelect from "../../dropdown-select/DropdownSelect";
import MultiSelectDropDown from "../../multi-select-drop-down/MultiSelectDropDown";
// Other
import { getIcon } from "../../../icon/icon";
import { TargetFields } from "../../../state/constants/TargetFields";
import { getCustomerAttributes } from "../../../state/actions/CoreRangeActions";

// COMPONENT
export default function ListableRulesItem(props) {
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const customerAttributes = useSelector(
    (state) => state.CoreRangeReducer.customerAttributes
  );

  let typesArray = Object.values(
    TargetFields[props.modelType.replace(" ", "")].RULE
  );
  let filteredArray = [];

  if (props.modelType === "CUSTOMER") {
    filteredArray = typesArray.filter((item) =>
      customerAttributes.includes(item.key)
    );
  }

  useEffect(() => {
    dispatch(
      getCustomerAttributes(loggedInUser.territory === "nz" ? 2 : 1),
      () => {}
    );
  }, []);

  useEffect(() => {
    var a = props.modelType;
  }, []);
  const possibleRuleValues =
    props.possibleRuleValues.length > 0
      ? props.possibleRuleValues[0]["RulePossibleValues"]
      : [];

  const selectedRuleValues =
    props.selectedRuleValues !== undefined
      ? props.selectedRuleValues.map((rule) => {
          return possibleRuleValues.length > 0
            ? possibleRuleValues.find(
                (possibleRule) => possibleRule.Id === rule
              )
            : rule;
        })
      : [];
  return (
    <ListItem disableGutters={true}>
      {props.toolbarShowing && (
        <div className={styles.checkbox}>
          <Checkbox
            onClick={() => props.handleCheck(props.id)}
            checked={props.selectedItems.hasOwnProperty(props.id)}
          />
        </div>
      )}
      <ListItemText>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <DropdownSelect
              fullWidth={true}
              disabled={props.disabled}
              label="Name"
              handleChange={(value) => {
                props.handleFieldNameChange(
                  TargetFields[props.modelType.replace(" ", "")].RULE[value],
                  props.index
                );
              }}
              data={
                props.modelType && props.isCoreRange
                  ? filteredArray
                  : Object.values(
                      TargetFields[props.modelType.replace(" ", "")].RULE
                    )
              }
              value={
                TargetFields[props.modelType.replace(" ", "")].RULE[
                  props.fieldNameValue
                ]
                  ? TargetFields[props.modelType.replace(" ", "")].RULE[
                      props.fieldNameValue
                    ].key
                  : ""
              }
              valueName={props.fieldName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {possibleRuleValues.length > 0 ? (
              <MultiSelectDropDown
                id={props.id}
                fullWidth={true}
                multiple={true}
                disableCloseOnSelect={true}
                showCheckbox={true}
                disabled={props.disabled}
                label="Value"
                options={possibleRuleValues}
                selectedValues={selectedRuleValues}
                onChange={(event, value) => {
                  props.handleValueChange(value);
                }}
                variant="outlined"
                textFieldLabel="Select Rule Options"
                textFieldPlaceHolder="Rule Options"
              />
            ) : (
              <TextField
                fullWidth={true}
                disabled={props.disabled}
                label="Value"
                margin="dense"
                value={selectedRuleValues.join("|")}
                onChange={(event) => {
                  props.handleValueChange(
                    event.target.value
                      .split("|")
                      .map(Function.prototype.call, String.prototype.trim)
                  );
                }}
                variant="outlined"
                className={styles.textField}
              />
            )}
          </Grid>
        </Grid>
      </ListItemText>
      {props.toolbarShowing && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => props.handleDelete([props.data.id])}
            edge="end"
            aria-label="delete"
          >
            {getIcon("trash.svg", styles.icon)}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
