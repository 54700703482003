export function sortDropdown(a, b) {
    var nameA = a.DisplayName.toUpperCase();
    var nameB = b.DisplayName.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}