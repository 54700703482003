export const ActivityGroupModel = {
  name: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "name",
    label: "Group Name",
  },
  startDate: {
    type: "date",
    required: true,
    errorMessage: "",
    value: "startDate",
    label: "Effective From",
  },
  endDate: {
    type: "date",
    required: true,
    errorMessage: "",
    value: "endDate",
    label: "Effective To",
  },
  sequence: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "sequence",
    label: "Sequence",
  },
  callScheduleTypes: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "callScheduleTypes",
    label: "Call Schedule Type(s)",
  },
  active: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "active",
    label: "Active",
  },
  groupType: {
    type: "string",
    required: false,
    errorMessage: "",
    value: "groupType",
    label: "RED Category",
  },
};
