import { AnyAction } from "redux";
import {
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_GROUP,
  CREATE_CHOICE,
  UPDATE_CHOICE,
  CREATE_REWARD,
  CREATE_TARGET,
  GET_ALL_ACTIVITY_GROUPS,
  GET_ACTIVITY_BY_ID,
  GET_ACTIVITY_GROUP_BY_ID,
  GET_CHOICE_BY_ID,
  GET_REWARD_BY_ID,
  GET_TARGET_BY_ID,
  GET_DROPDOWN_LOOKUPS,
  RESET_DISPLAY_TARGET,
} from "../constants/action-types";

import { getActivityRulesDropdownData } from "../constants/ActivityRules";

export interface ActivityReducerState {
  activityGroups: any,
  currentActivity: any,
  currentActivityGroup: any,
  currentChoice: any,
  currentReward: any,
  currentTarget: any,
  lookupData: any
}

// INITIALIZE STATE
const initialState = {
  activityGroups: [],
  currentActivity: {},
  currentActivityGroup: {},
  currentChoice: {},
  currentReward: {},
  currentTarget: {},
  lookupData: {},
};

const createActivityGroupFromResponse = (response: any) => {
  let activityGroup: any = {};

  if (Object.keys(response).length > 0) {
    activityGroup.id = response.data.ActivityGroup.Id;
    activityGroup.name = response.data.ActivityGroup.Name;
    activityGroup.startDate = response.data.ActivityGroup.StartDate
      ? new Date(response.data.ActivityGroup.StartDate + "Z")
      : "";
    activityGroup.endDate = response.data.ActivityGroup.EndDate
      ? new Date(response.data.ActivityGroup.EndDate + "Z")
      : "";
    activityGroup.sequence = response.data.ActivityGroup.Sequence;
    activityGroup.callScheduleTypes =
      response.data.ActivityGroupCallSchedules.map((call: any) => {
        return { Id: call.ScheduleTypeId, DisplayName: call.ScheduleTypeId };
      });
    activityGroup.activities = response.data.Activities;
    activityGroup.targets = response.data.ActivityGroupTargets;
    activityGroup.active = response.data.ActivityGroup.IsActive;
    activityGroup.groupType = response.data.ActivityGroup.GroupType;
  } else {
    activityGroup = {
      id: "",
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      sequence: "",
      callScheduleTypes: [],
      activities: [],
      targets: [],
      active: true,
      groupType: "",
    };
  }

  return activityGroup;
};

const createActivityFromResponse = (response: any) => {
  let activity: any = {};

  if (Object.keys(response).length > 0) {
    activity.id = response.data.Activity.Id;
    activity.name = response.data.Activity.Name;
    activity.startDate = response.data.Activity.StartDate
      ? new Date(response.data.Activity.StartDate + "Z")
      : "";
    activity.endDate = response.data.Activity.EndDate
      ? new Date(response.data.Activity.EndDate + "Z")
      : "";
    activity.sequence = response.data.Activity.Sequence;
    activity.required = response.data.Activity.IsRequired;
    activity.oneTimeAnswer = response.data.Activity.IsOneTimeAnswer;
    activity.active = response.data.Activity.IsActive;
    activity.activityGroupId = response.data.Activity.ActivityGroupId;
    activity.activityTypeId = response.data.Activity.ActivityTypeId;
    activity.activityDataTypeId = response.data.Activity.ActivityDataTypeId;
    activity.activityFrequencyId = response.data.Activity.ActivityFrequencyId;
    activity.min = response.data.Activity.Min;
    activity.max = response.data.Activity.Max;
    activity.clientId = response.data.Activity.ClientId;
    activity.choices = response.data.Choices;
    activity.rewards = response.data.Rewards;
    activity.targets = response.data.Targets;
  } else {
    activity = {
      startDate: new Date(),
      endDate: new Date(),
      sequence: "",
      required: false,
      oneTimeAnswer: false,
      active: true,
      activityGroupId: "",
      activityTypeId: "",
      name: "",
      activityDataTypeId: "",
      activityFrequencyId: "",
      min: 0,
      max: 0,
      clientId: null,
      choices: [],
      rewards: [],
      targets: [],
    };
  }

  return activity;
};

const createChoiceFromResponse = (response: any, state: any) => {
  let choice: any = {};
  if (Object.keys(response).length > 0) {
    choice.activityId = response.data.ActivityId;
    choice.name = response.data.Name;
    choice.weight = response.data.Weight;
    choice.active = response.data.IsActive;
    choice.rangingType =
      response.data.SelectionType === 1
        ? "Product Selection"
        : response.data.SelectionType === 3
        ? "Weight Selection"
        : "Brand & Material Selection";
    choice.subText = response.data.SubText;
    choice.minVariants = response.data.MinimumVariants;
    choice.imageOne = response.data.Image1;
    choice.imageTwo = response.data.Image2;
    choice.imageThree = response.data.Image3;
    choice.nonRangingName = response.data.Name;
    choice.nonRangingWeight = response.data.Weight;
    choice.nonRangingActive = response.data.IsActive;

    let lookupData: any = [];

    if (
      state.lookupData !== undefined &&
      Object.keys(state.lookupData).length > 0
    ) {
      lookupData = state.lookupData;
    }

    // Brand and material
    let brand: any = [];
    let material: any = [];
    let packType: any = [];
    let packSize: any = [];
    let beverageCategory: any = [];

    // The fields that support multiple values must be split into arrays, as they as stored as "a,b,c" format in DB.
    let splitbrand =
      response.data !== undefined &&
      response.data.Brand !== null &&
      Object.keys(response.data.Brand).length !== 0
        ? response.data.Brand.split(",")
        : [];
    let splitmaterial =
      response.data !== undefined &&
      response.data.Material !== null &&
      Object.keys(response.data.Material).length !== 0
        ? response.data.Material.split(",")
        : [];
    let splitPackType =
      response.data !== undefined &&
      response.data.PackType !== null &&
      Object.keys(response.data.PackType).length !== 0
        ? response.data.PackType.split(",")
        : [];
    let splitPackSize =
      response.data !== undefined &&
      response.data.PackSize !== null &&
      Object.keys(response.data.PackSize).length !== 0
        ? response.data.PackSize.split(",")
        : [];
    let splitBeverageCategory =
      response.data !== undefined &&
      response.data.BeverageCategory !== null &&
      Object.keys(response.data.BeverageCategory).length !== 0
        ? response.data.BeverageCategory.split(",")
        : [];
    if (lookupData !== undefined) {
      // Build the fully-formed objects for the lookups, aligned with the lookup data.
      // ! Need to handle impresence of brand or material
      splitbrand.forEach((el: any) =>
        brand.push(lookupData["Brands"].find((b: any) => b.Id === el))
      );
      splitmaterial.forEach((el: any) =>
        material.push(lookupData["Materials"].find((b: any) => b.Id === el))
      );
      splitPackType.forEach((el: any) =>
        packType.push(lookupData["PackTypes"].find((b: any) => b.Id === el))
      );
      splitPackSize.forEach((el: any) =>
        packSize.push(lookupData["PackSizes"].find((b: any) => b.Id === el))
      );
      splitBeverageCategory.forEach((el: any) =>
        beverageCategory.push(
          lookupData["BeverageCategories"].find((b: any) => b.Id === el)
        )
      );
      choice.brand = brand !== undefined ? brand : {};
      choice.material = material !== undefined ? material : {};
      choice.packSize = packSize !== undefined ? packSize : {};
      choice.packType = packType !== undefined ? packType : {};
      choice.beverageCategory =
        beverageCategory !== undefined ? beverageCategory : {};
    }
  } else {
    choice = {
      activityId: "",
      name: "",
      weight: "1",
      active: true,
      rangingType: "",
      brand: {},
      material: {},
      packType: {},
      packSize: {},
      beverageCategory: {},
      subText: "",
      minVariants: 0,
      imageOne: "",
      imageTwo: "",
      imageThree: "",
      nonRangingName: "",
      nonRangingWeight: "",
      nonRangingActive: true,
    };
  }
  return choice;
};

const createRewardFromResponse = (response: any) => {
  let reward: any = {};

  if (Object.keys(response).length > 0) {
    reward.activityId = response.data.ActivityId;
    reward.upperRange = response.data.UpperRange;
    reward.value = response.data.Value;
    reward.active = response.data.IsActive;
  } else {
    reward = {
      activityId: "",
      upperRange: "",
      value: "",
      active: true,
    };
  }

  return reward;
};

const createTargetFromResponse = (response: any, state: any) => {
  const dropdownData = getActivityRulesDropdownData(null);
  let target: any = {};

  if (Object.keys(response).length > 0) {
    target.site = response.data.Site === 1 ? "au" : "nz";
    target.active = response.data.IsActive;
    target.opportunityValue = response.data.OpportunityValue;

    target.targetValue = response.data.TargetValue;
    target.customerNumbers =
      response.data.CustomerNumbers !== undefined &&
      response.data.CustomerNumbers !== null
        ? response.data.CustomerNumbers.join(",")
        : "";

    const rules: any = [];
    let lookupData: any = [];

    if (
      state.lookupData !== undefined &&
      Object.keys(state.lookupData).length > 0
    ) {
      lookupData = state.lookupData;
    }

    Object.values(dropdownData).forEach((item: any) => {
      if (
        response.data[item.requestFieldName] !== null &&
        response.data[item.requestFieldName] !== "" && 
        response.data[item.requestFieldName] !== " "
      ) {

        if (item.isLookup) {
          let name = response.data[item.requestFieldName];
          
          if (lookupData !== undefined) {
            name = lookupData[item.lookupDataListName].find(
              (d: any) => d.Id === response.data[item.requestFieldName]
            );
          }

          rules.push({
            field: item.label,
            value: {
              Id: response.data[item.requestFieldName],
              DisplayName: name.DisplayName,
            },
          });
        } else {
          rules.push({
            field: item.label,
            value: response.data[item.requestFieldName],
          });
        }
      }
    });

    target.rules = rules;
  } else {
    target = {
      site: "au",
      active: true,
      rules: [],
      opportunityValue: 0,
      targetValue: 0,
    };
  }

  return target;
};

// REDUCER
export const ActivityReducer = (state = initialState, action: AnyAction): ActivityReducerState => {
  switch (action.type) {
    case CREATE_ACTIVITY: {
      const currentActivity = createActivityFromResponse(action.payload.data);

      return {
        ...state,
        currentActivity,
      };
    }

    case CREATE_ACTIVITY_GROUP: {
      const currentActivityGroup = createActivityGroupFromResponse(
        action.payload.data
      );

      return {
        ...state,
        currentActivityGroup,
      };
    }

    case CREATE_CHOICE: {
      const currentChoice = createChoiceFromResponse(action.payload.data, state);

      return {
        ...state,
        currentChoice,
      };
    }

    case UPDATE_CHOICE: {
      return {
        ...state,
        currentChoice: action.payload,
      };
    }

    case CREATE_REWARD: {
      const currentReward = createRewardFromResponse(action.payload.data);

      return {
        ...state,
        currentReward,
      };
    }

    case CREATE_TARGET: {
      const currentTarget = createTargetFromResponse(action.payload.data, state);

      return {
        ...state,
        currentTarget,
      };
    }

    case GET_ALL_ACTIVITY_GROUPS: {
      const activityGroups = action.payload.data;

      return {
        ...state,
        activityGroups,
      };
    }

    case GET_ACTIVITY_BY_ID: {
      const currentActivity = createActivityFromResponse(action.payload.data);

      return {
        ...state,
        currentActivity,
      };
    }

    case GET_ACTIVITY_GROUP_BY_ID: {
      const currentActivityGroup = createActivityGroupFromResponse(
        action.payload.data
      );

      return {
        ...state,
        currentActivityGroup,
      };
    }

    case GET_CHOICE_BY_ID: {
      const currentChoice = createChoiceFromResponse(
        action.payload.data,
        state
      );
      return {
        ...state,
        currentChoice,
      };
    }

    case GET_REWARD_BY_ID: {
      const currentReward = createRewardFromResponse(action.payload.data);

      return {
        ...state,
        currentReward,
      };
    }

    case GET_TARGET_BY_ID: {
      const currentTarget = createTargetFromResponse(
        action.payload.data,
        state
      );

      return {
        ...state,
        currentTarget,
      };
    }

    case GET_DROPDOWN_LOOKUPS: {
      return {
        ...state,
        lookupData: action.payload.data,
      };
    }

    case RESET_DISPLAY_TARGET: {
      return {
        ...state,
        currentTarget: {
          site: "au",
          active: true,
          rules: [],
          opportunityValue: 0,
          targetValue: 0,
        }
      }
    }

    default:
      return state;
  }
};
