import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { format } from "date-fns";

export const LocationActivityRow = ({
  displayIndex,
  displayActivities,
  displayGroupId,
}) => {
  const history = useHistory();

  const activeRewards = displayActivities[0].Rewards.filter(
    (item) => item.IsActive === true
  );

  return (
    <Grid
      container
      spacing={6}
      style={{
        marginTop: "1px",
        marginBottom: "0.5rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid item xs={1}>
        <Typography variant="body1">
          <strong>Location</strong>
        </Typography>
      </Grid>
      {displayIndex && (
        <>
          <Grid item xs={1}>
            <Typography
              variant="body2"
              className="activity-link"
              onClick={() =>
                history.push(
                  `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${displayActivities[0].Activity.Id}`
                )
              }
            >
              {displayActivities[0].Activity.Id}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {displayActivities[0].Activity.Name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {" "}
              {format(
                new Date(displayActivities[0].Activity.StartDate),
                "dd/MM/yyyy"
              )}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {" "}
              {format(
                new Date(displayActivities[0].Activity.EndDate),
                "dd/MM/yyyy"
              )}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <span
              className={`${
                displayActivities[0].Activity.IsActive
                  ? "active-tag-text"
                  : "inactive-tag-text"
              }`}
            >
              {displayActivities[0].Activity.IsActive ? "Active" : "Inactive"}
            </span>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {displayActivities[0].ActivityDataType.Value}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              variant="body2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {displayActivities[0].Rewards.length === 0 &&
              activeRewards.length === 0
                ? 0
                : Math.max(...activeRewards.map((item) => item.Value))}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
