// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import { Loading } from "../../components/loading/Loading";
import { getIcon } from "../../icon/icon";
import { isValid } from "date-fns";
import { compareDates, validateField } from "../../helpers/validation";
import ErrorIcon from "@material-ui/icons/Error";
import { format } from "date-fns";
import { removeColumnsByKey, reorderColumns } from '../../state/constants/ModelType'

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import PageContainer from "../../components/page-container/PageContainer";
import DateFnsUtils from "@date-io/date-fns";


// UI and Styling
import {
    Button, Grid, TextField, Paper,
    FormControl, Tooltip, IconButton
} from "@material-ui/core";

// Segments
import Axios from "axios";
import { adalApiFetch } from "../../config/azureConfig";
import { getApiConfig } from "../../config/apiConfig";
import { getFormat } from '../../state/constants/ModelType'

//Models
import { ViewOrderModel } from "../../models/ViewOrderModel";

//Details
import { ViewrrcOrderDetails } from "./ViewrrcOrderDetails";
const API_CONFIG = getApiConfig();


const ViewrrcOrdersPage = (props) => {
    const [dropdownDetails, setDropdownDetails] = useState(null);
    const [formProperties, setFormProperties] = useState(ViewOrderModel);
    const [isDetailsPageOpen, setIsDetailsPageOpen] = useState(false);
    const [selectedDropDown, setSelectedDropDown] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);

    const [searchedData, setSearchedData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);

    const [loading, setLoading] = useState(false);

    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    useEffect(() => {
        let model = ViewOrderModel;

        Object.keys(model).forEach(key => {
            const value = model[key];
            value.errorMessage = ''
        });
        setFormProperties(model)
    }, [])

    useEffect(() => {
        const siteId = loggedInUser.territory === "au" ? 1 : 2
        adalApiFetch(Axios, `${API_CONFIG.RRC}/GetRRCTPPTDetails?SiteId=${siteId}`,
            {
                method: "get",
            }).then((res) => {
                setDropdownDetails(res.data)
                let currentDate = new Date();
                // Subtract two months from the current date
                currentDate.setMonth(currentDate.getMonth() - 2);
                let localData = Object.assign({}, selectedDropDown);
                localData['FromDate'] = getFormat(currentDate);
                localData['ToDate'] = getFormat(new Date());
                setSelectedDropDown(localData)
            })
            .catch((error) => {
                console.log(`Exception Occurred: ${error}`);
            });
    }, []);


// API call to get Filtered data from database
    const fetchApiData = async () => {
        try {
            setLoading(true)
            const headers = { "Content-Type": "application/json" };
            const selectedServiceAgents = selectedDropDown.ServiceAgent?.map((item) => item.split("|")[0].trimEnd())
            const response = await adalApiFetch(Axios, `${API_CONFIG.RRC}/GetRRCOrderDetails`,
                {
                    params: {
                        TPPT: selectedDropDown?.TPPT,
                        ServiceAgent: `${selectedDropDown?.ServiceAgent?.length > 0 ? selectedServiceAgents : null}`,
                        ToDate: selectedDropDown?.ToDate,
                        FromDate: selectedDropDown?.FromDate
                    },
                    method: "get",
                    headers: headers
                }
            );
            if (response.status === 200) {
                setOriginalData(response.data);
                setSearchedData(response.data);
                setLoading(false)
            } else {
                console.error(`API request failed with status code: ${response.status}`);
                setLoading(false)
            }
        }
        catch (err) {
            setLoading(false)
            console.log(err);
        }
    }

    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, selectedDropDown);
        localData[name] = value;
        if (name === 'TPPT') {
            localData.ServiceAgent = null;
        }
        setSelectedDropDown(localData)
    }
    // The headers of the table
    const createHeaders = () => {
        return [
            {
                id: "TPPT",
                align: "left",
                label: "TPPT",
            },
            {
                id: "ServiceAgent",
                align: "left",
                label: "ServiceAgent",
            },
            {
                id: "RRCNumber",
                align: "left",
                label: "RRC Order",
            },
            {
                id: "ConsignmentNote",
                align: "left",
                label: "Consignment Note",
            },
            {
                id: "CreateDate",
                align: "left",
                label: "RRC Date",
            },
            {
                id: "NoOfPallets",
                align: "left",
                label: "Pallet Qty",
            },
            {
                id: "RRCQuantityReturned",
                align: "left",
                label: "RRC Returned Qty",
            },
        ];
    };
    // Create the cells for the table and configure them.
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            cell.align = header.align;
            cell.clickable = header.clickable;

            cells.push(cell);
        });

        return cells;
    };

    /*
      Generic validation to perform on all field types
     */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            selectedDropDown?.[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    const validateForm = () => {
        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";

            if (key === "FromDate") {
                errorMessage = checkGenericField(key, formProperties[key].required);

                if (errorMessage === "") {
                    let isD1Valid = isValid(new Date(selectedDropDown["FromDate"]));
                    let isD2Valid = isValid(new Date(selectedDropDown["ToDate"]));
                    if (isD1Valid && isD2Valid) {
                        errorMessage = compareDates(
                            selectedDropDown["FromDate"] && new Date(selectedDropDown[key]).toISOString(),
                            selectedDropDown["ToDate"] && new Date(selectedDropDown["ToDate"]).toISOString(),
                            formProperties[key].label,
                            formProperties["ToDate"].label,
                            false
                        );
                    }
                }
            } else if (key === "ToDate") {
                errorMessage = checkGenericField(key, formProperties[key].required);

                if (errorMessage === "") {
                    let isD1Valid = isValid(new Date(selectedDropDown["FromDate"]));
                    let isD2Valid = isValid(new Date(selectedDropDown["ToDate"]));
                    if (isD1Valid && isD2Valid) {
                        errorMessage = compareDates(
                            selectedDropDown["FromDate"] && new Date(selectedDropDown["FromDate"]).toISOString(),
                            selectedDropDown["ToDate"] && new Date(selectedDropDown["ToDate"]).toISOString(),
                            formProperties["FromDate"].label,
                            formProperties[key].label,
                            true
                        );
                    }
                }
            } else if (key === "TPPT") {
                errorMessage = checkGenericField(key, formProperties[key].required);
            }


            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            return false;
        } else {
            return true;
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            fetchApiData();
        }
    }
    const handleRowClick = (row) => {
        const details = searchedData.find(item => item.RRCNumber === row?.RRCNumber)
        setOrderDetails(details?.RRCorderdetail)
        setIsDetailsPageOpen(true)
    }
    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("zip.svg")}
                    text="No RRC Orders found."
                />
            ) : (
                <SimpleTable
                    handleSelection={null}
                    handleRowClick={handleRowClick}
                    onClick={() => { }}
                    dataId={"id"}
                    cells={cells}
                    headers={headers}
                    rows={searchedData}
                    isEditing={true}
                />
            );

        return data;
    };


    const getDataExport = () => {
        const newColumnOrder = ['TPPT', 'ServiceAgent', 'RRCNumber', 'ConsignmentNote', 'CreateDate', 'NoOfPallets', 'RRCQuantityReturned'];
        const newArray = removeColumnsByKey(searchedData, ["ServiceAgentName", "RRCQuantityAvailable", "RRCorderdetail"]);
        const reOrderArray = reorderColumns(newArray, newColumnOrder);
        return reOrderArray;
    }
    const serviceAgentDetails = dropdownDetails?.TpptServiceAgents.filter(item => item.TPPT === selectedDropDown?.TPPT)
    const getServiceSelected = () => {
        if (selectedDropDown?.ServiceAgent) {
            return selectedDropDown.ServiceAgent
        } else {
            return []
        }
    }
    const compareByName = (a, b) => {
        const nameA = a.TPPT.toUpperCase(); // Convert to uppercase for case-insensitive comparison
        const nameB = b.TPPT.toUpperCase();

        if (nameA < nameB) {
            return -1;
        } else if (nameA > nameB) {
            return 1;
        } else {
            return 0; // Names are equal
        }
    }
    const getSortedTPPT = dropdownDetails?.TPPTData.sort(compareByName)
    return (
        <>
            {isDetailsPageOpen ? null : <div>
                <HeaderBlock
                    title={"View RRC Order"}
                    right={
                        <Grid container spacing={1} alignItems="center" justify="flex-end">
                            <Grid item>
                                <Search
                                    searchField={"RRCNumber"}
                                    returnSearchMatches={(data) => {
                                        setSearchedData(data);
                                    }}
                                    data={originalData}
                                    searchTitle="Search RRC Orders"
                                />
                            </Grid>
                            <Grid item>
                                <ExportExcel disable={!searchedData.length} csvData={getDataExport()} fileName={`RRC Order Overview - ${searchedData?.[0]?.TPPT} - ${format(new Date(), "yyyyMMdd")}`} />
                            </Grid>
                        </Grid>
                    } />
                <PageContainer>
                    <Paper>
                        <Grid
                            container
                            item xs={12}
                            spacing={0}
                            alignItems="center" justifyContent="space-evenly"
                        >
                            <Grid item xs={2} style={{ margin: "2px" }}>
                                <Autocomplete
                                    options={getSortedTPPT ? getSortedTPPT.map((item) => item.TPPT) : []}
                                    value={selectedDropDown?.TPPT}
                                    onChange={(e, value) => handleInputChange("TPPT", value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            label="TPPT"
                                            placeholder="Select TPPT..."
                                            error={formProperties.TPPT.errorMessage !== ""}
                                            helperText={formProperties.TPPT.errorMessage}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} style={{ margin: "2px" }}>
                                <Autocomplete
                                    options={serviceAgentDetails ? serviceAgentDetails.map((item) => `${item.ServiceAgent} | ${item.ServiceAgentName}`) : []}
                                    value={getServiceSelected()}
                                    onChange={(e, value) => handleInputChange("ServiceAgent", value)}
                                    multiple={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            label="Service Agent"
                                            placeholder="Select Service Agent"
                                            error={formProperties.ServiceAgent.errorMessage !== ""}
                                            helperText={formProperties.ServiceAgent.errorMessage}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} style={{ margin: "2px", display: "flex" }}>
                                <FormControl
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="dense"
                                            disableToolbar
                                            fullWidth
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            InputProps={{ readOnly: true }}
                                            value={selectedDropDown?.FromDate ? selectedDropDown.FromDate : null}
                                            label="FromDate"
                                            error={formProperties.FromDate.errorMessage !== ""}
                                            helperText={formProperties.FromDate.errorMessage}
                                            onChange={(date) => {
                                                if (isNaN(date.getTime())) {
                                                    //date not valid, assume user is trying to enter the string manually.
                                                } else {
                                                    handleInputChange(
                                                        "FromDate",
                                                        getFormat(date)
                                                    );
                                                }
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <Grid item xs={2}>
                                    <Tooltip title="From Date can be applicable within one year limit only">
                                        <IconButton style={{ padding: "25px 5px 0px 0px" }}>
                                            <ErrorIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} style={{ margin: "2px", display: "flex" }}>
                                <FormControl
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="dense"
                                            disableToolbar
                                            fullWidth
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            InputProps={{ readOnly: true }}
                                            label="ToDate"
                                            value={selectedDropDown?.ToDate ? selectedDropDown.ToDate : null}
                                            error={formProperties.ToDate.errorMessage !== ""}
                                            helperText={formProperties.ToDate.errorMessage}
                                            onChange={(date) => {
                                                if (isNaN(date.getTime())) {
                                                    //date not valid, assume user is trying to enter the string manually.
                                                } else {
                                                    handleInputChange(
                                                        "ToDate",
                                                        getFormat(date)
                                                    );
                                                }
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <Grid item xs={2}>
                                    <Tooltip title="To Date can be applicable within one year limit only">
                                        <IconButton style={{ padding: "25px 5px 0px 0px" }}>
                                            <ErrorIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} style={{ margin: "2px" }}>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={handleSubmitClicked}
                                >
                                    Apply Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    {loading ? <Loading /> : renderData()}
                </PageContainer>
            </div>}
            {isDetailsPageOpen ? <ViewrrcOrderDetails TPPT={searchedData[0].TPPT} closeDetailsPage={() => setIsDetailsPageOpen(false)} searchedData={orderDetails} /> : null}
        </>
    )
}


const hoc = withRouter(ViewrrcOrdersPage);

// EXPORT COMPONENT
export { hoc as ViewrrcOrdersPage };