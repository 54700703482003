import {
  CREATE_ROLE,
  DELETE_ROLE,
  GET_ROLES,
  GET_ROLE,
  UPDATE_ROLE,
  GET_ROLES_FOR_USER,
  GET_ROLES_FOR_LOGGED_IN_USER,
  ASSIGN_USERS_TO_ROLE,
  REMOVE_USERS_FROM_ROLE
} from "../constants/action-types";

// INITIALIZE STATE
const initialState = {
  roles: {},
  rolesForUser: {},
  role: {},
  loggedInUserRoles: {}
};

function createRole(serverRole) {
  let role = {};

  if (serverRole) {
    role = {
      id: serverRole.RoleId,
      name: serverRole.RoleName,
      loweredRoleName: serverRole.LoweredRoleName,
      description: serverRole.Description
    };
  } else {
    role = {
      id: "",
      name: "",
      loweredRoleName: "",
      description: ""
    };
  }

  return role;
}

// REDUCER
export const RoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES: {
      let rolesList = action.payload;
      const roles = {};

      rolesList.forEach((role) => {
        roles[role.RoleId] = createRole(role);
      });

      return {
        ...state,
        roles
      };
    }
    case GET_ROLE: {
      let role = createRole(action.payload);

      return {
        ...state,
        role
      };
    }
    case CREATE_ROLE: {
      const roles = Object.assign({}, state.roles);
      const newRole = action.payload;

      roles[newRole.id] = newRole;

      return {
        ...state,
        roles
      };
    }
    case UPDATE_ROLE: {
      return {
        ...state,
        role: action.payload
      };
    }
    case DELETE_ROLE: {
      return {
        ...state
      };
    }
    case GET_ROLES_FOR_USER: {
      let rolesForUserList = action.payload;

      const rolesForUser = {};

      rolesForUserList.forEach((role) => {
        rolesForUser[role.RoleId] = createRole(role);
      });

      return {
        ...state,
        rolesForUser
      };
    }
    case GET_ROLES_FOR_LOGGED_IN_USER: {
      return {
        ...state,
        loggedInUserRoles: action.payload
      };
    }
    case ASSIGN_USERS_TO_ROLE: {
      let userIds = action.payload.userIds;

      let users = Object.assign({}, state.users);
      let usersInRole = Object.assign({}, state.usersInRole);

      userIds.forEach((userId) => {
        usersInRole[userId] = users[userId];
      });

      return {
        ...state,
        usersInRole
      };
    }
    case REMOVE_USERS_FROM_ROLE: {
      let userIds = action.payload.userIds;
      let usersInRole = Object.assign({}, state.usersInRole);

      userIds.forEach((userId) => {
        delete usersInRole[userId];
      });

      return {
        ...state,
        usersInRole
      };
    }
    default:
      return state;
  }
};
