// React
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import { Button } from "@material-ui/core";

// Components
import Modal from "../../components/dialog/Modal";
import ScrollableTabs from "../../components/tabs/Tabs";

// Containers
import GenericPicker from "../generic-picker/GenericPicker";

const TargetGroupPickerModal = (props) => {
  const [selection, setSelection] = useState([]);

  const handleSelection = (newSelection) => {
    setSelection(
      selection.concat(
        newSelection.filter((item) => selection.indexOf(item) < 0)
      )
    );
  };

  const getTabs = () => {
    return Object.keys(props.data).map((key) => {
      return {
        name: `${key[0].toUpperCase()}${key.slice(1)}`,
        key: key,
        content: (
          <GenericPicker
            selected={props.selected.map((item) => item.TargetGroupId)}
            handleSelection={handleSelection}
            searchTitle="Target Groups"
            data={props.data[key]}
            idField="id"
            valueField="name"
            currentSelection={selection}
          />
        )
      };
    });
  };

  return (
    <Modal
      title={"Target Groups"}
      open={props.open}
      fullWidth={true}
      fixedHeight={true}
      actions={
        <div>
          <Button color="secondary" onClick={props.handleCancel}>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              props.handleDone(selection);
              setSelection([]);
            }}
          >
            Done
          </Button>
        </div>
      }
    >
      <ScrollableTabs tabs={getTabs()} />
    </Modal>
  );
};

const hoc = withRouter(TargetGroupPickerModal);

// EXPORT COMPONENT
export { hoc as TargetGroupPickerModal };
