const environment = process.env.REACT_APP_ENVIRONMENT;
const flavour = process.env.REACT_APP_FLAVOUR;

const url = process.env.REACT_APP_API_URL;
const roleId = process.env.REACT_APP_CORE_RANGE_ROLE_ID;

const displayActivityType =
  process.env.REACT_APP_RED_MYDISPLAY_DISPLAY_ACTIVITY_TYPE;
const locationActivityType =
  process.env.REACT_APP_RED_MYDISPLAY_LOCATION_ACTIVITY_TYPE;
const productActivityType =
  process.env.REACT_APP_RED_MYDISPLAY_PRODUCT_ACTIVITY_TYPE;
const complianceActivityType =
  process.env.REACT_APP_RED_MYDISPLAY_COMPLIANCE_ACTIVITY_TYPE;
const competitorActivityType =
  process.env.REACT_APP_RED_MYDISPLAY_COMPETITOR_ACTIVITY_TYPE;

const locationDataType = process.env.REACT_APP_RED_MYDISPLAY_LOCATION_DATA_TYPE;

const productDataType = process.env.REACT_APP_RED_MYDISPLAY_PRODUCT_DATA_TYPE;

const useLocalhostApis = process.env.REACT_APP_USE_LOCALHOST_APIS;

export function getApiConfig() {
  if (environment !== undefined && flavour !== undefined) {
    if (useLocalhostApis !== undefined && useLocalhostApis === "true") {
      return {
        CONTENT: `http://localhost:7077/api`,
        USERS: `http://localhost:7075/api`,
        ROLES: `http://localhost:7078/api`,
        ACTIVITIES: `http://localhost:7080/api`,
        TARGETING: `http://localhost:7072/api`,
        CORERANGE: `http://localhost:6969/api`,
        CMS: `http://localhost:1220/api`,
        RETRIEVAL: `http://localhost:7079/api`,
        SYNC: `http://localhost:1234/api`,
        UPLOAD: `http://localhost:7076/api`,
        WMD: `http://localhost:7074/api`,
        RRC: `http://localhost:7080/api`,
        coreRangeRoleId: roleId,
        displayActivityType: displayActivityType,
        locationActivityType: locationActivityType,
        productActivityType: productActivityType,
        complianceActivityType: complianceActivityType,
        locationDataType: locationDataType,
        productDataType: productDataType,
        competitorActivityType: competitorActivityType,
      };
    } else {
      return {
        CONTENT: `${url}/Content`,
        USERS: `${url}/Users`,
        ACTIVITIES: `${url}/Activities`,
        TARGETING: `${url}/Targeting`,
        CORERANGE: `${url}/CoreRange`,
        CMS: `${url}/cms`,
        RETRIEVAL: `${url}/Retrieval`,
        SYNC: `${url}/Sync`,
        UPLOAD: `${url}/Upload`,
        ROLES: `${url}/Roles`,
        WMD: `${url}/WMD`,
        RRC:`${url}-rrc`,
        coreRangeRoleId: roleId,
        displayActivityType: displayActivityType,
        locationActivityType: locationActivityType,
        productActivityType: productActivityType,
        complianceActivityType: complianceActivityType,
        locationDataType: locationDataType,
        productDataType: productDataType,
        competitorActivityType: competitorActivityType,
      };
    }
  }

  return {};
}
