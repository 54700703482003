// React
import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { getApiConfig } from "../../config/apiConfig";

// Models
import {
  GenericContentModel,
  createContentModelRequestBody
} from "../../models/GenericContentModel";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./MyCCAContentPage.module.scss";
import EditingAlert from "../../components/editing-alert/EditingAlert";

// Actions
import {
  getMyccaContentById,
  getAllMyccaLookups,
  updateMyccaContent,
  uploadMyccaContent,
  getAllTypes
} from "../../state/actions/MyCCAResourceImageManagerActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";
import { createNewContent } from "../../state/reducers/MyCCAResourceImageManagerReducer";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import FileDrop from "../../components/file-drop/FileDrop";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import GenericContentUploadSegment from "../../segments/content-upload-segments/GenericContentUploadSegment";
import { FileType } from "../../state/constants/FileType";


// Models
import { MyccaContentModel } from "../../models/MyccaContentModel"
// Other
import DateFnsUtils from "@date-io/date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";
import { isValid, format, startOfDay } from "date-fns";
import { sortDropdown } from "../../helpers/sortDropdown";
import { Territories, GetSite } from "../../state/constants/Territories";

import moment from 'moment';
import { createTargetedContent } from "../../state/actions/TargetedContentActions";
moment().format();

const API_CONFIG = getApiConfig();

/*
    Component entry point
*/
const MyCCAContentUploader = (props) => {
  // Reducer Setup
  const currentMyccaContent = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.currentMyccaContent
  );

  const lookups = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.lookups
  );

  const types = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.types
  );

  const loggedInUser = useSelector(
    (state) => state.UserReducer.loggedInUser
  );

  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  // Create dispatcher.
  const dispatch = useDispatch();

  // State Objects
  const [genericContentData, setGenericContentData] = useState(
    GenericContentModel()
  );
  const [targetedContent, setTargetedContent] = useState({});
  const [localTargetedContent, setLocalTargetedContent] = useState({});
  const [uneditedTargetedContent, setUneditedTargetedContent] = useState({});
  const [formProperties, setFormProperties] = useState(MyccaContentModel);
  const [categoryLookupData, setCategoryLookupData] = useState([]);
  const [languageLookupData, setLanguageLookupData] = useState([]);
  const [retentionPolicyLookupData, setRetentionPolicyLookupData] = useState([]);
  const [typeLookupData, setTypeLookupData] = useState([]);
  const [getAllTypesCallMade, setGetAllTypesCallMade] = useState(false);

  // Page State Controllers
  const [loading, setLoading] = useState(false);
  const [
    cancelTargetedContentEditingAlertOpen,
    setCancelTargetedContentEditingAlertOpen
  ] = useState(false);
  const [isEditing, setIsEditing] = useState(
    props.type != null && props.type !== FormType.VIEW
  );
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [formDataUpdated, setFormDataUpdated] = useState(false);
  const [isNewContent, setIsNewContent] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [selectedContentTypeId, setSelectedContentTypeId] = useState("");
  const [ignoreMaxFileSize, setIgnoreMaxFileSize] = useState(false);
  const [isContentAdmin, setIsContentAdmin] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(9999);
  const [
    getAllMyccaLookupsCallMade,
    setgetAllMyccaLookupsCallMade
  ] = useState(false);
  const [getTargetedContentCallMade, setGetTargetedContentCallMade] = useState(
    false
  );
  const [imageValidationErrors, setImageValidationErrors] = useState({});

  const targetedContentId = props.match.params.contentId;

  useEffect(() => {
    
    regenerateLocalContent();

    setLoading(true);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }

    //Get all dropdown lookup data
    dispatch(getAllMyccaLookups(() => {
      setgetAllMyccaLookupsCallMade(true);
    }));

    // Get all types (needed for displaying size reqs on the uploaders).
    dispatch(getAllTypes(() => {
      setGetAllTypesCallMade(true);
    }));

    //If not creating
    if (props.type != null && props.type === FormType.VIEW) {
      setIsEditing(false);
      setIsNewContent(false);
      dispatch(

        getMyccaContentById(targetedContentId, () => {
          setGetTargetedContentCallMade(true);
        })
      );
    } else {
      setIsEditing(true);
      setIsNewContent(true);

      dispatch(
        getMyccaContentById(undefined, () => {
          setGetTargetedContentCallMade(true);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      loggedInUserRoles !== undefined &&
      (loggedInUserRoles.includes("Content Admin") ||
        loggedInUserRoles.includes("SaM Core Admin") ||
        loggedInUserRoles.includes("Content Manager") ||
        loggedInUserRoles.includes("Content Targeting Manager"))
    ) {
      setIsContentAdmin(true);
    } else {
      setIsContentAdmin(false);
    }
  }, [loggedInUserRoles]);

  useEffect(() => {
    if (loggedInUser != null) {
      let localData = targetedContent;
      if (Object.keys(localData).length === 0) { localData = createNewContent() }
      localData["site"] = loggedInUser.site;
      setTargetedContent(localData);
    }
  }, [loggedInUser]);

  useEffect(() => {
    const localData = currentMyccaContent;
    if (isNewContent) {
      localData.site = loggedInUser.site;
    }
    setTargetedContent(localData);
    // Need to set the link text if the content type is Links
    if (
      targetedContent.contentType &&
      typeLookupData.find((type) => type.Id === targetedContent.contentType)
        .DisplayName === "Links"
    ) {
      localData["link"] =
        !isNewContent && currentMyccaContent.file !== null
          ? currentMyccaContent.file[0].resourceUrl
          : "";
    }
    setTargetedContent(localData);

    if (!isNewContent) {
      setSelectedContentTypeId(currentMyccaContent.contentType);
    } else {
      setSelectedContentTypeId(1);
    }
  }, [currentMyccaContent]);

  useEffect(() => {
    setDataChanged(!dataChanged);
  }, [targetedContent]);

  useEffect(() => {
    if (isEditing) {
      setSubmitEnabled(true);
    }
  }, [isEditing]);

  useEffect(() => {
    setCategoryLookupData(
      lookups.CategoryLookups !== undefined
        ? lookups.CategoryLookups.sort()
        : []
    );
    setLanguageLookupData(lookups.LanguageLookups);
    setRetentionPolicyLookupData(lookups.RetentionPolicyLookups);
    setTypeLookupData(
      lookups.TypeLookups !== undefined ? lookups.TypeLookups.sort() : []
    );
  }, [lookups]);

  useEffect(() => {
    if (
      categoryLookupData.length > 0 &&
      languageLookupData.length > 0 &&
      retentionPolicyLookupData.length > 0 &&
      typeLookupData.length > 0
    ) {
      if (props.type != null && props.type === FormType.CREATE) {
        let localData = targetedContent;
        if (Object.keys(localData).length === 0) { localData = createNewContent(); }
        localData["site"] = loggedInUser.site;
        setTargetedContent(localData);
        const lang = languageLookupData.find(
          (data) => data.DisplayName === "en"
        );

        if (lang !== undefined) {
          localData["language"] = [lang.Id];
          localData["site"] = loggedInUser.site;
          if (!formEdited) {
            setFormEdited(true);
          }
          setTargetedContent(localData);

          // Forces a rerender to display updated inout data
          setDataChanged(!dataChanged);
        }
      }

      setgetAllMyccaLookupsCallMade(true);
    }
  }, [
    categoryLookupData,
    languageLookupData,
    retentionPolicyLookupData,
    typeLookupData
  ]);

  useEffect(() => {
    if (
      loggedInUserCallMade &&
      getAllMyccaLookupsCallMade &&
      getTargetedContentCallMade
    ) {
      setLoading(false);
    }
  }, [
    loggedInUserCallMade,
    getAllMyccaLookupsCallMade,
    getTargetedContentCallMade
  ]);

  // Rerender certain components on selected id changing.
  const showUploadComponents = () => {
    let renderMultiMode;

    if (lookups.data != undefined) {
      renderMultiMode = lookups.data.types.filter(function (i) {
        return i.code == selectedContentTypeId;
      }).map(function (si) {
        return si.extra
      })[0]
    }

    if (renderMultiMode) {
      return (
        <Fragment>
          {renderUploadComponent("Desktop", 4)}
          {renderUploadComponent("Tablet", 4)}
          {renderUploadComponent("Mobile", 4)}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {renderUploadComponent("Source", 12)}
        </Fragment>
      )
    }
  }

  const renderUploadComponent = (title, size) => {
    // Get the active types size data first.
    let sizeData = types.data?.filter(function (x) {
      return x.id == targetedContent.data?.base.typeId
    });

    // Renders in an interactive upload box.
    return (
      <Grid
        item
        xs={size}
        style={{
          display: isNewContent ? "block" : "none"
        }}
      >
        {sizeData != undefined && sizeData.length !== 0 ?
          sizeData[0][title.toLowerCase() + 'Width'] != 10000 ?
            <Typography h2><b>{title}</b> ( {sizeData[0][title.toLowerCase() + 'Width']} x {sizeData[0][title.toLowerCase() + 'Height']} )</Typography> :
            <Typography h2><b>{title}</b> ( <i>No Size Limit</i> )</Typography> :
          <Typography h2><b>Please select a content type.</b></Typography>
        }
        <FileDrop
          disabled={sizeData != undefined && sizeData.length !== 0 ? false : true}
          enforceTypes={[FileType.jpeg, FileType.png, FileType.pdf]}
          enforceTypesMessage={
            "This type does not support this format."
          }
          badSizeMessage={
            "Image dimensions incorrect."
          }
          message={sizeData != undefined && sizeData.length !== 0 ? "Add files" : <i>No content type selected.</i>}
          onFilesAdded={(file) => handleInputChange(`${title}-file`, file)}
          file={targetedContent.data != undefined ? targetedContent.data?.base[`${title}-file`] : null}
          multiple={false}
          errorMessage={
            genericContentData.file && genericContentData.file.errorMessage
          }
          maxFileSize={sizeLimit}
          maxDimensions={{
            height: sizeData != undefined && sizeData.length !== 0 ? sizeData[0][title.toLowerCase() + 'Height'] : 0,
            width: sizeData != undefined && sizeData.length !== 0 ? sizeData[0][title.toLowerCase() + 'Width'] : 0
          }}
        />
      </Grid>
    )
  }

  // Whenever a change to any of the form fields happens this method is called to update the state of the targetedContent
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    if (localTargetedContent.data == undefined) {
      regenerateLocalContent();
    }
    const localData = localTargetedContent;

    // handle file slightly differently.
    if (name.includes("file")) {
      localData.data.base[name] = value;
    } else {
      localData.data.base[name] = value;
    }


    if (!formEdited) {
      setFormEdited(true);
    }

    setTargetedContent(localData);

    // Forces a rerender to display updated inout data
    setDataChanged(!dataChanged);
  };

  // Validate the field data then update the localFormData with any new error messages
  const checkFieldIsValid = (required, value, type, fieldName, fieldLabel) => {
    let localFormProperties = formProperties;
    const errorMessage = validateField(required, value, type, fieldLabel);

    localFormProperties[fieldName].errorMessage = errorMessage;

    setFormProperties(localFormProperties);

    return errorMessage;
  };

  // Validate the form using the fields from TargetedContentModel
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      if (key === "endDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);
      }

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    setFormProperties(localProperties);
    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setFormDataUpdated(!formDataUpdated);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  const checkGenericField = (key, required) => {
    return checkFieldIsValid(
      required,
      targetedContent.data.base[key],
      formProperties[key].type,
      key,
      formProperties[key].label
    );
  };

  const regenerateLocalContent = () => {
    let emptyContent = {
      data: {
        base: {},
        meta: {}
      }
    }

    emptyContent.data.base = {
      id: "",
      code: "",
      description: "",
      contentType: "",
      active: true,
      site: "",
      endDate: new Date(),
      retentionPolicyId: ""
    };

    setLocalTargetedContent(emptyContent);

    return true;
  }

  /* 
    Generate the formdata request before forwarding it to the action manager.
    */
  const buildAndSendUploadRequest = () => {
    // Define the formdata
    let formData = new FormData();

    // Determine if we're in multimode
    let renderMultiMode;

    if (lookups.data != undefined) {
      renderMultiMode = lookups.data.types.filter(function (i) {
        return i.code == selectedContentTypeId;
      }).map(function (si) {
        return si.extra
      })[0]

      if (renderMultiMode) {
        // Load in all possible size args.
        formData.append("source", targetedContent.data.base["Desktop-file"][0]); // Forced to accomodate backend. It literally just prevents a crash in BE.
        formData.append("desktop", targetedContent.data.base["Desktop-file"][0]);
        formData.append("tablet", targetedContent.data.base["Tablet-file"][0]);
        formData.append("mobile", targetedContent.data.base["Mobile-file"][0]);
      } else {
        // Load in only the source arg.
        formData.append("source", targetedContent.data.base["Source-file"][0]);
      }
    }

    // Build the form's normal field data by ripping it off DOM elements.
    formData.append("Site", targetedContent.data.base.site);
    formData.append("Code", targetedContent.data.base.code);
    formData.append("Description", targetedContent.data.base.description);
    formData.append("EndDate", new Date(targetedContent.data.base.endDate.getTime() - (targetedContent.data.base.endDate.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0]);
    formData.append("RetentionPolicy", targetedContent.data.base.retentionPolicyId);
    formData.append("Type", targetedContent.data.base.typeId);
    formData.append("Author", loggedInUser.userName);
    formData.append("Active", targetedContent.data.base.active);

    // Send request out via its action.
    dispatch(
      uploadMyccaContent(formData, () => {
        //setGetTargetedContentCallMade(true);
      })
    );
  }

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (dataChanged) {
      setCancelTargetedContentEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (isNewContent) {
        props.history.push(`/content-upload`);
      }
    }
  };

  /*
    Display the cancel editing alert dialog
  */
  const cancelTargetedContentEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to cancel?`}
        description={"Any progress or images added will be lost."}
        options={[
          {
            label: "No",
            action: () => setCancelTargetedContentEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setCancelTargetedContentEditingAlertOpen(false);

              if (isNewContent) {
                props.history.push(`/mycca-content`);
              } else {
                setTargetedContent(uneditedTargetedContent);
              }
            }
          }
        ]}
        isOpen={cancelTargetedContentEditingAlertOpen}
      />
    );
  };

  /*
    Display all the page data
  */
  const renderData = () => {
    if (!localTargetedContent) {
      return <div />;
    }
    let isLinkContentType = false;

    if (Object.keys(localTargetedContent).length > 0) {
      const foundItem =
        localTargetedContent.contentType !== undefined &&
          typeLookupData !== undefined
          ? typeLookupData.find(
            (type) => type.Id === localTargetedContent.contentType
          )
          : undefined;

      if (foundItem != null && foundItem?.SizeLimit == null) { foundItem.SizeLimit = 9999 }
      if (foundItem != null && foundItem?.SizeLimit != sizeLimit) {
        foundItem.SizeLimit != null ?
          setSizeLimit(foundItem.SizeLimit) :
          setSizeLimit(9999);
      }

      isLinkContentType =
        localTargetedContent.contentType &&
        foundItem !== undefined &&
        foundItem.DisplayName === "Links";
    }

    let link = "";

    if (isLinkContentType) {
      if (localTargetedContent.link !== undefined) {
        link = localTargetedContent.link;
      } else if (
        localTargetedContent.file &&
        localTargetedContent.file[0].resourceUrl !== undefined
      ) {
        link = localTargetedContent.file[0].resourceUrl;
      } else {
        link = "";
      }
    }

    return (
      <Fragment>
        <EditingAlert
          isEditing={isEditing}
          title={"Information"}
          severity={"info"}
          body={["Note that", <b> Code, Site, Content Type, </b>, "and any", <b> Images </b>, "uploaded will not be editable after submission."]}
        />
        <div className={styles.contentGrid}>
          <CustomCard
            title="Main Details"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DropdownSelect
                  disabled={!isNewContent}
                  label="Site"
                  handleChange={(value) => {
                    handleInputChange("site", value);
                  }}
                  data={[
                    {
                      key: 1,
                      value: GetSite(1)
                    },
                    {
                      key: 2,
                      value: GetSite(2)
                    }
                  ]
                  }
                  value={
                    localTargetedContent.data != undefined ?
                      localTargetedContent?.data.base.site
                      : "nz"
                  }
                  valueName={"site"}
                  error={
                    formProperties.site &&
                    formProperties.site.errorMessage !== ""
                  }
                  fullWidth={true}
                >
                  {formProperties.site &&
                    formProperties.site.errorMessage !== "" ? (
                      <FormHelperText>
                        {formProperties.site.errorMessage}
                      </FormHelperText>
                    ) : null}
                </DropdownSelect>
              </Grid>

              <Grid item xs={12} md={6}>
                <DropdownSelect
                  disabled={!isNewContent}
                  label="Content Type"
                  handleChange={(value) => {
                    setSelectedContentTypeId(value);
                    handleInputChange("typeId", value);
                  }}
                  data={
                    lookups.data !== undefined && lookups.data.types.length > 0
                      ? lookups.data.types.map((t) => {
                        return { key: t.code, value: t.value };
                      })
                      : []
                  }
                  value={
                    lookups.data !== undefined &&
                      lookups.data.types.length > 0
                      ? localTargetedContent.data != undefined ? localTargetedContent.data.base.typeId : ""
                      : ""
                  }
                  valueName={"contentType"}
                  error={formProperties.contentType.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.contentType.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.contentType.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              {showUploadComponents()}
              <Grid item xs={12} md={12}>
                <TextField
                  disabled={!isNewContent}
                  id="code"
                  name="code"
                  label="Code"
                  placeholder="Code"
                  value={
                    localTargetedContent.data != undefined ?
                      localTargetedContent.data.base.code ? localTargetedContent.data.base.code : ""
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange("code", event.target.value)
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.code.errorMessage !== ""}
                  helperText={formProperties.code.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="description"
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  value={
                    localTargetedContent.data != undefined ?
                      localTargetedContent.data.base.description ? localTargetedContent.data.base.description : ""
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange("description", event.target.value)
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.description.errorMessage !== ""}
                  helperText={formProperties.description.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DropdownSelect
                  disabled={!isEditing}
                  label="Retention Policy"
                  handleChange={(value) => {
                    handleInputChange("retentionPolicyId", value);
                  }}
                  data={
                    lookups.data !== undefined &&
                      lookups.data.retentionPolicies.length > 0
                      ? lookups.data.retentionPolicies.map((t) => {
                        return { key: t.code, value: t.value };
                      })
                      : []
                  }
                  value={
                    lookups.data !== undefined &&
                      lookups.data.retentionPolicies.length > 0
                      ? localTargetedContent.data != undefined ? localTargetedContent.data.base.retentionPolicyId : ""
                      : ""
                  }
                  valueName={"retentionPolicy"}
                  error={formProperties.retentionPolicy.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.retentionPolicy.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.retentionPolicy.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label="Effective To"
                    value={localTargetedContent.data != undefined ? localTargetedContent.data.base.endDate : new Date()}
                    onChange={(date) => {
                      handleInputChange(
                        "endDate",
                        date);
                    }}
                    error={formProperties.endDate.errorMessage !== ""}
                    helperText={formProperties.endDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label="Active"
                  control={
                    <Checkbox
                      checked={
                        localTargetedContent.data != undefined ?
                          localTargetedContent.data.base.isActive != undefined
                            ? localTargetedContent.data.base.isActive
                            : true
                          : true
                      }
                      onChange={() =>
                        handleInputChange("isActive", !localTargetedContent.data.base.isActive)
                      }
                      name="active"
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <HeaderBlock
        title={
          props.type === FormType.VIEW
            ? localTargetedContent != null
              ? `MyCCA Content - ${localTargetedContent.data != undefined ? localTargetedContent.data.base.code : "New"}`
              : ""
            : "New Content"
        }
        right={
          <Fragment>

            <Grid container spacing={1} alignItems="center" justify="flex-end">
              <Grid item>
                {props.type != null && props.type === FormType.VIEW ? (
                  <ButtonGroup>
                    {isEditing ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleCancelEditButtonClicked(true)}
                      >
                        Cancel
                      </Button>
                    ) : null}
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        if (isEditing) {
                          buildAndSendUploadRequest();
                        } else {
                          setUneditedTargetedContent(localTargetedContent);
                          setIsEditing(true);
                        }
                      }}
                    >
                      {isEditing ? "Submit" : "Edit"}
                    </Button>
                  </ButtonGroup>
                ) : (
                    <ButtonGroup>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleCancelEditButtonClicked(true)}
                      >
                        Cancel
                  </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          buildAndSendUploadRequest();
                        }}
                      >
                        Submit
                  </Button>
                    </ButtonGroup>
                  )}
              </Grid>
            </Grid>
          </Fragment>
        }
      />

      {cancelTargetedContentEditingAlert()}

      <PageContainer hasHelp={props.type !== FormType.VIEW}>
        {renderData()}
      </PageContainer>
    </Fragment>

  );
};

const hoc = withRouter(MyCCAContentUploader);

// EXPORT COMPONENT
export { hoc as MyccaContentUploader };
