// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import { Box, Button, ButtonGroup, Grid } from "@material-ui/core";
import styles from "./MigrationPage.module.scss";

// Actions
import { createMassContent } from "../../state/actions/ResourceImageUploaderActions";
import { getSizeMetadata } from "../../state/actions/ResourceImageUploaderActions";
import { getAllLookupContent } from "../../state/actions/TargetedContentActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Models
import { createMassMigrationRequestBody } from "../../models/GenericContentModel";
import { createImageSizeRequestBody } from "../../models/ImageSizeDataModel";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/circleLoader";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import MassContentUploadSegment from "../../segments/content-upload-segments/MigrationContentUploadSegment";
import { UUID } from "../../helpers/uuid";

// Other
import { ImageMigrationExpectedColumns } from "../../state/constants/TemplateColumns";

// Moment
var moment = require("moment");
moment().format();

const ImageMigrationPage = (props) => {
  const [genericContentData, setGenericContentData] = useState({
    prefile: [
      {
        type: "file",
        value: null,
        required: true,
        errorMessage: "",
        label: "File"
      }
    ],
    file: []
  });
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [cancelAlertShowing, setCancelAlertShowing] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [imageSizeMetaData, setImageSizeMetadata] = useState([]);
  const [typeLookupData, setTypeLookupData] = useState([]);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [sizeMetadataCallMade, setSizeMetadataCallMade] = useState(false);
  const [
    getAllLookupContentCallMade,
    setGetAllLookupContentCallMade
  ] = useState(false);
  const [csvInvalid, setCSVInvalid] = useState(false);

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const targetIds = useSelector(
    (state) => state.ResourceImageUploaderReducer.targetIds
  );
  const sizeData = useSelector(
    (state) => state.ResourceImageUploaderReducer.sizeData
  );
  const lookupData = useSelector(
    (state) => state.TargetedContentReducer.lookupData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }

    dispatch(
      getSizeMetadata(() => {
        setSizeMetadataCallMade(true);
      })
    );

    dispatch(
      getAllLookupContent("imagesonly", () => {
        setGetAllLookupContentCallMade(true);
      })
    );
  }, []);

  useEffect(() => {
    if (
      (loggedInUserCallMade, sizeMetadataCallMade, getAllLookupContentCallMade)
    ) {
      setLoading(false);
    }
  }, [loggedInUserCallMade, sizeMetadataCallMade, getAllLookupContentCallMade]);

  useEffect(() => {
    setImageSizeMetadata(sizeData);
  }, [sizeData]);

  useEffect(() => {
    setTypeLookupData(lookupData.TypeLookups);
  }, [lookupData]);

  useEffect(() => {
    if (targetsEdited) {
      setSubmitEnabled(true);
    }
  }, [targetsEdited]);

  useEffect(() => {
    if (dataChanged) {
      setDataChanged(false);
    }
  }, [dataChanged]);

  useEffect(() => {
    let localTargets = [];
    setCsvData(localTargets);
  }, [targetIds]);

  const handleSubmit = async () => {
    setIsUploading(true);
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    const data = createRequestBody();

    dispatch(
      createMassContent(data, genericContentData.file, (res) => {
        if (res) {
          props.history.push("/post-migration");
        }
      })
    );
  };

  const createRequestBody = () => {
    let toReturn = [];

    csvData.forEach((row) => {
      const requestBody = {};
      requestBody["content"] = createMassMigrationRequestBody(row, loggedInUser.territory);
      requestBody["targets"] = row.TargetIds.split("|")
        .join(",")
        .split(",")
        .map(Function.prototype.call, String.prototype.trim);

      const type =
        typeLookupData === undefined
          ? ""
          : typeLookupData.find(
            (type) =>
              type.DisplayName.toLowerCase() === row.ContentType.toLowerCase()
          );

      requestBody["imageSizes"] = createImageSizeRequestBody(
        type.Id,
        imageSizeMetaData
      );
      requestBody["UploadedBy"] =
        loggedInUser.userName == null ? "Unknown" : loggedInUser.userName;
      requestBody["UploadedOn"] = new Date().toISOString();
      toReturn.push(requestBody);
    });
    return toReturn;
  };

  // This refers to the cancel button on the edit or create form
  const handleCancelEditButtonClicked = () => {
    setIsEditing(false);
    props.history.push(`/image-upload`);
  };

  const cancelAlertDialog = () => {
    const title = "Are you sure you want to go back?";
    return (
      <AlertDialog
        title={title}
        description={"Any changes made will be lost."}
        options={[
          {
            label: "Cancel",
            action: () => setCancelAlertShowing(false)
          },
          {
            label: "Confirm",
            action: () => {
              props.history.replace(`/image-uploader`);
              setIsEditing(false);
              setCancelAlertShowing(false);
            }
          }
        ]}
        isOpen={cancelAlertShowing}
      />
    );
  };

  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleSubmit()}
        disabled={!submitEnabled || csvInvalid}
      >
        Submit
      </Button>,
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleCancelEditButtonClicked()}
      >
        Cancel
      </Button>
    ];
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      <Fragment>
        {isUploading ? (
          <>
            <HeaderBlock title={"Please Wait: Images are being migrated"} />
            <PageContainer>
              <LoadingComponent></LoadingComponent>
            </PageContainer>
          </>
        ) : (
            <>
              <HeaderBlock
                title={"Image Mass Upload"}
                right={
                  renderSubmitAndCancelButtons() ? (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justify="flex-end"
                    >
                      <Grid item>
                        <ButtonGroup>
                          {renderSubmitAndCancelButtons()}
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  ) : null
                }
              />

              <PageContainer>
                <div className={styles.contentGrid}>
                  <Grid container spacing={3}>
                    <Grid key={"migrationUploadSegment"} item xs={12}>
                      <MassContentUploadSegment
                        genericContentData={genericContentData}
                        setGenericContentData={setGenericContentData}
                        setDataChanged={setDataChanged}
                        title="Image Upload"
                        isEditing={isEditing}
                        formEdited={formEdited}
                        setFormEdited={setFormEdited}
                        isNewContent={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid key={"migrationUploadSegment"} item xs={12}>
                      <MigrationUploadSegment
                        csvData={csvData}
                        setCsvData={setCsvData}
                        setDataChanged={setDataChanged}
                        title="Image Metadata CSV Upload"
                        targetsEdited={targetsEdited}
                        setTargetsEdited={setTargetsEdited}
                        isEditing={isEditing}
                        setFormEdited={setFormEdited}
                        description="Please upload a CSV containing the Metadata that you would like to attach to the uploaded files"
                        templateFileName="ContentTemplate.csv"
                        expectedColumns={ImageMigrationExpectedColumns}
                        setCSVInvalid={setCSVInvalid}
                      />
                    </Grid>
                  </Grid>
                </div>
                {cancelAlertDialog()}
              </PageContainer>
            </>
          )}
      </Fragment>
    </Box>
  );
};

const hoc = withRouter(ImageMigrationPage);

// EXPORT COMPONENT
export { hoc as ImageMigrationPage };
