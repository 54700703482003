function createTargetFields() {
  var object = {};

  object.ACTIVITY = {
    NAME: "Activity",
    FIELD: {},
    RULE: {}
  };
  object.CUSTOMER = {
    NAME: "Customer",
    FIELD: {},
    RULE: {}
  };
  object.MATERIAL = {
    NAME: "Material",
    FIELD: {},
    RULE: {}
  };

  object.PROMOTION = {
    NAME: "Promotion",
    FIELD: {},
    RULE: {}
  };

  object.REDCHANNEL = {
    NAME: "Redchannel",
    FIELD: {},
    RULE: {}
  };

  //Activity Rules
  object.ACTIVITY.RULE.Activity_Id = {
    key: "Activity_Id",
    value: "Activity Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.ACTIVITY.RULE.Activity_Category = {
    key: "Activity_Category",
    value: "Activity Category",
    FilterType: ["Equals", "Equals Any"]
  };

  object.ACTIVITY.RULE.Activity_Type = {
    key: "Activity_Type",
    value: "Activity Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.ACTIVITY.RULE.Activity_Group = {
    key: "Activity_Group",
    value: "Activity Group",
    FilterType: ["Equals", "Equals Any"]
  };

  //Customer Rules

  object.CUSTOMER.RULE.Customer_Number = {
    key: "Customer_Number",
    value: "Number",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_City = {
    key: "Customer_City",
    value: "City",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_RegionalCode = {
    key: "Customer_RegionalCode",
    value: "Regional Code",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_PostalCode = {
    key: "Customer_PostalCode",
    value: "Postal Code",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_PrecinctId = {
    key: "Customer_PrecinctId",
    value: "Precinct Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_MarketType = {
    key: "Customer_MarketType",
    value: "Market Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_TradeChannel = {
    key: "Customer_TradeChannel",
    value: "Trade Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_SubTradeChannel = {
    key: "Customer_SubTradeChannel",
    value: "Sub Trade Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_ClocCode = {
    key: "Customer_ClocCode",
    value: "Cloc Code",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_BusinessOwnerId = {
    key: "Customer_BusinessOwnerId",
    value: "Business Owner Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_TradeNameId = {
    key: "Customer_TradeNameId",
    value: "Trade Name Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_Type = {
    key: "Customer_Type",
    value: "Customer Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_BusinessType = {
    key: "Customer_BusinessType",
    value: "Business Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_OperationalRegion = {
    key: "Customer_OperationalRegion",
    value: "Operational Region",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_LicenseType = {
    key: "Customer_LicenseType",
    value: "License Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_GlobalCustomerNumber = {
    key: "Customer_GlobalCustomerNumber",
    value: "Global Customer Number",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_ServicePolicy = {
    key: "Customer_ServicePolicy",
    value: "Service Policy",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_SalesPolicy = {
    key: "Customer_SalesPolicy",
    value: "Sales Policy",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_PreferredOrderMethod = {
    key: "Customer_PreferredOrderMethod",
    value: "Preferred Order Method",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_SalesOffice = {
    key: "Customer_SalesOffice",
    value: "Sales Office",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_PriceBook = {
    key: "Customer_PriceBook",
    value: "Price Book",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_DistributionChannel = {
    key: "Customer_DistributionChannel",
    value: "Distribution Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_BlackChannel = {
    key: "Customer_BlackChannel",
    value: "Black Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_RedChannel = {
    key: "Customer_RedChannel",
    value: "Red Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_Level2Customer = {
    key: "Customer_Level2Customer",
    value: "Level 2 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_Level3Customer = {
    key: "Customer_Level3Customer",
    value: "Level 3 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_Level4Customer = {
    key: "Customer_Level4Customer",
    value: "Level 4 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  object.CUSTOMER.RULE.Customer_Level5Customer = {
    key: "Customer_Level5Customer",
    value: "Level 5 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  //Redchannel Customer Rules

  object.REDCHANNEL.RULE.Redchannel_Number = {
    key: "Redchannel_Number",
    value: "Number",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_City = {
    key: "Redchannel_City",
    value: "City",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_RegionalCode = {
    key: "Redchannel_RegionalCode",
    value: "Regional Code",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_PostalCode = {
    key: "Redchannel_PostalCode",
    value: "Postal Code",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_PrecinctId = {
    key: "Redchannel_PrecinctId",
    value: "Precinct Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_MarketType = {
    key: "Redchannel_MarketType",
    value: "Market Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_TradeChannel = {
    key: "Redchannel_TradeChannel",
    value: "Trade Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_SubTradeChannel = {
    key: "Redchannel_SubTradeChannel",
    value: "Sub Trade Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_ClocCode = {
    key: "Redchannel_ClocCode",
    value: "Cloc Code",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_BusinessOwnerId = {
    key: "Redchannel_BusinessOwnerId",
    value: "Business Owner Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_TradeNameId = {
    key: "Redchannel_TradeNameId",
    value: "Trade Name Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_Type = {
    key: "Redchannel_Type",
    value: "Customer Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_BusinessType = {
    key: "Redchannel_BusinessType",
    value: "Business Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_OperationalRegion = {
    key: "Redchannel_OperationalRegion",
    value: "Operational Region",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_LicenseType = {
    key: "Redchannel_LicenseType",
    value: "License Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_GlobalCustomerNumber = {
    key: "Redchannel_GlobalCustomerNumber",
    value: "Global Customer Number",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_ServicePolicy = {
    key: "Redchannel_ServicePolicy",
    value: "Service Policy",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_SalesPolicy = {
    key: "Redchannel_SalesPolicy",
    value: "Sales Policy",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_PreferredOrderMethod = {
    key: "Redchannel_PreferredOrderMethod",
    value: "Preferred Order Method",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_SalesOffice = {
    key: "Redchannel_SalesOffice",
    value: "Sales Office",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_PriceBook = {
    key: "Redchannel_PriceBook",
    value: "Price Book",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_DistributionChannel = {
    key: "Redchannel_DistributionChannel",
    value: "Distribution Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_BlackChannel = {
    key: "Redchannel_BlackChannel",
    value: "Black Channel",
    FilterType: ["Equals", "Equals Any"]
  };

  // Can not target a redchannel by redchannel.
  // object.CUSTOMER.RULE.Redchannel_RedChannel = {
  //   key: "Redchannel_RedChannel",
  //   value: "Red Channel",
  //   FilterType: ["Equals", "Equals Any"]
  // };

  object.REDCHANNEL.RULE.Redchannel_Level2Customer = {
    key: "Redchannel_Level2Customer",
    value: "Level 2 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_Level3Customer = {
    key: "Redchannel_Level3Customer",
    value: "Level 3 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_Level4Customer = {
    key: "Redchannel_Level4Customer",
    value: "Level 4 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  object.REDCHANNEL.RULE.Redchannel_Level5Customer = {
    key: "Redchannel_Level5Customer",
    value: "Level 5 Customer",
    FilterType: ["Equals", "Equals Any"]
  };

  //Material Rules

  object.MATERIAL.RULE.Material_Number = {
    key: "Material_Number",
    value: "Material Number",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_ProductType = {
    key: "Material_ProductType",
    value: "Material Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_Category = {
    key: "Material_Category",
    value: "Category",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_Flavour = {
    key: "Material_Flavour",
    value: "Flavour",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_Brand = {
    key: "Material_Brand",
    value: "Brand",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_PackSize = {
    key: "Material_PackSize",
    value: "Pack Size",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.MaterialType = {
    key: "MaterialType",
    value: "Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_PackType = {
    key: "Material_PackType",
    value: "Pack Type",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_Group1Id = {
    key: "Material_Group1Id",
    value: "Group 1 Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_Group3Id = {
    key: "Material_Group3Id",
    value: "Group 3 Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_MyCcaMaterialCategoryId = {
    key: "Material_MyCcaMaterialCategoryId",
    value: "MyCCA Material Category Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_MyCcaProductTypeId = {
    key: "Material_MyCcaProductTypeId",
    value: "MyCCA Material Type Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.MATERIAL.RULE.Material_GroceryProductGroup = {
    key: "Material_GroceryProductGroup",
    value: "Grocery Product Group",
    FilterType: ["Equals", "Equals Any"]
  };

  //Promotion Rules
  object.PROMOTION.RULE.Promotion_Id = {
    key: "Promotion_Id",
    value: "Promotion Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.PROMOTION.RULE.Promotion_TypeId = {
    key: "Promotion_TypeId",
    value: "Type Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.PROMOTION.RULE.Promotion_ActivationTypeId = {
    key: "Promotion_ActivationTypeId",
    value: "Activation Type Id",
    FilterType: ["Equals", "Equals Any"]
  };

  object.PROMOTION.RULE.Promotion_OrderMethod = {
    key: "Promotion_OrderMethod",
    value: "Order Method",
    FilterType: ["Equals", "Equals Any"]
  };

  return Object.freeze(object);
}

export const TargetFields = createTargetFields();
