import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../styles/style.css";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import { CoreRangeExpectedColumns, CoreRangeColumn } from "../../state/constants/TemplateColumns";
import CustomCard from "../../components/custom-card/CustomCard";
import ErrorIcon from "@material-ui/icons/Error";


import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, DeleteForever, Edit, FileCopy } from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Modal from "../../components/dialog/Modal";

const useStyles = makeStyles({
  sticky: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    backgroundColor: "yellow",
    padding: "50px",
    fontSize: "20px",
    alignSelf: "flex-start",
  },
  head: {
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
  },
  table: {
    minWidth: 500,
  },
  tableContainer: {
    display: "flex",
    flexDirection: "row",
    height: "80vh",
    overflowX: "scroll",
  },
  headerLabelLeft: {
    borderLeft: "2px solid lightgrey",
    fontSize: "9.4px",
  },
  headerLabelRight: {
    fontSize: "9.4px",
  },
  versionLabel: {
    borderLeft: "2px solid lightgrey",
  },
  doorLabel: {
    borderLeft: "2px solid lightgrey",
  },

  yellow: {
    backgroundColor: "#FBC34566",
    "&:hover": {
      backgroundColor: "#FBC345 !important",
    },
  },
  pink: {
    backgroundColor: "#EE696866",
    "&:hover": {
      backgroundColor: "#EE6968 !important",
    },
  },
  blue: {
    backgroundColor: "#4545B566",
    "&:hover": {
      backgroundColor: "#4545B5 !important",
      color: "#FFF",
    },
  },
  highlightRow: {
    backgroundColor: "#e96564",
    height: 58,
  },
  tableCell: {
    height: 58,
  },
  tableRow: {
    height: 58,
  },
  tableHead: {
    maxHeight: 53,
    minHeight: 53,
  },
  noSquish: {
    width: "5%",
  },
  sticky2: {
    width: "35%",
  },
  status: {
    width: "10%",
  },
  range: {
    width: "20%",
  },
  order: {
    width: "20%",
  },
  baseItem: { minWidth: "max-content" },
});

const CoreRangeProductsTable = (props) => {
  const defaultProps = () => {
    return {
      options: isCustomTargetGroup ? props.materialTargets : props.productData,
      getOptionLabel: isCustomTargetGroup
        ? (option) => `${option.Description}`
        : (option) => `${option.Number}: ${option.Description}`,
    };
  };

  const [isEditingProducts, setIsEditingProducts] = useState(false);
  const [isCustomTargetGroup, setIsCustomTargetGroup] = useState(false);
  const [copyVersionDialogOpen, setCopyVersionDialogOpen] = useState(false);
  const [newDoorName, setNewDoorName] = useState("");
  const [bulkAddIds, setBulkAddIds] = useState(""); // Items entered in the Edit Products -> Target Ids input
  const [selectedProducts, setSelectedProducts] = useState([]); // Anything 'checked; in the products list.
  const [currentlySelectedToCopy, setCurrentlySelectedToCopy] = useState({
    door: 0,
    version: 0,
  });
  const [modalSelectedItems, setModalSelectedItems] = useState({
    door: null,
    version: null,
  });
  const ProductList = useSelector((state) => state.CoreRangeReducer.Products);
  const TargetGroupList = useSelector(
    (state) => state.TargetGroupReducer.targetGroups
  );
  const classes = useStyles();

  const rowRef = useRef();
  const [height, setHeight] = useState(0);

  const [isBulkUploadModel, setIsBulkUploadModel] = useState(false);
  const [isErrorScreenVisible, setIsErrorScreenVisible] = useState(false);

  // Bulk Upload

  const [csvData, setCsvData] = useState({});
  const [csvInvalidError, setCsvInvalidError] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    if (rowRef.current) {
      setHeight(Math.max(rowRef.current.clientHeight));
    }
  }, [rowRef?.current?.clientHeight]);

  const productsByDescription = []
    .concat(props.tableProducts)
    .sort((a, b) => {
      if (a.SortOrder === b.SortOrder) {
        return 0;
      }
      // move null to the last
      if (a.SortOrder === null) {
        return 1;
      }
      if (b.SortOrder === null) {
        return -1;
      }

      // otherwise, lowest sorts first
      return Number(a.SortOrder) < Number(b.SortOrder) ? -1 : 1;
    });

  const productColumns = () => {
    let cols = [];
    cols.push({ id: "Number", label: "Id", className: "noSquish" });
    cols.push({
      id: "Description",
      label: "Description",
      className: "sticky2",
    });
    cols.push({ id: "Status", label: "Status" });
    if (props.isContractedRange)
      cols.push({
        id: "ContractedRange",
        label: "Contracted Range",
        className: "range",
      });
    cols.push({ id: "SortOrder", label: "Display Sequence", className: "order", align: "right" });
    return cols;
  };

  const getContractedForProduct = (productId) => {
    if (props.Doors.length === 0) return;
    props.Doors[0].Data[0][productId].IsContracted =
      !props.Doors[0].Data[0][productId].IsContracted;
  };

  const copyVersion = (door, version) => {
    setCurrentlySelectedToCopy({
      door: door,
      doorName: props.Doors[door].DoorName,
      version: version,
    });
    setCopyVersionDialogOpen(true);
  };

  const copyVersionDialogClosed = () => {
    //debugger;
    var a = { ...currentlySelectedToCopy };
    var b = { ...modalSelectedItems };
    // b.door = parseInt(b.door.split(" ")[1] - 1);
    // b.door = b.door.toString();
    props.copyVersion(a, b);
    //setModalSelectedItems({ door: null, version: null });
  };

  const generateProductColumnsForVersions = (versions, data) => {
    let cols = [];
    for (let j = 0; j < versions; j++) {
      let totalRangePoints = 0;
      for (const [key, value] of Object.entries(data[j])) {
        if (parseFloat(value.RangePoints) !== NaN && value.RangePoints !== "-")
          totalRangePoints += parseFloat(value.RangePoints);
      }
      cols.push({
        id: "Threshold",
        label: "Threshold",
        className: "headerLabelLeft",
        version: j,
      });
      cols.push({
        id: "RangePoints",
        label: `Range Points (${totalRangePoints})`,
        className: "headerLabelRight",
        version: j,
      });
    }
    return cols;
  };

  // Reformat and de-duplicate the list of IDs then return an array to the Core Range view.
  const handleAddTargets = (targetIds) => {
    if (!targetIds) return;

    let inputtedTargets = targetIds
      .split("|")
      .join(",")
      .split(",")
      .map(Function.prototype.call, String.prototype.trim);
    inputtedTargets = [...new Set(inputtedTargets)]; // Remove any duplicates

    props.addProducts(inputtedTargets);
    setBulkAddIds(""); // Reset the input
  };

  const handleSelectProduct = (row) => {
    let id;
    if ("Number" in row) {
      id = row.Number;
    } else if ("Counter" in row) {
      id = row.Counter;
    } else {
      throw new Error("Not a valid product or targetGroup");
    }

    // Add or remove the item from the list
    if (selectedProducts.indexOf(id) === -1) {
      setSelectedProducts([...selectedProducts, id]);
    } else {
      const newList = [...selectedProducts].filter((item) => item !== id);
      setSelectedProducts(newList);
    }
  };

  const generateDoorHeaders = (doorName, versionCount, id) => {
    return (
      <>
        {props.edit !== id ? (
          <TableCell
            align="center"
            variant="head"
            className={classes.doorLabel}
            colSpan={2 * versionCount}
            style={{ height: "65px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {doorName}
              <Tooltip
                title={
                  <span style={{ fontSize: "12px" }}>Rename this Door</span>
                }
                placement="top"
              >
                <Edit
                  size="small"
                  style={{
                    marginLeft: "20px",
                    padding: "5px",
                    cursor: "pointer",
                    borderRadius: "9999px",
                    background: "#c41212",
                    color: "#fff",
                  }}
                  onClick={() => props.handleEdit(id, newDoorName)}
                />
              </Tooltip>
            </div>
          </TableCell>
        ) : (
          <TableCell
            align="center"
            variant="head"
            className={classes.doorLabel}
            colSpan={2 * versionCount}
          >
            <input
              type="number"
              min="0"
              step="0.5"
              autoFocus
              placeholder="Enter door number..."
              onChange={(e) => setNewDoorName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.renameDoor(id, newDoorName);
                  e.preventDefault();
                  e.stopPropagation();
                } else if (e.key === "e") {
                  e.preventDefault();
                }
              }}
            />
            <button
              className="rename-door-button"
              onClick={() => props.setEdit(false)}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </button>
          </TableCell>
        )}
      </>
    );
  };

  const getVersionSelectionOptions = () => {
    let doors = props.Doors;
    if (modalSelectedItems.door === null) {
      return [];
    }
    let doorNumber = null;
    if (modalSelectedItems.door === 0) {
      doorNumber = 0;
    } else {
      doorNumber = modalSelectedItems.door;
    }
    let versions = doors[modalSelectedItems.door].Data.map((item, index) => {
      return <option value={index}>{index + 1}</option>;
    });
    return versions;
  };

  const generateVersionHeaders = (doorName, versionCount) => {
    let components = [];
    for (let j = 0; j < versionCount; j++) {
      components.push(
        <TableCell
          key={`doorName_${j}`}
          align="center"
          className={classes.versionLabel}
          colSpan={2}
          style={{ height: "82px" }}
        >
          <Tooltip
            title={
              <span style={{ fontSize: "12px" }}>Delete this version</span>
            }
            placement="top"
          >
            <IconButton
              color="secondary"
              size="small"
              style={{ marginRight: "30px" }}
              onClick={(event) => props.removeVersion(doorName, j)}
            >
              <DeleteForever fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              <span style={{ fontSize: "12px" }}>
                Copy this version to another version
              </span>
            }
            placement="top"
          >
            <IconButton
              color="secondary"
              size="small"
              style={{ marginRight: "30px" }}
              onClick={(event) => copyVersion(doorName, j)}
            >
              <FileCopy fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <span style={{ fontSize: "12px" }}>Version {j + 1}</span>
          {j + 1 == versionCount && (
            <Tooltip
              title={
                <span style={{ fontSize: "12px" }}>Add a new version</span>
              }
              placement="top"
            >
              <IconButton
                color="primary"
                size="small"
                style={{ marginLeft: "30px" }}
                onClick={(event) => {
                  props.addVersion(doorName);
                }}
              >
                <Add fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      );
    }
    return components;
  };

  /**
   * Show editing controls for the product list if we're in edit mode.
   */
  const renderProductListControls = () => {
    if (isEditingProducts) {
      return (
        <Box isplay="flex" justifyContent="space-between">
          <Box display="flex" alignItems="end" style={{ gap: "0.5em" }}>
            <TextField
              id="targetIds"
              name="targetIds"
              label="Target Id's"
              variant="outlined"
              size="small"
              margin="dense"
              style={{ marginRight: "8px", marginBottom: "0", flex: 1 }}
              value={bulkAddIds}
              onChange={(event) => setBulkAddIds(event.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              className={classes.buttonStyle}
              variant="outlined"
              color="secondary"
              onClick={() => handleAddTargets(bulkAddIds)}
              disabled={bulkAddIds.length === 0}
            >
              Add
            </Button>
            <Button
              className={classes.buttonStyle}
              variant="outlined"
              color="secondary"
              style={{ marginLeft: "12px" }}
              onClick={() => {
                props.deleteItems(selectedProducts);
                setSelectedProducts([]);
              }}
              disabled={selectedProducts.length === 0}
            >
              Delete Selected
            </Button>
            <Button
              className={classes.buttonStyle}
              variant="contained"
              color="primary"
              onClick={() => setIsEditingProducts(false)}
            >
              Done
            </Button>
          </Box>
        </Box>
      );
    }
    return (
      <>
        <TableRow
          style={{ maxWidth: "100%", marginLeft: "0px" }}
          hover
          role="checkbox"
          tabIndex={0}
          key={`${Math.random() * 100000}`}
          className={classes.tableRow}
        >
          <TableCell className={classes.tableCell} style={{ width: "30%" }}>
            <Select
              style={{ paddingLeft: "0px" }}
              native
              value={isCustomTargetGroup}
              onChange={(event) => {
                setIsCustomTargetGroup(!isCustomTargetGroup);
              }}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
              placeholder="Type"
              label="Type"
            >
              <option value={false}>Product</option>
              <option value={true}>Custom Product Group</option>
            </Select>
          </TableCell>
          <TableCell className={classes.tableCell} style={{ width: "90%" }}>
            <Autocomplete //TODO allow for filtering of products
              {...defaultProps()}
              renderInput={(params) => (
                <TextField
                  id="product-field"
                  {...params}
                  placeholder="Type or select the product..."
                  InputProps={{
                    ...params.InputProps,
                  }}
                  margin="dense"
                />
              )}
              onChange={(e, value) => {
                if (!value.Number) return;
                props.addProducts(value.Number);
              }}
            />
          </TableCell>
        </TableRow>
      </>
    );
  };

  const productsList = () => {
    return (
      <div
        id="Table"
        style={{
          width: "100%",
        }}
      >
        <Table stickyHeader>
          <TableHead style={{ position: "sticky", top: "0px", zIndex: 99 }}>
            <TableRow style={{ margin: "auto" }}>
              <TableCell
                className={classes.tableHead}
                align="center"
                colSpan={props.isContractedRange ? 5 : 4}
              >
                <div style={{ display: "flex", height: "53px", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      margin: "8px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      width: "max-content"
                    }}
                    onClick={props.addDoor}
                  >
                    Add Door
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      margin: "8px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      width: "max-content"
                    }}
                    onClick={() => setIsEditingProducts(true)}
                  >
                    Edit Products
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      margin: "8px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      width: "max-content"
                    }}
                    onClick={() => {
                      props.setAddProductGroupOpen(true);
                    }}
                  >
                    Add Product Group
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{
                      textTransform: "capitalize",
                      margin: "8px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      width: "max-content"
                    }}
                    onClick={() => {
                      setIsBulkUploadModel(true)
                    }}
                  >
                    Mass Upload
                  </Button>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableHead} align="left">
              <TableCell
                align="center"
                variant="head"
                colSpan={props.isContractedRange ? 5 : 4}
                style={{ height: "104px" }}
              >
                {renderProductListControls()}
              </TableCell>
            </TableRow>
            <TableRow style={{ height: "104px" }}>
              {productColumns().map((column) => (
                <TableCell
                  style={{ fontSize: "10px", textAlign: "left" }}
                  className={classes[column.className]}
                  key={column.id}
                  align={column.align}
                  component="th"
                  scope="row"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ zIndex: 0 }}>
            {productsByDescription.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.Number}
                  className={
                    row.Status != " " && row.Status != "NP"
                      ? classes.highlightRow
                      : classes.tableRow
                  }
                  style={{ height: "70px" }}
                >
                  {productColumns().map((column) => {
                    let value = row[column.id];
                    if (value == null) value = "";
                    return (
                      <TableCell
                        key={`${row.Number}-${column.id}`}
                        align={column.align}
                        className={classes.tableCell}
                        style={{ paddingLeft: "8px" }}
                      >
                        {column.id == "ContractedRange" ? (
                          // checked={value}
                          <Checkbox
                            style={{ padding: "0px", marginLeft: "45px" }}
                            checked={
                              props.Doors[0].Data[0][row.id]?.IsContracted
                                ? props.Doors[0].Data[0][row.id]?.IsContracted
                                : props.Doors[0].Data[0][row.Number]
                                  ?.IsContracted
                            }
                            size="small"
                            onChange={(event) => {
                              props.toggleContractedProduct(row);
                            }}
                          ></Checkbox>
                        ) : column.id === "Status" && row.Status !== " " ? (
                          <Chip
                            style={{ backgroundColor: "rgba(255,255,255,0.3" }}
                            variant="outlined"
                            label={value}
                          >
                            <Typography
                              noWrap={true}
                              variant="body2"
                              style={{ fontWeight: ".9rem" }}
                            >
                              {value}
                            </Typography>
                          </Chip>
                        ) : (
                          <Typography
                            noWrap={true}
                            variant="body2"
                            style={{ fontSize: "10px" }}
                          >
                            {column.id == "Number" && isEditingProducts && (
                              <Checkbox
                                style={{
                                  padding: "0px",
                                  margin: "-0.1em 0.25em 0 0",
                                  zIndex: 0,
                                }}
                                checked={selectedProducts.includes(value)}
                                size="small"
                                onChange={(event) => {
                                  handleSelectProduct(row, column);
                                }}
                              />
                            )}
                            {value}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  const generateTable = (data, index) => {
    let choices = ["yellow", "pink", "blue"];
    return (
      <div
        selectable="false"
        key={data.id}
        style={{ marginBottom: "100px" }}
      >
        <Table
          selectable="false"
          stickyHeader
          className={classes.table}
          aria-label="simple table"
          style={{ margin: 0 }}
        >
          <TableHead
            style={{
              position: "sticky",
              top: "0px",
              zIndex: 99,
            }}
          >
            <TableRow className={classes.tableHead} style={{ height: "82px" }}>
              {generateDoorHeaders(
                data.doorName ? data.doorName : data.DoorName,
                data.versions ? data.versions : data.Versions,
                data.id
              )}
            </TableRow>
            <TableRow className={classes.tableHead} style={{ height: "104px" }}>
              {generateVersionHeaders(
                index,
                data.versions ? data.versions : data.Versions
              )}
            </TableRow>
            <TableRow
              className={classes.tableHead}
              ref={rowRef}
              style={{ height: "104px" }}
            >
              {generateProductColumnsForVersions(
                data.versions ? data.versions : data.Versions,
                data.data ? data.data : data.Data
              ).map((column) => (
                <TableCell
                  className={classes[column.className]}
                  key={`${index}_${column.version}_${column.id}_label`}
                  align={column.align}
                  component="th"
                  scope="row"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {productsByDescription.map((row) => {
              let isObsolete = null;
              if (row.Status === "OB") {
                isObsolete = true;
              }
              const uuidRegex =
                /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
              let isLikelyProductGroup = uuidRegex.test(
                row.id ? row.id : row.Number
              );

              return (
                <TableRow
                  selectable="false"
                  hover
                  tabIndex={-1}
                  key={`${row.id ? row.id : row.Number}`}
                  className={classes[choices[index % choices.length]]}
                  style={{ height: "70px" }}
                >
                  {generateProductColumnsForVersions(
                    data.Versions,
                    data.Data
                  ).map((column) => {
                    let isThresholdColumn = column.id === "Threshold";
                    let isRangePointColumn = column.id === "RangePoints";

                    let doorVersionData =
                      props.Doors[index].Data[column.version];
                    let productData = row.id
                      ? doorVersionData[row.id]
                      : doorVersionData[row.Number];
                    let value = productData ? productData[column.id] : "-";
                    return (
                      <TableCell
                        selectable="false"
                        key={`${column.version}_${row.Number}_${column.id}`}
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "5px",
                        }}
                      >
                        <div
                          key={`${column.version}_${row.Number}_${column.id}_div`}
                        >
                          <TextField
                            style={{ width: "95%", paddingLeft: "25px" }}
                            key={`${column.version}_${row.Number}_${column.id}_text`}
                            size="small"
                            margin="none"
                            variant="standard"
                            disabled={
                              (!isLikelyProductGroup && isThresholdColumn) ||
                              (isObsolete && isRangePointColumn)
                            }
                            type="number"
                            value={value}
                            onSelect={(event) => {
                              value === "-" &&
                                props.updateValue(
                                  index,
                                  column.version,
                                  row.Number,
                                  column.id,
                                  "-"
                                );
                            }}
                            onBlur={(event) => {
                              value === "" &&
                                props.updateValue(
                                  index,
                                  column.version,
                                  row.Number,
                                  column.id,
                                  "-"
                                );
                            }}
                            onChange={(event) => {
                              props.updateValue(
                                index,
                                column.version,
                                row.Number,
                                column.id,
                                event.target.value
                              );
                            }}
                          />
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

          </TableBody>
        </Table>
      </div>
    );
  };

  const filterData = (data, key) => {
    const products = data.filter(item => item[key]);
    return products;
  }

  const filterDataWithMap = (data, key, productOrGroupList, productKey) => {
    const productData = data.filter((item) =>
      productOrGroupList.map(item => item[productKey]).includes(item[key])
    );
    return productData;
  }

  const onSubmitClick = () => {
    const products = filterData(csvData, CoreRangeColumn.ProductNumber)
    const productList = filterDataWithMap(products, CoreRangeColumn.ProductNumber, ProductList, 'Number')
    const productGroup = filterData(csvData, CoreRangeColumn.ProductGroupTargetID)
    const productGroupList = filterDataWithMap(productGroup, CoreRangeColumn.ProductGroupTargetID, TargetGroupList, 'TargetGroupId')

    if (validate()) {
      props.submitExcelData([...productList, ...productGroupList])
      setIsBulkUploadModel(false);
      setCsvData({})

    } else {
      setIsErrorScreenVisible(true)
    }
  }

  const getVersionOrder = (csvData) => {
    let version = null;
    for (var i = 0; i < csvData.length; i++) {
      let getDoors = csvData.filter(item => item[CoreRangeColumn.Door] === csvData[i][CoreRangeColumn.Door])
      let getVersions = getDoors.map(item => item[CoreRangeColumn.VersionNumber]).filter(item => item !== false)
      getVersions = getVersions.map(Number)
      getVersions = [...new Set(getVersions)]
      const getIndex = getVersions.indexOf(parseInt(csvData[i][CoreRangeColumn.VersionNumber]))
      if (parseInt(csvData[i][CoreRangeColumn.VersionNumber]) !== getIndex + 1) {
        version = false;
        break;
      } else {
        version = true;
      }
    }
    return version;
  }

  const getVersionError = (csvData, data) => {
    let version = null;
    let getDoors = csvData.filter(item => item[CoreRangeColumn.Door] === data[CoreRangeColumn.Door])
    let getVersions = getDoors.map(item => item[CoreRangeColumn.VersionNumber]).filter(item => item !== false)
    getVersions = getVersions.map(Number)
    getVersions = [...new Set(getVersions)]
    const getIndex = getVersions.indexOf(parseInt(data[CoreRangeColumn.VersionNumber]))
    if (parseInt(data[CoreRangeColumn.VersionNumber]) !== getIndex + 1) {
      version = false;
    } else {
      version = true;
    }
    return version
  }

  const isDecimal = (num) => {
    return (num % 1);
  }

  // Checking is Number or not
  const isNumeric = (str) => {
    return !isNaN(str - parseFloat(str));
  }

  const isNullOrBlank = (value) => {
    if (value === null) {
      return true;
    }

    value = value.trim();
    if (value === "") {
      return true;
    }

    return false;
  }

  const isInvalidDoor = (value) => {
    if (isNullOrBlank(value)) {
      // Disregard null or blank.
      return true;
    }
    
    if (isNumeric(value)) {
      // Disregard negative values.
      return !(value >= 0)
    }

    // Disregard non-numeric values.
    return true;
  }

  const isInvalidVersionNumber = (value) => {
    if (isNullOrBlank(value)) {
      // Disregard null or blank.
      return true;
    }
    
    if (isNumeric(value)) {
      if (isDecimal(Number(value))) {
        // Disregard decimal values.
        return true;
      }

      // Disregard zero or negative values.
      return !(value > 0)
    }

    // Disregard non-numeric values.
    return true;
  }

  const isInvalidThresholdForProduct = (value) => {
    if (isNullOrBlank(value)) {
      // Disregard null or blank.
      return true;
    }
    
    // Disregard non-zero values.
    return value !== "0";
  }

  const isInvalidThresholdForProductGroup = (value) => {
    if (isNullOrBlank(value)) {
      // Disregard null or blank.
      return true;
    }
    
    if (isNumeric(value)) {
      if (isDecimal(Number(value))) {
        // Disregard decimal values.
        return true;
      }

      // Disregard zero or negative values.
      return !(value > 0)
    }

    // Disregard non-numeric values.
    return true;
  }

  const isInvalidRangePoint = (value) => {
    if (isNullOrBlank(value)) {
      // Disregard null or blank.
      return true;
    }

    if (isNumeric(value)) {
      // Disregard negative values.
      return !(value >= 0)
    }

    // Disregard non-numeric values.
    return true;
  }

  const validate = () => {
    const products = filterData(csvData, CoreRangeColumn.ProductNumber)
    const productList = filterDataWithMap(products, CoreRangeColumn.ProductNumber, ProductList, 'Number')
    const productGroup = filterData(csvData, CoreRangeColumn.ProductGroupTargetID)
    const productGroupList = filterDataWithMap(productGroup, CoreRangeColumn.ProductGroupTargetID, TargetGroupList, 'TargetGroupId')

    const duplicateProducts = products.filter(
      (obj, index) =>
        products.findIndex((item) => item[CoreRangeColumn.ProductNumber] === obj[CoreRangeColumn.ProductNumber] && item[CoreRangeColumn.Door] === obj[CoreRangeColumn.Door]
          && item[CoreRangeColumn.VersionNumber] === obj[CoreRangeColumn.VersionNumber]) === index
    );
    if (products.length !== duplicateProducts.length) {
      return false;
    }

    const duplicateProductGroups = productGroup.filter(
      (obj, index) =>
        productGroup.findIndex((item) => item[CoreRangeColumn.ProductGroupTargetID] === obj[CoreRangeColumn.ProductGroupTargetID] && item[CoreRangeColumn.Door] === obj[CoreRangeColumn.Door]
          && item[CoreRangeColumn.VersionNumber] === obj[CoreRangeColumn.VersionNumber]) === index
    );
    if (productGroup.length !== duplicateProductGroups.length) {
      return false;
    }
    
    const doorIsInvalid = csvData.find(item => isInvalidDoor(item[CoreRangeColumn.Door]))
    if (doorIsInvalid) {
      return false;
    }
    
    const versionNumberIsInvalid = csvData.find(item => isInvalidVersionNumber(item[CoreRangeColumn.VersionNumber]))
    if (versionNumberIsInvalid) {
      return false;
    }

    const productThresholdIsInvalid = csvData.find(item => item[CoreRangeColumn.ProductNumber] && isInvalidThresholdForProduct(item[CoreRangeColumn.Threshold]))
    if (productThresholdIsInvalid) {
      return false;
    }
        
    const productGroupThresholdIsInvalid = csvData.find(item => item[CoreRangeColumn.ProductGroupTargetID] && isInvalidThresholdForProductGroup(item[CoreRangeColumn.Threshold]))
    if (productGroupThresholdIsInvalid) {
      return false;
    }
    
    const rangePointIsInvalid = csvData.find(item => isInvalidRangePoint(item[CoreRangeColumn.RangePoints]))
    if (rangePointIsInvalid) {
      return false;
    }
    
    const contractedRangeIsInvalid = csvData.find(item => item[CoreRangeColumn.ContractedRange] && (/[^01]/.test(item[CoreRangeColumn.ContractedRange])))
    if (contractedRangeIsInvalid) {
      return false;
    }

    const version = getVersionOrder(csvData)

    const isProductandGroupEmpty = csvData.find(item => item[CoreRangeColumn.ProductNumber] === null && item[CoreRangeColumn.ProductGroupTargetID] === null)
    const isProductandGroupVisible = csvData.find(item => item[CoreRangeColumn.ProductNumber] !== null && item[CoreRangeColumn.ProductGroupTargetID] !== null)
    
    if (!isProductandGroupEmpty && !isProductandGroupVisible && products.length === productList.length && productGroup.length === productGroupList.length) {
      if (version) {
        return true;
      } else {
        return false
      }
    } else {
      return false;
    }
  }

  // Download the existing data of new template for core range
  const DownloadLogFile = () => {
    csvData.map(item => {
      item[CoreRangeColumn.ProductNumber] = item[CoreRangeColumn.ProductNumber] || ""
      item[CoreRangeColumn.ProductGroupTargetID] = item[CoreRangeColumn.ProductGroupTargetID] || ""
      item[CoreRangeColumn.VersionNumber] = item[CoreRangeColumn.VersionNumber] || ""
      item[CoreRangeColumn.Door] = item[CoreRangeColumn.Door] || ""
      item[CoreRangeColumn.ContractedRange] = item[CoreRangeColumn.ContractedRange] || ""
      item[CoreRangeColumn.RangePoints] = item[CoreRangeColumn.RangePoints] || ""
      item[CoreRangeColumn.Threshold] = item[CoreRangeColumn.Threshold] || ""
      const productData = ProductList.map(item => item.Number).includes(item[CoreRangeColumn.ProductNumber])
      const productGroup = TargetGroupList.map(item => item.TargetGroupId).includes(item[CoreRangeColumn.ProductGroupTargetID])
      const productsList = filterData(csvData, CoreRangeColumn.ProductNumber)
      const productGroupList = filterData(csvData, CoreRangeColumn.ProductGroupTargetID)
      const isProductDuplicates = productsList.filter(obj => obj[CoreRangeColumn.ProductNumber] === item[CoreRangeColumn.ProductNumber] &&
        obj[CoreRangeColumn.Door] === item[CoreRangeColumn.Door] && obj[CoreRangeColumn.VersionNumber] === item[CoreRangeColumn.VersionNumber])
      const isProductGroupDuplicates = productGroupList.filter(obj => obj[CoreRangeColumn.ProductGroupTargetID] === item[CoreRangeColumn.ProductGroupTargetID] &&
        obj[CoreRangeColumn.Door] === item[CoreRangeColumn.Door] && obj[CoreRangeColumn.VersionNumber] === item[CoreRangeColumn.VersionNumber])
      
      item['Error'] = '';

      // Validating Version order
      if (!getVersionError(csvData, item)) {
        item['Error'] += 'Version Number order mismatch in the file. '
        return;
      }

      // Validating Doors
      if (isNullOrBlank(item[CoreRangeColumn.Door])) {
        item['Error'] += `${CoreRangeColumn.Door} cannot be empty. `
      } else if (!isNumeric(item[CoreRangeColumn.Door])) {
        item['Error'] += `${CoreRangeColumn.Door} should be a number. `
      } else if (isInvalidDoor(item[CoreRangeColumn.Door])) {
        item['Error'] += `${CoreRangeColumn.Door} cannot be "${item[CoreRangeColumn.Door]}" (any negative values). `
      }

      // Validating Version Number
      if (isNullOrBlank(item[CoreRangeColumn.VersionNumber])) {
        item['Error'] += `${CoreRangeColumn.VersionNumber} cannot be empty. `
      } else if (!isNumeric(item[CoreRangeColumn.VersionNumber])) {
        item['Error'] += `${CoreRangeColumn.VersionNumber} should be a number. `
      } else if (isInvalidVersionNumber(item[CoreRangeColumn.VersionNumber])) {
        item['Error'] += `${CoreRangeColumn.VersionNumber} cannot be "${item[CoreRangeColumn.VersionNumber]}" (zero or any negative values). `
      }

      // Validating Threshold
      if (item[CoreRangeColumn.ProductNumber] && isNullOrBlank(item[CoreRangeColumn.Threshold])) {
        item['Error'] += `${CoreRangeColumn.Threshold} cannot be empty and must equal to zero "0" for product. `
      } else if (item[CoreRangeColumn.ProductNumber] && !isNumeric(item[CoreRangeColumn.Threshold])) {
        item['Error'] += `${CoreRangeColumn.Threshold} should be a number and must equal to zero "0" for product. `
      } else if (item[CoreRangeColumn.ProductNumber] && isInvalidThresholdForProduct(item[CoreRangeColumn.Threshold])) {
        item['Error'] += `${CoreRangeColumn.Threshold} must equal to zero "0" for product. `
      } else if (item[CoreRangeColumn.ProductGroupTargetID] && isNullOrBlank(item[CoreRangeColumn.Threshold])) {
        item['Error'] += `${CoreRangeColumn.Threshold} cannot be empty for product group. `
      } else if (item[CoreRangeColumn.ProductGroupTargetID] && !isNumeric(item[CoreRangeColumn.Threshold])) {
        item['Error'] += `${CoreRangeColumn.Threshold} should be a number for product group. `
      } else if (item[CoreRangeColumn.ProductGroupTargetID] && isDecimal(Number(item[CoreRangeColumn.Threshold]))) {
        item['Error'] += `${CoreRangeColumn.Threshold} cannot be decimal for product group. `
      } else if (item[CoreRangeColumn.ProductGroupTargetID] && isInvalidThresholdForProductGroup(item[CoreRangeColumn.Threshold])) {
        item['Error'] += `${CoreRangeColumn.Threshold} cannot be "${item[CoreRangeColumn.Threshold]}" (zero or any negative values) for product group. `
      }

      // Validating Range Points
      if (isNullOrBlank(item[CoreRangeColumn.RangePoints])) {
        item['Error'] += `${CoreRangeColumn.RangePoints} cannot be empty. `
      } else if (!isNumeric(item[CoreRangeColumn.RangePoints])) {
        item['Error'] += `${CoreRangeColumn.RangePoints} should be a number. `
      } else if (isInvalidRangePoint(item[CoreRangeColumn.RangePoints])) {
        item['Error'] += `${CoreRangeColumn.RangePoints} cannot be "${item[CoreRangeColumn.RangePoints]}" (any negative values). `
      } 
      
      // Validating Products && Groups
      if (item[CoreRangeColumn.ProductNumber] !== "" && item[CoreRangeColumn.ProductGroupTargetID] !== "") {
        item['Error'] += "Product Number and Product Group cannot be Added. "
      }

      if (item[CoreRangeColumn.ProductNumber] || item[CoreRangeColumn.ProductGroupTargetID]) {
        if (item[CoreRangeColumn.ProductNumber] && !productData) {
          item['Error'] += "Product Number not found in the system. "
        } else if (item[CoreRangeColumn.ProductGroupTargetID] && !productGroup) {
          item['Error'] += "Product Group not found in the system. "
        }
        else if (isProductDuplicates.length > 1) {
          item['Error'] += "Duplicate Product Number. "
        } else if (isProductGroupDuplicates.length > 1) {
          item['Error'] += "Duplicate Product Group. "
        }
      } else {
        item['Error'] += "Product Number or Group cannot be empty. "
      }

      // Validating ContractedRange
      if (/[^01]/.test(item[CoreRangeColumn.ContractedRange])) {
        item['Error'] += `${CoreRangeColumn.ContractedRange} should be 0 or 1. `
      }
    })
    props.downloadLogFileData(csvData)
  }

  const ErrorScreen = () => {
    return (
      <CustomCard
      ><Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="subtitle1" gutterBottom>
                <Tooltip title="Error">
                  <IconButton style={{ color: '#c31d1d' }}>
                    <ErrorIcon />
                  </IconButton>
                </Tooltip>ERROR: File not uploaded
              </Typography>
            </div>
            <Typography style={{ fontSize: '0.7rem' }} variant="subtitle1" gutterBottom>
              There were some errors identified while processing your CSV file. To help resolve these errors, please download the log file provided below. The log file contains the information needed to help you identify and correct the errors for a successful reupload.
            </Typography>
            <div className="download-log-file" style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color="secondary"
                onClick={DownloadLogFile}
              >
                Download Log file
              </Button>
            </div>
          </Grid>
        </Grid>
      </CustomCard>
    )
  }

  return (
    <>

      <ScrollSync horizontal={false}>
        <Grid container style={{ maxWidth: "100%", marginBottom: "15px" }}>
          <Modal
            open={isBulkUploadModel}
            fullWidth={false}
            fixedHeight={false}
            actions={
              <Box>
                {!isErrorScreenVisible ? <Button
                  color="secondary"
                  disabled={csvInvalidError || !csvData.length}
                  onClick={onSubmitClick}>
                  {/* Absorb the onClick */}
                  Submit
                </Button> : null}
                <Button
                  color="secondary"
                  onClick={() => {
                    setCsvData({})
                    setIsBulkUploadModel(false);
                    setIsErrorScreenVisible(false)
                  }}
                >
                  Cancel
                </Button>
              </Box>
            }
          >
            {!isErrorScreenVisible ? <><div className="download-template" style={{ display: 'flex', justifyContent: 'flex-end' }}><Button
              color="secondary"
              onClick={props.downloadExcelTemplateData}
            >
              Download CSV
            </Button></div> <MigrationUploadSegment
                csvData={csvData}
                setCsvData={setCsvData}
                setDataChanged={setDataChanged}
                isColumnsUnique ={true}
                setCSVInvalid={(option) => {
                  //TODO Show Error message if csv is invalid
                  if (option) {
                    setCsvInvalidError(true);
                    setCsvData({})
                  } else {
                    setCsvInvalidError(false);
                  }
                }}
                expectedColumns={CoreRangeExpectedColumns}
                title="CSV Upload & Replace"
                targetsEdited={targetsEdited}
                setTargetsEdited={setTargetsEdited}
                isEditing={isEditing}
                setFormEdited={setFormEdited}
                description="Please Upload a CSV of Activity Targets.(All existing Activity Targets will be set to inactive unless explicitly update within the CSV Uploaded."
              /></> : <ErrorScreen />}
          </Modal>
          <ScrollSyncPane>
            <Grid
              item
              xs={props.isContractedRange ? 5 : 5}
              id="table-container"
            >
              {productsList()}
            </Grid>
          </ScrollSyncPane>
          <ScrollSyncPane>
            <Grid
              item
              xs={props.isContractedRange ? 7 : 7}
              id="door-container"
            >
              {props.Doors.map((item, index) => {
                return generateTable(item, index);
              })}
            </Grid>
          </ScrollSyncPane>
        </Grid>
      </ScrollSync>

      <Modal
        title={`Copy Data from (${currentlySelectedToCopy.doorName} Version ${currentlySelectedToCopy.version + 1
          }) to:`}
        open={copyVersionDialogOpen}
        fullWidth={true}
        fixedHeight={true}
        actions={
          <Box>
            <Button
              color="secondary"
              // disabled={CSVInvalid}
              onClick={() => {
                copyVersionDialogClosed();
                setCopyVersionDialogOpen(false);
              }}
            >
              {/* Absorb the onClick */}
              Submit
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setCopyVersionDialogOpen(false);
              }}
            >
              Cancel
            </Button>
          </Box>
        }
      >
        <Grid container>
          <Grid item xs={2}>
            <Typography>Door:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Select
              label="Door Selection"
              native
              value={modalSelectedItems.door}
              onChange={(event) => {
                setModalSelectedItems({
                  ...modalSelectedItems,
                  door: parseInt(event.target.value),
                });
              }}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
              placeholder="Type"
            >
              <option defaultValue>Select a Door...</option>
              {props.Doors.map((item, index) => {
                return <option value={index}>{item.DoorName}</option>;
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Typography>Version:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Select
              label="Version Selection"
              native
              value={modalSelectedItems.version}
              onChange={(event) =>
                setModalSelectedItems({
                  ...modalSelectedItems,
                  version: parseInt(event.target.value),
                })
              }
            >
              <option defaultValue>Select a Version...</option>
              {getVersionSelectionOptions()}
            </Select>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
const hoc = withRouter(CoreRangeProductsTable);
export { hoc as CoreRangeProductsTable };
