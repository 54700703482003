import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import { formatISO, getYear, getMonth, getDate } from "date-fns";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  getActivitiesById,
  getActivityById,
  GetDisplayGroupById,
} from "../../../state/actions/MyDisplaysActions";
import { ChoicesTable } from "./components/ChoicesTable";
import { RewardsTable } from "./components/RewardsTable";
import {
  getDropdownLookups,
  updateActivity,
} from "../../../state/actions/ActivityActions";
import { Loading } from "../../../components/loading/Loading";
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import { set } from "date-fns";
import { useValidate } from "../Hooks/useValidate";

import { getApiConfig } from "../../../config/apiConfig";

const API_CONFIG = getApiConfig();

const DisplayTypePage = (props) => {
  const {
    displayNameError,
    setDisplayNameError,
    displayStartDateError,
    setDisplayNameStartError,
    displayEndDateError,
    setDisplayNameEndDateError,
    displayFrequencyError,
    setDisplayFrequencyError,
    displayMinError,
    setDisplayMinError,
    displayMaxError,
    setDisplayMaxError,
    displaySequenceError,
    setDisplaySequenceError,
  } = useValidate();

  const dispatch = useDispatch();
  const activityId = props.match.params.ActivityId;
  const displayGroupId = props.match.params.DisplayGroupId;
  const displayIndex = props.match.params.DisplayIndex;

  const currentActivity = useSelector(
    (state) => state.MyDisplaysReducer.displayActivity
  );

  const displayGroup = useSelector(
    (state) => state.MyDisplaysReducer.displayGroup
  );

  const activityIds = displayGroup.Displays[displayIndex].Activities.map(
    (i) => i.MyDisplaysActivityId
  );

  const displayActivities = useSelector(
    (state) => state.MyDisplaysReducer.displayActivities
  );

  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  let createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [existingActivity, setExistingActivity] = useState({});
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);

  const getPageTitle = () => {
    let pageTitle = "";

    const activityIdArray = displayActivities.map((act) =>
      act.Activity.Id.toString()
    );

    const isDisplay =
      displayGroup.Displays[displayIndex].DisplayTypeActivityId.toString() ===
      activityId;

    const isLocation = activityIdArray.indexOf(activityId) === 0;

    const isCompliance = activityIdArray.indexOf(activityId) >= 2;

    if (isDisplay) {
      pageTitle = "Display Activity";
    } else if (isLocation) {
      pageTitle = "Location Activity";
    } else if (isCompliance) {
      pageTitle = "Compliance Activity";
    } else {
      pageTitle = "Product Activity";
    }
    return pageTitle;
  };

  useEffect(() => {
    dispatch(GetDisplayGroupById(displayGroupId, () => {}));
  }, []);

  useEffect(() => {
    dispatch(getActivityById(activityId, () => {}));
  }, [displayGroup]);

  useEffect(() => {
    dispatch(getActivitiesById(activityIds, () => {}));
    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => {
        setLoading(true);
      })
    );
    setExistingActivity(currentActivity);
  }, [currentActivity]);

  const handleNameChange = (e) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.Name = e.target.value;
    setExistingActivity(localActivity);
    setDisplayNameError(false);
  };

  const handleActivityTypeChange = (e, value) => {
    let selectedItem = lookupData.ActivityTypes.find(
      (type) => type.DisplayName === value
    );

    let localActivity = { ...currentActivity };
    localActivity.Activity.ActivityTypeId = parseInt(selectedItem.Id);
    localActivity.ActivityType.Value = value;
    localActivity.ActivityType.Code = parseInt(selectedItem.Id);
    setExistingActivity(localActivity);
  };

  const handleFrequencyChange = (e, value) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.ActivityFrequencyId = value.Id;
    setExistingActivity(localActivity);
    setDisplayFrequencyError(false);
  };

  const handleDataTypeChange = (e, value) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.ActivityDataTypeId = value.Id;
    setExistingActivity(localActivity);
  };

  const handleMinChange = (e) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.Min = e.target.value;
    setExistingActivity(localActivity);
    setDisplayMinError(false);
  };

  const handleMaxChange = (e) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.Max = e.target.value;
    setExistingActivity(localActivity);
    setDisplayMaxError(false);
  };

  const handleSequenceChange = (e) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.Sequence = e.target.value;
    setExistingActivity(localActivity);
    setDisplaySequenceError(false);
  };

  const handleRequiredChange = (e) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.IsRequired = e.target.checked;
    setExistingActivity(localActivity);
  };

  const handleOTAChange = (e) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.IsOneTimeAnswer = e.target.checked;
    setExistingActivity(localActivity);
  };

  const handleActiveChange = (e) => {
    let localActivity = { ...currentActivity };
    localActivity.Activity.IsActive = e.target.checked;
    setExistingActivity(localActivity);
  };

  const submitActivity = () => {
    if (currentActivity.Activity.Name.length === 0) {
      setDisplayNameError(true);
    } else if (!currentActivity.Activity.StartDate) {
      setDisplayNameStartError(true);
    } else if (!currentActivity.Activity.EndDate) {
      setDisplayNameEndDateError(true);
    } else if (!currentActivity.Activity.ActivityFrequencyId) {
      setDisplayFrequencyError(true);
    } else if (currentActivity.Activity.Min < 0) {
      setDisplayMinError(true);
    } else if (currentActivity.Activity.Max < 0) {
      setDisplayMaxError(true);
    } else if (!currentActivity.Activity.Sequence) {
      setDisplaySequenceError(true);
    } else if (
      !displayNameError &&
      !displayStartDateError &&
      !displayEndDateError &&
      !displayFrequencyError &&
      !displayMinError &&
      !displayMinError &&
      !displaySequenceError
    ) {
      let localActivity = { ...currentActivity };
      localActivity.ModifiedBy = createdUser;
      setExistingActivity(localActivity);
      if (
        existingActivity.Activity.ActivityTypeId ==
        API_CONFIG.displayActivityType
      ) {
        dispatch(
          updateActivity(
            existingActivity.Activity.Id,
            existingActivity,
            () => {}
          )
        );
        displayActivities.forEach((act) => {
          act.Activity.StartDate = existingActivity.Activity.StartDate;
          act.Activity.EndDate = existingActivity.Activity.EndDate;
          act.Activity.ActivityFrequencyId =
            existingActivity.Activity.ActivityFrequencyId;
          act.ActivityFrequency = existingActivity.ActivityFrequency;

          dispatch(
            updateActivity(act.Activity.Id, act, () => {
              setIsEditing(false);
            })
          );
        });
      } else {
        dispatch(
          updateActivity(existingActivity.Activity.Id, existingActivity, () => {
            setIsEditing(false);
          })
        );
      }
    }
  };

  const buttonGroupElement = isEditing ? (
    <ButtonGroup style={{ marginRight: "35px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setCancelEditingAlertOpen(true)}
      >
        Cancel
      </Button>
      <Button variant="outlined" color="secondary" onClick={submitActivity}>
        Submit
      </Button>
    </ButtonGroup>
  ) : (
    <ButtonGroup style={{ marginRight: "35px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() =>
          props.history.push(
            `/my-displays-maintenance/${displayGroupId}/${displayIndex}`
          )
        }
      >
        Go back
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setIsEditing(true)}
      >
        Edit
      </Button>
    </ButtonGroup>
  );

  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false),
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setCancelEditingAlertOpen(false);
            },
          },
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  return (
    <div>
      {loading ? (
        <>
          <HeaderBlock
            title={currentActivity.Activity.Name}
            right={
              <Grid
                container
                spacing={3}
                alignItems="center"
                justify="flex-end"
              >
                {buttonGroupElement}
              </Grid>
            }
          />
          {cancelEditingAlert()}
          <div style={{ marginBottom: "2.5rem" }}>
            <h2 style={{ marginTop: "150px", marginLeft: "2.5rem" }}>
              {getPageTitle()}
            </h2>
            <div className="create-container" style={{ marginTop: "20px" }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label={"Name"}
                    InputLabelProps={{ shrink: true }}
                    placeholder={"Enter Activity Name"}
                    disabled={!isEditing}
                    onChange={handleNameChange}
                    value={currentActivity.Activity.Name}
                    error={displayNameError}
                    helperText={displayNameError ? "Name is Required" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={existingActivity.ActivityType.Value}
                    options={lookupData.ActivityTypes.filter(
                      (type) =>
                        type.Id === API_CONFIG.displayActivityType ||
                        type.Id === API_CONFIG.competitorActivityType
                    ).map((item) => item.DisplayName)}
                    onChange={(e, value) => handleActivityTypeChange(e, value)}
                    disabled={
                      !isEditing || getPageTitle() !== "Display Activity"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        label="Activity Type"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      disabled={!isEditing}
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Effective to"
                      onChange={(date) => {
                        if (isNaN(date.getTime())) {
                          //
                        } else {
                          let localActivity = { ...currentActivity };
                          localActivity.Activity.StartDate =
                            formatISO(
                              new Date(
                                getYear(date),
                                getMonth(date),
                                getDate(date),
                                0,
                                0,
                                52
                              )
                            ).split("+")[0] + "Z";
                          setExistingActivity(localActivity);
                        }
                      }}
                      value={existingActivity.Activity.StartDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Effective to"
                      onChange={(date) => {
                        if (isNaN(date.getTime())) {
                          //
                        } else {
                          let localActivity = { ...currentActivity };
                          localActivity.Activity.EndDate =
                            formatISO(
                              new Date(
                                getYear(date),
                                getMonth(date),
                                getDate(date),
                                0,
                                0,
                                52
                              )
                            ).split("+")[0] + "Z";
                          setExistingActivity(localActivity);
                        }
                      }}
                      disabled={
                        !isEditing ||
                        activityId !=
                          displayGroup.Displays[displayIndex]
                            .DisplayTypeActivityId
                      }
                      value={
                        existingActivity !== undefined
                          ? existingActivity.Activity.EndDate
                          : new Date()
                      }
                      error={displayEndDateError}
                      helperText={
                        displayEndDateError ? "End Date is Required" : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={lookupData.ActivityFrequencies}
                    getOptionLabel={(option) => option.DisplayName}
                    onChange={handleFrequencyChange}
                    disabled={
                      !isEditing ||
                      activityId !=
                        displayGroup.Displays[displayIndex]
                          .DisplayTypeActivityId
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        placeholder={`${existingActivity.ActivityFrequency.Value} (${existingActivity.ActivityFrequency.Code})`}
                        label="Frequency"
                        error={displayFrequencyError}
                        helperText={
                          displayFrequencyError ? "Frequency is Required" : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={lookupData.ActivityDataTypes}
                    getOptionLabel={(option) => option.DisplayName}
                    onChange={handleDataTypeChange}
                    disabled={
                      existingActivity.Activity.ActivityTypeId !==
                        parseInt(API_CONFIG.complianceActivityType) ||
                      !isEditing
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        placeholder={currentActivity.ActivityDataType.Value}
                        label="Data Type"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label="Min"
                    placeholder="Enter Min Value"
                    value={currentActivity.Activity.Min.toString()}
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    disabled={!isEditing}
                    onChange={handleMinChange}
                    error={displayMinError}
                    helperText={displayMinError ? "Min is Required" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label="Max"
                    placeholder="Enter Max Value"
                    value={currentActivity.Activity.Max.toString()}
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    disabled={!isEditing}
                    onChange={handleMaxChange}
                    error={displayMaxError}
                    helperText={displayMaxError ? "Max is Required" : ""}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label="Sequence"
                    placeholder="Enter Sequence"
                    value={currentActivity.Activity.Sequence.toString()}
                    InputLabelProps={{ shrink: true }}
                    disabled={!isEditing}
                    onChange={handleSequenceChange}
                    error={displaySequenceError}
                    helperText={
                      displaySequenceError ? "Sequence is Required" : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <span>Required</span>
                  <Checkbox
                    color="primary"
                    disabled={!isEditing}
                    checked={currentActivity.Activity.IsRequired}
                    onChange={handleRequiredChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <span>One Time Answer</span>
                  <Checkbox
                    color="primary"
                    disabled={!isEditing}
                    checked={currentActivity.Activity.IsOneTimeAnswer}
                    onChange={handleOTAChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <span>Active</span>
                  <Checkbox
                    color="primary"
                    disabled={!isEditing}
                    checked={currentActivity.Activity.IsActive}
                    onChange={handleActiveChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: "50px",
                marginLeft: "2.5rem",
                marginRight: "2.5rem",
              }}
            >
              <ChoicesTable
                currentActivity={currentActivity}
                displayGroupId={displayGroupId}
                displayIndex={displayIndex}
                activityId={activityId}
              />
            </div>

            <div
              style={{
                marginTop: "50px",
                marginLeft: "2.5rem",
                marginRight: "2.5rem",
              }}
            >
              <RewardsTable
                currentActivity={currentActivity}
                displayGroupId={displayGroupId}
                displayIndex={displayIndex}
                activityId={activityId}
              />
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const hoc = withRouter(DisplayTypePage);

// EXPORT COMPONENT
export { hoc as DisplayTypePage };
