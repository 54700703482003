// IMPORT PACKAGE REFERENCES
import React from "react";
import styles from "./Toolbar.module.scss"; // Import css modules stylesheet as styles
import { Button } from "@material-ui/core";

// COMPONENT
export class Toolbar extends React.Component {
  render() {
    return (
      <div className={styles.toolbar}>
          <Button color="primary" onClick={this.props.multiSelect}>{ this.props.multiSelectLabel }</Button>
          { this.props.showDelete && <Button color="primary" onClick={this.props.onDelete}>Delete</Button>}
      </div>
    );
  }
}
