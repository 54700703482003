// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../components/dialog/Modal";

// UI and Styling
import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import WarningRounded from "@material-ui/icons/WarningRounded";
import Information from "@material-ui/icons/InfoRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// Actions
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";
import { getUser } from "../../config/azureConfig";

// Components
import HeaderBlock from "../../components/header-block/HeaderBlock";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import { UUID } from "../../helpers/uuid";
import PageContainer from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/circleLoader";
import { uploadSECPointsData, getAllSamConfigurations } from "../../state/actions/SECActions";
import { downloadSECPointsData } from "../../state/actions/SECActions";
import { Alert, AlertTitle } from "@material-ui/lab";

// API

// Moment
var moment = require("moment");
moment().format();

const RedTextTypography = withStyles({
  root: {
    color: "#f53100",
  },
})(Typography);

const SECPointsUploadPage = (props) => {
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const configurations = useSelector( (state) => state.ConfigurationReducer.configurations);

  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [previewMigrationCalled, setPreviewMigrationCalled] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [csvInvalidError, setCsvInvalidError] = useState(false);
  const [invalidDeliveryPeriods, setInvalidDeliveryPeriods] = useState(false);
  const [invalidDeliveryPeriodIDs, setInvalidDeliveryPeriodIDs] = useState(false);

  const dispatch = useDispatch();

  const userId = getUser().profile.oid;

  //Use Effects
  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    dispatch(
      getAllSamConfigurations(loggedInUser.territory === "au" ? 1 : 2, () => {
        setLoading(false);
      })
    );
    
  }, [loggedInUserCallMade]);

  useEffect(() => {
    if (targetsEdited) {
      setSubmitEnabled(true);
    }
  }, [targetsEdited]);

  useEffect(() => {
    if (dataChanged) {
      setDataChanged(false);
    }
  }, [dataChanged]);

  const handleSubmit = async () => {
    setIsUploading(true);
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    const data = createRequestBody();
  };

  const csvErrorElement = csvInvalidError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      The CSV file you have provided does not match the specification -{" "}
      <strong>Try Again Later.</strong>
    </Alert>
  ) : (
    <></>
  );

  const invalidDelvieryPeriodsErrorElement = invalidDeliveryPeriods ? (
    <Alert severity="error">
      <AlertTitle>Error: Invalid Delivery Periods</AlertTitle>
      The CSV file you have provided contains invalid delivery periods:
      <br/>

      {invalidDeliveryPeriodIDs.join(", ")}

      <br/>
      <br/>
      Please create delivery periods before uploading points
    </Alert>
  ) : (
    <></>
  );

  useEffect(() => {
    if (dataChanged) {
      setDataChanged(false);

      var invalidDatePeriods = []

      var periods = {};

      configurations.forEach((config) => {
        if(config.application === "SaM" && config.mainkey === "SEC" && config.subkey === "DeliveryPeriod") {
          periods[config.code] = true;
        }
      })
      
      let modifiedCSVRows = [];

      csvData.forEach((row) => {

        if (periods[row.DeliveryPeriod] !== true) {
          invalidDatePeriods.push(row.DeliveryPeriod)
        }

        const updateRow = {
          Id: (row.Id === "" || (row.Id === undefined || row.Id === null)) ? UUID() : row.Id,
        Site: row.Site,
        DeliveryPeriod: row.DeliveryPeriod,
        MaterialNumber: row.MaterialNumber,
        InvoiceThresholdPrice: row.InvoiceThresholdPrice,
        Point: row.Point,
        // CreatedDate: moment().toISOString(),
        // CreatedBy:
        //   (row.CreatedBy === "" || (row.CreatedBy === undefined || row.CreatedBy === null))
        //     ? userId
        //     : row.CreatedBy,
        // ModifiedBy: userId,
        // ModifiedDate: moment().toISOString(),
        };

        modifiedCSVRows.push(updateRow);
      });

      setSubmitEnabled(true);
      
    }
  }, [dataChanged]);

  const createRequestBody = () => {
    let toReturn = [];

    csvData.forEach((row) => {
      const requestBody = {
        Id: row.Id,
        Site: parseInt(row.Site),
        DeliveryPeriod: row.DeliveryPeriod,
        MaterialNumber: row.MaterialNumber,
        InvoiceThresholdPrice: parseFloat(row.InvoiceThresholdPrice),
        Point: parseInt(row.Point),
        // CreatedDate: moment().toISOString(),
        // CreatedBy:userId,
        // ModifiedBy: userId,
        // ModifiedDate: moment().toISOString(),
      };

      toReturn.push(requestBody);
    });

    return toReturn;
  };

  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => {
          const data = createRequestBody();
          const site = loggedInUser.territory === "au" ? 1 : 2;
          dispatch(
            uploadSECPointsData(data, site, (res) => {
              if (res === false) {
                res = {message: "Upload failed, check file for duplicate products"};
              } else {
                res.message = "SEC Points Upload Successful";
              }
              setPreviewData(res);
              setModalShow(true);
            })
          );
        }}
        disabled={!submitEnabled}
      >
        Submit
      </Button>,
    ];
  };

  function MyVerticallyCenteredModal() {
    return (
      <Modal
        style={{ color: "green" }}
        title={
          <div>
            <Typography variant="h4" align="center">
              <CheckCircleIcon />
            </Typography>
            <Typography align="center" variant="h5">
              {previewData.message}
            </Typography>
          </div>
        }
        open={modalShow}
        fullWidth={true}
        actions={
          <div>
            <br />
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setModalShow(false);
              }}
            >
              Close
            </Button>
          </div>
        }
      >
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "20vh",
          }}
        >
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Updated SEC Points: </b>
            <span style={{ fontSize: "24px" }}>{previewData.updated}</span>
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Created SEC Points: </b>
            <span style={{ fontSize: "24px" }}>{previewData.created}</span>
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Deleted SEC Points: </b>
            <span style={{ fontSize: "24px" }}>{previewData.deleted}</span>
          </Typography>
        </div>
      </Modal>
    );
  }

  return (
    <Box>
      <Fragment>
        {isUploading ? (
          <>
            <HeaderBlock
              title={"Please Wait: SEC Points Data is being uploaded"}
            />
            <PageContainer>
              <LoadingComponent></LoadingComponent>
            </PageContainer>
          </>
        ) : (
          <>
            <HeaderBlock
              title={"SEC Points Bulk Upload"}
              right={
                renderSubmitAndCancelButtons() ? (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          const site = loggedInUser.territory === "au" ? 1 : 2;
                          dispatch(downloadSECPointsData(site, (res) => {}));
                        }}
                      >
                        Download CSV
                      </Button>
                    </Grid>
                    <Grid item>
                      <ButtonGroup>
                        {renderSubmitAndCancelButtons()}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                ) : null
              }
            />

            <PageContainer>
              {csvErrorElement}
              {invalidDelvieryPeriodsErrorElement}
              <div>
                <Grid container spacing={3}>
                  <Grid
                    key={"migrationUploadSegment"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <MigrationUploadSegment
                      csvData={csvData}
                      setCsvData={setCsvData}
                      setCSVInvalid={(option) => {
                        //TODO Show Error message if csv is invalid
                        if (option) {
                          setCsvInvalidError(true);
                          setTimeout(() => {
                            setCsvInvalidError(false);
                          }, 30000);
                        }
                        //debugger;
                      }}
                      setDataChanged={setDataChanged}
                      title="Upload SEC Points"
                      targetsEdited={targetsEdited}
                      setTargetsEdited={setTargetsEdited}
                      isEditing={isEditing}
                      setFormEdited={setFormEdited}
                      description="WARNING: This will delete all existing SEC Points in the database and replace them with the data from the CSV. Please make sure you have all the existing SEC Points data before uploading. Please upload a CSV containing the SEC points data that you would like uploaded to SaM-Core."
                      columns={[
                        "Id",
                        "Site",
                        "DeliveryPeriod",
                        "MaterialNumber",
                        "InvoiceThresholdPrice",
                        "Point",
                        // "CreatedBy",
                        // "CreatedDate",
                        // "ModifiedBy",
                        // "ModifiedDate",
                      ]}
                      allDataUrl={"templateUrl"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </Grid>
              </div>
            </PageContainer>
          </>
        )}
      </Fragment>
    </Box>
  );
};

const hoc = withRouter(SECPointsUploadPage);

// EXPORT COMPONENT
export { hoc as SECPointsUploadPage };
