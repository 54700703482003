export const ImageMigrationExpectedColumns = [
  "FileName",
  "ContentType",
  "RetentionPolicy",
  "Title",
  "Description",
  "Keywords",
  "ContentLanguage",
  "Territory",
  "DateFrom",
  "DateTo",
  "isInternal",
  "TargetIds"
];

export const SaMCNewLeadsMigrationExpectedColumns = [
  "FileName",
  "ContentType",
  "RetentionPolicy",
  "Title",
  "Description",
  "Keywords",
  "ContentLanguage",
  "Territory",
  "DateFrom",
  "DateTo",
  "isInternal",
  "isActive"
];

export const ContentMigrationExpectedColumns = [
  "FileName",
  "Territory",
  "Type",
  "ContentCategory",
  "ContentTitle",
  "ContentDescription",
  "ContentLanguage",
  "ContentRetentionPolicy",
  "ContentKeywords",
  "ContentEffectiveStartDate",
  "ContentEffectiveEndDate",
  "IsInternal",
  "CampaignTitle",
  "Description",
  "StartDate",
  "EndDate",
  "ActivityId",
  "CustomerId",
  "MaterialId",
  "PromotionId",
  "TargetGroupNames",
  "TargetGroupIds",
  "ContractExpiryDate",
  "SelectedApplications"
];
export const LinkMigrationExpectedColumns = [
  "Link_URL",
  "Territory",
  "LinkCategory",
  "LinkTitle",
  "LinkDescription",
  "LinkLanguage",
  "LinkRetentionPolicy",
  "LinkKeywords",
  "LinkEffectiveStartDate",
  "LinkEffectiveEndDate",
  "IsInternal",
  "CampaignTitle",
  "Description",
  "StartDate",
  "EndDate",
  "ActivityId",
  "CustomerId",
  "MaterialId",
  "PromotionId",
  "TargetGroupNames",
  "TargetGroupIds",
  "ContractExpiryDate"
];

export const PPPMigrationExpectedColumns = [
  "Site",
  "AssociationTypeId",
  "Association",
  "Score",
  "Discount",
  "IsActive",
  "CreatedDate",
  "ModifiedDate"
];

export const CoreRangeColumn = {
  Door: 'Door',
  VersionNumber: "Version Number",
  Threshold: "Threshold",
  Description: "Description",
  RangePoints: "Range Points",
  ProductNumber: "Product Number",
  ProductGroupTargetID: "Product Group Target ID",
  ContractedRange: "Contracted Range",
  SortOrder: "Display Sequence"
}

export const CoreRangeExpectedColumns = [
  CoreRangeColumn.Door,
  CoreRangeColumn.VersionNumber,
  CoreRangeColumn.Threshold,
  CoreRangeColumn.RangePoints,
  CoreRangeColumn.ProductNumber,
  CoreRangeColumn.ProductGroupTargetID,
  CoreRangeColumn.ContractedRange,
  CoreRangeColumn.SortOrder
];



export const PPPTargetExpectedColumns = [
  "Id",
  "Site",
  "RegionCode",
  "Sequence",
  "IsActive",
  "City",
  "PostalCode",
  "PrecinctId",
  "MarketTypeId",
  "TradeChannelId",
  "SubTradeChannelId",
  "OperationalRegionId",
  "LicenseTypeId",
  "GlobalCustomerNumberId",
  "ServicePolicyId",
  "SalesPolicyId",
  "PreferredOrderMethodId",
  "BusinessTypeId",
  "SalesOfficeId",
  "DistributionChannelId",
  "BlackCodeId",
  "RedCodeId",
  "L2CustomerNumber",
  "L3CustomerNumber",
  "L4CustomerNumber",
  "PriceBookId",
  "CustomerTypeId",
  "TradeNameId",
  "BusinessOwnerId",
  "L5CustomerNumber",
  "ClocCodeId",
];
