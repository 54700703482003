import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({});

export default function DropdownSelect(props) {
  const [labelWidth, setLabelWidth] = React.useState(0);

  const inputLabel = React.useRef(null);

  const classes = useStyles();

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  let value = props.value || "";
  if (value === "" && props.multiple) {
    value = [];
  }

  return (
    <FormControl
      disabled={props.disabled}
      variant="outlined"
      margin="dense"
      fullWidth={props.fullWidth}
      error={props.error}
    >
      <InputLabel ref={inputLabel} className={classes.label}>
        {props.label}
      </InputLabel>

      <Select
        className={props.classes}
        value={value}
        onChange={(event) => props.handleChange(event.target.value)}
        input={<OutlinedInput labelWidth={labelWidth} />}
        autoWidth
        multiple={props.multiple}
      >
        {props.data.map((item) => {
          return (
            <MenuItem
              key={item.key ? item.key : item}
              value={item.key ? item.key : item}
            >
              {(item.value
                ? item.value
                : item != null
                ? item
                : "None"
              ).toString()}
            </MenuItem>
          );
        })}
      </Select>
      {props.children}
    </FormControl>
  );
}
