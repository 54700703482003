export const TargetedContentModel = () => {
  return {
    contentId: {
      type: "string",
      required: false,
      errorMessage: "",
      label: "Content Id"
    },
    file: {
      type: "file",
      required: true,
      errorMessage: "",
      label: "File"
    },
    internal: {
      type: "checkbox",
      required: false,
      errorMessage: "",
      label: "Internal"
    },
    active: {
      type: "checkbox",
      required: false,
      errorMessage: "",
      label: "Active"
    },
    contentType: {
      type: "select",
      required: true,
      errorMessage: "",
      label: "Content Type"
    },
    category: {
      type: "select",
      required: true,
      errorMessage: "",
      label: "Category"
    },
    link: {
      type: "link",
      required: true,
      errorMessage: "",
      label: "Link"
    },
    title: {
      type: "text",
      required: true,
      errorMessage: "",
      label: "Title"
    },
    description: {
      type: "text",
      required: true,
      errorMessage: "",
      label: "Description"
    },

    language: {
      type: "select",
      required: true,
      errorMessage: "",
      label: "Language"
    },
    retentionPolicy: {
      type: "select",
      required: true,
      errorMessage: "",
      label: "Retention Policy"
    },
    territory: {
      type: "select",
      required: true,
      errorMessage: "",
      label: "Site"
    },
    keywords: {
      type: "text",
      required: false,
      errorMessage: "",
      label: "Keywords"
    },
    startDate: {
      type: "date",
      required: true,
      errorMessage: "",
      label: "Start Date"
    },
    endDate: {
      type: "date",
      required: true,
      errorMessage: "",
      label: "End Date"
    },
    contractExpiry: {
      type: "date",
      required: false,
      errorMessage: "",
      label: "Contract Expiry Date"
    }
  };
};
