import { useState } from "react";

const useValidate = () => {
  const [displayGroupDescriptionError, setDisplayGroupDescriptionError] =
    useState(false);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [displayStartDateError, setDisplayNameStartError] = useState(false);
  const [displayEndDateError, setDisplayNameEndDateError] = useState(false);
  const [displayFrequencyError, setDisplayFrequencyError] = useState(false);
  const [displayMinError, setDisplayMinError] = useState(false);
  const [displayMaxError, setDisplayMaxError] = useState(false);
  const [displaySequenceError, setDisplaySequenceError] = useState(false);

  return {
    displayGroupDescriptionError,
    setDisplayGroupDescriptionError,
    displayNameError,
    setDisplayNameError,
    displayStartDateError,
    setDisplayNameStartError,
    displayEndDateError,
    setDisplayNameEndDateError,
    displayFrequencyError,
    setDisplayFrequencyError,
    displayMinError,
    setDisplayMinError,
    displayMaxError,
    setDisplayMaxError,
    displaySequenceError,
    setDisplaySequenceError,
  };
};

export { useValidate };
