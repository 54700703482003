// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import { Box, Button, ButtonGroup, Grid } from "@material-ui/core";
import styles from "./MigrationPage.module.scss";

// Actions
import { MassCreation } from "../../state/actions/CampaignActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import PageContainer from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/circleLoader";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import MassContentUploadSegment from "../../segments/content-upload-segments/MigrationContentUploadSegment";
import { UUID } from "../../helpers/uuid";

// Other
import { ContentMigrationExpectedColumns } from "../../state/constants/TemplateColumns";

// Moment
var moment = require("moment");
moment().format();

const TargetedContentMigrationPage = (props) => {
  const [genericContentData, setGenericContentData] = useState({
    prefile: [
      {
        type: "file",
        value: null,
        required: true,
        errorMessage: "",
        label: "File"
      }
    ],
    file: []
  });
  const [isUploading, setIsUploading] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [cancelAlertShowing, setCancelAlertShowing] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [csvInvalid, setCSVInvalid] = useState(false);

  const targetIds = useSelector(
    (state) => state.ResourceImageUploaderReducer.targetIds
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (targetsEdited) {
      setSubmitEnabled(true);
    }
  }, [targetsEdited]);

  useEffect(() => {
    if (dataChanged) {
      setDataChanged(false);
    }
  }, [dataChanged]);

  useEffect(() => {
    let localTargets = [];
    setCsvData(localTargets);
  }, [targetIds]);

  const handleSubmit = async () => {
    setIsUploading(true);
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    const data = createRequestBody();

    dispatch(
      MassCreation(data, genericContentData.file, (res) => {
        if (res) {
          props.history.push("/post-migration");
        }
      })
    );
  };

  // Generate the target groups for the Campaign if needed
  function getTargetGroups(row) {
    var targetGroups = [];
    var targetGroupNames = row.TargetGroupNames !== null ? row.TargetGroupNames.split("|") : [];
    
    if (
      row.hasOwnProperty("ActivityId") &&
      row.ActivityId !== null &&
      row.ActivityId !== "" &&
      row.ActivityId.length > 0
    ) {
      var activityIds = row.ActivityId.split("|");
      targetGroups.push({
        ModelType: "Activity",
        Description: "",
        CreatedBy: "Mass Migration",
        // Name cannot be null, default to the campaign title
        Name: targetGroupNames.length > 0 ? targetGroupNames[targetGroups.length] : row.CampaignTitle, 
        TargetGroupRules: [
          {
            Name: "Activity_Id",
            Operator: "eq",
            Values: activityIds
          },
          {
            Name: "Activity_Site",
            Operator: "eq",
            Values: [row.Territory]
          }
        ]
      });
    }
    if (
      row.hasOwnProperty("CustomerId") &&
      row.CustomerId !== null &&
      row.CustomerId !== "" &&
      row.CustomerId.length > 0
    ) {
      var customerIds = row.CustomerId.split("|");
      targetGroups.push({
        ModelType: "Customer",
        Description: row.Description,
        CreatedBy: "Mass Migration",
        // Name cannot be null, default to the campaign title
        Name: targetGroupNames.length > 0 ? targetGroupNames[targetGroups.length] : row.CampaignTitle, 
        TargetGroupRules: [
          {
            Name: "Customer_Number",
            Operator: "eq",
            Values: customerIds
          },
          {
            Name: "Customer_Site",
            Operator: "eq",
            Values: [row.Territory]
          }
        ]
      });
    }
    if (
      row.hasOwnProperty("PromotionId") &&
      row.PromotionId !== null &&
      row.PromotionId !== "" &&
      row.PromotionId.length > 0
    ) {
      var promotionIds = row.PromotionId.split("|");
      targetGroups.push({
        ModelType: "Promotion",
        Description: "",
        CreatedBy: "Mass Migration",
        // Name cannot be null, default to the campaign title
        Name: targetGroupNames.length > 0 ? targetGroupNames[targetGroups.length] : row.CampaignTitle, 
        TargetGroupRules: [
          {
            Name: "Promotion_Id",
            Operator: "eq",
            Values: promotionIds
          },
          {
            Name: "Promotion_Site",
            Operator: "eq",
            Values: [row.Territory]
          }
        ]
      });
    }
    if (
      row.hasOwnProperty("MaterialId") &&
      row.MaterialId !== null &&
      row.MaterialId !== "" &&
      row.MaterialId.length > 0
    ) {
      var materialIds = row.MaterialId.split("|");
      targetGroups.push({
        ModelType: "Material",
        Description: "",
        CreatedBy: "Mass Migration",
        // Name cannot be null, default to the campaign title
        Name: targetGroupNames.length > 0 ? targetGroupNames[targetGroups.length] : row.CampaignTitle, 
        TargetGroupRules: [
          {
            Name: "Material_Number",
            Operator: "eq",
            Values: materialIds
          },
          {
            Name: "Material_Site",
            Operator: "eq",
            Values: [row.Territory]
          }
        ]
      });
    }
    return targetGroups;
  }

  const isNumeric = (str) => {
    return !isNaN(str - parseFloat(str));
  }

  function getCampaign(row) {
    let TargetGroupIds = row.TargetGroupIds !== null ? row.TargetGroupIds.split("|") : [];
    let SelectedApplications = row.SelectedApplications !== null ? row.SelectedApplications.split("|") : [];
    SelectedApplications.forEach(app => {
      return app.trim();
    });

    return {
      Name: row.CampaignTitle,
      Description: row.Description,
      Territory: row.Territory,
      StartDate: moment(row.StartDate, "DD/MM/YYYY").toISOString(),
      EndDate: moment(row.EndDate, "DD/MM/YYYY").toISOString(),
      ContractExpiryDate: moment(
        row.ContractExpiryDate,
        "DD/MM/YYYY"
        ).toISOString(),
      Targets: {
        TargetContentIds: [],
        TargetGroups:
        TargetGroupIds[0] !== ""
          ? TargetGroupIds.map(function (item) {
            // If this is a number, set it to Counter.
            if (isNumeric(item)) {
              return {
                Counter: item,
                IsExcluding: false
              };
            }

            // Otherwise, set it to TargetGroupId. Let the API validates if this is a valid GUID.
            return {
              TargetGroupId: item,
              IsExcluding: false
            };
          })
          : []
      },
      SelectedApplications: SelectedApplications,
    };
  }

  function getContent(row) {
    return {
      FileName: row.FileName,
      ContentPath: "",
      TypeId: row.Type,
      CategoryId: row.ContentCategory,
      Territory: row.Territory,
      Title: row.ContentTitle,
      Description: row.ContentDescription,
      Languages: row.ContentLanguage,
      RetentionPolicyId: row.ContentRetentionPolicy,
      Keywords: row.ContentKeywords.split("|"),
      ContractExpiryDate: moment(
        row.ContractExpiryDate,
        "DD/MM/YYYY"
      ).toISOString(),
      StartDate: moment(
        row.ContentEffectiveStartDate,
        "DD/MM/YYYY"
      ).toISOString(),
      EndDate: moment(row.ContentEffectiveEndDate, "DD/MM/YYYY").toISOString(),
      IsInternal: row.IsInternal,
      IsActive: true
    };
  }

  const createRequestBody = () => {
    let toReturn = [];
    csvData.forEach((row) => {
      const requestBody = {};
      requestBody["Content"] = getContent(row);
      requestBody["TargetGroups"] = getTargetGroups(row);
      requestBody["Campaign"] = getCampaign(row);
      toReturn.push(requestBody);
    });
    return toReturn;
  };

  // This refers to the cancel button on the edit or create form
  const handleCancelEditButtonClicked = () => {
    setIsEditing(false);
    props.history.push(`/content-migration`);
  };

  const cancelAlertDialog = () => {
    const title = "Are you sure you want to go back?";
    return (
      <AlertDialog
        title={title}
        description={"Any changes made will be lost."}
        options={[
          {
            label: "Cancel",
            action: () => setCancelAlertShowing(false)
          },
          {
            label: "Confirm",
            action: () => {
              props.history.replace(`/image-uploader`);
              setIsEditing(false);
              setCancelAlertShowing(false);
            }
          }
        ]}
        isOpen={cancelAlertShowing}
      />
    );
  };

  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleSubmit()}
        disabled={!submitEnabled || csvInvalid}
      >
        Submit
      </Button>,
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleCancelEditButtonClicked()}
      >
        Cancel
      </Button>
    ];
  };

  return (
    <Box>
      <Fragment>
        {isUploading ? (
          <>
            <HeaderBlock
              title={"Please Wait: Campaigns and Target Groups are uploading"}
            />
            <PageContainer>
              <LoadingComponent></LoadingComponent>
            </PageContainer>
          </>
        ) : (
            <>
              <HeaderBlock
                title={"Content Mass Upload"}
                right={
                  renderSubmitAndCancelButtons() ? (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justify="flex-end"
                    >
                      <Grid item>
                        <ButtonGroup>
                          {renderSubmitAndCancelButtons()}
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  ) : null
                }
              />

              <PageContainer>
                <div className={styles.contentGrid}>
                  <Grid container spacing={3}>
                    <Grid
                      key={"migrationUploadSegment"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <MassContentUploadSegment
                        genericContentData={genericContentData}
                        setGenericContentData={setGenericContentData}
                        setDataChanged={setDataChanged}
                        title="Content File Upload"
                        isEditing={isEditing}
                        formEdited={formEdited}
                        setFormEdited={setFormEdited}
                        isNewContent={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid
                      key={"migrationUploadSegment"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <MigrationUploadSegment
                        csvData={csvData}
                        setCsvData={setCsvData}
                        setDataChanged={setDataChanged}
                        title="Content Metadata CSV Upload"
                        targetsEdited={targetsEdited}
                        setTargetsEdited={setTargetsEdited}
                        isEditing={isEditing}
                        setFormEdited={setFormEdited}
                        description="Please upload a CSV containing the Metadata that you would like to attach to the uploaded files"
                        templateFileName="ContentMigrationTemplate.csv"
                        expectedColumns={ContentMigrationExpectedColumns}
                        setCSVInvalid={setCSVInvalid}
                      />
                    </Grid>
                  </Grid>
                </div>
                {cancelAlertDialog()}
              </PageContainer>
            </>
          )}
      </Fragment>
    </Box>
  );
};

const hoc = withRouter(TargetedContentMigrationPage);

// EXPORT COMPONENT
export { hoc as TargetedContentMigrationPage };
