import {
  GET_RESOURCE_IMAGES,
  CLEAR_RESOURCE_IMAGES,
  GET_RESOURCE_IMAGE_URLS,
  SET_CURRENT_RESOURCE_IMAGE
} from "../constants/action-types";

// INITIALIZE STATE
const initialState = {
  resourceImages: {},
  currentResourceImage: {}
};

// REDUCER
export const ResourceImageManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESOURCE_IMAGES: {
      if (action.payload) {
        return {
          ...state,
          resourceImages: action.payload
        };
      } else {
        return {
          ...state
        };
      }
    }
    case GET_RESOURCE_IMAGE_URLS: {
      if (action.payload) {
        return {
          ...state,
          resourceImageUrls: action.payload
        };
      } else {
        return {
          ...state
        };
      }
    }
    case CLEAR_RESOURCE_IMAGES: {
      return {
        ...state,
        resourceImages: {}
      };
    }
    case SET_CURRENT_RESOURCE_IMAGE: {
      return {
        ...state,
        currentResourceImage: action.payload
      };
    }
    default:
      return state;
  }
};
