// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styles from "./DrawerItems.module.scss";
import { ListSubheader, Collapse, Icon } from "@material-ui/core";

// Actions
import { getRolesForLoggedInUser } from "../../state/actions/RoleActions";

// Other
import { getIcon } from "../../icon/icon";
import { UserRoles } from "../../state/constants/UserRoles";

export default function DrawerItems(props) {
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [items, setItems] = useState([]);
  const [openHeaders, setOpenHeaders] = useState([]);

  useEffect(() => {
    dispatch(getRolesForLoggedInUser(() => { }));
  }, []);

  useEffect(() => {

  }, [openHeaders]);

  useEffect(() => {
    if (loggedInUserRoles) {
      generateItems();
    }
  }, [loggedInUserRoles]);

  const handleClick = (route) => {
    route === '/wmd' ? window.open(`${route}`) :
      props.history.push(route);
    // setOpen(!open);

    props.handleClick();
  };

  const handleHeaderCollapseToggle = (header) => {
    // Clone the current state
    let localCollapseState = Object.assign({}, openHeaders);

    // Invert the target headers value, closing or opening it.
    localCollapseState[header] = !localCollapseState[header];

    // update state.
    setOpenHeaders(localCollapseState);
  }

  const generateItems = () => {
    const {
      SAM_CORE_ADMIN,
      CORE_RANGE_ADMIN,
      CORE_RANGE_USER,
      USER_MANAGER,
      CONTENT_MANAGER,
      CONTENT_ADMIN,
      CONTENT_TARGETING_MANAGER,
      ACTIVITY_MANAGER,
      MYCCA_CONTENT_ADMIN,
      SAM_M_ADMIN,
      MY_DISPLAYS_ADMIN,
      SEC_ADMIN,
      NEW_LEADS_ADMIN,
      SAMA_ADMIN,
      WHERE_IS_MY_DELIVERY,
      RRC_VIEW
    } = UserRoles;

    if (Object.keys(loggedInUserRoles).length > 0) {
      let items = [];
      items.push({
        icon: "home",
        name: "Home",
        route: "/",
        type: "link",
        roles: []
      });

      /* User and role management */
      items.push({
        id: "userandroles",
        name: "User and Role Management",
        type: "header",
        roles: [SAM_CORE_ADMIN, USER_MANAGER],
        children: [
          {
            icon: "group",
            name: "Users",
            route: "/users",
            type: "link",
            roles: [SAM_CORE_ADMIN, USER_MANAGER]
          },
          {
            icon: "manage_accounts",
            name: "Roles",
            route: "/roles",
            type: "link",
            roles: [SAM_CORE_ADMIN, USER_MANAGER]
          }
        ]
      });

      /* sitecore image management */
      items.push({
        id: 'sitecore',
        name: "Sitecore - Image Management",
        type: "header",
        roles: [SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN],
        children: [{
          icon: "add_photo_alternate",
          name: "Image Upload",
          route: "/image-upload",
          type: "link",
          roles: [SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]
        },
        {
          icon: "collections",
          name: "Image Manager",
          route: "/image-manager",
          type: "link",
          roles: [SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]
        }]
      });

      /* Mycca image management */
      items.push({
        id: "mycca",
        name: "MyCCA - Image Management",
        type: "header",
        roles: [SAM_CORE_ADMIN, MYCCA_CONTENT_ADMIN],
        children: [{
          icon: "add_photo_alternate",
          name: "Image Upload",
          route: "/mycca-image-upload",
          type: "link",
          roles: [SAM_CORE_ADMIN, MYCCA_CONTENT_ADMIN]
        },
        {
          icon: "collections",
          name: "Image Manager",
          route: "/mycca-content",
          type: "link",
          roles: [SAM_CORE_ADMIN, MYCCA_CONTENT_ADMIN]
        }]
      });

      /* sam-c */
      items.push({
        id: "samc",
        name: "New Leads / SaM-C",
        type: "header",
        roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER],
        children: [
          {
            icon: "bolt",
            name: "New Leads / SaM-C",
            route: "/samc-newleads-upload",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          }
        ]
      });

      /* targeting */
      items.push({
        id: "targeting",
        name: "Targeting",
        type: "header",
        roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER],
        children: [
          {
            icon: "file_upload",
            name: "File / Link Upload",
            route: "/content-upload",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          },
          {
            icon: "adjust",
            name: "Target Groups",
            route: "/target-groups",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          },
          {
            icon: "campaign",
            name: "Campaigns",
            route: "/campaigns",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          }
        ]
      });

      /* 3d pos stuff */
      items.push({
        id: "3dpos",
        name: "3D POS",
        type: "header",
        roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER],
        children: [
          {
            icon: "ondemand_video",
            name: "3D POS Manager",
            route: "/three-dimensional-pos",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          }
        ]
      });


      /* mass uploaders */
      items.push({
        id: "massupload",
        name: "Mass Upload",
        type: "header",
        roles: [
          SAM_CORE_ADMIN,
          CORE_RANGE_ADMIN,
          CONTENT_TARGETING_MANAGER,
          CONTENT_MANAGER,
          CONTENT_ADMIN
        ],
        children: [
          {
            icon: "drive_folder_upload",
            name: "Image Mass Upload",
            route: "/image-migration",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]
          },
          {
            icon: "cloud_upload",
            name: "Content Mass Upload",
            route: "/content-migration",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          },
          {
            icon: "link",
            name: "Targeted Links Mass Upload",
            route: "/link-migration",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          },
          {
            icon: "bolt",
            name: "SaMC / New Leads Mass Upload",
            route: "/samc-newleads-migration",
            type: "link",
            roles: [SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]
          },
          {
            icon: "cloud_upload",
            name: "Customer Door Uploader",
            route: "/customer-door-uploader",
            type: "link",
            roles: [CORE_RANGE_ADMIN]
          }
        ]
      });

      items.push({
        id: "sec",
        name: "SEC",
        type: "header",
        roles: [SEC_ADMIN],
        children: [
          {
            icon: "add_business",
            name: "Customer Upload",
            route: "/sec-customer-uploader",
            type: "link",
            roles: [SEC_ADMIN]
          },
          {
            icon: "scoreboard",
            name: "Points Upload",
            route: "/sec-points-uploader",
            type: "link",
            roles: [SEC_ADMIN]
          },
          {
            icon: "format_list_numbered",
            name: "SaM Configuration",
            route: "/sam-configuration-upload",
            type: "link",
            roles: [SEC_ADMIN]
          }
        ]
      });

      items.push({
        id: "newleads",
        name: "New Leads",
        type: "header",
        roles: [SAM_CORE_ADMIN, NEW_LEADS_ADMIN],
        children: [
          {
            icon: "map",
            name: "New Leads Map",
            route: "/new-leads-map",
            type: "link",
            roles: [SAM_CORE_ADMIN, NEW_LEADS_ADMIN]
          },
          {
            icon: "upload",
            name: "Bulk Update Leads",
            route: "/bulk-update-leads",
            type: "link",
            roles: [SAM_CORE_ADMIN, NEW_LEADS_ADMIN]
          }
        ]
      });

      //Activities
      items.push({
        id: "activities",
        name: "Activity Management",
        type: "header",
        roles: [SAM_CORE_ADMIN, ACTIVITY_MANAGER],
        children: [
          {
            icon: "campaign",
            name: "Activity Groups",
            route: "/activity-groups",
            type: "link",
            roles: [SAM_CORE_ADMIN, ACTIVITY_MANAGER]
          },
          {
            icon: "calculate",
            name: "SFE Activation",
            route: "/sfe-activation",
            type: "link",
            roles: [SAM_CORE_ADMIN, ACTIVITY_MANAGER]
          },
          {
            icon: "price_check",
            name: "PPP Mass Upload",
            route: "/ppp-mass-upload",
            type: "link",
            roles: [SAM_CORE_ADMIN, ACTIVITY_MANAGER]
          }
        ]
      });

      items.push({
        id: "corerange",
        name: "Core Range",
        type: "header",
        roles: [SAM_CORE_ADMIN, CORE_RANGE_USER],
        children: [
          {
            icon: "low_priority",
            name: "Core Range",
            route: "/core-range",
            type: "link",
            roles: [SAM_CORE_ADMIN, CORE_RANGE_USER]
          }
        ]
      });

      items.push({
        id: "wmd",
        name: "Where's My Delivery",
        type: "header",
        roles: [WHERE_IS_MY_DELIVERY],
        children: [
          {
            icon: "local_shipping",
            name: "Where's My Delivery",
            route: "/wmd",
            type: "link",
            roles: [WHERE_IS_MY_DELIVERY],
          }
        ]
      });

      // Regional Return Cockpit
      items.push({
        id: "rrcorder",
        name: "Regional Return Cockpit",
        type: "header",
        roles: [RRC_VIEW],
        children: [
          {
            icon: "rv_hookup",
            name: "View RRC Order",
            route: "/view-regional-returns",
            type: "link",
            roles: [RRC_VIEW],
          },
          {
            icon: "rv_hookup",
            name: "Maintain RRC Inventory",
            route: "/maintain-regional-returns-inventory",
            type: "link",
            roles: [RRC_VIEW],
          }
        
        ]
      });
      // myDisplays Maintenance
      items.push({
        id: "myDisplaysMaintenance",
        name: "myDisplays",
        type: "header",
        roles: [SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN],
        children: [
          {
            icon: "image_aspect_ratio",
            name: "Planning",
            route: "/my-displays-planning",
            type: "link",
            roles: [SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]
          },
          {
            icon: "engineering",
            name: "Maintenance",
            route: "/my-displays-maintenance",
            type: "link",
            roles: [MY_DISPLAYS_ADMIN]
          },
          {
            icon: "upload",
            name: "Stockweight Targets",
            route: "/stockweight-targets-upload",
            type: "link",
            roles: [SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]
          }
        ]
      })

      // additional maint
      items.push({
        id: "sama",
        name: "SaMa Programmes",
        type: "header",
        roles: [SAMA_ADMIN],
        children: [
          {
            icon: "ballot",
            name: "SaMa Programmes",
            route: "/samaprogrammes",
            type: "link",
            roles: [SAMA_ADMIN],
          },
          {
            icon: "construction",
            name: "Agreement Specifications",
            route: "/agreementspecifications",
            type: "link",
            roles: [SAMA_ADMIN],
          },
          {
            icon: "check_circle",
            name: "Programme Discrepancy Check",
            route: "/programme-discrepancy-check",
            type: "link",
            roles: [SAMA_ADMIN],
          },
        ],
      });

      // additional maint
      items.push({
        id: "wamaint",
        name: "Maintenance",
        type: "header",
        roles: [SAM_CORE_ADMIN],
        children: [
          {
            icon: "construction",
            name: "Content Categories",
            route: "/categories",
            type: "link",
            roles: [SAM_CORE_ADMIN]
          },
          {
            icon: "construction",
            name: "Application Types",
            route: "/applications",
            type: "link",
            roles: [SAM_CORE_ADMIN]
          },
          {
            icon: "construction",
            name: "MyCCA Image Types",
            route: "/mycca-types",
            type: "link",
            roles: [SAM_CORE_ADMIN, MYCCA_CONTENT_ADMIN]
          }
        ]
      });

      setItems(
        items.filter((item) => {
          let success = item.roles.length === 0;

          for (let i = 0; i < item.roles.length; i++) {
            if (success) {
              return success;
            }
            success = loggedInUserRoles.includes(item.roles[i]);
          }

          return success;
        })
      );
    }
  };

  const isActive = (route) => {
    return props.history.location.pathname.endsWith(route);
  };

  const renderItem = (item) => {
    return (
      <ListItem
        key={item.route}
        selected={isActive(item.route)}
        onClick={() => {
          handleClick(item.route);
        }}
        button
      >
        <ListItemIcon><Icon color="primary">{item.icon}</Icon></ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    );
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">

      {items.map((item) => {
        let listItemMarkup = null;

        if (item.type === "header") {
          if (item.children) {
            listItemMarkup = (
              <Fragment key={`${item.id}-header`}>
                <ListSubheader onClick={() => handleHeaderCollapseToggle(item.id)} >
                  {item.name}{openHeaders[item.id] ?
                    <ExpandLess fontSize="inherit" /> :
                    <ExpandMore fontSize="inherit" />}
                </ListSubheader >
                <Collapse in={!openHeaders[item.id]} timeout="auto" unmountOnExit>
                  {item.children != undefined ? item.children.map((childItem) => {
                    return (
                      <List
                        key={`${childItem.route}${childItem.id}`}
                        component="div"
                        disablePadding
                      >
                        {renderItem(childItem)}
                      </List>
                    );
                  }) : null}
                </Collapse>
              </Fragment>
            );
          } else {
            listItemMarkup = (
              <Fragment>
                <ListSubheader key={`${item.name}-header`}>{item.name}</ListSubheader>
              </Fragment>
            )
          }
        } else if (item.type === "link") {
          listItemMarkup = (renderItem(item))
        }

        return listItemMarkup;
      })}
    </List>
  );
}
