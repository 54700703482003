import {
  GET_ALL_CONTENT,
  GET_CONTENT_BY_ID,
  GET_SIZE_METADATA,
  CREATE_NEW_CONTENT,
  UPDATE_CONTENT
} from "../constants/action-types";

import { GenericContentModel } from "../../models/GenericContentModel";

// INITIALIZE STATE
const initialState = {
  allContent: [],
  content: {},
  targetIds: [],
  failures: [],
  successes: [],
  UploadFailures: [],
  UploadSuccesses: [],
  sizeData: []
};

function createNewContent(serverContent) {
  let content = GenericContentModel();

  if (serverContent) {
    content.contentId.value = serverContent.contentId
      ? serverContent.contentId
      : "";
    content.file.value = serverContent.associatedResources
      ? serverContent.associatedResources
      : [];
    content.title.value = serverContent.title ? serverContent.title : "";

    content.contentType.value = serverContent.typeId
      ? serverContent.typeId
      : "";

    content.internal.value = serverContent.isInternal
      ? serverContent.isInternal
      : false;
    content.active.value = serverContent.isActive
      ? serverContent.isActive
      : false;
    content.description.value = serverContent.description
      ? serverContent.description
      : "";
    content.language.value = serverContent.associatedLanguages
      ? serverContent.associatedLanguages
      : [];

    content.territory.value = serverContent.territory
      ? serverContent.territory
      : "";
    content.startDate.value = serverContent.startDate
      ? new Date(serverContent.startDate + 'Z')
      : "";
    content.endDate.value = serverContent.endDate
      ? new Date(serverContent.endDate + 'Z')
      : "";

    content.retentionPolicy.value = serverContent.retentionPolicyId
      ? serverContent.retentionPolicyId
      : "";

    content.keywords.value = serverContent.keywords
      ? serverContent.keywords.join(",")
      : "";

    content.category.value = serverContent.categoryId
      ? serverContent.categoryId
      : "";
  } else {
    content = {
      id: "",
      file: [],
      title: "",
      contentType: "",
      internal: false,
      active: false,
      description: "",
      language: "",
      territory: "",
      startDate: "",
      endDate: "",
      relatedIds: [],
      retentionPolicy: ""
    };
  }

  return content;
}

// REDUCER
export const ResourceImageUploaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTENT: {
      return {
        ...state,
        allContent: action.payload
      };
    }
    case GET_CONTENT_BY_ID: {
      let content = createNewContent(action.payload);
      let targetIds = action.payload.associatedTargets.map(
        (target) => target.name
      );

      return {
        ...state,
        content,
        targetIds
      };
    }
    case GET_SIZE_METADATA: {
      return {
        ...state,
        sizeData: action.payload.data.defaultImageSizes
      };
    }
    case CREATE_NEW_CONTENT: {
      const allContent = Object.assign({}, state.allContent);
      const newContent = action.payload;

      allContent[newContent.id] = newContent;

      return {
        ...state,
        allContent
      };
    }
    case UPDATE_CONTENT: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
};
