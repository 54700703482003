export const ApplicationModel = {
    ApplicationId: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Id",
        label: "Id"
    },
    ApplicationName: {
        type: "string",
        required: true,
        errorMessage: "",
        value: "ApplicationName",
        label: "Name"
    }
}