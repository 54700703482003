import React from "react";

export default function BoxSelectCanvas(props) {
    
    const [draggingBox, setdraggingBox] = React.useState(false);
    const [boxStartPos, setBoxStartPos] = React.useState([0, 0]);

    const boxStartPosRef = React.useRef([0, 0]);


    const selectionCanvas = React.useRef(null);

    React.useEffect(() => {
        resetBoxSize();
    }, []);

    React.useEffect(() => {
        boxStartPosRef.current = boxStartPos
    }, [boxStartPos])

    function resetBoxSize() {
        const canvas = selectionCanvas.current
        canvas.width = canvas.offsetWidth
        canvas.height = canvas.offsetHeight
    }

    function clearCanvas() {
        const canvas = selectionCanvas.current
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
        context.beginPath()
    }

    function clickedCanvas(event) {
        resetBoxSize();

        var rect = event.target.getBoundingClientRect();
        setBoxStartPos([event.clientX - rect.left, event.clientY - rect.top])
        setdraggingBox(true)
    }

    function movedMouse(event) {
        if (draggingBox) {
            clearCanvas()

            const context = selectionCanvas.current.getContext('2d')

            var rect = event.target.getBoundingClientRect();
            const currentPos = boxStartPosRef.current
            var boxWidth = (event.clientX - rect.left) - currentPos[0]
            var boxHeight = (event.clientY - rect.top) - currentPos[1]

            context.strokeStyle = '#ffffff'
            context.fillStyle = "rgba(240, 240, 240, 0.5)";
            context.lineWidth = 1
            context.fillRect(currentPos[0], currentPos[1], boxWidth, boxHeight)
            context.rect(currentPos[0], currentPos[1], boxWidth, boxHeight)
            context.stroke()

        }
    }

    function releasedMouse(event) {
        const startPos = boxStartPosRef.current
        var rect = event.target.getBoundingClientRect();

        const extentWidth = (event.clientX - rect.left - startPos[0]) / 2
        const extentHeight = (event.clientY - rect.top - startPos[1]) / 2
        props.finishBoxSelection([startPos[0] + extentWidth, startPos[1] + extentHeight, Math.abs(extentWidth), Math.abs(extentHeight)])

        clearCanvas();
        setdraggingBox(false);

    }


    return (
        <canvas ref={selectionCanvas} onMouseDown={clickedCanvas} onMouseMove={movedMouse} onMouseUp={releasedMouse} style={{position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%"}} {... props}/>
    );
}
  