// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField
} from "@material-ui/core";
import styles from "./ViewCategoryPage.module.scss";

// Actions
import {
  upsertApplication,
  getApplicationById
} from "../../../state/actions/CampaignActions";
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import CustomCard from "../../../components/custom-card/CustomCard";
import DropdownSelect from "../../../components/dropdown-select/DropdownSelect";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";

// Models
import { ApplicationModel } from "../../../models/ApplicationModel";

// Other
import { FormType } from "../../../state/constants/FormType";
import { validateField } from "../../../helpers/validation";
import { sortDropdown } from "../../../helpers/sortDropdown";

const ViewApplicationPage = (props) => {
  const currentCallType = useSelector(
    (state) => state.CampaignReducer.currentApplication
  );

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(ApplicationModel);
  const [Application, setApplication] = useState({});
  const [uneditedApplication, setUneditedApplication] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingApplication, setIsExistingApplication] = useState(true);
  const [ApplicationId, setApplicationId] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [getApplicationCallMade, setGetApplicationCallMade] = useState(false);

  //Use Effects
  useEffect(() => {
    setLoading(true);

    setIsExistingApplication(
      props.type != null && props.type === FormType.VIEW
    );
  }, []);

  useEffect(() => {
    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getApplicationById(props.match.params.ApplicationId, () => {
          setGetApplicationCallMade(true);
          setApplicationId(props.match.params.ApplicationId);
        })
      );
    } else {
      dispatch(
        getApplicationById(undefined, () => {
          setGetApplicationCallMade(true);
          setIsEditing(true);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (getApplicationCallMade) {
      setLoading(false);
      setDataChanged(!dataChanged);
    }
  }, [getApplicationCallMade]);


  useEffect(() => {
    let localCallType = currentCallType;
    setApplication(localCallType);

  }, [currentCallType]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the Application
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, Application);

    localData[name] = value;

    setApplication(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      Application[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";
      errorMessage = checkGenericField(key, formProperties[key].required);
      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the applcation data into the form that is required by the backend */
  const createRequestBody = (newitem) => {
    let requestBody = {
      ...Application
    };

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      dispatch(
        upsertApplication(createRequestBody(true), (success, id) => {
          if (success) {
            props.history.push(`/applications/`);
          } else {
            setIsEditing(true);
            setSubmitEnabled(true);
            setFormEdited(true);
          }
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        upsertApplication(createRequestBody(true), () => {
          props.history.push(`/applications/`);
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingApplication) {
        props.history.push(`/applications`);
      }
    }
  };

  // Display Applcation data
  const renderData = () => {
    return (
      <Fragment>
        <div className={styles.contentGrid}>
          <CustomCard title="Application Data" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  disabled={true}
                  id={formProperties.ApplicationId.value}
                  name={formProperties.ApplicationId.value}
                  label={formProperties.ApplicationId.value}
                  placeholder="Call Type Id"
                  value={
                    Application != null && Object.keys(Application).length > 0 &&
                      Application.ApplicationId !== undefined
                      ? Application.ApplicationId
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.ApplicationId.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.ApplicationId.errorMessage !== ""}
                  helperText={formProperties.ApplicationId.errorMessage}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.ApplicationName.value}
                  name={formProperties.ApplicationName.value}
                  label={formProperties.ApplicationName.label}
                  placeholder="Name"
                  value={
                    Application != null && Object.keys(Application).length > 0 &&
                      Application.ApplicationName !== undefined
                      ? Application.ApplicationName
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.ApplicationName.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.ApplicationName.errorMessage !== ""}
                  helperText={formProperties.ApplicationName.errorMessage}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <HeaderBlock
        title={Application != null && Object.keys(Application).length > 0 ? Application.ApplicationName : "New CallType"}
        right={
          <Grid container spacing={2} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedApplication(Application);
                    } else if (isEditing && isExistingApplication) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing
                    ? isExistingApplication
                      ? "Done"
                      : "Submit"
                    : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />


      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewApplicationPage);

// EXPORT COMPONENT
export { hoc as ViewApplicationPage };
