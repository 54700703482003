// React
import React from "react";

// UI and Styling
import { Grid, TextField } from "@material-ui/core";
import styles from "./TextFieldWithDomain.module.scss";

const TextFieldWithDomain = ({
  domainValue,
  errorText,
  disabled,
  id,
  name,
  label,
  placeholder,
  value,
  onChange,
  helperText
}) => {
  return (
    <Grid
      item
      style={{
        display: "flex"
      }}
    >
      <div
        className={`${styles.textFieldBorderRadiusRightZero} ${styles.textFieldBorderRightNone}`}
      >
        <TextField
          className={styles.disabledDomain}
          disabled={true}
          value={domainValue}
          variant="outlined"
          margin="dense"
          style={{
            marginTop: 16
          }}
        />
      </div>
      <div
        className={`${styles.textFieldBorderRadiusLeftZero} ${styles.inputFillRemainingSpace}`}
      >
        <TextField
          disabled={disabled}
          error={errorText}
          id={id}
          name={name}
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          variant="outlined"
          margin="dense"
          helperText={helperText}
          style={{
            marginTop: 16,
            flexGrow: 1
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
    </Grid>
  );
};

export default TextFieldWithDomain;
