import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

//Styling
import DateRange from "@material-ui/icons/DateRange";
import { format } from "date-fns";
import { getActivitiesById } from "../../../../state/actions/MyDisplaysActions";
import { DisplayGroup, Activity } from "../MyDisplaysRedView";

interface Props {
  existingDisplayGroup: DisplayGroup;
  displayGroupId: string;
  displayActivities: Activity[];
}

export const DisplayTypeCard: React.FC<Props> = ({
  existingDisplayGroup,
  displayGroupId,
  displayActivities,
}) => {
  const getMaxScore = (index: number) => {
    const scores = new Array();
    let tmp = [];
    if (
      existingDisplayGroup !== undefined &&
      existingDisplayGroup.Displays &&
      displayActivities !== undefined
    ) {
      for (let i = 0; i < existingDisplayGroup.Displays.length; i++) {
        if (
          displayActivities[i] !== undefined &&
          displayActivities[i].Rewards.length > 0
        ) {
          const activeRewards = displayActivities[i]?.Rewards?.filter(
            (item) => item.IsActive === true
          );
          let tmpScore: number | null = null;
          if (activeRewards) {
            tmpScore = Math.max(...activeRewards.map((r) => r.Value));
          } else {
            tmpScore = Math.max(
              ...displayActivities[i].Rewards.map((r) => r.Value)
            );
          }
          tmp.push(tmpScore);
        }
        for (
          let j = 0;
          j < existingDisplayGroup.Displays[i].Activities.length;
          j++
        ) {
          if (
            existingDisplayGroup.Displays[i].DisplayTypeActivityId !==
            existingDisplayGroup.Displays[i].Activities[j].MyDisplaysActivityId
          ) {
            tmp.push(existingDisplayGroup.Displays[i].Activities[j].MaxScore);
          }
        }
        scores.push(tmp);
        tmp = [];
      }
      return scores[index];
    }
  };

  const history = useHistory();
  return (
    <div className="display-type-card-container">
      {displayActivities.map((display, index) => (
        <>
          <div
            key={index}
            className="display-type-card"
            onClick={() =>
              history.push(
                `/my-displays-maintenance/${displayGroupId}/${index}`
              )
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>
                  <strong>{display.Activity.Id}</strong>
                </span>
                <span>{display.Activity.Name}</span>
                <div className="active-tag">
                  <span
                    className={`${
                      display.Activity.IsActive
                        ? "active-tag-text"
                        : "inactive-tag-text"
                    }`}
                  >
                    {display.Activity.IsActive ? "Active" : "Inactive"}
                  </span>
                </div>
              </div>
              <div className="red-score">
                <span style={{ textAlign: "center" }}>Max Red Score</span>
                <span className="red-score-number">
                  {display !== undefined && getMaxScore(index).length
                    ? getMaxScore(index)?.reduce(
                        (prev: number, curr: number) => prev + curr
                      )
                    : 0}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <div
                className="date"
                style={{ display: "flex", alignItems: "center" }}
              >
                <DateRange />
                <span>
                  &nbsp;
                  {format(
                    new Date(display.Activity.StartDate),
                    "dd/MM/yyyy"
                  )} -{" "}
                  {format(new Date(display.Activity.EndDate), "dd/MM/yyyy")}
                </span>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
