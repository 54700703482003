// React
import React, { useState } from "react";

// UI and Styling
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import styles from "./ContentUploadSegments.module.scss";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import FileDrop from "../../components/file-drop/FileDrop";

// Other
import { FileType } from "../../state/constants/FileType";
import { UUID } from "../../helpers/uuid";

const CSVUploadSegment = ({
  csvData,
  setCsvData,
  setDataChanged,
  title,
  targetsEdited,
  setTargetsEdited,
  isEditing,
  setFormEdited
}) => {
  const [csvFile, setCsvFile] = useState(null);
  const [manuallyInputtedTargets, setManuallyInputtedTargets] = useState("");

  const parseFilterUpload = (files) => {
    const file = files[0];

    if (file !== null) {
      setCsvFile([file]);

      const fileReader = new FileReader();

      const eventListener = (event) => {
        let fileData = event.target.result;

        var data = [];

        fileData.split(",").forEach((value) => {
          if (value !== "" && value !== "\n" && value.trim().length !== 0) {
            data.push(value.trim());
          }
        });

        const localData = csvData;

        data.forEach((target) => {
          if (!localData.targets.value.includes(target.trim())) {
            localData.targets.value.push(target.trim());
          }
        });

        if (!targetsEdited) {
          setTargetsEdited(true);
        }
        setCsvData(localData);
        setDataChanged(true);
      };

      fileReader.addEventListener("load", eventListener);

      fileReader.readAsBinaryString(file);
    }
  };

  const handleDownloadOnClick = (event) => {
    window.open(`/templates/filter_template.csv`, "_self");
  };

  const handleAddTargetsClick = (event) => {
    const inputtedTargets = manuallyInputtedTargets
      .split("|")
      .join(",")
      .split(",")
      .map(Function.prototype.call, String.prototype.trim);
    const localData = csvData;

    inputtedTargets.forEach((inputtedTarget) => {
      if (!localData.targets.value.includes(inputtedTarget.trim())) {
        localData.targets.value.push(inputtedTarget.trim());
      }
    });

    setManuallyInputtedTargets("");
    setCsvData(localData);
    setDataChanged(true);
    setFormEdited(true);
  };

  const handleRemoveTargetsClick = (rowsToDelete) => {
    const localData = csvData;

    rowsToDelete.data.sort((a, b) => {
      return b.index - a.index;
    });

    rowsToDelete.data.forEach((row) => {
      localData.targets.value.splice(row.index, 1);
    });

    setCsvData(localData);
    setDataChanged(true);
    setFormEdited(true);
  };

  // Get the markup for the main content
  const getIdGrid = () => {
    const ids =
      csvData != null
        ? csvData.targets.value.map((data) => {
          return {
            id: data
          };
        })
        : [];

    const userTableColumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true
        }
      }
    ];

    const userTableOptions = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 50, 100, 200],

      viewColumns: false,
      filterType: "checkbox",
      elevation: 0,
      selectableRows: isEditing ? "multiple" : "none",
      print: false,
      download: true,
      downloadOptions: {
        filename: "ContentIds.csv",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      onRowsDelete: (rowsDeleted, dataRows) =>
        handleRemoveTargetsClick(rowsDeleted)
    };

    return (
      <MUIDataTable
        title="Ids"
        data={ids}
        columns={userTableColumns}
        options={userTableOptions}
      />
    );
  };
  return (
    <CustomCard
      title={title}
      actionButton={[
        <Button
          key={UUID()}
          className={styles.buttonStyle}
          variant="outlined"
          color="secondary"
          onClick={(event) => handleDownloadOnClick(event)}
        >
          Download Template
        </Button>
      ]}
    >
      <Grid container spacing={3}>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Manually enter target id's below separating each by a ,
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              display: "flex"
            }}
          >
            <TextField
              disabled={!isEditing}
              id="targetIds"
              name="targetIds"
              label="Target Id's"
              value={manuallyInputtedTargets}
              onChange={(event) =>
                setManuallyInputtedTargets(event.target.value)
              }
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              error={csvData.targets.errorMessage !== ""}
              helperText={
                csvData.targets.errorMessage !== "" &&
                "Please manually add target id's above or upload a csv of id's below "
              }
              style={{
                marginRight: 8,
                flex: 1
              }}
            />
            <Button
              key={UUID()}
              className={styles.buttonStyle}
              variant="outlined"
              color="secondary"
              onClick={(event) => handleAddTargetsClick(event)}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            OR
          </Typography>
          <div className={styles.fileDrop}>
            <FileDrop
              message={"Upload file of Ids"}
              onFilesAdded={(file) => parseFilterUpload(file)}
              file={csvFile}
              errorMessage={csvData.targets.errorMessage}
              csv
              enforceTypes={[
                FileType.csv,
                FileType.xls,
                FileType.plain,
                FileType.xcsv,
                FileType.appcsv,
                FileType.appxcsv,
                FileType.comma,
                FileType.xcomma,
                FileType.tab
              ]}
              enforceTypesMessage={"File must be a csv"}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {getIdGrid()}
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default CSVUploadSegment;
