// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import { Button, ButtonGroup, Grid, Paper } from "@material-ui/core";
import styles from "./ThreeDimensionalPOSPage.module.scss";

// Actions
import { getAllTargetedContent } from "../../state/actions/TargetedContentActions";
import {
  upsert3DPosItem,
  get3DPOSItemById
} from "../../state/actions/ThreeDimensionalPOSActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { ListableStopsCard } from "../../components/listable-stops-card/ListableStopsCard";
import { ListableBeaconsCard } from "../../components/listable-beacons-card/ListableBeaconsCard";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";

// Other
import { FormType } from "../../state/constants/FormType";
import { UUID } from "../../helpers/uuid";
import {
  validateField,
  validateBeacons,
  validateStopSeconds
} from "../../helpers/validation";

/*
    Component entry point
*/
const ThreeDimensionalPOSPage = (props) => {
  // Reducer logic
  const targetedContent = useSelector(
    (state) => state.TargetedContentReducer.targetedContent
  );

  const currentPOSItem = useSelector(
    (state) => state.ThreeDimensionalPOSReducer.currentPOSItem
  );

  const currentPOSItemWithSteps = useSelector(
    (state) => state.ThreeDimensionalPOSReducer.currentPOSItemWithSteps
  );

  const dispatch = useDispatch();

  // State Logic
  const [loading, setLoading] = useState(false);
  const [posContent, setPosContent] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [isNewContent, setIsNewContent] = useState(false);
  const [data, setData] = useState({ Beacons: [], Stops: [] });
  const [uneditedData, setUneditedData] = useState({});
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [stopsErrorMessage, setStopsErrorMessage] = useState("");
  const [beaconsErrorMessage, setBeaconsErrorMessage] = useState("");

  const contentId = props.match.params.contentId;

  useEffect(() => {
    setLoading(true);

    dispatch(
      getAllTargetedContent(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    if (currentPOSItem !== undefined) {
      setPosContent(currentPOSItem.data.ContentTitle);
      if (currentPOSItem.data.HasExistingSteps) {
        setIsEditing(false);
        setIsNewContent(false);

        setLoading(true);

        dispatch(
          get3DPOSItemById(currentPOSItem.data.ContentId, () => {
            setLoading(false);
          })
        );
      } else {
        setIsEditing(true);
        setIsNewContent(true);
      }
    }
  }, [currentPOSItem]);

  useEffect(() => {
    if (currentPOSItem.data.HasExistingSteps) {
      if (
        currentPOSItemWithSteps !== {} &&
        Object.keys(currentPOSItemWithSteps).length > 0
      ) {
        setData({
          Beacons: currentPOSItemWithSteps.data.Beacons,
          Stops: currentPOSItemWithSteps.data.Stops.map((stop) => {
            const timeStampCollection = stop.StopTimeStamp.split(":");
            return {
              StopNumber: stop.StopNumber,
              Minutes: timeStampCollection[1],
              Seconds: timeStampCollection[2].substring(
                0,
                timeStampCollection[2].indexOf(".")
              ),
              Milliseconds: timeStampCollection[2].substring(
                timeStampCollection[2].indexOf(".") + 1
              )
            };
          })
        });
      }
    }
  }, [currentPOSItemWithSteps]);

  useEffect(() => {
    if (isEditing) {
      setSubmitEnabled(true);
    }
  }, [isEditing]);

  /*
    Iterate through all stops and display its stop number and timestamp
  */
  const setupStopsData = () => {
    if (!data.Stops || data.Stops.length <= 0) {
      return [];
    }

    return data.Stops.map((stop, index) => {
      return {
        id: index,
        stopNumber: stop.StopNumber,
        minutes: stop.Minutes,
        seconds: stop.Seconds,
        milliseconds: stop.Milliseconds,
        handleValueChange: (label, value) => {
          let localData = Object.assign([], data);

          localData.Stops[index][label] = value;

          setData(localData);

          if (!formHasChanged) {
            setFormHasChanged(true);
          }
        }
      };
    });
  };

  /*
    Iterate through all beacons and display its data
  */
  const setupBeaconsData = () => {
    if (!data.Beacons || data.Beacons.length <= 0) {
      return [];
    }

    return data.Beacons.map((beacon, index) => {
      return {
        id: index,
        stepContentId: beacon.StepContentId,
        stopNumber: beacon.StopNumber,
        xCoordinate: beacon.XCoordinate,
        yCoordinate: beacon.YCoordinate,
        handleValueChange: (label, value) => {
          let localData = Object.assign([], data);

          localData.Beacons[index][label] = value;

          setData(localData);

          if (!formHasChanged) {
            setFormHasChanged(true);
          }
        },
        handleDelete: () => {
          let localData = Object.assign([], data);

          localData.Beacons.splice(index, 1);

          setData(localData);

          if (!formHasChanged) {
            setFormHasChanged(true);
          }
        }
      };
    });
  };

  /*
    Add a new stop on button click
  */
  const handleAddStop = () => {
    let localData = Object.assign([], data);

    localData.Stops.push({
      StopNumber: data.Stops.length + 1,
      Minutes: "00",
      Seconds: "00",
      Milliseconds: "00"
    });

    setData(localData);

    if (!formHasChanged) {
      setFormHasChanged(true);
    }
  };

  /*
    Add a new beacon on button click
  */
  const handleAddBeacon = () => {
    let localData = Object.assign([], data);

    localData.Beacons.push({
      StepContentId: "",
      StopNumber: 0,
      XCoordinate: 0,
      YCoordinate: 0
    });

    setData(localData);

    if (!formHasChanged) {
      setFormHasChanged(true);
    }
  };

  //Validate the form before submitting
  const validateForm = () => {
    //Reset any validation messages shown
    setStopsErrorMessage("");
    setBeaconsErrorMessage("");

    let errorCount = 0;
    const stopsError = validateField(true, data.Stops, "array", "Stops");

    const beaconsError = validateField(true, data.Beacons, "array", "Beacons");

    if (stopsError !== "") {
      errorCount++;
      setStopsErrorMessage(stopsError);
    }

    if (beaconsError !== "") {
      errorCount++;
      setBeaconsErrorMessage(beaconsError);
    }

    return errorCount === 0;
  };

  const validateAllBeacons = () => {
    const errorMessage = validateBeacons(data.Beacons, data.Stops);

    if (errorMessage !== "") {
      setBeaconsErrorMessage(errorMessage);
      return false;
    }

    return true;
  };

  const validateStops = () => {
    const errorMessage = validateStopSeconds(data.Stops);

    if (errorMessage !== "") {
      setStopsErrorMessage(errorMessage);
      return false;
    }

    return true;
  };

  //Format the data into the format required by the backend.
  const createRequestBody = () => {
    return {
      Beacons: data.Beacons,
      Stops: data.Stops.map((stop) => {
        return {
          StopNumber: stop.StopNumber,
          StopTimeStamp: `00:${stop.Minutes}:${stop.Seconds}.${stop.Milliseconds}`
        };
      })
    };
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formHasChanged) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (isNewContent) {
        props.history.push(`/three-dimensional-pos`);
      }
    }
  };

  /*
    Display the cancel editing alert dialog
  */
  const cancelTargetedContentEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);

              if (isNewContent) {
                props.history.push(`/three-dimensional-pos`);
              } else {
                setData(uneditedData);
                setFormHasChanged(false);
                setCancelEditingAlertOpen(false);
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  const deleteLastStop = () => {
    let localData = Object.assign([], data);

    localData.Stops.pop();

    setData(localData);

    if (!formHasChanged) {
      setFormHasChanged(true);
    }
  };

  /*
    When the submit button is clicked on either the create or update form.
    The create param is optional
  */
  const submit = (create = true) => {
    setSubmitEnabled(false);
    //Ensure the data being submitted is valid before submitting
    if (validateForm() && validateStops() && validateAllBeacons()) {
      const requestBody = createRequestBody();

      // Create the 3D POS item then redirect user to the three dimensional POS page
      dispatch(
        upsert3DPosItem(contentId, requestBody, (success) => {
          if (success) {
            if (create) {
              props.history.push("/three-dimensional-pos");
            } else {
              setIsEditing(false);
              setFormHasChanged(false);
            }
          }
        })
      );
    } else {
      setSubmitEnabled(true);
    }
  };

  /*
    Display all the page data
  */
  const renderData = () => {
    return (
      <div className={styles.contentGrid}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper>
              <ListableStopsCard
                actionButton={[
                  <Button
                    key={UUID()}
                    onClick={() => handleAddStop()}
                    variant="outlined"
                    color="secondary"
                  >
                    Add Stop
                  </Button>
                ]}
                addLabel={"Add Stop"}
                disabled={!isEditing}
                isLoading={false}
                title={"Stops"}
                errorMessage={stopsErrorMessage}
                emptyPlaceholder={"No Stops added"}
                data={setupStopsData() === null ? [] : setupStopsData()}
                deleteLastStop={() => deleteLastStop()}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper>
              <ListableBeaconsCard
                actionButton={[
                  <Button
                    key={UUID()}
                    onClick={() => handleAddBeacon()}
                    variant="outlined"
                    color="secondary"
                  >
                    Add Beacon
                  </Button>
                ]}
                addLabel={"Add Beacon"}
                disabled={!isEditing}
                isLoading={false}
                title={"Beacons"}
                errorMessage={beaconsErrorMessage}
                emptyPlaceholder={"No Beacons added"}
                data={setupBeaconsData() === null ? [] : setupBeaconsData()}
                stops={data.Stops.map((stop) => {
                  return { key: stop.StopNumber, value: stop.StopNumber };
                })}
                targetedContent={targetedContent.map((tc) => {
                  return {
                    key: tc.ContentId,
                    value: `${
                      tc.ContentTitle
                    } - ${tc.ContentDescription.substring(0, 50)}`
                  };
                })}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={posContent}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isEditing && !submitEnabled}
                  onClick={() => {
                    if (isEditing) {
                      submit(
                        !(
                          currentPOSItem !== undefined &&
                          currentPOSItem.data.HasExistingSteps
                        )
                      );
                    } else {
                      setUneditedData(data);
                      setIsEditing(true);
                    }
                  }}
                >
                  {isEditing ? "Submit" : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelTargetedContentEditingAlert()}

      <PageContainer hasHelp={props.type !== FormType.VIEW}>
        {renderData()}
      </PageContainer>
    </div>
  );
};

const hoc = withRouter(ThreeDimensionalPOSPage);

// EXPORT COMPONENT
export { hoc as ThreeDimensionalPOSPage };
