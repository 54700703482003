import React from "react";
import styles from "./CardHeader.module.scss";

export class SimpleCardHeader extends React.Component {
  render() {
    return (
        <div className={styles.cardHeader}>
            { this.props.children }
        </div>
    )
  }
}
