export const TargetModel = () => {
  return {
    targets: {
      type: "csv",
      value: [],
      required: true,
      errorMessage: "",
      label: "Targets"
    }
  };
};
