// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import styles from "./SFEActivationListPage.module.scss";

// Actions
import { getAllSFEActivations } from "../../state/actions/SFEActivationActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";

// Other
import { getIcon } from "../../icon/icon";

// Start of main component body
const SFEActivationListPage = (props) => {
  const { sfeActivations } = useSelector((state) => state.SFEActivationReducer);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);

  useEffect(() => {
    setLoading(false);

    dispatch(
      getAllSFEActivations(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    if (sfeActivations !== undefined) {
      setLoading(true);
      const data = createData(sfeActivations);

      setOriginalData(data);
      setSearchedData(data);
      setLoading(false);
    }
  }, [sfeActivations]);

  const createData = (sfeActivations) => {
    const sfeActivationKeys = Object.keys(sfeActivations);

    return sfeActivationKeys.map((sfeActivationKey) => {
      const sfeActivation = sfeActivations[sfeActivationKey];

      return {
        id: sfeActivation.Id,
        redChannelCode: sfeActivation.RedChannelCode,
        activityGroup: sfeActivation.ActivityGroup,
        activityGroupId: sfeActivation.ActivityGroupId,
        activity: sfeActivation.Activity,
        activityId: sfeActivation.ActivityId,
        calculationTypeCode: sfeActivation.CalculationTypeCode,
        referenceTables: sfeActivation.ReferenceTables,
        modified: sfeActivation.SysUpdated,
        created: sfeActivation.SysCreated,
        isActive: sfeActivation.SysIsCurrent ? "True" : "False"
      };
    });
  };

  // The headers of the table
  const createHeaders = () => {
    return [
      {
        id: "redChannelCode",
        clickable: true,
        action: { path: "/sfe-activation/{id}", identifier: "id" },
        align: "left",
        label: "Red Channel Code"
      },
      {
        id: "activityGroup",
        align: "left",
        label: "Activity Group"
      },
      {
        id: "activityGroupId",
        align: "left",
        label: "Activity Group Id"
      },
      {
        id: "activity",
        align: "left",
        label: "Activity"
      },
      {
        id: "activityId",
        align: "left",
        label: "Activity Id"
      },
      {
        id: "calculationTypeCode",
        align: "left",
        label: "Calculation Type Code"
      },
      {
        id: "referenceTables",
        align: "left",
        label: "Reference Tables"
      },
      {
        id: "modified",
        align: "left",
        label: "Modified"
      },
      {
        id: "created",
        align: "left",
        label: "Created"
      },
      {
        id: "isActive",
        align: "left",
        label: "Active"
      }
    ];
  };

  // Create the cells for the table and configure them.
  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      searchedData.length === 0 ? (
        <PagePlaceholder
          image={getIcon("licencse.svg")}
          text="No SFE Activation Calculations found."
        />
      ) : (
        <SimpleTable
          handleSelection={null}
          isSelectable={false}
          onClick={() => {}}
          dataId={"sfeActivation"}
          cells={cells}
          headers={headers}
          rows={searchedData}
        />
      );

    return data;
  };

  return (
    <div>
      <HeaderBlock
        title={"SFE Activation Calculation Assignment"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <Search
                searchField={"activity"}
                returnSearchMatches={(data) => {
                  setSearchedData(data);
                }}
                data={originalData}
                searchTitle="Search SFE Activation"
              />
            </Grid>
            <ExportExcel csvData={searchedData} fileName="sfe-calculations" />
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("/sfe-activation/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  New SFE Activation
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(SFEActivationListPage);

// EXPORT COMPONENT
export { hoc as SFEActivationListPage };
