// React
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import PageContainer from "../../components/page-container/PageContainer";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomCard from "../../components/custom-card/CustomCard";
import styles from "./MigrationPage.module.scss";
import MUIDataTable from "mui-datatables";

const MigrationCompletionPage = (props) => {
  const failures = useSelector(
    (state) => state.MigrationReducer.UploadFailures
  );
  const successes = useSelector(
    (state) => state.MigrationReducer.UploadSuccesses
  );

  const failuremsg = useSelector(
    (state) => state.MigrationReducer.FailureMessage
  );

  const getFailureGrid = () => {
    const ids = failures !== null ? failures : [];

    var columns = [];
    if (failures.length > 0) {
      for (const [key, val] of Object.entries(ids[0])) {
        if (!Array.isArray(val) && typeof val !== "object") {
          columns.push(key);
        }
      }
    }

    const userTableOptions = {
      filter: false,
      responsive: "stacked",
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 50, 100, 200],

      viewColumns: false,
      search: false,
      filterType: "checkbox",
      elevation: 0,
      selectableRows: "none",
      print: false,
      download: false
    };
    return (
      <MUIDataTable
        title="Failures"
        data={ids}
        columns={columns}
        options={userTableOptions}
      />
    );
  };

  const getSuccessGrid = () => {
    const ids = successes != null ? successes : [];

    var columns = [];
    if (successes.length > 0) {
      for (const [key, val] of Object.entries(ids[0])) {
        if (!Array.isArray(val) && typeof val !== "object") {
          columns.push(key);
        }
      }
    }
    const userTableOptions = {
      filter: false,
      responsive: "stacked",
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 50, 100, 200],

      viewColumns: false,
      filterType: "checkbox",
      elevation: 0,
      selectableRows: "none",
      print: false,
      download: true,
      downloadOptions: {
        filename: "SuccededUploads.csv",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      }
    };
    return (
      <MUIDataTable
        title="Succeeded Uploads"
        data={ids}
        columns={columns}
        options={userTableOptions}
      />
    );
  };

  const renderSegment = (pageName) => {
    switch (pageName) {
      case "failures":
        return (
          <CustomCard title={"Failed Uploads"} display={false}>
            <Grid item xs={12}>
              {getFailureGrid()}
            </Grid>
          </CustomCard>
        );
      case "successes":
        return (
          <CustomCard title={"Succeeded Uploads"} display={false}>
            <Grid item xs={12}>
              {getSuccessGrid()}
            </Grid>
          </CustomCard>
        );
      default:
        return null;
    }
  };

  const renderPageContent = () => {
    return Object.keys({ failures: "failures", successes: "successes" }).map(
      (key) => {
        return (
          <Grid key={key} item xs={12} md={6}>
            {renderSegment(key)}
          </Grid>
        );
      }
    );
  };

  return (
    <Box>
      <Fragment>
        <HeaderBlock title={"Post Upload"} right={null} />

        <PageContainer>
          {failures.length > 0 ? (
            <div className={styles.contentGrid}>
              <Grid container spacing={3}>
                <CustomCard
                  title={"Failed Upload Debug Message"}
                  display={true}
                >
                  <Grid item xs={12}>
                    <pre>
                      <code>{JSON.stringify(failuremsg)}</code>
                    </pre>
                  </Grid>
                </CustomCard>
              </Grid>
            </div>
          ) : null}
          <div className={styles.contentGrid}>
            <Grid container spacing={3}>
              {renderPageContent()}
            </Grid>
          </div>
        </PageContainer>
      </Fragment>
    </Box>
  );
};
const hoc = withRouter(MigrationCompletionPage);

// EXPORT COMPONENT
export { hoc as MigrationCompletionPage };
