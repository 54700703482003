// React
import React, { Fragment, useState, useEffect } from "react";

// UI and Styling
import { Box, FormHelperText, Grid, Icon, Typography } from "@material-ui/core";
import styles from "./FileDrop.module.scss"; // Import css modules stylesheet as styles

// Other
import { UUID } from "../../helpers/uuid";
import CustomizedSnackbars from "../snackbar/Snackbar";

function formatBytes(a, b = 2) { if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d] }

const FileDrop = (props) => {
  const [highlight, setHighlight] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [fileImages, setFileImages] = useState({});
  const [dragCounter, setDragCounter] = useState(0);
  const [csvSnackbarOpen, setCsvSnackbarOpen] = useState(false);
  const [size, setSize] = useState(props.maxFileSize ? props.maxFileSize : 20 * 1024);
  const fileInputRef = React.createRef();

  const openFileDialog = () => {
    if (props.disabled) return;
    fileInputRef.current.click();
  };

  useEffect(() => {
    var inSize = 1024 * 1024;
    if (props.maxFileSize != null) {
      inSize = inSize * props.maxFileSize;
    } else {
      inSize = inSize * 3;
    }
    setSize(inSize);
  }, []);

  const handleFilesAdded = (filesList) => {
    // If disabled, break.
    if (props.disabled) return;

    // If length of files > 0, and files are being added, process.
    if (filesList.length > 0 && props.onFilesAdded) {
      // If this input is not designed to take multiple images
      if (!props.multiple) {
        if (checkFile(filesList[0])) {
          props.onFilesAdded([filesList[0]]);
        } else {
          setSnackbarOpen(true);
        }
      } else {
        filesList.forEach((file) => {
          if (checkFile(filesList[0])) {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
              const localFileImages = Object.assign({}, fileImages);
              localFileImages[file.name] = event.target.result;
              setFileImages(localFileImages);
            };
            fileReader.readAsDataURL(file);
          } else {
            setSnackbarOpen(true);
            return;
          }
        });
        props.onFilesAdded(filesList);
      }
    } else {
      setSnackbarOpen(true);
    }
  };

  const onFilesAdded = (event) => {
    const files = event.currentTarget.files;
    handleFilesAdded(Object.values(files));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.disabled) return;
    setDragCounter(dragCounter + 1);
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setHighlight(true);
    }
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragCounter(dragCounter - 1);
    if (dragCounter === 0) {
      setHighlight(false);
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Object.values(event.dataTransfer.files);
    handleFilesAdded(files);
    setHighlight(false);
    event.dataTransfer.clearData();
    setDragCounter(0);
  };

  const checkFile = (file) => {
    if (props.maxDimensions != undefined) {
      // Check the dimensions of the proposed file before going to check its filesize.
      var reader = new FileReader();
      //Read the contents of Image File.
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height > props.maxDimensions["height"] || width > props.maxDimensions["width"]) {
            return false;
          }
          return true;
        };
      };
    }

    if (props.ignoreMaxFileSize) {
      return true;
    } else {
      if (file) {
        //Max file size = 40mb
        if (file.size > size) {
          setSnackbarOpen(true);
          return false;
        }

        if (props.enforceTypes.length > 0) {
          if (
            !props.enforceTypes.map((type) => type.mime).includes(file.type)
          ) {
            setCsvSnackbarOpen(true);
            return false;
          }
        }
        return true;
      }
      return false;
    }
  };

  return (
    <Fragment>
      <div
        className={
          styles.dropzone +
          " " +
          (highlight ? styles.highlight : "") +
          (props.errorMessage !== "" ? styles.error : "")
        }
        style={{ cursor: props.disabled ? "default" : "pointer" }}
      >
        <CustomizedSnackbars
          handleClose={() => {
            setSnackbarOpen(false);
          }}
          open={snackbarOpen}
          variant="error"
          message={`File size too large. Maximum is ${size / 1024}MB`}
        />

        <CustomizedSnackbars
          handleClose={() => {
            setCsvSnackbarOpen(false);
          }}
          open={csvSnackbarOpen}
          variant="error"
          message={props.enforceTypesMessage}
        />

        <div
          className={styles.innerZone}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
        >
          <input
            ref={fileInputRef}
            className={styles.fileInput}
            type="file"
            multiple={props.multiple}
            onChange={onFilesAdded}
          />

          {props.file === null ||
            props.file === undefined ||
            props.file.length === 0 ? (
            <Box className={styles.item}>
              <Icon color="primary">upload</Icon>
              <span className={styles.textStyle}>{props.message}</span>
            </Box>
          ) : (
            <Box className={styles.gridContent}>
              {props.multiple ? (
                  <Typography align="center" variant='h6' sx={{marginBottom: '1em'}}>{`${props.file.length} File${props.file.length === 1 ? '' : 's' } to be Uploaded`}</Typography>
                ) : null}
              <Grid container>
                {props.file.map((file, index) => {
                  return (
                    <Grid item xs={12} key={UUID()}>
                      <Box className={styles.item}> 
                        { fileImages[file.name] ? 
                            <img
                              alt={file.name}
                              className={styles.icon}
                              src={fileImages[file.name]}
                            /> : null
                        }
                        <Typography align="center" className={styles.textStyle}>
                          {file == null ? props.message : file.name}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}
        </div>
      </div>
      {props.errorMessage !== "" ? (
        <FormHelperText error>{props.errorMessage}</FormHelperText>
      ) : null}
    </Fragment>
  );
};
export default FileDrop;
