// React
import React, { useState } from "react";

// UI and Styling
import {
  Grid,
  Chip,
  FormHelperText,
  List,
  Button,
  ButtonGroup,
  Typography
} from "@material-ui/core";
import styles from "./ListableBeaconsCard.module.scss"; // Import css modules stylesheet as styles

// Components
import { CustomCardContent } from "../card-content/CardContent";
import { SimpleCardHeader } from "../card/card-header/CardHeader";
import { Loading } from "../loading/Loading";
import BeaconItem from "./beacon-item/BeaconItem";
import ShrinkingButtonGroup from "../shrinking-button-group/ShrinkingButtonGroup";

export const ListableBeaconsCard = (props) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [toolbarShowing, setToolbarShowing] = useState(false);
  const handleCheck = (id) => {
    const localSelectedItems = Object.assign({}, selectedItems);
    if (!localSelectedItems.hasOwnProperty(id)) {
      localSelectedItems[id] = true;
    } else {
      delete localSelectedItems[id];
    }

    setSelectedItems(localSelectedItems);
  };

  const toggleToolbar = () => {
    setToolbarShowing(!toolbarShowing);
  };

  const getDataList = () => {
    if (props.isLoading) {
      return <Loading />;
    }

    if (props.data.length === 0 && props.errorMessage !== "") {
      return <FormHelperText error={true}>{props.errorMessage}</FormHelperText>;
    }

    if (props.data.length === 0) {
      return <div className={styles.placeholder}>{props.emptyPlaceholder}</div>;
    }

    return (
      <Grid>
        <List>
          {props.data.map((beacon) => {
            const id = beacon.id;

            return (
              <BeaconItem
                key={`${id}`}
                disabled={props.disabled}
                id={id}
                toolbarShowing={toolbarShowing}
                handleValueChange={(label, value) => {
                  beacon.handleValueChange(label, value);
                }}
                handleDelete={(id) => {
                  props.data[id].handleDelete();
                }}
                targetedContent={props.targetedContent}
                stops={props.stops}
                data={beacon}
                handleCheck={() => {
                  handleCheck(id);
                }}
                selectedItems={selectedItems}
              />
            );
          })}
        </List>
        {props.errorMessage !== "" ? (
          <FormHelperText error={true}>{props.errorMessage}</FormHelperText>
        ) : null}
      </Grid>
    );
  };

  const actionButtons = (props.actionButton
    ? props.actionButton.map((action) => {
        return {
          label: action.props.children,
          action: () => action.props.onClick()
        };
      })
    : []
  ).concat({
    label: toolbarShowing ? "Done" : "Delete Items",
    action: () => toggleToolbar()
  });

  return (
    <CustomCardContent>
      <SimpleCardHeader>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderLeft}>
            <div className={styles.cardTitle}>{props.title}</div>
            {!props.isLoading && (
              <Chip label={props.data.length} variant="outlined" />
            )}
          </div>
          {props.disabled ? null : (
            <div>
              <div className={styles.largeScreen}>
                <ButtonGroup>
                  {!props.isLoading && props.actionButton}
                  {props.data.length > 0 && (
                    <Button onClick={() => toggleToolbar()} variant="outlined">
                      {toolbarShowing ? "Done" : "Delete Items"}
                    </Button>
                  )}
                </ButtonGroup>
              </div>
              <div className={styles.smallScreen}>
                <ShrinkingButtonGroup
                  secondary={actionButtons}
                  default={{
                    label: "...",
                    action: null
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </SimpleCardHeader>
      <SimpleCardHeader>
        <Typography variant="body2">{props.subheading}</Typography>
      </SimpleCardHeader>

      {getDataList()}
    </CustomCardContent>
  );
};
