// React
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  TextField
} from "@material-ui/core";
import styles from "./CategoriesListPage.module.scss";

// Components
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";
import { adalApiFetch } from "../../../config/azureConfig";
import Axios from "axios";
import { getApiConfig } from "../../../config/apiConfig";

const FileDownload = require('js-file-download');
const API_CONFIG = getApiConfig();

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  }
}));

const AgreementSpecificationListPage = (props: any) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [specs, setSpecs] = useState([]);

  useEffect(() => {
    setSpecs([]);
    setLoading(true);
    const fetchData = async () => {
      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.SYNC}/SyncAgreementSpecifications`,
        {
          method: "get",
          headers: headers
        }
      );
      setSpecs(response.data.Specifications);
      setLoading(false);
    }
    fetchData().catch(console.error);
  }, []);

  const inputRef: any = useRef(null);

  const createHeaders = () => {
    return [
      {
        id: "Id",
        clickable: true,
        action: {
          path: "/agreementspecifications/{id}",
          identifier: "Id"
        },
        label: "Name",
        align: "left"
      }
    ];
  };

  const createCells = (headers: any) => {
    let cells: any = [];

    headers.forEach((header: any) => {
      let cell: any = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event: any, row: any) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      specs.length === 0 ? (
        <PagePlaceholder
          image={getIcon("frame-expand.svg")}
          text="No Agreement Specifications found."
        />
      ) : (
        <SimpleTable
          handleSelection={null}
          isSelectable={false}
          dataId={"Id"}
          cells={cells}
          headers={headers}
          rows={specs}
        />
      );
    return data;
  };

  return (
    <div>
      <HeaderBlock
        title="Agreement Specifications"
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item xs={8}>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("/agreementspecifications/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  New Agreement
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(AgreementSpecificationListPage);

// EXPORT COMPONENT
export { hoc as AgreementSpecificationListPage };
