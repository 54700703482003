// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Tooltip
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MUIDataTable from "mui-datatables";
import styles from "./ViewCampaignPage.module.scss";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
  getCampaignById,
  resetLocalCampaign,
  updateCampaign,
  createCampaign,
  getAllApplications
} from "../../../state/actions/CampaignActions";
import { getTargetGroups } from "../../../state/actions/TargetGroupActions";
import { getAllTargetedContent } from "../../../state/actions/TargetedContentActions";

// Models
import { CampaignModel } from "../../../models/CampaignModel";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import Modal from "../../../components/dialog/Modal";
import PageContainer from "../../../components/page-container/PageContainer";
import Search from "../../../components/search/Search";
import SimpleList from "../../../components/simple-list/SimpleList";
import TitledCard from "../../../components/titled-card/TitledCard";

// Containers
import { TargetGroupPickerModal } from "../../../containers/target-group-picker-modal/TargetGroupPickerModal";
import { TargetedContentPickerModal } from "../../../containers/targeted-content-picker-modal/TargetedContentPickerModal";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { FormType } from "../../../state/constants/FormType";
import {
  dateIsAfter,
  validateField,
  compareDates,
  compareDatesAreWithinRange
} from "../../../helpers/validation";
import { getIcon } from "../../../icon/icon";
import { createLogger } from "redux-logger";
import MultiSelectDropDown from "../../../components/multi-select-drop-down/MultiSelectDropDown";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "22px"
  },
  warning: {
    color: "orange"
  }
}));

const ViewCampaignPage = (props) => {
  const classes = useStyles();

  const currentCampaign = useSelector(
    (state) => state.CampaignReducer.currentCampaign
  );
  const targetGroups = useSelector(
    (state) => state.TargetGroupReducer.targetGroups
  );
  const targetedContent = useSelector(
    (state) => state.TargetedContentReducer.targetedContent
  );
  const applications = useSelector(
    (state) => state.CampaignReducer.applications
  );

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [campaign, setCampaign] = useState({ Name: "" });
  const [uneditedCampaign, setUneditedCampaign] = useState({});
  const [campaignData, setCampaignData] = useState(CampaignModel);
  const [isEditing, setIsEditing] = useState(false);
  const [fetchCampaign, setFetchCampaign] = useState(true);
  const [targetGroupModalOpen, setTargetGroupModalOpen] = useState(false);
  const [targetContentModalOpen, setTargetContentModalOpen] = useState(false);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [isExistingCampaign, setIsExistingCampaign] = useState(true);
  const [fetchCampaignTargets, setFetchCampaignTargets] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [targetedContentPickerData, setTargetedContentPickerData] = useState({});
  const [searchedTargetedContentData, setSearchedTargetedContentData] = useState({});
  const [originalTargetedContentData, setOriginalTargetedContentData] = useState({});
  const [searchedTargetGroupsData, setSearchedTargetGroupsData] = useState({});
  const [originalTargetGroupsData, setOriginalTargetGroupsData] = useState({});
  const [targetGroupsPickerData, setTargetGroupsPickerData] = useState({});
  const [removeContentFromCampaignModalOpen, setRemoveContentFromCampaignModalOpen] = useState(false);
  const [targetedContentToDelete, setTargetedContentToDelete] = useState([]);
  const [removeTargetGroupsFromCampaignModalOpen, setRemoveTargetGroupsFromCampaignModalOpen] = useState(false);
  const [targetGroupsToDelete, setTargetGroupsToDelete] = useState([]);
  const [applicationList, setApplicationList] = useState([]);

  //Use Effects
  useEffect(() => {
    if (fetchCampaign) {
      setIsExistingCampaign(props.type != null && props.type === FormType.VIEW);
      if (props.type != null && props.type === FormType.VIEW) {
        setLoading(true);
        setCampaignId(props.match.params.campaignId);

        dispatch(
          getCampaignById(props.match.params.campaignId, () => {
            setLoading(false);
            setFetchCampaignTargets(true);
          })
        );
      } else {
        setIsEditing(true);
        setFetchCampaignTargets(true);
      }

      setFetchCampaign(false);
    }

    return () => {
      dispatch(resetLocalCampaign(() => { }));
    };
  }, [fetchCampaign]);

  useEffect(() => {
    let applicationListMap = applications.map(item => {
      return { Id: item.ApplicationId, DisplayName: item.ApplicationName }
    });

    setApplicationList(applicationListMap);
  }, [applications]);


  useEffect(() => {
    if (fetchCampaignTargets) {
      dispatch(getTargetGroups(() => { }));
      dispatch(getAllTargetedContent(() => { }));
      dispatch(getAllApplications(() => { }));
    }
  }, [fetchCampaignTargets]);

  useEffect(() => {
    let localTargetGroupsData = {};

    targetGroups.forEach((tg) => {
      const contentType = tg.ModelType.toLowerCase();

      localTargetGroupsData.hasOwnProperty(contentType)
        ? localTargetGroupsData[contentType].push({
          name: tg.TargetGroupName,
          id: tg.TargetGroupId
        })
        : (localTargetGroupsData[contentType] = [
          {
            name: tg.TargetGroupName,
            id: tg.TargetGroupId
          }
        ]);
    });

    setTargetGroupsPickerData(localTargetGroupsData);
  }, [targetGroups]);

  useEffect(() => {
    let localTargetedContentData = {};

    targetedContent.forEach((tc) => {
      const contentType = tc.ContentType.toLowerCase();
      localTargetedContentData.hasOwnProperty(contentType)
        ? localTargetedContentData[contentType].push({
          name: `${tc.ContentTitle}`,
          extraData: `Start: ${tc.StartDate.split("T")[0]} End: ${tc.EndDate.split("T")[0]} Active: ${tc.IsActive}`,
          id: tc.ContentId
        })
        : (localTargetedContentData[contentType] = [
          {
            name: `${tc.ContentTitle}`,
            extraData: `Start: ${tc.StartDate.split("T")[0]} End: ${tc.EndDate.split("T")[0]} Active: ${tc.IsActive}`,
            id: tc.ContentId
          }
        ]);
    });

    setTargetedContentPickerData(localTargetedContentData);
  }, [targetedContent]);

  useEffect(() => {
    let searchableTargetGroupsData = [];
    let searchableTargetedContentData = [];

    if (currentCampaign && Object.keys(currentCampaign).length > 0) {
      if (currentCampaign.TargetGroups.length > 0 && targetGroups.length > 0) {
        searchableTargetGroupsData = currentCampaign.TargetGroups.map(
          (campaignTargetGroup) => {
            return {
              id: campaignTargetGroup.TargetGroupId,
              value: targetGroups.find(
                (tg) => tg.TargetGroupId === campaignTargetGroup.TargetGroupId
              ).TargetGroupName
            };
          }
        );
      }

      if (applicationList.length >= 1 && currentCampaign.SelectedApplications != null && currentCampaign.SelectedApplications.length >= 1) {
        currentCampaign.SelectedApplications = currentCampaign.SelectedApplications.map(item => {
          var actualValue = item;
          applicationList.forEach(element => {
            if (element.Id == item) {
              actualValue = element;
            }
          });
          return actualValue;
        });
      }

      if (
        currentCampaign.TargetContent.length > 0 &&
        targetedContent.length > 0
      ) {
        searchableTargetedContentData = currentCampaign.TargetContent.map(
          (id) => {
            return {
              id: id,
              value: targetedContent.find((tc) => tc.ContentId === id)
                .ContentTitle
            };
          }
        );
      }
    }

    setCampaign(currentCampaign);
    setOriginalTargetedContentData(searchableTargetedContentData);
    setSearchedTargetedContentData(searchableTargetedContentData);
    setOriginalTargetGroupsData(searchableTargetGroupsData);
    setSearchedTargetGroupsData(searchableTargetGroupsData);
  }, [currentCampaign, applicationList]);

  useEffect(() => {
    let searchableTargetGroupsData = [];
    let searchableTargetedContentData = [];

    if (campaign && Object.keys(campaign).length > 0) {
      if (
        campaign.TargetGroups !== undefined &&
        campaign.TargetGroups.length > 0 &&
        targetGroups !== undefined &&
        targetGroups.length > 0
      ) {
        searchableTargetGroupsData = campaign.TargetGroups.map(
          (campaignTargetGroup) => {
            return {
              id: campaignTargetGroup.TargetGroupId,
              value: targetGroups.find(
                (tg) => tg.TargetGroupId === campaignTargetGroup.TargetGroupId
              ).TargetGroupName
            };
          }
        );
      }

      if (
        campaign.TargetContent !== undefined &&
        campaign.TargetContent.length > 0 &&
        targetedContent !== undefined &&
        targetedContent.length > 0
      ) {
        searchableTargetedContentData = campaign.TargetContent.map((id) => {
          return {
            id: id,
            value: targetedContent.find((tc) => tc.ContentId === id)
              .ContentTitle
          };
        });
      }
    }

    setOriginalTargetedContentData(searchableTargetedContentData);
    setSearchedTargetedContentData(searchableTargetedContentData);
    setOriginalTargetGroupsData(searchableTargetGroupsData);
    setSearchedTargetGroupsData(searchableTargetGroupsData);
  }, [campaign, targetGroups, targetedContent]);

  //Called when selecting target groups in the modal
  const handleTargetGroupSelection = (selectedItems) => {
    const selectedTargetGroups = getTargetGroupsForIds(selectedItems);

    var localCampaign = Object.assign({}, campaign);

    selectedTargetGroups.forEach((group) => {
      localCampaign.TargetGroups.push({
        TargetGroupId: group.Id,
        IsExcluding: false
      });
    });

    setCampaign(localCampaign);
    setTargetGroupModalOpen(false);
    setDataChanged(true);
  };

  const validateTargetedContentDates = () => {
    let localCampaign = Object.assign({}, campaign);
    let localData = Object.assign({}, campaignData);

    let errorCount = 0;

    localCampaign.TargetContent.forEach((id) => {
      let content = targetedContent.find((tc) => tc.ContentId === id);

      const ok = dateIsAfter(new Date(content.EndDate), localCampaign.EndDate);

      if (!ok) {
        errorCount++;
        if (errorCount > 1) {
          localData[
            "TargetContent"
          ].warningMessage = `${localData["TargetContent"].warningMessage}
          ${content.ContentTitle} will expire on the ${content.EndDate} which is before the campaign ends.`;
        } else {
          localData[
            "TargetContent"
          ].warningMessage = `${content.ContentTitle} will expire on the ${content.EndDate} which is before the campaign ends.`;
        }
      }
    });

    if (errorCount > 0) {
      setCampaignData(localData);
    }
  };

  //Called when selecting targeted content in the modal
  const handleTargetedContentSelection = (selectedItems) => {
    const selectedTargetedContent = getTargetedContentForIds(selectedItems);

    var localCampaign = Object.assign({}, campaign);

    selectedTargetedContent.forEach((tc) => {
      localCampaign.TargetContent.push(tc.Id);
    });

    const warning = validateTargetedContentDates();

    setCampaign(localCampaign);
    setTargetContentModalOpen(false);
    setDataChanged(true);
  };

  // Get the target group ids
  const getTargetGroupsForIds = (items) => {
    if (items !== undefined) {
      return items.map((item) => {
        if (item !== undefined) {
          const targetGroup = targetGroups.find(
            (tg) => tg.TargetGroupId === item
          );

          if (targetGroup !== undefined) {
            const campaignTargetGroup = campaign.TargetGroups.find(
              (tg) => tg.TargetGroupId === item
            );

            return {
              Id: targetGroup.TargetGroupId,
              SecondaryText: targetGroup.ModelType,
              Name: targetGroup.TargetGroupName,
              Description: targetGroup.TargetGroupDescription,
              IsExcluding: campaignTargetGroup
                ? campaignTargetGroup.IsExcluding
                : false
            };
          }

          return [];
        }
        return [];
      });
    }

    return [];
  };

  // Get the targeted content ids
  const getTargetedContentForIds = (itemIds) => {
    if (itemIds !== undefined) {
      return itemIds.map((itemId) => {
        const localTargetedContent = targetedContent.find(
          (tc) => tc.ContentId === itemId
        );

        if (localTargetedContent !== undefined) {
          return {
            Id: localTargetedContent.ContentId,
            SecondaryText: localTargetedContent.ContentType,
            Name: localTargetedContent.ContentTitle,
            Description: localTargetedContent.ContentDescription,
            StartDate: localTargetedContent.StartDate,
            EndDate: localTargetedContent.EndDate,
            Category: localTargetedContent.Category
          };
        }

        return [];
      });
    }

    return [];
  };

  //A modal used to remove one or more targeted content items from the campaign
  const removeTargetedContentFromCampaignModal = () => {
    return (
      <Modal
        title={"Remove Targeted Content From Campaign"}
        open={removeContentFromCampaignModalOpen}
        fullWidth={true}
        fixedHeight={true}
        actions={
          <Fragment>
            <Button
              onClick={() => setRemoveContentFromCampaignModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                let localCampaign = Object.assign({}, campaign);

                targetedContentToDelete.forEach((id) => {
                  var index = localCampaign.TargetContent.indexOf(id);

                  if (index > -1) {
                    localCampaign.TargetContent.splice(index, 1);
                  }
                });

                validateTargetedContentDates();
                setCampaign(localCampaign);
                setRemoveContentFromCampaignModalOpen(false);
                setDataChanged(true);
              }}
              color="secondary"
            >
              Remove Targeted Content
            </Button>
          </Fragment>
        }
      >
        <Search
          searchField={"value"}
          returnSearchMatches={(data) => {
            setSearchedTargetedContentData(data);
          }}
          data={originalTargetedContentData}
          searchTitle="Search Targeted Content"
        />
        <SimpleList
          emptyPlaceholder={"All targeted content has been removed."}
          handleSelection={(selected) => setTargetedContentToDelete(selected)}
          data={searchedTargetedContentData}
        />
      </Modal>
    );
  };

  //A modal used to remove one or more target groups from the campaign
  const removeTargetGroupsFromCampaignModal = () => {
    return (
      <Modal
        title={"Remove Target Groups From Campaign"}
        open={removeTargetGroupsFromCampaignModalOpen}
        fullWidth={true}
        fixedHeight={true}
        actions={
          <Fragment>
            <Button
              onClick={() => setRemoveTargetGroupsFromCampaignModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                let localCampaign = Object.assign({}, campaign);

                targetGroupsToDelete.forEach((id) => {
                  var index = localCampaign.TargetGroups.findIndex(
                    (tg) => tg.TargetGroupId === id
                  );

                  if (index > -1) {
                    localCampaign.TargetGroups.splice(index, 1);
                  }
                });

                setCampaign(localCampaign);
                setRemoveTargetGroupsFromCampaignModalOpen(false);
                setDataChanged(true);
              }}
              color="secondary"
            >
              Remove Target Groups
            </Button>
          </Fragment>
        }
      >
        <Search
          searchField={"value"}
          returnSearchMatches={(data) => {
            setSearchedTargetGroupsData(data);
          }}
          data={originalTargetGroupsData}
          searchTitle="Search Target Groups"
        />
        <SimpleList
          emptyPlaceholder={"All target groups have been removed."}
          handleSelection={(selected) => setTargetGroupsToDelete(selected)}
          data={searchedTargetGroupsData}
        />
      </Modal>
    );
  };

  /*
      Validate the form before it is submitted.
      ates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    let errorCount = 0;
    let localData = Object.assign({}, campaignData);

    Object.keys(localData).forEach((key) => {
      const data = localData[key];

      let errorMessage = "";

      if (key === "StartDate") {
        errorMessage = validateField(
          data.required,
          campaign[key],
          data.type,
          data.label
        );

        if (errorMessage === "") {
          errorMessage = compareDates(
            campaign[key].toISOString(),
            campaign["EndDate"].toISOString(),
            data.label,
            localData["EndDate"].label,
            false
          );
        }
      } else if (key === "EndDate") {
        errorMessage = validateField(
          data.required,
          campaign[key],
          data.type,
          data.label
        );

        if (errorMessage === "") {
          errorMessage = compareDates(
            campaign["StartDate"].toISOString(),
            campaign[key].toISOString(),
            localData["StartDate"].label,
            data.label,
            true
          );
        }
      } else {
        errorMessage = validateField(
          data.required,
          campaign[key],
          data.type,
          data.label,
          data.maxLength || 0
        );
      }

      if (errorMessage.length > 0) {
        errorCount++;
      }

      localData[key].errorMessage = errorMessage;
    });

    setCampaignData(localData);

    if (errorCount > 0) return false;

    return true;
  };

  //When a change is made to the exclude checkbox for target groups
  const handleExcludeCheckboxChange = (rowData) => {
    let localCampaign = Object.assign({}, campaign);

    let localTargetGroups = localCampaign.TargetGroups;

    localTargetGroups.forEach((tg, index) => {
      if (tg.TargetGroupId === rowData[2]) {
        localTargetGroups[index].IsExcluding = !localTargetGroups[index]
          .IsExcluding;
      }
    });

    localCampaign.TargetGroups = localTargetGroups;

    setCampaign(localCampaign);
    setDataChanged(true);
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      dispatch(
        createCampaign(campaign, () => {
          props.history.push("/campaigns");
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateCampaign(campaignId, campaign, () => {
          setIsEditing(false);
          setCampaign(campaign);
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (dataChanged) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);
      setDataChanged(false);

      if (!isExistingCampaign) {
        props.history.push(`/campaigns`);
      }
    }
  };

  // Get the markup for the target group and target content lists
  const getListData = (
    data,
    tableTitle,
    fileName,
    addLabel,
    addAction,
    deleteAction,
    containsExclude,
    showRemove
  ) => {
    let localTargetGroup = {};

    let listData = [];
    let columns = [];

    switch (tableTitle) {
      /* Target Groups */
      case "Target Groups": {
        listData =
          data != null
            ? data.map((item) => {
              return {
                name: item.Name,
                description: item.Description,
                id: item.Id,
                isExcluding: item.IsExcluding
              };
            })
            : [];

        columns = [
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "description",
            label: "Description",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "id",
            label: "Id",
            options: {
              filter: false,
              sort: true,
              display: false
            }
          }
        ];
        break;
      }
      case "Targeted Content": {
        listData =
          data != null || data.length === 0
            ? data.map((item) => {
              if (Array.isArray(item)) { return item }
              return {
                name: item.Name,
                description: item.Description,
                startDate: item.StartDate.split('T')[0],
                endDate: item.EndDate.split('T')[0],
                category: item.Category,
                id: item.Id
              };
            })
            : [];

        columns = [
          {
            name: "name",
            label: "Name",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "description",
            label: "Description",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "startDate",
            label: "Start Date",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "endDate",
            label: "EndDate",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "category",
            label: "Category",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "id",
            label: "Id",
            options: {
              filter: false,
              sort: true,
              display: false
            }
          }
        ];
        break;
      }
    }

    // Checkbox for setting target group as an exclusion on this campaign
    if (containsExclude) {
      columns.push({
        name: "exclude",
        label: "Exclude",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            localTargetGroup = campaign.TargetGroups.find(
              (tg) => tg.TargetGroupId === tableMeta.rowData[2]
            );
            return (
              <FormControlLabel
                disabled={!isEditing}
                labelPlacement="start"
                label=""
                control={
                  <Checkbox
                    checked={
                      localTargetGroup !== undefined
                        ? localTargetGroup.IsExcluding
                        : false
                    }
                    onChange={(event) => {
                      handleExcludeCheckboxChange(tableMeta.rowData);
                    }}
                    name="internal"
                    color="primary"
                  />
                }
              />
            );
          }
        }
      });
    }

    const options = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      viewColumns: false,
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: true,
      downloadOptions: {
        filename: { fileName },
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      }
    };

    if (isEditing) {
      options.customToolbar = () => {
        return (
          <Fragment>
            <Tooltip title={addLabel}>
              <IconButton onClick={addAction}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={"Remove "}
              style={{
                display: showRemove ? "none" : "inline-flex"
              }}
            >
              <IconButton onClick={deleteAction} edge="end" aria-label="delete">
                {getIcon("trash.svg", classes.icon)}
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      };
    }

    return (
      <MUIDataTable
        title={tableTitle}
        data={listData}
        columns={columns}
        options={options}
      />
    );
  };

  /*
    Display the cancel editing alert dialog
  */
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setCancelEditingAlertOpen(false);
              setDataChanged(false);

              if (!isExistingCampaign) {
                props.history.push(`/campaigns`);
              } else {
                setCampaign(uneditedCampaign);
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  const renderData = () => {
    return (
      <div className={styles.contentGrid}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Paper>
              <TitledCard title={campaignData.Name.label}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled={!isEditing}
                  classes={{ root: styles.textField }}
                  label={campaignData.Name.label}
                  value={campaign.Name || ""}
                  onChange={(event) => {
                    let localCampaign = Object.assign({}, campaign);
                    localCampaign.Name = event.target.value;
                    setCampaign(localCampaign);

                    if (!dataChanged) {
                      setDataChanged(true);
                    }
                  }}
                  variant="outlined"
                  margin="dense"
                  error={campaignData.Name.errorMessage !== ""}
                  helperText={campaignData.Name.errorMessage}
                />
              </TitledCard>
            </Paper>
          </Grid>
          <Grid item xs={6} md={6}>
            <Paper>
              <TitledCard title={campaignData.SelectedApplications.label}>
                <MultiSelectDropDown
                  id={props.id}
                  fullWidth={true}
                  multiple={true}
                  disabled={!isEditing}
                  disableCloseOnSelect={true}
                  margin="dense"
                  showCheckbox={true}
                  label={campaignData.SelectedApplications.label}
                  options={
                    applicationList !== undefined
                      ? applicationList
                      : []
                  }
                  selectedValues={
                    Object.keys(campaignData).length > 0 &&
                      campaign.SelectedApplications !== undefined &&
                      campaign.SelectedApplications.length > 0
                      ? campaign.SelectedApplications
                      : []
                  }
                  onChange={(event, value) => {
                    let localCampaign = Object.assign({}, campaign);
                    localCampaign.SelectedApplications = value;
                    setCampaign(localCampaign);
                    if (!dataChanged) {
                      setDataChanged(true);
                    }
                  }}
                  variant="outlined"
                  textFieldLabel="Select Targeted Applications"
                  textFieldPlaceHolder={campaignData.SelectedApplications.label}
                />
                {campaignData.SelectedApplications.errorMessage !== "" ? (
                  <FormHelperText error={true}>
                    {campaignData.SelectedApplications.errorMessage}
                  </FormHelperText>
                ) : null}
              </TitledCard>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper>
              <TitledCard title={campaignData.Description.label}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled={!isEditing}
                  classes={{ root: styles.textField }}
                  label={campaignData.Description.label}
                  value={campaign.Description}
                  multiline={true}
                  rows={4}
                  onChange={(event) => {
                    let localCampaign = Object.assign({}, campaign);
                    localCampaign.Description = event.target.value;
                    setCampaign(localCampaign);

                    if (!dataChanged) {
                      setDataChanged(true);
                    }
                  }}
                  variant="outlined"
                  margin="dense"
                  error={campaignData.Description.errorMessage !== ""}
                  helperText={campaignData.Description.errorMessage}
                />
              </TitledCard>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper>
              <TitledCard title="Start Date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    classes={{ root: styles.datePicker }}
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label="Select Start Date"
                    value={campaign.StartDate}
                    onChange={(date) => {
                      let localCampaign = Object.assign({}, campaign);
                      localCampaign.StartDate = date;
                      setCampaign(localCampaign);

                      if (!dataChanged) {
                        setDataChanged(true);
                      }
                    }}
                    error={campaignData.StartDate.errorMessage !== ""}
                    helperText={campaignData.StartDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </TitledCard>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Paper>
              <TitledCard title="End Date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    classes={{ root: styles.datePicker }}
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label="Select End Date"
                    value={campaign.EndDate}
                    onChange={(date) => {
                      let localCampaign = Object.assign({}, campaign);
                      localCampaign.EndDate = date;
                      setCampaign(localCampaign);

                      if (!dataChanged) {
                        setDataChanged(true);
                      }
                    }}
                    error={campaignData.EndDate.errorMessage !== ""}
                    helperText={campaignData.EndDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </TitledCard>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {getListData(
              campaign.TargetGroups === null
                ? []
                : getTargetGroupsForIds(
                  campaign.TargetGroups &&
                  campaign.TargetGroups.map((tg) => tg.TargetGroupId)
                ),
              "Target Groups",
              `${campaign.Name}-TargetGroups.csv`,
              "Add Target Group",
              () => setTargetGroupModalOpen(true),
              () => setRemoveTargetGroupsFromCampaignModalOpen(true),
              true,
              campaign.TargetGroups !== undefined &&
              campaign.TargetGroups.length === 0
            )}
            {campaignData["TargetGroups"].errorMessage !== "" ? (
              <FormHelperText error>
                {campaignData["TargetGroups"].errorMessage}
              </FormHelperText>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {getListData(
              campaign.TargetContent === null
                ? []
                : getTargetedContentForIds(campaign.TargetContent),
              "Targeted Content",
              `${campaign.Name}-TargetedContent.csv`,
              "Add Targeted Content",
              () => setTargetContentModalOpen(true),
              () => setRemoveContentFromCampaignModalOpen(true),
              false,
              campaign.TargetContent !== undefined &&
              campaign.TargetContent.length === 0
            )}
            {campaignData["TargetContent"].errorMessage !== "" ? (
              <FormHelperText error>
                {campaignData["TargetContent"].errorMessage}
              </FormHelperText>
            ) : null}
            {campaignData["TargetContent"].warningMessage !== "" ? (
              <FormHelperText className={classes.warning}>
                {campaignData["TargetContent"].warningMessage}
              </FormHelperText>
            ) : null}
          </Grid>
        </Grid>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={campaign.Name}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedCampaign(campaign);
                    } else if (isEditing && isExistingCampaign) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing
                    ? isExistingCampaign
                      ? "Done"
                      : "Submit"
                    : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      <TargetGroupPickerModal
        data={targetGroupsPickerData}
        handleCancel={() => {
          setTargetGroupModalOpen(false);
        }}
        handleDone={(selectedItems) => {
          handleTargetGroupSelection(selectedItems);
        }}
        open={targetGroupModalOpen}
        selected={campaign.TargetGroups || []}
        searchTitle="Target Groups"
      />

      <TargetedContentPickerModal
        data={targetedContentPickerData}
        handleCancel={() => {
          setTargetContentModalOpen(false);
        }}
        handleDone={(selectedItems) => {
          handleTargetedContentSelection(selectedItems);
        }}
        open={targetContentModalOpen}
        selected={campaign.TargetedContent || []}
        searchTitle="Targeted Content"
      />

      {removeTargetedContentFromCampaignModal()}
      {removeTargetGroupsFromCampaignModal()}
      {cancelEditingAlert()}

      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewCampaignPage);

// EXPORT COMPONENT
export { hoc as ViewCampaignPage };
