import {
  GET_ALL_CONTENT,
  GET_CONTENT_BY_ID,
  GET_SIZE_METADATA,
  CREATE_NEW_CONTENT,
  MASS_UPLOAD_CONTENT,
  MASS_UPLOAD_IMAGES,
  UPDATE_CONTENT
} from "../constants/action-types";

import Axios from "axios";

import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

const API_CONFIG = getApiConfig();


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

/* 
      Get a list of content.
  */
export const getAllContent = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetAllContent`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_ALL_CONTENT,
        payload: response.data
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

/* 
      Get a single piece of content.
  */
export const getContentById = (contentId, callback) => {
  return async (dispatch) => {
    try {
      if (contentId !== "new") {
        const response = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/GetContentById?contentId=${contentId}`,
          {
            method: "get"
          }
        );

        dispatch({
          type: GET_CONTENT_BY_ID,
          payload: response.data
        });

        callback(true);
      } else {
        dispatch({
          type: GET_CONTENT_BY_ID,
          payload: null
        });

        callback(true);
      }
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

const getImageSize = (imageSizes, size) => {
  const imageData = imageSizes.find((image) => image.sizeName === size);

  return imageData.width;
};

/*
      Create mass upload of content
  */
export const createMassContent = (metadata, images, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    const formData = new FormData();

    images.forEach((file) => {
      formData.append(file.value.name, file.value);
    });
    let failures = [];
    let productImage = [];
    let equipmentImage = [];
    let csvOnly = false;
    let uploadResponse = { status: 200, data: [] };

    if (images.length >= 1) {
      uploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/MigrationUploader`,
        {
          method: "post",
          header: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        }
      );
    } else {
      csvOnly = true;
    }
    if (uploadResponse.status === 200) {
      let successes = uploadResponse.data;

      images.forEach((item) => {
        if (!successes.includes(item.value.name)) {
          failures.push(...item.value.name);
        }
      });

      const DropdownLookup = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetDropdownLookups?scope=all`,
        {
          method: "get"
        }
      );
      if (DropdownLookup.status === 200) {
        metadata.forEach((row) => {
          row.content.categoryId = uuidv4();
          let data = DropdownLookup.data;

          //Convert language to GUID
          row.content.languages = data.LanguageLookups.filter(function (
            item
          ) {
            return (
              item.DisplayName.toLowerCase() ===
              row.content.languages.toLowerCase()
            );
          }).map(function (selected) {
            return selected.Id;
          });

          row.content.retentionPolicyId =
            data.RetentionPolicyLookups.filter(function (item) {
              return (
                item.DisplayName.toLowerCase() ===
                row.content.retentionPolicyId.toLowerCase()
              );
            }).map(function (selected) {
              return selected.Id;
            })[0] || null;

          // Set id so that we can get back the right stuff
          let tempId = uuidv4();
          let m = {
            path: row.content.fileLocation,
            territory: row.content.territory,
            output: tempId
          };

          row["uuid"] = tempId;
          if (row.content.typeId === "productimage") {
            row.content.typeId = data.TypeLookups.filter(function (item) {
              return (
                item.DisplayName.toLowerCase() ===
                row.content.typeId.toLowerCase()
              );
            }).map(function (selected) {
              return selected.Id;
            });
            row.content.typeId = row.content.typeId[0];

            row.content.categoryId =
              data.CategoryLookups.filter(function (item) {
                return item.TypeId === row.content.typeId;
              }).map(function (selected) {
                return selected.Id;
              })[0] || null;

            productImage.push(m);
          } else if (row.content.typeId === "equipmentimage") {
            row.content.typeId = data.TypeLookups.filter(function (item) {
              return (
                item.DisplayName.toLowerCase() ===
                row.content.typeId.toLowerCase()
              );
            }).map(function (selected) {
              return selected.Id;
            });
            row.content.typeId = row.content.typeId[0];

            row.content.categoryId =
              data.CategoryLookups.filter(function (item) {
                return item.TypeId === row.content.typeId;
              }).map(function (selected) {
                return selected.Id;
              })[0] || null;

            equipmentImage.push(m);
          }
        });
      }
      const productImageResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/MassMigrateResources?targetType=productimage&csvOnly=${csvOnly}`,
        {
          method: "post",
          header: headers,
          data: productImage
        }
      ).catch(function (error) { });
      const equipmentImageResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/MassMigrateResources?targetType=equipmentimage&csvOnly=${csvOnly}`,
        {
          method: "post",
          header: headers,
          data: equipmentImage
        }
      ).catch(function (error) { });

      if ( // Not really a needed check as the functionapp now only ever returns a 200 for anything as we're swallowing errors and returning them to the user
        // ++ Application insights logging ovbs
        productImageResponse.status === 200 ||
        equipmentImageResponse.status === 200
      ) {

        for (const [id, values] of Object.entries(
          productImageResponse.data.succeededUploads
        )) {
          metadata.forEach((item) => {
            if (item.uuid === id) {
              item["uploadedResources"] = values.map((res) => {
                return {
                  resourceUrl: res.path,
                  imageSize: {
                    height: res.height,
                    width: res.width
                  }
                };
              });
            }
          });
        }
        for (const [id, values] of Object.entries(
          equipmentImageResponse.data.succeededUploads
        )) {
          metadata.forEach((item) => {
            if (item.uuid === id) {
              item["uploadedResources"] = values.map((res) => {
                return {
                  resourceUrl: res.path,
                  imageSize: {
                    height: res.height,
                    width: res.width
                  }
                };
              });
            }
          });
        }
        // Delete the ones that don't have any associated images to them
        let toUpload = metadata.filter(function (item) {
          return !(
            !Array.isArray(item.uploadedResources) ||
            !("uploadedResources" in item) ||
            !item.uploadedResources.length
          );
        });

        let failures = metadata
          .filter(function (item) {
            return (
              !item.hasOwnProperty("TargetedMediaUrl") &&
              (!Array.isArray(item.uploadedResources) ||
                !("uploadedResources" in item) ||
                !item.uploadedResources.length)
            );
          })
          .map(function (item) {
            return {
              failureReason: "Blob Not Found",
              ...item,
              ...item.content,
              targets: item.targets[0]
            };
          });

        try {
          const massContentResponse = await adalApiFetch(
            Axios, `${API_CONFIG.CONTENT}/CreateMassContent`,
            {
              method: "post",
              data: toUpload,
              headers: headers
            }
          );
          let successes = massContentResponse.data;

          dispatch({
            type: MASS_UPLOAD_IMAGES,
            payload: {
              failures,
              successes,
              message: failures.length >= 1 ? failures.map(function (item) { return { failureReason: item.failureReason } }).filter(onlyUnique) : ""
            }
          });

          callback(true);

        } catch (error) {
          let temp = toUpload.map(function (item) {
            return {
              failureReason: "Item/s failed on content table creation",
              ...item.content,
              ...item,
            }
          })
          failures.length === 0 ? failures = temp : failures.push(temp);
          console.log(`Exception occured: ${error.message}`);
          dispatch({
            type: MASS_UPLOAD_IMAGES,
            payload: {
              failures: failures,
              successes: [],
              message: error.message
            }
          });
          callback(true);
        };
      } else {

        dispatch({
          type: MASS_UPLOAD_IMAGES,
          payload: {
            failures: metadata,
            successes: [],
            message: { ...productImageResponse.data, ...equipmentImageResponse.data }
          }
        });
        callback(true);
      }
    }
  };
};

/*
      Create mass upload of content
  */
export const createMassSaMCNewLeads = (metadata, files, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    let formData = new FormData();

    files.forEach((file) => {
      formData.append(file.value.name, file.value);
    });

    try {
      const uploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/UploadFile`,
        {
          method: "post",
          header: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        }
      );

      let pp = [];

      if (uploadResponse.status === 200) {
        let successes = uploadResponse.data;

        const DropdownLookup = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/GetDropdownLookups?scope=all`,
          {
            method: "get"
          }
        );
        if (DropdownLookup.status === 200) {
          metadata.forEach((row) => {
            row.content.categoryId = uuidv4();
            let data = DropdownLookup.data;

            //Convert language to GUID
            row.content.languages = data.LanguageLookups.filter(function (
              item
            ) {
              return (
                item.DisplayName.toLowerCase() ===
                row.content.languages.toLowerCase()
              );
            }).map(function (selected) {
              return selected.Id;
            });

            row.content.retentionPolicyId =
              data.RetentionPolicyLookups.filter(function (item) {
                return (
                  item.DisplayName.toLowerCase() ===
                  row.content.retentionPolicyId.toLowerCase()
                );
              }).map(function (selected) {
                return selected.Id;
              })[0] || null;

            // Set id so that we can get back the right stuff
            let tempId = uuidv4();
            let m = {
              path: row.content.fileLocation,
              territory: row.content.territory,
              output: tempId
            };

            row["uuid"] = tempId;
            row.content.typeId = data.TypeLookups.filter(function (item) {
              return (
                item.DisplayName.toLowerCase() ===
                row.content.typeId.toLowerCase()
              );
            }).map(function (selected) {
              return selected.Id;
            });
            row.content.typeId = row.content.typeId[0];

            row.content.categoryId =
              data.CategoryLookups.filter(function (item) {
                return item.TypeId === row.content.typeId;
              }).map(function (selected) {
                return selected.Id;
              })[0] || null;

            pp.push(m);
          });
        }
        successes.forEach((success) => {
          metadata.forEach((item) => {
            if (item.content.fileLocation === success.originalFilename) {
              item["uploadedResources"] = [
                {
                  resourceUrl: success.path,
                  imageSize: {
                    height: success.height === null ? 0 : success.height,
                    width: success.width === null ? 0 : success.width
                  }
                }
              ];
            }
          });
        });
        const imageResponse = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/CreateMassContent`,
          {
            method: "post",
            data: metadata,
            headers: headers
          }
        );

        let failures = metadata
          .filter(function (item) {
            return (
              !item.hasOwnProperty("TargetedMediaUrl") &&
              (!Array.isArray(item.uploadedResources) ||
                !("uploadedResources" in item) ||
                !item.uploadedResources.length)
            );
          })
          .map(function (item) {
            return {
              ...item,
              ...item.content,
              targets: item.targets[0]
            };
          });

        if (imageResponse.status !== 200) {
          failures.push(metadata);
        } else {
          successes = imageResponse.data;
        }

        dispatch({
          type: MASS_UPLOAD_IMAGES,
          payload: {
            failures,
            successes
          }
        });

        callback(true);
      } else {
        dispatch({
          type: MASS_UPLOAD_IMAGES,
          payload: {
            failures: metadata,
            successes: []
          }
        });
        callback(true);
      }
    } catch (error) {
      console.log(error);
      console.log(`Exception occured: ${error.message}`);
      dispatch({
        type: MASS_UPLOAD_IMAGES,
        payload: {
          failures: metadata,
          successes: []
        }
      });
      callback(true);
    }
  };
};

export const MassUploadFiles = (files, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    const formData = new FormData();

    files.forEach((file) => {
      formData.append(file.value.name, file.value);
    });

    try {
      const uploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/MigrationUploader`,
        {
          method: "post",
          header: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        }
      );

      if (uploadResponse.status === 200) {
        let successes = uploadResponse.data;
        let failures = [];

        files.forEach((item) => {
          if (!successes.includes(item.value.name)) {
            failures.push(item.value.name);
          }
        });
        dispatch({
          type: MASS_UPLOAD_IMAGES,
          payload: { failures: failures, successes: successes }
        });

        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

/*
      Create new content
  */
export const createNewContent = (
  metadata,
  files,
  contentTypeLabel,
  territory,
  callback
) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    const formData = new FormData();

    files.forEach((file) => {
      formData.append(file.name, file);
    });

    try {
      const uploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD
          }/UploadResourceImage?TargetType=${contentTypeLabel}&territory=${territory}&small=${getImageSize(
            metadata.imageSizes,
            "Small"
          )}&medium=${getImageSize(
            metadata.imageSizes,
            "Medium"
          )}&large=${getImageSize(
            metadata.imageSizes,
            "Large"
          )}&xl=${getImageSize(metadata.imageSizes, "ExtraLarge")}`,
        {
          method: "post",
          header: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        }
      );

      if (uploadResponse.status === 200) {
        metadata["uploadedResources"] = uploadResponse.data.map((res) => {
          return {
            resourceUrl: res.path,
            imageSize: {
              height: res.height,
              width: res.width
            }
          };
        });

        const response = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/CreateContent`,
          {
            method: "post",
            data: metadata,
            headers: headers
          }
        );

        dispatch({
          type: CREATE_NEW_CONTENT,
          payload: response
        });

        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

/*
  Get image size data from the DB
*/
export const getSizeMetadata = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetSizeMetaData`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_SIZE_METADATA,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
      Create new content
  */
export const createSaMCNewLeadsContent = (
  metadata,
  files,
  contentTypeLabel,
  territory,
  callback
) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    const formData = new FormData();

    files.forEach((file) => {
      formData.append(file.name, file);
    });

    try {
      const uploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/UploadFile?territory=${territory}`,
        {
          method: "post",
          header: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        }
      );
      if (uploadResponse.status === 200) {
        metadata["uploadedResources"] = uploadResponse.data.map((res) => {
          return {
            resourceUrl: res.path,
            imageSize: {
              height: res.height === null ? 0 : res.height,
              width: res.width === null ? 0 : res.width
            }
          };
        });

        const response = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/CreateContent`,
          {
            method: "post",
            data: metadata,
            headers: headers
          }
        );

        dispatch({
          type: CREATE_NEW_CONTENT,
          payload: response
        });

        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

export const updateContent = (contentId, metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/UpdateContent?ContentId=${contentId}`,
        {
          method: "put",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: UPDATE_CONTENT,
        payload: metadata
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};
