import { formatISO, getYear, getMonth, getDate } from "date-fns";

export const ModelType = Object.freeze({
  CUSTOMER: "Customer",
  MATERIAL: "Material",
  ACTIVITY: "Activity",
  PROMOTION: "Promotion",
  REDCHANNEL: "Red Channel"
});

export function modelTypeToIndex(modelType) {
  switch (modelType) {
    case ModelType.CUSTOMER:
      return 0;
    case ModelType.MATERIAL:
      return 1;
    case ModelType.ACTIVITY:
      return 2;
    case ModelType.PROMOTION:
      return 3;
    case ModelType.REDCHANNEL:
      return 4;
    default:
      return null;
  }
}

export const getFormat = (date) => {
  return formatISO(
      new Date(
          getYear(date),
          getMonth(date),
          getDate(date),
          0,
          0,
          52
      )
  ).split("+")[0]
}


export const removeColumnsByKey = (arrayOfObjects, keysToRemove) => {
  return arrayOfObjects.map(obj => {
      const newObj = {};
      for (const key in obj) {
          if (!keysToRemove.includes(key)) {
              newObj[key] = obj[key];
          }
      }
      return newObj;
  });
}

export const reorderColumns = (arrayOfObjects, columnOrder) => {
  return arrayOfObjects.map(obj => {
      const newObj = {};
      columnOrder.forEach(column => {
          // Copy values from the original object to the new object
          newObj[column] = obj[column];
      });
      return newObj;
  });
}
