// React
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";


// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import { Loading } from "../../components/loading/Loading";
import { getIcon } from "../../icon/icon";
import { isValid } from "date-fns";
import { compareDates, validateField } from "../../helpers/validation";
import ErrorIcon from "@material-ui/icons/Error";
import { format } from "date-fns";


import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import PageContainer from "../../components/page-container/PageContainer";
import DateFnsUtils from "@date-io/date-fns";
import { UserRoles } from "../../state/constants/UserRoles";
import Modal from "../../components/dialog/Modal";
import { removeColumnsByKey, reorderColumns } from '../../state/constants/ModelType'


// UI and Styling
import {
    Button, Grid, TextField, Paper, Box,
    FormControl, Tooltip, IconButton, FormControlLabel, Switch,
} from "@material-ui/core";

// Segments
import Axios from "axios";
import { adalApiFetch } from "../../config/azureConfig";
import { getApiConfig } from "../../config/apiConfig";
import { getFormat } from '../../state/constants/ModelType'

//Models
import { ViewOrderModel } from "../../models/ViewOrderModel";

const API_CONFIG = getApiConfig();

const MaintainrrcInventory = (props) => {
    const [dropdownDetails, setDropdownDetails] = useState(null);
    const [dropdownRRCRequiredDetails, setDropdownRRCRequiredDetails] = useState(null);
    const [formProperties, setFormProperties] = useState(ViewOrderModel);
    const [selectedDropDown, setSelectedDropDown] = useState(null);
    const [searchedData, setSearchedData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);
    const [disableEditRole, setDisableEditRole] = useState(false);
    const [checkBoxChecked, setCheckBoxChecked] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
    const [maintainOrders, setMaintainOrders] = useState({});
    const [rrcOrderValue, setRrcOrderValue] = useState(null);
    const [checkBoxRRCRequired, setCheckBoxRRCRequired] = useState(true);
    const [showUnprocessedDisabled, setShowUnprocessedDisabled] = useState(true);


    const [loading, setLoading] = useState(false);
    const loggedInUserRoles = useSelector(
        (state) => state.RoleReducer.loggedInUserRoles
    );
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    useEffect(() => {
        if (loggedInUserRoles) {
            const {
                RRC_EDIT_INVENTORY
            } = UserRoles;
            const findRole = loggedInUserRoles.find(item => item === RRC_EDIT_INVENTORY)
            if (findRole) {
                setDisableEditRole(false)
            } else {
                setDisableEditRole(true)
            }
        }
    }, [loggedInUserRoles]);

    useEffect(() => {
        let model = ViewOrderModel;

        Object.keys(model).forEach(key => {
            const value = model[key];
            value.errorMessage = ''
        });
        setFormProperties(model)
    }, [])

    useEffect(() => {
        const siteId = loggedInUser.territory === "au" ? 1 : 2
        adalApiFetch(Axios, `${API_CONFIG.RRC}/GetRRCTPPTDetails?SiteId=${siteId}`,
            {
                method: "get",
            }).then((res) => {
                const filteredData = res.data.TPPTData.filter(item => item.RRCRequired === true)
                setDropdownDetails({
                    "TPPTData": filteredData,
                    "TpptServiceAgents": res.data.TpptServiceAgents,
                })
                setDropdownRRCRequiredDetails(res.data)
                let currentDate = new Date();
                // Subtract two months from the current date
                currentDate.setMonth(currentDate.getMonth() - 2);
                let localData = Object.assign({}, selectedDropDown);
                localData['FromDate'] = getFormat(currentDate);
                localData['ToDate'] = getFormat(new Date());
                setSelectedDropDown(localData)
            })
            .catch((error) => {
                console.log(`Exception Occurred: ${error}`);
            });
    }, []);
    // Custom sorting function
    const customSort = (a, b) => {
        // Compare by null values
        if (a.RRCOrder === null && b.RRCOrder !== null) {
            return -1;
        }
        if (a.RRCOrder !== null && b.RRCOrder === null) {
            return 1;
        }
        return 0; // <- this makes the code clear. it clearly maintains the order when both a.RRCOrder and b.RRCOrder are not null
    };

    const updateSearchData = (data) => {
        const updatedData = data.map(item => {
            return { ...item, ReturnDate: format(new Date(item.ReturnDate), "yyyy/MM/dd") }
        })
        return updatedData.sort(customSort)
    }

    // API call to get Filtered data from database
    const fetchApiData = async (isUpdate) => {
        try {
            setLoading(true)
            const headers = { "Content-Type": "application/json" };
            const selectedServiceAgents = selectedDropDown.ServiceAgent?.map((item) => item.split("|")[0].trimEnd())
            const response = await adalApiFetch(Axios, `${API_CONFIG.RRC}/GetRRCInventory`,
                {
                    params: {
                        TPPT: selectedDropDown?.TPPT,
                        ServiceAgent: `${selectedDropDown?.ServiceAgent?.length > 0 ? selectedServiceAgents : null}`,
                        ToDate: selectedDropDown?.ToDate,
                        FromDate: selectedDropDown?.FromDate
                    },
                    method: "get",
                    headers: headers
                }
            );
            if (response.status === 200) {
                const result = updateSearchData(response.data)
                result.map(item => {
                    // Add the new column to each object
                    item["id"] = uuidv4();
                });
                setOriginalData(result);
                if (!isUpdate) {
                    const filterList = result.filter(item => item.RRCOrder)
                    setSearchedData(filterList)
                    setCheckBoxChecked(false)
                } else {
                    setSearchedData(result)
                    setCheckBoxChecked(true)
                }
                setShowUnprocessedDisabled(false)
                setLoading(false)

            } else {
                console.error(`API request failed with status code: ${response.status}`);
                setLoading(false)
            }
        }
        catch (err) {
            setLoading(false)
            console.log(err);
        }
    }

    /* Transform the maintain Inventory group data into the form that is required by the backend */
    const createRequestBody = () => {
        let requestBody = {
            rrcInventoryGridData: maintainOrders.map(group => {
                return {
                    TPPT: group?.TPPT,
                    ServiceAgent: group?.ServiceAgent,
                    ShipmentNumber: group.Shipment,
                    Invoice: group.InvoiceNumber,
                    InvoiceMaterial: group.InvoiceMaterial,
                    PickUpMaterial: group.PickUpMaterial,
                    RRCNumber: rrcOrderValue === "Blank" ? null : rrcOrderValue,
                }
            })
        }
        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitConfirmClicked = async () => {
        try {
            const headers = { "Content-Type": "application/json" };
            const response = await adalApiFetch(Axios, `${API_CONFIG.RRC}/UpdateInventoryRRCOrder`,
                {
                    method: "POST",
                    headers: headers,
                    data: createRequestBody()
                }
            );
            if (response.status === 200) {
                fetchApiData(true);
                setIsEditPopupOpen(false)
            }
        }
        catch (err) {
            setLoading(false)
            console.log(err);
        }
    }

    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, selectedDropDown);
        localData[name] = value
        if (name === 'TPPT') {
            localData.ServiceAgent = null;
        }
        setShowUnprocessedDisabled(true)
        setCheckBoxChecked(false)
        setSelectedDropDown(localData)
    }
    // The headers of the table
    const createHeaders = () => {
        return [
            {
                id: "Shipment",
                align: "left",
                label: "Shipment",
            },
            {
                id: "ReturnDate",
                align: "left",
                label: "Return Date Invoice",
            },
            {
                id: "InvoiceNumber",
                align: "left",
                label: "Invoice",
            },
            {
                id: "InvoiceMaterial",
                align: "left",
                label: "Invoice Material",
            },
            {
                id: "PickUpMaterial",
                align: "left",
                label: "Actual Material",
            },
            {
                id: "PickUpMaterialName",
                align: "left",
                label: "Actual Material Name",
            },
            {
                id: "QtyReturned",
                align: "left",
                label: "RRC Qty",
            },
            {
                id: "Description",
                align: "left",
                label: "Returned Reason",
            },
            {
                id: "DriverName",
                align: "left",
                label: "Locked Driver Name",
            },
            {
                id: "RRCOrder",
                align: "left",
                label: "RRC Order",
            },
        ];
    };
    // Create the cells for the table and configure them.
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            cell.align = header.align;
            cell.clickable = header.clickable;

            cells.push(cell);
        });

        return cells;
    };

    /*
      Generic validation to perform on all field types
     */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            selectedDropDown?.[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    const validateForm = () => {
        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";

            if (key === "FromDate") {
                errorMessage = checkGenericField(key, formProperties[key].required);

                if (errorMessage === "") {
                    let isD1Valid = isValid(new Date(selectedDropDown["FromDate"]));
                    let isD2Valid = isValid(new Date(selectedDropDown["ToDate"]));
                    if (isD1Valid && isD2Valid) {
                        errorMessage = compareDates(
                            selectedDropDown["FromDate"] && new Date(selectedDropDown[key]).toISOString(),
                            selectedDropDown["ToDate"] && new Date(selectedDropDown["ToDate"]).toISOString(),
                            formProperties[key].label,
                            formProperties["ToDate"].label,
                            false
                        );
                    }
                }
            } else if (key === "ToDate") {
                errorMessage = checkGenericField(key, formProperties[key].required);

                if (errorMessage === "") {
                    let isD1Valid = isValid(new Date(selectedDropDown["FromDate"]));
                    let isD2Valid = isValid(new Date(selectedDropDown["endDate"]));
                    if (isD1Valid && isD2Valid) {
                        errorMessage = compareDates(
                            selectedDropDown["FromDate"] && new Date(selectedDropDown["FromDate"]).toISOString(),
                            selectedDropDown["ToDate"] && new Date(selectedDropDown["ToDate"]).toISOString(),
                            formProperties["FromDate"].label,
                            formProperties[key].label,
                            true
                        );
                    }
                }
            } else if (key === "TPPT") {
                errorMessage = checkGenericField(key, formProperties[key].required);
            }


            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            return false;
        } else {
            return true;
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            fetchApiData(false);
        }
    }

    const handleSelection = (data) => {
        let resultArr = [];
        data.map((arr) => {
            let findData = originalData.find(item => item.id === arr);
            let obj = {
                Shipment: findData?.Shipment,
                DriverName: findData?.DriverName,
                InvoiceMaterial: findData?.InvoiceMaterial,
                InvoiceNumber: findData?.InvoiceNumber,
                IsDisabled: findData?.isDisabled,
                PickUpMaterial: findData?.PickUpMaterial,
                PickUpMaterialName: findData?.PickUpMaterialName,
                QtyReturned: findData?.QtyReturned,
                RRCOrder: findData?.RRCOrder,
                ReturnDate: findData?.ReturnDate,
                ServiceAgent: findData?.ServiceAgent,
                Description: findData?.Description,
                TPPT: findData?.TPPT
            };

            resultArr.push(obj)
        })
        setMaintainOrders(resultArr)
    }

    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("zip.svg")}
                    text="No RRC Orders found."
                />
            ) : (
                <SimpleTable
                    handleSelection={handleSelection}
                    isSelectable={true}
                    onClick={() => { }}
                    dataId={"id"}
                    cells={cells}
                    headers={headers}
                    rows={searchedData}
                    isEditing={true}
                    disableEditRole={disableEditRole}
                />
            );

        return data;
    };
    const onEditClick = () => {
        setIsEditPopupOpen(true)
        setRrcOrderValue(null)
    }
    const handleCancelEditButtonClicked = () => {
        setIsEditPopupOpen(false)
    }
    const handleChange = (checked) => {
        if (checked) {
            setSearchedData(originalData)
        } else {
            const filterList = searchedData.filter(item => item.RRCOrder)
            setSearchedData(filterList)
        }
        setCheckBoxChecked(checked);
    }

    const handleRRCRequired = (checked) => {
        let localData = Object.assign({}, selectedDropDown);
        localData.TPPT = null;
        localData.ServiceAgent = null;
        setSelectedDropDown(localData)
        const filteredData = dropdownRRCRequiredDetails.TPPTData.filter(item => item.RRCRequired === checked)
        setDropdownDetails({
            "TPPTData": filteredData,
            "TpptServiceAgents": dropdownRRCRequiredDetails.TpptServiceAgents,
        })
        setCheckBoxRRCRequired(checked)
    }

    let uniqueStringsArray = new Set(searchedData.filter(item => item.isDisabled === false && item.RRCOrder !== null).map(obj =>
        parseInt(obj.RRCOrder).toString()))
    let uniqueRRCOrderArray = Array.from(uniqueStringsArray).sort((a, b) => b - a);
    if (!uniqueRRCOrderArray.includes('Blank')) {
        uniqueRRCOrderArray.push('Blank')
    }

    const disableEditButton = () => {
        if (disableEditRole === false) {
            return !maintainOrders.length
        } else {
            return disableEditRole
        }
    }

    const handleRRCOrder = (value) => {
        setRrcOrderValue(value)
    }


    var keyMapping = {
        Shipment: 'Shipment',
        ReturnDate: 'Return Date Invoice',
        InvoiceNumber: 'Invoice',
        InvoiceMaterial: 'Invoice Material',
        PickUpMaterial: 'Actual Material',
        PickUpMaterialName: 'Actual Material Name',
        QtyReturned: 'RRC Qty',
        Description: 'Returned Reason',
        DriverName: 'Locked Driver Name',
        RRCOrder: 'RRC Order'
        // Add more key mappings as needed
    };

    const getRenameColumnNames = (arrayOfObjects) => {
        var newArray = arrayOfObjects.map(function (obj) {
            var newObj = {};
            for (var oldKey in obj) {
                if (obj.hasOwnProperty(oldKey) && keyMapping.hasOwnProperty(oldKey)) {
                    newObj[keyMapping[oldKey]] = obj[oldKey];
                } else {
                    newObj[oldKey] = obj[oldKey];
                }
            }
            return newObj;
        });
        return newArray
    }

    const getDataExport = () => {
        const newColumnOrder = ['Shipment', 'ReturnDate', 'InvoiceNumber', 'InvoiceMaterial', 'PickUpMaterial', 'PickUpMaterialName', 'QtyReturned', 'Description', 'DriverName', 'RRCOrder'];
        const newArray = removeColumnsByKey(searchedData, ["ServiceAgent", "TPPT", "isDisabled"]);
        const reOrderArray = reorderColumns(newArray, newColumnOrder);
        const renamedArray = getRenameColumnNames(reOrderArray)
        return renamedArray;
    }
    const serviceAgentDetails = dropdownDetails?.TpptServiceAgents?.filter(item => item.TPPT === selectedDropDown?.TPPT)
    const getServiceSelected = () => {
        if (selectedDropDown?.ServiceAgent) {
            return selectedDropDown.ServiceAgent
        } else {
            return []
        }
    }
    const compareByName = (a, b) => {
        const nameA = a.TPPT.toUpperCase(); // Convert to uppercase for case-insensitive comparison
        const nameB = b.TPPT.toUpperCase();

        if (nameA < nameB) {
            return -1;
        } else if (nameA > nameB) {
            return 1;
        } else {
            return 0; // Names are equal
        }
    }
    const getSortedTPPT = dropdownDetails?.TPPTData?.sort(compareByName)
    const CustomPaper = (props) => (
        <Paper {...props} style={{ height: '100%', maxHeight: '150px', overflowY: 'auto' }} />
    );
    return (
        <div>
            <HeaderBlock
                title={"Maintain RRC Inventory"}
                right={
                    <Grid container spacing={1} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Search
                                searchField={"Shipment"}
                                returnSearchMatches={(data) => {
                                    setSearchedData(data);
                                }}
                                data={originalData}
                                searchTitle="Search Shipment"
                            />
                        </Grid>
                        <Grid item>
                            <ExportExcel disable={!searchedData.length} csvData={getDataExport()} fileName={`RRC Inventory Overview - ${searchedData[0]?.TPPT} - ${format(new Date(), "yyyyMMdd")}`} />
                        </Grid>
                        <Grid item xs={2} style={{ margin: "2px" }}>
                            <Button
                                variant="outlined"
                                size="large"
                                disabled={disableEditButton()}
                                onClick={onEditClick}
                            >
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                } />
            <PageContainer>
                <Paper>
                    <Grid
                        container
                        item xs={12}
                        spacing={0}
                        style={{ display: "flex",justifyContent:"space-evenly" }} 
                        alignItems="center"
                    >
                        <Grid item xs={2} style={{ margin: "2px" }}>
                            <Autocomplete
                                options={getSortedTPPT ? getSortedTPPT.map((item) => item.TPPT) : []}
                                value={selectedDropDown?.TPPT ? selectedDropDown.TPPT : ''}
                                onChange={(e, value) => handleInputChange("TPPT", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        label="TPPT"
                                        placeholder="Select TPPT..."
                                        error={formProperties.TPPT.errorMessage !== ""}
                                        helperText={formProperties.TPPT.errorMessage}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3} style={{ margin: "2px" }}>
                            <Autocomplete
                                options={serviceAgentDetails ? serviceAgentDetails.map((item) => `${item.ServiceAgent} | ${item.ServiceAgentName}`) : []}
                                value={getServiceSelected()}
                                multiple={true}
                                onChange={(e, value) => handleInputChange("ServiceAgent", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        label="Service Agent"
                                        placeholder="Select Service Agent"
                                        error={formProperties.ServiceAgent.errorMessage !== ""}
                                        helperText={formProperties.ServiceAgent.errorMessage}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} style={{ margin: "2px", display: "flex" }}>
                            <FormControl
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="dense"
                                        disableToolbar
                                        fullWidth
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        InputProps={{ readOnly: true }}
                                        value={selectedDropDown?.FromDate ? selectedDropDown.FromDate : null}
                                        label="FromDate"
                                        error={formProperties.FromDate.errorMessage !== ""}
                                        helperText={formProperties.FromDate.errorMessage}
                                        onChange={(date) => {
                                            if (isNaN(date.getTime())) {
                                                //date not valid, assume user is trying to enter the string manually.
                                            } else {
                                                handleInputChange(
                                                    "FromDate",
                                                    getFormat(date)
                                                );
                                            }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <Grid item xs={1}>
                                <Tooltip title="From Date can be applicable within one year limit only">
                                    <IconButton style={{ padding: "25px 5px 0px 0px" }}>
                                        <ErrorIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} style={{ margin: "2px", display: "flex" }}>
                            <FormControl
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="dense"
                                        disableToolbar
                                        fullWidth
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        InputProps={{ readOnly: true }}
                                        label="ToDate"
                                        value={selectedDropDown?.ToDate ? selectedDropDown.ToDate : null}
                                        error={formProperties.ToDate.errorMessage !== ""}
                                        helperText={formProperties.ToDate.errorMessage}
                                        onChange={(date) => {
                                            if (isNaN(date.getTime())) {
                                                //date not valid, assume user is trying to enter the string manually.
                                            } else {
                                                handleInputChange(
                                                    "ToDate",
                                                    getFormat(date)
                                                );
                                            }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <Grid item xs={1}>
                                <Tooltip title="To Date can be applicable within one year limit only">
                                    <IconButton style={{ padding: "25px 5px 0px 0px" }}>
                                        <ErrorIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} style={{ margin: "2px" }}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={handleSubmitClicked}
                            >
                                Apply Filter
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    <Grid item xs={2} style={{ margin: "2px" }}>
                            <FormControlLabel
                                labelPlacement="start"
                                label="RRC Required"
                                control={
                                    <Switch
                                        checked={checkBoxRRCRequired}
                                        onChange={() => handleRRCRequired(!checkBoxRRCRequired)}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={3} style={{ margin: "2px" }}>
                            <FormControlLabel
                                labelPlacement="start"
                                disabled={showUnprocessedDisabled}
                                label="Show unprocessed RRC Products"
                                control={
                                    <Switch
                                        checked={checkBoxChecked}
                                        name="Hide UnProcessed RRC Products"
                                        onChange={() => handleChange(!checkBoxChecked)}
                                        color="primary"
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Paper>
                {loading ? <Loading /> : renderData()}
            </PageContainer>
            <Modal
                title="Update RRC Order"
                open={isEditPopupOpen}
                fullWidth={true}
                actions={
                    <Box>
                        <Button
                            color="secondary"
                            disabled={!rrcOrderValue}
                            onClick={handleSubmitConfirmClicked}
                        >
                            {/* Absorb the onClick */}
                            Submit
                        </Button>
                        <Button
                            color="secondary"
                            onClick={handleCancelEditButtonClicked}
                        >
                            Cancel
                        </Button>
                    </Box>
                }
            >
                <Grid className="parent-container" container style={{ height: '200px' }}>
                    <Grid item xs={6}>
                        <Autocomplete
                            disablePortal
                            id="RRCOrder"
                            name="RRCOrder"
                            options={uniqueRRCOrderArray}
                            PaperComponent={CustomPaper}
                            value={rrcOrderValue}
                            onChange={(e, value) => { handleRRCOrder(value) }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="normal"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    label="RRCOrder"
                                    placeholder="Please Select RRC Order ..."
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </div>
    )
}

const hoc = withRouter(MaintainrrcInventory);

// EXPORT COMPONENT
export { hoc as MaintainrrcInventory };