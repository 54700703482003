import { createTheme } from '@material-ui/core/styles';

export function getCustomTheme() {
  let theme = createTheme({
    palette: {
      // type: 'dark',
      primary: {
        main: '#E71937',
      },
      secondary: {
        main: '#1F3B87',
      },
    },
    overrides: {
      MuiTablePagination: {
        toolbar: {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }
      },
      MuiTableCell: {
        root: {
          fontSize: '12px',
          color: "var(--secondary-font)",
        }
      },
      MuiTypography: {
        h5: {
          fontSize: '20px',
          fontWeight: '600',
        }
      },
      MuiListSubheader: {
        root: {
          textTransform: 'uppercase',
          fontSize: '12px',
          letterSpacing: '0.2px',
          color: "var(--secondary-font)",
        }
      },
      MuiDrawer: {
        root: {
          paddingTop: '0px',
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: "var(--bg)",
        },
        rounded: {
          borderRadius: "8px"
        }
      },
      MuiFormControl: {
        marginDense: {
          marginBottom: "8px"
        }
      },
      MuiButton: {
        root: {
          borderRadius: "8px"
        }
      },
      PrivateNotchedOutline: {
        root: {
          borderRadius: "8px"
        }
      },
      MuiCardContent: {
        root: {
          "&:last-child": {
            paddingBottom: "16px"
          }
        }
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: '"Inter", Arial, Helvetica, sans-serif',
    },
    status: {
      danger: "orange"
    },
  });

  theme.overrides.MuiTableCell = {
    root: {
      padding: '14px 30px 14px 30px',
      [theme.breakpoints.down('md')]: {
        padding: '14px 20px 14px 20px',
      }
    }
  };

  return theme;
}
