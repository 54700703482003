import { AuthenticationContext, adalFetch } from "react-adal";

const isCCATenant = () => {
  return window.location.hostname.endsWith(".ccamatil.com");
}; 
const tenantEnv = isCCATenant() ? process.env.REACT_APP_TENANT : process.env.REACT_APP_CCEP_TENANT;
const clientIdEnv = isCCATenant() ? process.env.REACT_APP_CLIENT_ID : process.env.REACT_APP_CCEP_CLIENT_ID;
const redirectUriEnv = window.location.protocol + "//" + window.location.hostname + ((window.location.port == "" || window.location.port == 443) ? "" : (":" + window.location.port))  + "/auth";

let config = {
  tenant: tenantEnv,
  clientId: clientIdEnv,
  redirectUri: redirectUriEnv,
  cacheLocation: "sessionStorage"
};

const authContext = new AuthenticationContext(config);

export const getToken = () => authContext.getCachedToken(config.clientId);
export const getTokenAsync = (callback) =>
  authContext.acquireToken(config.clientId, callback);
export const getUser = () => authContext.getCachedUser(config.clientId);
export const logout = () => authContext.logOut();
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, config.clientId, fetch, url, options);
