// Data and Config
import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";

// Other
import {
  GET_PRODUCTS,
  GET_TARGETING_DATA,
  UPLOAD_CUSTOMER_DOORS,
  DOWNLOAD_CUSTOMER_DOORS,
  UPSERT_CORERANGE,
  GET_CORERANGE_LIST,
  VIEW_CORERANGE,
  GET_CUSTOMER_ATTRIBUTES,
} from "../constants/action-types";

const FileDownload = require("js-file-download");
const API_CONFIG = getApiConfig();

/*
    Get a list of core ranges.
*/
export const GetCorerangeBySite = (siteId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.CORERANGE}/GetCoreRangeBySite?Site=${siteId}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: GET_CORERANGE_LIST,
      payload: response.data,
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const GetCoreRangeById = (id, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.CORERANGE}/GetCoreRangeById?id=${id}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: VIEW_CORERANGE,
      payload: response.data,
    });
    callback(response.data);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const GetProductList = (siteId, callback) => async (dispatch) => {
  if(!siteId) return;

  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.CORERANGE}/GetProductList?Site=${siteId}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: GET_PRODUCTS,
      payload: response.data,
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const GetTargetGroups = (siteId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.CORERANGE}/GetTargetGroupList?Site=${siteId}`,
      {
        method: "get",
      }
    );
    dispatch({
      type: GET_TARGETING_DATA,
      payload: response.data,
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const GetCoreRangeAsCSV = (siteId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.CORERANGE}/ExportCoreRanges?Site=${siteId}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: DOWNLOAD_CUSTOMER_DOORS,
      payload: {
        data: FileDownload(response.data, `CustomerDoors.csv`),
      },
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const downloadCustomerDoorData =
  (site, callback) => async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.CORERANGE}/ExportDoors?site=${site}`,
        {
          method: "get",
        }
      );

      dispatch({
        type: DOWNLOAD_CUSTOMER_DOORS,
        payload: {
          data: FileDownload(response.data, `CustomerDoors.csv`),
        },
      });

      callback(true);
    } catch (error) {
      console.log(error);
      callback(false);
    }
  };

export const uploadCustomerDoorData =
  (data, site, callback) => async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.CORERANGE}/ImportDoors?site=${site}`,
        {
          method: "post",
          data: data,
        }
      );
      dispatch({
        type: UPLOAD_CUSTOMER_DOORS,
        payload: response.data,
      });

      callback(response.data);
    } catch (error) {
      console.log(error);
      callback(false);
    }
  };

export const upsertCoreRangeAction =
  (data, type, callback) => async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.CORERANGE}/UpsertCoreRange?Type=${type}`,
        {
          method: "post",
          data: data,
        }
      );
      dispatch({
        type: UPSERT_CORERANGE,
        payload: response.data,
      });
      callback(true, response.data.coreRangeModel.coreRangeId);
    } catch (error) {
      console.log(error);
      callback(false);
    }
  };

export const getCustomerAttributes = (siteId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.CORERANGE}/GetCoreRangeCustomerAttributesBySite?Site=${siteId}`,
      {
        method: "get",
      }
    );
    dispatch({
      type: GET_CUSTOMER_ATTRIBUTES,
      payload: response.data,
    });

    callback && callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};
