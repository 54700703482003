export const UserInputModel = () => {
  return {
    firstName: {
      type: "string",
      required: true,
      id: "outlined-firstName",
      name: "firstName",
      label: "First Name",
      placeholder: "Enter first name",
      errorText: ""
    },
    lastName: {
      type: "string",
      required: true,
      id: "outlined-lastName",
      name: "lastName",
      label: "Last Name",
      placeholder: "Enter last name",
      errorText: ""
    },
    sapUsername: {
      type: "string",
      required: false,
      id: "outlined-sapUsername",
      name: "sapUsername",
      label: "SAP Username",
      placeholder: "Enter SAP username",
      errorText: ""
    },
    territory: {
      type: "select",
      required: true,
      id: "outlined-territory",
      name: "territory",
      label: "Site",
      placeholder: "Select territory",
      errorText: ""
    },
    email: {
      type: "email",
      required: true,
      id: "outlined-email",
      name: "email",
      label: "Email",
      placeholder: "Enter email address",
      errorText: ""
    }
  };
};
