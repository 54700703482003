import React, {useEffect, useState, useReducer} from 'react';
import { adalApiFetch } from "../../../../../../config/azureConfig";
import { Autocomplete } from '@material-ui/lab';
import { Box, Button, Grid, TextField, Paper, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { GenericDictionary } from '../../../../../../types/GenericDictionary';
import { getApiConfig } from "../../../../../../config/apiConfig";
import { ProgrammeAction, ProgrammeRequirement, ActivityChoice } from '../../reducers/programme-reducer';
import Axios from "axios";


const API_CONFIG = getApiConfig();


interface RequirementFormProps {
  requirement: ProgrammeRequirement | null;
  requirementKey: string | null;
  handleUpdateRequirements: (action: ProgrammeAction) => void;
  items: GenericDictionary<ProgrammeRequirement>;
  close: () => void;
}

const requirementTemplate: ProgrammeRequirement = {
  activities: [{
    name: '',
    activityId: '',
    choiceIds: []
  }],
  question: '',
  answer: '',
  description: '',
  type: '',
  code: '',
}

const newRequirementReducer = (state: ProgrammeRequirement, action: {type: string; payload: {name?: string, value:any}}) => {
  switch (action.type) {
    case 'HANDLE_TEXT_CHANGE': {
      return {
        ...state,
        [action.payload.name as string]: action.payload.value
      }
    }
    case 'SET_ACTIVITY_ID': {
      const newState = cloneDeep(state);
      newState.activities[0].activityId = action.payload.value;
      return newState;
    }
    case 'SET_CHOICES': {
      const newState = cloneDeep(state);
      newState.activities[0].choiceIds = action.payload.value as ActivityChoice[];
      return newState;
    }
    default:
      return state;
  }
}

function RequirementForm(props: RequirementFormProps) {
  const {
    handleUpdateRequirements,
    close,
    requirement,
    requirementKey,
    items,
  } = props;

  const isNewRequirement = !requirement;
  const initialRequirementData = isNewRequirement ? requirementTemplate : requirement;
  const [newRequirement, dispatch] = React.useReducer(newRequirementReducer, initialRequirementData );
  const [newRequirementKey, setNewRequirementKey] = React.useState<string>(requirementKey ? requirementKey : ''); // Keep this separate from the newRequirement object so that we can set the newRequirement object's key independently
  const [loading, setLoading] = React.useState(false);
  const [selectedActivityId, setSelectedActivityId] = React.useState<string | null>(null);
  const [activityError, setActivityError] = React.useState<string | null>(null);
  const [choiceOptions, setChoiceOptions] = React.useState<ActivityChoice[]>([]);

  useEffect(() => {
    if(newRequirement.activities && newRequirement.activities.length > 0){
      setSelectedActivityId(newRequirement.activities[0].activityId);
    }
  }, [])

  // Get Choices from selected activity
  useEffect(() => {
    const getActivity = async (activityId: string) => {
      setLoading(true);
      setChoiceOptions([]);
      dispatch({type: 'SET_CHOICES', payload: {value: []}});
      const response = await adalApiFetch(Axios, `${API_CONFIG.ACTIVITIES}/GetActivityById?Id=${activityId}`, { method: "get", });
      const activity = await response.data;
      if(!activity.Choices || activity.Choices.length < 1) {
        setActivityError('No choices found for this activity');
        setLoading(false);
        return;
      }
      const choices = activity.Choices.reduce((acc: ActivityChoice[], val: any) => {
        return acc.concat({
          choiceId: `${activity.Activity.Id}${val.Name}`,
        })
      }, []);
      setChoiceOptions(choices);
      setLoading(false);
    }

    if(selectedActivityId) {
      getActivity(selectedActivityId).catch((error) => {
        console.dir(error)
        switch (error.status) {
          case '404':
            setActivityError('Activity not found');
            break;
          default:
            setActivityError('Error getting activity details');
        }
      });
    } else {
      setChoiceOptions([]);
    }
  }, [selectedActivityId]);

  function requirementIsValid() : boolean {
    return newRequirementKey.length > 0
      && keyIsValid()
      && newRequirement.question.length > 0
      && newRequirement.answer.length > 0
      && newRequirement.type.length > 0
      && newRequirement.code.length > 0;
  }

  function keyIsValid() : boolean {
    const isInExistingItems = !!items && Object.keys(items).some(item => item.toLowerCase() === newRequirementKey.toLowerCase());
    const isCurrentItem = requirementKey?.toLowerCase() === newRequirementKey.toLowerCase();

    if(!isInExistingItems || isCurrentItem) return true;
    return false;
  }

  function handleSubmit() {
    if(!requirementIsValid()) {
      return;
    }
    if(isNewRequirement){
      handleUpdateRequirements({type: 'ADD_REQUIREMENT', payload: {[newRequirementKey] : {...newRequirement}}});
    } else {
      handleUpdateRequirements({type: 'UPDATE_REQUIREMENT', payload: {
        oldKey : requirementKey,
        newKey : newRequirementKey,
        value : {...newRequirement},
      }});
    }
    close();
  }

  return (
    <>
      <DialogTitle>
        {isNewRequirement ? 'Add a Requirement' : 'Edit Requirement'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField required value={newRequirementKey || ''} size='small' label="Key" variant="outlined" onChange={ e => setNewRequirementKey(e.target.value) } name='key' fullWidth error={!keyIsValid()} />
            {!keyIsValid() && <small style={{color: 'var(--error)'}}>Key already exists</small> }
          </Grid>
          <Grid item xs={12}>
            <TextField required value={newRequirement.description || ''} size='small' label="Description" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='description' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined">
              <Box padding={2}>
                <h4 style={{marginTop:0}}>Activity Choices</h4>
                <Box display='flex' style={{gap: '1rem'}} mb={2}>
                  <Box flex={1}>
                    {/* The activities property contains an array, but we only allow one activity to be added here. */}
                    <TextField required size='small' label="Activity ID" variant="outlined"
                      value={(newRequirement.activities && newRequirement.activities[0]?.activityId) || ''}
                      onFocus={()=>{setActivityError(null)}}
                      onChange={ e => dispatch({ type: 'SET_ACTIVITY_ID', payload: {name: e.target.name, value: e.target.value }}) } name='activityId' fullWidth
                      onBlur={(e)=>{ setSelectedActivityId(e.target.value) }}
                      />
                  </Box>
                  <Box flex={0}>
                    <Button onClick={()=>{ setSelectedActivityId((newRequirement.activities && newRequirement.activities[0]?.activityId) || null)}}>Search</Button>
                  </Box>
                </Box>
                { activityError !== null && (
                  <Box display='flex' style={{gap: '1rem', color: 'var(--error)'}} mb={2}>{activityError}</Box>
                )}
                {choiceOptions.length > 0 && 
                  <Autocomplete
                    multiple
                    value={(newRequirement.activities && newRequirement.activities[0].choiceIds) ? newRequirement.activities[0]!.choiceIds : []}
                    loading={loading}
                    options={choiceOptions}
                    filterSelectedOptions
                    disabled={!choiceOptions || choiceOptions.length === 0}
                    getOptionLabel={(option) => option.choiceId.replace(newRequirement.activities[0]?.activityId,'')}
                    onChange={(e:any, newValue: any)=>{ dispatch({type: 'SET_CHOICES', payload: {name: 'choices', value: newValue}}) }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size='small'
                        label="Compliant Activity Choices"
                        placeholder="Choices"
                      />
                    )}
                  />
                }
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TextField required value={newRequirement.question || ''} size='small' label="Question" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value }}) } name='question' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField required value={newRequirement.answer || ''} size='small' label="Answer" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='answer' fullWidth />
          </Grid>
          <Grid item xs={6} sm={6} md={5}>
            <TextField required value={newRequirement.type || ''} size='small' label="Type" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='type' fullWidth />
          </Grid>
          <Grid item xs={6} sm={6} md={5}>
            <TextField required value={newRequirement.code || ''} size='small' label="Code" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='code' fullWidth />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display='flex' justifyContent='space-between' flex="1">
          <Box flex='0' flexBasis='8em'>
            <Button variant="text" color="secondary" fullWidth onClick={close}>Cancel</Button>
          </Box>
          <Box flex='0' flexBasis='12em'>
            <Button variant="outlined" color="secondary" disabled={!requirementIsValid()} fullWidth onClick={handleSubmit}>{isNewRequirement ? 'Add Requirement' : 'Save' }</Button>
          </Box>
        </Box>
      </DialogActions>
    </>
  );
}

export default RequirementForm;