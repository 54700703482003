export const ActivityTargetModel = {
  site: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "site",
    label: "Site"
  },
  active: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "active",
    label: "Active"
  },
  rules: {
    type: "array",
    required: false,
    errorMessage: "",
    value: "rules",
    label: "Rules"
  },
  customerNumbers: {
    type: "string",
    required: false,
    errorMessage: "",
    value: "customerNumbers",
    label: "Customer Numbers "
  },
  opportunityValue: {
    type: "number",
    required: true,
    errorMessage: "",
    value: "opportunityValue",
    label: "Opportunity Value"
  },
  targetValue: {
    type: "number",
    required: true,
    errorMessage: "",
    value: "targetValue",
    label: "Target Value"
  }
};
