import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
    GET_ALL_MYCCA_CONTENT,
    GET_MYCCA_CONTENT_BY_ID,
    GET_MYCCA_LOOKUPS,
    UPLOAD_MYCCA_CONTENT,
    GET_MYCCA_RESOURCE,
    GET_ALL_TYPES,
    UPDATE_CONTENT,
    GET_TYPE_BY_ID
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

/* 
    Get All MYCCA Content, for list views only.
*/
export const getAllMyccaContent = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios, `${API_CONFIG.CMS}/GetAllMyCCAContent`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_ALL_MYCCA_CONTENT,
                payload: {
                    data: response.data
                }
            });

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

/*
    Get all types.
*/
export const getAllTypes = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios, `${API_CONFIG.CMS}/GetTypes`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_ALL_TYPES,
                payload: {
                    data: response.data
                }
            });

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

/*
    Get a specific piece of content with all of its metadata.
*/
export const getMyccaContentById = (contentId, callback) => {
    return async (dispatch) => {
        try {
            const response =
                contentId !== undefined
                    ? await adalApiFetch(
                        Axios, `${API_CONFIG.CMS}/GetMyCCAContentById?id=${contentId}`,
                        {
                            method: "get"
                        }
                    )
                    : {};

            dispatch({
                type: GET_MYCCA_CONTENT_BY_ID,
                payload: response
            });

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getMyccaTypeById = (myccaTypeId, callback) => {
    return async (dispatch) => {
        try {
            let response = {};
            if (myccaTypeId !== undefined) {
                response = await adalApiFetch(
                    Axios, `${API_CONFIG.CMS}/GetTypeById?id=${myccaTypeId}`,
                    { method: "get" }
                );
            }
            dispatch({
                type: GET_TYPE_BY_ID,
                payload: response.data
            });

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

/*
    Get all MYCCA-specific lookup data. Doesnt take a scope.
*/
export const getAllMyccaLookups = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios, `${API_CONFIG.CMS}/GetLookups`,
                { method: "get" }
            );

            dispatch({
                type: GET_MYCCA_LOOKUPS,
                payload: response
            });

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const uploadMyccaContent = (requestObj, callback) => {
    return async (dispatch) => {
        try {
            let response = {};
            if ("" !== undefined) {
                response = await adalApiFetch(
                    Axios, `${API_CONFIG.CMS}/UploadContent`,
                    { method: "post", data: requestObj }
                );
            }

            dispatch({
                type: UPLOAD_MYCCA_CONTENT,
                payload: response
            });

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const updateMyccaContent = (requestObj, callback) => {
    return async (dispatch) => {
        try {
            let response = {};
            if ("" !== undefined) {
                response = await adalApiFetch(
                    Axios, `${API_CONFIG.CMS}/UpdateContent?Id=${requestObj.Id}&Description=${requestObj.Description}&EndDate=${requestObj.EndDate}&IsActive=${requestObj.IsActive}&Author=${requestObj.Author}`,
                    { method: "post" }
                );
            }

            dispatch({
                type: UPDATE_CONTENT,
                payload: response
            });

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

/* 
    Create a New category
*/
export const createMyccaType = (data, callback) => {
    return async (dispatch) => {
        try {
            const headers = { "Content-Type": "application/json" };

            await adalApiFetch(
                Axios, `${API_CONFIG.CMS}/CreateMyCCAType`,
                {
                    method: "post",
                    headers: headers,
                    data: data
                }
            );

            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error.message}`);
            callback(false);
        }
    };
};

/* 
    Update an existing category
*/
export const updateMyccaType = (data, callback) => {
    return async (dispatch) => {
        try {
            const headers = { "Content-Type": "application/json" };

            await adalApiFetch(
                Axios, `${API_CONFIG.CMS}/UpdateMyCCAType`,
                {
                    method: "post",
                    headers: headers,
                    data: data
                }
            );
            
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error.message}`);
            callback(false);
        }
    };
};

