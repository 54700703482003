import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { format } from "date-fns";

export const ComplianceActivityRow = ({
  activity,
  displayIndex,
  displayGroupId,
}) => {
  const history = useHistory();

  const activeActivities = activity.Rewards.filter(
    (reward) => reward.IsActive === true
  );

  return (
    <>
      <Grid
        container
        spacing={6}
        style={{
          marginTop: "1px",
          marginBottom: "0.5rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={1}>
          <Typography variant="body1">
            <strong>Compliance</strong>
          </Typography>
        </Grid>
        {displayIndex && (
          <>
            <Grid item xs={1}>
              <Typography
                variant="body2"
                className="activity-link"
                onClick={() =>
                  history.push(
                    `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activity.Activity.Id}`
                  )
                }
              >
                {activity.Activity.Id}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">{activity.Activity.Name}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                {" "}
                {format(new Date(activity.Activity.StartDate), "dd/MM/yyyy")}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                {" "}
                {format(new Date(activity.Activity.EndDate), "dd/MM/yyyy")}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <span
                className={`${
                  activity.Activity.IsActive
                    ? "active-tag-text"
                    : "inactive-tag-text"
                }`}
              >
                {activity.Activity.IsActive ? "Active" : "Inactive"}
              </span>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                {activity.ActivityDataType.Value}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography
                variant="body2"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {activeActivities && activeActivities.length < 1
                  ? 0
                  : Math.max(...activeActivities.map((reward) => reward.Value))}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
