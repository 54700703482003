import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "../../components/dialog/Modal";
import {
    Box, Button, Grid, TextField, Checkbox,
    FormControlLabel
} from "@material-ui/core";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
// Other
import DateFnsUtils from "@date-io/date-fns";
import { getFormat } from '../../state/constants/ModelType'


const ActivityModel = ({ isMassUpdatePopupOpen, formProperties, activity, handleInputChange, handleCancelEditButtonClicked, handleSubmitClicked }) => {
    const typeArray = ["Range", "Share", "Activation"];
    const typeActiveArray = ["Active", "InActive"];
    const submitButtonDisable = () => {
        for (let key in activity) {
            if (key === 'active' && activity[key] !== null) {
                return false
            }
            else if (activity[key]) {
                return false;
            }
        }
        return true;
    }

    return (
        <Modal
            title=""
            open={isMassUpdatePopupOpen}
            fullWidth={false}
            fixedHeight={false}
            actions={
                <Box>
                    <Button
                        color="secondary"
                        disabled={submitButtonDisable()}
                        onClick={handleSubmitClicked}
                    >
                        {/* Absorb the onClick */}
                        Submit
                    </Button>
                    <Button
                        color="secondary"
                        onClick={handleCancelEditButtonClicked}
                    >
                        Cancel
                    </Button>
                </Box>
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="dense"
                            disableToolbar
                            fullWidth
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            label={formProperties.startDate.label}
                            InputProps={{ readOnly: true }}
                            error={formProperties.startDate.errorMessage !== ""}
                            helperText={formProperties.startDate.errorMessage}
                            value={activity['startDate']}
                            onChange={(date) => {
                                if (isNaN(date.getTime())) {
                                    //date not valid, assume user is trying to enter the string manually.
                                } else {
                                    handleInputChange(formProperties.startDate.value, getFormat(date))
                                }
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="dense"
                            disableToolbar
                            fullWidth
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            label={formProperties.endDate.label}
                            value={activity['endDate']}
                            InputProps={{ readOnly: true }}
                            onChange={(date) => {
                                if (isNaN(date.getTime())) {
                                    //date not valid, assume user is trying to enter the string manually.
                                } else {
                                    handleInputChange(formProperties.endDate.value, getFormat(date))
                                }
                            }}
                            error={formProperties.endDate.errorMessage !== ""}
                            helperText={formProperties.endDate.errorMessage}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id={formProperties.sequence.value}
                        name={formProperties.sequence.value}
                        label={formProperties.sequence.label}
                        placeholder="Enter sequence"
                        value={
                            Object.keys(activity).length > 0 &&
                                activity.sequence !== undefined
                                ? activity.sequence
                                : ""
                        }
                        onChange={(event) =>
                            handleInputChange(
                                formProperties.sequence.value,
                                event.target.value
                            )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        error={formProperties.sequence.errorMessage !== ""}
                        helperText={formProperties.sequence.errorMessage}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id={formProperties.active.value}
                        name={formProperties.active.value}
                        options={typeActiveArray}
                        value={
                            Object.keys(activity).length > 0 &&
                                activity.active !== undefined && activity.active !== null
                                ? activity.active ? "Active" : "InActive"
                                : ""
                        }
                        onChange={(e, value) => {
                            handleInputChange(formProperties.active.value, value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="normal"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                label={formProperties.active.label}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id={formProperties.groupType.value}
                        name={formProperties.groupType.value}
                        options={typeArray}
                        value={
                            Object.keys(activity).length > 0 &&
                                activity.groupType !== undefined
                                ? activity.groupType
                                : ""
                        }
                        onChange={(e, value) => {
                            handleInputChange(formProperties.groupType.value, value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="normal"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                label={formProperties.groupType.label}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Modal >
    )
}
const hoc = withRouter(ActivityModel);

// EXPORT COMPONENT
export { hoc as ActivityModel };
