// React
import React from "react";
import { withRouter } from "react-router-dom";
// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import SimpleTable from "../../components/table/Table";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import { getIcon } from "../../icon/icon";
import PageContainer from "../../components/page-container/PageContainer";
import {  reorderColumns } from '../../state/constants/ModelType'

// UI and Styling
import { Grid, Paper, Button, } from "@material-ui/core";
// UI and Styling


const ViewrrcOrderDetails = ({ searchedData, closeDetailsPage, TPPT }) => {
    // The headers of the table
    const createHeaders = () => {
        return [
            {
                id: "RRCMaterial",
                align: "left",
                label: "RRC Material",
            },
            {
                id: "RRCMaterialName",
                align: "left",
                label: "RRC Material Name",
            },
            {
                id: "RRCAvailable",
                align: "left",
                label: "RRC Available",
            },
            {
                id: "RRCReturned",
                align: "left",
                label: "RRC Returned",
            },
            {
                id: "DiscrepancyReason",
                align: "left",
                label: "Discrepancy Reason",
            },
        ];
    };
    // Create the cells for the table and configure them.
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};
            cell.id = header.id;
            cell.align = header.align;
            cell.clickable = header.clickable;

            cells.push(cell);
        });

        return cells;
    };

    const sumColumns = [
        {
            id: "RRCAvailable",
            align: "left",
            label: "RRC Available",
        },
        {
            id: "RRCReturned",
            align: "left",
            label: "RRC Returned",
        }
    ]
    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("zip.svg")}
                    text="No RRC Orders found."
                />
            ) : (
                <SimpleTable
                    handleSelection={null}
                    onClick={() => { }}
                    dataId={"id"}
                    cells={cells}
                    headers={headers}
                    rows={searchedData}
                    isEditing={true}
                    isfooterDetailsVisible={true}
                    sumColumns={sumColumns}
                    replaceColumn='RRCMaterial'
                />
            );

        return data;
    };

    const getOrderedData = () => {
        const newColumnOrder = ['RRCOrder', 'RRCDate', 'ConsignmentNote', 'NoOfPallets', 'RRCMaterial', 'RRCMaterialName', 'RRCAvailable','RRCReturned','DiscrepancyReason'];
        const reOrderArray = reorderColumns(searchedData, newColumnOrder);
        return reOrderArray;
    }

    const getExcelData = () => {
        const reOrderData = getOrderedData()
        let propertySums = {};
        sumColumns.forEach(prop => {
            propertySums[prop.id] = reOrderData.reduce((acc, obj) => acc + obj[prop.id], 0);
        });

        // Create a new object representing the row with the sums
        let sumRow = {};
        sumColumns.forEach(prop => {
            sumRow[prop.id] = propertySums[prop.id];
        });
        sumRow["RRCOrder"] = "Total"
        // Add the new row to the array of objects
        let arrayWithSumRow = [...reOrderData, sumRow];
        return arrayWithSumRow;

    }
    
    return (
        <div>
            <HeaderBlock
                title="View RRC Details"
                right={
                    <Grid container alignItems="center" justify="flex-end">
                        <Grid item xs={2} style={{ margin: "2px" }}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => closeDetailsPage()}
                            >
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={2} style={{ margin: "2px" }}>
                            <ExportExcel csvData={getExcelData()} fileName={`RRC Order - ${TPPT} - ${searchedData[0].RRCOrder}`} />
                        </Grid>
                    </Grid>} />
            <PageContainer>
                <Paper>
                    <Grid
                        container
                        item xs={12}
                        spacing={0}
                        alignItems="center"
                        style={{ padding: '16px'}}
                    >
                        <Grid item  style={{ margin: "2px",paddingRight:"20px", display: "flex", alignItems: 'center' }}>
                            <h3>RRC Order: </h3>
                            <label style={{ marginLeft: '5px', fontSize: '16px' }}>{searchedData?.[0]?.RRCOrder}</label>
                        </Grid>
                        <Grid item  style={{ margin: "2px",paddingRight:"20px", display: "flex", alignItems: 'center' }}>
                            <h3>RRC Date: </h3>
                            <label style={{ marginLeft: '5px', fontSize: '16px' }}>{searchedData?.[0]?.RRCDate}</label>
                        </Grid>
                        <Grid item  style={{ margin: "2px", paddingRight:"20px", display: "flex", alignItems: 'center' }}>
                            <h3>Consignment Note: </h3>
                            <label style={{ marginLeft: '5px', fontSize: '16px' }}>{searchedData?.[0]?.ConsignmentNote}</label>
                        </Grid>
                        <Grid item  style={{ margin: "2px", paddingRight:"20px", display: "flex", alignItems: 'center' }}>
                            <h3>Pallet Qty: </h3>
                            <label style={{ marginLeft: '5px', fontSize: '16px' }}>{searchedData?.[0]?.NoOfPallets}</label>
                        </Grid>
                    </Grid>
                    </Paper>
                    <Grid
                        container
                        item xs={12}
                        spacing={0}
                        style={{ display: "flex"}}
                        alignItems="center" justifyContent="space-evenly"
                    >
                        {renderData()}
                    </Grid>
            </PageContainer>
        </div>)
}


const hoc = withRouter(ViewrrcOrderDetails);

// EXPORT COMPONENT
export { hoc as ViewrrcOrderDetails };