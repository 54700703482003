import {
  MASS_UPLOAD_IMAGES,
  MASS_UPLOAD_CONTENT,
  PPP_MASS_UPLOAD_SUCCESS,
  PPP_MASS_UPLOAD_FAILURE
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
  UploadFailures: [],
  UploadSuccesses: [],
  FailureMessage: "No failures"
};

// REDUCER
export const MigrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case MASS_UPLOAD_IMAGES: {
      return {
        ...state,
        UploadSuccesses: action.payload.successes,
        UploadFailures: action.payload.failures,
        FailureMessage: action.payload.message
      }
    }

    case MASS_UPLOAD_CONTENT: {
      return {
        ...state,
        UploadSuccesses: action.successes,
        UploadFailures: action.failures,
        FailureMessage: action.message
      }
    }

    case PPP_MASS_UPLOAD_SUCCESS: {
      return {
        ...state,
        UploadSuccesses: action.payload,
        UploadFailures: [],
        FailureMessage: ""
      }
    }

    case PPP_MASS_UPLOAD_FAILURE: {
      return {
        ...state,
        UploadFailures: action.payload,
        UploadSuccesses: [],
        FailureMessage: action.message
      }
    }
    default:
      return state;
  }
};
