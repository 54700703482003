// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

// UI and Styling
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';

import styles from "./MyccaResourceImageManagerPage.module.scss";

// Action Imports.
import {
  getLoggedInUserByEmail
} from "../../state/actions/UserActions";
import {
  getAllMyccaContent, getAllMyccaLookups
} from "../../state/actions/MyCCAResourceImageManagerActions"

// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import { OutlinedIconButton } from "../../components/buttons/OutlinedIconButton";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import PageContainer from "../../components/page-container/PageContainer";
import SimpleTable from "../../components/table/Table";

// Other
import { getIcon } from "../../icon/icon";
import { Territories, ExpandedTerritories, TerritoriesWithNA } from "../../state/constants/Territories";
import { UUID } from "../../helpers/uuid";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 200,
    minWidth: 200,
  },
  select: {
    height: 39
  },
  selectAdornment: {
    "& .MuiButtonBase-root": {
      position: "absolute",
      padding: 0,
      right: "32px",
      top: "calc(50% - 12px)",
    },
  },
}));

const MyccaResourceImageManagerPage = (props) => {
  const classes = useStyles();

  const contents = useSelector((state) => state.MyccaResourceImageManagerReducer.myccaAllContent);
  const typedata = useSelector((state) => state.MyccaResourceImageManagerReducer.lookups)
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});
  const [filtersUpdated, setFiltersUpdated] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeTypeFilter, setActiveTypeFilter] = useState("All");

  const setupData = () => {
    setLoading(true);

    dispatch(
      getAllMyccaContent(() => {
        setLoading(false);
      })
    );

    dispatch(
      getAllMyccaLookups(() => {
      })
    );
  };

  useEffect(() => {
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setupData();
        })
      );
    } else {
      setupData();
    }
  }, []);

  useEffect(() => {
    const data = createData(contents);

    setOriginalData(data);

    let localFilters = filters;

    localFilters[loggedInUser.territory] = true;

    setFilters(localFilters);
    setFiltersUpdated(true);
  }, [contents]);

  useEffect(() => {
    setFiltersUpdated(false);
    search(searchText);
  }, [filters, filtersUpdated]);

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const territories = [];

    Object.keys(ExpandedTerritories).forEach((key, index) => {
      if (filters[key]) {
        territories.push(ExpandedTerritories[key]);
      }
    });

    const foundData = originalData.filter((user) => {
      return (
        (user.code.toLowerCase().includes(text) ||
          (user.description && user.description.toLowerCase().includes(text))
        ) && (territories.length === 0 || territories.includes(user.site)));
    });

    setSearchedData(foundData);
  };

  const createData = (users) => {
    if (users.data != undefined) {
      const userKeys = Object.keys(users.data);
      return userKeys.map((userKey) => {
        const user = users.data[userKey];
        return {
          id: user.base.id,
          code: user.base.code,
          description: user.base.description,
          type: user.meta.typeName,
          site: ExpandedTerritories[user.base.site],
          endDate: user.base.endDate,
          isActive: user.base.isActive ? "Yes" : "No"
        };
      });
    }
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const createHeaders = () => {
    return [
      { id: "id", align: "left", label: "Id" },
      { id: "code", align: "left", label: "Code", clickable: true, action: { path: "mycca-content/{id}", identifier: "id" } },
      { id: "description", align: "left", label: "Description" },
      { id: "type", align: "left", label: "Type" },
      { id: "site", align: "left", label: "Site" },
      { id: "endDate", align: "left", label: "End Date" },
      { id: "isActive", align: "left", label: "Active?" }
    ];
  };

  const handleChange = (event) => {
    let localFilters = filters;
    if (filters[event.target.name]) {
      localFilters[event.target.name] = !localFilters[event.target.name];
    } else {
      localFilters[event.target.name] = true;
    }

    setFilters(localFilters);
    setFiltersUpdated(true);
  };

  const loadTypeDataIntoSelect = () => {
    let types = {};

    if (typedata.data != undefined) {

      types = typedata.data.types.map(x => <MenuItem value={x.value}>{x.value}</MenuItem>);
    } else {
      return [<MenuItem value={0}>Loading...</MenuItem>];

    }

    return (types)
  }

  const handleTypeFilterChange = (event) => {
    setActiveTypeFilter(event.target.value);
    searchViaType(event.target.value);
  }

  const resetTypeFilter = () => {
    setActiveTypeFilter("");
    searchViaType("");
  }

  const searchViaType = (tn) => {
    const foundData = originalData.filter((x) => {
      return (
        (x.type.includes(tn))
      )
    })

    setSearchedData(foundData);
  }

  const displayFilters = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="flex-end"
        style={{
          display: showFilters ? "inline-flex" : "none",
          // transition:
          padding: 16
        }}
      >
        {/* Territory filtering */}
        <Typography align="right">Filter by Site: </Typography>
        <FormGroup row>
          {Object.keys(Territories).map((key, index) => {
            return (
              <FormControlLabel
                labelPlacement="start"
                key={UUID()}
                label={Territories[key]}
                control={
                  <Checkbox
                    checked={filters[key]}
                    onChange={handleChange}
                    name={key}
                    color="primary"
                  />
                }
              />
            );
          })}
        </FormGroup>
      </Grid>
    );
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    if (searchedData.length === 0) {
      return (
        <Fragment>
          {displayFilters()}
          <PagePlaceholder
            image={getIcon("users.svg")}
            text="No images found."
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {displayFilters()}
          <SimpleTable
            dataId={"id"}
            cells={cells}
            headers={headers}
            rows={searchedData}
          />
        </Fragment>
      );
    }
  };

  return (
    <div className={styles.container}>
      <BreadcrumbsItem to="/users">Users</BreadcrumbsItem>
      <HeaderBlock
        title={"MyCCA Images"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            {/* Type filtering */}
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Content Type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={activeTypeFilter}
                displayEmpty
                onChange={handleTypeFilterChange}
                label="Type Filter"
                className={classes.select}
                endAdornment={
                  <InputAdornment position="end" className={classes.selectAdornment}>
                    {activeTypeFilter != undefined ? <IconButton onClick={() => { resetTypeFilter() }}><ClearIcon /></IconButton> : null}
                  </InputAdornment>
                }
              >
                {loadTypeDataIntoSelect()}
              </Select>
            </FormControl>
            <Grid item>
              <TextField
                className={styles.roleField}
                id="outlined-name"
                label="Search Images"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <OutlinedIconButton
                clickHandler={() => setShowFilters(!showFilters)}
                icon="funnel.svg"
              />
            </Grid>
            <Grid item><Button
              variant="outlined"
              size="large"
              onClick={() => {
                props.history.push("mycca-image-upload");
              }}
            >
              New Image
            </Button>
            </Grid>
          </Grid>
        }
      />

      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(MyccaResourceImageManagerPage);

// EXPORT COMPONENT
export { hoc as MyccaResourceImageManagerPage };
