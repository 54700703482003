// Data and Config
import jwt from "jsonwebtoken";
import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";

// Other
import {
  GET_USERS,
  GET_USER,
  GET_USER_BY_EMAIL,
  GET_LOGGED_IN_USER_BY_EMAIL,
  CREATE_USER,
  UPDATE_USER,
  ASSIGN_USER_TO_ROLES,
  REMOVE_USER_FROM_ROLES,
  GET_USERS_FOR_ROLE,
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

/* 
    Get a list of users.
*/
export const getUsers = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.USERS}/GetAllUsers`,
        {
          method: "get",
        }
      );

      dispatch({
        type: GET_USERS,
        payload: response.data,
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get a single user.
*/
export const getUser = (userId, callback) => {
  return async (dispatch) => {
    if (userId !== "new") {
      try {
        getTokenAsync(async (errorDesc, token, error) => {
          const response = await adalApiFetch(
            Axios,
            `${API_CONFIG.USERS}/GetUserById?userId=${userId}&token=${token}`,
            {
              method: "get",
            }
          );

          dispatch({
            type: GET_USER,
            payload: response.data,
          });

          callback(true);
        });
      } catch (error) {
        console.log(`Exception Occurred: ${error}`);
        callback(false);
      }
    } else {
      dispatch({
        type: GET_USER,
        payload: null,
      });

      callback(true);
    }
  };
};

/* 
    Get a single user by email.
*/
export const getUserByEmail = (email, callback) => (dispatch) => {
  getTokenAsync((errorDesc, token, error) => {

    adalApiFetch(
      Axios,
      `${API_CONFIG.USERS}/GetUserByEmail?email=ausdom\\${email}&checkAD=true`,
      {
        method: "post",
        data: JSON.stringify(token),
      }
    )
      .then((res) => {
        dispatch({
          type: GET_USER_BY_EMAIL,
          payload: res.data,
        });

        callback(true);
      })
      .catch((error) => {
        console.log(`Exception Occurred: ${error}`);
        callback(false);
      });
  });
};

/* 
    Get the logged in user details by logged in users upn/email.
*/
export const getLoggedInUserByEmail = (callback) => (dispatch) => {
  
  getTokenAsync((errorDesc, token, error) => {
    let userToken = token;

    const cleanToken = userToken.replace('Bearer ','');
    const decodedToken = jwt.decode(cleanToken);

    if (decodedToken) {
      let userEmail;
      if (decodedToken.tid == "c3549632-51ee-40fe-b6ae-a69f3a6cc157") {
        userEmail = decodedToken.extensionattribute10;
      } else {
        userEmail = decodedToken.upn ?? decodedToken.unique_name;
      }
      const userName = `ausdom\\${userEmail}`;

      adalApiFetch(
        Axios,
        `${API_CONFIG.USERS}/GetUserByEmail?email=${userName}&checkAD=true`,
        {
          method: "post",
          data: JSON.stringify(token),
        }
      )
        .then((res) => {
          dispatch({
            type: GET_LOGGED_IN_USER_BY_EMAIL,
            payload: res.data,
          });

          if (callback) callback(true);
        })
        .catch((error) => {
          console.log(`Exception Occurred: ${error}`);
          if (callback) callback(false);
        });
    } else {
      console.log(`Token failed to decode`);
      if (callback) callback(false);
    }
  });
};

// Username is to have ausdom as the domain at the start of the email
const CreateUserNameWithDomain = (userName) => {
  if (userName.indexOf("\\") >= 0) {
    return userName;
  }

  return `ausdom\\${userName}`;
};

/*
    Create a new user
*/
export const createUser = (user, scope, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    const data = {
      userInfo: {
        UserName: CreateUserNameWithDomain(user.email),
        FirstName: user.firstName,
        LastName: user.lastName,
        PaymentAccess: true,
        EmailOptIn: true,
        RouteSite: user.territory === "au" ? 1 : 2,
        SapUserName: user.sapUsername,
      },
      scope: scope,
    };

    try {
      await adalApiFetch(Axios, `${API_CONFIG.USERS}/InsertUser`, {
        method: "post",
        data: data,
        headers: headers,
      });

      dispatch({
        type: CREATE_USER,
        payload: user,
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Update a user
*/
export const updateUser = (userId, user, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    let territory = 1;

    if (user.territory === "nz") {
      territory = 2;
    }

    const data = {
      UserName: CreateUserNameWithDomain(user.email),
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      sapUsername: user.sapUsername,
      territory: user.territory,
      bdeCustomerNumber: user.bdeCustomerNumber,
      bdeCustomerSite: user.bdeCustomerSite,
      currentCustomerNumber: user.currentCustomerNumber,
      RouteSite: territory,
      emailOptIn: user.emailOptIn,
      externalUserId: user.externalUserId,
      paymentAccess: user.paymentAccess,
      primaryPhoneNumber: user.primaryPhoneNumber,
      secondaryPhoneNumber: user.secondaryPhoneNumber,
    };

    try {
      await adalApiFetch(
        Axios,
        `${API_CONFIG.USERS}/UpdateUserById?userId=${userId}`,
        {
          method: "post",
          data: data,
          headers: headers,
        }
      );

      dispatch({
        type: UPDATE_USER,
        payload: user,
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Update user locally only
*/
export const updateUserLocally = (user) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: user,
  });
};

/* 
    Get a list of users for a specific role.
*/
export const getUsersForRole = (roleId, callback) => (dispatch) => {
  adalApiFetch(Axios, `${API_CONFIG.USERS}/GetUsersByRole?roleID=${roleId}`, {
    method: "get",
  })
    .then((res) => {
      dispatch({
        type: GET_USERS_FOR_ROLE,
        payload: res.data,
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    });
};

/* 
    Assign a user to role(s).
*/
export const assignToRoles = (userId, roleIds, callback) => (dispatch) => {
  adalApiFetch(
    Axios,
    `${API_CONFIG.USERS}/AssignUserToRoles?userId=${userId}`,
    {
      method: "post",
      data: roleIds,
    }
  )
    .then((_) => {
      dispatch({
        type: ASSIGN_USER_TO_ROLES,
        payload: { userId, roleIds },
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    });
};

/* 
    Remove a user from role(s).
*/
export const removeFromRoles = (userId, roleIds, callback) => (dispatch) => {
  adalApiFetch(
    Axios,
    `${API_CONFIG.USERS}/RemoveUserFromRoles?userId=${userId}`,
    {
      method: "delete",
      data: roleIds,
    }
  )
    .then((_) => {
      dispatch({
        type: REMOVE_USER_FROM_ROLES,
        payload: { userId, roleIds },
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    });
};
