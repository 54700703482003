export const ActivityRewardModel = {
  upperRange: {
    type: "number",
    required: true,
    errorMessage: "",
    value: "upperRange",
    label: "Upper Range"
  },
  value: {
    type: "number",
    required: true,
    errorMessage: "",
    value: "value",
    label: "Value"
  },
  active: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "active",
    label: "Active"
  }
};
