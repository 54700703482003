// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import { Box, Button, ButtonGroup, Grid } from "@material-ui/core";
import styles from "./MigrationPage.module.scss";

// Actions
import { PPPMassUpload } from "../../state/actions/ActivityActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import PageContainer from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/circleLoader";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import { UUID } from "../../helpers/uuid";

// Other
import { PPPMigrationExpectedColumns } from "../../state/constants/TemplateColumns";

// Moment
var moment = require("moment");
moment().format();

const PPPMigrationPage = (props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [cancelAlertShowing, setCancelAlertShowing] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [csvInvalid, setCSVInvalid] = useState(false);

  const targetIds = useSelector(
    (state) => state.ResourceImageUploaderReducer.targetIds
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (targetsEdited) {
      setSubmitEnabled(true);
    }
  }, [targetsEdited]);

  useEffect(() => {
    if (dataChanged) {
      setDataChanged(false);
    }
  }, [dataChanged]);

  useEffect(() => {
    let localTargets = [];
    setCsvData(localTargets);
  }, [targetIds]);

  const handleSubmit = async () => {
    setIsUploading(true);
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    const data = createRequestBody();

    dispatch(
      PPPMassUpload(data, (res) => {
        if (res) {
          props.history.push("/post-migration");
        }
      })
    );
  };

  const createRequestBody = () => {
    let toReturn = [];
    csvData.forEach((row) => {
      const requestBody = {
        ...row,
        CreatedDate: moment().toISOString(),
        ModifiedDate: moment().toISOString(),
        IsActive: Boolean(Number(row.IsActive))
      };
      toReturn.push(requestBody);
    });
    return toReturn;
  };

  // This refers to the cancel button on the edit or create form
  const handleCancelEditButtonClicked = () => {
    setIsEditing(false);
    props.history.push(`/content-migration`);
  };

  const cancelAlertDialog = () => {
    const title = "Are you sure you want to go back?";
    return (
      <AlertDialog
        title={title}
        description={"Any changes made will be lost."}
        options={[
          {
            label: "Cancel",
            action: () => setCancelAlertShowing(false)
          },
          {
            label: "Confirm",
            action: () => {
              props.history.replace(`/image-uploader`);
              setIsEditing(false);
              setCancelAlertShowing(false);
            }
          }
        ]}
        isOpen={cancelAlertShowing}
      />
    );
  };

  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleSubmit()}
        disabled={!submitEnabled || csvInvalid}
      >
        Submit
      </Button>,
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleCancelEditButtonClicked()}
      >
        Cancel
      </Button>
    ];
  };

  return (
    <Box>
      <Fragment>
        {isUploading ? (
          <>
            <HeaderBlock
              title={"Please Wait: PPP Score Mappings are being uploaded"}
            />
            <PageContainer>
              <LoadingComponent></LoadingComponent>
            </PageContainer>
          </>
        ) : (
          <>
            <HeaderBlock
              title={"PPP Score Mapping Mass Upload"}
              right={
                renderSubmitAndCancelButtons() ? (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      <ButtonGroup>
                        {renderSubmitAndCancelButtons()}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                ) : null
              }
            />

            <PageContainer>
              <div className={styles.contentGrid}>
                <Grid container spacing={3}>
                  <Grid
                    key={"migrationUploadSegment"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <MigrationUploadSegment
                      csvData={csvData}
                      setCsvData={setCsvData}
                      setDataChanged={setDataChanged}
                      title="Upload PPP Score Mapping"
                      targetsEdited={targetsEdited}
                      setTargetsEdited={setTargetsEdited}
                      isEditing={isEditing}
                      setFormEdited={setFormEdited}
                      description="Please upload a CSV containing the PPP Score Mappings that you would like to upload"
                      templateFileName="PPP_Template.csv"
                      expectedColumns={PPPMigrationExpectedColumns}
                      setCSVInvalid={setCSVInvalid}
                    />
                  </Grid>
                </Grid>
              </div>
              {cancelAlertDialog()}
            </PageContainer>
          </>
        )}
      </Fragment>
    </Box>
  );
};

const hoc = withRouter(PPPMigrationPage);

// EXPORT COMPONENT
export { hoc as PPPMigrationPage };
