import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Dialog,
  ThemeOptions
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from "mui-datatables";
import format from "date-fns/format";
import { Loading } from "../../components/loading/Loading";
import ExpandableTableRow from "./ExpandableTableRow";
import { PlannedDisplay, SKU } from "../../state/reducers/MyDisplaysPlanningReducer";
import { createTheme, StylesProvider, MuiThemeProvider } from "@material-ui/core/styles"
import * as styles from "./styles/PlanDetailModal.module.scss";
import { FixMeLater } from "../../types/FixMeLaterType";

interface ThemeType extends ThemeOptions {
  overrides: Object
}

const getMuiTheme = () => createTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none !important',
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        opacity: 0.35,
        pointerEvents: 'none'
      },
    },
  },
} as ThemeType);

interface PlanDetailModalProps {
  open: boolean;
  handleClose: () => void;
  plan: PlannedDisplay | undefined;
  planData: any;
  getTotalQuantity: (skus: SKU[]) => number | "–";
  getMaterialNameFromMaterialNumber: (materialNumber: string) => string;
}

export default function PlanDetailModal(props: PlanDetailModalProps) {
  if(!props.open) return null;

  const theme = getMuiTheme();
  const {
    open,
    handleClose,
    plan, // The main plan object
    planData, // Detail data for the plan
    getTotalQuantity,
    getMaterialNameFromMaterialNumber
  } = props;

  function formatSKUs(value: SKU[]){
    if(value.length > 1) {
      return ( <span>{`${value.length} products`}</span>)
    }
    
    if(value.length === 1) {
      const materialName = getMaterialNameFromMaterialNumber(value[0].MaterialNumber);
      return (
        <span>{materialName}</span>
        )
    }
    return <span>–</span>
  }

  const columns = [
    { name: 'Customer No.', options: {filter: false}},
    { name: 'Start Date', options: {filter: false}},
    { name: 'End Date', options: {filter: false}},
    { name: 'Planned Type', options: {filter: false}},
    { name: 'Display Type', options: {filter: false}},
    { name: 'Location', options: {filter: false}},
    { name: 'SKUs', options: {
      filter: false,
      customBodyRender: (value:FixMeLater) => {
        // if we have SKUs for this row, use those. Otherwise fall back to the original plan values

        if(value && value.length > 0) {
          return <span>{formatSKUs(value)}</span>
        }
        if(plan && plan.SKUs){
          return <span style={{opacity: 0.7}}>{formatSKUs(plan.SKUs)}</span>
        }
        return '-';
      }
    }},
    { name: 'Qty', options: {filter: false}},
    { name: 'Co-op', options: {filter: false}},
    { name: 'RPP', options: {filter: false}},
    { name: 'Notes', options: {filter: false}},
    { name: 'Modified', options: {filter: false}},
  ];

  function resolveDisplayTypeName(detailItem: FixMeLater){
    const {DisplayTypeActivityId} = detailItem.displayType;
    const normal = detailItem.normal?.find((normalItem: FixMeLater) => normalItem.ActivityId === DisplayTypeActivityId);
    return normal?.Value || '–';
  }

  function formatPlanDataforTable(plan: FixMeLater, planData: FixMeLater){
    if(!planData) return [];
    
    return planData.map((item: FixMeLater) => {
      const {customer, SKUs, displayType} = item;
      const totalQuantity = () => {
        // If we don't have any SKUs for this custoner, use the default plan value
        if(!SKUs || !SKUs.length) {
          return getTotalQuantity(plan.SKUs);
        }
        if(typeof SKUs === 'object') {
          // A single SKU record, convert to an array for consistent fomratting
          return getTotalQuantity([].concat(SKUs));
        }
        return getTotalQuantity(SKUs);
      }

      return [
        customer.CustomerNumber,
        format(new Date(customer.StartDate), 'd LLL yyyy'),
        format(new Date(customer.EndDate), 'd LLL yyyy'),
        plan.myDisplaysPlanning.PlannedDisplayType, // Display type form the original plan
        displayType.GroupDescription || '–',   // The final display type
        plan.myDisplaysPlanning.LocationValue || '–',
        SKUs,
        totalQuantity,
        customer.CoopCost || plan.myDisplaysPlanning.CoopCost || '–',
        customer.RecommendedPromotionalPrice || plan.myDisplaysPlanning.RecommendedPromotionalPrice || '–',
        customer.Notes || plan.myDisplaysPlanning.Notes || '–',
        (<div><span>{customer.ModifiedBy}</span><br/>
          <small>on {format(new Date(customer.ModifiedDate), 'd LLL yyyy')}</small></div>)
      ]
    });
  }

  if(!plan) return null;
  
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <StylesProvider injectFirst>
        <MuiDialogTitle className={styles.titleBar}>
          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h6"><span className={styles.planDescription}>{plan.myDisplaysPlanning.Description}</span></Typography>
            </Box>
            <Box ml="auto">
              <IconButton aria-label="Close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </MuiDialogTitle>

        <MuiDialogContent className={styles.modalContent}>
          {/* <pre>{JSON.stringify(planData, 2, 2)}</pre> */}
          {!planData ? <Loading/> :
            <div className={styles.planDetailTableWrapper}>
              <MuiThemeProvider theme={theme}>

                <MUIDataTable
                  // className={styles.planDetailTable}
                  data={formatPlanDataforTable(plan, planData)}
                  title=''
                  options={{
                    download: false,
                    expandableRows: true,
                    expandableRowsHeader: false,
                    search: false,
                    filter: false,
                    fixedHeader: true,
                    fixedSelectColumn: false,
                    pagination: false,
                    sort: false,
                    print: false,
                    responsive: 'standard',
                    selectableRows: 'none',
                    viewColumns: false,
                    isRowExpandable: (index) => {
                      // Enable expansion if there is more than one SKU
                      const rowData = planData[index];
                      return (Array.isArray(rowData.SKUs) && rowData.SKUs.length > 1) || (plan.SKUs && plan.SKUs.length > 1);
                    },
                    renderExpandableRow: (rowData, rowMeta) => {
                      const rawRowData = planData.find((plan:FixMeLater) => plan.customer.CustomerNumber === rowData[0]);
                      return <ExpandableTableRow plan={plan} rowData={rawRowData} getMaterialNameFromMaterialNumber={getMaterialNameFromMaterialNumber}/>
                    }
                  }}
                  columns={columns}
                />
              </MuiThemeProvider>
            </div>
          }
        </MuiDialogContent>

        {/* <MuiDialogActions>
          <Button autoFocus onClick={()=>{handleClose()}} color="primary">
            Save changes
          </Button>
        </MuiDialogActions> */}
      </StylesProvider>
    </Dialog>
  )
}