import {
  GET_ALL_TARGETED_CONTENT_FOR_POS,
  GET_3D_POS_ITEM_BY_ID,
  SET_CURRENT_POS_ITEM
} from "../constants/action-types";

// INITIALIZE STATE
const initialState = {
  posTargetedContent: [],
  currentPOSItem: {},
  currentPOSItemWithSteps: {}
};

// REDUCER
export const ThreeDimensionalPOSReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TARGETED_CONTENT_FOR_POS: {
      const posTargetedContent = action.payload.data;

      return {
        ...state,
        posTargetedContent
      };
    }
    case GET_3D_POS_ITEM_BY_ID: {
      return {
        ...state,
        currentPOSItemWithSteps: action.payload
      };
    }
    case SET_CURRENT_POS_ITEM: {
      return {
        ...state,
        currentPOSItem: action.payload
      };
    }

    default:
      return state;
  }
};
