export const ActivityMassModel = {
    startDate: {
      type: "date",
      required: true,
      errorMessage: "",
      value: "startDate",
      label: "Start Date",
    },
    endDate: {
      type: "date",
      required: true,
      errorMessage: "",
      value: "endDate",
      label: "End Date",
    },
    sequence: {
      type: "string",
      required: true,
      errorMessage: "",
      value: "sequence",
      label: "Sequence",
    },
    active: {
      type: "checkbox",
      required: false,
      errorMessage: "",
      value: "active",
      label: "isActive",
    },
    groupType: {
      type: "string",
      required: false,
      errorMessage: "",
      value: "groupType",
      label: "RED Category",
    },
  };