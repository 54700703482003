import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import HeaderBlock from "../../../components/header-block/HeaderBlock";

import { getActivityById } from "../../../state/actions/MyDisplaysActions";
import { createChoice } from "../../../state/actions/ActivityActions";

import { Loading } from "../../../components/loading/Loading";

const NewChoicePage = (props) => {
  const dispatch = useDispatch();
  const displayGroupId = props.match.params.DisplayGroupId;
  const displayIndex = props.match.params.DisplayIndex;
  const activityId = props.match.params.ActivityId;

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  let createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const currentActivity = useSelector(
    (state) => state.MyDisplaysReducer.displayActivity
  );

  const choiceData = {
    ActivityId: currentActivity.Activity.Id,
    BeverageCategory: null,
    Brand: null,
    CreatedBy: createdUser,
    CreatedDate: new Date(),
    Image1: null,
    Image2: null,
    Image3: null,
    IsActive: true,
    Material: null,
    MinimumVariants: 0,
    ModifiedBy: createdUser,
    ModifiedDate: new Date(),
    Name: null,
    PackSize: null,
    PackType: null,
    SelectionType: 0,
    SubText: null,
    Weight: null,
  };

  const [loading, setLoading] = useState(false);
  const [newChoice, setNewChoice] = useState(choiceData);

  useEffect(() => {
    dispatch(
      getActivityById(activityId, () => {
        setLoading(true);
      })
    );
  }, []);

  const handleNameChange = (e) => {
    let localChoice = { ...newChoice };
    localChoice.Name = e.target.value;
    setNewChoice(localChoice);
  };

  const handleWeightChange = (e) => {
    let localChoice = { ...newChoice };
    localChoice.Weight = e.target.value;
    setNewChoice(localChoice);
  };

  const handleActiveChange = (e) => {
    let localChoice = { ...newChoice };
    localChoice.IsActive = e.target.checked;
    setNewChoice(localChoice);
  };

  const submitChoice = () => {
    dispatch(
      createChoice(newChoice, () => {
        props.history.push(
          `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}`
        );
      })
    );
  };

  return (
    <div>
      {loading ? (
        <>
          <HeaderBlock
            title={`New Choice - ${currentActivity.Activity.Name}`}
            right={
              <ButtonGroup
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    props.history.push(
                      `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}`
                    )
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={!newChoice.Name || !newChoice.Weight}
                  onClick={submitChoice}
                >
                  Save
                </Button>
              </ButtonGroup>
            }
          />
          <div className="create-container" style={{ marginTop: "150px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  label={"Enter Name"}
                  value={newChoice.Name}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  label={"Enter Weight"}
                  value={newChoice.Weight}
                  onChange={handleWeightChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography>Active</Typography>
                <Checkbox
                  color="primary"
                  checked={newChoice.IsActive}
                  onChange={handleActiveChange}
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const hoc = withRouter(NewChoicePage);

// EXPORT COMPONENT
export { hoc as NewChoicePage };
