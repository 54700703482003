export const TargetGroupModel = () => {
  return {
    name: {
      type: "text",
      required: true,
      id: "outlined-name",
      name: "name",
      label: "Name",
      placeholder: "Enter name",
      errorText: ""
    },
    description: {
      type: "text",
      required: true,
      id: "outlined-description",
      name: "description",
      label: "Description",
      placeholder: "Enter description",
      errorText: ""
    },
    site: {
      type: "select",
      required: true,
      id: "outlined-site",
      name: "site",
      label: "Site",
      placeholder: "Select description",
      errorText: ""
    },
    rules: {
      type: "array",
      required: true,
      name: "rules",
      label: "Rules",
      errorText: ""
    }
  };
};
