import { Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@material-ui/core";
import React, { useReducer, useEffect, useState } from "react";
import ContactsList from "./components/contacts-list";
import AlertEmailsList from "./components/alert-emails-list";
import RequirementsList from "./components/requirements-list/requirements-list";
import PriceBooksList from "./components/price-books-list/price-books-list";
import { programmeReducer, SamaProgramme } from "./reducers/programme-reducer";
import PdfsList from "./components/pdfs-list/pdfs-list";
import MaterialGroupsList from './components/material-groups-list/material-groups-list';
import { Autocomplete } from "@material-ui/lab";
import { adalApiFetch } from "../../../../config/azureConfig";
import { getApiConfig } from "../../../../config/apiConfig";
import Axios from "axios";

const API_CONFIG = getApiConfig();

interface SamaProgrammeFormProps {
  data: SamaProgramme;
  setData: (data: SamaProgramme) => void;
  isNew: boolean;
}

interface SamaProgrammeOption {
  Id: string;
  Name: string;
}

function CustomCardHeading(props: {children?: React.ReactNode}) {
  const{children} = props;
  return (
    <Typography variant="subtitle1" component="h2">
      <Box fontWeight={'fontWeightBold'} mb={3}>{children}</Box>
    </Typography>
  )
}

function SamaProgrammeForm(props: SamaProgrammeFormProps) {
  const {
    data,
    setData,
    isNew
  } = props;

  const [formState, dispatch] = useReducer(programmeReducer, data);
  const [programmes, setProgrammes] = useState([]);
  const [loadingProgrammes, setLoadingProgrammes] = useState(false);

  useEffect(() => {
    if(formState.bolton){
      getProgrammes().catch(e => {
        console.log('Error fetching material groups', e);
      });
    }
  }, []);
  
  useEffect(() => {
    setData(formState);
  }, [formState]);

  useEffect(()=>{
    if(formState.bolton && !programmes.length) {
      getProgrammes().catch(e => {
        console.log('Error fetching material groups', e);
      });
    }

    if(formState.bolton === undefined) {
      dispatch({type: "SET_PREREQUISITE", payload: null});
    }
  }, [formState.bolton])
  
  async function getProgrammes(){
    if(programmes.length) return;
    setLoadingProgrammes(true);
    const headers = { "Content-Type": "application/json" };
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.SYNC}/ListSAMAProgrammes`,
      {
        method: "get",
        headers: headers
      }
    );

    response.data.unshift({ Id: null, Name: "None" });

    setProgrammes(response.data);
    setLoadingProgrammes(false);
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { name, checked } = e.target;
    dispatch({
      type: "SET_CHECKBOX_VALUE",
      payload: { name, checked }
    });
  }

  function handleGenericChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) {
    let { name, value } = e.target;
    dispatch({
      type: type,
      payload: { name, value }
    });
  }

  return (
    <form>
      <Box display={'flex'} flexDirection='column' style={{gap: '1rem'}}>
        <Card variant="outlined">
          <CardContent>
            { isNew && 
              <Box mb={2}>
                <TextField required size='small' variant='outlined' value={formState.id} onChange={(e)=> handleGenericChange(e, 'SET_VALUE')} name="id"  label="Id" fullWidth />
              </Box>
            }
            <Box mb={2}>
              <TextField required size='small' variant='outlined' value={formState.details.general.name} onChange={(e)=> handleGenericChange(e, 'SET_NAME')} name="name"  label="Name" fullWidth />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  control={<Checkbox checked={formState.active || false} onChange={handleCheckboxChange} name="active" />}
                  label="Is Active"
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  control={ <Checkbox checked={formState.bolton || false} onChange={handleCheckboxChange} name="bolton" /> }
                  label="Is Bolt-On"
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  control={ <Checkbox checked={formState.protected || false} onChange={handleCheckboxChange} name="protected" /> }
                  label="Is Protected"
                  />
              </Grid>
              {formState.bolton === true && (
                <Grid item xs={12}>
                  <Autocomplete
                    value={formState.prerequisite === undefined ? null : programmes.find((option: SamaProgrammeOption) => {
                      return option.Id === formState.prerequisite;
                    }) || null}
                    options={programmes}
                    disabled={!programmes.length}
                    filterSelectedOptions
                    fullWidth
                    loading={loadingProgrammes}
                    renderInput={(params) => (
                      <TextField {...params} label="Prerequisite Programme" variant="outlined" />
                    )}
                    getOptionLabel={(option : {Id: string, Name: string}) => {
                      return option.Id === null ? option.Name : `${option.Id} – ${option.Name}`;
                    }}
                    onChange={(e, value) => {
                      dispatch({ type: "SET_VALUE", payload: { name: 'prerequisite', value: value!.Id } });
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField required size='small' variant='outlined' value={formState.description} onChange={(e)=> handleGenericChange(e, 'SET_VALUE')} name="description"  label="Description" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required size='small' variant='outlined' value={formState.details.general.length} type="number" onChange={(e)=> handleGenericChange(e, 'SET_LENGTH')} name="length"  label="Length (Years)" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required size='small' variant='outlined' value={formState.availableToSubTrades} onChange={(e)=> handleGenericChange(e, 'SET_AVAILABLE_TO_SUB_TRADES')} name="availableToSubTrades"  label="Available to Sub-trades" fullWidth placeholder="Comma-separated list of subtrade IDs" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required size='small' variant='outlined' value={formState.bannerImage} onChange={(e)=> handleGenericChange(e, 'SET_VALUE')} name="bannerImage"  label="Banner image" fullWidth placeholder="Banner Image Url"/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField size='small' variant='outlined' value={formState.businessOwner} onChange={(e)=> handleGenericChange(e, 'SET_VALUE')} name="businessOwner"  label="Business Owner" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField size='small' variant='outlined' value={formState.detailsToCapture} onChange={(e)=> handleGenericChange(e, 'SET_VALUE')} name="detailsToCapture"  label="Details to Capture" fullWidth />
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField size='small' variant='outlined' value={formState.minimumProductGroups} type="number" onChange={(e)=> handleGenericChange(e, 'SET_NUMERIC_VALUE')} name="minimumProductGroups"  label="Minimum Product Groups for Compliance" fullWidth />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <CustomCardHeading>Contacts</CustomCardHeading>
            <ContactsList contacts={formState.contacts} handleUpdateContacts={dispatch} />
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <CustomCardHeading>Alert Emails</CustomCardHeading>
            <Box mb={2}>
              <AlertEmailsList emails={formState.alertEmailAddresses} handleUpdateAlertEmails={dispatch} />
            </Box>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <Box mb={2}>
              <h3 style={{marginTop: 0}}>Compliance</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField size='small' variant='outlined' value={formState.details.compliance.priceChangeNotice || ''}
                    onChange={(e)=> handleGenericChange(e, 'SET_PRICE_CHANGE_NOTICE_VALUE')} name="priceChangeNotice"  label="Price Change Notice" fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <TextField size='small' variant='outlined' value={formState.details.compliance.redScore || ''} type="number" onChange={(e)=> handleGenericChange(e, 'SET_RED_SCORE_VALUE')} name="redScore"  label="Red Score" fullWidth />
                </Grid>
                <Grid item xs={12} >
                  <h4 style={{marginTop: 0}}>Requirements</h4>
                  <RequirementsList items={formState.details.compliance.requirements} handleUpdateRequirements={dispatch} />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <h3 style={{marginTop: 0}}>PDFs</h3>
            <p>PDFs can be uploaded with the <a href='/content-upload' target="_blank" title='Open content uploader in a new tab.'>Content Uploader</a>.</p>
            <PdfsList pdfs={formState.pdfs} handleUpdatePdfs={dispatch} />
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <h3 style={{marginTop: 0}}>Price Books</h3>
            <p>Price books listed here will be excluded from the programme.</p>
            <PriceBooksList priceBooks={formState.priceBooks} handleUpdatePriceBooks={dispatch}/>
          </CardContent>
        </Card>

        <Card variant="outlined">
          <CardContent>
            <h3 style={{marginTop: 0}}>Material Groups</h3>
            <MaterialGroupsList data={formState.productGroups} handleUpdateMaterialGroups={dispatch} />
          </CardContent>
        </Card>
      </Box>
    </form>
  );
}

export default SamaProgrammeForm;