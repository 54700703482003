import React, { Fragment } from "react";
import styles from "./AccountPopover.module.scss"; // Import css modules stylesheet as styles
import { getUser, logout } from "../../config/azureConfig";
import { ClickAwayListener, Chip, Grid, Icon } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import packageJson from "../../../package.json";

export class AccountPopover extends React.Component {
  constructor() {
    super();

    this.state = {
      accountPopoverShowing: false
    };
  }

  switchTheme() {
    let theme = document.documentElement.getAttribute("data-theme");

    if (theme === "light") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }

    this.forceUpdate();
  }

  handleClickAway() {
    if (this.setState({ accountPopoverShowing: false }));
  }

  render() {
    return (
      <Fragment>
        <div
          className={
            styles.background +
            " " +
            (this.state.accountPopoverShowing ? styles.showing : styles.hidden)
          }
        />
        <ClickAwayListener onClickAway={() => this.handleClickAway()}>
          <div className={styles.accountPopoverContainer}>
            <div
              onClick={() =>
                this.setState({
                  accountPopoverShowing: !this.state.accountPopoverShowing
                })
              }
              className={styles.appBarButton}
            >
              {getUser().userName}
            </div>
            <div className={styles.button}>
              <div
                className={
                  styles.accountPopover +
                  " " +
                  (this.state.accountPopoverShowing
                    ? styles.active
                    : styles.inactive)
                }
              >
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem button onClick={() => logout()}>
                    <ListItemIcon>
                      <Icon color="primary">logout</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>
                  <ListItem classes={{ root: styles.chipContainer }}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Chip
                          variant="outlined"
                          label={process.env.REACT_APP_ENVIRONMENT}
                        />
                      </Grid>
                      <Grid item>
                        <Chip variant="outlined" label={packageJson.version} />
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </Fragment>
    );
  }
}
