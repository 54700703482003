import React from "react";
import styles from "./PageContainer.module.scss"; // Import css modules stylesheet as styles

export default class PageContainer extends React.Component {
  render() {
    return (
      <div
        className={
          styles.container +
          " " +
          (this.props.hasFooter ? styles.containerFooter : null) +
          " " +
          (this.props.hasHelp ? styles.containerHelp : null)
        }
      >
        {this.props.children}
      </div>
    );
  }
}
