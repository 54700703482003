import { v4 as uuidv4 } from 'uuid';

import {
  GET_ALL_CAMPAIGNS,
  GET_CURRENT_CAMPAIGN,
  GET_CAMPAIGNS_BY_TARGET_GROUP_ID,
  UPDATE_CURRENT_CAMPAIGN,
  RESET_CURRENT_CAMPAIGN,
  CREATE_CAMPAIGN,
  GET_ALL_APPLICATIONS,
  GET_APPLICATION_BY_ID
} from "../constants/action-types";

// INITIALIZE STATE
const initialState = {
  campaigns: {},
  currentCampaign: {},
  campaignsRelatedToTargetGroup: {},
  applications: [],
  currentApplication: {}
};

const createNewContent = (serverContent) => {
  let content = {};

  if (serverContent) {
    content.CampaignId = serverContent.CampaignId
      ? serverContent.CampaignId
      : "";

    content.Name = serverContent.Name ? serverContent.Name : "";

    content.Description = serverContent.Description
      ? serverContent.Description
      : "";

    content.StartDate = serverContent.StartDate
      ? new Date(serverContent.StartDate + 'Z')
      : "";

    content.EndDate = serverContent.EndDate
      ? new Date(serverContent.EndDate + 'Z')
      : "";

    content.TargetGroups =
      serverContent.TargetGroups.length > 0
        ? serverContent.TargetGroups.map((tg) => {
          return {
            TargetGroupId: tg.TargetGroupId,
            IsExcluding: tg.IsExcluding,
            Name: tg.Name,
            SecondaryText: tg.EntityTarget
          };
        })
        : [];

    content.SelectedApplications = serverContent.SelectedApplications;

    content.TargetContent =
      serverContent.TargetedContent.length > 0
        ? serverContent.TargetedContent.map((tc) => tc.ContentId)
        : [];
  } else {
    content = {
      CampaignId: "",
      Name: "",
      Description: "",
      StartDate: new Date(),
      EndDate: new Date(),
      TargetGroups: [],
      TargetContent: []
    };
  }

  return content;
};

const createNewApplication = () => {
  let application = {
    ApplicationId: uuidv4().toString(),
    ApplicationName: ""
  }
  return application;
}

// REDUCER
export const CampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CAMPAIGNS: {
      const incomingCampaigns = action.payload.data;
      const campaigns = {};

      incomingCampaigns.forEach((campaign) => {
        campaigns[campaign.CampaignId] = campaign;
      });

      return {
        ...state,
        campaigns
      };
    }
    case GET_CURRENT_CAMPAIGN: {
      const currentCampaign = createNewContent(action.payload.data);

      return {
        ...state,
        currentCampaign: currentCampaign
      };
    }
    case GET_CAMPAIGNS_BY_TARGET_GROUP_ID: {
      const incomingCampaigns = action.payload.data;
      const campaignsRelatedToTargetGroup = {};
      incomingCampaigns.forEach((campaign) => {
        campaignsRelatedToTargetGroup[campaign.CampaignId] = campaign;
      });

      return {
        ...state,
        campaignsRelatedToTargetGroup
      };
    }
    case CREATE_CAMPAIGN: {
      const campaigns = Object.assign({}, state.campaigns);

      return {
        ...state,
        campaigns
      };
    }
    case UPDATE_CURRENT_CAMPAIGN: {
      const currentCampaign = action.payload;

      return {
        ...state,
        currentCampaign
      };
    }

    case RESET_CURRENT_CAMPAIGN: {
      return {
        ...state,
        currentCampaign: {
          Name: "",
          ModelType: "",
          Active: true,
          StartDate: new Date(),
          EndDate: new Date(),
          TargetGroups: [],
          TargetContent: []
        }
      };
    }

    case GET_ALL_APPLICATIONS: {
      return {
        ...state,
        applications: action.payload.data
      }
    }

    case GET_APPLICATION_BY_ID: {
      var curr = action.payload;
      curr == null ? curr = createNewApplication() : curr = curr.data;
      return {
        ...state,
        currentApplication: curr
      }
    }

    default:
      return state;
  }
};
