import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  GetDisplayGroupById,
  UpsertDisplayGroup,
} from "../../../state/actions/MyDisplaysActions";
import {
  createActivity,
  createTarget,
  getActivityById,
  updateTarget,
  getDropdownLookups,
} from "../../../state/actions/ActivityActions";
import { Loading } from "../../../components/loading/Loading";

import { useValidate } from "../Hooks/useValidate";

import { getApiConfig } from "../../../config/apiConfig";

const API_CONFIG = getApiConfig();

const NewDisplayActivity = (props) => {
  const {
    displayGroupDescriptionError,
    setDisplayGroupDescriptionError,
    displayNameError,
    setDisplayNameError,
    displayStartDateError,
    setDisplayNameStartError,
    displayEndDateError,
    setDisplayNameEndDateError,
    displayFrequencyError,
    setDisplayFrequencyError,
    displayMinError,
    setDisplayMinError,
    displayMaxError,
    setDisplayMaxError,
    displaySequenceError,
    setDisplaySequenceError,
  } = useValidate();

  const dispatch = useDispatch();
  const displayGroupId = props.match.params.DisplayGroupId;
  const displayIndex = props.match.params.DisplayIndex;

  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);

  const displayGroup = useSelector(
    (state) => state.MyDisplaysReducer.displayGroup
  );

  const currentActivity = useSelector(
    (state) => state.ActivityReducer.currentActivity
  );

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  let createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const activityData = {
    Activity: {
      ActivityDataTypeId: null,
      ActivityFrequencyId:
        displayGroup.Displays[displayIndex].DisplayTypeActivity
          .ActivityFrequencyId,
      ActivityGroupId:
        displayGroup.Displays[displayIndex].DisplayTypeActivity.ActivityGroupId,
      ActivityTypeId: null,
      ClientId: null,
      EndDate: displayGroup.Displays[displayIndex].DisplayTypeActivity.EndDate,
      IsActive: true,
      IsOneTimeAnswer: false,
      IsRequired: false,
      Max: 0,
      Min: 0,
      ModifiedBy: createdUser,
      Name: null,
      Sequence: null,
      StartDate:
        displayGroup.Displays[displayIndex].DisplayTypeActivity.StartDate,
      CreatedBy: createdUser,
    },
  };

  const [loading, setLoading] = useState(false);
  const [newActivity, setNewActivity] = useState(activityData);
  const [existingDisplayGroup, setExistingDisplayGroup] = useState();
  const [upsertError, setUpsertError] = useState(false);
  const [targetSuccess, setTargetSuccess] = useState(false);
  const [newActivityId, setNewActivityId] = useState("");

  useEffect(() => {
    if (targetSuccess === true) {
      dispatch(
        UpsertDisplayGroup(existingDisplayGroup, (success) => {
          if (success) {
            props.history.push(
              `/my-displays-maintenance/${existingDisplayGroup.GroupId}/${displayIndex}/${newActivityId}`
            );
          } else {
            setUpsertError(true);
          }
        })
      );
    }
  }, [targetSuccess]);

  useEffect(() => {
    dispatch(GetDisplayGroupById(displayGroupId, () => {}));
    setExistingDisplayGroup(displayGroup);
  }, []);

  useEffect(() => {
    dispatch(
      getActivityById(displayGroup.Displays[0].DisplayTypeActivityId, () => {})
    );
    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => {
        setLoading(true);
      })
    );
  }, [displayGroup]);

  const getActivityTypeId = () => {
    let activityTypeString = "";

    const locationActivityType = API_CONFIG.locationActivityType;
    const isLocationActivity =
      displayGroup.Displays[displayIndex].Activities[0].MyDisplaysActivityId ===
      displayGroup.Displays[displayIndex].DisplayTypeActivityId;

    const productActivityType = API_CONFIG.productActivityType;
    const isProductActivity =
      displayGroup.Displays[displayIndex].Activities.length === 1;

    const complianceActivityType = API_CONFIG.complianceActivityType;

    if (isLocationActivity) {
      activityTypeString = lookupData.ActivityTypes.find(
        (item) => item.Id === locationActivityType
      );
    } else if (isProductActivity) {
      activityTypeString = lookupData.ActivityTypes.find(
        (item) => item.Id === productActivityType
      );
    } else {
      activityTypeString = lookupData.ActivityTypes.find(
        (item) => item.Id === complianceActivityType
      );
    }
    return activityTypeString.Id;
  };

  const getDataTypeId = () => {
    let activityTypeString = "";

    const locationDataType = API_CONFIG.locationDataType;
    const isLocationActivity =
      displayGroup.Displays[displayIndex].Activities[0].MyDisplaysActivityId ===
      displayGroup.Displays[displayIndex].DisplayTypeActivityId;

    const productDataType = API_CONFIG.productDataType;
    const isProductActivity =
      displayGroup.Displays[displayIndex].Activities.length === 1;

    if (isLocationActivity) {
      activityTypeString = lookupData.ActivityDataTypes.find(
        (item) => item.Id === locationDataType
      );
    } else if (isProductActivity) {
      activityTypeString = lookupData.ActivityDataTypes.find(
        (item) => item.Id === productDataType
      );
    }
    return activityTypeString.Id;
  };

  const getActivityTypeName = () => {
    let activityTypeString = "";

    const locationActivityType = API_CONFIG.locationActivityType;
    const isLocationActivity =
      displayGroup.Displays[displayIndex].Activities[0].MyDisplaysActivityId ===
      displayGroup.Displays[displayIndex].DisplayTypeActivityId;

    const productActivityType = API_CONFIG.productActivityType;
    const isProductActivity =
      displayGroup.Displays[displayIndex].Activities.length === 1;

    const complianceActivityType = API_CONFIG.complianceActivityType;

    if (isLocationActivity) {
      activityTypeString = lookupData.ActivityTypes.find(
        (item) => item.Id === locationActivityType
      );
    } else if (isProductActivity) {
      activityTypeString = lookupData.ActivityTypes.find(
        (item) => item.Id === productActivityType
      );
    } else {
      activityTypeString = lookupData.ActivityTypes.find(
        (item) => item.Id === complianceActivityType
      );
    }
    return activityTypeString.DisplayName;
  };

  const getDataTypeName = () => {
    let activityTypeString = "";

    const locationDataType = API_CONFIG.locationDataType;
    const isLocationActivity =
      displayGroup.Displays[displayIndex].Activities[0].MyDisplaysActivityId ===
      displayGroup.Displays[displayIndex].DisplayTypeActivityId;

    const productDataType = API_CONFIG.productDataType;
    const isProductActivity =
      displayGroup.Displays[displayIndex].Activities.length === 1;

    if (isLocationActivity) {
      activityTypeString = lookupData.ActivityDataTypes.find(
        (item) => item.Id === locationDataType
      );
    } else if (isProductActivity) {
      activityTypeString = lookupData.ActivityDataTypes.find(
        (item) => item.Id === productDataType
      );
    }
    return activityTypeString.DisplayName;
  };

  const handleNameChange = (e) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.Name = e.target.value;
    setNewActivity(localActivity);
    setDisplayNameError(false);
  };

  const handleActivityTypeChange = (e, value) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.ActivityTypeId = value.Id;
    setNewActivity(localActivity);
  };

  const handleFrequencyChange = (e, value) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.ActivityFrequencyId = value.Id;
    setNewActivity(localActivity);
    setDisplayFrequencyError(false);
  };

  const handleDataTypeChange = (e, value) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.ActivityDataTypeId = value.Id;
    setNewActivity(localActivity);
  };

  const handleMinChange = (e) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.Min = e.target.value;
    setNewActivity(localActivity);
    setDisplayMinError(false);
  };

  const handleMaxChange = (e) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.Max = e.target.value;
    setNewActivity(localActivity);
    setDisplayMaxError(false);
  };

  const handleSequenceChange = (e) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.Sequence = e.target.value;
    setNewActivity(localActivity);
    setDisplaySequenceError(false);
  };

  const handleRequiredChange = (e) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.IsRequired = e.target.checked;
    setNewActivity(localActivity);
  };

  const handleOTAChange = (e) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.IsOneTimeAnswer = e.target.checked;
    setNewActivity(localActivity);
  };

  const handleActiveChange = (e) => {
    let localActivity = { ...newActivity };
    localActivity.Activity.IsActive = e.target.checked;
    setNewActivity(localActivity);
  };

  const submitActivity = () => {
    if (!newActivity.Activity.Name) {
      setDisplayNameError(true);
    } else if (!newActivity.Activity.StartDate) {
      setDisplayNameStartError(true);
    } else if (!newActivity.Activity.EndDate) {
      setDisplayNameEndDateError(true);
    } else if (!newActivity.Activity.ActivityFrequencyId) {
      setDisplayFrequencyError(true);
    } else if (!newActivity.Activity.Min) {
      setDisplayMinError(true);
    } else if (!newActivity.Activity.Max) {
      setDisplayMaxError(true);
    } else if (!newActivity.Activity.Sequence) {
      setDisplaySequenceError(true);
    } else if (
      !displayNameError &&
      !displayStartDateError &&
      !displayEndDateError &&
      !displayFrequencyError &&
      !displayMinError &&
      !displayMinError &&
      !displaySequenceError
    ) {
      let localData = { ...newActivity };
      localData.Activity.ActivityDataTypeId = getDataTypeId()
        ? getDataTypeId()
        : newActivity.Activity.ActivityDataTypeId;
      localData.Activity.ActivityTypeId = getActivityTypeId();
      setNewActivity(localData);
      let newTarget = currentActivity.targets[0];
      dispatch(
        createActivity(newActivity, (success, id) => {
          setNewActivityId(id);
          dispatch(
            updateTarget(
              id,
              newTarget.ActivityTargetId,
              newTarget,
              (success) => {
                if (success) {
                  setTargetSuccess(true);
                }
              }
            )
          );
          if (
            existingDisplayGroup.Displays[displayIndex]
              .DisplayTypeActivityId ===
            existingDisplayGroup.Displays[displayIndex].Activities[0]
              .MyDisplaysActivityId
          ) {
            existingDisplayGroup.Displays[displayIndex].Activities.splice(0, 1);
          }
          existingDisplayGroup.Displays[displayIndex].Activities.push({
            MyDisplaysActivityId: id,
            MaxScore: 0,
          });
        })
      );
    }
  };

  const upsertAlertElement = upsertError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Failed to create new activity<strong> Please contact support</strong>
    </Alert>
  ) : null;

  return (
    <div>
      {loading ? (
        <>
          <HeaderBlock
            title={
              displayGroup.Displays[displayIndex].Activities[0]
                .MyDisplaysActivityId ===
              displayGroup.Displays[displayIndex].DisplayTypeActivityId
                ? "New Location Activity"
                : displayGroup.Displays[displayIndex].Activities.length === 1
                ? "New Product Activity"
                : "New Compliance Activity"
            }
            right={
              <ButtonGroup
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "35px",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    props.history.push(
                      `/my-displays-maintenance/${displayGroupId}/${displayIndex}`
                    )
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={submitActivity}
                >
                  Save
                </Button>
              </ButtonGroup>
            }
          />
          {upsertAlertElement}
          <div style={{ marginTop: "150px" }}>
            <div className="create-container" style={{ marginTop: "20px" }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label={"Name"}
                    onChange={handleNameChange}
                    error={displayNameError}
                    helperText={displayNameError ? "Name is Required." : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    disabled
                    id="combo-box-demo"
                    options={lookupData.ActivityTypes}
                    getOptionLabel={(option) => option.DisplayName}
                    onChange={handleActivityTypeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        label={"Activity Type"}
                        value={getActivityTypeId()}
                        placeholder={getActivityTypeName()}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      required
                      fullWidth
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Effective From"
                      value={newActivity.Activity.StartDate}
                      disabled
                      error={displayStartDateError}
                      helperText={
                        displayStartDateError ? "Start Date is Required" : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Effective to"
                      value={newActivity.Activity.EndDate}
                      disabled
                      error={displayEndDateError}
                      helperText={
                        displayEndDateError ? "End Date is Required" : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    required
                    disablePortal
                    id="combo-box-demo"
                    options={lookupData.ActivityFrequencies}
                    getOptionLabel={(option) => option.DisplayName}
                    onChange={handleFrequencyChange}
                    disabled
                    placeholder={
                      displayGroup.Displays[displayIndex].DisplayTypeActivity
                        .ActivityFrequencyId
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        placeholder={newActivity.Activity.ActivityFrequencyId}
                        label="Frequency"
                        error={displayFrequencyError}
                        helperText={
                          displayFrequencyError ? "Frequency is Required" : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    disabled={getDataTypeId() !== undefined}
                    id="combo-box-demo"
                    options={lookupData.ActivityDataTypes}
                    getOptionLabel={(option) => option.DisplayName}
                    onChange={handleDataTypeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        label={"Data Type"}
                        value={getDataTypeId()}
                        placeholder={getDataTypeName()}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label="Min"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleMinChange}
                    error={displayMinError}
                    helperText={displayMinError ? "Min is Required." : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label="Max"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleMaxChange}
                    error={displayMaxError}
                    helperText={displayMaxError ? "Max is Required." : ""}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label={"Sequence"}
                    onChange={handleSequenceChange}
                    error={displaySequenceError}
                    helperText={
                      displaySequenceError ? "Sequence is Required." : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <span>Required</span>
                  <Checkbox
                    color="primary"
                    checked={newActivity.Activity.IsRequired}
                    onChange={handleRequiredChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <span>One Time Answer</span>
                  <Checkbox
                    color="primary"
                    checked={newActivity.Activity.IsOneTimeAnswer}
                    onChange={handleOTAChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <span>Active</span>
                  <Checkbox
                    color="primary"
                    checked={newActivity.Activity.IsActive}
                    onChange={handleActiveChange}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const hoc = withRouter(NewDisplayActivity);

// EXPORT COMPONENT
export { hoc as NewDisplayActivity };
