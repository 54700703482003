// Data and Config
import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";

// Other
import {
  DOWNLOAD_MD_STOCKWEIGHT_TARGETS,
  UPLOAD_MD_STOCKWEIGHT_TARGETS,
} from "../constants/action-types";

const FileDownload = require('js-file-download');
const API_CONFIG = getApiConfig();


export const downloadMDStockweightTargetData = (site, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.ACTIVITIES}/ExportStockWeightTargets?site=${site}`,
      {
        method: "get"
      }
    );

    dispatch({
      type: DOWNLOAD_MD_STOCKWEIGHT_TARGETS,
      payload: {
        data: FileDownload(response.data, `MyDisplaysStockweightTargets.csv`)
      }
    });

    if(callback) callback(true);
  } catch (error) {
    console.log(error);
    if(callback) callback(false);
  }
};

export const uploadMDStockweightTargetData = (data, site, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.ACTIVITIES}/ImportStockWeightTargets?site=${site}`,
      {
        method: "post",
        data: data
      }
    ); dispatch({
      type: UPLOAD_MD_STOCKWEIGHT_TARGETS,
      payload: response.data
    });

    if(callback) callback(response.data);
  } catch (error) {
    console.log(error);
    if(callback) callback(false);
  }
};