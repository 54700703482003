// React
import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Grid,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
  Tooltip,
  Divider
} from "@material-ui/core";
import styles from "./ViewMyccaTypePage.module.scss";
import HelpIcon from '@material-ui/icons/Help';

// Actions
import {
  createMyccaType,
  getAllMyccaLookups,
  getMyccaTypeById,
  updateMyccaType
} from "../../../state/actions/MyCCAResourceImageManagerActions";
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import CustomCard from "../../../components/custom-card/CustomCard";
import DropdownSelect from "../../../components/dropdown-select/DropdownSelect";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";

// Models
import { MyCCATypeModel } from "../../../models/MyCCATypeModel";

// Other
import { FormType } from "../../../state/constants/FormType";
import { validateField } from "../../../helpers/validation";
import { sortDropdown } from "../../../helpers/sortDropdown";
import EditingAlert from "../../../components/editing-alert/EditingAlert";

const ViewMyccaTypePage = (props) => {
  const currentMyccaType = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.currentMyccaType
  );
  const lookupData = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.lookups
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(MyCCATypeModel);
  const [MyccaType, setMyccaType] = useState({});
  const [uneditedMyccaType, setUneditedMyccaType] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingMyccaType, setIsExistingMyccaType] = useState(true);
  const [MyccaTypeId, setMyccaTypeId] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [typeLookupData, setTypeLookupData] = useState([]);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [lookupsCallMade, setLookupsCallMade] = useState(false);
  const [getMyccaTypeCallMade, setGetMyccaTypeCallMade] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [radioToggle, setRadioToggle] = useState(false);

  //Use Effects
  useEffect(() => {
    setLoading(true);
    setIsExistingMyccaType(props.type != null && props.type === FormType.VIEW);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }

    dispatch(
      getAllMyccaLookups(() => {
        setLookupsCallMade(true);
      })
    );
    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getMyccaTypeById(props.match.params.myccaTypeId, () => {
          setGetMyccaTypeCallMade(true);
          setMyccaTypeId(props.match.params.myccaTypeId);
        })
      );
    } else {
      dispatch(
        getMyccaTypeById(undefined, () => {
          setGetMyccaTypeCallMade(true);
          setIsEditing(true);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (loggedInUserCallMade && lookupsCallMade && getMyccaTypeCallMade) {
      setLoading(false);
    }
  }, [loggedInUserCallMade, lookupsCallMade, getMyccaTypeCallMade]);

  useEffect(() => {
    setTypeLookupData(lookupData.TypeLookups);
  }, [lookupData]);

  useEffect(() => {
    setMyccaType(currentMyccaType);
  }, [currentMyccaType]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, MyccaType);

    localData[name] = value;

    setMyccaType(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      MyccaType[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = checkGenericField(key, formProperties[key].required);

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    let requestBody = {
      name: MyccaType.name,
      multipart: MyccaType.multipart,
      sourceWidth: MyccaType.sourceWidth,
      sourceHeight: MyccaType.sourceHeight,
      desktopWidth: MyccaType.desktopWidth,
      desktopHeight: MyccaType.desktopHeight,
      tabletWidth: MyccaType.tabletWidth,
      tabletHeight: MyccaType.tabletHeight,
      mobileWidth: MyccaType.mobileWidth,
      mobileHeight: MyccaType.mobileHeight,
      createdBy: loggedInUser.userName.substring(
        loggedInUser.userName.indexOf("\\") + 1
      )
    };

    if (!isCreate) {
      requestBody.MyccaTypeId = MyccaTypeId;
    }

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      const body = createRequestBody(true);
      
      dispatch(
        createMyccaType(createRequestBody(true), (success, id) => {
          setIsEditing(false);
          props.history.push(`/mycca-types`);
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateMyccaType(MyccaType, (success) => {
          if (success) {
            setIsEditing(false);
            setSubmitEnabled(true);
          } else {
            setIsEditing(true);
            setSubmitEnabled(true);
          }
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingMyccaType) {
        props.history.push(`/mycca-types`);
      }
    }
  };

  /*
  Display the cancel editing alert dialog
*/
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"Any changes will be discarded."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setMyccaType(uneditedMyccaType);
              setCancelEditingAlertOpen(false);

              if (!isExistingMyccaType) {
                props.history.push(`/mycca-types`);
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  //Display Activity group and associated list of activities
  const renderData = () => {
    return (
      <Fragment>
        {
          isEditing && isExistingMyccaType ?
            <EditingAlert
              isEditing={isEditing}
              title={"Warning"}
              severity={"warning"}
              body={["Existing images of this type will not be automatically scaled to reflect the updated sizes, only new images are supported."]}
            /> :
            <EditingAlert
              isEditing={isEditing}
              title={"Information"}
              severity={"info"}
              body={["Please provide values for all fields, even if the type you are creating will not utilise the images in that size."]}
            />
        }
        <div className={styles.contentGrid}>
          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.name.value}
                  name={formProperties.name.value}
                  label={formProperties.name.label}
                  placeholder="Enter Name"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.name !== undefined
                      ? MyccaType.name
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'name',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.name.errorMessage !== ""}
                  helperText={formProperties.name.errorMessage}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  id={formProperties.multipart.label}
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.multipart.label}
                  control={
                    <Checkbox
                      checked={
                        MyccaType != undefined ?
                          MyccaType.multipart != undefined
                            ? MyccaType.multipart
                            : true
                          : false
                      }
                      onChange={() =>
                        handleInputChange("multipart", MyccaType == undefined ? true : !MyccaType.multipart)
                      }
                      name="active"
                      color="primary"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
              {/* Sizing Field Starts */}
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.sourceWidth.value}
                  name={formProperties.sourceWidth.value}
                  label={formProperties.sourceWidth.label}
                  placeholder="Enter Source Width"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.sourceWidth !== undefined
                      ? MyccaType.sourceWidth
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'sourceWidth',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.sourceWidth.errorMessage !== ""}
                  helperText={formProperties.sourceWidth.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.sourceHeight.value}
                  name={formProperties.sourceHeight.value}
                  label={formProperties.sourceHeight.label}
                  placeholder="Enter sourceHeight"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.sourceHeight !== undefined
                      ? MyccaType.sourceHeight
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'sourceHeight',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.sourceHeight.errorMessage !== ""}
                  helperText={formProperties.sourceHeight.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.desktopWidth.value}
                  name={formProperties.desktopWidth.value}
                  label={formProperties.desktopWidth.label}
                  placeholder="Enter desktopWidth"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.desktopWidth !== undefined
                      ? MyccaType.desktopWidth
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'desktopWidth',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.desktopWidth.errorMessage !== ""}
                  helperText={formProperties.desktopWidth.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.desktopHeight.value}
                  name={formProperties.desktopHeight.value}
                  label={formProperties.desktopHeight.label}
                  placeholder="Enter desktopHeight"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.desktopHeight !== undefined
                      ? MyccaType.desktopHeight
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'desktopHeight',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.desktopHeight.errorMessage !== ""}
                  helperText={formProperties.desktopHeight.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.tabletWidth.value}
                  name={formProperties.tabletWidth.value}
                  label={formProperties.tabletWidth.label}
                  placeholder="Enter tabletWidth"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.tabletWidth !== undefined
                      ? MyccaType.tabletWidth
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'tabletWidth',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.tabletWidth.errorMessage !== ""}
                  helperText={formProperties.tabletWidth.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.tabletHeight.value}
                  name={formProperties.tabletHeight.value}
                  label={formProperties.tabletHeight.label}
                  placeholder="Enter tabletHeight"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.tabletHeight !== undefined
                      ? MyccaType.tabletHeight
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'tabletHeight',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.tabletHeight.errorMessage !== ""}
                  helperText={formProperties.tabletHeight.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.mobileWidth.value}
                  name={formProperties.mobileWidth.value}
                  label={formProperties.mobileWidth.label}
                  placeholder="Enter mobileWidth"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.mobileWidth !== undefined
                      ? MyccaType.mobileWidth
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'mobileWidth',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.mobileWidth.errorMessage !== ""}
                  helperText={formProperties.mobileWidth.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.mobileHeight.value}
                  name={formProperties.mobileHeight.value}
                  label={formProperties.mobileHeight.label}
                  placeholder="Enter mobileHeight"
                  value={
                    MyccaType != undefined &&
                      Object.keys(MyccaType).length > 0 &&
                      MyccaType.mobileHeight !== undefined
                      ? MyccaType.mobileHeight
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      'mobileHeight',
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.mobileHeight.errorMessage !== ""}
                  helperText={formProperties.mobileHeight.errorMessage}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={MyccaType != undefined ? MyccaType.name : 'New MyCCA Type'}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedMyccaType(MyccaType);
                    } else if (isEditing && isExistingMyccaType) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing
                    ? isExistingMyccaType
                      ? "Done"
                      : "Submit"
                    : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelEditingAlert()}

      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewMyccaTypePage);

// EXPORT COMPONENT
export { hoc as ViewMyccaTypePage };
