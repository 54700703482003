// React
import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Box,
  Button,
  Container,
  Grid
} from "@material-ui/core";
import styles from "../ViewCategoryPage.module.scss";

// Components
import HeaderBlock from "../../../../components/header-block/HeaderBlock";
import { Loading } from "../../../../components/loading/Loading";
import PageContainer from "../../../../components/page-container/PageContainer";
import SamaProgrammeForm from "./SamaProgrammeForm";

import { adalApiFetch } from "../../../../config/azureConfig";
import Axios from "axios";
import { getApiConfig } from "../../../../config/apiConfig";
import { FixMeLater } from "../../../../types/FixMeLaterType";
import { SamaProgramme, initialFormState } from "./reducers/programme-reducer";
import { cloneDeep, isEqual } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "state/reducers/AppReducer";
import { UserReducerState } from "state/reducers/UserReducer";

const API_CONFIG = getApiConfig();

function checkHasChanges(initialState: SamaProgramme | undefined, currentState: SamaProgramme){
  return isEqual(initialState, currentState);
}

const SaMaProgrammeDetailPage = (props: FixMeLater) => {

  //Setup State
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState<string | null>(null);
  const [initialState, setInitialState] = useState({...initialFormState});
  const [programmeData, setProgrammeData] = useState<SamaProgramme>({...initialFormState});
  const { loggedInUser } = useSelector<RootState, UserReducerState>((state) => state.UserReducer);

  const isNew = useRef(false);
  isNew.current = props.match.params.ProgrammeId === "new";

  // Use Effects
  useEffect(() => {
    setSavingError(null);
  }, [programmeData.id]);

  useEffect(() => {
    setLoading(true);

    if(isNew.current === true) {
      setLoading(false);
      return;
    } 

    const fetchData = async () => {
      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
          Axios, `${API_CONFIG.SYNC}/GetSAMAProgramme?Id=${props.match.params.ProgrammeId}`,
          {
            method: "get",
            headers: headers
          }
        );
        
      const programmeData = response.data;

      setInitialState(cloneDeep(programmeData));
      setProgrammeData(cloneDeep(programmeData));
      setLoading(false);
    }
    fetchData().catch(console.error);

    return () => {
      setInitialState(cloneDeep(initialFormState));
      setProgrammeData(cloneDeep(initialFormState));
    }
  }, []);

  function handleSaveProgramme(){
    setSaving(true);

    if(isNew && programmeData.id && programmeData.id.toLowerCase().trim() === 'new') {
      setSavingError(`"${programmeData.id}" is not a valid Programme ID`);
      setSaving(false);
      return;
    }

    const programmeId = isNew.current === true ? programmeData.id : props.match.params.ProgrammeId;
    const data = cloneDeep(programmeData);

    if(isNew.current === true) {
      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
      data.createdAt = localISOTime;
      if(loggedInUser) {
        data.createdBy = loggedInUser.userId;
      }
    }

    async function submitProgramme(programmeData: SamaProgramme){
      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.SYNC}/UpdateSAMAProgramme?Id=${programmeId}`,
          {
            method: "post",
            headers: headers,
            data: JSON.stringify(programmeData)
          }
        );
        setSaving(false);
      }
      
      submitProgramme(data).then(() => {
        setInitialState(cloneDeep(data));
      }).catch(e => {
        setSavingError(`Error saving programme. Please check the form and try again.`);
        console.log('Error saving programme', e);
        setTimeout(()=>{
          setSavingError(null);
        }, 5000);
      });
  }

  function handleResetProgramme(){
    console.log('Reset Programme', programmeData, initialState);
    setProgrammeData({...initialState});
  }

  const hasChanges = useCallback(()=>{
    return checkHasChanges(initialState, programmeData);
  }, [initialState, programmeData])


  // Display Applcation data
  const renderData = () => {
    return (
      <Container maxWidth='lg'>
        <div className={styles.contentGrid} style={saving ? {opacity: 0.6, pointerEvents: 'none'} : {opacity: 1, pointerEvents: 'all'}}>
          {/* @ts-ignore */}
          <Grid container spacing={3} style={{paddingBottom: '3rem'}}>
            <Grid item xs={12}>
              <SamaProgrammeForm data={programmeData} setData={setProgrammeData} isNew={isNew.current}/>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  };

  const pageTitle = isNew.current ? 'New Programme' : `Editing: ${programmeData.details.general.name} – ${decodeURIComponent(props.match.params.ProgrammeId)}`;

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <HeaderBlock
        title={pageTitle}
        showBackLink={true}
        right={
          <Box display='flex' justifyContent='end' alignItems='center' style={{gap: '1rem'}}>
            { saving && 
              <Box display='flex' justifyContent='end' alignItems='center' style={{gap: '1rem'}}>
                Saving <Loading width={24} height={24} />
              </Box>
          }
            { savingError && <span style={{fontWeight: 500, color: 'var(--primary)'}}>{savingError}</span> }
            <Button onClick={handleSaveProgramme} variant='contained' disabled={hasChanges() || saving}>Save Changes</Button>
          </Box>}
      />

      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(SaMaProgrammeDetailPage);

// EXPORT COMPONENT
export { hoc as SaMaProgrammeDetailPage };
