// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import styles from "./ListSaMCNewLeadsContentPage.module.scss";

// Actions
import { getAllTargetedContent } from "../../../state/actions/TargetedContentActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import ScrollableTabs from "../../../components/tabs/Tabs";
import Search from "../../../components/search/Search";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";
import {
  TargetedContentType,
  targetedContentTypeToIndex
} from "../../../state/constants/TargetedContentType";

// Component entry point
const ListSaMCNewLeadsContentPage = (props) => {
  const { targetedContent } = useSelector(
    (state) => state.TargetedContentReducer
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [currentContentType, setCurrentContentType] = useState(
    TargetedContentType.SAMC
  );

  useEffect(() => {
    setLoading(true);

    dispatch(
      getAllTargetedContent(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setupData();
  }, [targetedContent]);

  useEffect(() => {
    setupData();
  }, [currentContentType]);

  const setupData = () => {
    const filteredTargetedContent = targetedContent.filter((tc) =>
      currentContentType === "SaMC" ? tc.ContentType === "SaMC" : tc.ContentType === "New Leads"
    );
    const data = createData(filteredTargetedContent);

    setOriginalData(data);
    setSearchedData(data);
  };

  const createData = (content) => {
    return content.map((tc) => {
      return {
        id: tc.ContentId,
        name: tc.ContentTitle,
        description: tc.ContentDescription,
        contentType: tc.ContentType,
        category: tc.Category
      };
    });
  };

  const createHeaders = () => {
    return [
      {
        id: "name",
        clickable: true,
        action: {
          path: "/samc-newleads-upload/{id}",
          identifier: "id"
        },
        align: "left",
        label: "Content Name"
      },
      {
        id: "description",
        align: "left",
        label: "Description"
      },
      {
        id: "contentType",
        align: "left",
        label: "Content Type"
      }
    ];
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const onTabChange = (value) => {
    switch (value) {
      case targetedContentTypeToIndex(TargetedContentType.SAMC):
        setCurrentContentType(TargetedContentType.SAMC);
        break;
      case targetedContentTypeToIndex(TargetedContentType.NEWLEADS):
        setCurrentContentType(TargetedContentType.NEWLEADS);
        break;
      default:
        setCurrentContentType(TargetedContentType.SAMC);
    }
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      searchedData.length === 0 ? (
        <PagePlaceholder
          image={getIcon("frame-expand.svg")}
          text="No Content found."
        />
      ) : (
        <SimpleTable
          handleSelection={null}
          isSelectable={false}
          dataId={"targetedContentId"}
          cells={cells}
          headers={headers}
          rows={searchedData}
        />
      );
    return (
      <ScrollableTabs
        handleTabChange={(newValue) => onTabChange(newValue)}
        value={targetedContentTypeToIndex(currentContentType)}
        tabs={[
          {
            name: "SaMC",
            key: "SaMC",
            content: loading ? <Loading /> : data
          },
          {
            name: "New Leads",
            key: "New Leads",
            content: loading ? <Loading /> : data
          }
        ]}
      />
    );
  };

  return (
    <div>
      <HeaderBlock
        title={"Content"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <Search
                searchField={"name"}
                returnSearchMatches={(data) => {
                  setSearchedData(data);
                }}
                data={originalData}
                searchTitle="Search Content"
              />
            </Grid>
            <ExportExcel csvData={searchedData} fileName="content" />
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("samc-newleads-upload/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Add New
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ListSaMCNewLeadsContentPage);

// EXPORT COMPONENT
export { hoc as ListSaMCNewLeadsContentPage };
