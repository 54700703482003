import { cloneDeep } from "lodash";
import { AnyAction } from "redux";
import { GetPlannedDisplayDetails } from "../actions/MyDisplaysPlanningActions";

import {
  GET_PLANNED_DISPLAY_DETAILS,
  GET_PLANNED_DISPLAYS,
  UPSERT_DISPLAY_PLAN,
  UPSERT_DISPLAY_PLAN_SKU,
  REMOVE_DISPLAY_PLAN_SKU,
} from "../constants/action-types";

export interface SKU {
  MyDisplaysPlanningCustomersId?: string
  ActivityId?: number
  MaterialNumber : string
  Quantity: number
}

export interface PlannedDisplay {
  myDisplaysPlanning: {
    PlannedDisplayId?: string
    Site: number | undefined  // Replace with ENUM
    CustomerRule: string | null  // Replace with ENUM
    Value: string | null
    Description: string | null
    StartDate: string | null
    EndDate: string | null
    PlannedDisplayType: string | null  // Replace with ENUM
    DisplayType: string | null,
    DisplayTypeActivityId: number | null,
    AllowDisplayTypeChanges: boolean,
    LocationActivityId?: number | null // Populated by API
    LocationValue: string | null
    ProductActivityId?: number | null  // Populated by API
    CoopCost: number | null
    RecommendedPromotionalPrice: number | null
    CustomerConfirmed: boolean
    Notes: string
    LockedForUpdate: boolean
    IsActive: boolean
    IsRepCreated?: boolean
    CreatedBy?: string | null | undefined
    CreatedDate?: string | null | undefined
    ModifiedBy?: string | null | undefined
    ModifiedDate?: string | null | undefined
    Products: SKU[] | null | undefined
  },
  SKUs: Array<SKU>
}


export interface MyDisplaysActivity {
  ActivityId: number
  Name: string
  StartDate: string
  EndDate: string
}
export interface MyDisplaysActivities {
  rule: {
    Rule: string
    Value: string
    Site: number
  }
  activities: Array<MyDisplaysActivity>
}

export interface MyDisplaysPlanningReducerState {
  plannedDisplays: Array<PlannedDisplay>
  plannedDisplayDetails: null,
  activities: Array<MyDisplaysActivities>
}

export interface PlannedDisplayDetails {
  customer: Object
  SKUs: SKU[]
}


// INITIALIZE STATE
const initialState : MyDisplaysPlanningReducerState = {
  plannedDisplays: [],
  plannedDisplayDetails: null,
  activities: []
};

// REDUCER
export const MyDisplaysPlanningReducer = (state : MyDisplaysPlanningReducerState = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_PLANNED_DISPLAYS: {
      return {
        ...state,
        plannedDisplays: action.payload
      }
    }

    case GET_PLANNED_DISPLAY_DETAILS: {
      return {
        ...state,
        plannedDisplayDetails: action.payload
      }
    }

    case UPSERT_DISPLAY_PLAN: {
      const existingPlanIndex: number | undefined = state.plannedDisplays.findIndex(plan => plan.myDisplaysPlanning.PlannedDisplayId === action.payload.planId);
      const existingPlan: PlannedDisplay = state.plannedDisplays[existingPlanIndex];
      const plans = [...state.plannedDisplays];

      let products:SKU[] = [];
      if(action.payload.planData.Products && action.payload.planData.Products.length > 0) {
        products = [...action.payload.planData.Products];
      }

      let updatedPlan: PlannedDisplay = {
        myDisplaysPlanning: {
          ...action.payload.planData,
          PlannedDisplayId: action.payload.planId
        },
        // Copy the Products array to the SKUs to replicate what happens on the API
        SKUs: products
      };


      if(existingPlanIndex === -1) {
        // This is a new plan, we need to set CreatedBy to the current user's email (or name) for display purposes
        // otherwise it'll be the user ID we sent to the API
        updatedPlan.myDisplaysPlanning.CreatedBy = action.payload.loggedInUser.userName.split('\\')[1] || action.payload.loggedInUser.displayName;
      }

      if(existingPlanIndex !== -1) {
        // Filter out the existing plan and replace it with the updated one
        plans.splice(existingPlanIndex, 1, updatedPlan);
      } else {
        // Add our new one to the start of the array
        plans.unshift(updatedPlan);
      }
      
      return {
        ...state,
        plannedDisplays: plans
      }
    }
    default: return {
      ...state
    }
  }
};
