import { Box, Button, Grid, IconButton, TextField } from '@material-ui/core';
import { RemoveCircleOutline, Person } from '@material-ui/icons';
import styles from './programmeDetailList.module.scss';
import React from 'react';
import { ProgrammeAction, ProgrammeContact } from '../reducers/programme-reducer';



interface ContactsListProps {
  contacts: ProgrammeContact[];
  handleUpdateContacts: (action: ProgrammeAction) => void;
}

const contactTemplate: ProgrammeContact = {
  name: '',
  title: '',
  email: '',
}

const newContactReducer = (state: ProgrammeContact, action: {type: string; payload: any }) => {
  switch (action.type) {
    case 'HANDLE_TEXT_CHANGE':
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    case 'RESET_CONTACT':
      return {
        ...action.payload.value
      }
    default:
      return state;
  }
}


function ContactsList(props: ContactsListProps) {
  const {
    contacts,
    handleUpdateContacts,
  } = props;

  const [newContact, dispatch] = React.useReducer(newContactReducer, contactTemplate);


  function renderContacts(contacts: ProgrammeContact[]) {
    return (
      contacts.map((contact, index) => {
        return (
          <li key={index}>
            <Box display='flex' alignItems='center' style={{gap: '0.5rem'}}>
             <Person />
              <span className={styles.programmeDetailList_primary}>{contact.name}</span> – <span className={styles.programmeDetailList_secondary}>{contact.title}</span>
              <span className={styles.programmeDetailList_tertiary}>{contact.email}</span>
            </Box>
            <Box flex='0' ml='auto'>
              <IconButton onClick={(e) => handleUpdateContacts({type: 'REMOVE_CONTACT', payload: { index }})}>
                <RemoveCircleOutline fontSize='small' color="primary" />
              </IconButton>
            </Box>
          </li>
        )
      })
    );
  }

  return (
    <div>
      <ul className={styles.programmeDetailList}>
        {contacts.length ? renderContacts(contacts) : <li><p style={{color: 'var(--secondary-font)'}}>No contacts</p></li>}
      </ul>

      <div className={styles.programmeDetailList_extension}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField value={newContact.name} required size='small' label="Name" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value }}) } name='name' fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField value={newContact.title} required size='small' label="Title" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='title' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Box display='flex'>
              <Box flex='1'>
                <TextField value={newContact.email} required type='email' size='small' label="Email" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='email' fullWidth />
              </Box>
              <Box flex='0'>
                <Button variant="outlined" color="secondary"
                  disabled={!newContact.name || !newContact.title || !newContact.email}
                  onClick={(e) => {
                    handleUpdateContacts({type:'ADD_CONTACT', payload: { value: newContact}})
                    dispatch({type:'RESET_CONTACT', payload: { value: contactTemplate}})
                  }} style={{flex: 0, alignSelf: 'start', margin: '2px 0 0 0.75rem'}}>Add</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ContactsList;