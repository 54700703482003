// React
import React, { useState, useEffect } from "react";

// UI and Styling
import {
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField
} from "@material-ui/core";
import styles from "./BeaconItem.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Components
import DropdownSelect from "../../../components/dropdown-select/DropdownSelect";

// Other
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: "1px solid #333"
  }
}));

// COMPONENT
export default function BeaconItem(props) {
  const classes = useStyles();

  const [contentValues, setContentValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setContentValues(
      props.targetedContent.map((tc) => {
        return tc.value;
      })
    );

    const selected = props.targetedContent.find(
      (tc) => tc.key === props.data.stepContentId
    );

    setSelectedValue(selected !== undefined ? selected.value : "");
  }, []);

  return (
    <ListItem disableGutters={true} className={classes.listItem}>
      <ListItemText>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Autocomplete
              disabled={props.disabled}
              openOnFocus
              size="small"
              margin="dense"
              options={contentValues}
              value={selectedValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Targeted Content"
                  margin="normal"
                />
              )}
              onChange={(event) => {
                setSelectedValue(event.target.innerHTML);
                // Find the targeted content items guid by its value
                const selectedItem = props.targetedContent.find(
                  (tc) => tc.value === event.target.innerHTML
                );
                props.handleValueChange("StepContentId", selectedItem.key);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DropdownSelect
              disabled={props.disabled}
              label="Stop Number"
              handleChange={(value) => {
                props.handleValueChange("StopNumber", value);
              }}
              data={props.stops}
              value={props.data.stopNumber}
              valueName={"stopNumber"}
              error={false}
              fullWidth={true}
            ></DropdownSelect>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth={true}
                disabled={props.disabled}
                label="X Coordinate"
                onChange={(event) => {
                  props.handleValueChange("XCoordinate", event.target.value);
                }}
                type="number"
                margin="dense"
                value={props.data.xCoordinate}
                variant="outlined"
                error={false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                fullWidth={true}
                disabled={props.disabled}
                label="Y Coordinate"
                onChange={(event) => {
                  props.handleValueChange("YCoordinate", event.target.value);
                }}
                type="number"
                margin="dense"
                value={props.data.yCoordinate}
                variant="outlined"
                error={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </ListItemText>
      {props.toolbarShowing && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => props.handleDelete([props.data.id])}
            edge="end"
            aria-label="delete"
          >
            {getIcon("trash.svg", styles.icon)}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
