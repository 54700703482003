import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
  GET_ALL_TARGETED_CONTENT_FOR_POS,
  GET_3D_POS_ITEM_BY_ID,
  SET_CURRENT_POS_ITEM
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

/* 
    Get all 3D POS Targeted Content
*/
export const getAllTargetedContentForPos = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetAllTargetedContentForPos`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_ALL_TARGETED_CONTENT_FOR_POS,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Get an individual 3D POS item by its id
*/
export const get3DPOSItemById = (id, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetPicosStepsById?picosContentId=${id}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_3D_POS_ITEM_BY_ID,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
  Create a new 3D POS item
*/
export const upsert3DPosItem = (contentId, body, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };

      await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/UpsertPicosSteps?picosContentId=${contentId}`,
        {
          method: "put",
          data: body,
          headers: headers
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/* 
  Function only used to save the current selected item into global state.
  This is to ensure when a 3D POS item is selected from the list page it is available in the more details screen
*/
export const setCurrentPosItem = (item, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_CURRENT_POS_ITEM,
        payload: {
          data: item
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};
