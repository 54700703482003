// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  TextField
} from "@material-ui/core";
import styles from "./CategoriesListPage.module.scss";

// Actions
import { getAllApplications } from "../../../state/actions/CampaignActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  }
}));

const ApplicationsListPage = (props) => {
  const classes = useStyles();

  const { applications } = useSelector((state) => state.CampaignReducer);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);

  useEffect(() => {
    setLoading(true);

    dispatch(
      getAllApplications(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setupData();
  }, [applications]);

  const setupData = () => {
    const data = applications;

    setOriginalData(data);
    setSearchedData(data);
  };

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const foundData = originalData.filter((data) => {
      return (
        data.id.includes(text) ||
        data.typeId.includes(text) ||
        data.description.toLowerCase().includes(text)
      );
    });

    setSearchedData(foundData);
  };

  const createHeaders = () => {
    return [
      {
        id: "ApplicationName",
        clickable: true,
        action: {
          path: "/applications/{id}",
          identifier: "ApplicationId"
        },
        label: "Name",
        align: "left"
      },
      {
        id: "ApplicationId",
        align: "left",
        label: "Application Id"
      }
    ];
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      searchedData.length === 0 ? (
        <PagePlaceholder
          image={getIcon("frame-expand.svg")}
          text="No Applications found."
        />
      ) : (
        <SimpleTable
          handleSelection={null}
          isSelectable={false}
          dataId={"ApplicationId"}
          cells={cells}
          headers={headers}
          rows={searchedData}
        />
      );
    return data;
  };

  return (
    <div>
      <HeaderBlock
        title="Applications"
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item xs={5}>
              <TextField
                id="outlined-name"
                label="Search Applications"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <ExportExcel
                csvData={searchedData}
                fileName="Applications"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item xs>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("applications/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  New Application
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ApplicationsListPage);

// EXPORT COMPONENT
export { hoc as ApplicationsListPage };
