import {
  GET_ALL_TARGETED_CONTENT,
  GET_ALL_TARGETED_CONTENT_BY_TYPE,
  GET_TARGETED_CONTENT_BY_ID,
  GET_CONTENT_LOOKUP_DATA,
  GET_ALL_CATEGORIES,
  GET_CATEGORY_BY_ID,
  RESET_CURRENT_TARGETED_CONTENT,
  GET_ALL_APPLICATIONS,
  GET_APPLICATION_BY_ID
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
  targetedContent: [],
  targetedContentByType: [],
  currentTargetedContent: {},
  lookupData: {},
  categories: [],
  currentCategory: {},
};

export const createNewContent = (serverContent) => {
  let content = {};

  if (serverContent) {
    content.contentId = serverContent.contentId ? serverContent.contentId : "";
    content.file = serverContent.associatedResources
      ? serverContent.associatedResources
      : [];
    content.link =
      serverContent.associatedResources &&
        serverContent.associatedResources.length > 0
        ? serverContent.associatedResources[0].resourceUrl
        : "";
    content.title = serverContent.title ? serverContent.title : "";

    content.contentType = serverContent.typeId ? serverContent.typeId : "";

    content.internal = serverContent.isInternal;
    content.active = serverContent.isActive;
    content.description = serverContent.description
      ? serverContent.description
      : "";
    content.language = serverContent.associatedLanguages
      ? serverContent.associatedLanguages
      : [];

    content.territory = serverContent.territory ? serverContent.territory : "";
    content.startDate = serverContent.startDate
      ? new Date(serverContent.startDate + 'Z')
      : "";
    content.endDate = serverContent.endDate
      ? new Date(serverContent.endDate + 'Z')
      : "";
    content.contractExpiry = serverContent.contractExpiryDate
      ? new Date(serverContent.contractExpiryDate + 'Z')
      : null;

    content.retentionPolicy = serverContent.retentionPolicyId
      ? serverContent.retentionPolicyId
      : "";

    content.keywords = serverContent.keywords
      ? serverContent.keywords.join(",")
      : "";

    content.category = serverContent.categoryId ? serverContent.categoryId : "";
  } else {
    content = {
      id: "",
      file: [],
      link: "",
      title: "",
      contentType: "",
      internal: true,
      active: true,
      description: "",
      language: "",
      territory: "",
      startDate: new Date(),
      endDate: new Date(),
      contractExpiry: null,
      relatedIds: [],
      retentionPolicy: "",
      keywords: "",
      category: ""
    };
  }

  return content;
};

const createNewCategory = (serverCategory) => {
  let category = {};

  if (serverCategory) {
    category.description = serverCategory.Description;
    category.contentType = serverCategory.TypeId;
  } else {
    category = {
      description: "",
      typeId: ""
    };
  }

  return category;
};



// REDUCER
export const TargetedContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TARGETED_CONTENT: {
      const targetedContent = action.payload.data;

      return {
        ...state,
        targetedContent
      };
    }
    case GET_ALL_TARGETED_CONTENT_BY_TYPE: {
      const targetedContentByType = action.payload.data;

      return {
        ...state,
        targetedContentByType
      };
    }
    case GET_TARGETED_CONTENT_BY_ID: {
      const currentTargetedContent = createNewContent(action.payload.data);

      return {
        ...state,
        currentTargetedContent
      };
    }
    case GET_CONTENT_LOOKUP_DATA: {
      return {
        ...state,
        lookupData: action.payload.data
      };
    }

    case GET_ALL_CATEGORIES: {
      return {
        ...state,
        categories: action.payload.data
      };
    }
    case GET_CATEGORY_BY_ID: {
      return {
        ...state,
        currentCategory: createNewCategory(action.payload.data)
      };
    }
    case RESET_CURRENT_TARGETED_CONTENT: {
      return {
        ...state,
        currentTargetedContent: {}
      }
    }

    default:
      return state;
  }
};
