export const createImageSizeRequestBody = (typeId, imageSizeData) => {
  const smallImage = imageSizeData.find(
    (image) => image.typeId === typeId && image.size === "small"
  );
  const mediumImage = imageSizeData.find(
    (image) => image.typeId === typeId && image.size === "medium"
  );
  const largeImage = imageSizeData.find(
    (image) => image.typeId === typeId && image.size === "large"
  );
  const xlImage = imageSizeData.find(
    (image) => image.typeId === typeId && image.size === "xl"
  );

  return [
    {
      sizeName: "Small",
      width: smallImage ? smallImage.width : 120
    },
    {
      sizeName: "Medium",
      width: mediumImage ? mediumImage.width : 170
    },
    {
      sizeName: "Large",
      width: largeImage ? largeImage.width : 700
    },
    {
      sizeName: "ExtraLarge",
      width: xlImage ? xlImage.width : 1320
    }
  ];
};
