// React
import React, { Fragment, useState } from "react";

// UI and Styling
import { Box, FormHelperText, Grid, Icon, Typography } from "@material-ui/core";
import styles from "./FileDrop.module.scss"; // Import css modules stylesheet as styles

// Other
import CustomizedSnackbars from "../snackbar/Snackbar";

const FileDrop = (props) => {
  const [highlight, setHighlight] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [fileImages, setFileImages] = useState({});
  const [dragCounter, setDragCounter] = useState(0);
  const [csvSnackbarOpen, setCsvSnackbarOpen] = useState(false);

  const fileInputRef = React.createRef();

  const openFileDialog = () => {
    if (props.disabled) return;
    fileInputRef.current.click();
  };

  const handleFilesAdded = (filesList) => {
    if (props.disabled) return;
    if (filesList.length > 0 && props.onFilesAdded) {
      if (!props.multiple) {
        if (checkFile(filesList[0])) {
          props.onFilesAdded([filesList[0]]);
        } else {
          setSnackbarOpen(true);
        }
      } else {
        var localFileImages = Object.assign({}, fileImages);

        filesList.forEach((file) => {
          if (checkFile(filesList[0])) {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
              localFileImages[file.name] = event.target.result;
            };
            fileReader.readAsDataURL(file);

          } else {
            setSnackbarOpen(true);
            return;
          }
        });
        setFileImages(localFileImages);
        props.onFilesAdded(filesList);
      }
    } else {
      setSnackbarOpen(true);
    }
  };

  const onFilesAdded = (event) => {
    const files = event.currentTarget.files;
    handleFilesAdded(Object.values(files));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.disabled) return;
    setDragCounter(dragCounter + 1);
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setHighlight(true);
    }
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragCounter(dragCounter - 1);
    if (dragCounter === 0) {
      setHighlight(false);
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Object.values(event.dataTransfer.files);
    handleFilesAdded(files);
    setHighlight(false);
    event.dataTransfer.clearData();
    setDragCounter(0);
  };

  const checkFile = (file) => {
    if (file) {
      //Max file size = 3mb
      if (file.size > (props.maxsize ? props.maxsize : 3000000)) {
        setSnackbarOpen(true);
        return false;
      }

      if (props.enforceTypes.length > 0) {
        if (!props.enforceTypes.map((type) => type.mime).includes(file.type)) {
          setCsvSnackbarOpen(true);
          return false;
        }
      }

      return true;
    }

    return false;
  };

  return (
    <Fragment>
      <div
        className={
          styles.dropzone +
          " " +
          (highlight ? styles.highlight : "") +
          (props.errorMessage !== "" ? styles.error : "")
        }
        style={{ cursor: props.disabled ? "default" : "pointer" }}
      >
        <CustomizedSnackbars
          handleClose={() => {
            setSnackbarOpen(false);
          }}
          open={snackbarOpen}
          variant="error"
          message="File size too large. Maximum is 50MB"
        />

        <CustomizedSnackbars
          handleClose={() => {
            setCsvSnackbarOpen(false);
          }}
          open={csvSnackbarOpen}
          variant="error"
          message={props.enforceTypesMessage}
        />

        <div
          className={styles.innerZone}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          onClick={openFileDialog}
        >
          <input
            ref={fileInputRef}
            className={styles.fileInput}
            type="file"
            multiple={props.multiple}
            onChange={onFilesAdded}
          />
          {props.file === null || props.file.length === 0 ? (
            <Box className={styles.item}>
            <Icon color="primary">upload</Icon>
            <span className={styles.textStyle}>{props.message}</span>
          </Box>
          ) : (
              <Box className={styles.gridContent}>
                {props.multiple ? (
                  <Typography align="center" variant='h6' sx={{marginBottom: '1em'}}>{`${props.file.length} File${props.file.length === 1 ? '' : 's' } to be Uploaded`}</Typography>
                ) : null}
                <Grid container>
                  {props.file.map((file, index) => {
                    return (
                      <Grid item xs={12} key={file.value.name}>
                        <Box className={styles.item}>
                          { fileImages[file.name] ?
                            <img
                              alt={file.value.name}
                              className={styles.icon}
                              src={fileImages[file.name]}
                            /> : null
                          }
                          <Typography align="center" className={styles.textStyle}>
                            {file == null ? props.message : file.value.name}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
        </div>
      </div>
      {props.errorMessage !== "" ? (
        <FormHelperText error>{props.errorMessage}</FormHelperText>
      ) : null}
    </Fragment>
  );
};
export default FileDrop;
