import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { UUID } from "../../../../helpers/uuid";
import { getProductFilterRules } from "../../../../state/actions/MyDisplaysActions";
import DropdownSelect from "../../../../components/dropdown-select/DropdownSelect";
import { Autocomplete } from "@material-ui/lab";

export const ProductFilters = ({
  productFilterRules,
  isEditing,
  productFilters,
  setProductFilters,
  currentActivity,
  displayGroup,
  newRegexCheck,
  setNewRegexCheck,
  newCharAlert,
  setNewCharAlert,
}) => {
  const dispatch = useDispatch();

  const [ruleType, setRuleType] = useState("");
  const [ruleValue, setRuleValue] = useState("");
  const [ruleOperator, setRuleOperator] = useState("");

  const currentProductFilters = useSelector(
    (state) => state.MyDisplaysReducer.currentFilters
  );

  const regex = /^'[A-Z | a-z | \d_-]+'$/i;

  //data
  const operators = ["AND", "OR", "EXCLUDE"];

  useEffect(() => {
    if (displayGroup !== undefined && currentActivity !== undefined && currentActivity.Activity !== undefined) {
      dispatch(
        getProductFilterRules(currentActivity.Activity.Id, () => {
          
        })
      );
    }
  }, []);

  useEffect(() => {
    const localProductFilters = currentProductFilters
      ? currentProductFilters
      : [];

    setProductFilters(localProductFilters);
  }, [currentProductFilters]);

  //functions
  const passesRegex = (value) => {
    return regex.test(value);
  };

  const validateExistingRuleValue = (e, id) => {
    let existingRule = productFilters.find((item) => item.id === id);
    if (!e.target.value) {
      setNewRegexCheck(true);
      existingRule.regexError = true;
    } else {
      let ruleInput = e.target.value.split(",");
      existingRule.regexError = ruleInput.every(passesRegex);
    }

    if (e.target.value.length > 250) {
      existingRule.charError = true;
    } else if (e.target.value.length < 250) {
      existingRule.charError = false;
    }
  };

  const validateNewRuleValue = (e) => {
    if (!e.target.value) {
      setNewRegexCheck(true);
    } else {
      let ruleInput = e.target.value.split(",");
      setNewRegexCheck(ruleInput.every(passesRegex));
    }

    if (e.target.value.length > 250) {
      setNewCharAlert(true);
    } else if (e.target.value.length < 250 && newCharAlert) {
      setNewCharAlert(false);
    }
  };

  const handleNewRuleValueChange = (e) => {
    validateNewRuleValue(e);
    setRuleValue(e.target.value);
  };

  const handleExistingRuleValueChange = (e, id) => {
    let localData = [...productFilters];
    let existingRule = localData.find((item) => item.id === id);

    validateExistingRuleValue(e, existingRule.id);
    let ruleInput = e.target.value;

    existingRule.ruleValue = ruleInput;
    setProductFilters(localData);
  };

  const addProductFilter = () => {
    if (!ruleType || !ruleValue || !ruleOperator) {
      return;
    }
    let localProductFilters = productFilters;
    localProductFilters.push({
      id: UUID(),
      ruleType: ruleType,
      ruleValue: ruleValue,
      ruleOperator: ruleOperator,
      regexError: true,
      charError: false,
    });

    setProductFilters(localProductFilters);

    setRuleType("");
    setRuleValue("");
    setRuleOperator("");
  };

  const removeProductFilter = (id) => {
    let localProductFilters = [...productFilters];

    localProductFilters.splice(id, 1);
    setProductFilters(localProductFilters);
  };

  const isFormValid = () => {
    return ruleType === "" || ruleValue === "" || ruleOperator === "";
  };

  const newCharValidation = () => {
    if (newCharAlert) {
      return (
        <span style={{ color: "red" }}>
          Value must be less than 250 characters.
        </span>
      );
    }
  };

  const newRegexValidation = () => {
    if (!newRegexCheck) {
      return (
        <span style={{ color: "red" }}>
          Values must be surrounded by single quotes and be comma separated
        </span>
      );
    }
  };

  const productFilterList = () => {
    if (productFilters) {
      return productFilters.map((item, index) => (
        <Grid container spacing={2} key={item.id}>
          <Grid item xs={2}>
            <Grid item>
              <DropdownSelect
                disabled={!isEditing}
                label={"Rule Type"}
                handleChange={(value) => {
                  let localData = Object.assign({}, productFilters);

                  localData.rules[index].rule = value;

                  setProductFilters(localData);
                }}
                data={productFilterRules}
                value={item.ruleType}
                valueName={item.ruleType}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container direction="column">
              <Grid item>
                {/* VALUE */}
                <TextField
                  multiline={3}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => handleExistingRuleValueChange(e, item.id)}
                  value={item.ruleValue}
                  disabled={!isEditing}
                />
              </Grid>
              {item.charError ? (
                <span style={{ color: "red" }}>
                  Value must be less than 250 characters.
                </span>
              ) : null}
              {!item.regexError ? (
                <span style={{ color: "red" }}>
                  Values but be surrounded by single quotes and comma separated
                </span>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Grid item>
                <Autocomplete
                  disabled={!isEditing}
                  disablePortal
                  id="combo-box-demo"
                  options={operators}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  onChange={(e, value) => {
                    let localData = [...productFilters];
                    localData[index].operator = value;

                    setProductFilters(localData);
                  }}
                  value={item.ruleOperator}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container direction="column">
              <Grid item>
                <IconButton
                  disabled={!isEditing}
                  size="medium"
                  aria-label="delete"
                  color="primary"
                  onClick={() => removeProductFilter(index)}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ));
    }
  };

  return (
    <div
      style={{
        marginTop: "50px",
        marginBottom: "150px",
      }}
    >
      <div className="create-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Product Filters (Optional)</h2>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            style={{ textTransform: "capitalize", marginTop: "15px" }}
            onClick={addProductFilter}
            disabled={isFormValid()}
          >
            Add
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Grid item>
              <Typography>
                <strong>Rule Type</strong>
              </Typography>
            </Grid>
            <Divider />
          </Grid>
          <Grid item xs={7}>
            <Grid item>
              <Typography>
                <strong>
                  Value (Comma separated and values surrounded by ')
                </strong>
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item>
              <Typography>
                <strong>Operator</strong>
              </Typography>
            </Grid>
            <Divider />
          </Grid>
        </Grid>

        {productFilterList()}

        <Divider />

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Grid item>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={productFilterRules}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label="Rule Type"
                  />
                )}
                disabled={!isEditing}
                value={ruleType}
                onChange={(e, value) => {
                  setRuleType(value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container direction="column">
              <Grid item>
                {/* VALUE */}
                <TextField
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  label="Enter Values"
                  value={ruleValue}
                  onChange={handleNewRuleValueChange}
                  disabled={!isEditing}
                />
              </Grid>
              {newCharValidation()}
              {newRegexValidation()}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column">
              <Grid item>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={operators}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      label="Operators"
                    />
                  )}
                  disabled={!isEditing}
                  value={ruleOperator}
                  onChange={(e, value) => setRuleOperator(value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
