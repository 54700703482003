import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";

export const RewardsTable = ({
  currentActivity,
  displayGroupId,
  displayIndex,
  activityId,
}) => {
  const history = useHistory();

  const options = {
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    download: false,
    filterType: "multiselect",
    onRowClick: (a, b) => {
      //Retrieve the original object ID from the data list.
      let rewardRange = currentActivity.Rewards[b.dataIndex].UpperRange;
      history.push(
        `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}/reward/${rewardRange}`
      );
    },
    pagination: false,
    print: false,
    searchPlaceholder: "Search By Any Field",
    selectableRows: "none",
  };

  options.customToolbar = () => {
    return (
      <Fragment>
        <Tooltip title="Add New Reward">
          <IconButton
            onClick={() =>
              history.push(
                `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}/reward/new`
              )
            }
          >
            <Add />
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  };

  const columns = [
    {
      name: "UpperRange",
      label: "Upper Range",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "IsActive",
      label: "Active",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const isActiveElement =
            value === true ? (
              <span className="active-tag-text">Active</span>
            ) : (
              <span className="inactive-tag-text">Inactive</span>
            );
          return <>{isActiveElement}</>;
        },
      },
    },
  ];

  return (
    <>
      <MUIDataTable
        title={"Rewards"}
        data={currentActivity.Rewards}
        columns={columns}
        options={options}
      />
    </>
  );
};
