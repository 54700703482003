// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

// UI and Styling
import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import styles from "./ResourceImageUploaderPage.module.scss";

// Actions
import {
  createNewContent,
  getContentById,
  getSizeMetadata,
  updateContent
} from "../../../state/actions/ResourceImageUploaderActions";
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

// Models
import {
  GenericContentModel,
  createContentModelRequestBody
} from "../../../models/GenericContentModel";
import { createImageSizeRequestBody } from "../../../models/ImageSizeDataModel";
import { TargetModel } from "../../../models/TargetModel";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";

// Segments
import GenericContentUploadSegment from "../../../segments/content-upload-segments/GenericContentUploadSegment";
import CSVUploadSegment from "../../../segments/content-upload-segments/CSVUploadSegment";

// Other
import {
  validateField,
  compareDates,
  validateImageSize
} from "../../../helpers/validation";
import { UUID } from "../../../helpers/uuid";
import { FileType } from "../../../state/constants/FileType";

const ResourceImageUploaderPage = (props) => {
  const content = useSelector(
    (state) => state.ResourceImageUploaderReducer.content
  );
  const targetIds = useSelector(
    (state) => state.ResourceImageUploaderReducer.targetIds
  );
  const sizeData = useSelector(
    (state) => state.ResourceImageUploaderReducer.sizeData
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  const [genericContentData, setGenericContentData] = useState(
    GenericContentModel()
  );
  const [csvData, setCsvData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [segments, setSegments] = useState({
    genericContentSegment: "Main Details"
  });
  const [isNewContent, setIsNewContent] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [cancelAlertShowing, setCancelAlertShowing] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [isContentAdmin, setIsContentAdmin] = useState(false);
  const [imageSizeMetaData, setImageSizeMetadata] = useState([]);

  const resourceImageId = props.match.params.resourceImageId;

  const setupData = () => {
    dispatch(getSizeMetadata(() => { }));

    if (resourceImageId === "new") {
      setIsNewContent(true);
      setIsEditing(true);

      const localData = genericContentData;

      localData["startDate"] = new Date();
      localData["endDate"] = new Date();

      setGenericContentData(localData);
    } else {
      setIsNewContent(false);
      setLoadingContent(true);
      dispatch(
        getContentById(resourceImageId, (res) => {
          setLoadingContent(false);
        })
      );
    }
  };

  useEffect(() => {
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setupData();
        })
      );
    } else {
      setupData();
    }
  }, []);

  useEffect(() => {
    if (
      loggedInUserRoles.includes("Content Admin") ||
      loggedInUserRoles.includes("SaM Core Admin")
    ) {
      setIsContentAdmin(true);
    } else {
      setIsContentAdmin(false);
    }
  }, [loggedInUserRoles]);

  useEffect(() => {
    if (resourceImageId !== "new") {
      setGenericContentData(content);
    }

    if (content.contentType && content.contentType.value.type === "image") {
      setSegments({
        genericContentSegment: "Main Details",
        csvUploadSegment: "Target Id's"
      });
    }
    setDataChanged();
  }, [content]);

  useEffect(() => {
    setImageSizeMetadata(sizeData);
  }, [sizeData]);

  useEffect(() => {
    let localTargets = TargetModel();
    localTargets.targets.value =
      resourceImageId === "new" && !targetsEdited ? [] : targetIds;
    setCsvData(localTargets);
  }, [targetIds]);

  useEffect(() => {
    setSegments({
      genericContentSegment: "Main Details",
      csvUploadSegment: "Target Id's"
    });
  }, [csvData]);

  useEffect(() => {
    if (dataChanged) {
      if (genericContentData.contentType.value.type === "image") {
        setSegments({
          genericContentSegment: "Main Details",
          csvUploadSegment: "Target Id's"
        });
      }

      setDataChanged(false);
    }
  }, [dataChanged]);

  useEffect(() => {
    if (formEdited) {
      setSubmitEnabled(true);
    }
  }, [formEdited]);

  const validateData = () => {
    let localData = null;
    let setData = null;
    let errorCount = 0;

    Object.keys(segments).forEach((key) => {
      if (key === "csvUploadSegment") {
        localData = csvData;
        setData = setCsvData;
      } else {
        localData = genericContentData;
        setData = setGenericContentData;
      }

      Object.keys(localData).forEach((localDataKey) => {
        const data = localData[localDataKey];

        let errorMessage = "";
        if (localDataKey === "territory") {
          data.value = loggedInUser.territory;
        }

        if (localDataKey === "startDate") {
          errorMessage = validateField(
            data.required,
            data.value,
            data.type,
            data.label
          );

          if (errorMessage === "") {
            errorMessage = compareDates(
              data.value.toISOString(),
              localData["endDate"].value.toISOString(),
              data.label,
              localData["endDate"].label,
              false
            );
          }
        } else if (localDataKey === "endDate") {
          errorMessage = validateField(
            data.required,
            data.value,
            data.type,
            data.label
          );

          if (errorMessage === "") {
            errorMessage = compareDates(
              localData["startDate"].value.toISOString(),
              data.value.toISOString(),
              localData["startDate"].label,
              data.label,
              true
            );
          }
        } else {
          errorMessage = validateField(
            data.required,
            data.value,
            data.type,
            data.label
          );
        }

        if (errorMessage.length > 0) {
          errorCount++;
        }

        localData[localDataKey].errorMessage = errorMessage;
      });

      setData(localData);
      setDataChanged(true);
    });

    if (errorCount > 0) return false;

    return true;
  };

  const validateImage = async () => {
    if (genericContentData.contentTypeLabel.value !== "EquipmentImage") {
      const localData = genericContentData;

      const message = await validateImageSize(localData["file"].value, 700);

      localData["file"].errorMessage = message;

      setGenericContentData(localData);
      setDataChanged(true);

      if (message.length > 0) return false;
    }

    return true;
  };

  const createRequestBody = () => {
    const requestBody = {};
    requestBody["content"] = createContentModelRequestBody(genericContentData, loggedInUser.userName);

    requestBody["targets"] = csvData.targets.value;

    if (isNewContent) {
      requestBody["imageSizes"] = createImageSizeRequestBody(
        genericContentData.contentType.value,
        imageSizeMetaData
      );
    }

    requestBody["UploadedBy"] = loggedInUser.userName;
    requestBody["UploadedOn"] = new Date().toISOString();
    requestBody["ModifiedBy"] = loggedInUser.userName;
    requestBody["ModifiedOn"] = new Date().toISOString();

    return requestBody;
  };

  const cancelAlertDialog = () => {
    const title = isNewContent
      ? "Are you sure you want to go back?"
      : "Are you sure you want to stop editing?";
    return (
      <AlertDialog
        title={title}
        description={"Any changes made will be lost."}
        options={[
          {
            label: "Cancel",
            action: () => setCancelAlertShowing(false)
          },
          {
            label: "Confirm",
            action: () => handleCancelButtonClicked()
          }
        ]}
        isOpen={cancelAlertShowing}
      />
    );
  };

  // This refers to the cancel button within the alert dialog
  const handleCancelButtonClicked = () => {
    if (isNewContent) {
      props.history.replace(`/image-upload`);
    }

    setIsEditing(false);
    setCancelAlertShowing(false);
  };

  const handleEditButtonClicked = () => {
    setIsEditing(true);
  };

  // This refers to the cancel button on the edit or create form
  const handleCancelEditButtonClicked = () => {
    if (formEdited || targetsEdited) {
      setCancelAlertShowing(true);
    } else {
      setIsEditing(false);

      if (isNewContent) {
        props.history.push(`/image-upload`);
      }
    }
  };

  const handleSubmit = async () => {
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    const dataOk = validateData();
    if (isNewContent && dataOk) {
      const imageOk = await validateImage();

      if (imageOk) {
        const data = createRequestBody();

        // Force territory to be whatever the user is logged in as.
        data.territory = loggedInUser.territory;

        dispatch(
          createNewContent(
            data,
            genericContentData.file.value,
            genericContentData.contentTypeLabel.value,
            loggedInUser.territory, // Again, territory forcing.
            (res) => {
              if (res) {
                setGenericContentData(GenericContentModel());
                props.history.push("/image-upload");
              }
            }
          )
        );
      } else {
        setSubmitEnabled(true);
      }
    } else if (!isNewContent && dataOk) {
      const data = createRequestBody();

      dispatch(
        updateContent(resourceImageId, data, (res) => {
          if (res) {
            setIsEditing(false);
          }
        })
      );
    } else {
      setSubmitEnabled(true);
    }
  };

  const renderSegment = (pageName) => {
    switch (pageName) {
      case "genericContentSegment":
        return (
          <GenericContentUploadSegment
            genericContentData={
              isNewContent && !formEdited
                ? GenericContentModel()
                : genericContentData
            }
            setGenericContentData={setGenericContentData}
            setDataChanged={setDataChanged}
            title="Main Details"
            isNewContent={isNewContent}
            isEditing={isEditing}
            formEdited={formEdited}
            setFormEdited={setFormEdited}
            isContentAdmin={isContentAdmin}
            userTerritory={loggedInUser.territory}
            fileTypes={[FileType.jpeg, FileType.png]}
            dropdownScope="imagesonly"
          />
        );
      case "csvUploadSegment":
        return (
          <CSVUploadSegment
            csvData={csvData}
            setCsvData={setCsvData}
            setDataChanged={setDataChanged}
            title="Related Id's"
            targetsEdited={targetsEdited}
            setTargetsEdited={setTargetsEdited}
            isEditing={isEditing}
            setFormEdited={setFormEdited}
          />
        );
      default:
        return null;
    }
  };

  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        color="primary"
        onClick={() => handleCancelEditButtonClicked()}
      >
        Cancel
      </Button>,
      <Button
        key={UUID()}
        variant="outlined"
        color="secondary"
        onClick={() => handleSubmit()}
        disabled={!submitEnabled}
      >
        Submit
      </Button>
    ];
  };

  const renderEditButton = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleEditButtonClicked()}
      >
        Edit
      </Button>
    ];
  };

  const buttons =
    isEditing || isNewContent
      ? renderSubmitAndCancelButtons()
      : renderEditButton();

  const renderPageContent = () => {
    return Object.keys(segments).map((key) => {
      return (
        <Grid key={key} item xs={12} sm={12} md={6} lg={6} xl={6}>
          {renderSegment(key)}
        </Grid>
      );
    });
  };

  if (content == null) {
    return (
      <Box>
        <Typography>User model incorrect</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {loadingContent ? (
        <Loading />
      ) : (
          <Fragment>
            <HeaderBlock
              title={
                content.contentType !== undefined
                  ? content.contentType.name
                  : "New Image"
              }
              right={
                buttons ? (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      <ButtonGroup>{buttons}</ButtonGroup>
                    </Grid>
                  </Grid>
                ) : null
              }
            />

            <PageContainer>
              <div className={styles.contentGrid}>
                <Grid container spacing={3}>
                  {renderPageContent()}
                </Grid>
              </div>
              {cancelAlertDialog()}
            </PageContainer>
          </Fragment>
        )}
    </Box>
  );
};

const hoc = withRouter(ResourceImageUploaderPage);

// EXPORT COMPONENT
export { hoc as ResourceImageUploaderPage };
