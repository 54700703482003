// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import styles from "./ViewCategoryPage.module.scss";
import { ReactPictureAnnotation } from "react-picture-annotation";
import { createStyles, makeStyles  } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { IconButton } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded';

// Components
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";
import CustomCard from "../../../components/custom-card/CustomCard";

// Other
import { FormType } from "../../../state/constants/FormType";

import { adalApiFetch } from "../../../config/azureConfig";
import Axios from "axios";
import { getApiConfig } from "../../../config/apiConfig";
import ImageAnnotateSelect from "components/image-annotation/ImageAnnotateSelect";
import { UUID } from "helpers/uuid";

// Actions
import {
  getAllTargetedContent,
} from "../../../state/actions/TargetedContentActions";

const API_CONFIG = getApiConfig();

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1.2),
      margin: 'auto',
      width:222,
      maxWidth: 222,
    },
    pageNumber: {
      float: "right",
      lineHeight: "3.3",
      color:"#666",
      fontSize: 14,
      fontWeight:"bold",
    },
    dragLabel: {
      lineHeight: "3.3",
      color:"darkgray",
      fontSize: 16,
      fontWeight: "bold",
      marginRight: "50%",
      marginLeft:30
    },
    iconButton: {
      float: "right",
    },
    bar:{
      width: 950, 
      margin: "auto",
    },
    pagination:{
    },
    submitButton: {
      marginRight: 30,
    },
    imageUrl: {
      width: 900,
      height: 1260,
    },
    image:{ 
      width: 900, 
      height:1260, 
      margin: "auto", 
      boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
    },
    pageIcon: {
      border: "1px solid #ccc",
      borderRadius: "50%",
    }
  }),
);

const ViewAgreementSpecificationPage = (props) => {

  // Others
  const classes = useStyles();
  const dispatch = useDispatch();

  // Selectors
  const targetedContent = useSelector(
    (state) => state.TargetedContentReducer.targetedContent
  );

  //Setup State
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingSpecification, setIsExistingSpecification] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [agreementId, setAgreementId] = useState();
  const [debugJson, setDebugJson] = useState("");
  const [imageData, setImageData] = useState([]);
  const [typeData, setType] = useState(["label", "image"]);
  const [fieldsData, setFieldsData] = useState([]);
  const [imageJson, setImageJson] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [imageSize, setImageSize] = useState({});
  const [pageSize, setPageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Get the dimensions of the image from imageUrl
  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setPageSize({
          width: img.width,
          height: img.height,
        });
        setImageSize({ width: img.width, height: img.height});
      };
    }
  }, [imageUrl]);

  // Get image URL
  useEffect(() => {
    // Get all Target Content
    dispatch(getAllTargetedContent(() => {}));

    // Loop every pieces of the targetedContent and find the ContentDescription that matches the currentFile
    targetedContent.map((item) => {
      if (item.ContentDescription === currentFile) {
        let url = `${API_CONFIG.RETRIEVAL}/GetFile?ContentId=${item.ContentId}`;
        setImageUrl(url);
      }
    });
  },[currentFile])

  useEffect(() => {
    setLoading(true);
    const existing = props.type != null && props.type === FormType.VIEW;
    setIsExistingSpecification(existing);
    
    if (existing) {
      setAgreementId(props.match.params.AgreementId);
      const fetchData = async () => {
        const headers = { "Content-Type": "application/json" };
        const response = await adalApiFetch(
          Axios, `${API_CONFIG.SYNC}/GetAgreementSpecifications?Id=${props.match.params.AgreementId}`,
          {
            method: "get",
            headers: headers
          }
        );
        if(props.match.params.AgreementId === "itp") {
          response.data.pages.splice(1, 1);
        }
        setDebugJson(JSON.stringify(response.data, null, 4));
        setImageData(response.data);
        setLoading(false);
      }
      fetchData().catch(console.error);
    } else {
      setIsEditing(true);
      setLoading(false);
    }
  }, []);

  // Get keys fron JSON
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [imageData]);

  // Create a useEffect that will get all the info from the onChange function from the react-picture-annotation
  useEffect(() => {
    setCurrentFile(imageData.pages?.[currentPage]?.file)
    console.log("imageSize ->>>>", imageSize)

    if(imageData.pages?.length > 0) {
      imageData.pages[currentPage]?.attributes.map(data => {
        setFieldsData((prev) => [...prev, data.key])
        const annotateType = data.type;
        const keys = data.key;
        const mark = data.coordinates.map(coordinate => ({
          comment: keys,
          id: UUID(),
          // multiply the coordinate by 2 to get the annotation boxes aligned with the image
          mark: {
            x: coordinate.x,
            y: coordinate.y,
            width: coordinate.width,
            height: coordinate.height,
            type: annotateType,
          }
        }))
        setImageJson((prev) => [...prev, ...mark])
      })
    } else {
      console.log("Something goes wrong")
    }
  // clean up the imageJson everytime the currentPage changes
    return () => (
      setImageJson([]),
      setFieldsData([])
    )
  }, [imageData, currentPage, imageSize])

  // Handle image annotation resize
  const onResize = () => {
    setPageSize({ width: window.innerWidth, height: window.innerHeight });
  };

  // Handle image annotation select
  const onSelect = (id) => {
    setCurrentId(id)
  };

  // Handle image annotation change
  const onChange = (data) => {
    const newBox = data.map(item => {
      return {
        ...item,
        comment: item.comment ? item.comment : "",
        mark: {
          x: item.mark.x,
          y: item.mark.y,
          width: item.mark.width,
          height: item.mark.height,
          type: imageJson.find(annotate => annotate.id === item.id)?.mark.type || item.mark.type,
        }
      }      
    })
    setImageJson(newBox);
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = async () => {

    // Convert the imageJson into the format that the API expects
    let updateJson = imageJson.map(attribute => {
      return {
        key: attribute.comment,
        type: attribute.mark.type,
        // divide the coordinates by 2 to get the correct values and send to the API
        coordinates: [
          {
            x: attribute.mark.x,
            y: attribute.mark.y,
            width: attribute.mark.width,
            height: attribute.mark.height,
          }
        ]
      }
    })

    // New JSON that will be sent to the API
    const newJson = {
      programmeIdentifier: agreementId,
      pages: imageData.pages.map((page) => {
        return {
          file: page.file,
          index: page.index,
          attributes: page.file === currentFile ? updateJson : page.attributes
        }
      })
    }

    if (validateForm()) {
      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.SYNC}/UpdateAgreementSpecifications?Id=${agreementId}`,
        {
          method: "post",
          headers: headers,
          data: newJson
        }
      );
      if (isExistingSpecification) {
        window.location.reload();
      } else {
        props.history.push(`/agreementspecifications/${agreementId}`);
        window.location.reload();
      }
    }
  };

  // Create New Agreement Specification
    const createAgreement = async () => {
      if (validateForm()) {
        const headers = { "Content-Type": "application/json" };
        const response = await adalApiFetch(
          Axios, `${API_CONFIG.SYNC}/UpdateAgreementSpecifications?Id=${agreementId}`,
          {
            method: "post",
            headers: headers,
            data: debugJson
          }
        );
        if (isExistingSpecification) {
          window.location.reload();
        } else {
          props.history.push(`/agreementspecifications/${agreementId}`);
          window.location.reload();
        }
      }
    };


  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    setIsEditing(false);

    if (!isExistingSpecification) {
      props.history.push(`/agreementspecifications`);
    }
  };

  // Display Applcation data
  const renderData = () => {
    return (
      <Fragment>
        {imageUrl === null && imageJson.length !== 0 &&
         <Alert severity="error">
          The image is not available for this agreement -
          Please upload the image for agreement via File/Link Upload tab.
          </Alert>
        }
        { imageJson.length !== 0 ? 
        <div className={styles.contentGrid}>
          <Grid container className={classes.bar}>
            <Grid item className={classes.dragLabel}>
              Click and drag to highlight fields.
            </Grid>
            <Grid item className={classes.pagination}>
              <IconButton 
                className={classes.iconButton}
                disabled={currentPage >= imageData.pages?.length - 1}
                onClick={() => {
                    setCurrentPage(currentPage + 1)
                }}
              >
                <ChevronRightIcon
                  className={classes.pageIcon}
                  color={currentPage >= imageData.pages?.length - 1 ? "disabled" : "secondary"}
                />
              </IconButton>
              <Typography className={classes.pageNumber}>Page {currentPage + 1} of {imageData.pages?.length}</Typography>
                <IconButton 
                  className={classes.iconButton} 
                  disabled={currentPage < 1} 
                  onClick={() => {
                    setCurrentPage(currentPage - 1)
                  }}
                >
                <ChevronLeftRounded 
                  className={classes.pageIcon} 
                  color={currentPage <= 0 ? "disabled" : "secondary"}
                />
              </IconButton>
           </Grid>
          </Grid>
          <div className={classes.image}>
              <ReactPictureAnnotation
                // {...props}
                annotationData={imageJson}
                inputElement={(value, onChange, onDelete) => (
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <ImageAnnotateSelect
                        id={imageJson.find(item => item.id === currentId)?.id || ""}
                        label="Type"
                        fieldLable="Field Name"
                        typeValue={imageJson.find(item => item.id === currentId)?.mark.type || ""}
                        onChangeType={(event) => {
                          let copy = [...imageJson];
                          const currentBox = copy.find(item => item.id === currentId);
                          currentBox.mark.type  = event.target.value;
                          setImageJson(copy);
                        }}
                        fieldValue={value}
                        onChangeField={(event) => {
                          let copy = [...imageJson];
                          const currentBox = copy.find(item => item.id === currentId);
                          currentBox.comment = event.target.value;
                          setImageJson(copy);
                        }}
                        onDelete={onDelete}
                        size="small"
                        typeData={typeData}
                        fieldsData={fieldsData}
                      />
                    </Paper>
                  </div>
                )}
                scrollSpeed={0}
                // image={imageUrl}
                onSelect={onSelect}
                onChange={onChange} 
                width={900}
                height={1260}
              />
              <img 
                className={classes.imageUrl}
                unselectable={"on"} 
                draggable={"false"} 
                src={imageUrl}
              >
              </img>
          </div>
        </div> : 
        <div className={styles.contentGrid}>
          <CustomCard title="Create New Agreement" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  disabled={isExistingSpecification}
                  id={"Specification"}
                  name={"Agreement Specification Ientifier"}
                  label={"Agreement Specification Identifier"}
                  placeholder="placeholder"
                  value={
                    agreementId
                  }
                  onChange={(event) => {
                    setAgreementId(event.target.value)
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={16}
                  disabled={!isEditing}
                  id={"debugJson"}
                  name={"JSON"}
                  label={"JSON"}
                  placeholder="{}"
                  value={
                    debugJson
                  }
                  onChange={(event) => {
                    setDebugJson(event.target.value)
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>}
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <HeaderBlock
        title={"Agreement Specification"}
        right={
          <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
            <Grid item>
              {imageJson.length !== 0 ?
              <Button 
              className={classes.submitButton} 
              onClick={() => handleSubmitClicked()} 
              variant="outlined" 
              color="secondary"

              //  ? Temporarily removed this, not an accurate comparision. Needs a new way to check if the user has made any changes
               disabled={imageJson.length === imageData?.pages?.[currentPage].attributes.length && 
                imageJson.every((item, index) => item.comment === imageData?.pages?.[currentPage].attributes[index].key)}
              >
                Submit Changes
              </Button>
              :
              <Button color="secondary" onClick={() => createAgreement()} variant="outlined">Submit</Button>}
            </Grid>
          </Grid>
        }
      />

      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewAgreementSpecificationPage);

// EXPORT COMPONENT
export { hoc as ViewAgreementSpecificationPage };
