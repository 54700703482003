// IMPORT ACTIONS
import { VERSION_UPDATE } from "./../constants/action-types";

const initialState = {
  version: -1,
};

export const VersionReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERSION_UPDATE: {
      const newVersion = action.payload;

      return {
        ...state,
        version: newVersion,
      };
    }
    default:
      return state;
  }
};
