// React
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import styles from "./ListCampaignsPage.module.scss";

// Actions
import { getCampaigns } from "../../../state/actions/CampaignActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import { OutlinedIconButton } from "../../../components/buttons/OutlinedIconButton";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  }
}));

const ListCampaignsPage = (props) => {
  const classes = useStyles();

  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );
  const { campaigns } = useSelector((state) => state.CampaignReducer);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [filters, setFilters] = useState({ hidden: true });
  const [filtersUpdated, setFiltersUpdated] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    setLoading(true);

    dispatch(
      getCampaigns(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = createData(campaigns);

    setOriginalData(data);
    setSearchedData(data);
    search(""); 
    setLoading(false);
  }, [campaigns]);

  useEffect(() => {
    setFiltersUpdated(false);
    search(searchText);
  }, [filters, filtersUpdated]);

  const createData = (campaigns) => {
    const campaignKeys = Object.keys(campaigns);

    return campaignKeys.map((campaignKey) => {
      const campaign = campaigns[campaignKey];

      return {
        name: campaign.Name,
        id: campaignKey,
        description: campaign.Description.substring(0, 50),
        startDate: campaign.StartDate,
        endDate: campaign.EndDate,
        hidden: campaign.Hidden
      };
    });
  };

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const searchableData = !filters["hidden"]
      ? originalData
      : originalData.filter((data) => data.hidden === null || !data.hidden);

    const foundData = searchableData.filter((content) => {
      return (
        content.name.toLowerCase().includes(text) ||
        content.description.toLowerCase().includes(text) ||
        content.startDate.includes(text) ||
        content.endDate.includes(text)
      );
    });

    setSearchedData(foundData);
  };

  // The headers of the table
  const createHeaders = () => {
    return [
      {
        id: "name",
        clickable: true,
        action: { path: "/campaigns/{id}", identifier: "id" },
        align: "left",
        label: "Name"
      },
      {
        id: "description",
        align: "left",
        label: "Description"
      },
      {
        id: "startDate",
        align: "left",
        label: "Start Date"
      },
      {
        id: "endDate",
        align: "left",
        label: "End Date"
      }
    ];
  };

  // Create the cells for the table and configure them.
  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const handleChange = (event) => {
    let localFilters = filters;

    if (filters[event.target.name]) {
      localFilters[event.target.name] = !localFilters[event.target.name];
    } else {
      localFilters[event.target.name] = true;
    }

    setFilters(localFilters);
    setFiltersUpdated(true);
  };

  const displayFilters = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="flex-end"
        style={{
          display: showFilters ? "inline-flex" : "none",
          // transition:
          padding: 16
        }}
      >
        <Typography align="right">Filter: </Typography>
        <FormGroup row>
          <FormControlLabel
            labelPlacement="start"
            key="hidden-cb"
            label="Hide Mass-Uploaded"
            control={
              <Checkbox
                checked={filters["hidden"] || false}
                onChange={handleChange}
                name="hidden"
                color="primary"
              />
            }
          />
        </FormGroup>
      </Grid>
    );
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      searchedData.length === 0 ? (
        <Fragment>
          {displayFilters()}
          <PagePlaceholder
            image={getIcon("licencse.svg")}
            text="No Campaigns found."
          />
        </Fragment>
      ) : (
          <Fragment>
            {displayFilters()}
            <SimpleTable
              handleSelection={null}
              isSelectable={false}
              onClick={() => { }}
              dataId={"campaignId"}
              cells={cells}
              headers={headers}
              rows={searchedData}
            />
          </Fragment>
        );

    return data;
  };

  return (
    <div>
      <HeaderBlock
        title={"Campaigns"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                id="outlined-name"
                label="Search Campaigns"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            {loggedInUserRoles.includes("SaM Core Admin") ? (
              <Grid item>
                <OutlinedIconButton
                  clickHandler={() => setShowFilters(!showFilters)}
                  icon="funnel.svg"
                />
              </Grid>
            ) : null}
            <Grid item>
              <ExportExcel
                csvData={searchedData}
                fileName="campaigns"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("campaigns/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Add New
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ListCampaignsPage);

// EXPORT COMPONENT
export { hoc as ListCampaignsPage };
