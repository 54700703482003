// IMPORT PACKAGE REFERENCES
import { AnyAction, combineReducers } from "redux";

// IMPORT REDUCERS
import { ResourceImageUploaderReducer } from "./ResourceImageUploaderReducer";
import { ResourceImageManagerReducer } from "./ResourceImageManagerReducer";
import { MyccaResourceImageManagerReducer } from "./MyCCAResourceImageManagerReducer";
import { RoleReducer } from "./RoleReducer";
import { UserReducer } from "./UserReducer";
import { VersionReducer } from "./VersionReducer";
import { CampaignReducer } from "./CampaignReducer";
import { TargetGroupReducer } from "./TargetGroupReducer";
import { TargetedContentReducer } from "./TargetedContentReducer";
import { ThreeDimensionalPOSReducer } from "./ThreeDimensionalPOSReducer";
import { MigrationReducer } from "./MigrationReducer";
import { ActivityReducer } from "./ActivityReducer";
import { SFEActivationReducer } from "./SFEActivationReducer";
import { CoreRangeReducer } from "./CoreRangeReducer";
import { MyDisplaysReducer } from "./MyDisplaysReducer";
import { MyDisplaysPlanningReducer } from "./MyDisplaysPlanningReducer";
import { ConfigurationReducer } from "./ConfigurationReducer"

import { VERSION_UPDATE } from "../constants/action-types";

const AppReducer = combineReducers({
  VersionReducer,
  UserReducer,
  RoleReducer,
  ResourceImageUploaderReducer,
  ResourceImageManagerReducer,
  CampaignReducer,
  TargetGroupReducer,
  TargetedContentReducer,
  ThreeDimensionalPOSReducer,
  MigrationReducer,
  ActivityReducer,
  SFEActivationReducer,
  MyccaResourceImageManagerReducer,
  CoreRangeReducer,
  MyDisplaysReducer,
  MyDisplaysPlanningReducer,
  ConfigurationReducer
});

export const RootReducer = (state: any, action: AnyAction) => {
  if (action.type === VERSION_UPDATE) {
    state = {};
  }

  return AppReducer(state, action);
};

export type RootState = ReturnType<typeof RootReducer>
