// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { getApiConfig } from "../../../config/apiConfig";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./TargetedContentPage.module.scss";

// Actions
import {
  createTargetedContent,
  createTargetedContentForLink,
  getTargetedContentById,
  getAllLookupContent,
  resetLocalTargetedContent,
  updateTargetedContent
} from "../../../state/actions/TargetedContentActions";
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";
import { createNewContent } from "../../../state/reducers/TargetedContentReducer";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import CustomCard from "../../../components/custom-card/CustomCard";
import DropdownSelect from "../../../components/dropdown-select/DropdownSelect";
import FileDrop from "../../../components/file-drop/FileDrop";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Models
import { TargetedContentModel } from "../../../models/TargetedContentModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { FormType } from "../../../state/constants/FormType";
import { compareDates, validateField } from "../../../helpers/validation";
import { isValid, format, startOfDay } from "date-fns";
import { sortDropdown } from "../../../helpers/sortDropdown";
import { Territories, GetSite } from "../../../state/constants/Territories";

import moment from 'moment';
moment().format();

const API_CONFIG = getApiConfig();

/*
    Component entry point
*/
const TargetedContentPage = (props) => {
  // Reducer logic
  const currentTargetedContent = useSelector(
    (state) => state.TargetedContentReducer.currentTargetedContent
  );
  const lookupData = useSelector(
    (state) => state.TargetedContentReducer.lookupData
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  // State Logic
  const [loading, setLoading] = useState(false);
  const [targetedContent, setTargetedContent] = useState({});
  const [uneditedTargetedContent, setUneditedTargetedContent] = useState({});
  const [
    cancelTargetedContentEditingAlertOpen,
    setCancelTargetedContentEditingAlertOpen
  ] = useState(false);
  const [isEditing, setIsEditing] = useState(
    props.type != null && props.type !== FormType.VIEW
  );
  const [formProperties, setFormProperties] = useState(TargetedContentModel);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [formDataUpdated, setFormDataUpdated] = useState(false);
  const [isNewContent, setIsNewContent] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [categoryLookupData, setCategoryLookupData] = useState([]);
  const [languageLookupData, setLanguageLookupData] = useState([]);
  const [retentionPolicyLookupData, setRetentionPolicyLookupData] = useState(
    []
  );
  const [typeLookupData, setTypeLookupData] = useState([]);
  const [selectedContentTypeId, setSelectedContentTypeId] = useState("");
  const [ignoreMaxFileSize, setIgnoreMaxFileSize] = useState(false);
  const [isContentAdmin, setIsContentAdmin] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(20 * 1024);
  const [
    getAllLookupContentCallMade,
    setGetAllLookupContentCallMade
  ] = useState(false);
  const [getTargetedContentCallMade, setGetTargetedContentCallMade] = useState(
    false
  );
  const [copySuccessOpen, setCopySuccessOpen] = React.useState(false);

  
  const targetedContentId = props.match.params.targetContentId;

  useEffect(() => {
    setLoading(true);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }

    //Get all dropdown lookup data
    dispatch(getAllLookupContent("targetedonly", () => { }));

    //If not creating
    if (props.type != null && props.type === FormType.VIEW) {
      setIsEditing(false);
      setIsNewContent(false);

      dispatch(
        getTargetedContentById(targetedContentId, () => {
          setGetTargetedContentCallMade(true);
        })
      );
    } else {
      setIsEditing(true);
      setIsNewContent(true);

      dispatch(
        getTargetedContentById(undefined, () => {
          setGetTargetedContentCallMade(true);
        })
      );
    }
    return () => {
      dispatch(resetLocalTargetedContent(() => { }));
    };
  }, []);

  useEffect(() => {
    if (
      loggedInUserRoles !== undefined &&
      (loggedInUserRoles.includes("Content Admin") ||
        loggedInUserRoles.includes("SaM Core Admin") ||
        loggedInUserRoles.includes("Content Manager") ||
        loggedInUserRoles.includes("Content Targeting Manager"))
    ) {
      setIsContentAdmin(true);
    } else {
      setIsContentAdmin(false);
    }
  }, [loggedInUserRoles]);

  useEffect(() => {
    if (loggedInUser != null) {
      let localData = targetedContent;
      if (Object.keys(localData).length === 0) { localData = createNewContent(); }
      localData["territory"] = loggedInUser.territory;
      setTargetedContent(localData);
    }
  }, [loggedInUser]);

  useEffect(() => {
    const localData = currentTargetedContent;
    if (isNewContent) {
      localData.territory = loggedInUser.territory;
    }
    setTargetedContent(localData);
    // Need to set the link text if the content type is Links
    if (
      targetedContent.contentType &&
      typeLookupData.find((type) => type.Id === targetedContent.contentType)
        .DisplayName === "Links"
    ) {
      localData["link"] =
        !isNewContent && currentTargetedContent.file !== null
          ? currentTargetedContent.file[0].resourceUrl
          : "";
    }
    setTargetedContent(localData);

    if (!isNewContent) {
      setSelectedContentTypeId(currentTargetedContent.contentType);
    }
  }, [currentTargetedContent]);

  useEffect(() => {
    setDataChanged(!dataChanged);
  }, [targetedContent]);

  useEffect(() => {
    if (isEditing) {
      setSubmitEnabled(true);
    }
  }, [isEditing]);

  useEffect(() => {
    setCategoryLookupData(
      lookupData.CategoryLookups !== undefined
        ? lookupData.CategoryLookups.sort()
        : []
    );
    setLanguageLookupData(lookupData.LanguageLookups);
    setRetentionPolicyLookupData(lookupData.RetentionPolicyLookups);
    setTypeLookupData(
      lookupData.TypeLookups !== undefined ? lookupData.TypeLookups.sort() : []
    );
  }, [lookupData]);

  useEffect(() => {
    if (
      categoryLookupData.length > 0 &&
      languageLookupData.length > 0 &&
      retentionPolicyLookupData.length > 0 &&
      typeLookupData.length > 0
    ) {
      if (props.type != null && props.type === FormType.CREATE) {
        let localData = targetedContent;
        if (Object.keys(localData).length === 0) { localData = createNewContent(); }
        localData["territory"] = loggedInUser.territory;
        setTargetedContent(localData);
        const lang = languageLookupData.find(
          (data) => data.DisplayName === "en"
        );

        if (lang !== undefined) {
          localData["language"] = [lang.Id];
          localData["territory"] = loggedInUser.territory;
          if (!formEdited) {
            setFormEdited(true);
          }
          setTargetedContent(localData);

          // Forces a rerender to display updated inout data
          setDataChanged(!dataChanged);
        }
      }

      setGetAllLookupContentCallMade(true);
    }
  }, [
    categoryLookupData,
    languageLookupData,
    retentionPolicyLookupData,
    typeLookupData
  ]);

  useEffect(() => {
    if (
      loggedInUserCallMade &&
      getAllLookupContentCallMade &&
      getTargetedContentCallMade
    ) {
      setLoading(false);
    }
  }, [
    loggedInUserCallMade,
    getAllLookupContentCallMade,
    getTargetedContentCallMade
  ]);


  const handleCopySamContentUrl = () => {
    const clipboard = navigator.clipboard;
    if(!targetedContent.description || !clipboard) return;
    const encodedDescription = encodeURI(targetedContent.description);
    const url = `samcorecontent://description/${encodedDescription}`;

    clipboard.writeText(url).then(()=>{
      setCopySuccessOpen(true);
    });
  }

  // Whenever a change to any of the form fields happens this method is called to update the state of the targetedContent
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    const localData = targetedContent;
    localData[name] = value;

    if (!formEdited) {
      setFormEdited(true);
    }
    setTargetedContent(localData);

    // Forces a rerender to display updated inout data
    setDataChanged(!dataChanged);
  };

  // Validate the field data then update the localFormData with any new error messages
  const checkFieldIsValid = (required, value, type, fieldName, fieldLabel) => {
    let localFormProperties = formProperties;

    const errorMessage = validateField(required, value, type, fieldLabel);

    localFormProperties[fieldName].errorMessage = errorMessage;

    setFormProperties(localFormProperties);

    return errorMessage;
  };

  // Validate the form using the fields from TargetedContentModel
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      if (key === "startDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);
        if (errorMessage === "") {
          if (
            isValid(targetedContent["startDate"]) &&
            isValid(targetedContent["endDate"])
          ) {
            errorMessage = compareDates(
              targetedContent[key].toISOString(),
              targetedContent["endDate"].toISOString(),
              formProperties[key].label,
              formProperties["endDate"].label,
              false
            );
          }
        }
      } else if (key === "endDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);

        if (errorMessage === "") {
          if (
            isValid(targetedContent["startDate"]) &&
            isValid(targetedContent["endDate"])
          ) {
            errorMessage = compareDates(
              targetedContent["startDate"].toISOString(),
              targetedContent[key].toISOString(),
              formProperties["startDate"].label,
              formProperties[key].label,
              true
            );
          }
        }
      } else if (key === "link") {
        if (
          targetedContent.contentType &&
          typeLookupData.find((type) => type.Id === targetedContent.contentType)
            .DisplayName === "Links"
        ) {
          errorMessage = checkGenericField(key, true);
        }
      } else if (key === "file") {
        if (
          !targetedContent.contentType ||
          typeLookupData.find((type) => type.Id === targetedContent.contentType)
            .DisplayName !== "Links"
        ) {
          errorMessage = checkGenericField(key, true);
        }
      } else {
        errorMessage = checkGenericField(key, formProperties[key].required);
      }

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    setFormProperties(localProperties);
    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setFormDataUpdated(!formDataUpdated);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  const checkGenericField = (key, required) => {
    return checkFieldIsValid(
      required,
      targetedContent[key],
      formProperties[key].type,
      key,
      formProperties[key].label
    );
  };

  /*
      Format the data into a form that the endpoints expect
  */
  const createRequestBody = () => {
    const requestBody = {
      targetedContent: {
        typeId: targetedContent.contentType,
        isInternal:
          targetedContent.internal !== undefined
            ? targetedContent.internal
            : true,
        isActive:
          targetedContent.active !== undefined ? targetedContent.active : true,
        title: targetedContent.title,
        description: targetedContent.description,
        startdate: new Date(targetedContent["startDate"]).toISOString(),
        enddate: new Date(targetedContent["endDate"]).toISOString(),
        contractExpiryDate: targetedContent["contractExpiry"] == null ? null : targetedContent["contractExpiry"].toISOString(),
        territory: targetedContent.territory,
        languages: targetedContent.language,
        retentionPolicyId: targetedContent.retentionPolicy,
        keywords: targetedContent.keywords
          .split("|")
          .join(",")
          .split(",")
          .map(Function.prototype.call, String.prototype.trim),
        categoryId: targetedContent.category
      },
      UploadedBy: loggedInUser.userName,
      ModifiedBy: loggedInUser.userName,
      UploadedOn: moment().toISOString(),
      ModifiedOn: moment().toISOString()
    };

    if (
      targetedContent.contentType &&
      typeLookupData.find((type) => type.Id === targetedContent.contentType)
        .DisplayName === "Links"
    ) {
      requestBody["TargetedMediaUrl"] = targetedContent.link;
    }

    return requestBody;
  };

  /*
    When the submit button is clicked on either the create or update form.
    The create param is optional
  */
  const submitTargetedContent = (create = true) => {
    //Ensure the data being submitted is valid before submitting
    if (validateForm()) {
      const requestBody = createRequestBody();
      if (create) {
        // Create the targeted content item then redirect user to the target content list page
        // If the content type is Links then no need to upload any files
        if (
          targetedContent.contentType &&
          typeLookupData.find((type) => type.Id === targetedContent.contentType)
            .DisplayName === "Links"
        ) {
          dispatch(
            createTargetedContentForLink(requestBody, (success) => {
              if (success) {
                props.history.push("/content-upload");
              } else {
                setSubmitEnabled(true);
                setFormEdited(true);
              }
            })
          );
        } else {
          dispatch(
            createTargetedContent(
              targetedContent.file[0],
              requestBody.territory,
              requestBody,
              (success) => {
                if (success) {
                  props.history.push("/content-upload");
                } else {
                  setSubmitEnabled(true);
                  setFormEdited(true);
                }
              }
            )
          );
        }
      } else {
        if (
          targetedContent.contentType &&
          typeLookupData.find((type) => type.Id === targetedContent.contentType)
            .DisplayName === "Links"
        ) {
          requestBody.targetedContent["linkContentUrl"] =
            requestBody["TargetedMediaUrl"];
        }
        dispatch(
          updateTargetedContent(targetedContentId, requestBody, (success) => {
            if (success) {
              setIsEditing(false);
            } else {
              setIsEditing(true);
              setSubmitEnabled(true);
            }
          })
        );
      }
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (dataChanged) {
      setCancelTargetedContentEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (isNewContent) {
        props.history.push(`/content-upload`);
      }
    }
  };

  /*
    Display the cancel editing alert dialog
  */
  const cancelTargetedContentEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelTargetedContentEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setCancelTargetedContentEditingAlertOpen(false);

              if (isNewContent) {
                props.history.push(`/content-upload`);
              } else {
                setTargetedContent(uneditedTargetedContent);
              }
            }
          }
        ]}
        isOpen={cancelTargetedContentEditingAlertOpen}
      />
    );
  };

  /*
    Display all the page data
  */
  const renderData = () => {
    if (!targetedContent) {
      return <div />;
    }
    let isLinkContentType = false;

    if (Object.keys(targetedContent).length > 0) {
      const foundItem =
        targetedContent.contentType !== undefined &&
          typeLookupData !== undefined
          ? typeLookupData.find(
            (type) => type.Id === targetedContent.contentType
          )
          : undefined;

      if (foundItem != null && foundItem?.SizeLimit == null) { foundItem.SizeLimit = 20 * 1024 }
      if (foundItem != null && foundItem?.SizeLimit != sizeLimit) {
        foundItem.SizeLimit != null ?
          setSizeLimit(foundItem.SizeLimit) :
          setSizeLimit(9999);
      }

      isLinkContentType =
        targetedContent.contentType &&
        foundItem !== undefined &&
        foundItem.DisplayName === "Links";
    }

    let link = "";

    if (isLinkContentType) {
      if (targetedContent.link !== undefined) {
        link = targetedContent.link;
      } else if (
        targetedContent.file &&
        targetedContent.file[0].resourceUrl !== undefined
      ) {
        link = targetedContent.file[0].resourceUrl;
      } else {
        link = "";
      }
    }

    const downloadContent = () => {
      let url = `${API_CONFIG.RETRIEVAL}/GetFile?ContentId=${targetedContent.contentId}`;
      window.open(url, "_blank");
    };

    const formatContractExpiryDateLabel = (date, invalidLabel) => {
      const dateIsValid = targetedContent.contractExpiry != null && isValid(targetedContent.contractExpiry)
      return dateIsValid
        ? `${format(targetedContent.contractExpiry, "dd/MM/yy")}`
        : ''
    };

    return (
      <div className={styles.contentGrid}>
        <CustomCard
          title="Main Details"
          actionButton={[
            <Button
              key={"AAAAAAAVAAAAAAAAAAAA"}
              onClick={() => downloadContent()}
              variant="outlined"
              color="secondary"
            >
              Download Content
            </Button>
          ]}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                disabled={!isEditing}
                labelPlacement="start"
                label="Internal"
                control={
                  <Checkbox
                    checked={
                      targetedContent.internal !== undefined
                        ? targetedContent.internal
                        : true
                    }
                    onChange={() =>
                      handleInputChange("internal", !targetedContent.internal)
                    }
                    name="internal"
                    color="primary"
                  />
                }
              />
              <FormControlLabel
                disabled={!isEditing}
                labelPlacement="start"
                label="Active"
                control={
                  <Checkbox
                    checked={
                      targetedContent.active !== undefined
                        ? targetedContent.active
                        : true
                    }
                    onChange={() =>
                      handleInputChange("active", !targetedContent.active)
                    }
                    name="active"
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownSelect
                disabled={!isNewContent}
                label="Content Type"
                handleChange={(value) => {
                  setSelectedContentTypeId(value);
                  handleInputChange("contentType", value);

                  //Check if changed to 3D Pos. If it is 3D POS then the file upload max size is disregarded in the file drop
                  const typeData = typeLookupData.find((type) => {
                    return type.Id === value;
                  });

                  // Need to set up default retention policy based on content type selected
                  handleInputChange(
                    "retentionPolicy",
                    typeData.DefaultRetentionPolicyId
                  );
                }}
                data={
                  typeLookupData !== undefined && typeLookupData.length > 0
                    ? typeLookupData.sort(sortDropdown).map((t) => {
                      return { key: t.Id, value: t.DisplayName };
                    })
                    : []
                }
                value={
                  typeLookupData !== undefined && typeLookupData.length > 0
                    ? targetedContent.contentType
                    : ""
                }
                valueName={"contentType"}
                error={formProperties.contentType.errorMessage !== ""}
                fullWidth={true}
              >
                {formProperties.contentType.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.contentType.errorMessage}
                  </FormHelperText>
                ) : null}
              </DropdownSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownSelect
                disabled={!isNewContent}
                label="Category"
                handleChange={(value) => {
                  handleInputChange("category", value);
                }}
                data={
                  selectedContentTypeId !== ""
                    ? categoryLookupData !== undefined &&
                      categoryLookupData.length > 0
                      ? categoryLookupData
                        .filter((t) => t.TypeId === selectedContentTypeId)
                        .sort(sortDropdown)
                        .map((t) => {
                          return { key: t.Id, value: t.DisplayName };
                        })
                      : []
                    : []
                }
                value={
                  categoryLookupData !== undefined &&
                    categoryLookupData.length > 0
                    ? targetedContent.category
                    : ""
                }
                valueName={"category"}
                error={formProperties.category.errorMessage !== ""}
                fullWidth={true}
              >
                {formProperties.category.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.category.errorMessage}
                  </FormHelperText>
                ) : null}
              </DropdownSelect>
            </Grid>
            <Grid
              item
              xs={isNewContent ? 12 : 11}
              style={{
                display: isNewContent || isLinkContentType ? "block" : "none"
              }}
            >
              {targetedContent.contentType &&
                typeLookupData !== undefined &&
                typeLookupData.find(
                  (type) => type.Id === targetedContent.contentType
                ).DisplayName === "Links" ? (
                  <TextField
                    disabled={!isEditing}
                    id="link"
                    name="link"
                    label="Link"
                    placeholder="Enter link"
                    value={link}
                    onChange={(event) =>
                      handleInputChange("link", event.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={formProperties.link.errorMessage !== ""}
                    helperText={formProperties.link.errorMessage}
                  />
                ) : (
                  <FileDrop
                    enforceTypes={[]}
                    enforceTypesMessage={""}
                    message="Add files"
                    onFilesAdded={(file) => handleInputChange("file", file)}
                    file={targetedContent.file ? targetedContent.file : null}
                    multiple={false}
                    errorMessage={formProperties.file.errorMessage}
                    ignoreMaxFileSize={ignoreMaxFileSize}
                    maxFileSize={sizeLimit}
                  />
                )}
            </Grid>
            {!isNewContent &&
              targetedContent.contentType &&
              typeLookupData.find(
                (type) => type.Id === targetedContent.contentType
              ).DisplayName === "Links" ? (
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    style={{ marginTop: "10px" }}
                    color="primary"
                    href={
                      link.indexOf("http") === -1 ? `https://${link}` : `${link}`
                    }
                    target="_blank"
                  >
                    Open
                </Button>
                </Grid>
              ) : null}
            <Grid item xs={12} md={6}>
              <TextField
                disabled={!isEditing}
                id="title"
                name="title"
                label="Title"
                placeholder="Enter title"
                value={targetedContent.title ? targetedContent.title : ""}
                onChange={(event) =>
                  handleInputChange("title", event.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                error={formProperties.title.errorMessage !== ""}
                helperText={formProperties.title.errorMessage}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownSelect
                disabled={!isEditing}
                label="Site"
                handleChange={(value) => {
                  handleInputChange("territory", value);
                }}
                data={
                  isContentAdmin
                    ? Territories != null
                      ? Object.keys(Territories).map((key) => {
                        return { key: key, value: Territories[key] };
                      })
                      : []
                    : [
                      {
                        key: targetedContent.territory,
                        value: GetSite(targetedContent.territory)
                      }
                    ]
                }
                value={targetedContent?.territory?.toLowerCase()}
                valueName={"territory"}
                error={
                  formProperties.territory &&
                  formProperties.territory.errorMessage !== ""
                }
                fullWidth={true}
              >
                {formProperties.territory &&
                  formProperties.territory.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.territory.errorMessage}
                    </FormHelperText>
                  ) : null}
              </DropdownSelect>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="description"
                name="description"
                label="Description"
                placeholder="Enter description"
                value={
                  targetedContent.description ? targetedContent.description : ""
                }
                onChange={(event) =>
                  handleInputChange("description", event.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                error={formProperties.description.errorMessage !== ""}
                helperText={formProperties.description.errorMessage}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownSelect
                disabled={!isEditing}
                label="Content Language"
                multiple
                handleChange={(value) => {
                  handleInputChange("language", value);
                }}
                data={
                  languageLookupData !== undefined &&
                    languageLookupData.length > 0
                    ? languageLookupData.map((t) => {
                      return { key: t.Id, value: t.DisplayName };
                    })
                    : []
                }
                value={
                  targetedContent.language &&
                    languageLookupData !== undefined &&
                    languageLookupData.length > 0
                    ? targetedContent.language
                    : "en"
                }
                valueName={"language"}
                error={formProperties.language.errorMessage !== ""}
                fullWidth={true}
              >
                {formProperties.language.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.language.errorMessage}
                  </FormHelperText>
                ) : null}
              </DropdownSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownSelect
                disabled={!isEditing}
                label="Retention Policy"
                handleChange={(value) => {
                  handleInputChange("retentionPolicy", value);
                }}
                data={
                  retentionPolicyLookupData !== undefined &&
                    retentionPolicyLookupData.length > 0
                    ? retentionPolicyLookupData.map((t) => {
                      return { key: t.Id, value: t.DisplayName };
                    })
                    : []
                }
                value={
                  retentionPolicyLookupData !== undefined &&
                    retentionPolicyLookupData.length > 0
                    ? targetedContent.retentionPolicy
                    : ""
                }
                valueName={"retentionPolicy"}
                error={formProperties.retentionPolicy.errorMessage !== ""}
                fullWidth={true}
              >
                {formProperties.retentionPolicy.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.retentionPolicy.errorMessage}
                  </FormHelperText>
                ) : null}
              </DropdownSelect>
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="keywords"
                name="keywords"
                label="Keywords"
                placeholder="Enter keywords"
                value={targetedContent.keywords || ""}
                onChange={(event) =>
                  handleInputChange("keywords", event.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                error={formProperties.keywords.errorMessage !== ""}
                helperText={formProperties.keywords.errorMessage}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="dense"
                  disabled={!isEditing}
                  disableToolbar
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Effective From"
                  value={targetedContent.startDate}
                  onChange={(date) => {
                    var startOfDayDate = startOfDay(date)
                    handleInputChange("startDate", startOfDayDate);
                  }}
                  error={formProperties.startDate.errorMessage !== ""}
                  helperText={formProperties.startDate.errorMessage}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="dense"
                  disabled={!isEditing}
                  disableToolbar
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Effective To"
                  value={targetedContent.endDate}
                  onChange={(date) => {
                    handleInputChange("endDate", date);
                  }}
                  error={formProperties.endDate.errorMessage !== ""}
                  helperText={formProperties.endDate.errorMessage}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="dense"
                  disabled={!isEditing}
                  disableToolbar
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Contract Expiry Date"
                  value={
                    targetedContent.contractExpiry
                  }
                  labelFunc={formatContractExpiryDateLabel}
                  // renderDay={
                  //   (day, selectedDate, dayInCurrentMonth, dayComponent) => { return targetedContent.contractExpiry !== null ? selectedDate : null }}
                  onChange={(date) => {
                    handleInputChange("contractExpiry", date);
                  }}
                  error={formProperties.contractExpiry.errorMessage !== ""}
                  helperText={formProperties.contractExpiry.errorMessage}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </CustomCard>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={
          props.type === FormType.VIEW
            ? targetedContent != null
              ? `Content Upload - ${targetedContent.title}`
              : ""
            : "New Content"
        }
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              {props.type != null && props.type === FormType.VIEW ? (
                <ButtonGroup>
                  {!isEditing && navigator.clipboard ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleCopySamContentUrl()}
                    >
                      Copy SAM Content URL
                    </Button>
                  ) : null}
                  {isEditing ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleCancelEditButtonClicked(true)}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      if (isEditing) {
                        submitTargetedContent(false);
                      } else {
                        setUneditedTargetedContent(targetedContent);
                        setIsEditing(true);
                      }
                    }}
                  >
                    {isEditing ? "Submit" : "Edit"}
                  </Button>
                </ButtonGroup>
              ) : (
                  <ButtonGroup>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleCancelEditButtonClicked(true)}
                    >
                      Cancel
                  </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        submitTargetedContent();
                      }}
                    >
                      Submit
                  </Button>
                  </ButtonGroup>
                )}
            </Grid>
          </Grid>
        }
      />

      {cancelTargetedContentEditingAlert()}

      <PageContainer hasHelp={props.type !== FormType.VIEW}>
        {renderData()}
        <Snackbar open={copySuccessOpen} autoHideDuration={6000} onClose={()=> {setCopySuccessOpen(false)}}>
          <Alert severity="success" onClose={()=> {setCopySuccessOpen(false)}}>URL Copied</Alert>
        </Snackbar>
      </PageContainer>
    </div>
  );
};

const hoc = withRouter(TargetedContentPage);

// EXPORT COMPONENT
export { hoc as TargetedContentPage };
