import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import HeaderBlock from "../../../components/header-block/HeaderBlock";

import { getActivityById } from "../../../state/actions/MyDisplaysActions";
import { createReward } from "../../../state/actions/ActivityActions";
import { Loading } from "../../../components/loading/Loading";

const NewRewardPage = (props) => {
  const dispatch = useDispatch();
  const displayGroupId = props.match.params.DisplayGroupId;
  const displayIndex = props.match.params.DisplayIndex;
  const activityId = props.match.params.ActivityId;

  const currentActivity = useSelector(
    (state) => state.MyDisplaysReducer.displayActivity
  );

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  let createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const rewardData = {
    ActivityId: currentActivity.Activity.Id.toString(),
    CreatedBy: createdUser,
    IsActive: true,
    ModifiedBy: createdUser,
    UpperRange: null,
    Value: null,
  };

  const [loading, setLoading] = useState(false);
  const [newReward, setNewReward] = useState(rewardData);

  useEffect(() => {
    dispatch(
      getActivityById(activityId, () => {
        setLoading(true);
      })
    );
  }, []);

  const handleUpperRangeChange = (e) => {
    let localReward = { ...newReward };
    localReward.UpperRange = e.target.value;
    setNewReward(localReward);
  };

  const handleValueChange = (e) => {
    let localReward = { ...newReward };
    localReward.Value = e.target.value;
    setNewReward(localReward);
  };

  const handleActiveChange = (e) => {
    let localReward = { ...newReward };
    localReward.IsActive = e.target.checked;
    setNewReward(localReward);
  };

  const submitReward = () => {
    dispatch(
      createReward(newReward.ActivityId, newReward, () => {
        props.history.push(
          `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}`
        );
      })
    );
  };

  return (
    <div>
      {loading ? (
        <>
          <HeaderBlock
            title={`New Reward - ${currentActivity.Activity.Name}`}
            right={
              <ButtonGroup
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    props.history.push(
                      `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}`
                    )
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={!newReward.UpperRange || !newReward.Value}
                  onClick={submitReward}
                >
                  Save
                </Button>
              </ButtonGroup>
            }
          />
          <div className="create-container" style={{ marginTop: "150px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  type="number"
                  label={"Enter Upper Range"}
                  value={newReward.UpperRange}
                  onChange={handleUpperRangeChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  type="number"
                  label={"Enter Value"}
                  value={newReward.Value}
                  onChange={handleValueChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography>Active</Typography>
                <Checkbox
                  color="primary"
                  checked={newReward.IsActive}
                  onChange={handleActiveChange}
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const hoc = withRouter(NewRewardPage);

// EXPORT COMPONENT
export { hoc as NewRewardPage };
