// React
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import styles from "./ListTargetGroupsPage.module.scss";

// Actions
import { getTargetGroups } from "../../../state/actions/TargetGroupActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import { OutlinedIconButton } from "../../../components/buttons/OutlinedIconButton";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import ScrollableTabs from "../../../components/tabs/Tabs";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";
import {
  ModelType,
  modelTypeToIndex,
} from "../../../state/constants/ModelType";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px",
  },
  containerPadding: {
    padding: "16px",
  },
}));

const ListTargetGroupsPage = (props) => {
  const classes = useStyles();

  const { targetGroups } = useSelector((state) => state.TargetGroupReducer);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [currentModelType, setCurrentModelType] = useState(ModelType.CUSTOMER);
  const [filters, setFilters] = useState({ hidden: true });
  const [filtersUpdated, setFiltersUpdated] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [lowerMT, setLowerMT] = useState("");

  useEffect(() => {
    setLoading(true);

    dispatch(
      getTargetGroups(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    let str = currentModelType.toLowerCase().replace(/\s/g, "");
    setLowerMT(str.charAt(0).toUpperCase() + str.substring(1));
  }, [currentModelType]);

  useEffect(() => {
    setCurrentModelType(ModelType.CUSTOMER);
    setupData();
  }, []);

  useEffect(() => {
    search(searchText);
  }, [targetGroups]);

  useEffect(() => {
    setFiltersUpdated(false);
    search(searchText);
  }, [filters, filtersUpdated]);

  useEffect(() => {
    setupData();
  }, [currentModelType, setCurrentModelType]);

  const setupData = () => {
    const filteredTargetGroups = targetGroups.filter(
      (tg) =>
        tg.ModelType.toLowerCase() ===
        currentModelType.replace(" ", "").toLowerCase()
    );
    //debugger;
    const data = createData(filteredTargetGroups);

    const searchableData = filters["hidden"]
      ? data.filter((item) => item.hidden === null || !item.hidden)
      : data;

    setOriginalData(data);
    setSearchedData(searchableData);
  };

  const createData = (targetGroups) => {
    return targetGroups.map((targetGroup) => {
      return {
        id: targetGroup.TargetGroupId,
        name: targetGroup.TargetGroupName,
        description: targetGroup.TargetGroupDescription,
        hidden: targetGroup.Hidden,
      };
    });
  };

  const search = (searchText) => {
    setupData();
    setSearchText(searchText);
    const text = searchText.toLowerCase();
    const searchableData = filters["hidden"]
      ? searchedData.filter((data) => data.hidden === null || !data.hidden)
      : originalData;

    const foundData = searchableData.filter((content) => {
      return (
        content.name.toLowerCase().includes(text) ||
        content.description.toLowerCase().includes(text)
      );
    });
    setSearchedData(foundData);
  };

  const createHeaders = () => {
    return [
      {
        id: "name",
        clickable: true,
        action: {
          path: "/target-groups/{id}",
          identifier: "id",
        },
        align: "left",
        label: "Target Group Name",
      },
      { id: "description", label: "Description", align: "left" },
    ];
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const onTabChange = (newValue) => {
    switch (newValue) {
      case modelTypeToIndex(ModelType.CUSTOMER):
        setCurrentModelType(ModelType.CUSTOMER);
        break;
      case modelTypeToIndex(ModelType.MATERIAL):
        setCurrentModelType(ModelType.MATERIAL);
        break;
      case modelTypeToIndex(ModelType.ACTIVITY):
        setCurrentModelType(ModelType.ACTIVITY);
        break;
      case modelTypeToIndex(ModelType.PROMOTION):
        setCurrentModelType(ModelType.PROMOTION);
        break;
      case modelTypeToIndex(ModelType.REDCHANNEL):
        setCurrentModelType(ModelType.REDCHANNEL);
        break;
      default:
        setCurrentModelType(ModelType.CUSTOMER);
    }
  };

  const handleChange = (event) => {
    let localFilters = filters;

    if (filters[event.target.name]) {
      localFilters[event.target.name] = !localFilters[event.target.name];
    } else {
      localFilters[event.target.name] = true;
    }

    setFilters(localFilters);
    setFiltersUpdated(true);
    search(searchText);
  };

  const displayFilters = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="flex-end"
        style={{
          display: showFilters ? "inline-flex" : "none",
          // transition:
          padding: 16,
        }}
      >
        <Typography align="right">Filter: </Typography>
        <FormGroup row>
          <FormControlLabel
            labelPlacement="start"
            key="hidden-cb"
            label="Hide Mass-Uploaded"
            control={
              <Checkbox
                checked={filters["hidden"]}
                onChange={handleChange}
                name="hidden"
                color="primary"
              />
            }
          />
        </FormGroup>
      </Grid>
    );
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);
    const data =
      searchedData.length === 0 ? (
        <Fragment>
          {displayFilters()}
          <PagePlaceholder
            image={getIcon("frame-expand.svg")}
            text="No Target Groups found."
          />
        </Fragment>
      ) : (
        <Fragment>
          {displayFilters()}
          <SimpleTable
            handleSelection={null}
            isSelectable={false}
            dataId={"targetGroupId"}
            cells={cells}
            headers={headers}
            rows={searchedData}
          />
        </Fragment>
      );

    return (
      <ScrollableTabs
        handleTabChange={(newValue) => onTabChange(newValue)}
        value={modelTypeToIndex(currentModelType)}
        tabs={[
          {
            name: "Customers",
            key: "customers",
            content: loading ? <Loading /> : data,
          },
          {
            name: "Materials",
            key: "materials",
            content: loading ? <Loading /> : data,
          },
          {
            name: "Activities",
            key: "activities",
            content: loading ? <Loading /> : data,
          },
          {
            name: "Promotions",
            key: "promotions",
            content: loading ? <Loading /> : data,
          },
          {
            name: "Red Channel",
            key: "redchannel",
            content: loading ? <Loading /> : data,
          },
        ]}
      />
    );
  };

  return (
    <div>
      <HeaderBlock
        title={"Target Groups"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                id="outlined-name"
                label="Search Target Groups"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            {loggedInUserRoles.includes("Content Admin") ||
            loggedInUserRoles.includes("SaM Core Admin") ||
            loggedInUserRoles.includes("Content Manager") ||
            loggedInUserRoles.includes("Content Targeting Manager") ? (
              <Grid item>
                <OutlinedIconButton
                  clickHandler={() => setShowFilters(!showFilters)}
                  icon="funnel.svg"
                />
              </Grid>
            ) : null}
            <Grid item>
              <ExportExcel
                csvData={searchedData}
                fileName="target-groups"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push(
                      `target-groups/new?modelType=${lowerMT}`
                    );
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Add New
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ListTargetGroupsPage);

// EXPORT COMPONENT
export { hoc as ListTargetGroupsPage };
