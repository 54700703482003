// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import { Button, ButtonGroup, Grid, TextField } from "@material-ui/core";
import styles from "./SECOrderPeriodsUploadPage.module.scss";

// Actions
import { getAllSamConfigurations } from "../../state/actions/SECActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import ScrollableTabs from "../../components/tabs/Tabs";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";

// Other
import { getIcon } from "../../icon/icon";
import {
  TargetedContentType,
  targetedContentTypeToIndex
} from "../../state/constants/TargetedContentType";

// Component entry point
const SECOrderPeriodsUploadPage = (props) => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const configurations = useSelector((state) => state.ConfigurationReducer.configurations);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setLoading(true);

    dispatch(
      getAllSamConfigurations(loggedInUser.territory === "au" ? 1 : 2, () => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setupData();
  }, [configurations]);

  const setupData = () => {

    const filteredConfigurations = configurations
    let data = createData(filteredConfigurations);

    data = data.sort(function (a, b) {
      const keys = ["mainkey", "subkey", "code", "value"];

      let depth = 0;
      while(depth < (keys.length - 1)) {
        if (a[keys[depth]].toLowerCase() > b[keys[depth]].toLowerCase()) {
          return 1;
        }
        else if (a[keys[depth]].toLowerCase() < b[keys[depth]].toLowerCase()) {
          return -1;
        }
        else if (a[keys[depth]].toLowerCase() === b[keys[depth]].toLowerCase()) {
          depth += 1;
        }
      }
      
      return 0;
    });

    setOriginalData(data);
    setSearchedData(data);
  };

  const createData = (content) => {
    return content.map((config) => {
      return {
        id: config.id,
        site: config.site,
        application: config.application,
        mainkey: config.mainkey,
        subkey: config.subkey,
        code: config.code,
        value: config.value,
        valueDescription: config.valueDescription
      };
    });
  };

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const foundData = originalData.filter((config) => {
      return (
        (config.mainkey.toLowerCase().includes(text) ||
          config.subkey.toLowerCase().includes(text) ||
          config.site.toString().includes(text) ||
          config.code.toLowerCase().includes(text) ||
          config.value.toLowerCase().includes(text) ||
          config.valueDescription.toLowerCase().includes(text) ||
          config.application.toLowerCase().includes(text)
        )
      );
    });

    setSearchedData(foundData);
  };

  const createHeaders = () => {
    return [
      {
        id: "site",
        align: "left",
        label: "Site"
      },
      {
        id: "mainkey",
        align: "left",
        label: "Mainkey",
        clickable: true,
        action: {
          path: "/sam-configuration-upload/{id}",
          identifier: "id"
        },
      },
      {
        id: "subkey",
        align: "left",
        label: "Subkey"
      },
      {
        id: "code",
        align: "left",
        label: "Code"
      },
      {
        id: "value",
        align: "left",
        label: "Value"
      },
      {
        id: "valueDescription",
        align: "left",
        label: "Value Description"
      },
      {
        id: "application",
        align: "left",
        label: "Application"
      }
    ];
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const onTabChange = (value) => {

  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      searchedData.length === 0 ? (
        <PagePlaceholder
          image={getIcon("frame-expand.svg")}
          text="No configurations found."
        />
      ) : (
        <SimpleTable
          handleSelection={null}
          isSelectable={false}
          dataId={"id"}
          cells={cells}
          headers={headers}
          rows={searchedData}
        />
      );
    return (
      <ScrollableTabs
        handleTabChange={(newValue) => onTabChange(newValue)}
        value={0}
        tabs={[
          {
            name: "Configurations",
            key: "configurations",
            content: loading ? <Loading /> : data
          }
        ]}
      />
    );
  };

  return (
    <div>
      <HeaderBlock
        title={"SaM Configuration"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
               <TextField
                className={styles.roleField}
                id="outlined-name"
                label="Search"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("sam-configuration-upload/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  New Configuration
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(SECOrderPeriodsUploadPage);

// EXPORT COMPONENT
export { hoc as SECOrderPeriodsUploadPage };