import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Loading } from "../../components/loading/Loading";
import Search from "../../components/search/Search";
import SimpleList from "../../components/simple-list/SimpleList";

export default function GenericPicker(props) {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [newData, setNewData] = useState(false);

  useEffect(() => {
    if (props.getAllData) {
      retrieveData();
    }
  }, []);

  useEffect(() => {
    const fileValues = props.data;
    const data = [];
    const selected = props.selected ? props.selected : [];

    // Iterate through the data and add any items that haven't been selected
    fileValues.forEach((value) => {
      if (!selected.includes(value.id)) {
        if (
          props.idField != null &&
          (props.valueField != null || props.secondaryValueField != null)
        ) {
          data.push({
            id: value[props.idField],
            value:
              value[props.valueField] != null
                ? value[props.valueField]
                : value[props.secondaryValueField],
            secondaryValue: value[props.secondaryValueField] != null ? value[props.secondaryValueField] : null
          });
        } else {
          data.push({
            id: value,
            value: value
          });
        }
      }
    });
    setSearchedData(data);
    setOriginalData(data);
  }, [newData]);

  function handleSelection(selection) {
    props.handleSelection(selection);
  }

  function retrieveData() {
    setLoading(true);

    props.getAllData(() => {
      setLoading(false);
      setNewData(true);
    });
  }

  return (
    <Box style={{ height: "calc(100% - 55px)" }}>
      <Search
        loading={loading}
        searchField={props.searchField != null ? props.searchField : "value"}
        returnSearchMatches={(data) => {
          setSearchedData(data);
        }}
        data={originalData}
        searchTitle={`Search ${props.searchTitle}`}
      />
      {loading ? (
        <Loading />
      ) : (
          <SimpleList
            emptyPlaceholder={`No ${props.searchTitle} type ids to assign.`}
            handleSelection={handleSelection}
            data={searchedData}
            currentSelection={props.currentSelection}
          />
        )}
    </Box>
  );
}

GenericPicker.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  handleSelection: PropTypes.func.isRequired,
  searchTitle: PropTypes.string.isRequired,
  searchField: PropTypes.string,
  idField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  secondaryValueField: PropTypes.string,
  currentSelection: PropTypes.arrayOf(PropTypes.string).isRequired
};
