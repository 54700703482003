import React from "react";

import {
    Alert,
    AlertTitle
} from "@material-ui/lab"

import {
    Collapse
} from "@material-ui/core"

/*
Accepted props

title = self explanatory
body = the main content of the alert, use arrays if you're injecting html components mixed with strings.
severity = the colour theming of the alert, supports error, warning, info, success.
isEditing = the flag used by the wrapping component to determine this components transition state.

*/

export default function EditingAlert(props) {
    return (
        <Collapse in={props.isEditing}>
            <Alert severity={props.severity}>
                <AlertTitle>{props.title}</AlertTitle>
                        {props.body}
                    </Alert>
        </Collapse>
    )
}