// Data and Config
import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";

// Other
import {
  UPLOAD_SEC_CUSTOMERS,
  DOWNLOAD_SEC_CUSTOMERS,
  DOWNLOAD_SEC_POINTS,
  UPLOAD_SEC_POINTS,
  CREATE_CONFIGURATION,
  GET_CONFIGURATION_BY_ID,
  GET_ALL_CONFIGURATIONS,
  CREATE_NEW_CONFIGURATION
} from "../constants/action-types";

const FileDownload = require('js-file-download');
const API_CONFIG = getApiConfig();

export const downloadSECCustomerData = (site, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.ACTIVITIES}/ExportSECCustomers?site=${site}`,
      {
        method: "get"
      }
    );

    dispatch({
      type: DOWNLOAD_SEC_CUSTOMERS,
      payload: {
        data: FileDownload(response.data, `SECCustomers.csv`)
      }
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
}

export const uploadSECCustomerData = (data, site, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.ACTIVITIES}/ImportSECCustomers?site=${site}`,
      {
        method: "post",
        data: data
      }
    ); dispatch({
      type: UPLOAD_SEC_CUSTOMERS,
      payload: response.data
    });

    callback(response.data);
  } catch (error) {
    console.log(error);
    callback(false);
  }
}

export const downloadSECPointsData = (site, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.ACTIVITIES}/ExportSECPoints?site=${site}`,
      {
        method: "get"
      }
    );

    dispatch({
      type: DOWNLOAD_SEC_POINTS,
      payload: {
        data: FileDownload(response.data, `SECPoints.csv`)
      }
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
}

export const uploadSECPointsData = (data, site, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.ACTIVITIES}/ImportSECPoints?site=${site}`,
      {
        method: "post",
        data: data
      }
    ); dispatch({
      type: UPLOAD_SEC_POINTS,
      payload: response.data
    });

    callback(response.data);
  } catch (error) {
    console.log(error);
    callback(false);
  }
}

export const getAllSamConfigurations = (site, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/ListSamConfiguration?site=${site}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_ALL_CONFIGURATIONS,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const createConfiguration = (site, configuration, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertSamConfiguration?site=${site}`,
        {
          method: "post",
          headers: headers,
          data: configuration
        }
      );

      dispatch({
        type: CREATE_CONFIGURATION,
        payload: {
          data: response,
          site: site
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing activity group
*/
export const updateConfiguration = (
  site,
  configurationId,
  configuration,
  callback
) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertSamConfiguration?site=${site}`,
        {
          method: "post",
          headers: headers,
          data: configuration
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const createEmptyConfig = (site) => (dispatch) => {
  dispatch({
    type: CREATE_NEW_CONFIGURATION,
      payload: {
        site: site
      }
  });
};

export const getConfigurationById = (site, configurationId, callback) => {

  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/ListSamConfiguration?site=${site}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_CONFIGURATION_BY_ID,
        payload: {
          data: response.data,
          site: site,
          configurationId: configurationId
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};