export const ViewOrderModel = {
    FromDate: {
      type: "date",
      required: true,
      errorMessage: "",
      value: "FromDate",
      label: "From Date",
    },
    ToDate: {
      type: "date",
      required: true,
      errorMessage: "",
      value: "ToDate",
      label: "To Date",
    },
    TPPT: {
      type: "string",
      required: true,
      errorMessage: "",
      value: "TPPT",
      label: "TPPT",
    },
    ServiceAgent: {
      type: "string",
      required: true,
      errorMessage: "",
      value: "ServiceAgent",
      label: "Service Agent",
    },
  };