import React, {useEffect, useState, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import { createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Edit, Check, Clear } from "@material-ui/icons";

import MUIDataTable from "mui-datatables";
import { format } from "date-fns";
import PageContainer from "../../components/page-container/PageContainer";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import PlanDetailModal from "./PlanDetailModal";
import EditPlanModal from "./EditPlanModal";
import { Loading } from "../../components/loading/Loading";

import styles from './styles/myDisplaysPlanningTable.module.scss';
import { GetProductList } from "../../state/actions/CoreRangeActions";
import { GetPlannedDisplays,
  GetPlannedDisplayDetails,
  UpsertDisplayPlan } from "../../state/actions/MyDisplaysPlanningActions";
import { Box, ThemeOptions } from "@material-ui/core";

import { MyDisplaysPlanningReducerState, PlannedDisplay, SKU } from "../../state/reducers/MyDisplaysPlanningReducer";
import { FixMeLater } from '../../types/FixMeLaterType';
import { RootState } from "../../state/reducers/AppReducer";
import { LoggedInUser } from "../../state/reducers/UserReducer";
import ExpandableTableRow from "./ExpandableTableRow";
import { getSiteForLoggedInUser } from '../../helpers/userHelpers';
import { isEqual } from "lodash";

interface ThemeType extends ThemeOptions {
  overrides: Object
}

const getMuiTheme = () => createTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none !important',
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        opacity: 0.35,
        pointerEvents: 'none'
      },
    },
  },
} as ThemeType);

const MyDisplaysPlanningPage = () => {
  const dispatch = useDispatch();
  const theme = getMuiTheme();
  const productList = useSelector((state: FixMeLater) => state.CoreRangeReducer.Products);
  const plannedDisplays = useSelector<RootState, Array<PlannedDisplay>>(state => state.MyDisplaysPlanningReducer.plannedDisplays);
  const plannedDisplayDetails = useSelector<RootState, MyDisplaysPlanningReducerState>(state => state.MyDisplaysPlanningReducer.plannedDisplayDetails);
  const loggedInUser = useSelector<RootState, LoggedInUser | null>((state) => state.UserReducer.loggedInUser);
  
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formattedPlans, setFormattedPlans] = useState<Array<any>>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [newPlanData, setNewPlanData] = useState<PlannedDisplay | null>(null);
  const [editPlanModalOpen, setEditPlanModalOpen] = useState<boolean>(false);
  const [planDetailModalOpen, setPlanDetailModalOpen] = useState<boolean>(false);

  const selectedPlan = useMemo(() => {
    if(!plannedDisplays || !plannedDisplays.length) return;
    return plannedDisplays?.find(plan => plan.myDisplaysPlanning.PlannedDisplayId === selectedPlanId)
  }, [selectedPlanId]);


  if(!productList || !productList.length) {
    dispatch(GetProductList());
  }

  function handleSave(planData: PlannedDisplay, previousData: PlannedDisplay){
    if(!isEqual(planData, previousData)){
      // Copy the SKUs data to the Products field, as that's what the API expects...
      const updatedData ={
        ...planData,
        myDisplaysPlanning: {
          ...planData.myDisplaysPlanning,
          Products: planData.SKUs
        }
      };
      dispatch(UpsertDisplayPlan(updatedData, loggedInUser));
    }

    // Reset everthing
    setEditPlanModalOpen(false);
    setNewPlanData(null);
    setSelectedPlanId(null);
  }

  function getTotalQuantity(skus: Array<SKU>) {
    if(!skus || skus.length < 1) return '–';
    return skus.reduce((acc, sku) => {
      return acc + sku.Quantity;
    }, 0);
  }

  function getMaterialNameFromMaterialNumber(materialNumber: string){
    if(!materialNumber || typeof materialNumber !== 'string') return '–';
    const product = productList.find((product: FixMeLater) => product.Number === materialNumber); // Product should be a material type
    return product?.Description || '[Material name not found]';
  }


  useMemo(()=>{
    formatPlannedDisplaysDataForTable(plannedDisplays).then((result)=>{
      setFormattedPlans(result);
    });
  }, [plannedDisplays])
  
  async function formatPlannedDisplaysDataForTable(plannedDisplays: Array<PlannedDisplay>){
    if(!plannedDisplays) return [];
    setIsLoading(true);
    
    const formattedPlans = await plannedDisplays.map(item => {
      const display = item.myDisplaysPlanning;
      const SKUs : Array<SKU> = item.SKUs;
      const totalQuantity = getTotalQuantity(SKUs);
      
      return [
        display.PlannedDisplayId,
        display.Site,
        display.Description,
        display.CustomerRule,
        display.Value,
        ()=>{ return display.StartDate !== null ? format(new Date(display.StartDate), 'd LLL yyyy') : '?'},
        ()=>{ return display.EndDate !== null ? format(new Date(display.EndDate), 'd LLL yyyy') : '?'},
        display.PlannedDisplayType,
        display.DisplayType,
        display.LocationValue, // eg. 'Primary End', 'Counter', 'Checkout'
        SKUs,
        totalQuantity,
        !display.CoopCost ? '–' : Number(display.CoopCost).toFixed(2),
        !display.RecommendedPromotionalPrice ? '–' : Number(display.RecommendedPromotionalPrice).toFixed(2),
        display.AllowDisplayTypeChanges ? <Check fontSize="small" htmlColor="#4CAF50" data-value="true"/> : <Clear fontSize="small" htmlColor="#FFAADD"  data-value="false" />,
        display.Notes,
        display.IsActive ? <Check fontSize="small" htmlColor="#4CAF50"/> : <Clear fontSize="small" htmlColor="#FFAADD" />,
        display.CreatedBy,
        ()=>{ return display.CreatedDate !== null && display.CreatedDate !== undefined ? format(new Date(display.CreatedDate), 'd LLL yyyy') : '?'},
        display
      ]
    })
    setIsLoading(false);
    return formattedPlans;
  }
  
  function scaffoldNewPlan() : PlannedDisplay {
    return { 
      myDisplaysPlanning : {
        Site: getSiteForLoggedInUser(loggedInUser),
        CustomerRule : null,
        Value: null,
        Description : null,
        StartDate: null,
        EndDate: null,
        PlannedDisplayType: null,
        DisplayType: null,
        DisplayTypeActivityId: null,
        AllowDisplayTypeChanges: true,
        LocationValue: null,
        CoopCost: null,
        RecommendedPromotionalPrice: null,
        CustomerConfirmed: true,
        Notes: '',
        LockedForUpdate: false,
        IsActive: true,
        IsRepCreated: false,
        CreatedDate: null,
        CreatedBy: loggedInUser?.userId,
        ModifiedDate: null,
        ModifiedBy: loggedInUser?.userId,

        /**
         * There's overlap with the Products and SKUs field below. SKUs used to be added to the plan by a separate UpsertSKUs endpoint.
         * Products was added at a later stage so that's now included in the plan data and sent via the main UpsertDisplayPlan endpoint.
         * Products is the field that we now use to add SKUs to the plan, but SKUs is still the field that we use to render the data (when
         * querying a display plan, the Products field is empty.).
         * The Products array gets copied to the SKUs field on the server side.
         */
        Products: [
          // {MaterialNumber: '', Quantity: 0}
        ]
      },
      SKUs: [
        // {MaterialNumber: '', Quantity: 0}
      ]
    }
  }

  useEffect(() => {
    dispatch(
      GetPlannedDisplays(() => {
        setInitialLoad(false)
      })
    );

    if(!productList.length){
      dispatch(
        GetProductList(getSiteForLoggedInUser(loggedInUser), () => {'Fetched product list'})
      );
    }
  }, []);

  useMemo(() => {
    dispatch(
      GetPlannedDisplayDetails(selectedPlanId)
    )
  }, [selectedPlanId]);
    
  const columns = [
    { name: 'ID', options: {display: false, useAsKey: true, filter: false}},
    { name: 'Site', options: {filter: false}},
    { name: 'Description', options: {
      filter: false,
      customBodyRender: (value: string) => {
        return (<b className={styles.planDescription}>{value}</b>)
      }
    }},
    { name: 'Customer Rule', options: {filter: true}},
    { name: 'Value',
      options: {
        filter: false,
        customBodyRender: (value: string) => {
          if(!value || value.length === 0) return '–';
          return value.split(',').join(', ');
        }
      }
    },
    { name: 'Start Date', options: {
      filter: false,
      sortCompare: (order: any) => {
        return (obj1:any, obj2:any) => {
          let val1 = new Date(obj1?.rowData[5]());
          let val2 = new Date(obj2?.rowData[5]());
          if(order === 'asc') {
            if(val1.getTime() < val2.getTime()) return -1;
            if(val1.getTime() > val2.getTime()) return 1;
          }
          if(order === 'desc') {
            if(val1.getTime() > val2.getTime()) return -1;
            if(val1.getTime() < val2.getTime()) return 1;
          }
          return 0;
        };
      }}
  },
    { name: 'End Date', options: {
      filter: false,
      sortCompare: (order: any) => {
        return (obj1:any, obj2:any) => {
          let val1 = new Date(obj1?.rowData[6]());
          let val2 = new Date(obj2?.rowData[6]());
          if(order === 'asc') {
            if(val1.getTime() < val2.getTime()) return -1;
            if(val1.getTime() > val2.getTime()) return 1;
          }
          if(order === 'desc') {
            if(val1.getTime() > val2.getTime()) return -1;
            if(val1.getTime() < val2.getTime()) return 1;
          }
          return 0;
        };
      }}
  },
    { name: 'Planned Display Type', options: {filter: true}},
    { name: 'Display Type', options: {filter: true}},
    { name: 'Location', options: {filter: true}},
    { name: 'SKUs', options: {
      filter: false,
      customBodyRender: (value: Array<SKU>) => {
        if(!value || value.length === 0) return '–';
        if(value.length > 1) {
          return ( <span>{`${value.length} products`}</span>)
        }
        const materialName = getMaterialNameFromMaterialNumber(value[0].MaterialNumber);
        return (
          <span>{materialName}</span>
        )
      },
      sortCompare: (order: any) => {
        return (obj1:any, obj2:any) => {
          let val1 = obj1?.rowData[10].length;
          let val2 = obj2?.rowData[10].length;
          if(order === 'asc') {
            if(val1 === '–') return -1;
            if(val1 < val2) return -1;
            if(val1 > val2) return 1;
          }
          if(order === 'desc') {
            if(val2 === '–') return -1;
            if(val1 > val2) return -1;
            if(val1 < val2) return 1;
          }
          return 0;
        };
      }
    }},
    { name: 'Quantity', options: {
      filter: false,
      sortCompare: (order: any) => {
        return (obj1:any, obj2:any) => {
          let val1 = obj1?.rowData[11];
          let val2 = obj2?.rowData[11];
          if(order === 'asc') {
            if(val1 === '–') return -1;
            if(Number(val1) < Number(val2)) return -1;
            if(Number(val1) > Number(val2)) return 1;
          }
          if(order === 'desc') {
            if(val2 === '–') return -1;
            if(Number(val1) > Number(val2)) return -1;
            if(Number(val1) < Number(val2)) return 1;
          }
          return 0;
        };
      }
    }},
    { name: 'Co-op', options: {filter: false}},
    { name: 'Price', options: {filter: false}},
    { name: 'Allow Display Type Change?', options: {
        filter: true,
        sortCompare: (order: any) => {
          return (obj1:any, obj2:any) => {
            let val1 = obj1?.rowData[14];
            let val2 = obj2?.rowData[14];
            if(order === 'asc') {
              return (val1.props['data-value'] === val2.props['data-value']) ? 0 : val1.props['data-value'] === 'true' ? -1 : 1;
            }
            if(order === 'desc') {
              return (val1.props['data-value'] === val2.props['data-value']) ? 0 : val1.props['data-value'] === 'true' ? 1 : -1;
            }
            return 0;
          };
        }
      }
    },
    { name: 'Notes', options: {filter: false}},
    { name: 'Is Active', options: {filter: false, sort: false}},
    { name: 'Created', options: {
      filter: false,
      sortCompare: (order: any) => {
        return (obj1:any, obj2:any) => {
          let val1 = obj1?.rowData[18]?.CreatedBy;
          let val2 = obj2?.rowData[18]?.CreatedBy;
          if(order === 'asc') return val1 < val2 ? -1 : val1 > val2 ? 1 : 0;
          if(order === 'desc') return val1 > val2 ? -1 : val1 < val2 ? 1 : 0;
          return 0;
        };
      }
    }
  },
    { name: "Created Date", options: {
    filter: false,
      }
  },
    { name: 'Actions', options: {
      filter: false,
      customBodyRender: (value: FixMeLater) => {
        if(!value || value.length === 0) return '';
        return (
          <Box display='flex' justifyContent="flex-end" alignItems="center">
            <IconButton size="medium" aria-label="Edit Display Plan"
              aria-hidden={value.LockedForUpdate}
              onClick={e => {
                if(!value.LockedForUpdate) e.stopPropagation();
                setSelectedPlanId(value.PlannedDisplayId);
                setEditPlanModalOpen(true);
              }}><Edit /></IconButton>
          </Box>
        )
      }
    }},
  ];

  return (
    <>
      <HeaderBlock title="My Displays Planning"
        right={
          <Box display={'flex'} justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={()=>{
              setSelectedPlanId(null);
              setNewPlanData(scaffoldNewPlan());
              setEditPlanModalOpen(true);
              }}>New Display Plan</Button>
          </Box>
        }
      />
      <PageContainer>
        {initialLoad ? <Loading /> :
          <MuiThemeProvider theme={theme}>
            <MUIDataTable
                title=""
              // We need more complex CSS than the component exposes
              /* @ts-ignore */
              className={styles.myDisplaysPlanningTable}
              data={formattedPlans}
              columns={columns}
              options={{
                pagination: true,
                rowsPerPage: 15, // Keep this low for better perf. May look at other improvments in the future
                rowsPerPageOptions: [],
                // @ts-expect-error onChangePage was renamed to onPageChange
                onPageChange: ()=>{},
                onChangePage: ()=>{},
                search: true,
                print: false,
                download: false,
                filter: true,
                viewColumns: false,
                selectableRows: 'none',
                fixedHeader: true,
                responsive: 'standard',
                tableBodyMaxHeight: 'calc(100vh - 203px)',
                tableBodyHeight: 'calc(100vh - 203px)',
                /* @ts-ignore */
                responsiveScrollMaxHeight: 'calc(100vh - 203px)',
                onRowClick: (rowData, rowMeta)=>{
                  setPlanDetailModalOpen(true)
                  setSelectedPlanId(rowData[0])
                },
                expandableRows: true,
                expandableRowsHeader: false,
                fixedSelectColumn: false,
                isRowExpandable: (index) => {
                  // Enable expansion if there is more than one SKU
                  const rowData = plannedDisplays[index];
                  return Array.isArray(rowData.SKUs) && rowData.SKUs.length > 1;
                },
                renderExpandableRow: (rowData, rowMeta) => {
                  const rawRowData = plannedDisplays.find(plan => plan.myDisplaysPlanning.PlannedDisplayId === rowData[0]);
                  
                  return(
                    <ExpandableTableRow
                      rowData={rawRowData}
                      getMaterialNameFromMaterialNumber={getMaterialNameFromMaterialNumber}
                    />
                  )
                }
              }}
            />
          </MuiThemeProvider>
        }
      </PageContainer>

      {/* View plan details */}
      <PlanDetailModal
        planData={plannedDisplayDetails}
        plan={selectedPlan}
        open={planDetailModalOpen}
        getTotalQuantity={getTotalQuantity}
        getMaterialNameFromMaterialNumber={getMaterialNameFromMaterialNumber}
        handleClose={()=>{
          setPlanDetailModalOpen(false)
          setNewPlanData(null);
          setSelectedPlanId(null);
        }}
      />
      
      {/* Create a new plan */}
      { editPlanModalOpen && !selectedPlanId && newPlanData ?
        <EditPlanModal
          open={true}
          isNew={true}
          planData={newPlanData}
          handleSave={handleSave}
          getMaterialNameFromMaterialNumber={getMaterialNameFromMaterialNumber}
          productList={productList}
          handleClose={()=>{
            setNewPlanData(null);
            setSelectedPlanId(null)
            setEditPlanModalOpen(false);
          }}
          /> : null }
      
      {/*Edit an existing plan */}
      { editPlanModalOpen && selectedPlanId && !newPlanData ?
        <EditPlanModal
          isNew={false}
          open={true}
          planData={selectedPlan}
          handleSave={handleSave}
          getMaterialNameFromMaterialNumber={getMaterialNameFromMaterialNumber}
          productList={productList}
          handleClose={()=>{
            setNewPlanData(null);
            setSelectedPlanId(null)
            setEditPlanModalOpen(false);
          }}
        /> : null }
    </>
  );
}

const hoc = withRouter(MyDisplaysPlanningPage);
export { hoc as MyDisplaysPlanningPage };
