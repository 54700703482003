// React
import React from "react";

// UI and Styling
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  makeStyles
} from "@material-ui/core";

// Components
import ShrinkingButtonGroup from "../shrinking-button-group/ShrinkingButtonGroup";

// Other
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  smallScreen: {
    display: "none"
  },
  cardContent: {
    overflow: "auto",
    background:
      "linear-gradient(white 30%, rgba(255, 255, 255, 0)), " +
      "linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, " +
      "linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
    backgroundAttachment: "local, local, scroll, scroll",
  },
  "@media only screen and (max-width: 1550px)": {
    largeScreen: {
      display: "none"
    },

    smallScreen: {
      display: "inherit",
      fontSize: 16
    }
  },
  placeholder: {
    opacity: "0.5"
  },
  icon: {
    height: "22px"
  },
  cardHeaderAction: {
    marginTop: 0,
    marginRight: 0
  }
}));

const CustomCard = (props) => {
  // Import styles from our useStyles constant.
  const classes = useStyles();
  
  // Define actionButtons
  const actionButtons = props.actionButton
    ? props.actionButton.map((action) => {
      return {
        label: action.props.children,
        action: () => action.props.onClick()
      };
    })
    : [];
  return (
    <Card>
      { (props.actionButton || props.title) ? <CardHeader
        className={classes.cardHeader}
        title={props.title}
        classes={{ action: classes.cardHeaderAction }}
        action={
          !(props.hasOwnProperty("display") && props.display === false) && actionButtons.length > 0 &&
          <Box className={classes.cardHeaderAction}>
            <Box className={classes.largeScreen}>
              {props.showChip && (
                <Chip label={props.dataLength} variant="outlined" />
              )}
              <ButtonGroup>
                {!props.isLoading && props.actionButton}
              </ButtonGroup>
            </Box>
            <Box className={classes.smallScreen}>
              <ShrinkingButtonGroup
                style={{
                  display: "none"
                }}
                secondary={actionButtons}
                default={{
                  label: "...",
                  action: null
                }}
              />
            </Box>
          </Box>
        }
      ></CardHeader> : null }
      <CardContent className={classes.cardContent}>
        {props.children}
      </CardContent>
    </Card>
  );
};

CustomCard.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  actionButton: PropTypes.node,
  title: PropTypes.string,
  showChip: PropTypes.bool,
  dataLength: PropTypes.number
};

export default CustomCard;
