import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import { formatISO, getYear, getMonth, getDate } from "date-fns";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import {
  getActivityGroupIdForSite,
  GetDisplayGroupById,
  UpsertDisplayGroup,
} from "../../../state/actions/MyDisplaysActions";
import {
  createActivity,
  createTarget,
  getActivityById,
  updateTarget,
  getDropdownLookups,
} from "../../../state/actions/ActivityActions";
import { Loading } from "../../../components/loading/Loading";
import { getApiConfig } from "../../../config/apiConfig";

const API_CONFIG = getApiConfig();

const NewDisplay = (props) => {
  const dispatch = useDispatch();
  const displayGroupId = props.match.params.DisplayGroupId;

  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);

  const displayGroup = useSelector(
    (state) => state.MyDisplaysReducer.displayGroup
  );

  const currentActivity = useSelector(
    (state) => state.ActivityReducer.currentActivity
  );

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const activityGroupId = useSelector((state) => state.MyDisplaysReducer.activityGroupId);

  let createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const [loading, setLoading] = useState(false);
  const [newDisplayActivity, setNewDisplayActivity] = useState({});
  const [existingDisplayGroup, setExistingDisplayGroup] =
    useState(displayGroup);
  const [upsertError, setUpsertError] = useState(false);
  const [targetSuccess, setTargetSuccess] = useState(false);

  useEffect(() => {
    console.log(newDisplayActivity);
  }, [newDisplayActivity]);

  useEffect(() => {
    const activityData = {
      Activity: {
        ActivityDataTypeId: 15,
        ActivityFrequencyId: null,
        ActivityGroupId: activityGroupId,
        ActivityTypeId: null,
        ClientId: null,
        EndDate: null,
        IsActive: true,
        IsOneTimeAnswer: false,
        IsRequired: false,
        Max: 99,
        Min: 0,
        ModifiedBy: createdUser,
        Name: null,
        Sequence: 1,
        StartDate: null,
        CreatedBy: createdUser,
      },
    };

    setNewDisplayActivity(activityData);
  }, [activityGroupId]);

  useEffect(() => {
    if (targetSuccess === true) {
      dispatch(
        UpsertDisplayGroup(existingDisplayGroup, (success) => {
          if (success) {
            props.history.push(
              `/my-displays-maintenance/${existingDisplayGroup.GroupId}`
            );
          } else {
            setUpsertError(true);
          }
        })
      );
    }
  }, [targetSuccess]);

  useEffect(() => {
    dispatch(GetDisplayGroupById(displayGroupId, () => {}));
  }, []);

  useEffect(() => {
    dispatch(
      getActivityById(displayGroup.Displays[0].DisplayTypeActivityId, () => {})
    );
    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => {
        setLoading(true);
      })
    );
  }, [displayGroup]);

  useEffect(() => {
    dispatch(
      getActivityGroupIdForSite(
        loggedInUser.territory === "au" ? 1 : 2,
        () => {}
      )
    );
  }, [currentActivity]);

  const getActivityTypeName = () => {
    const activityType = lookupData.ActivityTypes.find(
      (item) =>
        item.Id === newDisplayActivity.Activity.ActivityTypeId.toString()
    );

    return activityType.DisplayName;
  };

  const getDataTypeName = () => {
    const dataType = lookupData.ActivityDataTypes.find(
      (item) =>
        item.Id === newDisplayActivity.Activity.ActivityDataTypeId.toString()
    );
    return dataType;
  };

  const handleNameChange = (e) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.Name = e.target.value;
    setNewDisplayActivity(localActivity);
  };

  const handleActivityTypeChange = (e, value) => {
    let selectedItem = lookupData.ActivityTypes.find(
      (type) => type.DisplayName === value
    );
    console.log(selectedItem.Id);
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.ActivityTypeId = parseInt(selectedItem.Id);
    setNewDisplayActivity(localActivity);
  };

  const handleStartDateChange = (date) => {
    let localActivity = { ...newDisplayActivity };
    if (isNaN(date.getTime())) {
      //
    } else {
      localActivity.Activity.StartDate =
        formatISO(
          new Date(getYear(date), getMonth(date), getDate(date), 0, 0, 52)
        ).split("+")[0] + "Z";
      setNewDisplayActivity(localActivity);
    }
  };

  const handleEndDateChange = (date) => {
    let localActivity = { ...newDisplayActivity };
    if (isNaN(date.getTime())) {
      //
    } else {
      localActivity.Activity.EndDate =
        formatISO(
          new Date(getYear(date), getMonth(date), getDate(date), 0, 0, 52)
        ).split("+")[0] + "Z";
      setNewDisplayActivity(localActivity);
    }
  };

  const handleFrequencyChange = (e, value) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.ActivityFrequencyId = value.Id;
    setNewDisplayActivity(localActivity);
  };

  const handleDataTypeChange = (e, value) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.ActivityDataTypeId = value.Id;
    setNewDisplayActivity(localActivity);
  };

  const handleMinChange = (e) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.Min = e.target.value;
    setNewDisplayActivity(localActivity);
  };

  const handleMaxChange = (e) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.Max = e.target.value;
    setNewDisplayActivity(localActivity);
  };

  const handleSequenceChange = (e) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.Sequence = e.target.value;
    setNewDisplayActivity(localActivity);
  };

  const handleRequiredChange = (e) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.IsRequired = e.target.checked;
    setNewDisplayActivity(localActivity);
  };

  const handleOTAChange = (e) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.IsOneTimeAnswer = e.target.checked;
    setNewDisplayActivity(localActivity);
  };

  const handleActiveChange = (e) => {
    let localActivity = { ...newDisplayActivity };
    localActivity.Activity.IsActive = e.target.checked;
    setNewDisplayActivity(localActivity);
  };

  const flatten = (item) => {
    let output = {};
    for (const i in item) {
      if (typeof item[i] === "object" && !Array.isArray(item[i])) {
        const temp = flatten(item[i]);
        for (const j in temp) {
          output[j] = temp[j];
        }
      } else {
        output[i] = item[i];
      }
    }
    return output;
  };

  const submitActivity = () => {
    let newTarget = currentActivity.targets[0];

    dispatch(
      createActivity(newDisplayActivity, (success, activityId) => {
        dispatch(
          updateTarget(
            activityId,
            newTarget.ActivityTargetId,
            newTarget,
            (success) => {
              if (success) {
                setTargetSuccess(true);
              }
            }
          )
        );
        newDisplayActivity.Activity.Id = activityId;
        const flattenedObj = flatten(newDisplayActivity);
        existingDisplayGroup.Displays.push({
          Activities: [
            {
              MyDisplaysActivityId: activityId,
            },
          ],
          DisplayTypeActivity: { ...flattenedObj },
          DisplayTypeActivityId: activityId,
        });
      })
    );
  };

  const upsertAlertElement = upsertError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Failed to create new display activity
      <strong> Please contact support</strong>
    </Alert>
  ) : null;

  return (
    <div>
      {loading ? (
        <>
          {" "}
          <HeaderBlock
            title={"New Display Activity"}
            right={
              <ButtonGroup
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "35px",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    props.history.push(
                      `/my-displays-maintenance/${displayGroupId}`
                    )
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={submitActivity}
                >
                  Save
                </Button>
              </ButtonGroup>
            }
          />
          {upsertAlertElement}
          <div style={{ marginBottom: "2.5rem" }}>
            <h2 style={{ marginTop: "150px", marginLeft: "2.5rem" }}>
              Display Activity
            </h2>
            <div className="create-container" style={{ marginTop: "20px" }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label={"Name"}
                    onChange={handleNameChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={
                      newDisplayActivity.Activity.ActivityTypeId
                        ? getActivityTypeName()
                        : null
                    }
                    options={lookupData.ActivityTypes.filter(
                      (type) =>
                        type.Id === API_CONFIG.displayActivityType ||
                        type.Id === API_CONFIG.competitorActivityType
                    ).map((item) => item.DisplayName)}
                    onChange={(e, value) => handleActivityTypeChange(e, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        label="Activity Type"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Effective From"
                      value={newDisplayActivity.Activity.StartDate}
                      onChange={handleStartDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      label="Effective to"
                      value={newDisplayActivity.Activity.EndDate}
                      onChange={handleEndDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={lookupData.ActivityFrequencies}
                    getOptionLabel={(option) => option.DisplayName}
                    onChange={handleFrequencyChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        label={"Frequency"}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    disabled
                    id="combo-box-demo"
                    options={lookupData.ActivityDataTypes}
                    getOptionLabel={(option) => option.DisplayName}
                    onChange={handleDataTypeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        label={"Data Type"}
                        placeholder={getDataTypeName().DisplayName}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label="Min"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleMinChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label="Max"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={handleMaxChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    label={"Enter Sequence"}
                    onChange={handleSequenceChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <span>Required</span>
                  <Checkbox
                    color="primary"
                    checked={newDisplayActivity.Activity.IsRequired}
                    onChange={handleRequiredChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <span>One Time Answer</span>
                  <Checkbox
                    color="primary"
                    checked={newDisplayActivity.Activity.IsOneTimeAnswer}
                    onChange={handleOTAChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <span>Active</span>
                  <Checkbox
                    color="primary"
                    checked={newDisplayActivity.Activity.IsActive}
                    onChange={handleActiveChange}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const hoc = withRouter(NewDisplay);

// EXPORT COMPONENT
export { hoc as NewDisplay };
