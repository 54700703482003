import { Box, Button, IconButton, TextField } from '@material-ui/core';
import { RemoveCircleOutline, Email } from '@material-ui/icons';
import styles from './programmeDetailList.module.scss';
import React from 'react';
import { ProgrammeAction } from '../reducers/programme-reducer';


interface AlertEmailsListProps {
  emails: string[];
  handleUpdateAlertEmails: (action: ProgrammeAction) => void;
}

function AlertEmailsList(props: AlertEmailsListProps) {
  const {
    emails,
    handleUpdateAlertEmails,
  } = props;

  const [newAlertEmail, setNewAlertEmail] = React.useState('');

  function handleAddEmail(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if(!newAlertEmail.length) return;
    handleUpdateAlertEmails({type:'ADD_ALERT_EMAIL', payload: { value: newAlertEmail}});
    setNewAlertEmail('');
  }

  function renderEmailsList(emails: string[]){
    return (
      emails.length && emails.map((email, index) => {
        return (
          <li key={index}>
            <Box color='text.secondary' display='flex'><Email /></Box>
            <span className="email">{email}</span>
            <Box flex='0' ml='auto'>
              <IconButton onClick={(e) => handleUpdateAlertEmails({type: 'REMOVE_ALERT_EMAIL', payload: { index }})}>
                <RemoveCircleOutline fontSize='small' color="primary" />
              </IconButton>
            </Box>
          </li>
        )
      })
    )
  }


  return (
    <div>
      <ul className={styles.programmeDetailList}>
        { emails?.length ? renderEmailsList(emails) : <li><p style={{color: 'var(--secondary-font)'}}>No alert emails added</p></li> }
      </ul>

      <div className={styles.programmeDetailList_extension}>
        <Box display='flex'>
          <Box flex='1'>
            <TextField value={newAlertEmail} size='small' label="Email" variant="outlined" onChange={ e => setNewAlertEmail(e.target.value) } name='email' fullWidth helperText="Must be in the format: EMAIL GROUP <EMAIL.GROUP@ccamatil.com>"/>
          </Box>
          <Box flex='0'>
            <Button disabled={!newAlertEmail.length} variant="outlined" color="secondary" onClick={handleAddEmail} style={{flex: 0, alignSelf: 'start', margin: '2px 0 0 0.75rem'}}>Add</Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default AlertEmailsList;