import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter"
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./Table.module.scss";
import { Chip, TextField, FormControlLabel } from "@material-ui/core";
import { UUID } from "../../helpers/uuid";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function SimpleTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    rows,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  const isEveryKeyTrue = rows.every(function (obj) {
    return obj.hasOwnProperty('isDisabled') && obj.isDisabled === true;
  });
  return (
    <TableHead>
      <TableRow>
        {props.isSelectable && (
          <TableCell padding="checkbox">
            <Checkbox
              disabled={(props.isEditing !== undefined && !props.isEditing) || (isEveryKeyTrue)}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onClick={() => onSelectAllClick(numSelected === rowCount)}
            />
          </TableCell>
        )}
        {props.headers.map((row) => (
          <TableCell
            key={row.id}
            align={row.align}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    borderRadius: 0,
    boxShadow: "none",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent"
  },
  tableWrapper: {
    overflowX: "auto",
    paddingBottom: "90px"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 0,
    width: 1
  }
}));

export default function SimpleTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("userId");
  const [stateSelected, setStateSelected] = React.useState(
    props.selected || []
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(isChecked) {
    if (!isChecked) {
      let rows;
      if (props.rows.find(item => item.hasOwnProperty('isDisabled'))) {
        const filterSelecteds = props.rows.filter(item => item.hasOwnProperty('isDisabled') && item?.isDisabled === false)
        rows = filterSelecteds.map((n) => n[props.dataId]);
      } else {
        rows = props.rows.map((n) => n[props.dataId]);
      }

      const newSelecteds = props.checkDefault
        ? rows.filter((n) => !props.checkDefault(n))
        : rows;

      props.handleSelection(newSelecteds);
      setStateSelected(newSelecteds);
      return;
    }

    props.handleSelection([]);
    setStateSelected([]);
  }

  function handleCheck(event, name) {
    const selected = props.selected != null ? props.selected : stateSelected;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    props.handleSelection(newSelected);
    setStateSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function getColumnSum(columnName) {
    const findColumnName = props?.sumColumns.find(obj => obj.id === columnName)
    if (findColumnName) {
      return parseFloat(props.rows.reduce((sum, row) => sum + row[columnName], 0)).toFixed(2);
    }
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function createRowItem(rowData, type, onChange) {
    if (type === "tag") {
      return <Chip label={String(rowData)} className={classes.chip} />;
    } else if (type === "sequence") {
      return (
        <TextField
          disabled={!props.isEditing}
          value={rowData}
          onChange={(event) => onChange(event.target.value)}
          margin="dense"
          variant="outlined"
        />
      );
    } else if (type === "checkbox") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              disabled={!props.isEditing}
              checked={rowData}
              onChange={(event) => onChange(event.target.checked)}
              value="checkedB"
              color="primary"
            />
          }
          label="Is Category"
        />
      );
    } else {
      return rowData;
    }
  }

  const isSelected = (name) =>
    (props.selected != null ? props.selected : stateSelected).indexOf(name) !==
    -1;
  const checkMaintainRRCTable = () => {
    return props.rows.find(obj => obj.hasOwnProperty('isDisabled'))
  }
  const getMaintainRRCTableEnableRows = () => {
    const rowsCount = props.rows.filter(obj => obj.hasOwnProperty('isDisabled') && obj.isDisabled === false)
    if (rowsCount.length > 0) {
      return rowsCount.length;
    } else {
      return props.rows.length
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={styles.table} aria-labelledby="tableTitle">
          <SimpleTableHead
            classes={classes}
            numSelected={
              (props.selected != null ? props.selected : stateSelected).length
            }
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={checkMaintainRRCTable() ? getMaintainRRCTableEnableRows() : props.rows.length}
            rows={props.rows}
            headers={props.headers}
            isSelectable={props.isSelectable}
            isEditing={props.isEditing}
          />
          <TableBody>
            {stableSort(props.rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row[props.dataId]);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    className={styles.tableRow}
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    onClick={() => props.handleRowClick ? props.handleRowClick(row) : {}}
                  >
                    {props.isSelectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={
                            (props.isEditing !== undefined &&
                              !props.isEditing) ||
                            (props.checkDefault
                              ? props.checkDefault(row[props.dataId])
                              : false) || (!props.disableEditRole && row.isDisabled)
                          }
                          onClick={(event) =>
                            handleCheck(event, row[props.dataId])
                          }
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                    )}
                    {props.cells.map((cell) => {
                      if (
                        createRowItem(
                          row[cell.id] != null
                            ? row[cell.id]
                            : row[cell.secondaryId],
                          cell.type,
                          (data) => cell.onChange(data, row)
                        ) === "missing"
                      ) {
                        return (
                          <TableCell key={UUID()} align={cell.align}>
                            <em>
                              <span
                                onClick={(event) => {
                                  if (cell.clickable)
                                    cell.handleClick(event, row);
                                }}
                                className={cell.clickable ? styles.rowName : ""}
                              >
                                {createRowItem(
                                  row[cell.id] != null
                                    ? row[cell.id]
                                    : row[cell.secondaryId],
                                  cell.type,
                                  (data) => cell.onChange(data, row)
                                )}
                              </span>
                            </em>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={UUID()} align={cell.align}>
                            <span
                              onClick={(event) => {
                                if (cell.clickable)
                                  cell.handleClick(event, row);
                              }}
                              className={cell.clickable ? styles.rowName : ""}
                            >
                              {createRowItem(
                                row[cell.id] != null
                                  ? row[cell.id]
                                  : row[cell.secondaryId],
                                cell.type,
                                (data) => cell.onChange(data, row)
                              )}
                            </span>
                          </TableCell>
                        );
                      }

                    })}
                  </TableRow>
                );
              })}
          </TableBody>
          {props.isfooterDetailsVisible ? <TableFooter>
            <TableRow>
              {props.headers.map((column) => (
                column.id === props?.replaceColumn ?
                  <TableCell><h3>Total</h3></TableCell> : <TableCell key={column.id}><h3>{getColumnSum(column.id)
                  }</h3></TableCell>
              ))}
            </TableRow>
          </TableFooter> : null}
        </Table>
      </div>
      <TablePagination
        className={styles.tablePagination}
        rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page"
        }}
        nextIconButtonProps={{
          "aria-label": "next page"
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
