// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import {
  Grid
} from "@material-ui/core";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import FileDrop from "../../components/file-drop/FileDrop2";
import { Loading } from "../../components/loading/Loading";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { FileType } from "../../state/constants/FileType";

const MassContentUploadSegment = ({
  genericContentData,
  setGenericContentData,
  setDataChanged,
  title,
  isNewContent,
  isEditing,
  formEdited,
  setFormEdited,
  isContentAdmin,
  userTerritory
}) => {
  const handleInputChange = (name, value) => {
    const localData = genericContentData;
    value.forEach(file => {
      localData[name].push({
        errorMessage: "",
        label: "File",
        required: true,
        type: "file",
        value: file
      });
    });

    if (!formEdited) {
      setFormEdited(true);
    }

    setGenericContentData(localData);
    setDataChanged(true);
  };

  return (
    <CustomCard title={title} actionButton={[]}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{
            display: isNewContent ? "block" : "none"
          }}
        >
          <FileDrop
            enforceTypes={[]}
            enforceTypesMessage={
              ""
            }
            maxsize={30 * 1024 * 1024} //30mb
            message="Add files"
            onFilesAdded={(file) => handleInputChange("file", file)}
            file={genericContentData.file}
            multiple={true}
            errorMessage={
              genericContentData.prefile[0] && genericContentData.prefile[0].errorMessage
            }
          />
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default MassContentUploadSegment;
