export const MyccaContentModel = () => {
    return {
      id: {
        type: "string",
        required: false,
        errorMessage: "",
        label: "Id"
      },
      code: {
        type: "string",
        required: true,
        errorMessage: "",
        label: "Code"
      },
      description: {
        type: "string",
        required: true,
        errorMessage: "",
        label: "Description"
      },
      endDate: {
        type: "date",
        required: true,
        errorMessage: "",
        label: "End Date"
      },
      contentType: {
        type: "select",
        required: true,
        errorMessage: "",
        label: "Content Type"
      },
      retentionPolicy: {
        type: "select",
        required: true,
        errorMessage: "",
        label: "Retention Policy"
      },
      site: {
        type: "select",
        required: true,
        errorMessage: "",
        label: "Site"
      }
    };
  };
  