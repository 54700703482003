import { ContentType } from "../state/constants/ContentType";
var moment = require("moment");
moment().format();

export const GenericContentModel = () => {
  return {
    contentId: {
      type: "string",
      value: "",
      required: false,
      errorMessage: "",
      label: "Content Id"
    },
    file: {
      type: "file",
      value: null,
      required: true,
      errorMessage: "",
      label: "File"
    },
    title: {
      type: "text",
      value: "",
      required: true,
      errorMessage: "",
      label: "Title"
    },
    contentType: {
      type: "select",
      value: {},
      required: true,
      errorMessage: "",
      label: "Content Type"
    },
    contentTypeLabel: {
      type: "text",
      value: {},
      required: false,
      errorMessage: "",
      label: "Content Type Label"
    },
    category: {
      type: "select",
      value: {},
      required: false,
      errorMessage: "",
      label: "Category"
    },
    retentionPolicy: {
      type: "select",
      value: {},
      required: true,
      errorMessage: "",
      label: "Retention Policy"
    },
    internal: {
      type: "checkbox",
      value: true,
      required: false,
      errorMessage: "",
      label: "Internal"
    },
    active: {
      type: "checkbox",
      value: true,
      required: false,
      errorMessage: "",
      label: "Active"
    },
    description: {
      type: "text",
      value: "",
      required: true,
      errorMessage: "",
      label: "Description"
    },
    keywords: {
      type: "text",
      value: "",
      required: false,
      errorMessage: "",
      label: "Keywords"
    },
    language: {
      type: "select",
      value: "",
      required: true,
      errorMessage: "",
      label: "Language"
    },
    territory: {
      type: "select",
      value: "",
      required: true,
      errorMessage: "",
      label: "Territory"
    },
    startDate: {
      type: "date",
      value: new Date(),
      required: true,
      errorMessage: "",
      comparator: "<",
      compareTo: "endDate",
      label: "Start Date"
    },
    endDate: {
      type: "date",
      value: new Date(),
      required: true,
      errorMessage: "",
      comparator: ">",
      compareTo: "startDate",
      label: "End Date"
    }
  };
};

export const createContentModelRequestBody = (genericContentData, uName) => {
  return {
    typeId: genericContentData.contentType.value,
    categoryId: genericContentData.category.value,
    isInternal: genericContentData.internal.value,
    isActive: genericContentData.active.value,
    title: genericContentData.title.value,
    description: genericContentData.description.value,
    startdate: new Date(genericContentData.startDate.value).toISOString(),
    enddate: new Date(genericContentData.endDate.value).toISOString(),
    territory: genericContentData.territory.value,
    languages: genericContentData.language.value,
    keywords: genericContentData.keywords.value
      .split("|")
      .join(",")
      .split(",")
      .map(Function.prototype.call, String.prototype.trim),
    retentionPolicyId: genericContentData.retentionPolicy.value,
    UploadedBy: uName,
    UploadOn: new Date().toISOString(),
    ModifiedBy: uName,
    ModifiedOn: new Date().toISOString(),
  };
};

export const createMassMigrationRequestBody = (row, territory) => {
  return {
    typeId: row.ContentType,
    categoryId: "",
    isInternal: "",
    isActive: true,
    title: row.Title,
    description: row.Description,
    startdate: moment(row.DateFrom, "DD/MM/YYYY").toISOString(),
    enddate: moment(row.DateTo, "DD/MM/YYYY").toISOString(),
    territory: territory,
    languages: row.ContentLanguage,
    keywords: row.Keywords
      .split("|")
      .join(",")
      .split(",")
      .map(Function.prototype.call, String.prototype.trim),
    retentionPolicyId: row.RetentionPolicy,
    fileLocation: row.FileName,
    isInternal: row.isInternal
  };
};
