// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

// UI and Styling
import { Grid, makeStyles, TextField } from "@material-ui/core";

// Actions
import {
  getAllTargetedContentForPos,
  setCurrentPosItem
} from "../../state/actions/ThreeDimensionalPOSActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../components/table/Table";

// Other
import { getIcon } from "../../icon/icon";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  }
}));

// Component entry point
const ListThreeDimensionalPOSPage = (props) => {
  const classes = useStyles();

  const posTargetedContent = useSelector(
    (state) => state.ThreeDimensionalPOSReducer.posTargetedContent
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const setupData = () => {
    const data = createData(posTargetedContent);

    setOriginalData(data);
    setSearchedData(data);
  };

  useEffect(() => {
    setLoading(true);

    dispatch(
      getAllTargetedContentForPos(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setupData();
  }, [posTargetedContent]);

  const createData = (content) => {
    return content.map((tc) => {
      return {
        id: tc.ContentId,
        name: tc.ContentTitle,
        description: tc.ContentDescription
      };
    });
  };

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    if (text === "") {
      setSearchedData(originalData);
    }
    const foundData = originalData.filter((data) => {
      return (
        (data.name && data.name.toLowerCase().includes(text)) ||
        (data.id && data.id.toLowerCase().includes(text))
      );
    });

    setSearchedData(foundData);
  };

  const createHeaders = () => {
    return [
      {
        id: "name",
        clickable: true,
        action: {
          path: `/three-dimensional-pos/{id}`,
          identifier: "id"
        },
        align: "left",
        label: "Targeted Content Name"
      },
      {
        id: "description",
        align: "left",
        label: "Description"
      }
    ];
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          //Get the hasSteps state of the current item
          const posItem = posTargetedContent.find(
            (item) => item.ContentId === row[header.action.identifier]
          );

          //Store the item clicked in state so it is easier to know
          //whether to show the create or edit page for this item
          dispatch(
            setCurrentPosItem(posItem, () => {
              let path = header.action.path.replace(
                "{id}",
                row[header.action.identifier]
              );
              props.history.push(path);
            })
          );
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    if (searchedData.length > 0) {
      return (
        <SimpleTable
          dataId={"id"}
          cells={cells}
          headers={headers}
          rows={searchedData}
        />
      );
    } else {
      return (
        <PagePlaceholder
          image={getIcon("seo-consulting.svg")}
          text={"No 3D POS Items Found."}
        />
      );
    }
  };

  return (
    <div>
      <BreadcrumbsItem to="/three-dimensional-pos">
        3D POS Manager
      </BreadcrumbsItem>
      <HeaderBlock
        title={"3D POS Manager"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                id="outlined-name"
                label="Search 3D POS Items"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <ExportExcel
              csvData={originalData}
              fileName="3d-pos-items"
              classes={classes.rightMargin}
            />
          </Grid>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ListThreeDimensionalPOSPage);

// EXPORT COMPONENT
export { hoc as ListThreeDimensionalPOSPage };
