import { AnyAction } from "redux";
import { UUID } from "../../helpers/uuid";
import {
  GET_DISPLAY_GROUPS,
  GET_DISPLAY_GROUP,
  UPSERT_DISPLAY_GROUP,
  GET_DISPLAY_ACTIVITY_BY_ID,
  GET_DISPLAY_ACTIVITIES_BY_ID,
  CREATE_DISPLAY_ACTIVITY,
  GET_PRODUCT_FILTER_RULES,
  UPSERT_PRODUCT_FILTERS,
  RESET_DISPLAY_GROUP_ACTIVITES,
  GET_ACTIVITY_GROUP_ID_FOR_SITE,
  ADD_TARGET_TO_DISPLAY_GROUP,
} from "../constants/action-types";

export interface MyDisplaysReducerState {
  displayGroupList: any;
  displayGroup: any;
  displayActivity: any;
  displayActivities: any;
  productFilterRules: any;
  activityTarget: any;
  currentFilters: any;
  activityGroupId: number | null;
  displayTarget: any;
}

// INITIALIZE STATE
const initialState = {
  displayGroupList: [],
  displayGroup: {},
  displayActivity: {},
  displayActivities: [],
  productFilterRules: [],
  activityTarget: {},
  currentFilters: [],
  activityGroupId: null,
  displayTarget: {},
};

const productFilters = [
  "CategoryId",
  "ProductTypeId",
  "MaterialGroup1Id",
  "BrandId",
  "FlavourId",
  "PackTypeId",
  "PackSizeId",
  "MaterialNameSearch",
  "MaterialNumber",
  "MaterialTypeId",
];

const createFilterFromResponse = (response: any) => {
  //let productFilter: any = {};
  let productFilterArray: any = [];
  if (response) {
    response.forEach((pf: any) => {
      productFilters.forEach((rule) => {
        if (pf[rule] !== null && pf[rule] !== undefined && pf[rule] !== "") {
          let productFilter: any = {
            id: UUID(),
            isActive: true,
            regexError: true,
            charError: false,
          };
          //@ts-ignore
          productFilter["ruleType"] = rule;
          //@ts-ignore
          productFilter["ruleValue"] = pf[rule];
          if (rule === "MaterialNameSearch") {
            let tmpRule: any = rule.split(/(?=[A-Z])/);
            tmpRule.pop();
            tmpRule = tmpRule.join("");
            productFilter["ruleOperator"] = pf[tmpRule + "Operator"];
          } else {
            //@ts-ignore
            productFilter["ruleOperator"] = pf[rule + "Operator"];
          }
          productFilterArray.push(productFilter);
          productFilter = {};
        }
      });
    });
  } else {
    productFilterArray = [];
  }
  return productFilterArray;
};

// REDUCER
export const MyDisplaysReducer = (
  state = initialState,
  action: AnyAction
): MyDisplaysReducerState => {
  switch (action.type) {
    case GET_DISPLAY_GROUPS: {
      return {
        ...state,
        displayGroupList: action.payload,
      };
    }
    case GET_DISPLAY_GROUP: {
      return {
        ...state,
        displayGroup: action.payload,
      };
    }
    case UPSERT_DISPLAY_GROUP: {
      return {
        ...state,
        displayGroup: action.payload,
      };
    }
    case GET_DISPLAY_ACTIVITY_BY_ID: {
      return {
        ...state,
        displayActivity: action.payload,
      };
    }
    case GET_DISPLAY_ACTIVITIES_BY_ID: {
      return {
        ...state,
        displayActivities: action.payload,
      };
    }
    case CREATE_DISPLAY_ACTIVITY: {
      return {
        ...state,
        displayActivity: action.payload,
      };
    }
    // FIXME: What field was this for?
    case GET_PRODUCT_FILTER_RULES: {
      const currentFilters = createFilterFromResponse(action.payload);
      return {
        ...state,
        currentFilters,
      };
    }
    case UPSERT_PRODUCT_FILTERS: {
      return {
        ...state,
        productFilterRules: action.payload,
      };
    }
    case RESET_DISPLAY_GROUP_ACTIVITES: {
      return {
        ...state,
        activityTarget: [],
        displayGroup: {},
        displayActivity: {},
        displayActivities: [],
      };
    }
    case GET_ACTIVITY_GROUP_ID_FOR_SITE: {
      return {
        ...state,
        activityGroupId: action.payload.value,
      };
    }
    case ADD_TARGET_TO_DISPLAY_GROUP: {
      return {
        ...state,
        displayTarget: action.payload,
      };
    }

    default:
      return state;
  }
};
