import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import HeaderBlock from "../../../components/header-block/HeaderBlock";

import { getActivityById } from "../../../state/actions/MyDisplaysActions";
import {
  getChoiceById,
  updateChoice,
} from "../../../state/actions/ActivityActions";

import { Loading } from "../../../components/loading/Loading";

const ChoicePage = (props) => {
  const dispatch = useDispatch();
  const displayGroupId = props.match.params.DisplayGroupId;
  const displayIndex = props.match.params.DisplayIndex;
  const activityId = props.match.params.ActivityId;
  const choiceName = props.match.params.ChoiceName;

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const currentActivity = useSelector(
    (state) => state.MyDisplaysReducer.displayActivity
  );

  const currentChoice = useSelector(
    (state) => state.ActivityReducer.currentChoice
  );

  let createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [existingChoice, setExistingChoice] = useState({});

  useEffect(() => {
    dispatch(getActivityById(activityId, () => {}));
  }, []);

  useEffect(() => {
    dispatch(
      getChoiceById(activityId, choiceName, () => {
        setLoading(true);
      })
    );
  }, [currentActivity]);

  useEffect(() => {
    setExistingChoice(currentChoice);
  }, [currentChoice]);

  const handleNameChange = (e) => {
    let localChoice = { ...existingChoice };
    localChoice.name = e.target.value;
    setExistingChoice(localChoice);
  };
  const handleWeightChange = (e) => {
    let localChoice = { ...existingChoice };
    localChoice.weight = e.target.value;
    localChoice.nonRangingWeight = parseInt(e.target.value);
    setExistingChoice(localChoice);
  };

  const handleActiveChange = (e) => {
    let localChoice = { ...existingChoice };
    localChoice.active = e.target.checked;
    setExistingChoice(localChoice);
  };

  const createRequestBody = () => {
    const requestBody = {
      ActivityId: activityId,
      BeverageCategory: null,
      Brand: null,
      Image1: null,
      Image2: null,
      Image3: null,
      IsActive: existingChoice.active,
      Material: null,
      MinimumVariants: 0,
      ModifiedBy: loggedInUser.userId,
      Name: existingChoice.name,
      PackSize: null,
      PackType: null,
      SelectionType: 1,
      SubText: null,
      Weight: existingChoice.weight,
    };

    return requestBody;
  };

  const submitChoice = () => {
    dispatch(
      updateChoice(
        activityId,
        currentChoice.name,
        createRequestBody(),
        (success) => {
          if (success) {
            props.history.push(
              `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}`
            );
          }
        }
      )
    );
  };

  const buttonGroupElement = isEditing ? (
    <ButtonGroup style={{ marginRight: "35px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setIsEditing(false)}
      >
        Cancel
      </Button>
      <Button variant="outlined" color="secondary" onClick={submitChoice}>
        Save
      </Button>
    </ButtonGroup>
  ) : (
    <ButtonGroup>
      <Button
        variant="outlined"
        color="primary"
        onClick={() =>
          props.history.push(
            `/my-displays-maintenance/${displayGroupId}/${displayIndex}/${activityId}`
          )
        }
      >
        Cancel
      </Button>
      <Button
        style={{ marginRight: "35px" }}
        variant="outlined"
        color="secondary"
        onClick={() => setIsEditing(true)}
      >
        Edit
      </Button>
    </ButtonGroup>
  );

  return (
    <div>
      {loading ? (
        <>
          <HeaderBlock
            title={`${currentActivity.Activity.Name} - ${existingChoice.name}`}
            right={
              <Grid
                container
                spacing={3}
                alignItems="center"
                justify="flex-end"
              >
                {buttonGroupElement}
              </Grid>
            }
          />
          <div className="create-container" style={{ marginTop: "150px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  disabled={!isEditing}
                  label={"Name"}
                  placeholder="Choice Name"
                  value={existingChoice.name}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  type="number"
                  label={"Weight"}
                  placeholder="Enter Weight"
                  value={existingChoice.weight}
                  InputLabelProps={{ shrink: true }}
                  disabled={!isEditing}
                  onChange={handleWeightChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography>Active</Typography>
                <Checkbox
                  color="primary"
                  disabled={!isEditing}
                  checked={existingChoice.active}
                  onChange={handleActiveChange}
                />
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const hoc = withRouter(ChoicePage);

// EXPORT COMPONENT
export { hoc as ChoicePage };
