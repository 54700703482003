// React
import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { getApiConfig } from "../../config/apiConfig";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./MyCCAContentPage.module.scss";
import GetAppIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/Link';

// Actions
import {
  getMyccaContentById,
  getAllMyccaLookups,
  updateMyccaContent
} from "../../state/actions/MyCCAResourceImageManagerActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";
import { createNewContent } from "../../state/reducers/MyCCAResourceImageManagerReducer";
import copy from 'copy-to-clipboard';


// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import FileDrop from "../../components/file-drop/FileDrop";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";

// Models
import { MyccaContentModel } from "../../models/MyccaContentModel"
// Other
import DateFnsUtils from "@date-io/date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";
import { isValid, format, startOfDay } from "date-fns";
import { sortDropdown } from "../../helpers/sortDropdown";
import { Territories, GetSite } from "../../state/constants/Territories";

import moment from 'moment';
moment().format();

const API_CONFIG = getApiConfig();

/*
    Component entry point
*/
const MyCCAContentPage = (props) => {
  // Reducer "logic"
  const currentMyccaContent = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.currentMyccaContent
  );

  const lookups = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.lookups
  );

  const loggedInUser = useSelector(
    (state) => state.UserReducer.loggedInUser
  );

  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  // State Logic
  const [loading, setLoading] = useState(false);
  const [targetedContent, setTargetedContent] = useState({});
  const [uneditedTargetedContent, setUneditedTargetedContent] = useState({});
  const [
    cancelTargetedContentEditingAlertOpen,
    setCancelTargetedContentEditingAlertOpen
  ] = useState(false);
  const [isEditing, setIsEditing] = useState(
    props.type != null && props.type !== FormType.VIEW
  );
  const [formProperties, setFormProperties] = useState(MyccaContentModel);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [formDataUpdated, setFormDataUpdated] = useState(false);
  const [isNewContent, setIsNewContent] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [categoryLookupData, setCategoryLookupData] = useState([]);
  const [languageLookupData, setLanguageLookupData] = useState([]);
  const [retentionPolicyLookupData, setRetentionPolicyLookupData] = useState(
    []
  );
  const [typeLookupData, setTypeLookupData] = useState([]);
  const [selectedContentTypeId, setSelectedContentTypeId] = useState("");
  const [ignoreMaxFileSize, setIgnoreMaxFileSize] = useState(false);
  const [isContentAdmin, setIsContentAdmin] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [sizeLimit, setSizeLimit] = useState(9999);
  const [
    getAllMyccaLookupsCallMade,
    setgetAllMyccaLookupsCallMade
  ] = useState(false);
  const [getTargetedContentCallMade, setGetTargetedContentCallMade] = useState(
    false
  );
  const baseUrl = process.env.REACT_APP_API_URL;
  const assetBaseUrl = process.env.REACT_APP_RESOURCE_URL;

  const targetedContentId = props.match.params.contentId;

  useEffect(() => {
    setLoading(true);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }

    //Get all dropdown lookup data
    dispatch(getAllMyccaLookups(() => {
      setgetAllMyccaLookupsCallMade(true);
    }));

    //If not creating
    if (props.type != null && props.type === FormType.VIEW) {
      setIsEditing(false);
      setIsNewContent(false);
      dispatch(

        getMyccaContentById(targetedContentId, () => {
          setGetTargetedContentCallMade(true);
        })
      );
    } else {
      setIsEditing(true);
      setIsNewContent(true);

      dispatch(
        getMyccaContentById(undefined, () => {
          setGetTargetedContentCallMade(true);
        })
      );
    }
    // return () => {
    //   dispatch(resetLocalTargetedContent(() => { }));
    // };
  }, []);

  useEffect(() => {
    if (
      loggedInUserRoles !== undefined &&
      (loggedInUserRoles.includes("Content Admin") ||
        loggedInUserRoles.includes("SaM Core Admin") ||
        loggedInUserRoles.includes("Content Manager") ||
        loggedInUserRoles.includes("Content Targeting Manager"))
    ) {
      setIsContentAdmin(true);
    } else {
      setIsContentAdmin(false);
    }
  }, [loggedInUserRoles]);

  useEffect(() => {
  }, [targetedContent])

  useEffect(() => {
    if (loggedInUser != null) {
      let localData = targetedContent;
      if (Object.keys(localData).length === 0) { localData = createNewContent() }
      localData["site"] = loggedInUser.site;
      setTargetedContent(localData);
    }
  }, [loggedInUser]);

  useEffect(() => {
    const localData = currentMyccaContent;
    if (isNewContent) {
      localData.site = loggedInUser.site;
    }
    setTargetedContent(localData);
    // Need to set the link text if the content type is Links
    if (
      targetedContent.contentType &&
      typeLookupData.find((type) => type.Id === targetedContent.contentType)
        .DisplayName === "Links"
    ) {
      localData["link"] =
        !isNewContent && currentMyccaContent.file !== null
          ? currentMyccaContent.file[0].resourceUrl
          : "";
    }
    setTargetedContent(localData);

    if (!isNewContent) {
      setSelectedContentTypeId(currentMyccaContent.contentType);
    }
  }, [currentMyccaContent]);

  useEffect(() => {
    setDataChanged(!dataChanged);
  }, [targetedContent]);

  useEffect(() => {
    if (isEditing) {
      setSubmitEnabled(true);
    }
  }, [isEditing]);

  useEffect(() => {
    setCategoryLookupData(
      lookups.CategoryLookups !== undefined
        ? lookups.CategoryLookups.sort()
        : []
    );
    setLanguageLookupData(lookups.LanguageLookups);
    setRetentionPolicyLookupData(lookups.RetentionPolicyLookups);
    setTypeLookupData(
      lookups.TypeLookups !== undefined ? lookups.TypeLookups.sort() : []
    );
  }, [lookups]);

  useEffect(() => {
    if (
      categoryLookupData.length > 0 &&
      languageLookupData.length > 0 &&
      retentionPolicyLookupData.length > 0 &&
      typeLookupData.length > 0
    ) {
      if (props.type != null && props.type === FormType.CREATE) {
        let localData = targetedContent;
        if (Object.keys(localData).length === 0) { localData = createNewContent(); }
        localData["site"] = loggedInUser.site;
        setTargetedContent(localData);
        const lang = languageLookupData.find(
          (data) => data.DisplayName === "en"
        );

        if (lang !== undefined) {
          localData["language"] = [lang.Id];
          localData["site"] = loggedInUser.site;
          if (!formEdited) {
            setFormEdited(true);
          }
          setTargetedContent(localData);

          // Forces a rerender to display updated inout data
          setDataChanged(!dataChanged);
        }
      }

      setgetAllMyccaLookupsCallMade(true);
    }
  }, [
    categoryLookupData,
    languageLookupData,
    retentionPolicyLookupData,
    typeLookupData
  ]);

  useEffect(() => {
    if (
      loggedInUserCallMade &&
      getAllMyccaLookupsCallMade &&
      getTargetedContentCallMade
    ) {
      setLoading(false);
    }
  }, [
    loggedInUserCallMade,
    getAllMyccaLookupsCallMade,
    getTargetedContentCallMade
  ]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the targetedContent
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    const localData = targetedContent;
    localData.data.base[name] = value;

    if (!formEdited) {
      setFormEdited(true);
    }

    setTargetedContent(localData);

    // Forces a rerender to display updated inout data
    setDataChanged(!dataChanged);
  };

  // Validate the field data then update the localFormData with any new error messages
  const checkFieldIsValid = (required, value, type, fieldName, fieldLabel) => {
    let localFormProperties = formProperties;
    const errorMessage = validateField(required, value, type, fieldLabel);

    localFormProperties[fieldName].errorMessage = errorMessage;

    setFormProperties(localFormProperties);

    return errorMessage;
  };

  // Validate the form using the fields from TargetedContentModel
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      if (key === "endDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);
      }

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    setFormProperties(localProperties);
    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setFormDataUpdated(!formDataUpdated);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  const checkGenericField = (key, required) => {
    return checkFieldIsValid(
      required,
      targetedContent.data.base[key],
      formProperties[key].type,
      key,
      formProperties[key].label
    );
  };

  /*
    When the submit button is clicked on either the create or update form.
    The create param is optional
  */
  const submitTargetedContent = () => {
    //Ensure the data being submitted is valid before submitting
    if (validateForm()) {
      const requestObject = {
        Id: targetedContent.data.base.id,
        Description: targetedContent.data.base.description,
        EndDate: targetedContent.data.base.endDate,
        IsActive: targetedContent.data.base.isActive,
        Author: targetedContent.data.base.createdBy
      }
      // if (create) {
      //   // Create the targeted content item then redirect user to the target content list page
      //   // If the content type is Links then no need to upload any files
      //   if (
      //     targetedContent.contentType &&
      //     typeLookupData.find((type) => type.Id === targetedContent.contentType)
      //       .DisplayName === "Links"
      //   ) {
      //     dispatch(
      //       createTargetedContentForLink(requestBody, (success) => {
      //         if (success) {
      //           props.history.push("/content-upload");
      //         } else {
      //           setSubmitEnabled(true);
      //           setFormEdited(true);
      //         }
      //       })
      //     );
      //   } else {
      //     dispatch(
      //       createTargetedContent(
      //         targetedContent.file[0],
      //         requestBody.site,
      //         requestBody,
      //         (success) => {
      //           if (success) {
      //             props.history.push("/content-upload");
      //           } else {
      //             setSubmitEnabled(true);
      //             setFormEdited(true);
      //           }
      //         }
      //       )
      //     );
      //   }
      // } else {
      // if (
      //   targetedContent.contentType &&
      //   typeLookupData.find((type) => type.Id === targetedContent.contentType)
      //     .DisplayName === "Links"
      // ) {
      //   requestBody.targetedContent["linkContentUrl"] =
      //     requestBody["TargetedMediaUrl"];
      // }
      dispatch(
        updateMyccaContent(requestObject, (success) => {
          if (success) {
            setIsEditing(false);
          } else {
            setIsEditing(true);
            setSubmitEnabled(true);
          }
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (dataChanged) {
      setCancelTargetedContentEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (isNewContent) {
        props.history.push(`/content-upload`);
      }
    }
  };

  /*
    Display the cancel editing alert dialog
  */
  const cancelTargetedContentEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelTargetedContentEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setCancelTargetedContentEditingAlertOpen(false);

              if (isNewContent) {
                props.history.push(`/content-upload`);
              } else {
                setTargetedContent(uneditedTargetedContent);
              }
            }
          }
        ]}
        isOpen={cancelTargetedContentEditingAlertOpen}
      />
    );
  };

  const getListItems = () => {
    return targetedContent.data.meta.resources.map((r, index) => {
      // Build the request URL.
      const localtarg = targetedContent.data.base;
      
      let url = `/cms/GetResource?Site=${localtarg.site}&Code=${localtarg.code}&Size=${r.sizeId}`
      
      url = url.replace(/ /g, "%20");

      return (
        <>
          <Grid key={index} container spacing={5}>
            <Grid item xs={2}>
              <Typography variant="h6" gutterBottom>
                {lookups.data != undefined ? lookups.data.sizes.filter(function (i) {
                  return i.code == r.sizeId
                }).map(function (si) { return si.value })[0] : "Unknown Size!"
                }
              </Typography>
            </Grid>
            <Grid item xs={7}>
              {`${assetBaseUrl}${url}`}
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="blue"
                onClick={() => copy(`${assetBaseUrl}${url}`)}
              >
                <LinkIcon />
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                href={`${assetBaseUrl}${url}`}
              >
                <GetAppIcon />
              </Button>
            </Grid>
          </Grid>
        </>
      );
    });
  };

  /*
    Display all the page data
  */
  const renderData = () => {
    if (!targetedContent) {
      return <div />;
    }
    let isLinkContentType = false;

    if (Object.keys(targetedContent).length > 0) {
      const foundItem =
        targetedContent.contentType !== undefined &&
          typeLookupData !== undefined
          ? typeLookupData.find(
            (type) => type.Id === targetedContent.contentType
          )
          : undefined;

      if (foundItem != null && foundItem?.SizeLimit == null) { foundItem.SizeLimit = 9999 }
      if (foundItem != null && foundItem?.SizeLimit != sizeLimit) {
        foundItem.SizeLimit != null ?
          setSizeLimit(foundItem.SizeLimit) :
          setSizeLimit(9999);
      }

      isLinkContentType =
        targetedContent.contentType &&
        foundItem !== undefined &&
        foundItem.DisplayName === "Links";
    }

    let link = "";

    if (isLinkContentType) {
      if (targetedContent.link !== undefined) {
        link = targetedContent.link;
      } else if (
        targetedContent.file &&
        targetedContent.file[0].resourceUrl !== undefined
      ) {
        link = targetedContent.file[0].resourceUrl;
      } else {
        link = "";
      }
    }

    const downloadContent = () => {
      let url = `${API_CONFIG.RETRIEVAL}/GetFile?ContentId=${targetedContent.contentId}`;
      window.open(url, "_blank");
    };

    return (
      <Fragment>
        <div className={styles.contentGrid}>
          <CustomCard
            title="Main Details"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled={!isNewContent}
                  id="code"
                  name="code"
                  label="Code"
                  placeholder="Code"
                  value={
                    targetedContent.data != undefined ?
                      targetedContent.data.base.code ? targetedContent.data.base.code : ""
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange("code", event.target.value)
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.code.errorMessage !== ""}
                  helperText={formProperties.code.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="description"
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  value={
                    targetedContent.data != undefined ?
                      targetedContent.data.base.description ? targetedContent.data.base.description : ""
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange("description", event.target.value)
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.description.errorMessage !== ""}
                  helperText={formProperties.description.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DropdownSelect
                  disabled={!isNewContent}
                  label="Content Type"
                  handleChange={(value) => {
                    setSelectedContentTypeId(value);
                    handleInputChange("typeId", value);
                  }}
                  data={
                    lookups.data !== undefined && lookups.data.types.length > 0
                      ? lookups.data.types.map((t) => {
                        return { key: t.code, value: t.value };
                      })
                      : []
                  }
                  value={
                    lookups.data !== undefined &&
                      lookups.data.types.length > 0
                      ? targetedContent.data != undefined ? targetedContent.data.base.typeId : ""
                      : ""
                  }
                  valueName={"contentType"}
                  error={formProperties.contentType.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.contentType.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.contentType.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={4}>
                <DropdownSelect
                  disabled={!isNewContent}
                  label="Site"
                  handleChange={(value) => {
                    handleInputChange("site", value == "nz" ? 2 : 1);
                  }}
                  data={[
                    {
                      key: 1,
                      value: GetSite(1)
                    },
                    {
                      key: 2,
                      value: GetSite(2)
                    }
                  ]
                  }
                  value={
                    targetedContent.data != undefined ?
                      targetedContent?.data.base.site
                      : "nz"
                  }
                  valueName={"site"}
                  error={
                    formProperties.site &&
                    formProperties.site.errorMessage !== ""
                  }
                  fullWidth={true}
                >
                  {formProperties.site &&
                    formProperties.site.errorMessage !== "" ? (
                      <FormHelperText>
                        {formProperties.site.errorMessage}
                      </FormHelperText>
                    ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={4}>
                <DropdownSelect
                  disabled={!isEditing}
                  label="Retention Policy"
                  handleChange={(value) => {
                    handleInputChange("retentionPolicyId", value);
                  }}
                  data={
                    lookups.data !== undefined &&
                      lookups.data.retentionPolicies.length > 0
                      ? lookups.data.retentionPolicies.map((t) => {
                        return { key: t.code, value: t.value };
                      })
                      : []
                  }
                  value={
                    lookups.data !== undefined &&
                      lookups.data.retentionPolicies.length > 0
                      ? targetedContent.data != undefined ? targetedContent.data.base.retentionPolicyId : ""
                      : ""
                  }
                  valueName={"retentionPolicy"}
                  error={formProperties.retentionPolicy.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.retentionPolicy.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.retentionPolicy.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label="Effective To"
                    value={targetedContent.data != undefined ? targetedContent.data.base.endDate : new Date()}
                    onChange={(date) => {
                      handleInputChange(
                        "endDate",
                        new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
                          .toISOString()
                          .split("T")[0]);
                    }}
                    error={formProperties.endDate.errorMessage !== ""}
                    helperText={formProperties.endDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label="Active"
                  control={
                    <Checkbox
                      checked={
                        targetedContent.data != undefined ?
                          targetedContent.data.base.isActive != undefined
                            ? targetedContent.data.base.isActive
                            : true
                          : true
                      }
                      onChange={() =>
                        handleInputChange("isActive", !targetedContent.data.base.isActive)
                      }
                      name="active"
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
        {
          isNewContent ? null : targetedContent.data != undefined ? (
            <div className={styles.contentGrid}>
              <CustomCard title="Image Links">
                {getListItems()}
              </CustomCard>
            </div>)
            : null
        }
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={
          props.type === FormType.VIEW
            ? targetedContent != null
              ? <div>MyCCA Image - <strong>{targetedContent.data != undefined ? targetedContent.data.base.code : "New"}</strong></div>
              : ""
            : "New Content"
        }
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              {props.type != null && props.type === FormType.VIEW ? (
                <ButtonGroup>
                  {isEditing ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleCancelEditButtonClicked(true)}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      if (isEditing) {
                        submitTargetedContent();
                      } else {
                        setUneditedTargetedContent(targetedContent);
                        setIsEditing(true);
                      }
                    }}
                  >
                    {isEditing ? "Submit" : "Edit"}
                  </Button>
                </ButtonGroup>
              ) : (
                  <ButtonGroup>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleCancelEditButtonClicked(true)}
                    >
                      Cancel
                  </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        submitTargetedContent();
                      }}
                    >
                      Submit
                  </Button>
                  </ButtonGroup>
                )}
            </Grid>
          </Grid>
        }
      />

      {cancelTargetedContentEditingAlert()}

      <PageContainer hasHelp={props.type !== FormType.VIEW}>
        {renderData()}
      </PageContainer>
    </div>
  );
};

const hoc = withRouter(MyCCAContentPage);

// EXPORT COMPONENT
export { hoc as MyCCAContentPage };
