import React, { useEffect } from 'react';
import { Box, Button, Card, CardContent, Chip, Dialog, Grid, IconButton, Paper, TextField, Tooltip } from '@material-ui/core';
import { EditOutlined, RemoveCircleOutline } from '@material-ui/icons';
import styles from '../programmeDetailList.module.scss';
import RequirementForm from './requirement-form';
import { ProgrammeAction, ProgrammeRequirement } from '../../reducers/programme-reducer';
import { GenericDictionary } from 'types/GenericDictionary';

interface RequirementsListProps {
  items: GenericDictionary<ProgrammeRequirement>;
  handleUpdateRequirements: (action: ProgrammeAction) => void;
}

function RequirementsList(props: RequirementsListProps) {
  const {
    items = {},
    handleUpdateRequirements,
  } = props;

  const [editingRequirement, setEditingRequirement] = React.useState<string | null>(null); // null or the key of the requirement being edited
  const [isAddingRequirement, setIsAddingRequirement] = React.useState(false);

  // const [activitiesOptions, setActivitiesOptions] = React.useState<string | null>(null);


  // Initial Mount
  // useEffect(() => {
  //   const fetchActivities = async () => {
  //     setLoading(true);
  //     const response = await adalApiFetch(Axios, `${API_CONFIG.ACTIVITIES}/activities`, { method: "get", });
  //     const activities = await response.json();
  //     setActivitiesOptions(activities);
  //     setLoading(false);
  //   }
    
  //   fetchActivities().catch((error) => console.error(error));
  // },[])


  return (
    <div>
      <ul className={styles.programmeDetailList}>
        { (!items || Object.entries(items).length < 1)
          ? <li><p style={{color: 'var(--secondary-font)'}}>No requirements added</p></li>
          : Object.entries(items).map(([key, value], index) => {
            return (
              <li key={key}>
                <Box flex='1'>
                  <Box display='flex' alignItems='baseline' style={{gap: '0.5em'}}>
                    <Box fontWeight='bold'>
                      {key}
                    </Box>
                    – {value.description}
                    {/* <span style={{opacity: 0.5, fontSize:'0.8em', textTransform: 'uppercase'}}>Type:{value.type}</span>
                    <span style={{opacity: 0.5, fontSize:'0.8em', textTransform: 'uppercase'}}>Code:{value.code}</span> */}
                  </Box>
                  <Box>
                    { value.activities?.map((activity, index) => {
                      return (
                        <Box key={index} display='flex' alignItems='baseline' style={{gap: '0.5em'}}>
                          { activity.activityId ? <span>ActivityID: {activity.activityId}</span> : '' }
                          { activity.choiceIds && activity.choiceIds.length > 0 ? 
                            (<>
                              <span>ChoiceIDs:&nbsp;</span>
                              {activity.choiceIds.map((choice) => {
                                const choiceLabel = choice.choiceId.replace(activity.activityId, '');
                                return (
                                  choiceLabel.length && <Chip size='small' label={choiceLabel} key={choice.choiceId} />
                                )
                              })}
                            </>) : ''
                          }
                        </Box>
                      )
                    })}
                  </Box>
                  <Box>
                    Q: {value.question} / A: {value.answer}
                  </Box>
                </Box>
                <Box flex='0'>
                  <Tooltip title='Edit Requirement'>
                    <IconButton onClick={(e) => setEditingRequirement(key)}>
                      <EditOutlined fontSize='small' color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box flex='0'>
                  <Tooltip title='Delete Requirement'>
                    <IconButton onClick={(e) => handleUpdateRequirements({type: 'REMOVE_REQUIREMENT', payload: { key }})}>
                      <RemoveCircleOutline fontSize='small' color="primary" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </li>
            )
          }
        )}
      </ul>

      <Box className={styles.programmeDetailList_extension} display='flex' justifyContent='flex-end'>
        <Button onClick={() => setIsAddingRequirement(true)} variant='outlined' color='secondary'>Add a Requirement</Button>
      </Box>

      <Dialog open={!!editingRequirement || !!isAddingRequirement}>
        <RequirementForm
          items={items}
          handleUpdateRequirements={handleUpdateRequirements}
          requirement={ editingRequirement ? items[editingRequirement] : null }
          requirementKey={editingRequirement}
          close={()=>{
            setIsAddingRequirement(false)
            setEditingRequirement(null)
          }}
        />
      </Dialog>
    </div>
  );
}

export default RequirementsList;