// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../components/dialog/Modal";

// UI and Styling
import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// Actions
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Components
import HeaderBlock from "../../components/header-block/HeaderBlock";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import PageContainer from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/circleLoader";
import { downloadMDStockweightTargetData, uploadMDStockweightTargetData } from "../../state/actions/stockweightTargetActions";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getSiteForLoggedInUser } from "../../helpers/userHelpers";
import { validate, v4 } from 'uuid';


const StockweightTargetsUploadPage = (props) => {
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const site = getSiteForLoggedInUser(loggedInUser);

  const configurations = useSelector( (state) => state.ConfigurationReducer.configurations);

  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [previewData, setPreviewData] = useState({});
  const [csvInvalidError, setCsvInvalidError] = useState(false);

  const dispatch = useDispatch();


  //Use Effects
  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }
  }, []);

  useEffect(() => {
    if (targetsEdited) {
      setSubmitEnabled(true);
    }
  }, [targetsEdited]);

  const csvErrorElement = csvInvalidError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      The CSV file you have provided does not match the specification -{" "}
      <strong>Try Again Later.</strong>
    </Alert>
  ) : (
    <></>
  );

  useEffect(() => {
    if (dataChanged) {
      setDataChanged(false);
     
      let modifiedCSVRows = [];

      csvData.forEach((row) => {
        const updateRow = {
          StockWeightTargetId: row.StockWeightTargetId,
          Site: row.Site,
          Year: row.Year,
          Week: row.Week,
          CustomerNumber: row.CustomerNumber,
          myDisplayCategoryId: row.myDisplayCategoryId,
          TargetNumberOfDisplays: row.TargetNumberOfDisplays,
          TargetStockWeightQty: row.TargetStockWeightQty,
        };

        modifiedCSVRows.push(updateRow);
      });

      setCsvData(modifiedCSVRows);
      setSubmitEnabled(true);
    }
  }, [dataChanged]);

  const createRequestBody = () => {
    let toReturn = [];

    csvData.forEach((row) => {
      const requestBody = {
        StockWeightTargetId: row.StockWeightTargetId,
        Site: parseInt(row.Site),
        Year: parseInt(row.Year),
        Week: parseInt(row.Week),
        CustomerNumber: row.CustomerNumber,
        myDisplayCategoryId: row.myDisplayCategoryId,
        TargetNumberOfDisplays: parseInt(row.TargetNumberOfDisplays),
        TargetStockWeightQty: row.TargetStockWeightQty,
      };

      toReturn.push(requestBody);
    });

    return toReturn;
  };

  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={v4()}
        variant="outlined"
        onClick={() => {
          const data = createRequestBody();

          dispatch(
            uploadMDStockweightTargetData(data, site, (res) => {
              if (res === false) {
                res = {message: "Upload failed, check file for duplicate products"};
              } else {
                res.message = "Upload Successful";
              }
              setPreviewData(res);
              setModalShow(true);
            })
          );
        }}
        disabled={!submitEnabled}
      >
        Submit
      </Button>,
    ];
  };

  function MyVerticallyCenteredModal() {
    return (
      <Modal
        style={{ color: "green" }}
        title={
          <div>
            <Typography variant="h4" align="center">
              <CheckCircleIcon />
            </Typography>
            <Typography align="center" variant="h5">
              {previewData.message}
            </Typography>
          </div>
        }
        open={modalShow}
        fullWidth={true}
        actions={
          <div>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setModalShow(false);
              }}
            >
              Close
            </Button>
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            minHeight: "15vh",
            alignItems: 'center',
            padding: '  '
          }}
        >
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Updated</b>
            <span style={{ fontSize: "36px" }}>{previewData.updated}</span>
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Created</b>
            <span style={{ fontSize: "36px" }}>{previewData.created}</span>
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Deleted</b>
            <span style={{ fontSize: "36px" }}>{previewData.deleted}</span>
          </Typography>
        </div>
      </Modal>
    );
  }

  const validateStockweightTargets = (data) => {
    let errorMessages = [];
    // Check if there are duplicate rows
    const hasDuplicates = () =>  [...new Set(data.map(row => JSON.stringify(row)))].length !== data.length;
    if(hasDuplicates()) {
      errorMessages.push("Found duplicate rows in the CSV file");
    }
    
    // Check week format is YYYYWW
    const isWeekFormatValid = () => {
      const datePattern = new RegExp('([2][0])([2-9][0-9])(0[1-9]|[1-4][0-9]|5[0-2])');
      return data.every(row => datePattern.test(row.Week));
    }
    if(!isWeekFormatValid()){ 
      errorMessages.push("Week formats should be YYYYMM. Example: 202309");
    }
    
    // Check if all StockWeightTargetId are either valid GUIDs or empty (in which case the server will generate one).
    const hasValidGUIDs = () => {
      return data.every(row => validate(row.StockWeightTargetId) || row.StockWeightTargetId === "" || row.StockWeightTargetId === null);
    }
    if(!hasValidGUIDs()){
      errorMessages.push("Found invalid GUIDs in the StockWeightTargetId column");
    }

    return errorMessages;
  }

  return (
    <Box>
      <Fragment>
        {isUploading ? (
          <>
            <HeaderBlock
              title={"Please Wait: Data is being uploaded"}
            />
            <PageContainer>
              <LoadingComponent></LoadingComponent>
            </PageContainer>
          </>
        ) : (
          <>
            <HeaderBlock
              title={"My Displays Upload"}
              right={
                renderSubmitAndCancelButtons() ? (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          dispatch(downloadMDStockweightTargetData(site, (res) => {}));
                        }}
                      >
                        Download CSV
                      </Button>
                    </Grid>
                    <Grid item>
                      <ButtonGroup>
                        {renderSubmitAndCancelButtons()}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                ) : null
              }
            />

            <PageContainer>
              {csvErrorElement}
              <div>
                <Grid container spacing={3}>
                  <Grid
                    key={"migrationUploadSegment"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <MigrationUploadSegment
                      csvData={csvData}
                      setCsvData={setCsvData}
                      setCSVInvalid={(option) => {
                        //TODO Show Error message if csv is invalid
                        if (option) {
                          setCsvInvalidError(true);
                          setTimeout(() => {
                            setCsvInvalidError(false);
                          }, 30000);
                        }
                      }}
                      sortProperties={['Year', 'Week', 'CustomerNumber', 'myDisplayCategoryId']}
                      setDataChanged={setDataChanged}
                      title="Upload Stockweight Targets"
                      targetsEdited={targetsEdited}
                      setTargetsEdited={setTargetsEdited}
                      isEditing={isEditing}
                      setFormEdited={setFormEdited}
                      description="WARNING: This will delete all existing Stockweight Targets in the database and replace them with the data from the CSV. Please make sure you have all the existing Stockweight Targets data before uploading. Please upload a CSV containing the Stockweight Target data that you would like uploaded to SaM-Core."
                      columns={[
                        "StockWeightTargetId",
                        "Site",
                        "Year",
                        "Week",
                        "CustomerNumber",
                        "myDisplayCategoryId",
                        "TargetNumberOfDisplays",
                        "TargetStockWeightQty",
                      ]}
                      stripUnexpectedColumns={true}
                      expectedColumns={[
                        "StockWeightTargetId",
                        "Site",
                        "Year",
                        "Week",
                        "CustomerNumber",
                        "myDisplayCategoryId",
                        "TargetNumberOfDisplays",
                        "TargetStockWeightQty"
                      ]}
                      customValidation={validateStockweightTargets}
                      allDataUrl={"templateUrl"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </Grid>
              </div>
            </PageContainer>
          </>
        )}
      </Fragment>
    </Box>
  );
};

const hoc = withRouter(StockweightTargetsUploadPage);

// EXPORT COMPONENT
export { hoc as StockweightTargetsUploadPage };
