// IMPORT PACKAGE REFERENCES
import React from 'react';
import styles from './CardContent.module.css'; // Import css modules stylesheet as styles

// COMPONENT
export class CustomCardContent extends React.Component {
    render() {
        return (
            <div className={styles.cardContent}>
                { this.props.children }
            </div>
        );
    }
}