// React
import React, { Fragment, useState, useEffect } from "react";

// UI and Styling
import {
  GridList,
  GridListTile,
  GridListTileBar,
  Checkbox,
  TablePagination
} from "@material-ui/core";
import styles from "./ImageGrid.module.scss";

// Components
import { Loading } from "../loading/Loading";
import { PagePlaceholder } from "../page-placeholder/PagePlaceholder";

// Other
import { getIcon } from "../../icon/icon";

const ImageGrid = ({
  cellHeight,
  cols,
  currentSelection,
  data,
  emptyPlaceholder,
  selectable,
  spacing,
  retrieveImage,
  onClick,
  searchText
}) => {
  const [checked, setChecked] = useState(currentSelection || []);
  const [images, setImages] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    data
      .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
      .forEach((resource) => {
        retrieveImage(resource, (resource, value) => {
          const localImages = images;
          localImages[resource] = value;
          setImages(localImages);
        });
      });
  }, []);

  useEffect(() => {
    data
      .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
      .forEach((resource) => {
        retrieveImage(resource, (resource, value) => {
          const localImages = images;
          localImages[resource] = value;
          setImages(localImages);
        });
      });
  }, [searchText]);

  const changePage = (currentPage, pageSize) => {
    data
      .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
      .forEach((resource) => {
        retrieveImage(resource, (resource, value) => {
          const localImages = images;
          localImages[resource] = value;
          setImages({ ...images, [resource.id]: resource });
        });
      });
  };

  return (
    <Fragment>
      <div className={styles.root}>
        {data.length === 0 ? (
          <PagePlaceholder text={emptyPlaceholder} />
        ) : (
          <GridList
            cellHeight={cellHeight}
            spacing={spacing}
            cols={cols}
            classes={{ root: styles.gridList }}
          >
            {data
              .slice(currentPage * pageSize, currentPage * pageSize + pageSize)
              .map((tile) => (
                <GridListTile
                  key={tile}
                  classes={{ tile: styles.imageTile }}
                  onClick={() => {
                    onClick(tile);
                  }}
                >
                  {images[tile] ? (
                    <img
                      className={styles.image}
                      src={images[tile]}
                      alt={tile}
                      onError={(e) => {
                        const image = getIcon("empty-file.svg");
                        e.target.src = image.props.src;
                      }}
                    />
                  ) : (
                    <Loading />
                  )}

                  <GridListTileBar
                    classes={{ actionIcon: styles.actionIcon }}
                    actionPosition="left"
                    title={tile}
                    actionIcon={
                      selectable ? (
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(tile.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": tile.id }}
                        />
                      ) : null
                    }
                  />
                </GridListTile>
              ))}
          </GridList>
        )}
      </div>
      <TablePagination
        className={styles.pagination}
        component="nav"
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        count={data.length}
        onChangePage={(evt, page) => {
          setCurrentPage(page);
          changePage(page, pageSize);
        }}
        onChangeRowsPerPage={(event) => {
          const pageSize = event.target.value;
          setPageSize(pageSize, currentPage ? currentPage : 0);
          changePage(0, pageSize);
        }}
      />
    </Fragment>
  );
};

export default React.memo(ImageGrid);
