import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
  CREATE_TARGETED_CONTENT,
  GET_ALL_TARGETED_CONTENT,
  GET_ALL_TARGETED_CONTENT_BY_TYPE,
  GET_TARGETED_CONTENT_BY_ID,
  GET_CONTENT_LOOKUP_DATA,
  GET_ALL_CATEGORIES,
  GET_CATEGORY_BY_ID,
  RESET_CURRENT_TARGETED_CONTENT,
  UPDATE_TARGETED_CONTENT,
  GET_ALL_APPLICATIONS,
  GET_APPLICATION_BY_ID
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

/*
    Get all Targeted Content
*/
export const getAllTargetedContent = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetAllTargetedContent`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_ALL_TARGETED_CONTENT,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Get all Targeted Content by a specific type
*/
export const getAllTargetedContentByType = (type, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetAllTargetedContent?type=${type}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_ALL_TARGETED_CONTENT_BY_TYPE,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Get an Individual Targeted Content item by Id
*/
export const getTargetedContentById = (targetedContentId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        targetedContentId !== undefined
          ? await adalApiFetch(
            Axios, `${API_CONFIG.CONTENT}/GetTargetedContentById?ContentId=${targetedContentId}`,
            {
              method: "get"
            }
          )
          : {};

      dispatch({
        type: GET_TARGETED_CONTENT_BY_ID,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const getAllLookupContent = (scope, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetDropdownLookups?scope=${scope}`,
        { method: "get" }
      );

      dispatch({
        type: GET_CONTENT_LOOKUP_DATA,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const getAllCategories = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/GetAllCategories`,
        { method: "get" }
      );

      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const getCategoryById = (categoryId, callback) => {
  return async (dispatch) => {
    try {
      let response = {};
      if (categoryId !== undefined) {
        response = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/GetCategoryById?categoryId=${categoryId}`,
          { method: "get" }
        );
      }

      dispatch({
        type: GET_CATEGORY_BY_ID,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Create a New Targeted Content resource
*/
export const createTargetedContent = (file, territory, metadata, callback) => {
  return async (dispatch) => {
    try {
      //Convert the file to formdata
      const formData = new FormData();
      formData.append(file.name, file);
      // First the file needs to be uploaded.
      const uploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/UploadFile?territory=${territory}`,
        {
          method: "post",
          header: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        }
      );

      // Once the file has uploaded successfully then the metadata relating to the file needs to be uploaded
      if (uploadResponse.status === 200) {
        //Add url of uploaded file to metadata.
        //Get the first item from the data array as we only allow one file upload for now.
        //The response returns an array as it can accommodate multiple uploads in the future.
        metadata["TargetedMediaUrl"] = uploadResponse.data[0].path;

        const headers = { "Content-Type": "application/json" };

        const response = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/CreateTargetedContent`,
          {
            method: "post",
            headers: headers,
            data: metadata
          }
        );

        dispatch({
          type: CREATE_TARGETED_CONTENT,
          payload: response
        });

        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Create a New category
*/
export const createCategory = (data, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };

      await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/UpsertCategory`,
        {
          method: "post",
          headers: headers,
          data: data
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Update an existing category
*/
export const updateCategory = (categoryId, data, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };

      await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/UpsertCategory?CategoryId=${categoryId}`,
        {
          method: "post",
          headers: headers,
          data: data
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Create a New Targeted Content resource
*/
export const createTargetedContentForLink = (metadata, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/CreateTargetedContent`,
        {
          method: "post",
          headers: headers,
          data: metadata
        }
      );

      dispatch({
        type: CREATE_TARGETED_CONTENT,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Update an existing Targeted Content resource
*/
export const updateTargetedContent = (contentId, metadata, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.CONTENT}/UpdateTargetedContent?contentId=${contentId}`,
        {
          method: "put",
          headers: headers,
          data: metadata
        }
      );

      dispatch({
        type: UPDATE_TARGETED_CONTENT,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
  Reset local state for current Targeted Content
*/
export const resetLocalTargetedContent = (callback) => (dispatch) => {
  dispatch({
    type: RESET_CURRENT_TARGETED_CONTENT,
    payload: null
  });

  callback(true);
};
