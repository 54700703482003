// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  FormHelperText,
  Grid,
  TextField
} from "@material-ui/core";
import styles from "./ViewCategoryPage.module.scss";

// Actions
import {
  createCategory,
  getAllLookupContent,
  getCategoryById,
  updateCategory
} from "../../../state/actions/TargetedContentActions";
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import CustomCard from "../../../components/custom-card/CustomCard";
import DropdownSelect from "../../../components/dropdown-select/DropdownSelect";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";

// Models
import { CategoryModel } from "../../../models/CategoryModel";

// Other
import { FormType } from "../../../state/constants/FormType";
import { validateField } from "../../../helpers/validation";
import { sortDropdown } from "../../../helpers/sortDropdown";

const ViewCategoryPage = (props) => {
  const currentCategory = useSelector(
    (state) => state.TargetedContentReducer.currentCategory
  );
  const lookupData = useSelector(
    (state) => state.TargetedContentReducer.lookupData
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(CategoryModel);
  const [category, setCategory] = useState({});
  const [uneditedCategory, setUneditedCategory] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingCategory, setIsExistingCategory] = useState(true);
  const [categoryId, setCategoryId] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [typeLookupData, setTypeLookupData] = useState([]);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [lookupsCallMade, setLookupsCallMade] = useState(false);
  const [getCategoryCallMade, setGetCategoryCallMade] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);

  //Use Effects
  useEffect(() => {
    setLoading(true);

    setIsExistingCategory(props.type != null && props.type === FormType.VIEW);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }

    dispatch(
      getAllLookupContent("targetedonly", () => {
        setLookupsCallMade(true);
      })
    );

    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getCategoryById(props.match.params.categoryId, () => {
          setGetCategoryCallMade(true);
          setCategoryId(props.match.params.categoryId);
        })
      );
    } else {
      dispatch(
        getCategoryById(undefined, () => {
          setGetCategoryCallMade(true);
          setIsEditing(true);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (loggedInUserCallMade && lookupsCallMade && getCategoryCallMade) {
      setLoading(false);
    }
  }, [loggedInUserCallMade, lookupsCallMade, getCategoryCallMade]);

  useEffect(() => {
    setTypeLookupData(lookupData.TypeLookups);
  }, [lookupData]);

  useEffect(() => {
    setCategory(currentCategory);
  }, [currentCategory]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, category);

    localData[name] = value;

    setCategory(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      category[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = checkGenericField(key, formProperties[key].required);

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    let requestBody = {
      description: category.description,
      typeId: category.contentType,
      createdBy: loggedInUser.userName.substring(
        loggedInUser.userName.indexOf("\\") + 1
      )
    };

    if (!isCreate) {
      requestBody.categoryId = categoryId;
    }

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      const body = createRequestBody(true);
      dispatch(
        createCategory(createRequestBody(true), (success, id) => {
          setIsEditing(false);
          props.history.push(`/categories`);
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateCategory(categoryId, createRequestBody(false), () => {
          setIsEditing(false);
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingCategory) {
        props.history.push(`/categories`);
      }
    }
  };

  /*
  Display the cancel editing alert dialog
*/
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setCategory(uneditedCategory);
              setCancelEditingAlertOpen(false);

              if (!isExistingCategory) {
                props.history.push(`/categories`);
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  //Display Activity group and associated list of activities
  const renderData = () => {
    return (
      <div className={styles.contentGrid}>
        <CustomCard title="Main Details" actionButton={[]}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                disabled={!isEditing}
                id={formProperties.description.value}
                name={formProperties.description.value}
                label={formProperties.description.label}
                placeholder="Enter description"
                value={
                  Object.keys(category).length > 0 &&
                  category.description !== undefined
                    ? category.description
                    : ""
                }
                onChange={(event) =>
                  handleInputChange(
                    formProperties.description.value,
                    event.target.value
                  )
                }
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                error={formProperties.description.errorMessage !== ""}
                helperText={formProperties.description.errorMessage}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownSelect
                disabled={!isEditing}
                label="Content Type"
                handleChange={(value) => {
                  handleInputChange("contentType", value);
                }}
                data={
                  typeLookupData !== undefined && typeLookupData.length > 0
                    ? typeLookupData.sort(sortDropdown).map((t) => {
                        return { key: t.Id, value: t.DisplayName };
                      })
                    : []
                }
                value={
                  typeLookupData !== undefined && typeLookupData.length > 0
                    ? category.contentType
                    : ""
                }
                valueName={"contentType"}
                error={formProperties.contentType.errorMessage !== ""}
                fullWidth={true}
              >
                {formProperties.contentType.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.contentType.errorMessage}
                  </FormHelperText>
                ) : null}
              </DropdownSelect>
            </Grid>
          </Grid>
        </CustomCard>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={category.description}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedCategory(category);
                    } else if (isEditing && isExistingCategory) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing
                    ? isExistingCategory
                      ? "Done"
                      : "Submit"
                    : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelEditingAlert()}

      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewCategoryPage);

// EXPORT COMPONENT
export { hoc as ViewCategoryPage };
