// React
import React, { useState } from "react";

// UI and Styling
import {
  Grid,
  Chip,
  FormHelperText,
  List,
  Button,
  ButtonGroup,
  Typography
} from "@material-ui/core";
import styles from "./ListableRulesCard.module.scss"; // Import css modules stylesheet as styles

// Components
import { CustomCardContent } from "../card-content/CardContent";
import { SimpleCardHeader } from "../card/card-header/CardHeader";
import { Loading } from "../loading/Loading";
import { Toolbar } from "../toolbar/Toolbar";
import ListableRulesItem from "./listable-item-rules/ListableRulesItem";
import ShrinkingButtonGroup from "../shrinking-button-group/ShrinkingButtonGroup";

export const ListableRulesCard = (props) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [toolbarShowing, setToolbarShowing] = useState(false);
  const handleCheck = (id) => {
    const localSelectedItems = Object.assign({}, selectedItems);
    if (!localSelectedItems.hasOwnProperty(id)) {
      localSelectedItems[id] = true;
    } else {
      delete localSelectedItems[id];
    }

    setSelectedItems(localSelectedItems);
  };

  const multiSelect = (shouldDeselect) => {
    const localSelectedItems = {};

    if (shouldDeselect) {
      setSelectedItems(localSelectedItems);
      return;
    }

    props.data.forEach((data) => {
      let id = "";

      if (data !== null && data !== undefined) {
        id = data.id === null || data.id === undefined ? "" : data.id;
      }

      localSelectedItems[id] = true;
    });

    setSelectedItems(localSelectedItems);
  };

  const toggleToolbar = () => {
    setToolbarShowing(!toolbarShowing);
  };

  const getDataList = () => {
    if (props.isLoading) {
      return <Loading />;
    }

    if (props.data.length === 0 && props.errorMessage !== "") {
      return <FormHelperText error={true}>{props.errorMessage}</FormHelperText>;
    }

    if (props.data.length === 0) {
      return <div className={styles.placeholder}>{props.emptyPlaceholder}</div>;
    }

    const allSelected = Object.keys(selectedItems).length === props.data.length;

    return (
      <Grid>
        <List>
          {toolbarShowing && props.data.length > 0 && (
            <Toolbar
              showDelete={Object.keys(selectedItems).length > 0}
              onDelete={() => {
                props.handleDelete(Object.keys(selectedItems));
                setSelectedItems({});
              }}
              multiSelectLabel={allSelected ? "Deselect All" : "Select All"}
              multiSelect={() => multiSelect(allSelected)}
            />
          )}

          {props.data.map((data) => {
            let name = "";
            let id = "";

            if (data !== null && data !== undefined) {
              name =
                data.name === null || data.name === undefined ? "" : data.name;
              id = data.id === null || data.id === undefined ? "" : data.id;
            }

            return (
              <ListableRulesItem
              isCoreRange={props.isCoreRange}
                key={`${id}_item`}
                disabled={props.disabled}
                id={id}
                toolbarShowing={toolbarShowing}
                modelType={props.modelType}
                fieldNameValue={data !== undefined ? data.fieldNameValue : ""}
                handleFieldNameChange={(value, index) => {
                  data.handleFieldNameChange(value, index);
                }}
                possibleRuleValues={
                  data !== undefined ? data.possibleRuleValues : []
                }
                handleValueChange={(value) => {
                  data.handleAddValue(value);
                }}
                handleDelete={() => {
                  props.handleDelete([`${data.id}`]);
                }}
                data={data}
                handleCheck={() => {
                  handleCheck(id);
                }}
                selectedRuleValues={
                  data !== undefined ? data.selectedRuleValues : []
                }
                selectedItems={selectedItems}
              />
            );
          })}
        </List>
        {props.errorMessage !== "" ? (
          <FormHelperText error={true}>{props.errorMessage}</FormHelperText>
        ) : null}
      </Grid>
    );
  };

  const actionButtons = (props.actionButton
    ? props.actionButton.map((action) => {
        return {
          label: action.props.children,
          action: () => action.props.onClick()
        };
      })
    : []
  ).concat({
    label: toolbarShowing ? "Done" : "Edit",
    action: () => toggleToolbar()
  });

  return (
    <CustomCardContent>
      <SimpleCardHeader>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderLeft}>
            <div className={styles.cardTitle}>{props.title}</div>
            {!props.isLoading && (
              <Chip label={props.data.length} variant="outlined" />
            )}
          </div>
          {props.disabled ? null : (
            <div>
              <div className={styles.largeScreen}>
                <ButtonGroup>
                  {!props.isLoading && props.actionButton}
                  {props.data.length > 0 && (
                    <Button onClick={() => toggleToolbar()} variant="outlined">
                      {toolbarShowing ? "Done" : "Edit"}
                    </Button>
                  )}
                </ButtonGroup>
              </div>
              <div className={styles.smallScreen}>
                <ShrinkingButtonGroup
                  secondary={actionButtons}
                  default={{
                    label: "...",
                    action: null
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </SimpleCardHeader>
      <SimpleCardHeader>
        <Typography variant="body2">{props.subheading}</Typography>
      </SimpleCardHeader>

      {getDataList()}
    </CustomCardContent>
  );
};
