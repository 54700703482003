import React from "react";
import { Route, Redirect } from "react-router-dom";
import { auth } from "../../auth/AuthProvider";

export const PrivateRoute = ({ component: Component, roles, userRoles, type, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const loginRedirect = <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        const roleRedirect = <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        

        if (auth.isAuthenticated === true) {
          if (roles) {
            let success = false;
            userRoles.forEach(role => {
              if (success) {
                return
              }
              success = roles.includes(role);
            })

            if (!success) {
              return roleRedirect
            }
          }

          return <Component type={type} {...props} />
        }
        return loginRedirect
      }}
    />
  )
}


