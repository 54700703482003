// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// UI and Styling
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import styles from "./ViewRolePage.module.scss";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
  createRole,
  deleteRole,
  getRole,
  updateRole,
  updateRoleLocally,
  assignUsersToRole,
  removeUsersFromRole
} from "../../../state/actions/RoleActions";
import { getUsers, getUsersForRole } from "../../../state/actions/UserActions";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import CustomCard from "../../../components/custom-card/CustomCard";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import Modal from "../../../components/dialog/Modal";
import PageContainer from "../../../components/page-container/PageContainer";
import Search from "../../../components/search/Search";
import SimpleList from "../../../components/simple-list/SimpleList";

// Models
import { RoleInputModel } from "../../../models/RoleModel";

// Other
import { UUID } from "../../../helpers/uuid";
import { validateField } from "../../../helpers/validation";
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "22px"
  }
}));

const ViewRolePage = (props) => {
  const classes = useStyles();

  const role = useSelector((state) => state.RoleReducer.role);
  const usersInRole = useSelector((state) => state.UserReducer.usersInRole);
  const users = useSelector((state) => state.UserReducer.users);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  const [originalRole, setOriginalRole] = useState({});
  const [loadingRole, setLoadingRole] = useState(false);
  const [loadingUsersInRole, setLoadingUsersInRole] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [deleteUsersAlertShowing, setDeleteUsersAlertShowing] = useState(false);
  const [deleteRoleAlertShowing, setDeleteRoleAlertShowing] = useState(false);
  const [cancelAlertShowing, setCancelAlertShowing] = useState(false);
  const [usersToDelete, setUsersToDelete] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [originalUsersInRoleData, setOriginalUsersInRoleData] = useState([]);
  const [searchedUsersInRoleData, setSearchedUsersInRoleData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [isNewRole, setIsNewRole] = useState(false);
  const [formData, setFormData] = useState(RoleInputModel);
  const [errorsCount, setErrorsCount] = useState(0);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [fetchRole, setFetchRole] = useState(true);
  const [fetchUsersForRole, setFetchUsersForRole] = useState(false);
  const [fetchUsersNotInRole, setFetchUsersNotInRole] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const roleId = props.match.params.roleId;
  

  //First get the role details
  useEffect(() => {
    if (fetchRole) {
      if (roleId === "new") {
        setIsNewRole(true);
        setEditing(true);
        dispatch(updateRoleLocally({}));
      } else {
        setLoadingRole(true);
        setLoadingUsersInRole(true);
        dispatch(
          getRole(roleId, () => {
            setLoadingRole(false);
            setFetchUsersForRole(true);
          })
        );
      }

      setFetchRole(false);
    }
  }, [fetchRole]);

  //Then get the users who are assigned to this role
  useEffect(() => {
    if (fetchUsersForRole) {
      if (!loadingUsersInRole) {
        setLoadingUsersInRole(true);
      }

      dispatch(
        getUsersForRole(roleId, () => {
          setLoadingUsersInRole(false);
          setFetchUsersForRole(false);
        })
      );
    }
  }, [fetchUsersForRole]);

  // Whenever the users in roles changes, update the usersInRoles search data.
  // This data is used when performing a search within the remove user from role modal.
  useEffect(() => {
    const usersInRoleData = [];

    Object.keys(usersInRole).forEach((userInRoleId) => {
      usersInRoleData.push({
        id: usersInRole[userInRoleId].id,
        value: usersInRole[userInRoleId].name
      });
    });

    setSearchedUsersInRoleData(usersInRoleData);
    setOriginalUsersInRoleData(usersInRoleData);
  }, [usersInRole]);

  //When adding users to a role get all users who aren't in the role already
  useEffect(() => {
    if (fetchUsersNotInRole) {
      setLoadingUsersInRole(true);

      dispatch(
        getUsers(() => {
          setFetchUsersNotInRole(false);
        })
      );
    }
  }, [fetchUsersNotInRole]);

  // Whenever users updates we need to update the users search data to match
  useEffect(() => {
    const userIdsInRole = Object.keys(usersInRole);
    const data = [];

    Object.keys(users).forEach((key) => {
      let item = users[key];

      if (!userIdsInRole.includes(item.id)) {
        data.push({
          id: item.id,
          value: item.name,
          secondaryValue: `ausdom\\${item.email}`
        });
      }
    });

    setLoadingUsersInRole(false);
    setSearchedData(data);
    setOriginalData(data);
  }, [users]);

  const deleteUsersFromRole = (usersToDeleteIds) => {
    dispatch(
      removeUsersFromRole(usersToDeleteIds, roleId, () => {
        setUsersToDelete([]);
        setDeleteUsersAlertShowing(false);
        setFetchUsersForRole(true);
      })
    );
  };

  const handleDeleteRoleButtonClicked = () => {
    dispatch(
      deleteRole(roleId, () => {
        props.history.push(`/roles`);
      })
    );
    setDeleteRoleAlertShowing(false);
  };

  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    let numErrors = 0;

    Object.values(formData).forEach((field) => {
      const errorText = checkFieldIsValid(
        field.required,
        role[field.name],
        field.type,
        field.name,
        field.label
      );

      if (errorText.length > 0) {
        numErrors++;
      }
    });

    if (numErrors > 0) {
      setErrorsCount(numErrors);
      console.log(
        `Form is not valid. There was ${errorsCount} ${
          errorsCount === 1 ? "error" : "errors"
        }`
      );
      setSubmitEnabled(true);
    } else {
      if (isNewRole) {
        dispatch(
          createRole(role, () => {
            props.history.push(`/roles`);
          })
        );
      } else if (editing) {
        dispatch(updateRole(roleId, role, () => {}));
      }

      setSelectedUsers([]);
      setEditing(false);
      setFormHasChanged(false);
    }
  };

  // This refers to the cancel button on the edit or create form
  const handleCancelEditButtonClicked = () => {
    if (formHasChanged) {
      setCancelAlertShowing(true);
    } else {
      setEditing(false);

      if (isNewRole) {
        props.history.push(`/roles`);
      }
    }
  };

  const handleEditButtonClicked = () => {
    //Take a copy of the current role in case the app user cancels
    setOriginalRole(role);

    setEditing(true);
  };

  // This refers to the cancel button within the alert dialog
  const handleCancelButtonClicked = () => {
    if (isNewRole) {
      props.history.replace(`/roles`);
    } else {
      dispatch(updateRoleLocally(originalRole));
      setFormHasChanged(false);
    }

    setSelectedUsers([]);
    setEditing(false);
    setCancelAlertShowing(false);
  };

  const deleteRoleAlertDialog = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to delete role  ${role.name}?`}
        description={
          "All users will be unassigned. This is permanent, and can't be undone."
        }
        options={[
          {
            label: "Cancel",
            action: () => setDeleteRoleAlertShowing(false)
          },
          {
            label: "Delete",
            action: () => handleDeleteRoleButtonClicked()
          }
        ]}
        isOpen={deleteRoleAlertShowing}
      />
    );
  };

  const deleteUsersAlertDialog = () => {
    if (deleteUsersAlertShowing) {
      let usersToDeleteName = "";

      let usersToDeleteIds = [];

      if (usersToDelete.length > 1) {
        usersToDeleteName = `${usersToDelete.length} users`;
        usersToDeleteIds = usersToDelete;
      } else if (usersToDelete.length === 1) {
        usersToDeleteName = usersInRole[usersToDelete[0]].name;
        usersToDeleteIds.push(usersInRole[usersToDelete[0]].id);
      }

      return (
        <AlertDialog
          title={`Are you sure you want to remove ${usersToDeleteName} from ${role.name}?`}
          description={"This is permanent, and can't be undone."}
          options={[
            {
              label: "Cancel",
              action: () => setDeleteUsersAlertShowing(false)
            },
            {
              label: "Delete",
              action: () => deleteUsersFromRole(usersToDeleteIds)
            }
          ]}
          isOpen={deleteUsersAlertShowing}
        />
      );
    }
  };

  const cancelAlertDialog = () => {
    const title = isNewRole
      ? "Are you sure you want to go back?"
      : "Are you sure you want to stop editing?";
    return (
      <AlertDialog
        title={title}
        description={"Any changes made will be lost."}
        options={[
          {
            label: "Cancel",
            action: () => setCancelAlertShowing(false)
          },
          {
            label: "Confirm",
            action: () => handleCancelButtonClicked()
          }
        ]}
        isOpen={cancelAlertShowing}
      />
    );
  };

  const renderDeleteButton = () => {
    if (
      role.name !== "samcore\\SaM Core Admin" ||
      (role.name === "samcore\\SaM Core Admin" &&
        loggedInUserRoles !== undefined &&
        loggedInUserRoles.includes("SaM Core Admin"))
    ) {
      return [
        <Button
          key={UUID()}
          variant="outlined"
          color={"primary"}
          onClick={() => setDeleteRoleAlertShowing(true)}
        >
          Delete Role
        </Button>
      ];
    } else {
      return [];
    }
  };

  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => validateForm()}
        disabled={!formHasChanged && submitEnabled === false}
      >
        Submit
      </Button>,
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleCancelEditButtonClicked()}
      >
        Cancel
      </Button>
    ];
  };

  const renderEditButton = () => {
    if (
      role.name !== "samcore\\SaM Core Admin" ||
      (role.name === "samcore\\SaM Core Admin" &&
        loggedInUserRoles !== undefined &&
        loggedInUserRoles.includes("SaM Core Admin"))
    ) {
      return [
        <Button
          key={UUID()}
          variant="outlined"
          onClick={() => handleEditButtonClicked()}
        >
          Edit
        </Button>
      ];
    } else {
      return [];
    }
  };

  const addUsersToRole = () => {
    dispatch(
      assignUsersToRole(selectedUsers, roleId, () => {
        setAddUserModalOpen(false);
        setFetchRole(true);
      })
    );
  };

  const handleAddUserModalOpening = () => {
    setAddUserModalOpen(true);
    setFetchUsersNotInRole(true);
  };

  const addUserModal = () => {
    return (
      <Modal
        title={"Assign Users to Role"}
        open={addUserModalOpen}
        fullWidth={true}
        fixedHeight={true}
        actions={
          <Fragment>
            <Button
              onClick={() => setAddUserModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={() => addUsersToRole()} color="secondary">
              Add to Role
            </Button>
          </Fragment>
        }
      >
        {loadingUsersInRole ? (
          <Loading />
        ) : (
          <>
            <Search
              searchField={"value"}
              returnSearchMatches={(data) => {
                setSearchedData(data);
              }}
              data={originalData}
              searchTitle="Search Users"
            />
            <SimpleList
              emptyPlaceholder={"All users have been assigned."}
              handleSelection={(selected) => setSelectedUsers(selected)}
              data={searchedData}
            />
          </>
        )}
      </Modal>
    );
  };

  const removeUserModal = () => {
    return (
      <Modal
        title={"Unassign Users from Role"}
        open={removeUserModalOpen}
        fullWidth={true}
        fixedHeight={true}
        actions={
          <Fragment>
            <Button
              onClick={() => setRemoveUserModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={() => handleDeleteUsers()} color="secondary">
              {usersToDelete.length <= 1
                ? "Unassign User from Role"
                : "Unassign Users from Role"}
            </Button>
          </Fragment>
        }
      >
        <Search
          searchField={"value"}
          returnSearchMatches={(data) => {
            setSearchedUsersInRoleData(data);
          }}
          data={originalUsersInRoleData}
          searchTitle="Search Users"
        />
        {loadingUsersInRole ? (
          <Loading />
        ) : (
          <SimpleList
            emptyPlaceholder={"All users have been unassigned."}
            handleSelection={(selected) => setUsersToDelete(selected)}
            data={searchedUsersInRoleData}
          />
        )}
      </Modal>
    );
  };

  const handleDeleteUsers = () => {
    setRemoveUserModalOpen(false);
    setDeleteUsersAlertShowing(true);
  };

  // Validate the field data then update the localFormData with any new error messages
  const checkFieldIsValid = (required, value, type, fieldName, fieldLabel) => {
    let localFormData = formData;

    const errorText = validateField(required, value, type, fieldLabel);

    localFormData[fieldName].errorText = errorText;

    setFormData(localFormData);

    return errorText;
  };

  // When the value of the input changes update the role state
  const handleTextInputChange = (event) => {
    const { name, value } = event.target;

    let localRole = Object.assign({}, role);
    localRole[name] = value;
    dispatch(updateRoleLocally(localRole));

    const formDataField = formData[name];

    checkFieldIsValid(
      formDataField.required,
      value,
      formDataField.type,
      name,
      formDataField.label
    );

    if (!formHasChanged) {
      setFormHasChanged(true);
    }
    if (!submitEnabled) {
      setSubmitEnabled(true);
    }
  };

  const createFormData = () => {
    return (
      <Fragment>
        <Grid item>
          <TextField
            disabled={!editing}
            fullWidth
            error={
              formData.name.errorText.length === 0
                ? false
                : editing
                ? true
                : false
            }
            id={formData.name.id}
            name={formData.name.name}
            label={formData.name.label}
            placeholder={formData.name.placeholder}
            value={role.name || ""}
            onChange={handleTextInputChange}
            variant="outlined"
            margin="dense"
            helperText={editing ? formData.name.errorText : ""}
            style={{
              marginTop: 16
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            disabled={!editing}
            fullWidth
            error={
              formData.description.errorText.length === 0
                ? false
                : editing
                ? true
                : false
            }
            id={formData.description.id}
            name={formData.description.name}
            label={formData.description.label}
            placeholder={formData.description.placeholder}
            value={role.description || ""}
            onChange={handleTextInputChange}
            variant="outlined"
            margin="dense"
            helperText={editing ? formData.description.errorText : ""}
            style={{
              marginTop: 16
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
      </Fragment>
    );
  };

  const buttons =
    editing || isNewRole ? renderSubmitAndCancelButtons() : renderEditButton();

  const getContentGrid = () => {
    const userRoles = Object.values(usersInRole).map((user) => {
      return { name: user.name, territory: user.territory };
    });

    const userTableColumns = [
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "territory",
        label: "Territory",
        options: {
          filter: true,
          sort: false
        }
      }
    ];

    const userTableOptions = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      viewColumns: false,
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: true,
      downloadOptions: {
        filename: "RoleUsers.csv",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      customToolbar: () => {
        if (
          role.name !== "samcore\\SaM Core Admin" ||
          (role.name === "samcore\\SaM Core Admin" &&
            loggedInUserRoles !== undefined &&
            loggedInUserRoles.includes("SaM Core Admin"))
        ) {
          return (
            <Fragment>
              <Tooltip title={"Assign user to role"}>
                <IconButton onClick={() => handleAddUserModalOpening()}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={"Unassign user from role"}
                style={{
                  display:
                    Object.keys(usersInRole).length === 0
                      ? "none"
                      : "inline-flex"
                }}
              >
                <IconButton
                  onClick={() => setRemoveUserModalOpen(true)}
                  edge="end"
                  aria-label="delete"
                >
                  {getIcon("trash.svg", classes.icon)}
                </IconButton>
              </Tooltip>
            </Fragment>
          );
        }
      }
    };

    return (
      <div className={styles.contentGrid}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CustomCard
              title={"Details"}
              showChip={false}
              actionButton={buttons}
            >
              {createFormData()}
            </CustomCard>
          </Grid>
          {isNewRole ? null : (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {loadingUsersInRole ? (
                <Loading />
              ) : (
                <MUIDataTable
                  title="Assigned to Role"
                  data={userRoles}
                  columns={userTableColumns}
                  options={userTableOptions}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  if (role == null) {
    return (
      <Box>
        <Typography>Incorrect Role data</Typography>
      </Box>
    );
  }

  return (
    <div>
      {addUserModal()}
      {removeUserModal()}
      {deleteRoleAlertDialog()}
      {deleteUsersAlertDialog()}
      {cancelAlertDialog()}

      {loadingRole ? (
        <Loading />
      ) : (
        <div>
          <HeaderBlock
            title={role.name}
            right={!isNewRole && renderDeleteButton()}
          />
          <PageContainer>{getContentGrid()}</PageContainer>
        </div>
      )}
    </div>
  );
};

const hoc = withRouter(ViewRolePage);

// EXPORT COMPONENT
export { hoc as ViewRolePage };
