import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
  GET_ALL_SFE_ACTIVATIONS,
  GET_SFE_ACTIVATION_CALCULATION_BY_ID,
  CREATE_SFE_ACTIVATION_CALCULATION,
  GET_DROPDOWN_LOOKUPS
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

/* 
    Get all Activity Groups
*/
export const getAllSFEActivations = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/GetAllSfeActivities`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_ALL_SFE_ACTIVATIONS,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual SFE activation calculation by id
*/
export const getSFEActivationById = (sfeActivationId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        sfeActivationId !== undefined
          ? await adalApiFetch(
              Axios, `${API_CONFIG.ACTIVITIES}/GetSfeActivityById?Id=${sfeActivationId}`,
              {
                method: "get"
              }
            )
          : {};

      dispatch({
        type: GET_SFE_ACTIVATION_CALCULATION_BY_ID,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
      Create a new SFE activation calculation
  */
export const createSFEActivation = (sfeActivation, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertSfeActivity`,
        {
          method: "post",
          headers: headers,
          data: sfeActivation
        }
      );

      dispatch({
        type: CREATE_SFE_ACTIVATION_CALCULATION,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
      Update an existing SFE activation calculation
  */
export const updateSFEActivation = (
  sfeActivationId,
  sfeActivation,
  callback
) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertSfeActivity?Id=${sfeActivationId}`,
        {
          method: "post",
          headers: headers,
          data: sfeActivation
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Get all lookup data related to sfe activations
*/
export const getDropdownLookups = (site, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/GetDropdownLookups?Site=${site}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_DROPDOWN_LOOKUPS,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};
