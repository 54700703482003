import Axios from "axios";
import jwt from "jsonwebtoken";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";
import { getCurrentTimeAsISO } from "../../helpers/time";
import {
  GET_PLANNED_DISPLAYS,
  GET_PLANNED_DISPLAY_DETAILS,
  UPSERT_DISPLAY_PLAN,
  UPSERT_DISPLAY_PLAN_SKU,
  REMOVE_DISPLAY_PLAN_SKU,
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

export const GetPlannedDisplays = (callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING}/ListDisplayPlans`,
        {
            method: "get"
        }
    );
    dispatch({
      type: GET_PLANNED_DISPLAYS,
      payload: response.data,
    });
    
    callback && callback(true);
  } catch (error) {
    console.log(error);
    callback && callback(false);
  }
};

export const GetPlannedDisplayDetails = (plannedDisplayId, callback) => async (dispatch) => {
  if(!plannedDisplayId) {
    // If no id is provided, reset the state
    dispatch({
      type: GET_PLANNED_DISPLAY_DETAILS,
      payload: null,
    });
    return
  }

  try {
    const response = await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING}/DisplayPlanDetail?id=${plannedDisplayId}`,
        {
            method: "get"
        }
    );
      
    dispatch({
      type: GET_PLANNED_DISPLAY_DETAILS,
      payload: response.data,
    });

    callback && callback(true);
  } catch (error) {
    console.log(error);
    callback && callback(false);
  }
}


export const UpsertDisplayPlan = (data, loggedInUser, callback) => async (dispatch) => {
  const now = getCurrentTimeAsISO();

  // Update the modified date.
  // Might handle this at the database level in the future
  const planDataWithModifiedTime = {
    ...data.myDisplaysPlanning,
    ModifiedDate: now
  }

  if (planDataWithModifiedTime.CreatedDate === null) {
    planDataWithModifiedTime.CreatedDate = now;
  }
  
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.TARGETING}/UpsertDisplayPlan`,
      {
        method: "post",
        data: planDataWithModifiedTime,
      }
    );
    
    dispatch({
      type: UPSERT_DISPLAY_PLAN,
      payload: {
        planData: planDataWithModifiedTime,
        planId: response.data,
        loggedInUser
      }
    });
    callback && callback(true, response.data);
  } catch (error) {
    console.log(error);
    callback && callback(false);
  }
};