// React
import React, { Fragment, useState, useEffect, useRef, ChangeEvent } from "react";
import { DefaultRootState, useSelector, useDispatch} from "react-redux";
import { withRouter } from "react-router-dom";
// UI and Styling

import { Box, TextField, FormControl, Button, ButtonGroup, Grid, Paper, List, ListItem, ListItemText, ListItemIcon, IconButton, ListItemSecondaryAction, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from "@material-ui/core";
// Components
import HeaderBlock from "../../components/header-block/HeaderBlock";

// Segments
import PageContainer from "../../components/page-container/PageContainer";
import { DeleteOutlined, Add, Remove, FlareSharp } from "@material-ui/icons";
import Axios from "axios";
import { adalApiFetch } from "../../config/azureConfig";
import { getApiConfig } from "../../config/apiConfig";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Alert } from "@material-ui/lab";
import useExternalScripts from "../../hooks/useExternalScripts";
import BoxSelectCanvas from "../../components/box-select-canvas/BoxSelectCanvas";
import { isAbsolute } from "path";
import { MakePickerOptions } from "@material-ui/pickers/Picker/makePickerWithState";


// Actions
import { getAllSamConfigurations } from "../../state/actions/SECActions";
import { configurationReducerState } from "state/reducers/ConfigurationReducer";
import { RootState } from "state/reducers/AppReducer";
import { FixMeLater } from "types/FixMeLaterType";

const API_CONFIG = getApiConfig();

declare global {
  interface Window {
      mapkit:any;
  }
}

const useStyles = makeStyles((theme) => ({
    map: {
      width: "100%",
      height: "500px"
    }
  }));

const NewLeadsMap = (props: any) => {
  const [mapkitUrl, setMapkitUrl] = useState<string | null>(null);
  const [mapkitLoaded, setMapkitLoaded] = useState<number>(0);
  useExternalScripts({url: mapkitUrl});

  const dispatch = useDispatch();

  const [selectedRoute, setSelectedRoute] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [tooManyPoints, setTooManyPoints] = useState<boolean>(true);
  const [routeChanges, setRouteChanges] = useState<any[]>([]);
  const [routeOptions, setRouteOptions] = useState<any[]>([]);
  const [datePickerDisabled, setDatePickerDisabled] = useState<boolean>(true);
  const [routePickerDisabled, setRoutePickerDisabled] = useState<boolean>(false);
  const [filterUnassigned, setFilterUnassigned] = useState<boolean>(false);
  const [enableBoxSelection, setEnableBoxSelection] = useState<boolean>(false);
  const [boxSelectShouldAdd, setBoxSelectShouldAdd] = useState<boolean>(false);
  const [mapkitConfiguration, setMapkitConfiguration] = useState<any>({});

  const configurations = useSelector<RootState, configurationReducerState>((state) => state.ConfigurationReducer.configurations);
  const loggedInUser = useSelector((state:FixMeLater) => state.UserReducer.loggedInUser);

  const routeChangesRef = useRef<any[]>([]);
  const mapRef = useRef<any>(null);
  const mapContainerRef = useRef<any>(null);
  const selectedRouteRef = useRef<string | null>(null);
  const selectedDateRef = useRef<Date | null>(null);
  const datePickerDisabledRef = useRef<boolean>(true);
  const filterUnassignedRef = useRef<boolean>(false);
  const boxSelectShouldAddRef = useRef<boolean>(false);
  const enableBoxSelectionRef = useRef<boolean>(false);


  useEffect(() => {
    // setLoading(true);

    dispatch(
      getAllSamConfigurations(loggedInUser.territory === "au" ? 1 : 2, () => {
        // setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setupData();
  }, [configurations]);

  const setupData = () => {

    const mapkitMainKey = "NewLeadsConfiguration"
    const mapkitSubKey = "MAP_KIT_JWT"
    const mapkitCode = "MAP_KIT_JWT"
    const mapkitApplication = "SaM Core"

    const filteredConfigurations = configurations
    let data = createData(filteredConfigurations);

    data = data.sort(function (a: any, b: any) {
      const keys = ["mainkey", "subkey", "code", "value"];

      let depth = 0;
      while(depth < (keys.length - 1)) {
        if (a[keys[depth]].toLowerCase() > b[keys[depth]].toLowerCase()) {
          return 1;
        }
        else if (a[keys[depth]].toLowerCase() < b[keys[depth]].toLowerCase()) {
          return -1;
        }
        else if (a[keys[depth]].toLowerCase() === b[keys[depth]].toLowerCase()) {
          depth += 1;
        }
      }
      
      return 0;
    });

    const mapkitData = data.filter((config: any) => {
      return (
        (config.mainkey.includes(mapkitMainKey) &&
          config.subkey.includes(mapkitSubKey) &&
          config.code.includes(mapkitCode) &&
          config.application.includes(mapkitApplication)
        )
      );
    })[0];

    setMapkitConfiguration(mapkitData);
  };

  const createData = (content: any) => {
    return content.map((config: any) => {
      return {
        id: config.id,
        site: config.site,
        application: config.application,
        mainkey: config.mainkey,
        subkey: config.subkey,
        code: config.code,
        value: config.value,
        valueDescription: config.valueDescription
      };
    });
  };

  function setPointColour(annotation: any) {
    if (annotation.data.onMyRoute) {
      if (annotation.data.removeFromRoute) {
        annotation.color = "#e6b3b3";
      } else {
        annotation.color = "#e33e3d";
      }
    } else {
      if (annotation.data.addToRoute) {
        annotation.color = "#23992c";
      } else {
        annotation.color = "#969696";
      }
    }
  }
  useEffect(() => {
    routeChangesRef.current = routeChanges;
  }, [routeChanges]);
  
  useEffect(() => {
    selectedDateRef.current = selectedDate;
  }, [selectedDate]);

  useEffect(() => {
    datePickerDisabledRef.current = datePickerDisabled;
  }, [datePickerDisabled]);

  useEffect(() => {
    boxSelectShouldAddRef.current = boxSelectShouldAdd;
  }, [boxSelectShouldAdd]);

  useEffect(() => {
    enableBoxSelectionRef.current = enableBoxSelection;
  }, [enableBoxSelection]);

  useEffect(() => {
    filterUnassignedRef.current = filterUnassigned;
    if (mapkitLoaded == 0) {
      return;
    }
    if (window.mapkit === undefined) {
      return;
    }
    filterAnnotations();
  }, [filterUnassigned]);

  useEffect(() => {
    selectedRouteRef.current = selectedRoute;
    if (selectedRoute === null) {
      return;
    }
    const response = adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/ScheduledChangesForRoute?route=${selectedRoute}`,
      {
        method: "get",
      }
    ).then((res: any) => {
      setRouteChanges(res.data.map((x: any) => {
        var newItem: any = {
          leadId: x.leadId,
          action: x.action,
          name: x.name,
          effectiveOn: new Date(x.effectiveOn)
        };
        if (newItem.action == "Add") {
          newItem.onMyRoute = false;
          newItem.addToRoute = true
        } else {
          newItem.onMyRoute = true;
          newItem.removeFromRoute = true;
        }
        return newItem;
      }));
    })
    .catch((error: any) => {
      console.log(`Exception Occurred: ${error}`);
    });
    
  }, [selectedRoute]);

  //Use Effects
  useEffect(() => {
    setMapkitUrl("https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js");  

    adalApiFetch(Axios, `${API_CONFIG.ACTIVITIES}/NewLeadsRoutes`,
      {
        method: "get"
    }).then((res: any) => {
      setRouteOptions(res.data);
      setMapkitLoaded(1);
    })
    .catch((error: any) => {
      console.log(`Exception Occurred: ${error}`);
    });
  }, []);
    
  useEffect(() => {
    if (mapkitLoaded == 0) {
      return;
    }
    if (window.mapkit === undefined) {
      return;
    }
    if (mapkitConfiguration === undefined || mapkitConfiguration.value === undefined) {
      return;
    }
    if (mapRef.current != undefined) {
      return;
    }
    const mapkit = window.mapkit;
    const jwt = mapkitConfiguration.value
    mapkit.init({
        authorizationCallback: (done: (arg0: string) => void) => { done(jwt); }
    });

    const nzregion = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(-41.30046, 174.78024),
        new mapkit.CoordinateSpan(12, 5)
    );

    // Create a map in the element whose ID is "map-container"
    const map = new mapkit.Map("map-container");

    var MarkerAnnotation = mapkit.MarkerAnnotation;
    // var p1 = new mapkit.Coordinate(-41.30046, 174.78024);
    // var p2 = new mapkit.Coordinate(-41.30046, 174.58024);
    // var workAnnotation = new MarkerAnnotation(p1);
    // workAnnotation.color = "#969696"; 
    // workAnnotation.title = "Coffee Shop";
    // workAnnotation.animates = false;
    // workAnnotation.subtitle = "Coffee'n'Friends";
    // workAnnotation.glyphText = "L";


    map.region = nzregion;
    map.showsPointsOfInterest = false;
    map.addEventListener("region-change-end", async function(event: any) {
      if (datePickerDisabledRef.current) {
        return;
      }
      try {
        const response = await adalApiFetch(
          Axios,
          `${API_CONFIG.ACTIVITIES}/GetNewLeadsInBox?lat=${map.region.center.latitude}&lon=${map.region.center.longitude}&latdelta=${map.region.span.latitudeDelta}&londelta=${map.region.span.longitudeDelta}`,
          {
            method: "get",
          }
        );
        if (response.data.length >= 100) {
          setTooManyPoints(true);
          // 100 is too many to display on the map, tell the user to zoom in.
          map.annotations = [];
        } else {
          setTooManyPoints(false);
          map.annotations = response.data.map(function(place: any) {
            var point = new mapkit.Coordinate(place.lat, place.lon);
            var annotation = new MarkerAnnotation(point);
            annotation.title = place.name;
            annotation.subtitle = place.routes[0];
            annotation.animates = false;
            annotation.data = {};
            annotation.data.leadId = place.leadId;
            annotation.data.name = place.name;
            annotation.data.routes = place.routes;
            annotation.visible = !filterUnassignedRef.current || annotation.data.routes.length == 0 || annotation.data.routes == undefined;
            var existingData = routeChangesRef.current.find(x => x.leadId === place.leadId);
            if (existingData !== undefined) {
              annotation.data.onMyRoute = existingData.onMyRoute;
              if (annotation.data.onMyRoute) {
                annotation.data.removeFromRoute = existingData.removeFromRoute;
              } else {
                annotation.data.addToRoute = existingData.addToRoute;
              }

            } else {
              // FIXME: pull the route from the select field.
              if (selectedRouteRef.current != null && place.routes.includes(selectedRouteRef.current)) {
                annotation.data.onMyRoute = true;
                annotation.data.removeFromRoute = false;
              } else {
                annotation.data.onMyRoute = false;
                annotation.data.addToRoute = false;
              }
            }

            setPointColour(annotation);
            return annotation;
          });
        }
      }
      catch(error) {
        console.log(error);
      }
    });
    map.addEventListener("select", function(event: any) {
      if(!event.annotation) {
          return;
      }
      var annotation = event.annotation;
      selectAnnotation(annotation);
    });
    mapRef.current = map;
  }, [mapkitLoaded, mapkitConfiguration]);

  const setRoute = (e: any, newValue: React.SetStateAction<string | null>) => {
    setSelectedRoute(newValue);
    setDatePickerDisabled(false);
    setRoutePickerDisabled(true);
  };

  const classes = useStyles();

  function TooManyPoints(props: any) {
    if (datePickerDisabled) {
      return <Alert severity="info">Pick a route to see outlets</Alert>
    } else if (tooManyPoints) {
      return <Alert severity="info">Zoom in to see outlets</Alert>
    } else {
      return <></>
    }
  }

  function removeItem(itemId: number) {
    setRouteChanges(existingChanges => {
      return existingChanges.filter(a => a.leadId !== itemId);
    });
    mapRef.current.annotations.map((annotation: any) => {
      if (annotation.data.leadId === itemId) {
        if (annotation.data.onMyRoute) {
          annotation.data.removeFromRoute = false;
        } else {
          annotation.data.addToRoute = false;
        }
      }
      setPointColour(annotation);
    });
  }

  async function submitChanges() {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.ACTIVITIES}/ScheduleLeadsUpdate`,
        {
          method: "post",
          headers: headers,
          data: {
            Route: selectedRoute,
            RouteChanges: routeChanges.map(x => {
              if (x.effectiveOn === undefined || x.effectiveOn === null) {
                x.effectiveOn = selectedDate;
              }
              if (x.onMyRoute) {
                return {
                  LeadId: x.leadId,
                  Action: "Remove",
                  EffectiveOn: x.effectiveOn 
                };
              } else {
                return {
                  LeadId: x.leadId,
                  Action: "Add",
                  EffectiveOn: x.effectiveOn 
                };
              }})
          }
        }
      );
      
      window.location.reload();

    } catch(error) {
      console.log(error);
    }
  }

  function selectAnnotation(annotation: any, allowSelection: boolean = true, allowDeselection: boolean = true) {

    if (annotation.data.onMyRoute) {
      if (annotation.data.removeFromRoute && allowSelection) {
        // Remove from changes
        annotation.data.removeFromRoute = false;
        setRouteChanges(existingChanges => {
          return existingChanges.filter(a => a.leadId !== annotation.data.leadId);
        });
        setPointColour(annotation);
      } else if (!annotation.data.removeFromRoute && allowDeselection) {
        // Add a record to routeChanges
        annotation.data.removeFromRoute = true;
        var newItem = annotation.data;
        newItem.effectiveOn = selectedDateRef.current;
        setRouteChanges(existingChanges => [newItem, ...existingChanges])
        setPointColour(annotation);
      }

    } else {
      if (annotation.data.addToRoute && allowDeselection) {
        // remove from changes
        annotation.data.addToRoute = false;
        setRouteChanges(existingChanges => {
          return existingChanges.filter(a => a.leadId !== annotation.data.leadId);
        });
        setPointColour(annotation);
      } else if (!annotation.data.addToRoute && allowSelection) {
        // Add a record to routeChanges
        annotation.data.addToRoute = true;
        var newItem = annotation.data;
        newItem.effectiveOn = selectedDateRef.current;
        setRouteChanges(existingChanges => [newItem, ...existingChanges])
        setPointColour(annotation);
      }
    }
  }

  function startBoxSelectMode(shouldSelect: boolean) {
    if (enableBoxSelectionRef.current && boxSelectShouldAddRef.current == shouldSelect) {
      //Box select is already active, toggle it off.
      endBoxSelectMode();
      return;
    }

    mapRef.current.isZoomEnabled = false;
    mapRef.current.isScrollEnabled = false;
    setBoxSelectShouldAdd(shouldSelect);
    setEnableBoxSelection(true);
  }

  function endBoxSelectMode() {
    mapRef.current.isZoomEnabled = true;
    mapRef.current.isScrollEnabled = true;
    setEnableBoxSelection(false);
  }

  //region = [centerX, centerY, extentX, extentY]
  function selectAnnotationsInArea(region: [number, number, number, number]) {
    const mapRect = mapRef.current.visibleMapRect;
    const centerX = ((region[0] / mapContainerRef.current.offsetWidth) * mapRect.size.width) + mapRect.origin.x
    const centerY = ((region[1] / mapContainerRef.current.offsetHeight) * mapRect.size.height) + mapRect.origin.y
    const extentWidth = (region[2] / mapContainerRef.current.offsetWidth) * mapRect.size.width
    const extentHeight = (region[3] / mapContainerRef.current.offsetHeight) * mapRect.size.height


    for(let annotation of mapRef.current.annotations) {
      let position = annotation.coordinate.toMapPoint();
      if (position.x >= centerX - extentWidth && position.x <= centerX + extentWidth &&
          position.y >= centerY - extentHeight && position.y <= centerY + extentHeight &&
          annotation.visible == true) {
            selectAnnotation(annotation, boxSelectShouldAddRef.current, !boxSelectShouldAddRef.current);
          } 
        
    }

    endBoxSelectMode()
  }

  const toggleFilterUnassignedLeads = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterUnassigned(event.target.checked);
  };

  function filterAnnotations() {
    for(let annotation of mapRef.current.annotations) {
      annotation.visible = !filterUnassigned || annotation.data.routes.length == 0 || annotation.data.routes == undefined;
    }
  }

  return (
    <Box>
      <Fragment>
        <HeaderBlock
            title={"New Leads Route Editor"}
        />
        <PageContainer>
          <Paper >
            <Grid
              container
              spacing={0}
              style={{ display: "flex" }}
              alignItems="center" justifyContent="space-between"
            >
              <Grid
                container
                item xs={12}
                spacing={0}
                style={{ display: "flex" }}
                alignItems="center" justifyContent="space-between"
              >
                <Grid item xs={4} style={{margin: "2px"}}>
                  <FormControl 
                    variant="outlined"
                    margin="dense"
                    disabled={routePickerDisabled}
                    fullWidth
                    style={{margin: "16px"}}>
                    <Autocomplete
                      options={routeOptions.map(x => x.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Route"
                          placeholder="Select route..."
                        />
                      )}
                      disabled={routePickerDisabled}
                      value={selectedRoute}
                      onChange={setRoute}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{margin: "2px"}}>
                  <FormControl 
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    style={{margin: "16px"}}>
                    
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="dense"
                        disabled={datePickerDisabled}
                        disableToolbar
                        disablePast
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        label="Apply changes on"
                        value={selectedDate}
                        onChange={(date) => {
                          setSelectedDate(date!)
                        }}
                        // error={formProperties.endDate.errorMessage !== ""}
                        // helperText={formProperties.endDate.errorMessage}
                      />
                    </MuiPickersUtilsProvider>
                    

                  </FormControl>
                </Grid>
                <Grid item xs={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    margin: "2px"
                  }}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    style={{margin: "2px"}}>
                    <ButtonGroup>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          window.location.reload();
                        }}
                        >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        disabled={datePickerDisabled}
                        onClick={() => {
                          submitChanges();
                        }}>
                          Save
                        </Button>
                    </ButtonGroup>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                item xs={12}
                spacing={0}
                style={{ display: "flex", margin: "2px" }}
                alignItems="center" justifyContent="flex-start"
              >
                <Grid item xs={2} style={{margin: "18px"}}>
                  <label>
                    Show only unassigned Leads
                  </label>
                  <Checkbox 
                  name="show only unassigned Leads"
                  checked={filterUnassigned}
                  onChange={toggleFilterUnassignedLeads}/>
                </Grid>
                <Grid item xs={3} style={{margin: "18px"}}>
                  <label style={{margin: "2px"}}>
                    Add Multiple Leads to Route
                  </label>
                  <Button
                    variant= { enableBoxSelection && boxSelectShouldAdd ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => {
                      startBoxSelectMode(true);
                    }}
                    >
                    +
                  </Button>
                </Grid>
                <Grid item xs={3} style={{margin: "18px"}}>
                  <label style={{margin: "2px"}}>
                    Remove Multiple Leads from Route
                  </label>
                  <Button
                    variant= { enableBoxSelection && !boxSelectShouldAdd ? "contained" : "outlined"}
                    color="secondary"
                    onClick={() => {
                      startBoxSelectMode(false);
                    }}
                    >
                    -
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          
          <Grid
              container
              spacing={0}
              style={{ display: "flex", margin: "10px 0 0 0"}}
            >
            <Grid item xs={8}>
              <div style={{position: "relative"}}>
                <TooManyPoints />
                <div className={classes.map} ref={mapContainerRef} id="map-container"></div>
                { enableBoxSelection ? <BoxSelectCanvas finishBoxSelection={selectAnnotationsInArea}/>: <></>}
              </div>
            </Grid>
            
            <Grid item xs={4} style={{background: "white"}}>
              <h2 style={{margin: "8px 0 0 16px"}}>Scheduled Changes</h2>
              <List>
                {routeChanges.map(function(data, index) {
                  if (data.onMyRoute) {
                    return (
                      <ListItem
                        key={data.leadId}
                        style={{background: "#fee"}}
                        >
                        <ListItemIcon>
                          <Remove />
                        </ListItemIcon>
                        <ListItemText
                          primary={data.name}
                          secondary={`Remove from ${selectedRoute} on ${data.effectiveOn?.toLocaleDateString("en-AU")}`}
                        />

                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => {
                            removeItem(data.leadId)
                          }}>
                            <DeleteOutlined />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>);
                  } else {
                    return (
                      <ListItem 
                        key={data.leadId}
                        style={{background: "#efe"}}
                        >
                        <ListItemIcon>
                          <Add />
                        </ListItemIcon>
                        <ListItemText
                          primary={data.name}
                          secondary={`Add to ${selectedRoute} on ${data.effectiveOn?.toLocaleDateString("en-AU")}`}
                        />

                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => {
                            removeItem(data.leadId)
                          }}>
                            <DeleteOutlined />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>);
                  }
                })}
              </List>
            </Grid>
          </Grid>
          
        </PageContainer>
      </Fragment>
    </Box>
  );
}

const hoc = withRouter(NewLeadsMap);

// EXPORT COMPONENT
export { hoc as NewLeadsMap };
