// React
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import { Button } from "@material-ui/core";

// Components
import Modal from "../../components/dialog/Modal";
import ScrollableTabs from "../../components/tabs/Tabs";

// Containers
import GenericPicker from "../generic-picker/GenericPicker";

const TargetedContentPickerModal = (props) => {
  const [selection, setSelection] = useState([]);

  const handleSelection = (newSelection) => {
    setSelection(
      selection.concat(
        newSelection.filter((item) => selection.indexOf(item) < 0)
      )
    );
  };

  const getTabs = () => {
    const linksData = props.data["links"];
    const allOtherData = [];

    Object.keys(props.data).forEach((key) => {
      if (key !== "links") {
        props.data[key].forEach((item) => allOtherData.push(item));
      }
    });
    return [
      {
        name: "Files",
        key: "files",
        content: (
          <GenericPicker
            selected={props.selected}
            handleSelection={handleSelection}
            searchTitle="Files"
            data={allOtherData}
            idField="id"
            valueField="name"
            secondaryValueField="extraData"
            currentSelection={selection}
          />
        )
      },
      {
        name: "Links",
        key: "links",
        content: (
          <GenericPicker
            selected={props.selected}
            handleSelection={handleSelection}
            searchTitle="Links"
            data={linksData}
            idField="id"
            valueField="name"
            secondaryValueField="extraData"
            currentSelection={selection}
          />
        )
      }
    ];
  };

  return (
    <Modal
      title={"Content"}
      open={props.open}
      fullWidth={true}
      fixedHeight={true}
      actions={
        <div>
          <Button color="secondary" onClick={props.handleCancel}>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              props.handleDone(selection);
              setSelection([]);
            }}
          >
            Done
          </Button>
        </div>
      }
    >
      <ScrollableTabs tabs={getTabs()} />
    </Modal>
  );
};

const hoc = withRouter(TargetedContentPickerModal);

// EXPORT COMPONENT
export { hoc as TargetedContentPickerModal };
