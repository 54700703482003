import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Chip, Divider, Grid, Typography } from "@material-ui/core";

export const ActivityTarget = ({ displayGroupId, target }) => {
  const history = useHistory();

  const hasValue = (array) => {
    if (array !== undefined && array !== null && array.length > 0) {
      return (
        <>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginTop: "2.5rem" }}
          >
            <Grid item xs={6}>
              <Typography variant="h6">
                <strong>RULES</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">
                <strong>VALUE</strong>
              </Typography>
            </Grid>
          </Grid>
          <Divider
            style={{
              height: "1px",
              backgroundColor: "#000000",
            }}
          />
        </>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <span style={{ fontSize: "16px", color: "#9ca3af" }}>
            This target has no rules
          </span>
        </div>
      );
    }
  };

  const ruleList = (array) => {
    if (array !== undefined && array !== null && array.length > 0) {
      let list = array.map((rule) => {
        return (
          <Grid
            container
            alignItems="center"
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            <Grid item xs={6}>
              <Typography>{rule.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                style={{ fontWeight: 600 }}
                color="primary"
                label={rule.value}
              />
            </Grid>
          </Grid>
        );
      });
      return list;
    } else {
      return null;
    }
  };

  return (
    <div className="create-container" style={{ marginTop: "2rem" }}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div>
          <Typography variant="h6" style={{ marginBottom: "0.5rem" }}>
            <strong>TARGET - {target.id}</strong>
          </Typography>
          <span
            className={`${
              target.isActive ? "active-tag-text" : "inactive-tag-text"
            }`}
          >
            {target.isActive ? "Active" : "Inactive"}
          </span>
        </div>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          style={{ textTransform: "capitalize", marginTop: "15px" }}
          onClick={() =>
            history.push(
              `/my-displays-maintenance/${displayGroupId}/target/${target.id}`
            )
          }
        >
          Edit Target
        </Button>
      </Grid>

      {hasValue(target.rules)}

      <div style={{ marginTop: "1rem" }}>{ruleList(target.rules)}</div>
    </div>
  );
};
