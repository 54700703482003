export const TargetedContentType = Object.freeze({
  FILE: "File",
  LINK: "Link",
  THREE_DIMENSIONAL_POS: "3D POS",
  SAMC: "SaMC",
  NEWLEADS: "New Leads"
});

export function targetedContentTypeToIndex(targetedContentType) {
  switch (targetedContentType) {
    case TargetedContentType.FILE:
      return 0;
    case TargetedContentType.LINK:
      return 1;
    case TargetedContentType.THREE_DIMENSIONAL_POS:
      return 2;
    case TargetedContentType.SAMC:
      return 0;
    case TargetedContentType.NEWLEADS:
      return 1;
    default:
      return null;
  }
}
