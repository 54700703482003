// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField
} from "@material-ui/core";
import styles from "./ViewActivityGroupPage.module.scss";

// Actions
import {
  createChoice,
  getChoiceById,
  getDropdownLookups,
  updateChoice
} from "../../state/actions/ActivityActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import PageContainer from "../../components/page-container/PageContainer";
import EditingAlert from "../../components/editing-alert/EditingAlert";

// Models
import { ChoiceModel } from "../../models/ChoiceModel";

// Other
import { FormType } from "../../state/constants/FormType";
import { validateField } from "../../helpers/validation";

const ViewChoicePage = (props) => {
  // Content State
  const [choice, setChoice] = useState({});
  const [uneditedChoice, setUneditedChoice] = useState({});
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedPackTypes, setSelectedPackTypes] = useState([]);
  const [selectedPackSizes, setSelectedPackSizes] = useState([]);
  const [selectedBeverageCategories, setSelectedBeverageCategories] = useState([]);

  // Lookups
  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);
  const [brandLookupData, setBrandLookupData] = useState([]);
  const [materialLookupData, setMaterialLookupData] = useState([]);
  const [beverageCategoryLookupData, setBeverageCategoryLookupData] = useState([]);
  const [packTypeLookupData, setPackTypeLookupData] = useState([]);
  const [packSizeLookupData, setPackSizeLookupData] = useState([]);

  // Page Meta State (Loadings, APIs, etc.)
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingChoice, setIsExistingChoice] = useState(true);
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [openEditingAlert, setOpenEditingAlert] = useState(false);

  // Utilities (3rd Party/Misc)
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const dispatch = useDispatch();
  const [formProperties, setFormProperties] = useState(ChoiceModel);

  // Page Constants
  const currentChoice = useSelector((state) => state.ActivityReducer.currentChoice);
  const activityId = props.match.params.activityId;
  const activityGroupId = props.match.params.activityGroupId;
  const choiceName = props.match.params.choiceId;
  const activityName = new URLSearchParams(props.location.search).get(
    "activity"
  );

  const isRanging = new URLSearchParams(props.location.search).get("isRanging");

  // Currently selected brands
  const selectedBrands =
    choice.brand !== undefined && Object.keys(choice.brand).length !== 0
      ? choice.brand.map((b) => {
        return brandLookupData.length > 0 ? brandLookupData.find(
          (possibleBrand) => possibleBrand.Id === b.Id
        ) : b
      })
      : [];

  //Use Effects
  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setUpData();
        })
      );
    } else {
      setUpData();
    }
  }, []);

  useEffect(() => {
    // First 4 blocks here are for state management of dropdown items and their selections.
    let localSelectedMaterials = Object.assign({}, selectedMaterials);
    localSelectedMaterials = choice.material !== undefined && choice.material[0] != null
      ? choice.material.map((b) => {
        return materialLookupData.length > 0 ? materialLookupData.find(
          (possibility) => {
            return possibility.Id === b.Id
          }
        ) : b
      })
      : [];

    let localSelectedPackTypes = Object.assign({}, selectedPackTypes);
    localSelectedPackTypes = choice.packType !== undefined && choice.packType[0] != null
      ? choice.packType.map((b) => {
        return packTypeLookupData.length > 0 ? packTypeLookupData.find(
          (possibility) => {
            return possibility.Id === b.Id
          }
        ) : b
      })
      : [];

    let localSelectedPackSizes = Object.assign({}, selectedPackSizes);
    localSelectedPackSizes = choice.packSize !== undefined && choice.packSize[0] != null
      ? choice.packSize.map((b) => {
        return packSizeLookupData.length > 0 ? packSizeLookupData.find(
          (possibility) => {
            return possibility.Id === b.Id
          }
        ) : b
      })
      : [];

    let localSelectedBeverageCategories = Object.assign({}, selectedBeverageCategories);
    localSelectedBeverageCategories = choice.beverageCategory !== undefined && choice.beverageCategory[0] != null
      ? choice.beverageCategory.map((b) => {
        return beverageCategoryLookupData.length > 0 ? beverageCategoryLookupData.find(
          (possibility) => {
            return possibility.Id === b.Id
          }
        ) : b
      })
      : [];

    // If the choice material doesn't exist in our site's version of material lookups, turn a flag on alerting the user to this.
    if (Array.isArray(choice.material) && (choice.material[0] == undefined || choice.material[0] == null) && !formEdited) {
      setOpenEditingAlert(true);
    } else {
      setOpenEditingAlert(false);
    }

    setSelectedMaterials(localSelectedMaterials);
    setSelectedPackTypes(localSelectedPackTypes);
    setSelectedPackSizes(localSelectedPackSizes);
    setSelectedBeverageCategories(localSelectedBeverageCategories);

  }, [choice]);

  useEffect(() => {
    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getChoiceById(activityId, choiceName, () => {
          setLoading(false);
        })
      );
    } else {
      dispatch(
        getChoiceById(undefined, undefined, () => {
          setIsEditing(true);
          setLoading(false);
        })
      );
    }
  }, [lookupData])

  const setUpData = () => {
    setIsExistingChoice(props.type != null && props.type === FormType.VIEW);

    //Get all dropdown lookup data
    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => { })
    );
  };


  useEffect(() => {
    setBrandLookupData(lookupData.Brands);
    setMaterialLookupData(lookupData.Materials);
    setBeverageCategoryLookupData(lookupData.BeverageCategories);
    setPackTypeLookupData(lookupData.PackTypes);
    setPackSizeLookupData(lookupData.PackSizes);
  }, [lookupData]);

  useEffect(() => {
    setChoice(currentChoice);
  }, [currentChoice]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, choice);

    localData[name] = value;

    setChoice(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      choice[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }
    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      if (
        (formProperties[key].isRangingField && isRanging === "true") ||
        (!formProperties[key].isRangingField && isRanging === "false")
      ) {
        errorMessage = checkGenericField(key, formProperties[key].required);
      }

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    let requestBody = {
      ActivityId: activityId,
      Name: isRanging === "true" ? choice.name : choice.nonRangingName,
      Weight:
        isRanging === "true" && choice.rangingType !== "Weight Selection"
          ? 0
          : choice.nonRangingWeight !== null
            ? choice.nonRangingWeight
            : 0,
      IsActive: isRanging === "true" ? choice.active : choice.nonRangingActive,
      SelectionType:
        isRanging === "true"
          ? choice.rangingType === "Product Selection"
            ? 1
            : choice.rangingType === "Weight Selection" ? 3 : 2
          : 1,
      Brand: isRanging === "true" && choice.rangingType !== "Weight Selection" && choice?.brand.length > 0 ? choice.brand.map((item) => item.Id).join(',') : null,
      Material: isRanging === "true" && choice.rangingType !== "Weight Selection" && choice?.material.length > 0 ? choice.material.map((item) => item.Id).join(',') : null,
      PackType: isRanging === "true" && choice.rangingType !== "Weight Selection" && choice?.packType.length > 0 ? choice.packType.map((item) => item.Id).join(',') : null,
      PackSize: isRanging === "true" && choice.rangingType !== "Weight Selection" && choice?.packSize.length > 0 ? choice.packSize.map((item) => item.Id).join(',') : null,
      BeverageCategory: isRanging === "true" && choice.rangingType !== "Weight Selection" && choice?.beverageCategory.length > 0 ? choice.beverageCategory.map((item) => item.Id).join(',') : null,
      SubText: isRanging === "true" && choice.rangingType !== "Weight Selection" ? choice.subText : null,
      MinimumVariants: isRanging === "true" && choice.rangingType !== "Weight Selection" ? choice.minVariants : 0,
      Image1: isRanging === "true" && choice.rangingType !== "Weight Selection" ? choice.imageOne : null,
      Image2: isRanging === "true" && choice.rangingType !== "Weight Selection" ? choice.imageTwo : null,
      Image3: isRanging === "true" && choice.rangingType !== "Weight Selection" ? choice.imageThree : null
    };

    if (isCreate) {
      requestBody.CreatedBy = loggedInUser.userId;
      requestBody.ModifiedBy = loggedInUser.userId;
    } else {
      requestBody.ModifiedBy = loggedInUser.userId;
    }

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      dispatch(
        createChoice(createRequestBody(true), (success, id) => {
          setIsEditing(false);
          props.history.push(
            `/activity-groups/${activityGroupId}/${activityId}`
          );
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateChoice(activityId, choiceName, createRequestBody(false), () => {
          setIsEditing(false);

          const currentName =
            isRanging === "true" ? choice.name : choice.nonRangingName;

          props.history.push(
            `/activity-groups/${activityGroupId}/${activityId}/choice/${currentName}?isRanging=${isRanging}&activity=${activityName}`
          );
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingChoice) {
        props.history.push(`/activity-groups/${activityGroupId}/${activityId}`);
      }
    }
  };

  /*
Display the cancel editing alert dialog
*/
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setChoice(uneditedChoice);
              setCancelEditingAlertOpen(false);

              if (!isExistingChoice) {
                props.history.push(
                  `/activity-groups/${activityGroupId}/${activityId}`
                );
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  const renderNonRangingForm = () => {
    return (
      <Fragment>
        <div className={styles.contentGrid}>
          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.nonRangingName.value}
                  name={formProperties.nonRangingName.value}
                  label={formProperties.nonRangingName.label}
                  placeholder="Enter name"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.nonRangingName !== undefined
                      ? choice.nonRangingName
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.nonRangingName.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.nonRangingName.errorMessage !== ""}
                  helperText={formProperties.nonRangingName.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.nonRangingWeight.value}
                  name={formProperties.nonRangingWeight.value}
                  label={formProperties.nonRangingWeight.label}
                  placeholder="Enter weight"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.nonRangingWeight !== undefined
                      ? choice.nonRangingWeight
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.nonRangingWeight.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.nonRangingWeight.errorMessage !== ""}
                  helperText={formProperties.nonRangingWeight.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.nonRangingActive.label}
                  control={
                    <Checkbox
                      checked={
                        choice !== undefined &&
                          choice.nonRangingActive !== undefined
                          ? choice.nonRangingActive
                          : true
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.nonRangingActive.value,
                          !choice.nonRangingActive
                        )
                      }
                      name={formProperties.nonRangingActive.value}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  const renderRangingForm = () => {
    return (
      <Fragment>
        <div className={styles.contentGrid}>

          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.name.value}
                  name={formProperties.name.value}
                  label={formProperties.name.label}
                  placeholder="Enter name"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.name !== undefined
                      ? choice.name
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.name.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.name.errorMessage !== ""}
                  helperText={formProperties.name.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.rangingType.label}
                  handleChange={(value) => {
                    handleInputChange(formProperties.rangingType.value, value);
                  }}
                  data={["Product Selection", "Brand & Material Selection", "Weight Selection"]}
                  value={choice.rangingType}
                  valueName={formProperties.rangingType.value}
                  error={formProperties.rangingType.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.rangingType.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.rangingType.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.active.label}
                  control={
                    <Checkbox
                      checked={
                        choice !== undefined && choice.active !== undefined
                          ? choice.active
                          : false
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.active.value,
                          !choice.active
                        )
                      }
                      name={formProperties.active.value}
                      color="primary"
                    />
                  }
                />
              </Grid>

              {/* Looks like the weight is finally over. */}
              <Grid
                item
                xs={12}
                style={{
                  display:
                    choice.rangingType === "Weight Selection"
                      ? "inline-flex"
                      : "none"
                }}
              >
                <TextField
                  disabled={!isEditing}
                  id={formProperties.nonRangingWeight.value}
                  name={formProperties.nonRangingWeight.value}
                  label={formProperties.nonRangingWeight.label}
                  placeholder="Enter Weighting"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.nonRangingWeight !== undefined
                      ? choice.nonRangingWeight
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.nonRangingWeight.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.nonRangingWeight.errorMessage !== ""}
                  helperText={formProperties.nonRangingWeight.errorMessage}
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display:
                    choice.rangingType === "Brand & Material Selection"
                      ? "inline-flex"
                      : "none"
                }}
              >
                <MultiSelectDropDown
                  id={`brand`}
                  fullWidth={true}
                  multiple={true}
                  disableCloseOnSelect={true}
                  showCheckbox={true}
                  disabled={!isEditing}
                  label={formProperties.brand.label}
                  options={brandLookupData}
                  selectedValues={selectedBrands}
                  onChange={(event, value) => {
                    handleInputChange(formProperties.brand.value, value);
                  }}
                  variant="outlined"
                  textFieldLabel="Select Brand(s)"
                  textFieldPlaceHolder="Brands"
                />
                {formProperties.brand.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.brand.errorMessage}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display:
                    choice.rangingType === "Brand & Material Selection"
                      ? "inline-flex"
                      : "none"
                }}
              >
                <MultiSelectDropDown
                  id={`material`}
                  fullWidth={true}
                  multiple={true}
                  disableCloseOnSelect={true}
                  showCheckbox={true}
                  disabled={!isEditing}
                  label={formProperties.material.label}
                  options={materialLookupData}
                  selectedValues={selectedMaterials}
                  onChange={(event, value) => {
                    handleInputChange(formProperties.material.value, value);
                  }}
                  variant="outlined"
                  textFieldLabel="Select Material(s)"
                  textFieldPlaceHolder="Material(s)"
                />
                {formProperties.material.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.material.errorMessage}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display:
                    choice.rangingType === "Product Selection"
                      ? "inline-flex"
                      : "none"
                }}
              >
                {/* <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.packType.label}
                  handleChange={(value) => {
                    handleInputChange(formProperties.packType.value, value);
                  }}
                  data={
                    packTypeLookupData !== undefined &&
                      packTypeLookupData.length > 0
                      ? packTypeLookupData.map((t) => {
                        return { key: t.Id, value: t.DisplayName };
                      })
                      : []
                  }
                  value={choice.packType}
                  valueName={formProperties.packType.value}
                  error={formProperties.packType.errorMessage !== ""}
                  fullWidth={true}
                > */}
                <MultiSelectDropDown
                  id={`packType`}
                  fullWidth={true}
                  multiple={true}
                  disableCloseOnSelect={true}
                  showCheckbox={true}
                  disabled={!isEditing}
                  label={formProperties.packType.label}
                  options={packTypeLookupData}
                  selectedValues={selectedPackTypes}
                  onChange={(event, value) => {
                    handleInputChange(formProperties.packType.value, value);
                  }}
                  variant="outlined"
                  textFieldLabel="Select Pack Type(s)"
                  textFieldPlaceHolder="Pack Types"
                />
                {formProperties.packType.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.packType.errorMessage}
                  </FormHelperText>
                ) : null}
                {/* </DropdownSelect> */}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display:
                    choice.rangingType === "Product Selection"
                      ? "inline-flex"
                      : "none"
                }}
              >
                {/* <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.packSize.label}
                  handleChange={(value) => {
                    handleInputChange(formProperties.packSize.value, value);
                  }}
                  data={
                    packSizeLookupData !== undefined &&
                      packSizeLookupData.length > 0
                      ? packSizeLookupData.map((t) => {
                        return { key: t.Id, value: t.DisplayName };
                      })
                      : []
                  }
                  value={choice.packSize}
                  valueName={formProperties.packSize.value}
                  error={formProperties.packSize.errorMessage !== ""}
                  fullWidth={true}
                > */}
                <MultiSelectDropDown
                  id={`packSize`}
                  fullWidth={true}
                  multiple={true}
                  disableCloseOnSelect={true}
                  showCheckbox={true}
                  disabled={!isEditing}
                  label={formProperties.packSize.label}
                  options={packSizeLookupData}
                  selectedValues={selectedPackSizes}
                  onChange={(event, value) => {
                    handleInputChange(formProperties.packSize.value, value);
                  }}
                  variant="outlined"
                  textFieldLabel="Select Pack Size(s)"
                  textFieldPlaceHolder="Pack Size(s)"
                />
                {formProperties.packSize.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.packSize.errorMessage}
                  </FormHelperText>
                ) : null}
                {/* </DropdownSelect> */}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display:
                    choice.rangingType === "Product Selection"
                      ? "inline-flex"
                      : "none"
                }}
              >
                {/* <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.beverageCategory.label}
                  handleChange={(value) => {
                    handleInputChange(
                      formProperties.beverageCategory.value,
                      value
                    );
                  }}
                  data={
                    beverageCategoryLookupData !== undefined &&
                      beverageCategoryLookupData.length > 0
                      ? beverageCategoryLookupData.map((t) => {
                        return { key: t.Id, value: t.DisplayName };
                      })
                      : []
                  }
                  value={choice.beverageCategory}
                  valueName={formProperties.beverageCategory.value}
                  error={formProperties.beverageCategory.errorMessage !== ""}
                  fullWidth={true}
                > */}
                <MultiSelectDropDown
                  id={`beverageCategory`}
                  fullWidth={true}
                  multiple={true}
                  disableCloseOnSelect={true}
                  showCheckbox={true}
                  disabled={!isEditing}
                  label={formProperties.beverageCategory.label}
                  options={beverageCategoryLookupData}
                  selectedValues={selectedBeverageCategories}
                  onChange={(event, value) => {
                    handleInputChange(formProperties.beverageCategory.value, value);
                  }}
                  variant="outlined"
                  textFieldLabel="Select Beverage Category(s)"
                  textFieldPlaceHolder="Beverage Category(s)"
                />
                {formProperties.beverageCategory.errorMessage !== "" ? (
                  <FormHelperText>
                    {formProperties.beverageCategory.errorMessage}
                  </FormHelperText>
                ) : null}
                {/* </DropdownSelect> */}
              </Grid>
              <Grid item xs={12} md={6}
                style={{
                  display:
                    choice.rangingType !== "Weight Selection"
                      ? "inline-flex"
                      : "none"
                }}>
                <DropdownSelect
                  disabled={!isEditing}
                  label={formProperties.minVariants.label}
                  handleChange={(value) => {
                    handleInputChange(formProperties.minVariants.value, value);
                  }}
                  data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]}
                  value={choice.minVariants}
                  valueName={formProperties.minVariants.value}
                  error={formProperties.minVariants.errorMessage !== ""}
                  fullWidth={true}
                >
                  {formProperties.minVariants.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.minVariants.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={6}
                style={{
                  display:
                    choice.rangingType !== "Weight Selection"
                      ? "inline-flex"
                      : "none"
                }}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.subText.value}
                  name={formProperties.subText.value}
                  label={formProperties.subText.label}
                  placeholder="Enter sub text"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.subText !== undefined
                      ? choice.subText
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.subText.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.subText.errorMessage !== ""}
                  helperText={formProperties.subText.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}
                style={{
                  display:
                    choice.rangingType !== "Weight Selection"
                      ? "inline-flex"
                      : "none"
                }}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.imageOne.value}
                  name={formProperties.imageOne.value}
                  label={formProperties.imageOne.label}
                  placeholder="Enter image one"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.imageOne !== undefined
                      ? choice.imageOne
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.imageOne.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.imageOne.errorMessage !== ""}
                  helperText={formProperties.imageOne.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}
                style={{
                  display:
                    choice.rangingType !== "Weight Selection"
                      ? "inline-flex"
                      : "none"
                }}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.imageTwo.value}
                  name={formProperties.imageTwo.value}
                  label={formProperties.imageTwo.label}
                  placeholder="Enter image two"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.imageTwo !== undefined
                      ? choice.imageTwo
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.imageTwo.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.imageTwo.errorMessage !== ""}
                  helperText={formProperties.imageTwo.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}
                style={{
                  display:
                    choice.rangingType !== "Weight Selection"
                      ? "inline-flex"
                      : "none"
                }}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.imageThree.value}
                  name={formProperties.imageThree.value}
                  label={formProperties.imageThree.label}
                  placeholder="Enter image three"
                  value={
                    choice !== undefined &&
                      Object.keys(choice).length > 0 &&
                      choice.imageThree !== undefined
                      ? choice.imageThree
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.imageThree.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.imageThree.errorMessage !== ""}
                  helperText={formProperties.imageThree.errorMessage}
                />
              </Grid>

            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>

      <HeaderBlock
        title={`${activityName} - ${
          isExistingChoice ? choice.name : "New Choice"
          }`}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">

            <Grid item>

              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedChoice(choice);
                    } else if (isEditing && isExistingChoice) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing ? (isExistingChoice ? "Done" : "Submit") : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelEditingAlert()}

      <PageContainer>
        <EditingAlert
          isEditing={openEditingAlert && choice?.rangingType === "Brand & Material Selection"}
          title={"Unmatched Materials"}
          severity={"info"}
          body={["This choice had material data associated with it, but those materials have either been deactivated, deleted, or are not visible to your current Territory."]}
        />
        {isRanging === "true" ? renderRangingForm() : renderNonRangingForm()}
      </PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewChoicePage);

// EXPORT COMPONENT
export { hoc as ViewChoicePage };
