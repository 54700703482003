// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";

// UI and Styling
import {
  Box,
  Button,
  ButtonGroup,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "../../components/dialog/Modal";
import styles from "./NewTargetGroupModal.module.scss";

// Actions
import {
  createTargetGroup,
  getTargetGroup,
  getTargetRules,
  resetLocalTargetGroup,
  updateTargetGroup,
  previewSync,
} from "../../state/actions/TargetGroupActions";
import { getCampaignsByTargetGroupId } from "../../state/actions/CampaignActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";
import { getDropdownLookups } from "../../state/actions/ActivityActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import CustomizedSnackbars from "../../components/snackbar/Snackbar";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { ListableRulesCard } from "../../components/listable-rules-card/ListableRulesCard";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";

// Models
import { TargetGroupModel } from "../../models/TargetGroupModel";

// Other
import { ModelType } from "../../state/constants/ModelType";
import { TargetFields } from "../../state/constants/TargetFields";
import { FormType } from "../../state/constants/FormType";
import { UUID } from "../../helpers/uuid";
import { validateField } from "../../helpers/validation";
import { Territories } from "../../state/constants/Territories";
import copy from "copy-to-clipboard";

/*
    Component entry point
*/
export const TargetGroupModal = ({
  targetGroupError,
  setTargetGroupError,
  setTargetModalOpen,
  targetModalOpen,
  targetGroupId,
  type,
  isModal,
  createdUser,
}) => {
  // Reducer logic
  const currentTargetGroupRules = useSelector(
    (state) => state.TargetGroupReducer.targetGroupRules
  );

  const campaignsRelatedToTargetGroup = useSelector(
    (state) => state.CampaignReducer.campaignsRelatedToTargetGroup
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );
  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);

  const dispatch = useDispatch();

  // State Logic
  const [loading, setLoading] = useState(false);
  const [targetGroup, setTargetGroup] = useState({});
  const [targetGroupRules, setTargetGroupRules] = useState({});
  const [uneditedTargetGroup, setUneditedTargetGroup] = useState({});
  const [modelTypeData, setModelTypeData] = useState(TargetFields);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [
    cancelTargetGroupEditingAlertOpen,
    setCancelTargetGroupEditingAlertOpen,
  ] = useState(false);
  const [isEditing, setIsEditing] = useState(
    type != null && type !== FormType.VIEW
  );
  const [formProperties, setFormProperties] = useState(TargetGroupModel);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [formDataUpdated, setFormDataUpdated] = useState(false);
  const [isNewTargetGroup, setIsNewTargetGroup] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [isContentAdmin, setIsContentAdmin] = useState(false);
  const [getTargetGroupCallMade, setGetTargetGroupCallMade] = useState(false);
  const [getTargetRulesCallMade, setGetTargetRulesCallMade] = useState(false);
  const [lookupsCallMade, setLookupsCallMade] = useState(false);
  const [
    getCampaignsByTargetGroupCallMade,
    setGetCampaignsByTargetGroupCallMade,
  ] = useState(false);
  const [getLoggedInUserCallMade, setGetLoggedInUserCallMade] = useState(false);
  const [currentCampaignText, setCurrentCampaignText] = useState("");
  const [routeLookupData, setRouteLookupData] = useState([]);
  const [routeErrorMessage, setRouteErrorMessage] = useState("");
  const [routeId, setRouteId] = useState("");

  const [redChannelValues, setRedChannelValues] = useState([]);

  const [contentValues, setContentValues] = useState([]);

  useEffect(() => {
    setLoading(true);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setGetLoggedInUserCallMade(true);
        })
      );
    } else {
      setGetLoggedInUserCallMade(true);
    }

    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => {
        setLookupsCallMade(true);
      })
    );
    setIsNewTargetGroup(true);
    dispatch(
      getTargetRules(() => {
        setGetTargetRulesCallMade(true);
      })
    );
    return () => {
      dispatch(resetLocalTargetGroup(() => {}));
    };
  }, []);

  useEffect(() => {
    if (
      loggedInUserRoles !== undefined &&
      (loggedInUserRoles.includes("Content Admin") ||
        loggedInUserRoles.includes("SaM Core Admin"))
    ) {
      setIsContentAdmin(true);
    } else {
      setIsContentAdmin(false);
    }
  }, [loggedInUserRoles]);

  useEffect(() => {
    setRouteLookupData(lookupData.Routes);
    updateRouteLookupData();
  }, [lookupData]);

  useEffect(() => {
    setTargetGroupRules(currentTargetGroupRules);
    setRedChannelValues(
      currentTargetGroupRules?.CustomerFilters?.find(
        (item) => item.RuleName === "Customer_RedChannel"
      )
    );
  }, [currentTargetGroupRules]);

  useEffect(() => {
    const localTargetGroup = {};

    var defaultModel = ModelType.CUSTOMER;
    if (typeof defaultModel !== "undefined") {
      defaultModel = defaultModel;
    }

    //Set default property values
    localTargetGroup.Name = "";
    localTargetGroup.Description = "";
    localTargetGroup.ModelType = defaultModel;
    localTargetGroup.Rules = [];
    localTargetGroup.Site = "au";

    if (type != null && type === FormType.VIEW) {
      setTargetGroup(localTargetGroup);
    } else {
      setTargetGroup({});
    }
  }, []);

  useEffect(() => {
    if (type != null && type === FormType.VIEW) {
      let localWarning =
        "Warning: This target group is part of the following campaigns: ";
      Object.values(campaignsRelatedToTargetGroup).forEach((ctg) => {
        localWarning = `${localWarning} ${ctg.Name}, `;
      });

      localWarning = localWarning.substring(0, localWarning.lastIndexOf(","));

      setCurrentCampaignText(localWarning);
    }
  }, [campaignsRelatedToTargetGroup]);

  useEffect(() => {
    if (
      (getTargetGroupCallMade,
      getTargetRulesCallMade,
      getCampaignsByTargetGroupCallMade,
      getLoggedInUserCallMade,
      lookupsCallMade)
    ) {
      setLoading(false);
    }
  }, [
    getTargetGroupCallMade,
    getTargetRulesCallMade,
    getCampaignsByTargetGroupCallMade,
    getLoggedInUserCallMade,
    lookupsCallMade,
  ]);

  useEffect(() => {
    if (isEditing) {
      setSubmitEnabled(true);
    }
  }, [isEditing]);

  const updateRouteLookupData = () => {
    setContentValues(
      routeLookupData !== undefined && routeLookupData.length > 0
        ? routeLookupData
            .sort(function (a, b) {
              return a.DisplayName.localeCompare(b.DisplayName);
            })
            .map((t) => {
              return t.DisplayName;
            })
            .filter((t) => {
              if (process.env.NODE_ENV !== "production") {
                return t !== "DO NOT USE" && t !== "Route setup for load test";
              } else {
                return t;
              }
            })
        : []
    );
  };

  // Validate the field data then update the localFormData with any new error messages
  const checkFieldIsValid = (required, value, type, fieldName, fieldLabel) => {
    let localFormData = formProperties;

    const errorText = validateField(required, value, type, fieldLabel);

    localFormData[fieldName].errorText = errorText;

    setFormProperties(localFormData);

    return errorText;
  };

  // Validate the form using the fields from TargetGroupModel
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;

    Object.values(formProperties).forEach((field) => {
      const errorText = checkFieldIsValid(
        field.required,
        targetGroup[field.label],
        field.type,
        field.name,
        field.label
      );

      if (errorText.length > 0) {
        numErrors++;
      }
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setFormDataUpdated(!formDataUpdated);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  //Go through each rule item and ensure each value is set and that rules that only accept one value have one value
  const validateRules = () => {
    setSubmitEnabled(false);

    let numErrors = 0;

    let localFormData = formProperties;

    let errorText = "";
    targetGroup.Rules.forEach((rule) => {
      if (errorText === "") {
        if (rule.RuleName === undefined || rule.RuleValues === undefined) {
          errorText = "Please ensure all rule fields have a value set.";
        } else if (
          rule.RuleValues.length > 1 &&
          !TargetFields[
            targetGroup.ModelType.replace(" ", "").toUpperCase()
          ].RULE[rule.RuleName].FilterType.includes("Equals Any")
        ) {
          errorText = `${
            TargetFields[targetGroup.ModelType.toUpperCase()].RULE[
              rule.RuleName
            ].value
          } can only take a single value. Please remove any extra values.`;
        }
      }
    });

    if (errorText.length > 0) {
      numErrors++;
      localFormData["rules"].errorText = errorText;

      setFormProperties(localFormData);
    }

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /*
    When the submit button is clicked on either the create or update form.
    The create param is optional
  */
  const submitTargetGroup = () => {
    //Ensure the data being submitted is valid before submitting
    if (validateForm() && validateRules()) {
      const rules = [];

      if (targetGroup.Rules != null && targetGroup.Rules.length > 0) {
        targetGroup.Rules.forEach((rule) => {
          rules.push({
            Name: rule.RuleName,
            Operator: rule.RuleValues.length > 1 ? "||" : "eq",
            Values: rule.RuleValues,
          });
        });
      }

      // Need to add a rule for site as it is required by the backend
      rules.push({
        Name: `${targetGroup.ModelType[0].toUpperCase()}${targetGroup.ModelType.slice(
          1
        )}_Site`,
        Operator: "eq",
        Values: [targetGroup.Site],
      });

      // Create the target group then redirect user to the target groups list page
      dispatch(
        createTargetGroup(targetGroup, rules, loggedInUser.userId, () => {})
      );
    }
  };

  /*
    Iterate through all rules and display its attribute and value
  */
  const setupRulesData = () => {
    if (!targetGroup || !targetGroup.Rules || !targetGroupRules) {
      return [];
    }

    const modelType = targetGroup.ModelType.toUpperCase();

    let targetGroupRulesKeys = Object.keys(targetGroupRules);

    let targetRules = [];

    let mtype = modelType;
    if (modelType == "CORERANGE") mtype = "CUSTOMER";

    for (var index in targetGroupRulesKeys) {
      if (targetGroupRulesKeys[index].toUpperCase().includes(mtype)) {
        targetRules = targetGroupRules[targetGroupRulesKeys[index]];
      }
    }
    //Display each rule attribute and value in its own row on the form
    return targetGroup.Rules.map((rule, index) => {
      //debugger;
      return {
        id: index,
        fieldNameValue: rule.RuleName,
        handleFieldNameChange: (item) => {
          let localTargetGroup = Object.assign({}, targetGroup);
          if (item && item.key) {
            const value = item.key;
            localTargetGroup.Rules[index].Field = value;
            localTargetGroup.Rules[index].FilterType =
              TargetFields[modelType.replace(" ", "")].RULE[value].FilterType;
            localTargetGroup.Rules[index].RuleName = value;
            localTargetGroup.Rules[index].RuleValues = [];
          } else {
            localTargetGroup.Rules[index].Field = undefined;
            localTargetGroup.Rules[index].FilterType = undefined;
            localTargetGroup.Rules[index].RuleName = undefined;
          }
          setTargetGroup(localTargetGroup);
        },
        possibleRuleValues: targetRules.filter(
          (tr) => tr.RuleName === rule.RuleName
        ),
        selectedRuleValues: rule.RuleValues,
        handleAddValue: (values) => {
          let localTargetGroup = Object.assign({}, targetGroup);
          const ruleValues = values.map((value) => {
            if (typeof value === "string") {
              return value;
            }

            return value.Id;
          });
          localTargetGroup.Rules[index].RuleValues = ruleValues;

          setTargetGroup(localTargetGroup);
        },
      };
    });
  };

  /*
    Add a new rule line on button click
  */
  const handleAddRule = () => {
    if (!targetGroup.ModelType) {
      setSnackbarOpen(true);
      setSnackbarMessage("Please select a Model Type before adding Rules");
    } else {
      let localTargetGroup = Object.assign({}, targetGroup);
      localTargetGroup.Rules.push({
        Field: undefined,
        FilterType: undefined,
      });

      setTargetGroup(localTargetGroup);
    }
  };

  /*
    Functionality to delete a rule
  */
  const handleDeleteRule = (data) => {
    var localTargetGroup = Object.assign({}, targetGroup);

    const dataReversed = data.reverse();

    dataReversed.forEach((index) => {
      localTargetGroup.Rules.splice(index, 1);
    });

    setTargetGroup(localTargetGroup);
  };

  /*
    Display the cancel editing alert dialog
  */
  const cancelTargetGroupEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelTargetGroupEditingAlertOpen(false),
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setTargetGroup(uneditedTargetGroup);
              setCancelTargetGroupEditingAlertOpen(false);

              setTargetGroup(uneditedTargetGroup);
            },
          },
        ]}
        isOpen={cancelTargetGroupEditingAlertOpen}
      />
    );
  };

  const copyToClipboard = (e) => {
    copy(targetGroup.TargetGroupId);
  };

  const handleSyncPreviewClick = () => {
    if (routeId !== "") {
      setRouteErrorMessage("");
      dispatch(previewSync(routeId, targetGroupId, () => {}));
    } else {
      setRouteErrorMessage("Please select a route");
    }
  };

  const getValue = () => {
    let ruleDisplayName = redChannelValues?.RulePossibleValues?.find(
      (item) => item.Id === targetGroup.Description
    );
    if (ruleDisplayName) {
      return ruleDisplayName.DisplayName;
    } else {
      return "";
    }
  };

  /*
    Display all the page data
  */
  const renderData = () => {
    if (!targetGroup) {
      return <div />;
    }
    const modelType = targetGroup.ModelType || ModelType.CUSTOMER;
    return (
      <div className={styles.contentGrid}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomCard title="Main Details" actionButton={[]}>
              <Grid container spacing={3}>
                {currentCampaignText !== "" ? (
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className={styles.warning}
                    >
                      {currentCampaignText}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "inline-flex",
                    // transition:
                    padding: 16,
                  }}
                >
                  <Grid container>
                    <Grid item xs={9}>
                      <TextField
                        classes={{ root: styles.textField }}
                        label={"Target Group Id"}
                        disabled={true}
                        value={
                          targetGroup.TargetGroupId
                            ? targetGroup.TargetGroupId
                            : ""
                        }
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        size="medium"
                        style={{ marginLeft: 20, marginTop: 10 }}
                        onClick={() => copyToClipboard()}
                      >
                        Copy To Clipboard
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: styles.textField }}
                    label={"Target Group Short Id"}
                    disabled={true}
                    value={targetGroup.Counter ? targetGroup.Counter : ""}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{ root: styles.textField }}
                    label={"Target Group Name"}
                    placeholder="Enter target group name"
                    value={targetGroup.Name ? targetGroup.Name : ""}
                    onChange={(event) => {
                      var localTargetGroup = Object.assign({}, targetGroup);
                      localTargetGroup.Name = event.target.value;

                      setTargetGroup(localTargetGroup);
                      setFormHasChanged(true);
                    }}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      formProperties.name.errorText.length === 0
                        ? false
                        : isEditing
                        ? true
                        : false
                    }
                    helperText={isEditing ? formProperties.name.errorText : ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* Description */}
                  {targetGroup.ModelType !== "Redchannel" ? (
                    <TextField
                      classes={{ root: styles.textField }}
                      label={"Description"}
                      placeholder="Enter description"
                      multiline={true}
                      rows={4}
                      value={
                        targetGroup.Description ? targetGroup.Description : ""
                      }
                      onChange={(event) => {
                        var localTargetGroup = Object.assign({}, targetGroup);
                        localTargetGroup.Description = event.target.value;

                        setTargetGroup(localTargetGroup);
                        setFormHasChanged(true);
                      }}
                      variant="outlined"
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        formProperties.description.errorText.length === 0
                          ? false
                          : isEditing
                          ? true
                          : false
                      }
                      helperText={
                        isEditing ? formProperties.description.errorText : ""
                      }
                    />
                  ) : (
                    <DropdownSelect
                      fullWidth={true}
                      label="Description"
                      handleChange={(value) => {
                        const name = redChannelValues?.RulePossibleValues?.find(
                          (item) => item.DisplayName === value
                        );
                        var localTargetGroup = Object.assign({}, targetGroup);
                        localTargetGroup.Description = name.Id;

                        setTargetGroup(localTargetGroup);
                        setFormHasChanged(true);
                      }}
                      data={redChannelValues?.RulePossibleValues?.map(
                        (item) => item.DisplayName
                      )}
                      value={getValue()}
                      valueName={"Description"}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    fullWidth={true}
                    label="Model Type"
                    handleChange={(value) => {
                      var localTargetGroup = Object.assign({}, targetGroup);
                      localTargetGroup.ModelType = value;
                      localTargetGroup.TokenField = "";
                      localTargetGroup.FilterRules = [];
                      localTargetGroup.Rules = [];

                      setTargetGroup(localTargetGroup);
                      setFormHasChanged(true);
                    }}
                    data={Object.values(modelTypeData).map(
                      (modelType) => modelType.NAME
                    )}
                    value={
                      modelType.charAt(0).toUpperCase() + modelType.slice(1)
                    }
                    valueName={"ModelType"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    fullWidth={true}
                    label="Site"
                    handleChange={(value) => {
                      var localTargetGroup = Object.assign({}, targetGroup);
                      localTargetGroup.Site = value;

                      setTargetGroup(localTargetGroup);
                      setFormHasChanged(true);
                    }}
                    data={
                      isContentAdmin
                        ? Territories != null
                          ? Object.keys(Territories).map((key) => {
                              return { key: key, value: Territories[key] };
                            })
                          : []
                        : [
                            {
                              key: loggedInUser.territory,
                              value: Territories[loggedInUser.territory],
                            },
                          ]
                    }
                    value={targetGroup.Site}
                    valueName={"territory"}
                  ></DropdownSelect>
                </Grid>
              </Grid>
            </CustomCard>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <ListableRulesCard
                isCoreRange={true}
                actionButton={[
                  <Button
                    key={UUID()}
                    onClick={() => handleAddRule()}
                    variant="outlined"
                    color="secondary"
                  >
                    Add Rule
                  </Button>,
                ]}
                addLabel={"Add Rule"}
                isLoading={false}
                title={"Rules"}
                errorMessage={formProperties.rules.errorText}
                emptyPlaceholder={"No Rules added"}
                handleDelete={(data) => {
                  handleDeleteRule(data);
                }}
                data={setupRulesData() === null ? [] : setupRulesData()}
                modelType={modelType.toUpperCase()}
                subheading={
                  "Add rules to select a target segmentation. All Rules are AND rules"
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Modal
      title={"View Target Group"}
      open={targetModalOpen}
      fullWidth
      fixedHeight
      actions={
        <Box>
          <Button
            color="secondary"
            onClick={() => {
              if (
                targetGroup.Name &&
                targetGroup.Description &&
                targetGroup.Rules.length >= 1
              ) {
                submitTargetGroup();
                setTargetGroup({
                  Name: "",
                  Description: "",
                  ModelType: ModelType.CUSTOMER,
                  Rules: [],
                  Site: "au",
                });
                setTargetModalOpen(false);
              } else {
                submitTargetGroup();
              }
            }}
          >
            {/* on click */}
            Submit
          </Button>

          <Button
            color="secondary"
            onClick={() => {
              setTargetGroup({
                Name: "",
                Description: "",
                ModelType: ModelType.CUSTOMER,
                Rules: [],
                Site: "au",
              });
              setTargetModalOpen(false);
            }}
          >
            Close
          </Button>
        </Box>
      }
    >
      {cancelTargetGroupEditingAlert()}

      {!isModal && (
        <CustomizedSnackbars
          handleClose={() => {
            setSnackbarOpen(false);
          }}
          open={snackbarOpen}
          variant="error"
          message={snackbarMessage}
        />
      )}

      {!isModal && (
        <PageContainer hasHelp={type !== FormType.VIEW}>
          {renderData()}
        </PageContainer>
      )}
      {isModal && renderData()}
    </Modal>
  );
};
