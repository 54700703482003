import {
  GET_ALL_SFE_ACTIVATIONS,
  GET_SFE_ACTIVATION_CALCULATION_BY_ID,
  CREATE_SFE_ACTIVATION_CALCULATION,
  GET_DROPDOWN_LOOKUPS
} from "../constants/action-types";

// INITIALIZE STATE
const initialState = {
  sfeActivations: [],
  currentSFEActivation: {},
  lookupData: {}
};

const createSFEActivationFromResponse = (response, state) => {
  let sfeActivation = {};

  if (Object.keys(response).length > 0) {
    sfeActivation.activityGroup = {
      Id: response.data.ActivityGroupId,
      DisplayName: response.data.ActivityGroup
    };
    sfeActivation.activity = {
      Id: response.data.ActivityId,
      DisplayName: response.data.Activity
    };
    sfeActivation.calculationType = {
      Id: response.data.CalculationTypeCode,
      DisplayName: response.data.CalculationTypeCode
    };
    sfeActivation.active = response.data.SysIsCurrent;

    let lookupData = [];
    if (
      state.lookupData !== undefined &&
      Object.keys(state.lookupData).length > 0
    ) {
      lookupData = state.lookupData;
    }
    let redChannel = {};

    if (lookupData !== undefined && Object.keys(lookupData).length > 0) {
      redChannel = lookupData["RedChannels"].find(
        (d) => d.Id === response.data.RedChannelCode
      );
    }

    sfeActivation.redChannel = redChannel;
  } else {
    sfeActivation = {
      redChannel: "",
      activityGroup: "",
      activity: "",
      calculationType: "",
      active: true
    };
  }

  return sfeActivation;
};

// REDUCER
export const SFEActivationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SFE_ACTIVATIONS: {
      const sfeActivations = action.payload.data;
      return {
        ...state,
        sfeActivations
      };
    }

    case GET_SFE_ACTIVATION_CALCULATION_BY_ID: {
      const currentSFEActivation = createSFEActivationFromResponse(
        action.payload.data,
        state
      );

      return {
        ...state,
        currentSFEActivation
      };
    }

    case CREATE_SFE_ACTIVATION_CALCULATION: {
      const currentSFEActivation = createSFEActivationFromResponse(
        action.payload.data,
        state
      );

      return {
        ...state,
        currentSFEActivation
      };
    }

    case GET_DROPDOWN_LOOKUPS: {
      return {
        ...state,
        lookupData: action.payload.data
      };
    }

    default:
      return state;
  }
};
