import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetDisplayGroupById,
  getActivityById,
  getActivitiesById,
} from "../../../state/actions/MyDisplaysActions";

import { ActivityTable } from "./components/ActivityTable";
import { ProductFilters } from "./components/ProductFilters";

import {
  Button,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import { upsertProductFilters } from "../../../state/actions/MyDisplaysActions";
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

const MyDisplayTypeView = (props) => {
  const displayIndex = props.match.params.DisplayIndex;
  const displayTypeId = props.match.params.DisplayTypeId;
  const displayGroupId = props.match.params.DisplayGroupId;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newRegexCheck, setNewRegexCheck] = useState(true);
  const [newCharAlert, setNewCharAlert] = useState(false);

  const [productFilterError, setProductFilterError] = useState(false);

  const displayGroup = useSelector(
    (state) => state.MyDisplaysReducer.displayGroup
  );

  let activityIds = null;

  const currentActivity = useSelector(
    (state) => state.MyDisplaysReducer.displayActivity
  );

  const [productFilters, setProductFilters] = useState([]);

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  let createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const displayActivities = useSelector(
    (state) => state.MyDisplaysReducer.displayActivities
  );

  const productFilterRules = [
    "CategoryId",
    "ProductTypeId",
    "MaterialGroup1Id",
    "BrandId",
    "FlavourId",
    "PackTypeId",
    "PackSizeId",
    "MaterialNameSearch",
    "MaterialNumber",
    "MaterialTypeId",
  ];

  useEffect(() => {
    dispatch(getLoggedInUserByEmail(() => {}));
    if (displayGroupId !== undefined) {
      dispatch(GetDisplayGroupById(displayGroupId, () => {}));
    }
  }, []);

  useEffect(() => {
    if (displayGroup && displayGroup !== undefined && displayGroup.Displays) {
      dispatch(
        getActivityById(
          displayGroup.Displays[displayIndex].DisplayTypeActivityId,
          () => {}
        )
      );
    }
  }, [displayGroup]);

  useEffect(() => {
    if (displayGroup !== undefined) {
      activityIds = displayGroup.Displays[displayIndex].Activities.map(
        (activity) => activity.MyDisplaysActivityId
      );

      dispatch(getActivitiesById(activityIds, () => {
        setLoading(true);
      }));
    }
  }, [currentActivity]);

  const createFilterData = () => {
    const productFilterList = [];

    productFilters.forEach((rule) => {
      const productFilterData = {
        DisplayTypeActivityId: currentActivity.Activity.Id,
        CatergoryId: null,
        CategoryIdOperator: null,
        ProductTypeId: null,
        ProductTypeIdOperator: null,
        MaterialGroup1Id: null,
        MaterialGroup1IdOperator: null,
        BrandId: null,
        BrandIdOperator: null,
        FlavourId: null,
        FlavourIdOperator: null,
        PackSizeId: null,
        PackSizeIdOperator: null,
        MaterialNameSearch: null,
        MaterialNameOperator: null,
        MaterialNumber: null,
        MaterialNumberOperator: null,
        IsActive: true,
        ModifiedBy: createdUser,
        ModifiedDate: new Date(),
        CreatedBy: productFilters.CreatedBy
          ? productFilters.CreatedBy
          : createdUser,
        CreatedDate: productFilters.CreatedDate
          ? productFilters.CreatedDate
          : new Date(),
        MaterialTypeId: null,
        MaterialTypeIdOperator: null,
        Site: productFilters.Site
          ? productFilters.Site
          : loggedInUser.territory === "nz"
          ? 2
          : 1,
      };
      productFilterData[rule.ruleType] = rule.ruleValue;
      if (rule.ruleType === "MaterialNameSearch") {
        let tmpRule = rule.ruleType.split(/(?=[A-Z])/);
        tmpRule.pop();
        tmpRule = tmpRule.join("");
        productFilterData[`${tmpRule}Operator`] = rule.ruleOperator;
      } else {
        productFilterData[`${rule.ruleType}Operator`] = rule.ruleOperator;
      }

      productFilterList.push(productFilterData);
    });

    return productFilterList;
  };

  const submitProductFilters = () => {
    dispatch(
      upsertProductFilters(
        currentActivity.Activity.Id,
        createFilterData(),
        (success) => {
          if (success) {
            props.history.push(
              `/my-displays-maintenance/${displayGroupId}/${displayIndex}`
            );
            setIsEditing(false);
          } else {
            setProductFilterError(true);
          }
        }
      )
    );
  };

  const buttonGroupElement = isEditing ? (
    <ButtonGroup style={{ marginRight: "35px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setIsEditing(false)}
      >
        Cancel
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={submitProductFilters}
        disabled={!newRegexCheck || newCharAlert}
      >
        Save
      </Button>
    </ButtonGroup>
  ) : (
    <ButtonGroup style={{ marginRight: "35px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() =>
          props.history.push(`/my-displays-maintenance/${displayGroupId}`)
        }
      >
        Go back
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setIsEditing(true)}
      >
        Edit
      </Button>
    </ButtonGroup>
  );

  const creatProductFilterErrorElement = productFilterError ? (
    <div style={{ marginTop: "100px" }}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Failed to create/edit product filters. It is likely the character limit
        was reached - <strong>Please Try Again.</strong>
      </Alert>
    </div>
  ) : null;

  return (
    <div>
      {loading ? (
        <>
          <HeaderBlock
            title={currentActivity?.Activity?.Name}
            right={
              <Grid
                container
                spacing={3}
                alignItems="center"
                justify="flex-end"
              >
                {buttonGroupElement}
              </Grid>
            }
          />

          {creatProductFilterErrorElement}

          <div style={{ marginTop: productFilterError ? "50px" : "150px" }}>
            <ActivityTable
              currentActivity={currentActivity}
              displayIndex={displayIndex}
              displayGroup={displayGroup}
              displayGroupId={displayGroupId}
              displayTypeId={displayTypeId}
              isEditing={isEditing}
              displayActivities={displayActivities}
            />
            {displayGroup && (
              <ProductFilters
                productFilterRules={productFilterRules}
                isEditing={isEditing}
                productFilters={productFilters}
                setProductFilters={setProductFilters}
                currentActivity={currentActivity}
                displayGroup={displayGroup}
                newRegexCheck={newRegexCheck}
                setNewRegexCheck={setNewRegexCheck}
                newCharAlert={newCharAlert}
                setNewCharAlert={setNewCharAlert}
              />
            )}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};;

const hoc = withRouter(MyDisplayTypeView);

// EXPORT COMPONENT
export { hoc as MyDisplayTypeView };
