// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// UI and Styling
import { Box, Grid, Typography, Button } from "@material-ui/core";
import styles from "./ViewResourceImagePage.module.scss";

// Actions
import { getResourceUrls } from "../../../state/actions/ResourceImageManagerActions";

// Components
import CustomCard from "../../../components/custom-card/CustomCard";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";

const ViewResourceImagePage = () => {
  const currentResourceImage = useSelector(
    (state) => state.ResourceImageManagerReducer.currentResourceImage
  );
  const resourceImageUrls = useSelector(
    (state) => state.ResourceImageManagerReducer.resourceImageUrls
  );

  // Prop Interactors Setup
  const [loadingResourceImage, setLoadingResourceImage] = useState(false);
  const [localUrls, setLocalUrls] = useState([]);

  const dispatch = useDispatch();

  const baseUrl = process.env.REACT_APP_RESOURCE_URL;

  // Initial state parsing & actions.
  useEffect(() => {
    setLoadingResourceImage(true);

    dispatch(
      getResourceUrls(
        currentResourceImage.territory,
        currentResourceImage.targetType,
        currentResourceImage.targetName,
        () => {
          setLoadingResourceImage(false);
        }
      )
    );
  }, [currentResourceImage]);

  // Initial state parsing & actions.
  useEffect(() => {
    setLocalUrls(resourceImageUrls);
  }, [resourceImageUrls]);

  const getListItems = () => {
    return localUrls.map((urlBody, index) => {
      const url = urlBody.url.replace(/ /g, "%20");
      return (
        <>
          <Grid key={index} container spacing={5}>
            <Grid item xs={2}>
              <Typography variant="h6" gutterBottom>
                {urlBody.size}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {`${baseUrl}${url}`}
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                href={`${baseUrl}${url}`}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </>
      );
    });
  };

  // Define the markdown for the content items that will appear.
  const getContentGrid = () => {
    return (
      <div className={styles.contentGrid}>
        <Grid container>
          <CustomCard title="Image URLs" actionButton={[]}>
            {getListItems()}
          </CustomCard>
        </Grid>
      </div>
    );
  };

  return (
    <Box>
      {loadingResourceImage ? (
        <Loading />
      ) : (
        <Fragment>
          <HeaderBlock title={`Image ${currentResourceImage.targetName}`} />
          <PageContainer>{getContentGrid()}</PageContainer>
        </Fragment>
      )}
    </Box>
  );
};

const hoc = withRouter(ViewResourceImagePage);

export { hoc as ViewResourceImagePage };
