// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import {
  ButtonGroup,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";

import styles from "./ResourceImageDataListPage.module.scss";

// Actions
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";
import { getAllContent } from "../../../state/actions/ResourceImageUploaderActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import { OutlinedIconButton } from "../../../components/buttons/OutlinedIconButton";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";
import { UUID } from "../../../helpers/uuid";
import { Territories } from "../../../state/constants/Territories";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  }
}));

const ResourceImageDataListPage = (props) => {
  const classes = useStyles();

  const resourceImageDataList = useSelector(
    (state) => state.ResourceImageUploaderReducer.allContent
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [filtersUpdated, setFiltersUpdated] = useState(false);
  const [isContentAdmin, setIsContentAdmin] = useState(false);

  const setupData = () => {
    dispatch(getAllContent(() => { }));
  };

  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setupData();
        })
      );
    } else {
      setupData();
    }
  }, []);

  useEffect(() => {
    const data = createData(resourceImageDataList);

    setOriginalData(data);

    let localFilters = filters;

    localFilters[loggedInUser.territory] = true;

    setFilters(localFilters);
    setFiltersUpdated(true);

    if (loading) {
      setLoading(false);
    }
  }, [resourceImageDataList]);

  useEffect(() => {
    if (
      loggedInUserRoles !== undefined &&
      (loggedInUserRoles.includes("Content Admin") ||
        loggedInUserRoles.includes("SaM Core Admin"))
    ) {
      setIsContentAdmin(true);
    } else {
      setIsContentAdmin(false);
    }
  }, [loggedInUserRoles]);

  useEffect(() => {
    setFiltersUpdated(false);
    search(searchText);
  }, [filters, filtersUpdated]);

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const territories = [];

    Object.keys(Territories).forEach((key, index) => {
      if (filters[key]) {
        territories.push(Territories[key]);
      }
    });

    const foundData = originalData.filter((content) => {
      return (
        (content.id.toLowerCase().includes(text) ||
          content.contentTitle.toLowerCase().includes(text) ||
          content.contentType.toLowerCase().includes(text))
        // && (territories.length === 0 || territories.includes(content.site))
      );
    });

    setSearchedData(foundData);
  };

  const createData = (content) => {
    const contentValues = Object.values(content);

    return contentValues.map((value) => {
      return {
        id: value.ContentId,
        contentTitle: value.ContentTitle,
        contentDescription:
          value.ContentDescription !== undefined
            ? value.ContentDescription.substring(0, 50)
            : "",
        contentType: value.ContentType,
        site: value.Territory ? Territories[value.Territory.toLowerCase()] : null,
        isInternal: value.IsInternal ? "Yes" : "No",
        isActive: value.IsActive ? "Yes" : "No"
      };
    });
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const createHeaders = () => {
    return [
      {
        id: "contentTitle",
        align: "left",
        label: "Title",
        clickable: true,
        action: { path: "/image-upload/{id}", identifier: "id" }
      },
      {
        id: "contentDescription",
        align: "left",
        label: "Description"
      },
      {
        id: "contentType",
        align: "left",
        label: "Content Type"
      },
      {
        id: "isInternal",
        align: "left",
        label: "Internal"
      },
      {
        id: "isActive",
        align: "left",
        label: "Active"
      }
    ];
  };

  const handleChange = (event) => {
    let localFilters = filters;

    if (filters[event.target.name]) {
      localFilters[event.target.name] = !localFilters[event.target.name];
    } else {
      localFilters[event.target.name] = true;
    }

    setFilters(localFilters);
    setFiltersUpdated(true);
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    return searchedData.length === 0 ? (
      <PagePlaceholder
        image={getIcon("add-file.svg")}
        text="No Image Data found."
      />
    ) : (
        <Fragment>
          <SimpleTable
            isSelectable={false}
            dataId={"id"}
            cells={cells}
            headers={headers}
            rows={searchedData}
          />
        </Fragment>
      );
  };

  return (
    <Fragment>
      <HeaderBlock
        title={"Image Upload"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                className={styles.roleField}
                id="outlined-name"
                label="Search Images"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <ExportExcel
                csvData={searchedData}
                fileName="content"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item>
              <ButtonGroup>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    props.history.push("image-upload/new");
                  }}
                >
                  New Image
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      <PageContainer>
        {loading ? (
          <Loading />
        ) : (
            <div className={styles.root}>{renderData()}</div>
          )}
      </PageContainer>
    </Fragment>
  );
};

const hoc = withRouter(ResourceImageDataListPage);

// EXPORT COMPONENT
export { hoc as ResourceImageDataListPage };
