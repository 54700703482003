import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React, { Fragment } from "react";
import { PagePlaceholder } from "../page-placeholder/PagePlaceholder";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

export default class SimpleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.currentSelection || []
    };
  }

  handleToggle = (id) => () => {
    const currentIndex = this.state.checked.indexOf(id);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
    this.props.handleSelection(newChecked);
  };

  componentDidMount() {}

  componentDidUpdate() {
    // this.setState({ loading: true });
  }

  render() {
    const Row = ({ index, style }) => {
      const dataItem = this.props.data[index];
      if (dataItem != null) {
        return (
          <ListItem
            key={dataItem.key}
            role={undefined}
            dense
            button
            onClick={this.handleToggle(dataItem.id)}
            style={style}
          >
            <ListItemIcon>
              {this.props.notSelectable ? (
                <div />
              ) : (
                <Checkbox
                  edge="start"
                  checked={this.state.checked.indexOf(dataItem.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": dataItem.id }}
                />
              )}
            </ListItemIcon>
            <ListItemText
              id={dataItem.id}
              primary={dataItem.value}
              secondary={
                dataItem.secondaryValue
                  ? dataItem.secondaryValue
                  : dataItem.id !== dataItem.value
                  ? dataItem.id
                  : null
              }
            />
          </ListItem>
        );
      }
    };
    return (
      <Fragment>
        {this.props.data.length === 0 ? (
          <PagePlaceholder text={this.props.emptyPlaceholder} />
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                itemCount={this.props.data.length}
                itemSize={50}
                width={width}
              >
                {Row}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
      </Fragment>
    );
  }
}
