import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField, Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from "axios";
import { wmdAdalApiFetch } from 'config/wmdConfig';
import { getApiConfig } from 'config/apiConfig';
import { Loading } from "../../components/loading/Loading";

const SearchPage = () => {
    const [customerNumber, setCustomerNumber] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [cnError, setcnError] = useState(null);
    const [invoiceError, setInvoiceError] = useState(null);
    const [formError, setFormError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const API_CONFIG = getApiConfig();
    const fetchApiData = async (customerNumber, invoiceNumber) => {
        try {
            const headers = { "Content-Type": "application/json" };
            const response = await wmdAdalApiFetch(
                Axios, `${API_CONFIG.WMD}/GetOrderDetailsByCustomer?customerId=${customerNumber ? customerNumber : null}&invoiceNumber=${invoiceNumber ? invoiceNumber : null}`,
                {
                    method: "get",
                    headers: headers
                }
            );
            if (response.status === 200) {
                history.push({ pathname: '/map', state: { res: response.data } });
                localStorage.setItem("reloadingCount", 0)

            } else {
                setFormError(`API request failed with status code: ${response.status}`);
                console.error(`API request failed with status code: ${response.status}`);
            }
        }
        catch (err) {
            setFormError(err.response.data);
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        const queryString = location.search;
        const searchParams = new URLSearchParams(queryString);
        const parameterNames = Array.from(searchParams.keys());
        const customerParam = searchParams.get(parameterNames.find(item => item === 'customerNumber'));
        const dsdParam = searchParams.get(parameterNames.find(item => item === 'invoiceNumber'));

        if (customerParam || dsdParam) {
            setCustomerNumber(customerParam)
            setInvoiceNumber(dsdParam)
            setIsLoading(true)
            fetchApiData(customerParam, dsdParam)
        }
    }, [location.search])

    const handleCNInputChange = (e) => {
        const value = e.target.value;
        setFormError(null);
        setCustomerNumber(value);
        if (!/^[0-9]*$/.test(value)) {
            setcnError('Only numbers are allowed');
        }
        else if (value.length > 10) {
            setcnError('Customer Number cannot exceed 10 digits');
        } else {
            setcnError(null);
        }
    };

    const handleDSDInputChange = (e) => {
        const value = e.target.value;
        setFormError(null);
        setInvoiceNumber(value);
        if (!/^[0-9]*$/.test(value)) {
            setInvoiceError('Only numbers are allowed');
        }
        else if (value.length > 10) {
            setInvoiceError('Invoice Number cannot exceed 10 digits');
        } else {
            setInvoiceError(null);
        }
    };

    const submitBtn = () => {
        if ((customerNumber === '' && invoiceNumber === '')) {
            setFormError('Customer Number and Invoice Number cannot be empty');
        } else if (customerNumber.length > 10) {
            setFormError('Customer Number cannot exceed 10 digits');
        } else if (invoiceNumber.length > 10) {
            setFormError('Invoice Number cannot exceed 10 digits');
        } else {
            setFormError(null);
            fetchApiData(customerNumber, invoiceNumber)
        }
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitBtn();
        }
    };
    return (
        <Box overflow="hidden">
            {isLoading ? <Loading /> : <Grid container spacing={2} direction="column" alignItems="center" >
                <Grid item xs={12} style={{ width: 500 }}>
                    <Typography variant='h4' gutterBottom style={{ marginLeft: 90, fontSize: '1.6rem', fontWeight: "bold", marginTop: 30 }}>
                        Where's My Delivery?
                    </Typography>
                    <Typography variant='paragraph' style={{ marginTop: 30, color: '#C0C0C0' }}>
                        Please provide your Customer Number or Proforma Invoice Number to retrieve your delivery details
                    </Typography>
                    <Typography variant='h6' style={{ marginTop: 20, marginBottom: 10, fontWeight: '500', fontSize: '1rem' }} >
                        Customer Number
                    </Typography>
                    <TextField id="CustomerNumber" onChange={handleCNInputChange} onKeyPress={handleKeyPress} variant="outlined" placeholder="Enter your Customer Number" style={{ width: '100%' }} value={customerNumber} />
                    {cnError && <div style={{ color: 'red' }}>{cnError}</div>}
                    <Typography variant='h6' style={{ fontWeight: '500', fontSize: '1rem', textAlign: 'center', marginTop: 10, marginBottom: 10 }} >
                        Or
                    </Typography>
                    <Typography variant='h6' style={{ fontWeight: '500', fontSize: '1rem', marginBottom: 10 }} >
                    Proforma Invoice Number
                    </Typography>
                    <TextField id="DSDNumber" onChange={handleDSDInputChange} onKeyPress={handleKeyPress} variant="outlined" placeholder="Enter your Proforma Invoice Number (Sales Document)" style={{ width: '100%' }} value={invoiceNumber} />
                    {invoiceError && <div style={{ color: 'red' }}>{invoiceError}</div>}
                    <Button onClick={submitBtn} variant="contained" color="primary" disabled={(cnError || invoiceError || customerNumber?.length > 10 || invoiceNumber?.length > 10)} style={{ width: '100%', marginRight: 300, marginTop: 30, textTransform: 'none' }}>Search</Button>
                    {formError && <div style={{ color: 'red', marginTop: '7px' }}>{formError}</div>}
                </Grid>
            </Grid>
            }
        </Box>
    )

}

export { SearchPage };