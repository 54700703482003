import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import _, { create } from "lodash";
// UI and Styling
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Paper,
  Checkbox,
  Box,
  TextField,
  FormControl,
  Snackbar,
} from "@material-ui/core";
import fileDownload from "js-file-download";
import { CoreRangeColumn } from "../../state/constants/TemplateColumns";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { CoreRangeProductsTable } from "./CoreRangeProductsTable";
import { NewTargetGroupModal } from "./NewTargetGroupModal";
import { NewProductGroupModal } from "./NewProductGroupModal";
import {
  GetCoreRangeById,
  GetProductList,
  upsertCoreRangeAction,
} from "../../state/actions/CoreRangeActions";
import {
  getTargetGroup,
  getTargetGroups,
} from "../../state/actions/TargetGroupActions";
import { FormType } from "../../state/constants/FormType";
import { ModelType } from "../../state/constants/ModelType";
import { CoreRangeType } from "../../state/constants/CoreRangeType";
import { format } from "date-fns";
import {
  getLoggedInUserByEmail,
  getUsersForRole,
} from "../../state/actions/UserActions";
import { getApiConfig } from "../../config/apiConfig";
import { TargetGroupModal } from "./TargetGroupModal";

const API_CONFIG = getApiConfig();

const CoreRangeView = (props) => {
  const coreRangeId = props.match.params.coreRangeId;

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const dispatch = useDispatch();
  const [userRolesList, setUserRolesList] = useState([]);
  const [editProductsAlert, setEditProductsAlert] = useState(false);
  const [upsertError, setUpsertError] = useState(false);
  const [upsertHasValidationError, setUpsertHasValidationError] = useState(false);
  const [upsertTypeDescription, setUpsertTypeDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [doorDescription, setDoorDescription] = useState("");
  const [detailedValidationError, setDetailedValidationError] = useState("");
  const [upsertSuccess, setUpsertSuccess] = useState(false);
  const [upsertType, setUpsertType] = useState("");
  const [isNewCoreRange, setIsNewCoreRange] = useState(false);
  const [fetchCoreRange, setFetchCoreRange] = useState(true);
  const [fetchUserList, setFetchUserList] = useState(true);
  const [targetModalOpen, setTargetModalOpen] = useState(false);
  const [createTargetModalOpen, setCreateTargetModalOpen] = useState(false);
  const [tableProducts, setTableProducts] = useState([]);
  const [targetGroupError, setTargetGroupError] = useState(false);

  const ProductList = useSelector((state) => state.CoreRangeReducer.Products);
  const TargetGroupList = useSelector(
    (state) => state.TargetGroupReducer.targetGroups
  );
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );
  const coreRangeUserList = useSelector(
    (state) => state.UserReducer.usersInRole
  );

  const currentTargetGroup = useSelector(
    (state) => state.TargetGroupReducer.currentTargetGroup
  );

  // * Modal options
  const [open, setOpen] = React.useState(false);
  const [addMaterialOpen, setAddMaterialOpen] = React.useState(false);
  const [addProductGroupOpen, setAddProductGroupOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedTargetGroup, setSelectedTargetGroup] = React.useState("");
  const [contractedRange, setContractedRange] = React.useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [currentlySelectedTargetGroup, setCurrentlySelectedTargetGroup] =
    useState({
      counter: 0,
      hidden: false,
      ModelType: "customer",
      Site: "2",
      TargetGroupDescription: "",
      TargetGroupId: "",
      TargetGroupName: "",
    });

  var createdUser = "";
  if (typeof loggedInUser.userName !== "undefined") {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const initialData = {
    id: uuidv4(),
    ProductList: [],
    Doors: [],
    Site: loggedInUser.territory === "au" ? 1 : 2,
    Description: "",
    //lastEdited: "",
    IsActive: true,
    CustomerTargetGroupId: "",
    ReadyToPublish: false,
    CreatedBy: createdUser,
    CreatedDate: new Date(),
    OwnerUserId: "",
    Owner: "",
    //modifiedBy: "",
    //modifiedDate: "",
    //publishedBy: "",
    //publishedDate: "",
  };
  const [coreRangeData, setCoreRangeData] = useState({ ...initialData });
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [hasBeenPublished, setHasBeenPublished] = useState(false);
  const [viewTargetGroup, setViewTargetGroup] = useState({});

  useEffect(() => {
    if (currentTargetGroup !== undefined) {
      setViewTargetGroup(currentTargetGroup);
    }
  }, [currentTargetGroup]);

  const getCoreRange = () => {
    if (fetchCoreRange) {
      if (coreRangeId === "new") {
        setIsNewCoreRange(true);
      } else {
        dispatch(
          GetCoreRangeById(coreRangeId, (res) => {
            if (res.CoreRangeModel) {
              dispatch(
                getTargetGroup(
                  res.CoreRangeModel.CustomerTargetGroupId,
                  () => { }
                )
              );
              setSelectedTargetGroup(res.CoreRangeModel.CustomerTargetGroupId);
              for (const [key, value] of Object.entries(coreRangeUserList)) {
                if (value.id === res.CoreRangeModel.OwnerUserId) {
                  res.CoreRangeModel.Owner = value.email;
                }
              }

              for (let i = 0; i < res.CoreRangeModel.Doors.length; i++) {
                res.CoreRangeModel.Doors[i].Versions += 1;
              }
              setCoreRangeData(res.CoreRangeModel);
            } else {
              setHasBeenPublished(true);
              dispatch(
                getTargetGroup(
                  res.CoreRangeModel.CustomerTargetGroupId,
                  () => { }
                )
              );
              if (res.PublishedModel) {
                for (let i = 0; i < res.PublishedModel.Doors.length; i++) {
                  res.PublishedModel.Doors[i].Versions += 1;
                  res.PublishedModel.Doors[i].DoorNumber = parseFloat(
                    res.CoreRangeModel.Doors[i].DoorName.split(" ")[1]
                  );
                  res.PublishedModel.Doors[i].DoorName =
                    res.CoreRangeModel.Doors[i].DoorName;
                }
                setSelectedTargetGroup(
                  res.PublishedModel.CustomerTargetGroupId
                );
                setCoreRangeData(res.PublishedModel);
              }
            }
          })
        );
      }

      setFetchCoreRange(false);
    }
  };

  useEffect(() => {
    dispatch(getTargetGroups());
  }, []);

  useEffect(() => {
    getCoreRange();
  }, []);

  useEffect(() => {
    dispatch(getTargetGroups());
  }, [targetModalOpen]);

  const [requestPublish, setRequestPublish] = useState(false);
  const [requestSave, setRequestSave] = useState(false);
  const [requestDraft, setRequestDraft] = useState(false);
  const [duplicateDoorError, setDuplicateDoorError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (requestPublish) {
      const type = "PUBLISH";

      dispatch(
        upsertCoreRangeAction(coreRangeData, type, (success) => {
          setUpsertType(type);
          if (success) {
            setUpsertSuccess(true);
            setTimeout(() => {
              props.history.push("/core-range");
            }, 1000);
          } else {
            setUpsertError(true);
            setTimeout(() => {
              setUpsertError(false);
            }, 5000);
          }
        })
      );
    }
  }, [requestPublish]);

  useEffect(() => {
    if (requestSave) {
      const type = "UPDATE";

      dispatch(
        upsertCoreRangeAction(coreRangeData, type, (success) => {
          setUpsertType(type);
          if (success) {
            setUpsertSuccess(true);
            setTimeout(() => {
              props.history.push("/core-range");
            }, 1000);
          } else {
            setUpsertError(true);
            setTimeout(() => {
              setUpsertError(false);
            }, 5000);
          }
        })
      );
    }
  }, [requestSave]);

  useEffect(() => {
    if (requestDraft) {
      const type = "DRAFT";

      dispatch(
        upsertCoreRangeAction(coreRangeData, type, (success, id) => {
          setUpsertType(type);
          if (success) {
            setUpsertSuccess(true);
            props.history.push(`/core-range/${id}`);
            window.location.reload();
          } else {
            setUpsertError(true);
            setTimeout(() => {
              setUpsertError(false);
            }, 5000);
          }
        })
      );
    }
  }, [requestDraft]);

  useEffect(() => {
    if (fetchUserList) {
      dispatch(getUsersForRole(API_CONFIG.coreRangeRoleId, () => { }));
      setFetchUserList(false);
    }
  });

  useEffect(() => {
    let localData = [];
    for (const [key, value] of Object.entries(coreRangeUserList)) {
      localData.push({ id: value.id, email: value.email });
    }
    setUserRolesList([...localData]);
  }, [coreRangeUserList]);

  useEffect(() => {
    setLoading(true);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }
  }, []);

  useEffect(() => {
    if (loggedInUserCallMade && typeof loggedInUser.userName !== "undefined") {
      setCoreRangeData({
        ...coreRangeData,
        Site: loggedInUser.territory === "au" ? 1 : 2,
        CreatedBy: createdUser,
      });
    }
  }, [loggedInUserCallMade]);

  const setDescription = (e) => {
    let localData = { ...coreRangeData };
    localData.Description = e.target.value;
    setCoreRangeData({ ...coreRangeData, Description: e.target.value });
  };

  const setOwner = (e, newValue) => {
    let selectedOwner = userRolesList.find((item) => item.email === newValue);
    if (selectedOwner !== undefined) {
      setCoreRangeData({
        ...coreRangeData,
        OwnerUserId: selectedOwner.id,
        Owner: selectedOwner.email,
      });
    }
  };

  const setTargetGroupId = (e, value) => {
    if (value === null) {
      setCurrentlySelectedTargetGroup({
        counter: 0,
        hidden: false,
        ModelType: "customer",
        Site: "2",
        TargetGroupDescription: "",
        TargetGroupId: "",
        TargetGroupName: "",
      });
      return;
    }
    setCurrentlySelectedTargetGroup(value);
    setCoreRangeData({
      ...coreRangeData,
      CustomerTargetGroupId: value.TargetGroupId,
    });
    dispatch(getTargetGroup(value.TargetGroupId, () => { }));
  };

  const addProducts = (productIds) => {
    setEditProductsAlert(false);

    if (!productIds) return;
    let productIdsList = [];

    if (Array.isArray(productIds)) {
      productIdsList = productIdsList.concat(productIds);
    } else if (
      typeof productIds === "string" ||
      typeof productIds === "number"
    ) {
      productIdsList.push(productIds.toString());
    } else {
      throw new Error("Product IDs not valid.");
    }

    const updatedCoreRange = { ...coreRangeData };

    // Get matching target groups and products
    const targetGroups = TargetGroupList.filter((target) => {
      return (
        productIdsList.includes(target.Counter.toString()) ||
        productIdsList.includes(target.TargetGroupId)
      );
    });
    const products = ProductList.filter((product) => {
      return productIdsList.includes(product.Number);
    });

    // Update the product list
    const updatedProductList = [...coreRangeData.ProductList];

    let currentSortOrder = null;
    if (updatedProductList.length > 0) {
      const list = updatedProductList.map(product => product.SortOrder).filter(order => order);
      if (list && list.length > 0) {
        currentSortOrder = Math.max(...list) + 1;
      }
    }

    targetGroups.map((targetGroup) => {
      if (
        !updatedProductList.some(product => product.MaterialTargetGroupId == targetGroup.TargetGroupId) &&
        !updatedProductList.some(product => product.MaterialNumber == targetGroup.Counter.toString())
      ) {
        updatedProductList.push({ MaterialNumber: null, MaterialTargetGroupId: targetGroup.TargetGroupId, SortOrder: currentSortOrder });
      }
    });
    products.map((baseProduct) => {
      if (!updatedProductList.some(product => product.MaterialNumber == baseProduct.Number)) {
        updatedProductList.push({ MaterialNumber: baseProduct.Number, MaterialTargetGroupId: null, SortOrder: currentSortOrder });
      }
    });

    // Update the doors
    if (updatedCoreRange.Doors.length > 0) {
      // Create records for each door.
      updatedCoreRange.Doors.forEach((door) => {
        door.Data.map((version, index) => {
          // Add products
          products.map((product) => {
            if (!Object.keys(door.Data).includes(product.Number)) {
              door.Data[index][product.Number] = {
                IsContracted: false,
                RangePoints: "-",
                Threshold: "-",
              };
            }
          });

          // Add target groups
          targetGroups.map((targetGroup) => {
            if (
              !Object.keys(door.Data).includes(
                targetGroup.Counter.toString()
              ) &&
              !Object.keys(door.Data).includes(targetGroup.TargetGroupId)
            ) {
              door.Data[index][targetGroup.TargetGroupId] = {
                IsContracted: false,
                RangePoints: "-",
                Threshold: "-",
              };
            }
          });
        });
      });
    }

    setEditProductsAlert({
      message: `Added ${updatedProductList.length - updatedCoreRange.ProductList.length
        } item${updatedProductList.length - updatedCoreRange.ProductList.length !== 1
          ? "s"
          : ""
        }.`,
      type: "success",
    });
    setCoreRangeData({ ...updatedCoreRange, ProductList: updatedProductList });
  };

  const toggleContractedProduct = (rowData) => {
    //debugger;
    let productNumber = rowData.id ? rowData.id : rowData.Number;
    let localData = { ...coreRangeData };
    localData.Doors.forEach((door) => {
      door.Data.forEach((version) => {
        version[productNumber].IsContracted =
          !version[productNumber].IsContracted;
      });
    });
    setCoreRangeData({ ...localData });
  };

  useEffect(() => {
    let products = ProductList
      .filter((item) => coreRangeData.ProductList.some(product => product.MaterialNumber == item.Number || product.MaterialTargetGroupId == item.Number))
      .map((item) => {
        const product = coreRangeData.ProductList.find(product => product.MaterialNumber == item.Number || product.MaterialTargetGroupId == item.Number);
        return {
          "Number": item.Number,
          "Description": item.Description,
          "Status": item.Status,
          "SortOrder": product?.SortOrder
        };
      });
    products.sort((a, b) => a.Description.localeCompare(b.Description));

    let targetGroups = TargetGroupList.filter(
      (s) =>
        s.ModelType == "material" &&
        coreRangeData.ProductList.some(product => product.MaterialTargetGroupId == s.TargetGroupId)
    ).map((item) => {
      const product = coreRangeData.ProductList.find(product => product.MaterialTargetGroupId == item.TargetGroupId);
      return {
        id: item.TargetGroupId,
        Number: item.Counter,
        Description: item.TargetGroupName,
        Status: " ",
        ContractedRange: false,
        SortOrder: product?.SortOrder
      };
    });
    targetGroups.sort((a, b) => a.Description.localeCompare(b.Description));

    setTableProducts([...products, ...targetGroups]);
  }, [coreRangeData]);

  const toggleContracted = () => {
    setContractedRange(!contractedRange);
    //(contractedRange);
  };

  // Door Data for versions
  const getnewVersion = (data, door) => {
    let arr = [];
    let newVersion = {};
    let getDoors = data.filter(x => x[CoreRangeColumn.Door] === door);
    const result = Object.values(
      getDoors.reduce((acc, obj) => ({ ...acc, [obj[CoreRangeColumn.VersionNumber]]: obj }), {})
    );
    result.map(res => {
      newVersion = {};
      let getArr = data.filter(x => x[CoreRangeColumn.Door] === door && x[CoreRangeColumn.VersionNumber] === res[CoreRangeColumn.VersionNumber]);

      getArr.forEach(
        (item) => {
          let productNumber = item[CoreRangeColumn.ProductNumber];
          let productTarget = item[CoreRangeColumn.ProductGroupTargetID];
          let product = productNumber ? productNumber : productTarget;
          (newVersion[product] = {
            Threshold: item[CoreRangeColumn.Threshold] || "-",
            RangePoints: parseFloat(item[CoreRangeColumn.RangePoints]).toFixed(2) || "-",
            IsContracted: !!parseInt(item[CoreRangeColumn.ContractedRange]),
          })
        })
      arr.push(newVersion
      )
    })
    return arr;
  }

  // Render Dynamic UI from excel data
  const renderData = (localData, data) => {
    const result = Object.values(
      data.reduce((acc, obj) => ({ ...acc, [obj[CoreRangeColumn.Door]]: obj }), {})
    );

    result.map(item => {
      localData.Doors.push({
        id: uuidv4(),
        DoorName: `Door ${item[CoreRangeColumn.Door]}`,
        DoorNumber: parseFloat(item[CoreRangeColumn.Door]),
        Versions: parseInt(item[CoreRangeColumn.VersionNumber]),
        Data: getnewVersion(data, item[CoreRangeColumn.Door])
      });
    })

    const productItems = data.filter(item => item[CoreRangeColumn.ProductNumber] !== null)
    const productGroupItems = data.filter(item => item[CoreRangeColumn.ProductGroupTargetID] !== null)

    productItems.map(item => {
      let existingProduct = localData.ProductList.find(product => product.MaterialNumber == item[CoreRangeColumn.ProductNumber]);
      const sortOrder = item[CoreRangeColumn.SortOrder] === 0 ? undefined : item[CoreRangeColumn.SortOrder];
      if (existingProduct) {
        existingProduct.SortOrder = sortOrder;
      } else {
        localData.ProductList.push({ MaterialNumber: item[CoreRangeColumn.ProductNumber], MaterialTargetGroupId: null, SortOrder: sortOrder });
      }
    })
    productGroupItems.map(item => {
      let existingProduct = localData.ProductList.find(product => product.MaterialTargetGroupId == item[CoreRangeColumn.ProductGroupTargetID]);
      const sortOrder = item[CoreRangeColumn.SortOrder] === 0 ? undefined : item[CoreRangeColumn.SortOrder];
      if (existingProduct) {
        existingProduct.SortOrder = sortOrder;
      } else {
        localData.ProductList.push({ MaterialNumber: null, MaterialTargetGroupId: item[CoreRangeColumn.ProductGroupTargetID], SortOrder: sortOrder });
      }
    });

    return localData;
  }
  const submitExcelData = (data) => {
    let localData = { ...coreRangeData };
    if (localData.Doors.length > 0) {
      localData.Doors = [];
      localData.ProductList = [];
      const result = renderData(localData, data);
      setCoreRangeData(result);
    } else {
      const result = renderData(localData, data);
      setCoreRangeData(result);
    }
  }

  const downloadLogFileData = (excelData) => {
    const csv = getCsvData(excelData)
    fileDownload(csv, `CoreRangeErrorData.csv`);
  }

  const convertToTemplateData = () => {
    let excelData = [];
    if (coreRangeData.Doors.length > 0) {
      for (let doorIndex = 0; doorIndex < coreRangeData.Doors.length; doorIndex++) {
        const door = coreRangeData.Doors[doorIndex];
        const doorNumber = door.DoorNumber;

        for (let dataIndex = 0; dataIndex < door.Data.length; dataIndex++) {
          let newVersion = {};
          const data = door.Data[dataIndex];
          let versionNumber = dataIndex + 1;

          Object.keys(data).map(key => {
            let product = ProductList.find((data) => data.Number === key);
            if (product) {
              let coreRangeProduct = coreRangeData.ProductList.find((data) => data.MaterialNumber == key);
              product = {
                "Number": product.Number,
                "Description": product.Description,
                "Status": product.Status,
                "SortOrder": coreRangeProduct?.SortOrder
              };
            }

            let targetGroup = TargetGroupList.find((data) => data.TargetGroupId === key)?.TargetGroupName
            const getData = data[key];
            let isnum = /^\d+$/.test(key);
            newVersion = {
              'Door': doorNumber.toString(),
              'Version Number': versionNumber.toString(),
              'Product Number': isnum ? key : "",
              'Product Group Target ID': !isnum ? key : "",
              'Description': product?.Description || targetGroup,
              'Contracted Range': +getData.IsContracted,
              'Threshold': getData.Threshold,
              'Range Points': getData.RangePoints,
              'Display Sequence': product?.SortOrder ?? "",
            }
            excelData.push(newVersion)
          })
        }
      }
    } else {
      let newVersion = {
        'Door': "",
        'Version Number': "",
        'Product Number': "",
        'Product Group Target ID': "",
        'Description': "",
        'Contracted Range': "",
        'Threshold': "",
        'Range Points': "",
        'Display Sequence': "",
      }
      excelData.push(newVersion)
    }
    return excelData;
  }

  const downloadExcelTemplateData = () => {
    const csv = getCsvData(convertToTemplateData())
    fileDownload(csv, `CoreRangeData.csv`);
  }

  const getCsvData = (excelData) => {
    let csv = "";
    for (let row = 0; row < excelData.length; row++) {
      let keysAmount = Object.keys(excelData[row]).length
      let keysCounter = 0

      // If this is the first row, generate the headings
      if (row === 0) {

        // Loop each property of the object
        for (let key in excelData[row]) {

          // This is to not add a comma at the last cell
          // The '\r\n' adds a new line
          csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
          keysCounter++
        }

      }
    }
    for (let row = 0; row < excelData.length; row++) {
      let keysAmount = Object.keys(excelData[row]).length
      let keysCounter = 0
      for (let key in excelData[row]) {
        csv += (excelData[row][key] ? excelData[row][key] : '') + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
        keysCounter++
      }

      keysCounter = 0
    }
    return csv;
  }

  const addDoor = () => {
    let localData = { ...coreRangeData };

    let newVersion = {};
    localData.ProductList.forEach(
      (item) =>
      (newVersion[item.MaterialNumber ?? item.MaterialTargetGroupId] = {
        Threshold: "-",
        RangePoints: "-",
        IsContracted: false,
      })
    );

    localData.Doors.push({
      id: uuidv4(),
      DoorName: `Door ${localData.Doors.length + 1}`,
      DoorNumber: parseFloat(localData.Doors.length + 1),
      Versions: 1,
      Data: [newVersion],
    });
    setCoreRangeData(localData);
  };

  const addVersion = (doorIndex) => {
    let localData = { ...coreRangeData };
    localData.Doors[doorIndex].Versions += 1;
    let newVersion = {};
    localData.ProductList.forEach(
      (item) =>
      (newVersion[item.MaterialNumber ?? item.MaterialTargetGroupId] = {
        Threshold: "-",
        RangePoints: "-",
        IsContracted: false,
      })
    );
    localData.Doors[doorIndex].Data.push(newVersion);
    setCoreRangeData(localData);
  };

  const removeDoor = (doorIndex) => {
    let localData = { ...coreRangeData };
    localData.Doors.splice(doorIndex, 1);
    setCoreRangeData(localData);
  };

  const renameDoor = (doorId, newName) => {
    if (!newName) {
      return;
    }
    let localData = { ...coreRangeData };
    let doorArray = localData.Doors;
    let tmp = null;
    let tmpId = null;

    doorArray.map((door, index) => {
      if (door.id === doorId) {
        tmp = door.DoorName;
        tmpId = door.id;
        door.DoorName = `Door ${newName}`;
        door.DoorNumber = parseFloat(newName);
      }
    });

    let valueArr = doorArray.map((item) => {
      return item.DoorName;
    });
    let isDuplicate = valueArr.some((item, index) => {
      return valueArr.indexOf(item) != index;
    });

    if (isDuplicate) {
      doorArray.map((door) => {
        if (door.id === tmpId) {
          door.DoorName = tmp;
        }
      });
      setDuplicateDoorError(true);
      setSnackbarOpen(true);
      setUpsertError(false);
    }

    setCoreRangeData(localData);
    setEdit(false);
  };

  const handleEdit = (id, text) => {
    setEdit(id);
    setInputValue(text);
  };

  const removeVersion = (doorIndex, versionIndex) => {
    let localData = { ...coreRangeData };
    localData.Doors[doorIndex].Versions -= 1;
    if (localData.Doors[doorIndex].Versions === 0) removeDoor(doorIndex);
    else {
      localData.Doors[doorIndex].Data.splice(versionIndex, 1);
    }
    setCoreRangeData(localData);
  };

  const copyVersion = (copyFromInfo, copyToInfo) => {
    let localData = { ...coreRangeData };
    //get the data to copy
    //get the object that is getting the copied data
    //make the object = to the data thats being copied

    let dataToCopy =
      localData.Doors[copyFromInfo.door].Data[copyFromInfo.version];
    let tmp = _.cloneDeep(dataToCopy);
    localData.Doors[copyToInfo.door].Data[copyToInfo.version] = tmp;
    setCoreRangeData(localData);
  };

  const SaveDraftofCoreRange = () => {
    let localData = { ...coreRangeData };
    localData.PublishedBy = "";
    localData.PublishedDate = "";
    localData.ReadyToPublish = false;
    setCoreRangeData(localData);
  };

  const publishCoreRange = () => {
    let localData = { ...coreRangeData };
    localData.PublishedDate = new Date();
    localData.PublishedBy = createdUser;
    localData.ReadyToPublish = false;
    setCoreRangeData({
      ...coreRangeData,
      PublishedDate: localData.PublishedDate,
      PublishedBy: localData.PublishedBy,
      ReadyToPublish: localData.ReadyToPublish,
    });
  };

  const saveCoreRange = () => {
    const localData = { ...coreRangeData };
    localData.PublishedBy = "";
    localData.PublishedDate = "";
    localData.ReadyToPublish = true;
    localData.ModifiedDate = new Date();
    localData.ModifiedBy = createdUser;
    setCoreRangeData(localData);
  };

  const cancelCoreRange = () => {
    setCoreRangeData({ ...initialData });
    props.history.push("/core-range");
  };

  const isNumeric = (str) => {
    return !isNaN(str - parseFloat(str));
  }

  const upsertCoreRange = (type) => {
    setUpsertHasValidationError(false);

    const uuidRegExp = new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/);
    const localData = { ...coreRangeData };
    var doUpsert = true;

    // Default value (just in case there is new type).
    var typeDescription = 'save Core Range';
    if (type === "PUBLISH") {
      typeDescription = 'publish Core Range';
    } else if (type === "UPDATE") {
      typeDescription = 'submit Core Range for review';
    } else if (type === "DRAFT") {
      typeDescription = 'save Core Range as draft';
    }

    localData.Doors.forEach(function (door) {
      if (!doUpsert) {
         return;
      }

      const doorName = door.DoorName;
      var versionNumber = 0;

      door.Data.forEach(function (doorData) {
        if (!doUpsert) {
           return;
        }

        versionNumber = versionNumber + 1;

        for (const [key, value] of Object.entries(doorData)) {
          if (!doUpsert) {
             break;
          }

          const id = key;
          const threshold = value.Threshold;
          const rangePoints = value.RangePoints;

          const isNumericThreshold = isNumeric(threshold);
          const isNumericRangePoints = isNumeric(rangePoints);

          setUpsertTypeDescription(typeDescription);
          setDoorDescription(`${doorName} version ${versionNumber}`);

          if (uuidRegExp.test(id)) {
            const productGroup = TargetGroupList.find(tg => tg.TargetGroupId === id);

            if (productGroup) {
              setProductDescription(`"${productGroup.TargetGroupName}" product group`);

              // AC2: When both Threshold and RangePoints is empty, allow submission and don't generate data for it.
              // This will be handled by Core Range API.
              if (!isNumericThreshold && !isNumericRangePoints) {
                continue;
              }

              // AC5: When both Threshold and RangePoints is zero, allow submission and don't generate data for it.
              // This will be handled by Core Range API.
              if (threshold == 0 && rangePoints == 0) {
                continue;
              }

              if (isNumericThreshold) {
                // AC1: When Threshold is greater than 0, we save the record (if RangePoints is empty/unspecified, this should be treated as 0 points)
                if (threshold > 0) {
                  // Allow the frontend to parse the value to integer as it was only acceptable type for Threshold on backend.
                  value.Threshold = parseInt(threshold).toString();
                } else {
                  // AC3: When Threshold is empty or zero and RangePoints is populated (any value), show a warning for product group as
                  // RangePoints with zero or negative Threshold makes no sense.
                  if (isNumericRangePoints) {
                    setDetailedValidationError(`Range Point with zero or negative Threshold is invalid.`);
                    doUpsert = false;
                    continue;
                  }
                }
              } else {
                // AC3: When Threshold is empty or zero and RangePoints is populated (any value), show a warning for product group as
                // RangePoints without Threshold makes no sense.
                if (isNumericRangePoints) {
                  setDetailedValidationError(`Range Point with blank Threshold is invalid.`);
                  doUpsert = false;
                  continue;
                }
              }
            } else {
              // If productGroup is null or undefined, notify the user that the specific product group is not available.
              setProductDescription('product group');
              setDetailedValidationError(`Product group not found using the id: ${id}`);
              doUpsert = false;
              continue;
            }
          } else {
            const product = ProductList.find(tg => tg.Number === id);

            if (product) {
              setProductDescription(`"${product.Description}" product`);
            } else {
              // If product is null or undefined, notify the user that the specific product is not available.
              setProductDescription('product');
              setDetailedValidationError(`Product not found using the id: ${id}`);
              doUpsert = false;
              continue;
            }
          }

          // AC4: When RangePoints is populated but with negative value, show a warning for product that the RangePoints is negative.
          if (isNumericRangePoints && parseFloat(rangePoints) < 0) {
            setDetailedValidationError(`Range Point value must not negative.`);
            doUpsert = false;
          }
        }
      });
    });

    if (doUpsert) {
      // Clear any validation error message.
      setUpsertHasValidationError(false);

      // And do the upsert operation according to type.
      if (type === "PUBLISH") {
        publishCoreRange();
        setRequestPublish(doUpsert);
      } else if (type === "UPDATE") {
        saveCoreRange();
        setRequestSave(doUpsert);
      } else if (type === "DRAFT") {
        SaveDraftofCoreRange();
        setRequestDraft(doUpsert);
      }
    } else {
      // Raise validation error.
      setUpsertHasValidationError(true);
      setTimeout(() => {
        setUpsertHasValidationError(false);
      }, 10000);
    }
  };

  const updateValue = (doorIndex, version, productId, attribute, value) => {
    const localData = { ...coreRangeData };
    const targetRange = TargetGroupList.filter(
      (targetGroup) => targetGroup.Counter === productId
    )[0];
    if (targetRange) {
      localData.Doors[doorIndex].Data[version][targetRange.TargetGroupId][
        attribute
      ] = value;
    } else {
      localData.Doors[doorIndex].Data[version][productId][attribute] = value;
    }
    setCoreRangeData(localData);
  };

  // Handle deleting items from the Core Range
  const deleteItems = (itemIds) => {
    const localData = { ...coreRangeData };

    itemIds.map((itemId) => {
      // Check if we're dealing with a Target Group
      const targetRange = TargetGroupList.filter(
        (targetGroup) => targetGroup.Counter === itemId
      )[0];

      localData.Doors.map((door) => {
        door.Data.map((version) => {
          if (targetRange) {
            delete version[targetRange.TargetGroupId];
            return;
          }
          delete version[itemId];
        });
      });

      const updatedProductList = localData.ProductList.filter((product) => {
        if (targetRange) {
          return product.MaterialTargetGroupId != targetRange.TargetGroupId;
        }
        return product.MaterialNumber != itemId;
      });
      localData.ProductList = updatedProductList;
      setCoreRangeData({ ...localData });
    });

    setEditProductsAlert({
      message: `Deleted ${itemIds.length} item${itemIds.length !== 1 ? "s" : ""
        }`,
      type: "success",
    });
  };

  useEffect(() => {
    setLoading(true);

    dispatch(
      GetProductList(loggedInUser.territory === "au" ? 1 : 2, () => {
        if (coreRangeId == "new") {
          setLoading(false);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (selectedTargetGroup) {
      dispatch(
        getTargetGroup(selectedTargetGroup, (tg) => {
          setLoading(false);
        })
      );
    }
  }, [selectedTargetGroup]);

  const createTargetGroupErrorElement = targetGroupError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Target Group was unable to be created. -{" "}
      <strong>Please Try Again.</strong>
    </Alert>
  ) : null;

  const renameDoorErrorElement = duplicateDoorError ? (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        Door names cannot be the same.
      </Alert>
    </Snackbar>
  ) : null;

  const upsertCoreRangeErrorElement = upsertError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Failed to {upsertType.toLowerCase()} new Core Range -{" "}
      <strong>Try Again Later.</strong>
    </Alert>
  ) : null;

  const upsertCoreRangeValidationErrorElement = upsertHasValidationError ? (
    <Alert severity="error">
      <AlertTitle>Validation Error</AlertTitle>
      <p>Failed to {upsertTypeDescription}. Error on <strong>{productDescription}</strong> under <strong>{doorDescription}</strong>.</p>
      <p>{detailedValidationError}</p>
    </Alert>
  ) : null;

  const editProductsAlertElement = editProductsAlert ? (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={editProductsAlert}
      autoHideDuration={5000}
      onClose={() => {
        setEditProductsAlert(false);
      }}
    >
      <Alert
        severity={editProductsAlert.type || "info"}
        onClose={() => {
          setEditProductsAlert(false);
        }}
      >
        {editProductsAlert.message}
      </Alert>
    </Snackbar>
  ) : null;

  const upsertCoreRangePublishSuccessElement =
    upsertSuccess && upsertType === "PUBLISH" ? (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Core Range {upsertType.toLowerCase()}ed successfully
      </Alert>
    ) : null;

  const upsertCoreRangeSavedSuccessElement =
    upsertSuccess && upsertType === "UPDATE" ? (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Core Range saved successfully
      </Alert>
    ) : null;

  const upsertCoreRangeDraftSuccessElement =
    upsertSuccess && upsertType === "DRAFT" ? (
      <Alert severity="success">
        <AlertTitle>Success</AlertTitle>
        Core Range saved as a draft
      </Alert>
    ) : null;

  return (
    <>
      {loading ? (
        <Loading></Loading>
      ) : (
        <>
          <HeaderBlock
            title={
              isNewCoreRange ? "New Core Range" : coreRangeData.Description
            }
            right={
              <Grid
                container
                spacing={1}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <ButtonGroup>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={cancelCoreRange}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => upsertCoreRange(CoreRangeType.draft)}
                    >
                      Save as Draft
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        upsertCoreRange(CoreRangeType.save);
                      }}
                    >
                      Submit for Review
                    </Button>
                    {loggedInUserRoles.includes("Core Range Admin") ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => upsertCoreRange(CoreRangeType.publish)}
                      >
                        Publish
                      </Button>
                    ) : null}
                  </ButtonGroup>
                </Grid>
              </Grid>
            }
          />

          <PageContainer>
            {userRolesList.map((u) => {
              <span>{u.id}</span>;
            })}
            {createTargetGroupErrorElement}
            {renameDoorErrorElement}
            {upsertCoreRangeErrorElement}
            {upsertCoreRangeValidationErrorElement}
            {upsertCoreRangePublishSuccessElement}
            {upsertCoreRangeSavedSuccessElement}
            {upsertCoreRangeDraftSuccessElement}
            {editProductsAlertElement}
            <Paper style={{ margin: "2px", padding: "10px" }}>
              <NewTargetGroupModal
                currentTargetGroup={currentTargetGroup}
                createdUser={createdUser}
                isModal={true}
                type={FormType.VIEW}
                targetGroupId={viewTargetGroup.TargetGroupId}
                targetGroupError={targetGroupError}
                setTargetGroupError={setTargetGroupError}
                setTargetModalOpen={setTargetModalOpen}
                targetModalOpen={targetModalOpen}
              />

              <TargetGroupModal
                createdUser={createdUser}
                isModal={true}
                type={FormType.VIEW}
                targetGroupError={targetGroupError}
                setTargetGroupError={setTargetGroupError}
                setTargetModalOpen={setCreateTargetModalOpen}
                targetModalOpen={createTargetModalOpen}
              />

              <NewProductGroupModal
                addProductGroupOpen={addProductGroupOpen}
                setAddProductGroupOpen={setAddProductGroupOpen}
                defaultModel={ModelType.MATERIAL}
              />

              <Grid
                container
                spacing={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item xs={3} md={3} lg={3}>
                  <FormControl fullWidth>
                    <TextField
                      label="Description"
                      placeholder="Enter Core Range Description"
                      value={coreRangeData.Description}
                      onChange={setDescription}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={userRolesList.map((u) => u.email)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Owner"
                          placeholder="Search for a users email..."
                        />
                      )}
                      value={coreRangeData.Owner}
                      onChange={setOwner}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography style={{ paddingTop: "10px" }}>
                    <strong>Created At:</strong>{" "}
                    {coreRangeData.CreatedDate
                      ? format(
                        new Date(coreRangeData.CreatedDate),
                        "dd/MM/yyyy"
                      )
                      : ""}
                  </Typography>
                  {/* <Typography style={{ paddingTop: "20px" }}>
                    <strong>Last Modified:</strong>{" "}
                    {coreRangeData.modifiedDate
                      ? format(
                          new Date(coreRangeData.modifiedDate),
                          "MM/dd/yyyy p"
                        )
                      : ""}
                  </Typography> */}
                </Grid>
                <Grid
                  item
                  xs={3}
                  md={2}
                  lg={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography style={{ paddingTop: "5px" }}>
                    <strong>Created By:</strong> {coreRangeData.CreatedBy}
                  </Typography>
                  {/* <Typography style={{ paddingTop: "20px" }}>
                    <strong>Modified By:</strong> {coreRangeData.modifiedBy}
                  </Typography> */}
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: "none",
                  }}
                >
                  Active{" "}
                  <Checkbox
                    checked={coreRangeData.IsActive}
                    onChange={(event) =>
                      setCoreRangeData({
                        ...coreRangeData,
                        IsActive: !coreRangeData.IsActive,
                      })
                    }
                  ></Checkbox>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 40,
                  paddingleft: 100,
                }}
              >
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={3}
                  className="core-range-target-group"
                >
                  {isNewCoreRange ? (
                    <FormControl fullWidth>
                      <Autocomplete
                        options={TargetGroupList.filter(
                          (targetGroup) => targetGroup.ModelType === "customer"
                        )}
                        getOptionLabel={(option) => option.TargetGroupName}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer Target Group"
                          />
                        )}
                        onChange={setTargetGroupId}
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Autocomplete
                        options={Array.from(1)}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer Target Group"
                          />
                        )}
                        value={viewTargetGroup.Name}
                      />
                    </FormControl>
                  )}

                  <ButtonGroup className="core-range-button-group">
                    {isNewCoreRange && (
                      <Button
                        onClick={() => {
                          setCurrentlySelectedTargetGroup({
                            counter: 0,
                            hidden: false,
                            ModelType: "customer",
                            Site: "2",
                            TargetGroupDescription: "",
                            TargetGroupId: "",
                            TargetGroupName: "",
                          });
                          setCreateTargetModalOpen(true);
                        }}
                      >
                        New
                      </Button>
                    )}
                    <Button
                      onClick={() => setTargetModalOpen(true)}
                      disabled={!coreRangeData.CustomerTargetGroupId}
                    >
                      View
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={3}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ButtonGroup
                    style={{ paddingTop: "16px", paddingLeft: "16px" }}
                  >
                    <Button
                      disabled={coreRangeData.Doors.length === 0}
                      onClick={toggleContracted}
                    >
                      Toggle Contracted Range
                    </Button>
                  </ButtonGroup>
                </Grid>
                {!isNewCoreRange ? (
                  <>
                    {" "}
                    <Grid
                      item
                      xs={2}
                      md={2}
                      lg={2}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography style={{ paddingTop: "16px" }}>
                        <strong>Last Modified:</strong>{" "}
                        {coreRangeData.ModifiedDate
                          ? format(
                            new Date(coreRangeData.ModifiedDate),
                            "dd/MM/yyyy p"
                          )
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      md={2}
                      lg={3}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography style={{ paddingTop: "16px" }}>
                        <strong>Modified By:</strong> {coreRangeData.ModifiedBy}
                      </Typography>
                    </Grid>
                  </>
                ) : null}

                {/* <Grid
                  item
                  xs={2}
                  md={2}
                  lg={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button color="secondary" variant="contained">
                    Publish
                  </Button>
                </Grid> */}
              </Grid>
              <Box
                style={{
                  width: "100%",
                  typography: "body1",
                  paddingTop: 40,
                }}
              >
                <CoreRangeProductsTable
                  tableProducts={tableProducts}
                  materialTargets={TargetGroupList.filter(
                    (s) => s.ModelType == "material"
                  ).map((item) => {
                    return {
                      id: item.TargetGroupId,
                      Number: item.Counter,
                      Description: item.TargetGroupName,
                    };
                  })}
                  addDoor={addDoor}
                  addNewMaterial={setAddMaterialOpen}
                  addProducts={addProducts}
                  addVersion={addVersion}
                  copyVersion={copyVersion}
                  deleteItems={deleteItems}
                  Doors={coreRangeData.Doors}
                  edit={edit}
                  handleEdit={handleEdit}
                  inputValue={inputValue}
                  isContractedRange={contractedRange}
                  productData={ProductList}
                  productList={coreRangeData.ProductList}
                  removeVersion={removeVersion}
                  renameDoor={renameDoor}
                  setEdit={setEdit}
                  setInputValue={setInputValue}
                  setAddProductGroupOpen={setAddProductGroupOpen}
                  toggleContractedProduct={toggleContractedProduct}
                  updateValue={updateValue}
                  submitExcelData={submitExcelData}
                  downloadExcelTemplateData={downloadExcelTemplateData}
                  downloadLogFileData={downloadLogFileData}
                />
              </Box>
            </Paper>
          </PageContainer>
        </>
      )}
    </>
  );
};

const hoc = withRouter(CoreRangeView);

// EXPORT COMPONENT
export { hoc as CoreRangeView };
