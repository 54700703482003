//React
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Redux
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

import { RootState } from "../../../state/reducers/AppReducer";
import { UserReducerState } from "../../../state/reducers/UserReducer";

//Styling
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import "../styles/style.css";

//API
import {
  getActivitiesById,
  GetDisplayGroupById,
  UpsertDisplayGroup,
} from "../../../state/actions/MyDisplaysActions";

//Components
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { DisplayTypeCard } from "./components/DisplayTypeCard";
import { ActivityTarget } from "./components/ActivityTarget";
import NotFoundPage from "../../not-found/NotFoundPage";

import { getActivityById } from "../../../state/actions/ActivityActions";
import { Loading } from "../../../components/loading/Loading";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getApiConfig } from "../../../config/apiConfig";
import { ActivityReducerState } from "../../../state/reducers/ActivityReducer";
import { MyDisplaysReducerState } from "../../../state/reducers/MyDisplaysReducer";
import { getActivityRulesDropdownData } from "../../../state/constants/ActivityRules";

const API_CONFIG = getApiConfig();

export interface Target {
  ActivityTargetId: number;
  BlackCodeId: string;
  BusinessOwnerId: string;
  BusinessTypeId: string;
  City: string;
  ClocCodeId: string;
  CreatedBy: string;
  CreatedDate: string;
  CustomerNumbers: string[];
  CustomerTypeId: string;
  DistributionChannelId: string;
  GlobalCustomerNumberId: string;
  Id: number;
  IsActive: true;
  L2CustomerNumber: string;
  L3CustomerNumber: string;
  L4CustomerNumber: string;
  L5CustomerNumber: string;
  LicenseTypeId: string;
  MarketTypeId: string;
  ModifiedBy: string | null;
  ModifiedDate: string | null;
  OperationalRegionId: string;
  OpportunityValue: number;
  PostalCode: string;
  PrecinctId: string;
  PreferredOrderMethodId: string;
  PriceBookId: string;
  RedCodeId: string;
  RegionCode: string;
  SalesOfficeId: string;
  SalesPolicyId: string;
  Sequence: number;
  ServicePolicyId: string;
  Site: number;
  SubTradeChannelId: string;
  TargetValue: number;
  TradeChannelId: string;
  TradeNameId: string;
}

export interface Reward {
  ActivityId: number;
  CreatedBy: string;
  CreatedDate: string;
  IsActive: boolean;
  ModifiedBy: string;
  ModifiedDate: string;
  UpperRange: number;
  Value: number;
}

export interface Choice {
  ActivityId: number;
  BeverageCategory: string | null;
  Brand: string | null;
  CreatedBy: string;
  CreatedDate: string;
  Image1: string | null;
  Image2: string | null;
  Image3: string | null;
  IsActive: boolean;
  Material: string | null;
  MinimumVariants: number;
  ModifiedBy: string;
  ModifiedDate: string;
  Name: string;
  PackSize: number | null;
  PackType: string | null;
  SelectionType: number;
  SubText: string | null;
  Weight: number;
}

export interface ActivityType {
  Code: number;
  CreatedDate: string;
  IsActive: boolean;
  ModifiedDate: string;
  Value: string;
}

export interface DisplayTypeActivity {
  ActivityDataTypeId: number;
  ActivityFrequencyId: number;
  ActivityGroupId: number;
  ActivityTypeId: number;
  ClientId: number | null;
  createdBy: string;
  CreatedDate: string;
  EndDate: string;
  Id: number;
  IsActive: boolean;
  IsOneTimeAnswer: boolean;
  IsRequired: boolean;
  Max: number;
  Min: number;
  ModifiedBy: string;
  ModifiedDate: string;
  Name: string;
  Sequence: number;
  StartDate: string;
}

export interface Activity {
  MyDisplaysActivityId: number | string;
  Activity: DisplayTypeActivity;
  ActivityDataType: ActivityType;
  ActivityFrequency: ActivityType;
  ActivityType: ActivityType;
  Choices: Choice[];
  Rewards: Reward[];
  Targets: Target[];
  MaxScore: number;
}
interface Display {
  DisplayTypeActivityId: string | number;
  Activities: Activity[];
  DisplayTypeActivity: DisplayTypeActivity;
}

export interface DisplayGroup {
  ActivityTargets?: any;
  CreatedBy?: string | undefined;
  CreatedDate?: string | undefined;
  Description?: string | undefined;
  Displays?: Display[];
  GroupId?: string;
  ModifiedBy?: string | undefined;
  ModifiedDate?: string | undefined;
  Site?: number;
}

// FIXME: history should be a string[] right?
const MyDisplaysRedView = (props: {
  match: { params: { DisplayGroupId: any } };
  location: { pathname: string };
  history: any;
}) => {
  const displayGroupId = props.match.params.DisplayGroupId;
  const dispatch = useDispatch();

  let activityIds = null;

  //Selector
  const { loggedInUser } = useSelector<RootState, UserReducerState>(
    (state) => state.UserReducer
  );

  let createdUser = "";
  if (loggedInUser !== undefined && loggedInUser !== null) {
    createdUser = loggedInUser.userName.split("\\")[1];
  }

  const { currentActivity } = useSelector<RootState, ActivityReducerState>(
    (state) => state.ActivityReducer
  );
  const { displayActivities } = useSelector<RootState, MyDisplaysReducerState>(
    (state) => state.MyDisplaysReducer
  );

  //State
  const [existingDisplayGroup, setExistingDisplayGroup] = useState<
    DisplayGroup | undefined
  >();
  const [activityTargets, setActivityTargets] = useState([]);
  const [displayGroupDescriptionError, setDisplayGroupDescriptionError] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const [updateError, setUpdateError] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setLoading(true);
    setNotFound(false);
    setExistingDisplayGroup(undefined);

    dispatch(getLoggedInUserByEmail(() => {}));

    dispatch(
      GetDisplayGroupById(displayGroupId, (displayGroup: any) => {
        if (displayGroup == false) {
          setNotFound(true);
        } else {
          setExistingDisplayGroup(displayGroup);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (
      existingDisplayGroup !== undefined &&
      existingDisplayGroup !== null &&
      existingDisplayGroup.Displays !== undefined &&
      existingDisplayGroup.Displays.length > 0
    ) {
      dispatch(
        getActivityById(
          existingDisplayGroup.Displays[0].DisplayTypeActivityId,
          () => {}
        )
      );
    }
  }, [existingDisplayGroup]);

  useEffect(() => {
    if (
      existingDisplayGroup !== null &&
      existingDisplayGroup !== undefined &&
      existingDisplayGroup.Displays !== undefined &&
      existingDisplayGroup.Displays.length > 0
    ) {
      activityIds = existingDisplayGroup.Displays.map(
        (display: any) => display.DisplayTypeActivityId
      );
      dispatch(
        getActivitiesById(activityIds, () => {
          setLoading(false);
        })
      );
    }
  }, [currentActivity]);

  useEffect(() => {
    let displayGroupTargets: any = [];

    displayActivities.forEach((activity: any) => {
      activity.Targets.forEach((tg: any) => {
        displayGroupTargets.push(tg.ActivityTargetId);
      });
    });

    //@ts-ignore
    let uniqueTargets = [...new Set(displayGroupTargets)];
    setActivityTargets(
      createTargetFromResponse(
        existingDisplayGroup?.ActivityTargets.filter((i: any) =>
          uniqueTargets.includes(i.Id)
        )
      )
    );
  }, [displayActivities]);

  const createTargetFromResponse = (targetArray: any[]) => {
    const dropdownData = getActivityRulesDropdownData(null);

    let targets: any = [];
    let tmp: any = {};

    if (targetArray && targetArray.length > 0) {
      targetArray.forEach((target: any, i: number) => {
        tmp["id"] = target.Id;
        tmp["isActive"] = target.IsActive;
        tmp["rules"] = [];
        Object.values(dropdownData).forEach((item: any) => {
          if (
            target[item.requestFieldName] !== null &&
            target[item.requestFieldName] !== "" &&
            target[item.requestFieldName] !== " "
          ) {
            let rule: any = {};
            rule["name"] = item.label;
            rule["value"] = target[item.requestFieldName];
            tmp.rules.push(rule);
          }
        });
        targets.push(tmp);
        tmp = {};
      });
    }
    return targets;
  };

  const handleDisplayGroupDescription = (e: any) => {
    if (e.target.value.length >= 1) {
      setDisplayGroupDescriptionError(false);
    }
    let localData = { ...existingDisplayGroup };
    localData.Description = e.target.value;
    setExistingDisplayGroup(localData);
  };

  const flatten = (item: any) => {
    let output: any = {};
    for (const i in item) {
      if (typeof item[i] === "object" && !Array.isArray(item[i])) {
        const temp = flatten(item[i]);
        for (const j in temp) {
          output[j] = temp[j];
        }
      } else {
        output[i] = item[i];
      }
    }
    return output;
  };

  const submitData = () => {
    if (
      existingDisplayGroup !== null &&
      existingDisplayGroup !== undefined &&
      !existingDisplayGroup.Description
    ) {
      setDisplayGroupDescriptionError(true);
    } else {
      let localData = { ...existingDisplayGroup };
      localData.ModifiedDate = new Date().toString();
      localData.ModifiedBy = createdUser;
      setExistingDisplayGroup(localData);
      dispatch(
        UpsertDisplayGroup(existingDisplayGroup, (success: any, data: any) => {
          if (success) {
            setIsEditing(false);
          } else {
            setUpdateError(true);
          }
        })
      );
    }
  };

  const buttonGroupElement = isEditing ? (
    <ButtonGroup style={{ marginRight: "35px" }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setIsEditing(false)}
      >
        Cancel
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        disabled={updateError}
        onClick={submitData}
      >
        Save
      </Button>
    </ButtonGroup>
  ) : (
    <Button
      style={{ marginRight: "35px" }}
      variant="outlined"
      color="secondary"
      onClick={() => setIsEditing(true)}
    >
      Edit
    </Button>
  );

  const updateAlertElement = updateError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      Failed to update display group<strong> Please contact support</strong>
    </Alert>
  ) : null;

  return (
    <div className="red-page">
      {!loading ? (
        <>
          <HeaderBlock
            title={
              existingDisplayGroup !== undefined &&
              existingDisplayGroup.Description
                ? existingDisplayGroup.Description
                : ""
            }
            right={
              <Grid
                container
                spacing={3}
                alignItems="center"
                justify="flex-end"
              >
                {buttonGroupElement}
              </Grid>
            }
          />

          <div style={{ marginTop: "100px", marginBottom: "1.5rem" }}>
            {updateAlertElement}
            <div style={{ marginTop: "150px" }}>
              <div className="create-container">
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={4}>
                    <Grid container direction="column">
                      <FormControl>
                        <TextField
                          style={{ marginTop: "20px" }}
                          variant="outlined"
                          disabled
                          label={"Id"}
                          InputLabelProps={{ shrink: true }}
                          placeholder={
                            existingDisplayGroup !== undefined &&
                            existingDisplayGroup.GroupId
                              ? existingDisplayGroup.GroupId
                              : ""
                          }
                        />
                        <TextField
                          required
                          style={{ marginTop: "20px" }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          placeholder={"Enter Display Group Description"}
                          value={
                            existingDisplayGroup !== undefined &&
                            existingDisplayGroup.Description
                          }
                          label="Description"
                          helperText={
                            displayGroupDescriptionError
                              ? "Group Description is Required."
                              : ""
                          }
                          error={displayGroupDescriptionError}
                          onChange={(e) => handleDisplayGroupDescription(e)}
                          disabled={!isEditing}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" spacing={5}>
                      <Grid item>
                        <span style={{ fontSize: "1.2rem" }}>
                          <strong>Created By:</strong>
                        </span>
                        <span style={{ color: "#6b7280", fontStyle: "italic" }}>
                          &nbsp;{" "}
                          {existingDisplayGroup !== undefined &&
                            existingDisplayGroup.CreatedBy}
                        </span>
                      </Grid>
                      <Grid item>
                        <span style={{ fontSize: "1.2rem" }}>
                          <strong>Last Updated By:</strong>
                        </span>
                        <span style={{ color: "#6b7280", fontStyle: "italic" }}>
                          &nbsp;{" "}
                          {existingDisplayGroup !== undefined &&
                            existingDisplayGroup.ModifiedBy}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "2.5rem",
              marginLeft: "2.5rem",
            }}
          >
            <Typography variant="h4">
              <strong>MyDisplay Activity Targets</strong>
            </Typography>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              style={{ textTransform: "capitalize", marginTop: "15px" }}
              onClick={() =>
                props.history.push(
                  `/my-displays-maintenance/${displayGroupId}/target/new`
                )
              }
            >
              New Target
            </Button>
          </div>

          {/* Target Group */}
          {!notFound && (
            <>
              {activityTargets.map((target) => (
                <ActivityTarget
                  displayGroupId={displayGroupId}
                  target={target}
                />
              ))}
            </>
          )}
          <div className="display-type-list">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">
                <strong>Displays</strong>
              </Typography>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                style={{ textTransform: "capitalize", marginTop: "15px" }}
                onClick={() =>
                  props.history.push(
                    `/my-displays-maintenance/${displayGroupId}/new`
                  )
                }
              >
                New Display
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!notFound &&
                existingDisplayGroup !== undefined &&
                displayActivities !== undefined &&
                displayGroupId !== undefined && (
                  <DisplayTypeCard
                    existingDisplayGroup={existingDisplayGroup}
                    displayGroupId={displayGroupId}
                    displayActivities={displayActivities}
                  />
                )}
            </div>
          </div>
        </>
      ) : notFound ? (
        <NotFoundPage />
      ) : (
        <Loading />
      )}
    </div>
  );
};

const hoc = withRouter(connect()(MyDisplaysRedView));

// EXPORT COMPONENT
export { hoc as MyDisplaysRedView };
