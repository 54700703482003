import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

//UI
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import HeaderBlock from "components/header-block/HeaderBlock";
import styles from "./ProgrammeDiscrepancyCheck.module.scss";
import { getIcon } from "../../../../icon/icon";

//Components
import { Loading } from "components/loading/Loading";
import PageContainer from "components/page-container/PageContainer";
import SimpleTable from "components/table/Table";

//Types
import {
  ProgrammeId,
  ProgrammeSignUpObject,
  ProgrammeSignUp,
  NavigationTab,
  Header,
  Cell,
} from "./util/types";

//API
import { adalApiFetch } from "../../../../config/azureConfig";
import Axios from "axios";
import { getApiConfig } from "../../../../config/apiConfig";
import { PagePlaceholder } from "components/page-placeholder/PagePlaceholder";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabPanelRoot: {
    padding: 0,
  },
}));

const API_CONFIG = getApiConfig();

const ProgrammeDiscrepancyCheck: React.FC = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>("1");
  const [programmeIds, setProgrammeIds] = useState<ProgrammeId[]>([]);
  const [programmeSignUps, setProgrammeSignUps] =
    useState<ProgrammeSignUpObject | null>();
  const [selectedProgrammeId, setSelectedProgrammeId] = useState("");

  const tabs: NavigationTab[] = [
    {
      id: "inSamcoreOnly",
      name: "SAM Core Only",
      current: true,
      value: "1",
    },
    {
      id: "inSapOnly",
      name: "SAP Only",
      current: false,
      value: "2",
    },
    {
      id: "inBoth",
      name: "Signed Up Multiple Times",
      current: false,
      value: "3",
    },
  ];

  useEffect(() => {
    setLoading(true);

    const fetchProgrammeIds = async () => {
      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/GetProgrammeIds`,
        {
          method: "get",
          headers: headers,
        }
      );

      const data = response.data;
      setProgrammeIds(data);
      setLoading(false);
    };
    fetchProgrammeIds();
  }, []);

  const fetchProgrammeSignUps = async (programmeId: string) => {
    setFetching(true);
    const headers = { "Content-Type": "application/json" };
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.TARGETING}/DiffProgrammeSignups?id=${programmeId}`,
      {
        method: "get",
        headers: headers,
      }
    );
    const data = response.data;
    if (data.inSamcoreOnly.length > 0) {
      data.inSamcoreOnly.forEach((item: ProgrammeSignUp) => {
        item.name === null ? (item.name = "missing") : (item.name = item.name);
        item.signupDate = new Date(
          Date.parse(item.signupDate)
        ).toLocaleDateString("en-AU");

        item.createdDate = new Date(
          Date.parse(item.createdDate)
        ).toLocaleDateString("en-AU");
      });
    }
    if (data.isSignedUpMultipleTimes.length > 0) {
      data.isSignedUpMultipleTimes.forEach(
        (item: ProgrammeSignUp, index: number) => {
          let obj: any = {};
          obj["name"] = "missing";
          obj["number"] = item;
          data.isSignedUpMultipleTimes.splice(index, 1, obj);
        }
      );
    }
    setProgrammeSignUps(data);
    setFetching(false);
  };

  const handleProgrammeIdChange = (e: any) => {
    setSelectedProgrammeId(e.target.value);
    fetchProgrammeSignUps(e.target.value);
  };

  const createHeaders = (list: ProgrammeSignUp[] | null | undefined) => {
    if (list === programmeSignUps?.inSamcoreOnly) {
      return [
        {
          id: "name",
          align: "left",
          label: "Customer Name",
        },
        { id: "number", label: "Customer Number", align: "left" },
        { id: "createdDate", label: "Created Date", align: "left" },
        { id: "signupDate", label: "Signed Up Date", align: "left" },
      ];
    } else {
      return [
        {
          id: "name",
          align: "left",
          label: "Customer Name",
        },
        { id: "number", label: "Customer Number", align: "left" },
      ];
    }
  };

  const createCells = (headers: Header[]) => {
    let cells: Cell[] = [];

    headers.forEach((header) => {
      let cell: Cell = {};

      cell.id = header.id;

      cell.align = header.align;

      cells.push(cell);
    });

    return cells;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <HeaderBlock
        title={
          selectedProgrammeId
            ? `Programme Discrepancy Check (ID: ${selectedProgrammeId})`
            : "Programme Discrepancy Check"
        }
        showBackLink={true}
        right={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            style={{ gap: "0.5rem", width: "50%", float: "right" }}
          >
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel id="programmeIdsLabel">Programme IDs</InputLabel>
              <Select
                onChange={handleProgrammeIdChange}
                labelId="programmeIDs"
                id="programmeIDs"
                value={selectedProgrammeId}
                label="Programme IDs"
              >
                {programmeIds.map((item: ProgrammeId) => (
                  <MenuItem
                    key={item.businessOwnerId}
                    value={item.businessOwnerId}
                  >
                    {item.programmeId} / {item.businessOwnerId}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
      />
      <PageContainer>
        <Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                //@ts-ignore
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className={styles.tabList}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.id}
                    label={tab.name}
                    value={tab.value}
                    disabled={fetching}
                  />
                ))}
              </TabList>
            </Box>
            {/* In SamCore */}
            <TabPanel value="1" classes={{ root: classes.tabPanelRoot }}>
              {/* @ts-ignore */}
              {!programmeSignUps ? (
                <div className={styles.placeholderWrapper}>
                  <PagePlaceholder
                    image={getIcon("code.svg")}
                    text="Select a programme ID to view the programme sign ups"
                  />
                </div>
              ) : programmeSignUps &&
                // @ts-ignore
                programmeSignUps?.inSamcoreOnly.length === 0 ? (
                <div className={styles.placeholderWrapper}>
                  <PagePlaceholder
                    image={getIcon("code.svg")}
                    text={`No programme sign ups found for programme id: ${selectedProgrammeId}`}
                  />
                </div>
              ) : (
                <SimpleTable
                  handleSelection={null}
                  isSelectable={false}
                  dataId={"name"}
                  cells={createCells(
                    createHeaders(programmeSignUps?.inSamcoreOnly)
                  )}
                  headers={createHeaders(programmeSignUps?.inSamcoreOnly)}
                  rows={programmeSignUps?.inSamcoreOnly}
                />
              )}
            </TabPanel>
            {/* In Sap */}
            <TabPanel value="2" classes={{ root: classes.tabPanelRoot }}>
              {/* @ts-ignore */}
              {!programmeSignUps ? (
                <div className={styles.placeholderWrapper}>
                  <PagePlaceholder
                    image={getIcon("code.svg")}
                    text="Select a programme ID to view the programme sign ups"
                  />
                </div>
              ) : programmeSignUps &&
                //@ts-ignore
                programmeSignUps?.inSapOnly.length === 0 ? (
                <div className={styles.placeholderWrapper}>
                  <PagePlaceholder
                    image={getIcon("code.svg")}
                    text={`No programme sign ups found for programme id: ${selectedProgrammeId}`}
                  />
                </div>
              ) : (
                <SimpleTable
                  handleSelection={null}
                  isSelectable={false}
                  dataId={"name"}
                  cells={createCells(createHeaders(programmeSignUps.inSapOnly))}
                  headers={createHeaders(programmeSignUps.inSapOnly)}
                  rows={programmeSignUps?.inSapOnly}
                />
              )}
            </TabPanel>
            {/* is Signed up multiple times */}
            <TabPanel value="3" classes={{ root: classes.tabPanelRoot }}>
              {/* @ts-ignore */}
              {!programmeSignUps ? (
                <div className={styles.placeholderWrapper}>
                  <PagePlaceholder
                    image={getIcon("code.svg")}
                    text="Select a programme ID to view the programme sign ups"
                  />
                </div>
              ) : programmeSignUps &&
                // @ts-ignore
                programmeSignUps?.isSignedUpMultipleTimes.length === 0 ? (
                <div className={styles.placeholderWrapper}>
                  <PagePlaceholder
                    image={getIcon("code.svg")}
                    text={`No programme sign ups found for programme id: ${selectedProgrammeId}`}
                  />
                </div>
              ) : (
                <SimpleTable
                  handleSelection={null}
                  isSelectable={false}
                  dataId={"name"}
                  cells={createCells(
                    createHeaders(programmeSignUps.isSignedUpMultipleTimes)
                  )}
                  headers={createHeaders(
                    programmeSignUps.isSignedUpMultipleTimes
                  )}
                  rows={programmeSignUps?.isSignedUpMultipleTimes}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </PageContainer>
    </div>
  );
};

const hoc = withRouter(ProgrammeDiscrepancyCheck);

// EXPORT COMPONENT
export { hoc as ProgrammeDiscrepancyCheck };
