import { AnyAction } from "redux";
import {
  GET_USERS,
  GET_USER,
  GET_USER_BY_EMAIL,
  GET_LOGGED_IN_USER_BY_EMAIL,
  CREATE_USER,
  UPDATE_USER,
  ASSIGN_USER_TO_ROLES,
  REMOVE_USER_FROM_ROLES,
  GET_USERS_FOR_ROLE
} from "../constants/action-types";

export interface LoggedInUser {
  userName: string,
  territory: string,
  userId: string
}

// INITIALIZE STATE
const initialState: UserReducerState = {
  users: {},
  user: {},
  existingUser: {},
  loggedInUser: null,
  usersInRole: {}
};

export function getMappedTerritory(Territory: string) {
  switch (Territory) {
    case ("au"):
      return 1;
    case ("nz"):
      return 2;
    default:
      return null;
  }
}

export function getMappedSite(RouteSite: number) {
  switch (RouteSite) {
    case (1):
      return "au";
    case (2):
      return "nz";
    default:
      return "na";
  }
}

function createUser(serverUser: any) {
  let user = {};

  if (serverUser) {
    let firstName =
      serverUser.FirstName !== null && serverUser.FirstName !== undefined
        ? serverUser.FirstName
        : "Not";

    let lastName =
      serverUser.LastName !== null && serverUser.LastName !== undefined
        ? serverUser.LastName
        : "Set";

    user = {
      id: serverUser.UserId,
      firstName: firstName,
      lastName: lastName,
      name: `${firstName} ${lastName}`,
      email: serverUser.UserName
        ? serverUser.UserName.substring(serverUser.UserName.indexOf("\\") + 1)
        : "",
      territory: getMappedSite(serverUser.RouteSite),
      sapUsername: serverUser.SapUserName ? serverUser.SapUserName : "",
      bdeCustomerNumber: serverUser.BdeCustomerNumber
        ? serverUser.BdeCustomerNumber
        : "",
      bdeCustomerSite: serverUser.BdeCustomerSite
        ? serverUser.BdeCustomerSite
        : "",
      currentCustomerNumber: serverUser.CurrentCustomerNumber
        ? serverUser.CurrentCustomerNumber
        : "",
      RouteSite: serverUser.RouteSite ? serverUser.RouteSite : "",
      emailOptIn: serverUser.EmailOptIn ? serverUser.EmailOptIn : true,
      externalUserId: serverUser.ExternalUserId
        ? serverUser.ExternalUserId
        : "",
      paymentAccess: serverUser.PaymentAccess
        ? serverUser.PaymentAccess
        : false,
      primaryPhoneNumber: serverUser.PrimaryPhoneNumber
        ? serverUser.PrimaryPhoneNumber
        : "",
      secondaryPhoneNumber: serverUser.SecondaryPhoneNumber
        ? serverUser.SecondaryPhoneNumber
        : "",
      isLockedOut: serverUser.IsLockedOut ? serverUser.IsLockedOut : false
    };
  } else {
    user = {
      id: "",
      firstName: "",
      lastName: "",
      name: "",
      email: "",
      sapUsername: "",
      territory: "2",
      bdeCustomerNumber: "",
      bdeCustomerSite: "",
      currentCustomerNumber: "",
      routeSite: "",
      emailOptIn: true,
      externalUserId: "",
      paymentAccess: false,
      primaryPhoneNumber: "",
      secondaryPhoneNumber: "",
      isLockedOut: false
    };
  }

  return user;
}

const createExistingUser = (serverExisitingUser: any) => {
  let existingUser = {
    userId: serverExisitingUser.UserId,
    existsInAuth: serverExisitingUser.existsInAuth,
    existsInSitecore: serverExisitingUser.existsInSitecore,
    existsInActiveDirectory: serverExisitingUser.existsInActiveDirectory,
    userName: serverExisitingUser.userName,
    territory: serverExisitingUser.Territory === 2 ? "nz" : "au",
    firstName:
      serverExisitingUser.adUserInfo !== null
        ? serverExisitingUser.adUserInfo.givenName
        : "",
    surname:
      serverExisitingUser.adUserInfo !== null
        ? serverExisitingUser.adUserInfo.surname
        : "",
    displayName:
      serverExisitingUser.adUserInfo !== null
        ? serverExisitingUser.adUserInfo.displayName
        : "",
  };

  return existingUser;
};

// REDUCER
export const UserReducer = (state = initialState, action: AnyAction): UserReducerState => {
  switch (action.type) {
    case GET_USERS: {
      let userList = action.payload;

      const users: any = {};

      userList.forEach((user: any) => {
        users[user.UserId] = createUser(user);
      });
      
      return {
        ...state,
        users
      };
    }
    case GET_USER: {
      let user = createUser(action.payload);

      return {
        ...state,
        user
      };
    }
    case GET_USER_BY_EMAIL: {
      let existingUser = createExistingUser(action.payload);

      return {
        ...state,
        existingUser
      };
    }
    case GET_LOGGED_IN_USER_BY_EMAIL: {
      let loggedInUser = createExistingUser(action.payload);

      return {
        ...state,
        loggedInUser
      };
    }
    case CREATE_USER: {
      const users: any = Object.assign({}, state.users);
      const newUser = action.payload;

      users[newUser.id] = newUser;

      return {
        ...state,
        users
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload
      };
    }
    case ASSIGN_USER_TO_ROLES: {
      let roleIds = action.payload.roleIds;

      let users: any = Object.assign({}, state.users);
      let user: any = Object.assign({}, state.user);

      if (user.id) {
        if (!user.hasOwnProperty("roles")) {
          user.roles = [];
        }

        roleIds.forEach((roleId: string) => {
          user.roles[roleId] = true;
        });

        //Update the users collection with the updated user
        users[user.id] = user;
      } else {
        user = Object.assign({}, user);

        roleIds.forEach((roleId: string) => {
          user.roles[roleId] = true;
        });
      }

      return {
        ...state,
        users,
        user
      };
    }
    case REMOVE_USER_FROM_ROLES: {
      let roleIds = action.payload.routeIds;

      let users: any = Object.assign({}, state.users);
      let usersInRole: any = Object.assign({}, state.usersInRole);
      let user: any = Object.assign({}, state.user);

      if (usersInRole.hasOwnProperty(user.id)) {
        delete usersInRole[user.id];
      }

      if (!user.hasOwnProperty("roles")) {
        user.roles = [];
      }

      roleIds.forEach((roleId: any) => {
        delete user.roles[roleId];
      });

      //Update the users collection with the updated user
      users[user.id] = user;

      return {
        ...state,
        users,
        user,
        usersInRole
      };
    }
    case GET_USERS_FOR_ROLE: {
      let usersInRoleList: any = action.payload;

      const usersInRole: any = {};

      usersInRoleList.forEach((user: any) => {
        const createdUser: any = createUser(user);
        usersInRole[createdUser.id] = createdUser;
      });

      return {
        ...state,
        usersInRole
      };
    }
    default:
      return state;
  }
};

export interface UserReducerState {
  // Sheet related
  users: {},
  user: {},
  existingUser: {},
  loggedInUser: LoggedInUser | null,
  usersInRole: {}
}
