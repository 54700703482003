// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import Modal from "../../components/dialog/Modal";
import { PPPTargetExpectedColumns } from "../../state/constants/TemplateColumns";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Box,
  Snackbar,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import styles from "./ViewActivityGroupPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
  createActivityGroup,
  getActivityGroupById,
  getDropdownLookups,
  updateActivityGroup,
  getPPPTargetsAsCSV,
  replacePPPTargets,
} from "../../state/actions/ActivityActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";
import {
  isValid,
  format,
  formatISO,
  getYear,
  getMonth,
  getDate,
} from "date-fns";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import PageContainer from "../../components/page-container/PageContainer";

// Models
import { ActivityGroupModel } from "../../models/ActivityGroupModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";

// Moment
var moment = require("moment");
moment().format();

const ViewActivityGroupPage = (props) => {
  // Reducer Based
  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const currentActivityGroup = useSelector(
    (state) => state.ActivityReducer.currentActivityGroup
  );

  // Utils
  const dispatch = useDispatch();

  const typeArray = ["Range", "Share", "Activation"];

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(ActivityGroupModel);
  const [activityGroup, setActivityGroup] = useState({});
  const [uneditedActivityGroup, setUneditedActivityGroup] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingActivityGroup, setIsExistingActivityGroup] = useState(true);
  const [activityGroupId, setActivityGroupId] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [callScheduleTypeLookupData, setCallScheduleTypeLookupData] = useState(
    []
  );
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [lookupsCallMade, setLookupsCallMade] = useState(false);
  const [getActivityGroupCallMade, setGetActivityGroupCallMade] =
    useState(false);
  const [showPPPUploadModal, setShowPPPUploadModal] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [CSVInvalid, setCSVInvalid] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [csvError, setCSVError] = useState(false);

  //Use Effects

  useEffect(() => {
    setLoading(true);

    setIsExistingActivityGroup(
      props.type != null && props.type === FormType.VIEW
    );

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }

    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => {
        setLookupsCallMade(true);
      })
    );
  }, []);

  useEffect(() => {
    if (callScheduleTypeLookupData !== undefined) {
      if (props.type != null && props.type === FormType.VIEW) {
        dispatch(
          getActivityGroupById(props.match.params.activityGroupId, () => {
            setGetActivityGroupCallMade(true);
            setActivityGroupId(props.match.params.activityGroupId);
          })
        );
      } else {
        dispatch(
          getActivityGroupById(undefined, () => {
            setGetActivityGroupCallMade(true);
            setIsEditing(true);
          })
        );
      }
    }
  }, [callScheduleTypeLookupData]);

  const handleDownloadCSVRequest = (id) => {
    dispatch(
      getPPPTargetsAsCSV(id, () => {
        // Callback, doesn't do anything. Might need to.
      })
    );
  };

  useEffect(() => {
    if (loggedInUserCallMade && lookupsCallMade && getActivityGroupCallMade) {
      setLoading(false);
      setDataChanged(!dataChanged);
    }
  }, [loggedInUserCallMade, lookupsCallMade, getActivityGroupCallMade]);

  useEffect(() => {
    setCallScheduleTypeLookupData(lookupData.CallScheduleTypes);
  }, [lookupData]);

  useEffect(() => {
    // Update the DisplayName of the call schedule as we only get the code down from the backend
    if (
      callScheduleTypeLookupData !== undefined &&
      callScheduleTypeLookupData.length > 0
    ) {
      let localActivityGroup = currentActivityGroup;

      const types = localActivityGroup.callScheduleTypes.map((type) => {
        const found = callScheduleTypeLookupData.find(
          (call) => call.Id === type.Id
        );
        return { Id: found.Id, DisplayName: found.DisplayName };
      });

      localActivityGroup.callScheduleTypes = types;
      
      setActivityGroup(localActivityGroup);
    }
  }, [currentActivityGroup]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, activityGroup);

    localData[name] = value;

    setActivityGroup(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      activityGroup[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      if (key === "startDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);

        if (errorMessage === "") {
          let isD1Valid = isValid(new Date(activityGroup["startDate"]));
          let isD2Valid = isValid(new Date(activityGroup["endDate"]));
          if (isD1Valid && isD2Valid) {
            errorMessage = compareDates(
              new Date(activityGroup[key]).toISOString(),
              new Date(activityGroup["endDate"]).toISOString(),
              formProperties[key].label,
              formProperties["endDate"].label,
              false
            );
          }
        }
      } else if (key === "endDate") {
        errorMessage = checkGenericField(key, formProperties[key].required);

        if (errorMessage === "") {
          let isD1Valid = isValid(new Date(activityGroup["startDate"]));
          let isD2Valid = isValid(new Date(activityGroup["endDate"]));
          if (isD1Valid && isD2Valid) {
            errorMessage = compareDates(
              new Date(activityGroup["startDate"]).toISOString(),
              new Date(activityGroup["endDate"]).toISOString(),
              formProperties["startDate"].label,
              formProperties[key].label,
              true
            );
          }
        }
      } else {
        errorMessage = checkGenericField(key, formProperties[key].required);
      }

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    const callScheduleTypes = activityGroup.callScheduleTypes.map((type) => {
      return {
        ScheduleTypeId: type.Id,
      };
    });

    let requestBody = {
      ActivityGroup: {
        Name: activityGroup.name,
        StartDate: activityGroup.startDate,
        EndDate: activityGroup.endDate,
        Sequence: activityGroup.sequence,
        IsActive: activityGroup.active,
        GroupType: activityGroup.groupType,
      },
      ActivityGroupCallSchedules: callScheduleTypes,
    };

    if (isCreate) {
      requestBody.ActivityGroup.CreatedBy = loggedInUser.userId;
      requestBody.ActivityGroup.ModifiedBy = loggedInUser.userId;
    } else {
      requestBody.ActivityGroup.ModifiedBy = loggedInUser.userId;
    }

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      dispatch(
        createActivityGroup(createRequestBody(true), (success, id) => {
          if (success) {
            setIsEditing(false);
            props.history.push(`/activity-groups/${id}`);
          } else {
            setIsEditing(true);
            setSubmitEnabled(true);
            setFormEdited(true);
          }
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateActivityGroup(activityGroupId, createRequestBody(false), () => {
          setIsEditing(false);
          window.location.reload(); // Hack to work around state management bugs not allowing a user to edit the same activity group twice.
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingActivityGroup) {
        props.history.push(`/activity-groups`);
      }
    }
  };

  /*
  Display the cancel editing alert dialog
*/
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false),
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setActivityGroup(uneditedActivityGroup);
              setCancelEditingAlertOpen(false);

              if (!isExistingActivityGroup) {
                props.history.push(`/activity-groups`);
              }
            },
          },
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  // Get the markup for the activities content lists
  const getActivitiesList = (data, fileName) => {
    const listData =
      activityGroup.activities != null
        ? activityGroup.activities.map((item) => {
            return {
              name: item.Activity.Name,
              id: item.Activity.Id,
              active: item.Activity.IsActive ? "True" : "False",
              sequence: item.Activity.Sequence,
            };
          })
        : [];

    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const url = `/activity-groups/${activityGroup.id}/${value}?activityGroup=${activityGroup.name}`;
            return <a href={url}>{value}</a>;
          },
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "active",
        label: "Active",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "sequence",
        label: "Sequence",
        options: {
          filter: false,
          sort: true,
        },
      },
    ];

    const options = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 20,
      rowsPerPageOptions: [5, 20, 50, 100, 200],
      viewColumns: false,
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: true,
      downloadOptions: {
        filename: { fileName },
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    options.customToolbar = () => {
      return (
        <Fragment>
          <Tooltip title="Add Activity">
            <IconButton
              onClick={() =>
                props.history.push(
                  `/activity-groups/${activityGroup.id}/new?activityGroup=${activityGroup.name}`
                )
              }
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      );
    };

    return (
      <MUIDataTable
        title="Activities"
        data={listData}
        columns={columns}
        options={options}
      />
    );
  };

  const getRuleData = (targetItem) => {
    const ruleData = [];
    const excludedValues = [
      "ActivityTargetId",
      "CreatedBy",
      "CreatedDate",
      "Id",
      "IsActive",
      "ModifiedBy",
      "ModifiedDate",
      "OpportunityValue",
      "Sequence",
      "Site",
      "TargetValue",
    ];

    Object.keys(targetItem).forEach((rule) => {
      if (
        (targetItem[rule] !== "" &&
          targetItem[rule] !== undefined &&
          targetItem[rule] !== null &&
          !Array.isArray(targetItem[rule]) &&
          !excludedValues.includes(rule)) ||
        (Array.isArray(targetItem[rule]) && targetItem[rule].length > 0)
      ) {
        if (Array.isArray(targetItem[rule])) {
          ruleData.push(`${rule}: [${targetItem[rule]}]`);
        } else {
          ruleData.push(`${rule}: ${targetItem[rule]}`);
        }
      }
    });

    return ruleData.join(", ");
  };

  // Converts a CSV row into an object shape that backend can understand.
  function buildPPPObject(row) {
    return {
      Site: row.Site,
      RegionCode: row.RegionCode,
      Sequence: row.Sequence,
      IsActive: row.IsActive,
      ModifiedBy: loggedInUser.userId,
      CreateBy: loggedInUser.userId,
      City: row.City,
      PostalCode: row.PostalCode,
      PrecinctId: row.PrecinctId,
      MarketTypeId: row.MarketTypeId,
      TradeChannelId: row.TradeChannelId,
      SubTradeChannelId: row.SubTradeChannelId,
      BusinessOwnerId: row.BusinessOwnerId,
      TradeNameId: row.TradeNameId,
      CustomerTypeId: row.CustomerTypeId,
      BusinessTypeId: row.BusinessTypeId,
      OperationalRegionId: row.OperationalRegionId,
      LicenseTypeId: row.LicenseTypeId,
      GlobalCustomerNumberId: row.GlobalCustomerNumberId,
      ServicePolicyId: row.ServicePolicyId,
      SalesPolicyId: row.SalesPolicyId,
      PreferredOrderMethodId: row.PreferredOrderMethodId,
      SalesOfficeId: row.SalesOfficeId,
      PriceBookId: row.PriceBookId,
      DistributionChannelId: row.DistributionChannelId,
      BlackCodeId: row.BlackCodeId,
      RedCodeId: row.RedCodeId,
      L2CustomerNumber: row.L2CustomerNumber,
      L3CustomerNumber: row.L3CustomerNumber,
      L4CustomerNumber: row.L4CustomerNumber,
      L5CustomerNumber: row.L5CustomerNumber,
      ClocCodeId: row.ClocCodeId,
    };
  }
  // Submit handler specifically for triggering PPP mass upload and reload on completion.
  const handlePPPSubmit = async () => {
    setIsUploading(true);
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    // TODO: needs specific reqbody builder for ppp
    const data = createRequestBody();

    dispatch();
    // createMassContent(data, genericContentData.file, (res) => {
    //   if (res) {
    //     props.history.push("/post-migration");
    //   }
    // })
  };

  // Get the markup for the ppp targets content lists
  const getPPPTargetsList = (data, fileName) => {
    const listData =
      activityGroup.targets != null
        ? activityGroup.targets.map((item) => {
            return {
              id: item.Id,
              rules: getRuleData(item),
              active: item.IsActive === true ? "true" : "false",
            };
          })
        : [];

    let columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const url = `/activity-groups/${activityGroupId}/target/${value}`;
            return <a href={url}>{value}</a>;
          },
        },
      },
      {
        name: "rules",
        label: "Rules",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "active",
        label: "Active",
        options: {
          filter: false,
          sort: true,
        },
      },
    ];

    const options = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 20,
      rowsPerPageOptions: [5, 20, 50, 100, 200],
      viewColumns: false,
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: false /* Default dl behaviour disabled for PPP */,
      downloadOptions: {
        filename: { fileName },
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    options.customToolbar = () => {
      return (
        <Fragment>
          {/* PPP Target CSV Downloader */}
          <Tooltip title="Download CSV">
            <IconButton
              onClick={() => handleDownloadCSVRequest(activityGroupId)}
            >
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>

          {/* PPP Target Mass Uploader */}
          <Tooltip title="Upload & Replace CSV">
            <IconButton onClick={() => setShowPPPUploadModal(true)}>
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>

          {/* Add PPP Target */}
          <Tooltip title="Add PPP Target">
            <IconButton
              onClick={() =>
                props.history.push(
                  `/activity-groups/${activityGroupId}/target/new`
                )
              }
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      );
    };

    return (
      <MUIDataTable
        title="PPP Targets"
        data={listData}
        columns={columns}
        options={options}
      />
    );
  };

  //Display Activity group and associated list of activities
  const renderData = () => {
    return (
      <Fragment>
        <Box className={styles.contentGrid}>
          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.name.value}
                  name={formProperties.name.value}
                  label={formProperties.name.label}
                  placeholder="Enter group name"
                  value={
                    Object.keys(activityGroup).length > 0 &&
                    activityGroup.name !== undefined
                      ? activityGroup.name
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.name.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formProperties.name.errorMessage !== ""}
                  helperText={formProperties.name.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label={formProperties.startDate.label}
                    value={
                      Object.keys(activityGroup).length > 0 &&
                      activityGroup.startDate !== undefined
                        ? activityGroup.startDate
                        : new Date()
                    }
                    onChange={(date) => {
                      if (isNaN(date.getTime())) {
                        //date not valid, assume user is trying to enter the string manually.
                      } else {
                        handleInputChange(
                          formProperties.startDate.value,
                          formatISO(
                            new Date(
                              getYear(date),
                              getMonth(date),
                              getDate(date),
                              0,
                              0,
                              52
                            )
                          ).split("+")[0] + "Z"
                        );
                      }
                    }}
                    error={formProperties.startDate.errorMessage !== ""}
                    helperText={formProperties.startDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="dense"
                    disabled={!isEditing}
                    disableToolbar
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label={formProperties.endDate.label}
                    value={
                      Object.keys(activityGroup).length > 0 &&
                      activityGroup.endDate !== undefined
                        ? activityGroup.endDate
                        : new Date()
                    }
                    onChange={(date) => {
                      if (isNaN(date.getTime())) {
                        //date not valid, assume user is trying to enter the string manually.
                      } else {
                        handleInputChange(
                          formProperties.endDate.value,
                          formatISO(
                            new Date(
                              getYear(date),
                              getMonth(date),
                              getDate(date),
                              0,
                              0,
                              52
                            )
                          ).split("+")[0] + "Z"
                        );
                      }
                    }}
                    error={formProperties.endDate.errorMessage !== ""}
                    helperText={formProperties.endDate.errorMessage}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={!isEditing}
                  id={formProperties.sequence.value}
                  name={formProperties.sequence.value}
                  label={formProperties.sequence.label}
                  placeholder="Enter sequence"
                  value={
                    Object.keys(activityGroup).length > 0 &&
                    activityGroup.sequence !== undefined
                      ? activityGroup.sequence
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.sequence.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formProperties.sequence.errorMessage !== ""}
                  helperText={formProperties.sequence.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MultiSelectDropDown
                  id={props.id}
                  fullWidth={true}
                  multiple={true}
                  disabled={!isEditing}
                  disableCloseOnSelect={true}
                  showCheckbox={true}
                  label={formProperties.callScheduleTypes.label}
                  options={
                    callScheduleTypeLookupData !== undefined
                      ? callScheduleTypeLookupData
                      : []
                  }
                  selectedValues={
                    Object.keys(activityGroup).length > 0 &&
                    activityGroup.callScheduleTypes !== undefined &&
                    activityGroup.callScheduleTypes.length > 0
                      ? activityGroup.callScheduleTypes
                      : []
                  }
                  onChange={(event, value) => {
                    handleInputChange(
                      formProperties.callScheduleTypes.value,
                      value
                    );
                  }}
                  variant="outlined"
                  textFieldLabel="Select Call Schedule Types"
                  textFieldPlaceHolder={formProperties.callScheduleTypes.label}
                />
                {formProperties.callScheduleTypes.errorMessage !== "" ? (
                  <FormHelperText error={true}>
                    {formProperties.callScheduleTypes.errorMessage}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.active.label}
                  control={
                    <Checkbox
                      checked={
                        activityGroup !== undefined &&
                        activityGroup.active !== undefined
                          ? activityGroup.active
                          : true
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.active.value,
                          !activityGroup.active
                        )
                      }
                      name={formProperties.active.value}
                      color="primary"
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  id={formProperties.groupType.value}
                  name={formProperties.groupType.value}
                  disabled={!isEditing}
                  options={typeArray}
                  value={
                    Object.keys(activityGroup).length > 0 &&
                    activityGroup.groupType !== undefined
                      ? activityGroup.groupType
                      : ""
                  }
                  onChange={(e, value) => {
                    handleInputChange(formProperties.groupType.value, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      label={formProperties.groupType.label}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </Box>
        {/* Only display activities and ppp targets if the activity group is existing e.g has an id */}
        {!loading &&
          activityGroup.id !== "" &&
          activityGroup.id !== undefined && (
            <Fragment>
              <Box className={styles.contentGrid}>{getActivitiesList()}</Box>
              <Box className={styles.contentGrid}>{getPPPTargetsList()}</Box>
            </Fragment>
          )}
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box>
      <HeaderBlock
        title={activityGroup.name}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  disabled={
                    Array.isArray(currentActivityGroup.activities) &&
                    currentActivityGroup.activities[0] != undefined &&
                    currentActivityGroup.activities[0].Activity
                      .ActivityTypeId === 104
                  }
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedActivityGroup(activityGroup);
                    } else if (isEditing && isExistingActivityGroup) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing
                    ? isExistingActivityGroup
                      ? "Done"
                      : "Submit"
                    : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      {cancelEditingAlert()}
      <PageContainer>
        <Snackbar
          severity="error"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={csvError}
          onClose={() => setCSVError(false)}
          message={"Failed to upload PPP targets. Please try again."}
        />
        {Array.isArray(currentActivityGroup.activities) &&
          currentActivityGroup.activities[0] != undefined &&
          currentActivityGroup.activities[0].Activity.ActivityTypeId ===
            104 && (
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              You need to edit MyDisplay activities in the MyDisplays tab of SaM
              Core
            </Alert>
          )}
        {renderData()}
      </PageContainer>
      {showPPPUploadModal ? (
        <Modal
          title={""}
          open={true}
          fullWidth={true}
          fixedHeight={false}
          actions={
            <Box>
              <Button
                color="secondary"
                disabled={CSVInvalid || csvError}
                onClick={() => {
                  setCSVInvalid(true); // Hack to force off the submit button, prevents multiclicks. It re-enables after callback completion.
                  // Quick cheeky mod.
                  csvData.forEach((item) => {
                    item.ModifiedBy = loggedInUser.userId;
                    item.CreatedBy = loggedInUser.userId;
                    //if ActivityTargetID or OpportunityValue or TargetValue are null set them to 1
                    if (item.ActivityTargetId === null) {
                      item.ActivityTargetId = 1;
                    }
                    if (item.OpportunityValue === null) {
                      item.OpportunityValue = 1;
                    }
                    if (item.TargetValue === null) {
                      item.TargetValue = 1;
                    }
                    //if Id is null remove it
                    if (item.Id === null) {
                      delete item.Id;
                    }
                  });

                  dispatch(
                    replacePPPTargets(
                      currentActivityGroup.id,
                      csvData,
                      (success) => {
                        if (success) {
                          setShowPPPUploadModal(false);
                          setCSVInvalid(false);
                          props.history.go(0);
                        } else {
                          setCSVInvalid(false);
                          setCSVError(true);
                        }
                      }
                    )
                  );
                }}
              >
                Submit
              </Button>
              <Button
                color="secondary"
                onClick={() => setShowPPPUploadModal(false)}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <MigrationUploadSegment
            csvData={csvData}
            setCsvData={setCsvData}
            setDataChanged={setDataChanged}
            title="CSV Upload & Replace"
            targetsEdited={targetsEdited}
            setTargetsEdited={setTargetsEdited}
            isEditing={isEditing}
            setFormEdited={setFormEdited}
            description={[
              "Please upload a CSV of PPP Targets.",
              <b> All existing PPP Targets will be set to inactive.</b>,
            ]}
            templateFileName="PPP_Target_Template.csv"
            expectedColumns={PPPTargetExpectedColumns}
            setCSVInvalid={setCSVInvalid}
          />
        </Modal>
      ) : null}
    </Box>
  );
};

const hoc = withRouter(ViewActivityGroupPage);

// EXPORT COMPONENT
export { hoc as ViewActivityGroupPage };
