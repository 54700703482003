export const RoleInputModel = () => {
  return {
    name: {
      type: "text",
      required: true,
      id: "outlined-roleName",
      name: "name",
      label: "Role Name",
      placeholder: "Enter role name",
      errorText: ""
    },
    description: {
      type: "text",
      required: false,
      id: "outlined-role-description",
      name: "description",
      label: "Description",
      placeholder: "Enter description",
      errorText: ""
    }
  };
};
