import React from 'react';
import {FunctionComponent} from 'react';
import { SKU, PlannedDisplay } from '../../state/reducers/MyDisplaysPlanningReducer';
import styles from './styles/myDisplaysPlanningTable.module.scss';

interface ExpandabletableRowProps {
  rowData: PlannedDisplay | undefined
  plan?: PlannedDisplay | undefined
  getMaterialNameFromMaterialNumber: (materialNumber: string) => string
}
 
const ExpandabletableRow: FunctionComponent<ExpandabletableRowProps> = (props: ExpandabletableRowProps) => {
  const {
    rowData,
    plan,
    getMaterialNameFromMaterialNumber
  } = props;

  if(!rowData) return null;

  function renderTable(SKUs: SKU[]){
    return (
      <table className={styles.myDisplaysPlanningTableExpandedRowTable}>
        <thead>
          <tr>
            <th>SKU</th>
            <th>Name</th>
            <th className="text-right">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {SKUs.map((sku : SKU) => {
            const materialName = getMaterialNameFromMaterialNumber(sku.MaterialNumber);
            return (
              <tr key={sku.MaterialNumber}>
                <td>{sku.MaterialNumber}</td>
                <td>{materialName}</td>
                <td className="text-right">{sku.Quantity}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  function renderBody(){
    if(Array.isArray(rowData?.SKUs) && rowData?.SKUs.length){
      return renderTable(rowData.SKUs)
    }
    if(plan && plan.SKUs.length) {
      return renderTable(plan.SKUs)
    }
    return null
  }

  return (
    <tr className={styles.myDisplaysPlanningTableExpandedRow}>
      <td></td>
      <td></td>
      <td colSpan={17} style={{paddingLeft:'30px'}}>
        { renderBody() }
      </td>
    </tr>
  );
}
 
export default ExpandabletableRow;