// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import styles from "./ViewActivityGroupPage.module.scss";

import EditingAlert from "../../components/editing-alert/EditingAlert";

// Actions
import {
  createTarget,
  getTargetById,
  getDropdownLookups,
  updateTarget,
  getActivityById,
} from "../../state/actions/ActivityActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import { CustomCardContent } from "../../components/card-content/CardContent";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import PageContainer from "../../components/page-container/PageContainer";
import ShrinkingButtonGroup from "../../components/shrinking-button-group/ShrinkingButtonGroup";
import { SimpleCardHeader } from "../../components/card/card-header/CardHeader";

// Models
import { ActivityTargetModel } from "../../models/ActivityTargetModel";

// Other
import { getActivityRulesDropdownData } from "../../state/constants/ActivityRules";
import { FormType } from "../../state/constants/FormType";
import { validateField } from "../../helpers/validation";
import { Territories } from "../../state/constants/Territories";
import { UUID } from "../../helpers/uuid";

const ViewTargetPage = (props) => {
  const currentTarget = useSelector(
    (state) => state.ActivityReducer.currentTarget
  );

  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(ActivityTargetModel);
  const [target, setTarget] = useState({});
  const [uneditedTarget, setUneditedTarget] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingTarget, setIsExistingTarget] = useState(true);
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [toolbarShowing, setToolbarShowing] = useState(false);
  const [dropdownData, setDropdownData] = useState({});
  const [showMinimumRuleWarning, setShowMinimumRuleWarning] = useState(true);

  const activityId = props.match.params.activityId;
  const activityGroupId = props.match.params.activityGroupId;
  const targetId = props.match.params.targetId;
  const activityName = new URLSearchParams(props.location.search).get(
    "activity"
  );
  const [existingRuleError, setExistingRuleError] = useState("");

  const setUpData = () => {
    setIsExistingTarget(props.type != null && props.type === FormType.VIEW);

    //Get all dropdown lookup data
    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => {})
    );
    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getTargetById(activityId, targetId, () => {
          setLoading(false);
        })
      );
    } else {
      dispatch(
        getTargetById(undefined, undefined, () => {
          setIsEditing(true);
          setLoading(false);
        })
      );
    }
  };

  //Use Effects
  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setUpData();
        })
      );
    } else {
      setUpData();
    }
  }, []);

  useEffect(() => {
    setDropdownData(getActivityRulesDropdownData(lookupData));
  }, [lookupData]);

  useEffect(() => {
    let hasAtLeastOneRule =
      target.rules === undefined || target.rules.length === 0 ? false : true;

    if (hasAtLeastOneRule) {
      // Check that there is not only a rule field, but also that the value of the field is valid.
      hasAtLeastOneRule =
        target.rules[0].value === undefined ||
        target.rules[0].value === "" ||
        target.rules[0].value === null
          ? false
          : true;
    }

    let hasAtLeastOneCustomerNumber =
      target.customerNumbers === undefined ||
      target.customerNumbers.length === 0
        ? false
        : true;

    // If there is at least one rule or one customer number, permit the rule.
    if (hasAtLeastOneCustomerNumber || hasAtLeastOneRule) {
      setShowMinimumRuleWarning(false);
      setSubmitEnabled(true);
    } else {
      setShowMinimumRuleWarning(true);
      setSubmitEnabled(false);
    }
  }, [target]);

  useEffect(() => {
    const localTarget = currentTarget;

    if (!isExistingTarget && !formEdited) {
      localTarget.site = loggedInUser.territory;
    }

    setTarget(localTarget);
  }, [currentTarget]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, target);

    localData[name] = value;

    setTarget(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      target[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      errorMessage = checkGenericField(key, formProperties[key].required);

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    let requestBody = {
      OpportunityValue: target.opportunityValue,
      TargetValue: target.targetValue,
      Site: target.site === "au" ? 1 : 2,
      IsActive: target.active,
      Sequence: 1,
      CustomerNumbers:
        target.customerNumbers !== undefined
          ? target.customerNumbers
              .split("|")
              .join(",")
              .split(",")
              .map(Function.prototype.call, String.prototype.trim)
          : [],
      RegionCode: "",
      City: "",
      PostalCode: "",
      PrecinctId: "",
      MarketTypeId: "",
      TradeChannelId: "",
      SubTradeChannelId: "",
      BusinessOwnerId: "",
      TradeNameId: "",
      CustomerTypeId: "",
      BusinessTypeId: "",
      OperationalRegionId: "",
      LicenseTypeId: "",
      GlobalCustomerNumberId: "",
      ServicePolicyId: "",
      SalesPolicyId: "",
      PreferredOrderMethodId: "",
      SalesOfficeId: "",
      PriceBookId: "",
      DistributionChannelId: "",
      BlackCodeId: null,
      RedCodeId: null,
      L2CustomerNumber: null,
      L3CustomerNumber: null,
      L4CustomerNumber: null,
      L5CustomerNumber: null,
      ClocCodeId: "",
    };

    const validRules = target.rules.filter((r) => r.field !== undefined);

    const localTarget = target;

    target.rules = validRules;

    setTarget(localTarget);

    // Update any rules that have had their values set in the UI
    validRules.forEach((rule) => {
      const ruleData = Object.values(dropdownData).find(
        (data) => data.label === rule.field
      );

      const value =
        ruleData.lookUpData !== null ? rule.value["Id"] : rule.value;

      requestBody[ruleData.requestFieldName] = value;
    });

    if (isCreate) {
      requestBody.CreatedBy = loggedInUser.userId;
      requestBody.ModifiedBy = loggedInUser.userId;
    } else {
      requestBody.ModifiedBy = loggedInUser.userId;
    }

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      dispatch(
        createTarget(activityId, createRequestBody(true), (success, id) => {
          setIsEditing(false);
          props.history.push(
            `/activity-groups/${activityGroupId}/${activityId}`
          );
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateTarget(activityId, targetId, createRequestBody(false), () => {
          setIsEditing(false);
          setExistingRuleError("");

          props.history.push(
            `/activity-groups/${activityGroupId}/${activityId}/target/${targetId}?activity=${activityName}`
          );
        })
      );
    }
  };

  // Hide or show the toolbar on rules
  const toggleToolbar = () => {
    setToolbarShowing(!toolbarShowing);
  };

  /*
    Add a new rule line on button click
  */
  const handleAddRule = () => {
    let localTarget = Object.assign({}, target);
    localTarget.rules.push({
      field: undefined,
      value: undefined,
    });

    setTarget(localTarget);
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingTarget) {
        props.history.push(`/activity-groups/${activityGroupId}/${activityId}`);
      }
    }
  };

  /*
  Display the cancel editing alert dialog
  */
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false),
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setTarget(uneditedTarget);
              setCancelEditingAlertOpen(false);

              if (!isExistingTarget) {
                props.history.push(
                  `/activity-groups/${activityGroupId}/${activityId}`
                );
              }
            },
          },
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  const actionButton = [
    <Button
      key={UUID()}
      onClick={() => handleAddRule()}
      variant="outlined"
      color="secondary"
    >
      Add Rule
    </Button>,
  ];

  const actionButtons = (
    actionButton
      ? actionButton.map((action) => {
          return {
            label: action.props.children,
            action: () => action.props.onClick(),
          };
        })
      : []
  ).concat({
    label: toolbarShowing ? "Done" : "Edit",
    action: () => toggleToolbar(),
  });

  const getRuleValueInput = (index) => {
    const field = target.rules.length > 0 ? target.rules[index].field : "";

    if (field === undefined) {
      return (
        <Grid item xs={12} md={6}>
          <Typography align="center" variant="body1" id={`value_${index}`}>
            Please select a field
          </Typography>
        </Grid>
      );
    }

    const ruleData =
      dropdownData[
        Object.keys(dropdownData).find(
          (key) => dropdownData[key].label === field
        )
      ];

    if (ruleData !== undefined && ruleData.lookUpData !== null) {
      return (
        <Grid item xs={12} md={6}>
          <MultiSelectDropDown
            id={`value_${index}`}
            fullWidth={true}
            multiple={false}
            disableCloseOnSelect={false}
            showCheckbox={false}
            disabled={!isEditing}
            label="Value"
            options={ruleData.lookUpData}
            selectedValues={target.rules[index].value}
            onChange={(event, value) => {
              let localData = Object.assign({}, target);

              localData.rules[index].value = value;

              setTarget(localData);
            }}
            variant="outlined"
            textFieldLabel="Select Rule Value"
            textFieldPlaceHolder="Rule value"
          />
        </Grid>
      );
    }

    return (
      <Grid item xs={12} md={6}>
        <TextField
          disabled={!isEditing}
          id={`value_${index}`}
          label={`${field} value`}
          placeholder="Enter rule value"
          value={target.rules[index].value || ""}
          onChange={(event) => {
            let localData = Object.assign({}, target);

            localData.rules[index].value = event.target.value;

            setTarget(localData);
          }}
          variant="outlined"
          margin="dense"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    );
  };

  const renderForm = () => {
    return (
      <Fragment>
        <EditingAlert
          isEditing={showMinimumRuleWarning}
          title={"Warning"}
          severity={"warning"}
          body={[
            "This target is incorrectly configured:",
            <b>
              {" "}
              Missing at least one valid targeting rule or Customer Number.
            </b>,
          ]}
        />
        <div className={styles.contentGrid}>
          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DropdownSelect
                  disabled={!isEditing}
                  label="Site"
                  handleChange={(value) => {
                    handleInputChange("site", value);
                  }}
                  data={
                    Territories != null
                      ? Object.keys(Territories).map((key) => {
                          return { key: key, value: Territories[key] };
                        })
                      : []
                  }
                  value={target.site}
                  valueName={"site"}
                  error={
                    formProperties.site &&
                    formProperties.site.errorMessage !== ""
                  }
                  fullWidth={true}
                >
                  {formProperties.site &&
                  formProperties.site.errorMessage !== "" ? (
                    <FormHelperText>
                      {formProperties.site.errorMessage}
                    </FormHelperText>
                  ) : null}
                </DropdownSelect>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.active.label}
                  control={
                    <Checkbox
                      checked={
                        target !== undefined && target.active !== undefined
                          ? target.active
                          : false
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.active.value,
                          !target.active
                        )
                      }
                      name={formProperties.active.value}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
        <div className={styles.contentGrid}>
          <Paper>
            <CustomCardContent>
              <SimpleCardHeader>
                <div className={styles.cardHeader}>
                  <div className={styles.cardHeaderLeft}>
                    <div className={styles.cardTitle}>Rules</div>
                    {!loading && (
                      <Chip
                        label={
                          target.rules !== undefined ? target.rules.length : 0
                        }
                        variant="outlined"
                      />
                    )}
                  </div>
                  {!isEditing ? null : (
                    <div>
                      <div className={styles.largeScreen}>
                        <ButtonGroup>
                          {!loading && actionButton}
                          {target.rules !== undefined &&
                            target.rules.length > 0 && (
                              <Button
                                onClick={() => toggleToolbar()}
                                variant="outlined"
                              >
                                {toolbarShowing ? "Done" : "Edit"}
                              </Button>
                            )}
                        </ButtonGroup>
                      </div>
                      <div className={styles.smallScreen}>
                        <ShrinkingButtonGroup
                          secondary={actionButtons}
                          default={{
                            label: "...",
                            action: null,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </SimpleCardHeader>

              {target &&
                target.rules !== undefined &&
                target.rules.map((rule, index) => {
                  return (
                    <div key={index} id={index}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <DropdownSelect
                            disabled={!isEditing}
                            label={"Rule Type"}
                            handleChange={(value) => {
                              let localData = Object.assign({}, target);

                              const usedRule = target.rules.find(
                                (r) => r.field === value
                              );

                              if (usedRule !== undefined) {
                                setExistingRuleError(
                                  `A rule with type "${value}" already exists. Only one rule of each type can be used. Please choose a different rule type.`
                                );
                              } else {
                                localData.rules[index].field = value;
                                localData.rules[index].value = "";
                                setExistingRuleError("");
                              }

                              setTarget(localData);
                            }}
                            data={Object.values(dropdownData).map(
                              (item) => item.label
                            )}
                            value={target.rules[index].field}
                            valueName={target.rules[index].field}
                            error={formProperties.rules.errorMessage !== ""}
                            fullWidth={true}
                          />
                        </Grid>

                        {getRuleValueInput(index)}
                      </Grid>
                    </div>
                  );
                })}
              {existingRuleError !== "" ? (
                <FormHelperText error>{existingRuleError}</FormHelperText>
              ) : null}
            </CustomCardContent>
          </Paper>
        </div>
        <div className={styles.contentGrid}>
          <CustomCard title="Extra Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  multiline={true}
                  rows="4"
                  id="customer_numbers"
                  label="Customer Numbers (comma separated)"
                  placeholder="Enter customer numbers"
                  value={target.customerNumbers || ""}
                  onChange={(event) => {
                    handleInputChange("customerNumbers", event.target.value);
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="opportunity_value"
                  label="Opportunity Value"
                  placeholder="Enter opportunity value"
                  value={target.opportunityValue || ""}
                  type="number"
                  onChange={(event) => {
                    handleInputChange("opportunityValue", event.target.value);
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formProperties.opportunityValue.errorMessage !== ""}
                  helperText={formProperties.opportunityValue.errorMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!isEditing}
                  id="target_value"
                  label="Target Value"
                  placeholder="Enter target value"
                  value={target.targetValue || ""}
                  type="number"
                  onChange={(event) => {
                    handleInputChange("targetValue", event.target.value);
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={formProperties.targetValue.errorMessage !== ""}
                  helperText={formProperties.targetValue.errorMessage}
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={`${activityName} - ${
          isExistingTarget ? targetId : "New Target"
        }`}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isEditing && showMinimumRuleWarning}
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedTarget(target);
                    } else if (isEditing && isExistingTarget) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing ? (isExistingTarget ? "Done" : "Submit") : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelEditingAlert()}

      <PageContainer>{renderForm()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewTargetPage);

// EXPORT COMPONENT
export { hoc as ViewTargetPage };
