import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_GROUP,
  CREATE_CHOICE,
  CREATE_REWARD,
  CREATE_TARGET,
  GET_ALL_ACTIVITY_GROUPS,
  GET_ACTIVITY_BY_ID,
  GET_ACTIVITY_GROUP_BY_ID,
  GET_CHOICE_BY_ID,
  GET_REWARD_BY_ID,
  GET_TARGET_BY_ID,
  GET_DROPDOWN_LOOKUPS,
  PPP_MASS_UPLOAD_SUCCESS,
  PPP_MASS_UPLOAD_FAILURE,
  GET_PPP_AS_CSV,
  GET_ACTIVITYTARGETS_AS_CSV,
  REPLACE_PPP_TARGETS,
  REPLACE_ACTIVITY_TARGETS,
  RESET_DISPLAY_TARGET
} from "../constants/action-types";

const FileDownload = require('js-file-download');
const API_CONFIG = getApiConfig();

var moment = require("moment");
moment().format();

/* 
    Get all Activity Groups
*/
export const getAllActivityGroups = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
          `${API_CONFIG.ACTIVITIES}/GetAllActivityGroups`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_ALL_ACTIVITY_GROUPS,
        payload: {
          data: response.data
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual activity group by id
*/
export const getActivityGroupById = (activityGroupId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityGroupId !== undefined
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetActivityGroupById?Id=${activityGroupId}`,
            {
              method: "get"
            }
          )
          : {};

      dispatch({
        type: GET_ACTIVITY_GROUP_BY_ID,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Create a new activity group
*/
export const createActivityGroup = (activityGroup, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertActivityGroup`,
        {
          method: "post",
          headers: headers,
          data: activityGroup
        }
      );

      dispatch({
        type: CREATE_ACTIVITY_GROUP,
        payload: {
          data: response
        }
      });

      callback(true, response.data.ActivityGroup.Id);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing activity group
*/
export const updateActivityGroup = (
  activityGroupId,
  activityGroup,
  callback
) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertActivityGroup?Id=${activityGroupId}`,
        {
          method: "post",
          headers: headers,
          data: activityGroup
        }
      );
      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual activity by id
*/
export const getActivityById = (activityId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityId !== undefined
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetActivityById?Id=${activityId}`,
            {
              method: "get"
            }
          )
          : {};

      dispatch({
        type: GET_ACTIVITY_BY_ID,
        payload: {
          data: response
        }
      });

      callback(response.data);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Create a new activity
*/
export const createActivity = (activity, callback) => {

  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertActivity`,
        {
          method: "post",
          headers: headers,
          data: activity
        }
      );

      dispatch({
        type: CREATE_ACTIVITY,
        payload: {
          data: response
        }
      });

      callback(true, response.data.Activity.Id);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing activity
*/
export const updateActivity = (activityId, activity, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    // massage date data before submitting to database, we convert it into local start of day. This ideally prevents UTC issues.
    var s = moment(activity.Activity.StartDate.toString());
    var e = moment(activity.Activity.EndDate.toString());

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertActivity?Id=${activityId}`,
        {
          method: "post",
          headers: headers,
          data: activity
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing activity
*/
export const updateBulkActivity = (activity, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertBulkActivityGroup`,
        {
          method: "post",
          headers: headers,
          data: activity
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual choice by id
*/
export const getChoiceById = (activityId, choiceName, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityId !== undefined && choiceName !== "new"
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetChoiceById?Id=${activityId}&Name=${choiceName}`,
            {
              method: "get"
            }
          )

          : {};
      dispatch({
        type: GET_CHOICE_BY_ID,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual choice by id
*/
export const getPPPTargetsAsCSV = (activityGroupId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityGroupId !== undefined
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetPPPTargetsAsCSV?Id=${activityGroupId}`,
            {
              method: "get"
            }
          )

          : {};
      dispatch({
        type: GET_PPP_AS_CSV,
        payload: {
          data: FileDownload(response.data, `${activityGroupId}_PPPTargets.csv`)
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const getActivityTargetsAsCSV = (activityId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityId !== undefined
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetActivityTargetsAsCSV?Id=${activityId}`,
            {
              method: "get"
            }
          )

          : {};
      dispatch({
        type: GET_PPP_AS_CSV,
        payload: {
          data: FileDownload(response.data, `${activityId}_PPPTargets.csv`)
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Create a new choice
*/
export const createChoice = (choice, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertChoice`,
        {
          method: "post",
          headers: headers,
          data: choice
        }
      );

      dispatch({
        type: CREATE_CHOICE,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing choice
*/
export const updateChoice = (activityId, name, choice, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertChoice?Id=${activityId}&Name=${name}`,
        {
          method: "post",
          headers: headers,
          data: choice
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual reward by id
*/
export const getRewardById = (activityId, upperRange, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityId !== undefined && upperRange !== undefined
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetRewardById?Id=${activityId}&UpperRange=${upperRange}`,
            {
              method: "get"
            }
          )
          : {};

      dispatch({
        type: GET_REWARD_BY_ID,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Create a new reward
*/
export const createReward = (activityId, reward, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertReward?Id=${activityId}`,
        {
          method: "post",
          headers: headers,
          data: reward
        }
      );

      dispatch({
        type: CREATE_REWARD,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing reward
*/
export const updateReward = (activityId, upperRange, reward, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertReward?Id=${activityId}&UpperRange=${upperRange}`,
        {
          method: "post",
          headers: headers,
          data: reward
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual target by id
*/
export const getTargetById = (activityId, targetId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityId !== undefined && targetId !== "new"
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetTargetById?Id=${activityId}&TargetId=${targetId}`,
            {
              method: "get"
            }
          )
          : {};

      dispatch({
        type: GET_TARGET_BY_ID,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Create a new target
*/
export const createTarget = (activityId, target, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertTarget?ActivityId=${activityId}`,
        {
          method: "post",
          headers: headers,
          data: target
        }
      );

      dispatch({
        type: CREATE_TARGET,
        payload: {
          data: response
        }
      });

      callback(true, response.data.Id);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing target
*/
export const updateTarget = (activityId, id, target, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertTarget?ActivityId=${activityId}&TargetId=${id}`,
        {
          method: "post",
          headers: headers,
          data: target
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/* 
    Get an individual target by id
*/
export const getPPPTargetById = (activityId, targetId, callback) => {
  return async (dispatch) => {
    try {
      const response =
        activityId !== undefined && targetId !== "new"
          ? await adalApiFetch(
            Axios, `${API_CONFIG.ACTIVITIES}/GetPPPTargetById?Id=${activityId}&TargetId=${targetId}`,
            {
              method: "get"
            }
          )
          : {};

      dispatch({
        type: GET_TARGET_BY_ID,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Create a new PPP target
*/
export const createPPPTarget = (id, target, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertPPPTarget?ActivityGroupId=${id}`,
        {
          method: "post",
          headers: headers,
          data: target
        }
      );

      dispatch({
        type: CREATE_TARGET,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Replace a set of PPP targets on a single activity group.
*/
export const replacePPPTargets = (id, targets, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UploadPPPTargetsFromCSV?id=${id}`,
        {
          method: "post",
          headers: headers,
          data: targets
        }
      );

      dispatch({
        type: REPLACE_PPP_TARGETS,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Replace a set of PPP targets on a single activity group.
    ID in this case should refer to the activity id not the activity group Id.
*/
export const replaceActivityTargets = (id, targets, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UploadActivityTargetsFromCSV?id=${id}`,
        {
          method: "post",
          headers: headers,
          data: targets
        }
      );

      dispatch({
        type: REPLACE_PPP_TARGETS,
        payload: {
          data: response
        }
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Update an existing PPP target
*/
export const updatePPPTarget = (activityId, id, target, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/UpsertPPPTarget?ActivityGroupId=${activityId}&TargetId=${id}`,
        {
          method: "post",
          headers: headers,
          data: target
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Get all lookup data related to activities
*/
export const getDropdownLookups = (site, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/GetDropdownLookups?Site=${site}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_DROPDOWN_LOOKUPS,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const PPPMassUpload = (data, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const PPPMassUploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/MassUploadPPP`,
        {
          method: "post",
          header: headers,
          data: data
        }
      );
      if (PPPMassUploadResponse.status === 200) {
        dispatch({
          type: PPP_MASS_UPLOAD_SUCCESS,
          payload: data
        });
        callback(true);
      } else {
        dispatch({
          type: PPP_MASS_UPLOAD_FAILURE,
          payload: data
        });
        callback(true);
      }
    } catch {
      dispatch({
        type: PPP_MASS_UPLOAD_FAILURE,
        payload: data
      });
      callback(true);
    }
  };
};

export const resetDisplaytarget = (callback) => (dispatch) => {
  dispatch({
    type: RESET_DISPLAY_TARGET,
    payload: null
  });

  callback(true);
};
