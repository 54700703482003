// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

// Actions
import { getAllLookupContent } from "../../state/actions/TargetedContentActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import FileDrop from "../../components/file-drop/FileDrop";
import { Loading } from "../../components/loading/Loading";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { Territories } from "../../state/constants/Territories";

const GenericContentUploadWithTerritorySegment = ({
  genericContentData,
  setGenericContentData,
  setDataChanged,
  title,
  isNewContent,
  isEditing,
  formEdited,
  setFormEdited,
  isContentAdmin,
  userTerritory,
  fileTypes,
  dropdownScope
}) => {
  const lookupData = useSelector(
    (state) => state.TargetedContentReducer.lookupData
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [categoryLookupData, setCategoryLookupData] = useState([]);
  const [languageLookupData, setLanguageLookupData] = useState([]);
  const [retentionPolicyLookupData, setRetentionPolicyLookupData] = useState(
    []
  );
  const [typeLookupData, setTypeLookupData] = useState([]);

  useEffect(() => {
    setLoading(true);
    //Get all dropdown lookup data
    dispatch(getAllLookupContent(dropdownScope, () => { }));
  }, []);

  useEffect(() => {
    setCategoryLookupData(lookupData.CategoryLookups);
    setLanguageLookupData(lookupData.LanguageLookups);
    setRetentionPolicyLookupData(lookupData.RetentionPolicyLookups);
    setTypeLookupData(lookupData.TypeLookups);
    //Set default language if it is not set. Use languageLookupData to do this.
    if (
      genericContentData.language.value === "" &&
      languageLookupData !== undefined
    ) {
      const lang = languageLookupData.find((data) => data.DisplayName === "en");
      if (lang !== undefined) {
        handleInputChange("language", [lang.Id]);
      }
    }

    genericContentData.territory.value = userTerritory;

    if (loading) {
      setLoading(false);
    }
  }, [lookupData]);

  const handleInputChange = (name, value) => {
    const localData = genericContentData;
    localData[name].value = value;

    if (!formEdited) {
      setFormEdited(true);
    }

    setGenericContentData(localData);
    setDataChanged(true);
  };

  return (
    <CustomCard title={title} actionButton={[]}>
      {loading ? (
        <Loading />
      ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                disabled={!isEditing}
                labelPlacement="start"
                label="Internal"
                control={
                  <Checkbox
                    checked={
                      genericContentData.internal
                        ? genericContentData.internal.value
                        : true
                    }
                    onChange={() =>
                      handleInputChange(
                        "internal",
                        !genericContentData.internal.value
                      )
                    }
                    name="internal"
                    color="primary"
                  />
                }
              />
              <FormControlLabel
                disabled={!isEditing}
                labelPlacement="start"
                label="Active"
                control={
                  <Checkbox
                    checked={
                      genericContentData.active
                        ? genericContentData.active.value
                        : true
                    }
                    onChange={() =>
                      handleInputChange(
                        "active",
                        !genericContentData.active.value
                      )
                    }
                    name="active"
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownSelect
                disabled={!isNewContent}
                label="Content Type"
                handleChange={(value) => {
                  handleInputChange("contentType", value);
                  const type = typeLookupData.filter((t) => t.Id === value);
                  handleInputChange("contentTypeLabel", type[0].DisplayName);

                  // Need to setup category data based on selected content type
                  const categoryId = categoryLookupData.filter(
                    (c) => c.TypeId === value
                  );
                  handleInputChange("category", categoryId[0].Id);

                  // Need to set up default retention policy based on content type selected
                  handleInputChange(
                    "retentionPolicy",
                    type[0].DefaultRetentionPolicyId
                  );
                }}
                data={
                  typeLookupData !== undefined && typeLookupData.length > 0
                    ? typeLookupData.map((t) => {
                      return { key: t.Id, value: t.DisplayName };
                    })
                    : []
                }
                value={
                  genericContentData.contentType &&
                    typeLookupData.length > 0 &&
                    Object.keys(genericContentData.contentType.value).length > 0
                    ? genericContentData.contentType.value
                    : ""
                }
                valueName={"contentType"}
                error={
                  genericContentData.contentType &&
                  genericContentData.contentType.errorMessage !== ""
                }
                fullWidth={true}
              >
                {genericContentData.contentType &&
                  genericContentData.contentType.errorMessage !== "" ? (
                    <FormHelperText>
                      {genericContentData.contentType.errorMessage}
                    </FormHelperText>
                  ) : null}
              </DropdownSelect>
            </Grid>
            <Grid item xs={6}>
              <DropdownSelect
                disabled={!isEditing}
                label="Retention Policy"
                handleChange={(value) => {
                  handleInputChange("retentionPolicy", value);
                }}
                data={
                  retentionPolicyLookupData !== undefined &&
                    retentionPolicyLookupData.length > 0
                    ? retentionPolicyLookupData.map((t) => {
                      return { key: t.Id, value: t.DisplayName };
                    })
                    : []
                }
                value={
                  genericContentData.retentionPolicy &&
                    retentionPolicyLookupData.length > 0 &&
                    Object.keys(genericContentData.retentionPolicy.value).length > 0
                    ? genericContentData.retentionPolicy.value
                    : ""
                }
                valueName={"retentionPolicy"}
                error={
                  genericContentData.retentionPolicy &&
                  genericContentData.retentionPolicy.errorMessage !== ""
                }
                fullWidth={true}
              >
                {genericContentData.retentionPolicy &&
                  genericContentData.retentionPolicy.errorMessage !== "" ? (
                    <FormHelperText>
                      {genericContentData.retentionPolicy.errorMessage}
                    </FormHelperText>
                  ) : null}
              </DropdownSelect>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: isNewContent ? "block" : "none"
              }}
            >
              <FileDrop
                enforceTypes={fileTypes}
                enforceTypesMessage={
                  "Uploaded file must be either a png or jpeg."
                }
                message="Add files"
                onFilesAdded={(file) => handleInputChange("file", file)}
                file={genericContentData.file && genericContentData.file.value}
                multiple={false}
                errorMessage={
                  genericContentData.file && genericContentData.file.errorMessage
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="title"
                name="title"
                label="Title"
                placeholder="Enter title"
                value={
                  genericContentData.title ? genericContentData.title.value : ""
                }
                onChange={(event) =>
                  handleInputChange("title", event.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                error={
                  genericContentData.title &&
                  genericContentData.title.errorMessage !== ""
                }
                helperText={
                  genericContentData.title &&
                  genericContentData.title.errorMessage
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="description"
                name="description"
                label="Description"
                placeholder="Enter description"
                value={
                  genericContentData.description
                    ? genericContentData.description.value
                    : ""
                }
                onChange={(event) =>
                  handleInputChange("description", event.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                error={
                  genericContentData.description &&
                  genericContentData.description.errorMessage !== ""
                }
                helperText={
                  genericContentData.description &&
                  genericContentData.description.errorMessage
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!isEditing}
                id="keywords"
                name="keywords"
                label="Keywords"
                placeholder="Enter keywords"
                value={
                  genericContentData.keywords
                    ? genericContentData.keywords.value
                    : ""
                }
                onChange={(event) =>
                  handleInputChange("keywords", event.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                error={
                  genericContentData.keywords &&
                  genericContentData.keywords.errorMessage !== ""
                }
                helperText={
                  genericContentData.keywords &&
                  genericContentData.keywords.errorMessage
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownSelect
                disabled={!isEditing}
                label="Content Language"
                multiple
                handleChange={(value) => {
                  handleInputChange("language", value);
                }}
                data={
                  languageLookupData
                    ? languageLookupData.map((t) => {
                      return { key: t.Id, value: t.DisplayName };
                    })
                    : []
                }
                value={
                  genericContentData.language && languageLookupData.length > 0
                    ? genericContentData.language.value
                    : []
                }
                valueName={"language"}
                error={
                  genericContentData.language &&
                  genericContentData.language.errorMessage !== ""
                }
                fullWidth={true}
              >
                {genericContentData.language &&
                  genericContentData.language.errorMessage !== "" ? (
                    <FormHelperText>
                      {genericContentData.language.errorMessage}
                    </FormHelperText>
                  ) : null}
              </DropdownSelect>
            </Grid>
            {<Grid item xs={12}>
              <DropdownSelect
                disabled={false}
                label="Site"
                handleChange={(value) => {
                  handleInputChange("territory", value);
                }}
                data={
                  isContentAdmin
                    ? Territories != null
                      ? Object.keys(Territories).map((key) => {
                        return { key: key, value: Territories[key] };
                      })
                      : []
                    : [{ key: userTerritory, value: Territories[userTerritory] }]
                }
                value={
                  genericContentData.territory &&
                  genericContentData.territory.value
                }
                valueName={"territory"}
                error={
                  genericContentData.territory &&
                  genericContentData.territory.errorMessage !== ""
                }
                fullWidth={true}
              >
                {genericContentData.territory &&
                  genericContentData.territory.errorMessage !== "" ? (
                    <FormHelperText>
                      {genericContentData.territory.errorMessage}
                    </FormHelperText>
                  ) : null}
              </DropdownSelect>
            </Grid>}
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="dense"
                  disabled={!isEditing}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Effective From"
                  value={
                    genericContentData.startDate &&
                    new Date(genericContentData.startDate.value)
                  }
                  onChange={(date) => {
                    handleInputChange("startDate", date);
                  }}
                  error={
                    genericContentData.startDate &&
                    genericContentData.startDate.errorMessage !== ""
                  }
                  helperText={
                    genericContentData.startDate &&
                    genericContentData.startDate.errorMessage
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="dense"
                  disabled={!isEditing}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  label="Effective To"
                  value={
                    genericContentData.endDate &&
                    new Date(genericContentData.endDate.value)
                  }
                  onChange={(date) => {
                    handleInputChange("endDate", date);
                  }}
                  error={
                    genericContentData.endDate &&
                    genericContentData.endDate.errorMessage !== ""
                  }
                  helperText={
                    genericContentData.endDate &&
                    genericContentData.endDate.errorMessage
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        )}
    </CustomCard>
  );
};

export default GenericContentUploadWithTerritorySegment;
