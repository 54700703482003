import React, { useEffect, useReducer } from "react";
import { Autocomplete } from "@material-ui/lab";
import styles from "./material-groups-list.module.scss";
import { MaterialGroup, MaterialOption, MgList, MgOption, MgPage, ProgrammeAction } from "../../reducers/programme-reducer";
import { GenericDictionary } from "types/GenericDictionary";
import Axios from "axios";
import { adalApiFetch } from "../../../../../../config/azureConfig";
import { getApiConfig } from "../../../../../../config/apiConfig";
import { TextField, Button, Grid, Box, IconButton, Tooltip } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { GetProductList } from '../../../../../../state/actions/CoreRangeActions';
import { useSelector, useDispatch } from "react-redux";
import { FixMeLater } from "../../../../../../types/FixMeLaterType";
import { getSiteForLoggedInUser } from "../../../../../../helpers/userHelpers";
import { RemoveCircleOutline } from "@material-ui/icons";

const API_CONFIG = getApiConfig();



const mgPageTemplate: MgPage = {
  materials: {},
  minVariants: 0,
  title: ''
}

const mgGroupsTemplate: MgList = {
  "pg1" : {
    materials: {
    },
    minVariants: -1,
    title: ''
  }
}

// Handle local state for material groups.
// The updated state is passed to the parent component via the handleUpdateMaterialGroups callback via a useEffect.
const materialGroupsReducer = (state: MgList, action: any) => {
  switch (action.type) {
    case 'ADD_PAGE': {
      return {
        ...state,
        [`pg${Object.keys(state).length + 1}`]: cloneDeep(mgPageTemplate)
      }
    }
    case 'REMOVE_PAGE': {
      const newState = cloneDeep(state);
      delete newState[action.payload];
      return newState;
    }
    case 'ADD_MG1': {
      const newState = cloneDeep(state);
      const materialCode = action.payload.code;
      const materialName = action.payload.title;
      const pageNum = action.payload.page;

      newState[pageNum!].materials![materialCode] = {
        title: materialName
      };

      return newState;
    }
    case 'ADD_MATERIAL': {
      const newState = cloneDeep(state);
      const materialCode = action.payload.code as string;
      const materialName = action.payload.title;
      const pageNum = action.payload.page;

      newState[pageNum!].materials![materialCode] = {
        materialNumbers: [materialCode],
        title: materialName
      };

      return newState;
    }
    case 'REMOVE_MG': {
      const newState = cloneDeep(state);
      delete newState[action.payload.page].materials![action.payload.code];
      return newState;
    }
    case 'SET_PAGE_MIN_VARIANTS': {
      const newState = cloneDeep(state);
      const typedValue = parseInt(action.payload.value);
      newState[action.payload.page].minVariants = typedValue;
      return newState;
    }
    case 'SET_PAGE_TITLE': {
      const newState = cloneDeep(state);
      newState[action.payload.page].title = action.payload.value;
      return newState;
    }
    default:
      return state;
  }
}

export interface MaterialGroupsListProps {
  data: GenericDictionary<MgPage>;
  handleUpdateMaterialGroups: (action: ProgrammeAction) => void;
}

function MaterialGroupsList(props: MaterialGroupsListProps) {
  const dispatch = useDispatch();
  
  // Default the initial state to the template if no data is passed in
  const initialMgList = (props.data !== undefined && typeof props.data === 'object' && Object.keys(props.data).length > 0)
    ? props.data : cloneDeep(mgGroupsTemplate);

  const [materialGroups, dispatchMgAction] = useReducer(materialGroupsReducer, initialMgList);
  const [materialGroupOptions, setMaterialGroupOptions] = React.useState<MgOption[]>([]);
  const [loading, setLoading] = React.useState(false);
  const productList = useSelector((state:FixMeLater) => state.CoreRangeReducer.Products);
  const [ mg1Selection, setMg1Selection ] = React.useState<MgOption | null>(null);
  const [ materialSelection, setMaterialSelection ] = React.useState<any>(null);
  const user = useSelector((state:FixMeLater) => state.UserReducer.loggedInUser);
  const currentSite = getSiteForLoggedInUser(user);

  async function getMaterialGroups(){
    setLoading(true);
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.ACTIVITIES}/ListMG1s`,
        {
          method: "get",
        }
      );
    setMaterialGroupOptions(response.data);
    setLoading(false);
  }

  useEffect(() => {
    // Get MG1 material groups
    getMaterialGroups().catch(e => {
      console.log('Error fetching material groups', e);
    });
    
    // Get all materials
    if(!productList || !productList.length) {
      dispatch(GetProductList(currentSite, () => {}));
    }
  }, []);

  // When the material groups list changes, update the parent component's data
  useEffect(() => {
    props.handleUpdateMaterialGroups({
      type: 'SET_MATERIALS',
      payload: materialGroups
    });

    // Reset the Autocompletes after a selection is made
    setMg1Selection(null)
    setMaterialSelection(null)
  }, [materialGroups]);

  return (
    <div>
      <ol className={styles.mgPageList}>
        {Object.entries(materialGroups).map(([pgKey, pgValue], pgIndex) => {
          return (
            <li className={styles.mgPageList_item} key={pgKey}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display='flex' flex={1} style={{gap: '0.5rem'}} alignItems='center'>
                    <Box flex='1'>
                      <h3 style={{marginTop:0}}>Page {pgIndex + 1}</h3>
                    </Box>
                    <Box flex='0'>
                      <Tooltip placement="top" title="Delete Page">
                        <IconButton onClick={(e) => dispatchMgAction({type: 'REMOVE_PAGE', payload: pgKey})}>
                          <RemoveCircleOutline fontSize='small' color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField variant='outlined' size='small' fullWidth label='Page Title' value={pgValue.title || ''} onChange={e => dispatchMgAction({type:'SET_PAGE_TITLE', payload: { value: e.target.value, page: pgKey }})}/>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField variant='outlined' size='small' fullWidth label='Minimum Variants' type='number' value={pgValue.minVariants || ''} onChange={e => dispatchMgAction({type:'SET_PAGE_MIN_VARIANTS', payload: {value: e.target.value, page: pgKey}})}/>
                </Grid>
                <Grid item xs={12}>
                  <ul className={styles.mgMaterialList}>
                    {Object.entries(pgValue.materials!).map(([mgKey, mgValue], mgIndex) => {
                      return (
                        <li key={mgKey} className={styles.mgMaterialList__item}>
                          <Box display='flex' flex={1} style={{gap: '0.5rem'}} alignItems='center'>
                            <Box flex='1'>
                              <strong>{mgKey}</strong> &ndash; {mgValue.title}
                            </Box>
                            <Box flex='0'>
                              <Tooltip placement="top" title="Delete Item">
                                <IconButton onClick={(e) => dispatchMgAction({type: 'REMOVE_MG', payload: {page: pgKey, code: mgKey}})}>
                                  <RemoveCircleOutline fontSize='small' color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </li>
                      )
                    })}
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={materialGroupOptions}
                    value={mg1Selection || null}
                    loading={loading}
                    blurOnSelect={true}
                    clearOnBlur={true}
                    fullWidth
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Add MG1..." variant="outlined" size="small" />
                    )}
                    getOptionLabel={(option: MgOption) =>
                      `${option.code} - ${option.value}`
                    }
                    onChange={(e, value) => {
                      if(!value) return;
                      setMg1Selection(value);
                      dispatchMgAction({ type: "ADD_MG1", payload: { code: value!.code, title: value!.value, page: pgKey } })
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={productList}
                    value={materialSelection || null}
                    loading={loading}
                    blurOnSelect={true}
                    clearOnBlur={true}
                    fullWidth
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="Add individual material..." variant="outlined" size="small" />
                    )}
                    getOptionLabel={(option: MaterialOption) =>
                      `${option.Number} - ${option.Description}`
                    }
                    onChange={(e, value) => {
                      if(!value) return;
                      setMaterialSelection(value);
                      dispatchMgAction({ type: "ADD_MATERIAL", payload: { code: value!.Number, title: value!.Description, page: pgKey } })
                    }}
                  />
                </Grid>
              </Grid>
            </li>
          );
        })}
      </ol>
      <Box display='flex' justifyContent='flex-end'>
        <Button onClick={(e) => dispatchMgAction({type: 'ADD_PAGE'})} variant='outlined'>Add Page</Button>
      </Box>
    </div>
  );
}

export default MaterialGroupsList;