// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../components/dialog/Modal";

// UI and Styling
import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import WarningRounded from "@material-ui/icons/WarningRounded";
import Information from "@material-ui/icons/InfoRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// Actions
import { getLoggedInUserByEmail } from "../../../state/actions/UserActions";

// Components
import HeaderBlock from "../../../components/header-block/HeaderBlock";

// Segments
import MigrationUploadSegment from "../../../segments/content-upload-segments/MigrationUploadSegment";
import { UUID } from "../../../helpers/uuid";
import PageContainer from "../../../components/page-container/PageContainer";
import { LoadingComponent } from "../../../components/loading2/circleLoader";
import { uploadCustomerDoorData } from "../../../state/actions/CoreRangeActions";
import { downloadCustomerDoorData } from "../../../state/actions/CoreRangeActions";
import { Alert, AlertTitle } from "@material-ui/lab";

// API

// Moment
var moment = require("moment");
moment().format();

const RedTextTypography = withStyles({
  root: {
    color: "#f53100",
  },
})(Typography);

const CustomerDoorUploadPage = (props) => {
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [previewMigrationCalled, setPreviewMigrationCalled] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [csvInvalidError, setCsvInvalidError] = useState(false);
  const [csvDuplicateError, setCsvDuplicateError] = useState(false);
  const [csvSiteError, setCsvSiteError] = useState(false);
  const [duplicateValues, setDuplicateValues] = useState([]);
  const [siteValues, setSiteValues] = useState([]);

  const dispatch = useDispatch();

  const hasDuplicates = (arr, property) => {
    let duplicates = [];
    let tmp = [];
    const values = new Set();
    for (const obj of arr) {
      const value = obj[property];
      tmp.push(value);
      if (tmp.filter((item) => item === value).length > 1) {
        duplicates.push(value);
      }
      values.add(value);
    }
    if (duplicates.length > 0) {
      setDuplicateValues(duplicates);
      return true;
    } else {
      return false;
    }
  };

  const hasDifferentValue = (arr, property) => {
    let diffs = [];
    if (csvData.length === 0) {
      return false;
    }

    const requiredValue = loggedInUser.territory === "au" ? "1" : "2";
    arr.forEach((item, index) => {
      let value = item[property];
      if (item[property] !== requiredValue) {
        diffs.push({ value: value, index: index });
      }
    });
    if (diffs.length > 0) {
      setSiteValues(diffs);
      return true;
    } else {
      return false;
    }
  };

  //Use Effects

  useEffect(() => {
    const csvHasDuplicates = hasDuplicates(csvData, "CustomerNumber");
    const csvHasDifferentSite = hasDifferentValue(csvData, "Site");

    if (csvHasDuplicates) {
      setCsvDuplicateError(true);
    } else if (csvHasDifferentSite) {
      setCsvSiteError(true);
    } else {
      setCsvInvalidError(false);
    }
  }, [csvData]);

  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }
  }, []);

  useEffect(() => {
    if (targetsEdited) {
      setSubmitEnabled(true);
    }
  }, [targetsEdited]);

  useEffect(() => {
    if (dataChanged) {
      setDataChanged(false);
    }
  }, [dataChanged]);

  const handleSubmit = async () => {
    setIsUploading(true);
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);

    const data = createRequestBody();

    // dispatch(
    //     MerchandiserMassUpload(
    //         data,
    //         (res) => {
    //             if (res) {
    //                 props.history.push("/post-migration");
    //             }
    //         }
    //     )
    // );
  };

  const csvDuplicateErrorElement = csvDuplicateError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p> CSV contains duplicate Customer Numbers:</p>
        <ul>
          {duplicateValues.map((value, i) => (
            <li key={i}>{value}</li>
          ))}
        </ul>
      </div>
      <strong>Clear CSV and Try Again.</strong>
    </Alert>
  ) : (
    <></>
  );

  const csvSiteErrorElement = csvSiteError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p> CSV contains different Site values:</p>
        <ul>
          {siteValues.map((value, i) => (
            <li key={i}>
              {value.value} - index ({value.index})
            </li>
          ))}
        </ul>
      </div>
      <strong>Clear CSV and Try Again.</strong>
    </Alert>
  ) : (
    <></>
  );

  const createRequestBody = () => {
    let toReturn = [];
    csvData.forEach((row) => {
      const requestBody = {
        ...row,
        DoorSize: parseInt(row.DoorSize),
        Site: parseInt(row.Site),
        CreatedDate: moment().toISOString(),
        CreatedBy:
          !row.createdBy && row.CreatedBy === ""
            ? loggedInUser.userName
            : row.CreatedBy,
        ModifiedBy: loggedInUser.userName,
        ModifiedDate: moment().toISOString(),
      };
      toReturn.push(requestBody);
    });
    return toReturn;
  };

  const renderSubmitAndCancelButtons = () => {
    const site = loggedInUser.territory === "au" ? 1 : 2;
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => {
          const data = createRequestBody();
          const site = csvData ? csvData[0].Site : site;
          dispatch(
            uploadCustomerDoorData(data, site, (res) => {
              if (res === false) {
                res = {
                  message: "Upload failed, check file for duplicate customers",
                };
              } else {
                res.message = "Customer Door Upload Successful";
              }
              setPreviewData(res);
              setModalShow(true);
            })
          );
        }}
        disabled={csvInvalidError}
      >
        Submit
      </Button>,
    ];
  };

  function MyVerticallyCenteredModal() {
    return (
      <Modal
        style={{ color: "green" }}
        title={
          <div>
            <Typography variant="h4" align="center">
              <CheckCircleIcon />
            </Typography>
            <Typography align="center" variant="h5">
              {previewData.message}
            </Typography>
          </div>
        }
        open={modalShow}
        fullWidth={true}
        actions={
          <div>
            <br />
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setModalShow(false);
              }}
            >
              Close
            </Button>
          </div>
        }
      >
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "20vh",
          }}
        >
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Updated Customer Doors: </b>
            <span style={{ fontSize: "24px" }}>{previewData.updated}</span>
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Created Customer Doors: </b>
            <span style={{ fontSize: "24px" }}>{previewData.created}</span>
          </Typography>
          <Typography
            style={{
              marginLeft: "30px",
              display: "flex",
              flexDirection: "column",
            }}
            align="center"
            variant="subtitle1"
          >
            <b>Deleted Customer Doors: </b>
            <span style={{ fontSize: "24px" }}>{previewData.deleted}</span>
          </Typography>
        </div>
      </Modal>
    );
  }

  return (
    <Box>
      <Fragment>
        {isUploading ? (
          <>
            <HeaderBlock
              title={"Please Wait: Customer Door Data is being uploaded"}
            />
            <PageContainer>
              <LoadingComponent></LoadingComponent>
            </PageContainer>
          </>
        ) : (
          <>
            <HeaderBlock
              title={"Customer Door Mass Upload"}
              right={
                renderSubmitAndCancelButtons() ? (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      <Button
                        style={{ marginRight: "1.5rem" }}
                        variant="outlined"
                        onClick={() => setCsvData([])}
                        disabled={csvData.length === 0}
                      >
                        Clear CSV
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          const site = loggedInUser.territory === "au" ? 1 : 2;
                          dispatch(downloadCustomerDoorData(site, (res) => {}));
                        }}
                      >
                        Download CSV
                      </Button>
                    </Grid>
                    <Grid item>
                      <ButtonGroup>
                        {renderSubmitAndCancelButtons()}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                ) : null
              }
            />

            <PageContainer>
              {csvDuplicateErrorElement}
              {csvSiteErrorElement}
              <div>
                <Grid container spacing={3}>
                  <Grid
                    key={"migrationUploadSegment"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <MigrationUploadSegment
                      csvData={csvData}
                      setCsvData={setCsvData}
                      setCSVInvalid={(option) => {
                        if (option) {
                          setCsvInvalidError(true);
                          setTimeout(() => {
                            setCsvInvalidError(false);
                          }, 30000);
                        }
                      }}
                      setDataChanged={setDataChanged}
                      title="Upload Customer Doors"
                      targetsEdited={targetsEdited}
                      setTargetsEdited={setTargetsEdited}
                      isEditing={isEditing}
                      setFormEdited={setFormEdited}
                      description="WARNING: This will delete all existing customer doors in the database and replace them with the data from the CSV. Please make sure you have all the existing door data before uploading. Please upload a CSV containing the Customer door data that you would like uploaded to SaM-Core. Please ensure there is only one record per customer number otherwise the whole upload will be rejected."
                      columns={[
                        "CustomerNumber",
                        "DoorSize",
                        "Site",
                        "CreatedBy",
                        "CreatedDate",
                        "ModifiedBy",
                        "ModifiedDate",
                      ]}
                      allDataUrl={"templateUrl"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </Grid>
              </div>
            </PageContainer>
          </>
        )}
      </Fragment>
    </Box>
  );
};

const hoc = withRouter(CustomerDoorUploadPage);

// EXPORT COMPONENT
export { hoc as CustomerDoorUploadPage };
