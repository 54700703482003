import * as React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/style.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createAppStore } from "./state/store/AppStore";

// Through
//@ts-ignore react-through has no typedef. In general this package may be able to be deprecated.
import { ThroughProvider } from "react-through";

// Theme
import { MuiThemeProvider } from "@material-ui/core";
import { getCustomTheme } from "./theme/theme";

export const redux = createAppStore();
const theme = getCustomTheme();

ReactDOM.render(
  <Provider store={redux.store}>
    <PersistGate loading={null} persistor={redux.persistor}>
      <MuiThemeProvider theme={theme}>
        <ThroughProvider>
          <App />
        </ThroughProvider>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
