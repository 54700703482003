import {
  GET_TARGET_GROUPS,
  GET_TARGET_GROUP,
  GET_TARGET_RULES,
  GET_TARGET_GROUPS_BY_IDS,
  UPDATE_TARGET_GROUP,
  UPDATE_CURRENT_TARGET_GROUP
} from "../constants/action-types";
import { ModelType } from "../constants/ModelType";

// INITIALIZE STATE
const initialState = {
  targetGroups: [],
  currentTargetGroup: {},
  currentModelType: ModelType.CUSTOMER,
  targetGroupRules: {}
};

function createTargetGroupData(payload) {
  if (payload !== undefined) {
    let siteRule = {};
    let siteIndex = {};

    if (payload.Rules.length > 0) {
      payload.Rules.forEach((rule, index) => {
        if (
          rule.RuleName.toUpperCase() ===
          `${payload.ModelType.toUpperCase()}_SITE`
        ) {
          siteRule = rule;
          siteIndex = index;
        }
      });

      // Need to remove the site rule and apply the site value to the site property of data
      payload.Rules.splice(siteIndex, 1);
      payload.Site =
        siteRule.RuleValues !== undefined ? siteRule.RuleValues[0] : "";
    }
  }

  return payload;
}

// REDUCER
export const TargetGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TARGET_GROUPS: {
      const targetGroups = action.payload.data;

      return {
        ...state,
        targetGroups
      };
    }
    case GET_TARGET_GROUP: {
      const currentTargetGroup = createTargetGroupData(action.payload.data);

      return {
        ...state,
        currentTargetGroup
      };
    }
    case GET_TARGET_RULES: {
      const targetGroupRules = action.payload.data;
      return {
        ...state,
        targetGroupRules
      };
    }
    case GET_TARGET_GROUPS_BY_IDS: {
      const incomingTargetGroups = action.payload;
      const targetGroups = Object.assign({}, state.targetGroups);

      incomingTargetGroups.forEach((targetGroup) => {
        const currentModelType = targetGroup.ModelType;

        if (!targetGroups[currentModelType]) {
          targetGroups[currentModelType] = {};
        }

        targetGroups[currentModelType][targetGroup.id] = targetGroup;
      });

      return {
        ...state,
        targetGroups,
        currentModelType: ModelType.CUSTOMERS
      };
    }
    case UPDATE_TARGET_GROUP: {
      return {
        ...state,
        currentTargetGroup: action.payload
      };
    }
    case UPDATE_CURRENT_TARGET_GROUP: {
      return {
        ...state,
        currentTargetGroup: action.payload
      };
    }

    default:
      return state;
  }
};
