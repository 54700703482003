import {
    GET_ALL_MYCCA_CONTENT,
    GET_MYCCA_CONTENT_BY_ID,
    GET_MYCCA_LOOKUPS,
    GET_ALL_TYPES,
    GET_TYPE_BY_ID
} from "../constants/action-types";

// INITIALIZE STATE
const initialState = {
    myccaAllContent: {},
    currentMyccaContent: {},
    currentMyccaType: {},
    lookups: {},
    types: {}
};

export const createNewContent = (serverContent) => {
    let content = {};

    if (serverContent) {
        content.contentId = serverContent.contentId ? serverContent.contentId : "";
        content.code = serverContent.code ? serverContent.code : "";
        content.typeId = serverContent.typeId ? serverContent.typeId : "";
        content.isActive = serverContent.isActive;
        content.description = serverContent.description
            ? serverContent.description
            : "";
        content.site = serverContent.site ? serverContent.site : "";
        content.endDate = serverContent.endDate
            ? new Date(serverContent.endDate + 'Z')
            : "";
        content.retentionPolicy = serverContent.retentionPolicyId
            ? serverContent.retentionPolicyId
            : "";
    } else {
        content = {
            id: "",
            code: "",
            description: "",
            typeId: "",
            isActive: true,
            site: "",
            endDate: new Date(),
            retentionPolicy: ""
        };
    }

    return content;
};

// REDUCER
export const MyccaResourceImageManagerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_MYCCA_CONTENT: {
            if (action.payload) {
                return {
                    ...state,
                    myccaAllContent: action.payload
                };
            } else {
                return {
                    ...state
                };
            }
        }
        case GET_ALL_TYPES: {
            return {
                ...state,
                types: action.payload
            }
        }
        case GET_MYCCA_CONTENT_BY_ID: {
            return {
                ...state,
                currentMyccaContent: action.payload
            };
        }
        case GET_TYPE_BY_ID: {
            return{
                ...state,
                currentMyccaType: action.payload
            }
        }
        case GET_MYCCA_LOOKUPS: {
            return {
                ...state,
                lookups: action.payload
            };
        }
        default:
            return state;
    }
};
