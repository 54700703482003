/**
 * Gets the current local time and formats is as ISO 8601
 * @returns The current local time as an ISO 8601 string (eg. 2011-10-05T14:48:00.000Z)
 */

export const getCurrentTimeAsISO = () => {
  const now = new Date();
  const nowISO = now.toISOString();

  return nowISO;
}