// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

// UI and Styling
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import styles from "./UsersPage.module.scss";

// Actions
import {
  getUsers,
  getLoggedInUserByEmail
} from "../../state/actions/UserActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import { OutlinedIconButton } from "../../components/buttons/OutlinedIconButton";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import PageContainer from "../../components/page-container/PageContainer";
import SimpleTable from "../../components/table/Table";

// Other
import { getIcon } from "../../icon/icon";
import { Territories, ExpandedTerritories, TerritoriesWithNA } from "../../state/constants/Territories";
import { UUID } from "../../helpers/uuid";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  }
}));

const UsersPage = (props) => {
  const classes = useStyles();

  const users = useSelector((state) => state.UserReducer.users);
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});
  const [filtersUpdated, setFiltersUpdated] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const setupData = () => {
    setLoading(true);

    dispatch(
      getUsers(() => {
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setupData();
        })
      );
    } else {
      setupData();
    }
  }, []);

  useEffect(() => {
    const data = createData(users);

    setOriginalData(data);

    let localFilters = filters;

    localFilters[loggedInUser.territory] = true;

    setFilters(localFilters);
    setFiltersUpdated(true);
  }, [users]);

  useEffect(() => {
    setFiltersUpdated(false);
    search(searchText);
  }, [filters, filtersUpdated]);

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const territories = [];

    Object.keys(ExpandedTerritories).forEach((key, index) => {
      if (filters[key]) {
        territories.push(ExpandedTerritories[key]);
      }
    });

    const foundData = originalData.filter((user) => {
      return (
        (user.name.toLowerCase().includes(text) ||
          (user.sapUsername && user.sapUsername.toLowerCase().includes(text)) ||
          (user.email && user.email.toLowerCase().includes(text))) &&
        (territories.length === 0 || territories.includes(user.territory))
      );
    });

    setSearchedData(foundData);
  };

  const createData = (users) => {
    const userKeys = Object.keys(users);

    return userKeys.map((userKey) => {
      const user = users[userKey];
      return {
        id: user.id,
        name: user.name,
        sapUsername: user.sapUsername,
        territory: ExpandedTerritories[user.territory],
        email: user.email
      };
    });
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const createHeaders = () => {
    return [
      { id: "name", align: "left", label: "Name" },
      { id: "sapUsername", align: "left", label: "SAP Username" },
      {
        id: "email",
        align: "left",
        label: "Email",
        clickable: true,
        action: { path: "/users/{id}", identifier: "id" }
      },
      { id: "territory", align: "left", label: "Site" }
    ];
  };

  const handleChange = (event) => {
    let localFilters = filters;
    if (filters[event.target.name]) {
      localFilters[event.target.name] = !localFilters[event.target.name];
    } else {
      localFilters[event.target.name] = true;
    }

    setFilters(localFilters);
    setFiltersUpdated(true);
  };

  const displayFilters = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="flex-end"
        style={{
          display: showFilters ? "inline-flex" : "none",
          // transition:
          padding: 16
        }}
      >
        <Typography align="right">Filter by territory: </Typography>
        <FormGroup row>
          {Object.keys(TerritoriesWithNA).map((key, index) => {
            return (
              <FormControlLabel
                labelPlacement="start"
                key={UUID()}
                label={TerritoriesWithNA[key]}
                control={
                  <Checkbox
                    checked={filters[key]}
                    onChange={handleChange}
                    name={key}
                    color="primary"
                  />
                }
              />
            );
          })}
        </FormGroup>
      </Grid>
    );
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    if (searchedData.length === 0) {
      return (
        <Fragment>
          {displayFilters()}
          <PagePlaceholder
            image={getIcon("users.svg")}
            text="No users found."
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {displayFilters()}
          <SimpleTable
            dataId={"id"}
            cells={cells}
            headers={headers}
            rows={searchedData}
          />
        </Fragment>
      );
    }
  };

  return (
    <div className={styles.container}>
      <BreadcrumbsItem to="/users">Users</BreadcrumbsItem>
      <HeaderBlock
        title={"Users"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                className={styles.roleField}
                id="outlined-name"
                label="Search Users"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <OutlinedIconButton
                clickHandler={() => setShowFilters(!showFilters)}
                icon="funnel.svg"
              />
            </Grid>
            <Grid item>
              <ExportExcel
                csvData={searchedData}
                fileName="users"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item><Button
              variant="outlined"
              size="large"
              onClick={() => {
                props.history.push("users/new");
              }}
            >
              Add User
            </Button>
            </Grid>
          </Grid>
        }
      />

      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(UsersPage);

// EXPORT COMPONENT
export { hoc as UsersPage };
