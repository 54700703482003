export const CategoryModel = {
  description: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "description",
    label: "Description"
  },
  contentType: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "contentType",
    label: "Content Type"
  }
};
