// React
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

// UI and Styling
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";

// Actions
import { getRoles } from "../../../state/actions/RoleActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  }
}));

const ListRolesPage = (props) => {
  const classes = useStyles();

  const roles = useSelector((state) => state.RoleReducer.roles);

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  const setupData = () => {
    const data = createData(roles);

    setOriginalData(data);
    setSearchedData(data);
  };

  useEffect(() => {
    setLoading(true);

    dispatch(
      getRoles(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setupData();
  }, [roles]);

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    if (text === "") {
      setSearchedData(originalData);
    }

    const foundData = originalData.filter((data) => {
      return (
        (data.name && data.name.toLowerCase().includes(text)) ||
        (data.description && data.description.toLowerCase().includes(text)) ||
        (data.id && data.id.toLowerCase().includes(text))
      );
    });

    setSearchedData(foundData);
  };

  const createData = (roles) => {
    const roleKeys = Object.keys(roles);

    return roleKeys.map((roleKey) => {
      const role = roles[roleKey];
      return {
        name: role.name,
        description: role.description,
        id: role.id
      };
    });
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      cell.handleClick = (event, row) => {
        props.history.push(`/roles/${row.id}`);
      };

      cell.align = header.align;
      cell.clickable = header.clickable;
      cell.type = header.type;

      cells.push(cell);
    });

    return cells;
  };

  const createHeaders = () => {
    return [
      {
        id: "name",
        clickable: true,
        action: { path: "/roles/{id}", identifier: "id" },
        label: "Name"
      },
      { id: "description", label: "Description" }
    ];
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    if (searchedData.length > 0) {
      return (
        <SimpleTable
          dataId={"id"}
          cells={cells}
          headers={headers}
          rows={searchedData}
        />
      );
    } else {
      return (
        <PagePlaceholder
          image={getIcon("seo-consulting.svg")}
          text={"No Roles Found."}
        />
      );
    }
  };

  return (
    <div>
      {/* <BreadcrumbsItem to="/roles">Roles</BreadcrumbsItem> */}
      <HeaderBlock
        title={"Roles"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                id="outlined-name"
                label="Search roles"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <ExportExcel
                csvData={originalData}
                fileName="roles"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  props.history.push("roles/new");
                }}
              >
                Add Role
            </Button>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ListRolesPage);

// EXPORT COMPONENT
export { hoc as ListRolesPage };
