export const CampaignModel = () => {
  return {
    Name: {
      type: "text",
      required: true,
      maxLength: 50,
      id: "outlined-name",
      name: "name",
      label: "Name",
      placeholder: "Enter name",
      errorMessage: ""
    },
    Description: {
      type: "text",
      required: true,
      maxLength: 250,
      id: "outlined-description",
      name: "description",
      label: "Description",
      placeholder: "Enter description",
      errorMessage: ""
    },
    StartDate: {
      type: "date",
      required: true,
      errorMessage: "",
      label: "Start Date"
    },
    EndDate: {
      type: "date",
      required: true,
      errorMessage: "",
      label: "End Date"
    },
    TargetGroups: {
      type: "array",
      required: true,
      name: "targetGroups",
      label: "Target Groups",
      errorMessage: ""
    },
    TargetContent: {
      type: "array",
      required: true,
      name: "targetedContent",
      label: "Targeted Content",
      errorMessage: "",
      warningMessage: ""
    },
    SelectedApplications: {
      type: "array",
      required: true,
      errorMessage: "",
      value: "selectedApplications",
      label: "Applications To Target"
    },
  };
};
