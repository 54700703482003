import React from "react";
import styles from "./PagePlaceholder.module.scss";

export class PagePlaceholder extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.image}>{ this.props.image }</div>
        <div className={styles.text}>{this.props.text}</div>
      </div>
    );
  }
}
