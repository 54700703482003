// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// UI and Styling
import { Box, Grid, TextField } from "@material-ui/core";

// Actions
import {
  getAllResources,
  clearResourceImages,
  downloadResourceImageBlob,
  setCurrentResourceImage
} from "../../state/actions/ResourceImageManagerActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Components
import HeaderBlock from "../../components/header-block/HeaderBlock";
import ImageGrid from "../../components/image-grid/ImageGrid";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import ScrollableTabs from "../../components/tabs/Tabs";

// Other
import { ContentType } from "../../state/constants/ContentType";

function ResourceImageManagerPage(props) {
  const targets = useSelector(
    (state) => state.ResourceImageManagerReducer.resourceImages
  );

  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentResourceType, setCurrentResourceType] = useState(
    "productImage"
  );
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [fetchResourceData, setFetchResourceData] = useState(false);
  const [isContentAdmin, setIsContentAdmin] = useState(false);

  useEffect(() => {
    dispatch(
      getLoggedInUserByEmail(() => {
        setFetchResourceData(true);
      })
    );

    return () => {
      setLoading(true);
      dispatch(
        clearResourceImages(() => {
          setLoading(false);
        })
      );
    };
  }, []);

  useEffect(() => {
    if (
      loggedInUserRoles !== undefined &&
      (loggedInUserRoles.includes("Content Admin") ||
        loggedInUserRoles.includes("SaM Core Admin"))
    ) {
      setIsContentAdmin(true);
    } else {
      setIsContentAdmin(false);
    }
  }, [loggedInUserRoles]);

  useEffect(() => {
    fetchData(currentResourceType);
  }, [fetchResourceData, isContentAdmin]);

  useEffect(() => {
    setSelection([]);
    setSearchedData(targets);
    setOriginalData(targets);
  }, [targets]);

  function fetchData(type) {
    setLoading(true);

    if (loggedInUser && Object.keys(loggedInUser).length > 0) {
      dispatch(
        getAllResources(
          type.toLowerCase(),
          isContentAdmin ? "all" : loggedInUser.territory,
          () => {
            setCurrentResourceType(type);
            setLoading(false);
            setFetchResourceData(false);
          }
        )
      );
    }
  }

  function resourceImageTypeToIndex(resourceImageType) {
    const resource = ContentType[resourceImageType];

    if (resource != null) {
      return resource.key;
    }

    return null;
  }

  function resourceIndexToResourceImageType(index) {
    const resource = Object.values(ContentType).find(
      (item) => item.key === index
    );
    if (resource != null) {
      return resource.value;
    }

    return null;
  }

  function onTabChange(newValue) {
    const type = resourceIndexToResourceImageType(newValue);
    fetchData(type);
  }

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const foundData = originalData.filter((target) => {
      return target.name.toLowerCase().includes(text);
    });

    setSearchedData(foundData);
  };

  function onImageClick(id) {
    const target = targets.find((t) => t.name === id);
    const resourceImage = {
      territory: target.territory,
      targetType: currentResourceType.toLowerCase(),
      targetName: target.name
    };

    dispatch(
      setCurrentResourceImage(resourceImage, () => {
        props.history.push(`/image-manager/${target.name}`);
      })
    );
  }

  function retrieveImage(target, callback) {
    // fetch from server
    dispatch(
      downloadResourceImageBlob(
        targets.find((t) => t.name === target).territory,
        "medium",
        currentResourceType.toLowerCase(),
        target,
        (blob) => {
          const value = "data:application/octet-stream;base64," + blob;
          callback(target, value);
        }
      )
    );
  }

  function renderData() {
    return !loading ? (
      <ImageGrid
        currentSelection={selection}
        data={
          searchedData
            ? Object.values(searchedData).map((target) => target.name)
            : []
        }
        spacing={5}
        cols={5}
        cellHeight={175}
        emptyPlaceholder={"No Images available"}
        retrieveImage={(target, callback) => retrieveImage(target, callback)}
        handleSelection={(items) =>
          setSelection(items.map((item) => ({ id: item, value: item })))
        }
        selectable={false}
        onClick={(id) => onImageClick(id)}
        searchText={searchText}
      />
    ) : (
        <Loading />
      );
  }

  return (
    <Box>
      <HeaderBlock
        title="Image Manager"
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <TextField
                id="outlined-name"
                label="Search Images"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
          </Grid>
        }
      />
      <PageContainer>
        <ScrollableTabs
          handleTabChange={(newValue) => onTabChange(newValue)}
          value={resourceImageTypeToIndex(currentResourceType)}
          tabs={[
            {
              key: ContentType.ProductImage.key,
              name: ContentType.ProductImage.tabLabel,
              content:
                currentResourceType === ContentType.ProductImage.value
                  ? renderData()
                  : null
            },
            {
              key: ContentType.EquipmentImage.key,
              name: ContentType.EquipmentImage.tabLabel,
              content:
                currentResourceType === ContentType.EquipmentImage.value
                  ? renderData()
                  : null
            }
          ]}
        />
      </PageContainer>
    </Box>
  );
}

export default React.memo(ResourceImageManagerPage);
