// React
import React, { useState } from "react";

// UI and Styling
import {
  Grid,
  Chip,
  FormHelperText,
  List,
  Button,
  ButtonGroup,
  Typography
} from "@material-ui/core";
import styles from "./ListableStopsCard.module.scss"; // Import css modules stylesheet as styles

// Components
import { CustomCardContent } from "../card-content/CardContent";
import { SimpleCardHeader } from "../card/card-header/CardHeader";
import { Loading } from "../loading/Loading";
import ListableStopsItem from "./listable-item-stops/ListableStopsItem";
import ShrinkingButtonGroup from "../shrinking-button-group/ShrinkingButtonGroup";

export const ListableStopsCard = (props) => {
  const [selectedItems, setSelectedItems] = useState({});
  const handleCheck = (id) => {
    const localSelectedItems = Object.assign({}, selectedItems);
    if (!localSelectedItems.hasOwnProperty(id)) {
      localSelectedItems[id] = true;
    } else {
      delete localSelectedItems[id];
    }

    setSelectedItems(localSelectedItems);
  };

  const getDataList = () => {
    if (props.isLoading) {
      return <Loading />;
    }

    if (props.data.length === 0 && props.errorMessage !== "") {
      return <FormHelperText error={true}>{props.errorMessage}</FormHelperText>;
    }

    if (props.data.length === 0) {
      return <div className={styles.placeholder}>{props.emptyPlaceholder}</div>;
    }

    return (
      <Grid>
        <List>
          {props.data.map((stop) => {
            const id = stop.stopNumber;

            return (
              <ListableStopsItem
                key={`${id}`}
                disabled={props.disabled}
                id={id}
                handleValueChange={(label, value) => {
                  stop.handleValueChange(label, value);
                }}
                handleDelete={() => {
                  props.handleDelete([`${stop.id}`]);
                }}
                data={stop}
                handleCheck={() => {
                  handleCheck(id);
                }}
                selectedItems={selectedItems}
              />
            );
          })}
        </List>
        {props.errorMessage !== "" ? (
          <FormHelperText error={true}>{props.errorMessage}</FormHelperText>
        ) : null}
      </Grid>
    );
  };

  const actionButtons = (props.actionButton
    ? props.actionButton.map((action) => {
        return {
          label: action.props.children,
          action: () => action.props.onClick()
        };
      })
    : []
  ).concat({
    label: "Delete Last",
    action: () => props.deleteLastStop()
  });

  return (
    <CustomCardContent>
      <SimpleCardHeader>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderLeft}>
            <div className={styles.cardTitle}>{props.title}</div>
            {!props.isLoading && (
              <Chip label={props.data.length} variant="outlined" />
            )}
          </div>
          {props.disabled ? null : (
            <div>
              <div className={styles.largeScreen}>
                <ButtonGroup>
                  {!props.isLoading && props.actionButton}
                  {props.data.length > 0 && (
                    <Button
                      onClick={() => props.deleteLastStop()}
                      variant="outlined"
                    >
                      Delete Last
                    </Button>
                  )}
                </ButtonGroup>
              </div>
              <div className={styles.smallScreen}>
                <ShrinkingButtonGroup
                  secondary={actionButtons}
                  default={{
                    label: "...",
                    action: null
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </SimpleCardHeader>
      <SimpleCardHeader>
        <Typography variant="body2">{props.subheading}</Typography>
      </SimpleCardHeader>

      {getDataList()}
    </CustomCardContent>
  );
};
