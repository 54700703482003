export const ContentType = Object.freeze({
  ProductImage: {
    key: 0,
    type: "image",
    name: "Product Image",
    value: "productImage",
    tabLabel: "Products"
  },
  EquipmentImage: {
    key: 1,
    type: "image",
    name: "Equipment Image",
    value: "equipmentImage",
    tabLabel: "Equipment"
  }
});
