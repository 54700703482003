export const Territories = Object.freeze({
  au: "Australia",
  nz: "New Zealand"
});

export const TerritoriesWithNA = Object.freeze({
  ...Territories,
  na: "N/A"
});

export const ExpandedTerritories = Object.freeze({
  ...Territories,
  1: "Australia",
  2: "New Zealand",
  NZ: "New Zealand",
  AU: "Australia",
  na: "N/A",
  null: "N/A"
});

export const GetSite = (site) => {
  if (typeof (site) === "string") {
    return ExpandedTerritories[site.toLowerCase()];
  } else {
    return ExpandedTerritories[site];
  }
}
