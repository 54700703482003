// Auth
import { runWithAdal } from 'react-adal';
import { AuthenticationContext } from 'react-adal';

const isCCATenant = () => {
  return window.location.hostname.endsWith(".ccamatil.com");
}; 

const samcoreConfig = {
  tenant: isCCATenant() ? process.env.REACT_APP_TENANT : process.env.REACT_APP_CCEP_TENANT,
  clientId: isCCATenant() ? process.env.REACT_APP_CLIENT_ID : process.env.REACT_APP_CCEP_CLIENT_ID,
  redirectUri: window.location.protocol + "//" + window.location.hostname + ((window.location.port == "" || window.location.port == 443) ? "" : (":" + window.location.port)) + "/auth",
  cacheLocation: "sessionStorage"
};

const wmdConfig = {
  tenant: process.env.REACT_APP_WMD_TENANT,
  clientId: process.env.REACT_APP_WMD_CLIENT_ID,
  redirectUri: window.location.protocol + "//" + window.location.hostname + ((window.location.port == "" || window.location.port == 443) ? "" : (":" + window.location.port)) + "/wmd/auth",
  cacheLocation: "sessionStorage"
};


const getAuthContext = () => {
  if (window.location.pathname.startsWith('/wmd') || window.location.pathname.startsWith('/map')) {
    const authContext = new AuthenticationContext(wmdConfig);
    return authContext
  } else {
    const authContext = new AuthenticationContext(samcoreConfig);
    return authContext
  }
}
const loginError = getAuthContext().getLoginError();
const loginWasTriedButFailed = loginError !== undefined && loginError !== null && loginError !== "";
const redirectIfNoUser = !loginWasTriedButFailed;
runWithAdal(
  getAuthContext(),
  () => {
    if (loginWasTriedButFailed) {
      window.alert(loginError);
      getAuthContext().logOut();
    }

    require('./reduxApp');
  },
  !redirectIfNoUser
);
