export const ConfigurationModel = {
    site: {
      type: "int",
      required: true,
      errorMessage: "",
      value: "site",
      label: "Site",
    },
    application: {
      type: "select",
      required: true,
      errorMessage: "",
      value: "application",
      label: "Application",
    },
    mainkey: {
      type: "string",
      required: true,
      errorMessage: "",
      value: "mainkey",
      label: "Mainkey",
    },
    subkey: {
      type: "string",
      required: true,
      errorMessage: "",
      value: "subkey",
      label: "Subkey",
    },
    code: {
      type: "string",
      required: true,
      errorMessage: "",
      value: "code",
      label: "Code",
    },
    value: {
      type: "string",
      required: true,
      errorMessage: "",
      value: "value",
      label: "Value",
    },
    valueDescription: {
      type: "string",
      required: false,
      errorMessage: "",
      value: "valueDescription",
      label: "Value Description",
    },
  };
  