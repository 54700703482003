// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid
} from "@material-ui/core";
import styles from "./ViewSFEActivationPage.module.scss";

// Actions
import {
  createSFEActivation,
  getSFEActivationById,
  updateSFEActivation,
  getDropdownLookups
} from "../../state/actions/SFEActivationActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import PageContainer from "../../components/page-container/PageContainer";

// Models
import { SFEActivationModel } from "../../models/SFEActivationModel";

// Other
import { FormType } from "../../state/constants/FormType";
import { validateField } from "../../helpers/validation";

const ViewSFEActivationPage = (props) => {
  const currentSFEActivation = useSelector(
    (state) => state.SFEActivationReducer.currentSFEActivation
  );
  const lookupData = useSelector((state) => state.ActivityReducer.lookupData);
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(SFEActivationModel);
  const [sfeActivation, setSFEActivation] = useState({});
  const [uneditedSFEActivation, setUneditedSFEActivation] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingSFEActivation, setIsExistingSFEActivation] = useState(true);
  const [sfeActivationId, setSFEActivationId] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);

  const [activityLookupData, setActivityLookupData] = useState([]);
  const [activityGroupLookupData, setActivityGroupLookupData] = useState([]);
  const [calculationTypeLookupData, setCalculationTypeLookupData] = useState(
    []
  );
  const [redChannelLookupData, setRedChannelLookupData] = useState([]);

  const setUpData = () => {
    setIsExistingSFEActivation(
      props.type != null && props.type === FormType.VIEW
    );

    //Get all dropdown lookup data
    dispatch(
      getDropdownLookups(loggedInUser.territory === "au" ? 1 : 2, () => {})
    );

    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getSFEActivationById(props.match.params.sfeActivationId, () => {
          setLoading(false);
          setSFEActivationId(props.match.params.sfeActivationId);
        })
      );
    } else {
      dispatch(
        getSFEActivationById(undefined, () => {
          setIsEditing(true);
          setLoading(false);
        })
      );
    }
  };

  //Use Effects
  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setUpData();
        })
      );
    } else {
      setUpData();
    }
  }, []);

  useEffect(() => {
    setActivityLookupData(lookupData.Activities);
    setActivityGroupLookupData(lookupData.ActivityGroups);
    setCalculationTypeLookupData(lookupData.CalculationTypes);
    setRedChannelLookupData(lookupData.RedChannels);
  }, [lookupData]);

  useEffect(() => {
    setSFEActivation(currentSFEActivation);
  }, [currentSFEActivation]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the sfeActivation
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, sfeActivation);

    localData[name] = value;

    setSFEActivation(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      sfeActivation[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }
    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      const errorMessage = checkGenericField(key, formProperties[key].required);

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the sfeActivation data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    let requestBody = {
      RedChannelCode: sfeActivation.redChannel.Id,
      ActivityGroupId: sfeActivation.activityGroup.Id,
      ActivityGroup: sfeActivation.activityGroup.DisplayName,
      ActivityId: sfeActivation.activity.Id,
      Activity: sfeActivation.activity.DisplayName,
      CalculationTypeCode: sfeActivation.calculationType.DisplayName,
      CalculationTypeDescription: sfeActivation.calculationType.DisplayName,
      SysIsCurrent: sfeActivation.active
    };

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      createRequestBody();
      dispatch(
        createSFEActivation(createRequestBody(true), (success, id) => {
          setIsEditing(false);
          props.history.push(`/sfe-activation`);
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      dispatch(
        updateSFEActivation(sfeActivationId, createRequestBody(false), () => {
          setIsEditing(false);
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingSFEActivation) {
        props.history.push(`/activity-groups/${sfeActivationId}`);
      }
    }
  };

  /*
Display the cancel editing alert dialog
*/
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setSFEActivation(uneditedSFEActivation);
              setCancelEditingAlertOpen(false);

              if (!isExistingSFEActivation) {
                props.history.push(`/activity-groups/${sfeActivationId}`);
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  //Display SFE activation calculation form
  const renderData = () => {
    return (
      <Fragment>
        <div className={styles.contentGrid}>
          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MultiSelectDropDown
                  id={`redChannel`}
                  fullWidth={true}
                  multiple={false}
                  disableCloseOnSelect={false}
                  showCheckbox={false}
                  disabled={!isEditing}
                  label={formProperties.redChannel.label}
                  options={
                    redChannelLookupData !== undefined
                      ? redChannelLookupData
                      : []
                  }
                  selectedValues={sfeActivation.redChannel || ""}
                  onChange={(event, value) => {
                    handleInputChange(formProperties.redChannel.value, value);
                  }}
                  variant="outlined"
                  textFieldLabel="Select Red Channel"
                  textFieldPlaceHolder="Red Channel"
                />
                {formProperties.redChannel.errorMessage !== "" ? (
                  <FormHelperText error>
                    {formProperties.redChannel.errorMessage}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <MultiSelectDropDown
                  id={`activityGroup`}
                  fullWidth={true}
                  multiple={false}
                  disableCloseOnSelect={false}
                  showCheckbox={false}
                  disabled={!isEditing}
                  label={formProperties.activityGroup.label}
                  options={
                    activityGroupLookupData !== undefined
                      ? activityGroupLookupData
                      : []
                  }
                  selectedValues={sfeActivation.activityGroup || ""}
                  onChange={(event, value) => {
                    handleInputChange(
                      formProperties.activityGroup.value,
                      value
                    );
                  }}
                  variant="outlined"
                  textFieldLabel="Select Activity Group"
                  textFieldPlaceHolder="Activity Group"
                />
                {formProperties.activityGroup.errorMessage !== "" ? (
                  <FormHelperText error>
                    {formProperties.activityGroup.errorMessage}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <MultiSelectDropDown
                  id={`activity`}
                  fullWidth={true}
                  multiple={false}
                  disableCloseOnSelect={false}
                  showCheckbox={false}
                  disabled={!isEditing}
                  label={formProperties.activity.label}
                  options={
                    activityLookupData !== undefined ? activityLookupData : []
                  }
                  selectedValues={sfeActivation.activity || ""}
                  onChange={(event, value) => {
                    handleInputChange(formProperties.activity.value, value);
                  }}
                  variant="outlined"
                  textFieldLabel="Select Activity"
                  textFieldPlaceHolder="Activity"
                />
                {formProperties.activity.errorMessage !== "" ? (
                  <FormHelperText error>
                    {formProperties.activity.errorMessage}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <MultiSelectDropDown
                  id={`calculationType`}
                  fullWidth={true}
                  multiple={false}
                  disableCloseOnSelect={false}
                  showCheckbox={false}
                  disabled={!isEditing}
                  label={formProperties.calculationType.label}
                  options={
                    calculationTypeLookupData !== undefined
                      ? calculationTypeLookupData
                      : []
                  }
                  selectedValues={sfeActivation.calculationType || ""}
                  onChange={(event, value) => {
                    handleInputChange(
                      formProperties.calculationType.value,
                      value
                    );
                  }}
                  variant="outlined"
                  textFieldLabel="Select Calculation Type"
                  textFieldPlaceHolder="Calculation Type"
                />
                {formProperties.calculationType.errorMessage !== "" ? (
                  <FormHelperText error>
                    {formProperties.calculationType.errorMessage}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                disabled={!isEditing}
                labelPlacement="start"
                label={formProperties.active.label}
                control={
                  <Checkbox
                    checked={
                      sfeActivation !== undefined &&
                      sfeActivation.active !== undefined
                        ? sfeActivation.active
                        : true
                    }
                    onChange={() =>
                      handleInputChange(
                        formProperties.active.value,
                        !sfeActivation.active
                      )
                    }
                    name={formProperties.active.value}
                    color="primary"
                  />
                }
              />
            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={sfeActivation.name}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedSFEActivation(sfeActivation);
                    } else if (isEditing && isExistingSFEActivation) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing
                    ? isExistingSFEActivation
                      ? "Done"
                      : "Submit"
                    : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelEditingAlert()}

      <PageContainer>{renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewSFEActivationPage);

// EXPORT COMPONENT
export { hoc as ViewSFEActivationPage };
