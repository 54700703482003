export const UserRoles = Object.freeze({
  SAM_CORE_ADMIN: "SaM Core Admin",
  USER_MANAGER: "User Manager",
  CONTENT_MANAGER: "Content Manager",
  CONTENT_ADMIN: "Content Admin",
  CONTENT_TARGETING_MANAGER: "Content Targeting Manager",
  ACTIVITY_MANAGER: "Activity Manager",
  MYCCA_CONTENT_ADMIN: "myCCA Content Admin",
  SAM_M_ADMIN: "SaM M Admin",
  CORE_RANGE_ADMIN: "Core Range Admin",
  CORE_RANGE_USER: "Core Range User",
  MY_DISPLAYS_ADMIN: "My Displays Admin",
  SEC_ADMIN: "SEC Admin",
  SAMA_ADMIN: "SAMA Admin",
  NEW_LEADS_ADMIN: "New Leads Admin",
  WHERE_IS_MY_DELIVERY: "Where Is My Delivery",
  RRC_EDIT_INVENTORY: "RRCEditInventory",
  RRC_VIEW: "RRCView"
});
