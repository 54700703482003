// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Box, Button, ButtonGroup, Grid, Typography, TextField } from "@material-ui/core";

import { UUID } from "../../helpers/uuid";

import { validateField } from "../../helpers/validation";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";


import { Loading } from "../../components/loading/Loading";

import AlertDialog from "../../components/alert-dialog/AlertDialog";
import PageContainer from "../../components/page-container/PageContainer";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import CustomCard from "../../components/custom-card/CustomCard";

import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

import { FormType } from "../../state/constants/FormType";

import styles from "./SaMConfigurationUploaderPage.module.scss";

// Models
import { ConfigurationModel } from "../../models/ConfigurationModel";

import {
    createConfiguration,
    updateConfiguration,
    getConfigurationById,
    createEmptyConfig
} from "../../state/actions/SECActions";

const SaMConfigurationUploaderPage = (props) => {

    // Utils
    const dispatch = useDispatch();

    const currentConfiguration = useSelector(
        (state) => state.ConfigurationReducer.currentConfiguration
    );

    const configurations = useSelector(
        (state) => state.ConfigurationReducer.configurations
    );

    const applicationsArray = ["SaM", "SaM Core"];
    const sites = ["1", "2"];

    const [loading, setLoading] = useState(false);
    const [configuration, setConfiguration] = useState({});
    const [uneditedConfiguration, setUneditedConfiguration] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingConfiguration, setIsExistingConfiguration] = useState(true);
    const [configurationId, setConfigurationId] = useState("");
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [formProperties, setFormProperties] = useState(ConfigurationModel);
    const [dataChanged, setDataChanged] = useState(false);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);

    useEffect(() => {
        setLoading(true);
    
        setIsExistingConfiguration(
          props.type != null && props.type === FormType.VIEW
        );
    
        if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
          dispatch(
            getLoggedInUserByEmail(() => {
              setLoggedInUserCallMade(true);
            })
          );
        } else {
          setLoggedInUserCallMade(true);
        }
      }, []);

      useEffect(() => {
        if (loggedInUserCallMade) {
          setLoading(false);
          setDataChanged(!dataChanged);
        }
      }, [loggedInUserCallMade]);

      useEffect(() => {
        let site = loggedInUser.territory === "au" ? 1 : 2
        if (props.type != null && props.type === FormType.VIEW && props.match.params.contentId != null && props.match.params.contentId !== undefined && props.match.params.contentId !== "new") {
            dispatch(
              getConfigurationById(site, props.match.params.contentId, () => {
                setConfigurationId(props.match.params.contentId);
              })
            );
          } else {
            dispatch(createEmptyConfig(site));
            setIsEditing(true);
            setConfigurationId(null);
          }
      }, [loggedInUserCallMade]);

      useEffect(() => {
        let localConfig = currentConfiguration;
        setConfiguration(localConfig);
      }, [currentConfiguration]);

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            dispatch(
                createConfiguration(loggedInUser.territory === "au" ? 1 : 2, createRequestBody(true), (success) => {
                    if (success) {
                        setIsEditing(false);
                        props.history.replace(`/sam-configuration-upload`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                updateConfiguration(loggedInUser.territory === "au" ? 1 : 2, configurationId, createRequestBody(false), () => {
                    setIsEditing(false);
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            setCancelEditingAlertOpen(true);
        } else {
            setIsEditing(false);

            if (!isExistingConfiguration) {
                props.history.push(`/sam-configuration-upload`);
            }
        }
    };

    /* Transform the configuration data into the form that is required by the backend */
    const createRequestBody = (isCreate) => {

        let requestBody = {
            Site: configuration.site,
            Application: configuration.application,
            Mainkey: configuration.mainkey,
            Subkey: configuration.subkey,
            Code: configuration.code,
            Value: configuration.value,
            ValueDescription: configuration.valueDescription,
        };

        if (isCreate) {
            requestBody.CreatedBy = loggedInUser.userId;
            requestBody.ModifiedBy = loggedInUser.userId;
        } else {
            requestBody.ModifiedBy = loggedInUser.userId;
            requestBody.Id = configurationId
        }

        return requestBody;
    };

    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, configuration);

        localData[name] = value;
        setConfiguration(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    const checkGenericField = (key, required) => {
        return validateField(
            required,
            configuration[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";
            
            if (key === "code" && isExistingConfiguration !== true) {
                errorMessage = checkGenericField(key, formProperties[key].required);

                if (errorMessage === "") {                   
                    let unqiueKey = configuration.site + configuration.application + configuration.mainkey + configuration.subkey + configuration.code
                    var alreadyExists = false

                    configurations.forEach( (config) => {
                        let configKey = config.site + config.application + config.mainkey + config.subkey + config.code

                        if (unqiueKey === configKey) {
                            alreadyExists = true;
                        }
                    })

                    if (alreadyExists) {
                        errorMessage = "This code already exists. Please use a unique code."
                    }
                }
            }
            else {
                errorMessage = checkGenericField(key, formProperties[key].required);
            }

            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            return true;
        }
    };

    const cancelEditingAlert = () => {
        return (
            <AlertDialog
                title={`Are you sure you want to stop editing?`}
                description={"This is permanent, and can't be undone."}
                options={[
                    {
                        label: "No",
                        action: () => setCancelEditingAlertOpen(false),
                    },
                    {
                        label: "Yes",
                        action: () => {
                            setIsEditing(false);
                            setConfiguration(uneditedConfiguration);
                            setCancelEditingAlertOpen(false);

                            if (!isExistingConfiguration) {
                                props.history.push(`/sam-configuration-upload`);
                            }
                        },
                    },
                ]}
                isOpen={cancelEditingAlertOpen}
            />
        );
    };

    const renderData = () => {
        return (
            <Fragment>
                <Box className={styles.contentGrid}>
                    <CustomCard title="Details" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <Autocomplete
                                    disablePortal
                                    id={formProperties.site.value}
                                    name={formProperties.site.value}
                                    disabled={!isEditing || configurationId !== null}
                                    options={sites}
                                    value={
                                        Object.keys(configuration).length > 0 &&
                                            configuration.site !== undefined
                                            ? configuration.site.toString()
                                            : ""
                                    }
                                    onChange={(e, value) => {
                                        handleInputChange(formProperties.site.value, parseInt(value));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            label={formProperties.site.label}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id={formProperties.application.value}
                                    name={formProperties.application.value}
                                    disabled={!isEditing || configurationId !== null}
                                    options={applicationsArray}
                                    value={
                                        Object.keys(configuration).length > 0 &&
                                            configuration.application !== undefined
                                            ? configuration.application
                                            : ""
                                    }
                                    onChange={(e, value) => {
                                        handleInputChange(formProperties.application.value, value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            fullWidth
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            size="small"
                                            label={formProperties.application.label}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={!isEditing || configurationId !== null}
                                    id={formProperties.mainkey.value}
                                    name={formProperties.mainkey.value}
                                    label={formProperties.mainkey.label}
                                    placeholder="Enter mainkey"
                                    value={
                                        Object.keys(configuration).length > 0 &&
                                            configuration.mainkey !== undefined
                                            ? configuration.mainkey
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.mainkey.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={formProperties.mainkey.errorMessage !== ""}
                                    helperText={formProperties.mainkey.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.subkey.value}
                                    name={formProperties.subkey.value}
                                    label={formProperties.subkey.label}
                                    placeholder="Enter subkey"
                                    value={
                                        Object.keys(configuration).length > 0 &&
                                            configuration.subkey !== undefined
                                            ? configuration.subkey
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.subkey.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={formProperties.subkey.errorMessage !== ""}
                                    helperText={formProperties.subkey.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    disabled={!isEditing || configurationId !== null }
                                    id={formProperties.code.value}
                                    name={formProperties.code.value}
                                    label={formProperties.code.label}
                                    placeholder="Enter code"
                                    value={
                                        Object.keys(configuration).length > 0 &&
                                            configuration.code !== undefined
                                            ? configuration.code
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.code.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={formProperties.code.errorMessage !== ""}
                                    helperText={formProperties.code.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.value.value}
                                    name={formProperties.value.value}
                                    label={formProperties.value.label}
                                    placeholder="Enter value"
                                    value={
                                        Object.keys(configuration).length > 0 &&
                                            configuration.value !== undefined
                                            ? configuration.value
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.value.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={formProperties.value.errorMessage !== ""}
                                    helperText={formProperties.value.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.valueDescription.value}
                                    name={formProperties.valueDescription.value}
                                    label={formProperties.valueDescription.label}
                                    placeholder="Enter value description"
                                    value={
                                        Object.keys(configuration).length > 0 &&
                                            configuration.valueDescription !== undefined
                                            ? configuration.valueDescription
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.valueDescription.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={formProperties.valueDescription.errorMessage !== ""}
                                    helperText={formProperties.valueDescription.errorMessage}
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </Box>
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Box>
            <HeaderBlock
                title={"SaM Configuration"}
                right={
                    <Grid container spacing={1} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    disabled={
                                        false
                                    }
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedConfiguration(configuration);
                                        } else if (isEditing && isExistingConfiguration) {
                                            handleUpdateClicked();
                                        } else {
                                            handleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingConfiguration
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />
            {cancelEditingAlert()}
            <PageContainer>
                {renderData()}
            </PageContainer>
        </Box>
    );

};

const hoc = withRouter(SaMConfigurationUploaderPage);

// EXPORT COMPONENT
export { hoc as SaMConfigurationUploaderPage };
