// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@material-ui/core";
import styles from "./ViewActivityGroupPage.module.scss";

// Actions
import {
  createReward,
  getRewardById,
  updateReward
} from "../../state/actions/ActivityActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Components
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import CustomCard from "../../components/custom-card/CustomCard";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";

// Models
import { ActivityRewardModel } from "../../models/ActivityRewardModel";

// Other
import { FormType } from "../../state/constants/FormType";
import { validateField } from "../../helpers/validation";

const ViewRewardPage = (props) => {
  const currentReward = useSelector(
    (state) => state.ActivityReducer.currentReward
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  //Setup State
  const [loading, setLoading] = useState(false);
  const [formProperties, setFormProperties] = useState(ActivityRewardModel);
  const [reward, setReward] = useState({});
  const [uneditedReward, setUneditedReward] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isExistingReward, setIsExistingReward] = useState(true);
  const [dataChanged, setDataChanged] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);

  const activityId = props.match.params.activityId;
  const activityGroupId = props.match.params.activityGroupId;
  const upperRange = props.match.params.upperRange;
  const activityName = new URLSearchParams(props.location.search).get(
    "activity"
  );

  const setUpData = () => {
    setIsExistingReward(props.type != null && props.type === FormType.VIEW);

    if (props.type != null && props.type === FormType.VIEW) {
      dispatch(
        getRewardById(activityId, upperRange, () => {
          setLoading(false);
        })
      );
    } else {
      dispatch(
        getRewardById(undefined, undefined, () => {
          setIsEditing(true);
          setLoading(false);
        })
      );
    }
  };

  //Use Effects
  useEffect(() => {
    setLoading(true);
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setUpData();
        })
      );
    } else {
      setUpData();
    }
  }, []);

  useEffect(() => {
    setReward(currentReward);
  }, [currentReward]);

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, reward);

    localData[name] = value;

    setReward(localData);

    if (!formEdited) {
      setFormEdited(true);
    }
  };

  /*
     Generic validation to perform on all field types
    */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      reward[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  /*
      Validate the form before it is submitted.
      Dates not only need to be checked for validity they also need to be compared with each other.
   */
  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }
    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      errorMessage = checkGenericField(key, formProperties[key].required);

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };

  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = (isCreate) => {
    let requestBody = {
      ActivityId: activityId,
      UpperRange: reward.upperRange,
      Value: reward.value,
      IsActive: reward.active
    };

    if (isCreate) {
      requestBody.CreatedBy = loggedInUser.userId;
      requestBody.ModifiedBy = loggedInUser.userId;
    } else {
      requestBody.ModifiedBy = loggedInUser.userId;
    }

    return requestBody;
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      const body = createRequestBody(true);

      dispatch(
        createReward(activityId, createRequestBody(true), (success, id) => {
          setIsEditing(false);
          props.history.push(
            `/activity-groups/${activityGroupId}/${activityId}`
          );
        })
      );
    }
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleUpdateClicked = () => {
    if (validateForm()) {
      const body = createRequestBody(true);
      dispatch(
        updateReward(activityId, upperRange, createRequestBody(false), () => {
          setIsEditing(false);
          props.history.push(
            `/activity-groups/${activityGroupId}/${activityId}/reward/${upperRange}?activity=${activityName}`
          );
        })
      );
    }
  };

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (formEdited) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);

      if (!isExistingReward) {
        props.history.push(`/activity-groups/${activityGroupId}/${activityId}`);
      }
    }
  };

  /*
Display the cancel editing alert dialog
*/
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false)
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setReward(uneditedReward);
              setCancelEditingAlertOpen(false);

              if (!isExistingReward) {
                props.history.push(
                  `/activity-groups/${activityGroupId}/${activityId}`
                );
              }
            }
          }
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  const renderForm = () => {
    return (
      <Fragment>
        <div className={styles.contentGrid}>
          <CustomCard title="Main Details" actionButton={[]}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  disabled={!isEditing || isExistingReward}
                  id={formProperties.upperRange.value}
                  name={formProperties.upperRange.value}
                  label={formProperties.upperRange.label}
                  placeholder="Enter upper range"
                  value={
                    reward !== undefined &&
                    Object.keys(reward).length > 0 &&
                    reward.upperRange !== undefined
                      ? reward.upperRange
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.upperRange.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.upperRange.errorMessage !== ""}
                  helperText={formProperties.upperRange.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  disabled={!isEditing}
                  id={formProperties.value.value}
                  name={formProperties.value.value}
                  label={formProperties.value.label}
                  placeholder="Enter weight"
                  value={
                    reward !== undefined &&
                    Object.keys(reward).length > 0 &&
                    reward.value !== undefined
                      ? reward.value
                      : ""
                  }
                  onChange={(event) =>
                    handleInputChange(
                      formProperties.value.value,
                      event.target.value
                    )
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={formProperties.value.errorMessage !== ""}
                  helperText={formProperties.value.errorMessage}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  disabled={!isEditing}
                  labelPlacement="start"
                  label={formProperties.active.label}
                  control={
                    <Checkbox
                      checked={
                        reward !== undefined && reward.active !== undefined
                          ? reward.active
                          : false
                      }
                      onChange={() =>
                        handleInputChange(
                          formProperties.active.value,
                          !reward.active
                        )
                      }
                      name={formProperties.active.value}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid>
          </CustomCard>
        </div>
      </Fragment>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <HeaderBlock
        title={`${activityName} - ${
          isExistingReward ? "Upper Range - " + reward.upperRange : "New Reward"
        }`}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <ButtonGroup>
                {isEditing ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleCancelEditButtonClicked(true)}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (!isEditing) {
                      setIsEditing(true);
                      setUneditedReward(reward);
                    } else if (isEditing && isExistingReward) {
                      handleUpdateClicked();
                    } else {
                      handleSubmitClicked();
                    }
                  }}
                >
                  {isEditing ? (isExistingReward ? "Done" : "Submit") : "Edit"}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />

      {cancelEditingAlert()}

      <PageContainer>{renderForm()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(ViewRewardPage);

// EXPORT COMPONENT
export { hoc as ViewRewardPage };
