import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Loading.module.scss'

export class Loading extends React.Component {
  render() {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }
}