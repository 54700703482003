
  import React,{useState, useEffect, useRef} from "react";
  import {
    Button,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Select,
  } from "@material-ui/core";
  import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
  import { createStyles } from '@material-ui/core/styles';
  
  const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: 1,
            minWidth: 200,
            maxWidth: 200,
        },
        dropdown: {
            marginBottom: 10,
        },
        delete: {
            display: "flex",
            float: 'left',
            marginTop: -1,
            maxWidth: 200
        }
  }),
);
  
  export function ImageAnnotateSelect(props) {
    // Styles
    const classes = useStyles();

    // Refs
    const typeInputLabel = useRef(null);
    const inputLabel = useRef(null);


    // State
    const [labelWidth, setLabelWidth] = useState(0);
    const [typeLabelWidth, setTypeLabelWidth] = useState(0);
  
    useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
      setTypeLabelWidth(typeInputLabel.current.offsetWidth);
    }, []);


  
    return (
        <div>
            <FormControl
                className={classes.formControl}
                disabled={props.disabled}
                variant="outlined"
                margin="dense"
                fullWidth={props.fullWidth}
                error={props.error}
                size={props.size}
            >
                <InputLabel ref={typeInputLabel}>
                    {props.label}
                </InputLabel>
        
                <Select
                    id={props.id}
                    className={classes.dropdown}
                    value={props.typeValue}
                    onChange={props.onChangeType}
                    input={<OutlinedInput labelWidth={typeLabelWidth} />}
                    autoWidth
                    >
                    {props.typeData.map((item) => {
                        return (
                        <MenuItem
                            key={item.key ? item.key : item}
                            value={item.key ? item.key : item}
                        >
                            {(item.value
                            ? item.value
                            : item != null
                            ? item
                            : "None"
                            ).toString()}
                        </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <FormControl
                className={classes.formControl}
                disabled={props.disabled}
                variant="outlined"
                margin="dense"
                fullWidth={props.fullWidth}
                error={props.error}
                size={props.size}
            >
                <InputLabel ref={inputLabel}>
                    {props.fieldLable}
                </InputLabel>
        
                <Select
                    id={props.id}
                    className={classes.dropdown}
                    value={props.fieldValue}
                    onChange={props.onChangeField}
                    input={<OutlinedInput labelWidth={labelWidth} />}
                    autoWidth
                    >
                    {props.fieldsData.map((item) => {
                        return (
                        <MenuItem
                            key={item.key ? item.key : item}
                            value={item.key ? item.key : item}
                        >
                            {(item.value
                            ? item.value
                            : item != null
                            ? item
                            : "None"
                            ).toString()}
                        </MenuItem>
                        );
                    })}
                </Select>
                <Button className={classes.delete} color="primary" variant="contained" onClick={props.onDelete}>
                    <DeleteForeverOutlinedIcon />
                </Button>
            </FormControl>
        </div>
    );
  }
  
  export default ImageAnnotateSelect;
