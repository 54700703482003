import React, {useEffect, useReducer} from 'react';
import { GenericDictionary } from '../../../../../../types/GenericDictionary';
import { ProgrammeAction, ProgrammePdf } from '../../reducers/programme-reducer';
import styles from './pdfsList.module.scss';
import listStyles from '../programmeDetailList.module.scss';
import { Box, Grid, Button, IconButton, TextField } from '@material-ui/core';
import { PictureAsPdf, RemoveCircleOutline, VisibilityOutlined } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

interface PdfsListProps {
  pdfs: GenericDictionary<ProgrammePdf>;
  handleUpdatePdfs: (action: ProgrammeAction) => void;
}

const pdfTemplate: ProgrammePdf = {
  dateAdded: '',
  name: '',
  url: '',
}

const newPdfReducer = (state: ProgrammePdf, action: {type: string; payload: {name: string, value:string}}) => {
  switch (action.type) {
    case 'HANDLE_TEXT_CHANGE': {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    }
    case 'RESET': {
      debugger
      return {
        ...pdfTemplate
      }
    }
    default:
      return state;
  }
}

function PdfsList(props: PdfsListProps) {
  const history = useHistory();

  const {
    pdfs,
    handleUpdatePdfs
  } = props;

  const theme = useTheme();
  
  const [newPdf, dispatch] = React.useReducer(newPdfReducer, pdfTemplate);
  const [urlError, setUrlError] = React.useState<null | string>(null);

  function validatePdfUrl(){
    if(newPdf.url.length > 0){
      new RegExp('^samcorecontent://|https://firebasestorage.googleapis.com').test(newPdf.url) ? setUrlError(null) : setUrlError('Must be a valid SAM Content URL (start with samcorecontent:// or https://firebasestorage.googleapis.com)');
    } else {
      setUrlError(null);
    }
  }

  function isValid(){
    return !!newPdf.name && !!newPdf.url && !urlError;
  }

  useEffect(() => {
    validatePdfUrl()
  },[newPdf])

  return (
    <div>
      <ul className={`${styles.pdfsList} ${listStyles.programmeDetailList}`}>
        <li className={styles.pdfsList_item}>
          {Object.entries(pdfs).length < 1 && <p style={{color: 'var(--secondary-font)'}}>No PDFs added</p>}
        </li>
        { Object.values(pdfs).map((pdf, index) => {
          let pdfLink;
          const isSamContentUrl = new RegExp('^samcorecontent://').test(pdf.url);

          if(isSamContentUrl){
            const decodedPdfId = decodeURIComponent(pdf.url || '');
            const searchTerm = decodedPdfId.split('/').pop(); // remove the file extension

            pdfLink = `/content-upload?search=${searchTerm}`;
          } else {
            pdfLink = pdf.url;
          }

          return (
          <li className={styles.pdfsList_item} key={pdf.url}>
            <div className={styles.pdfsList_itemDetails}>
              <Box color='text.secondary' alignSelf='center'><PictureAsPdf style={{display: 'flex'}} /></Box>
              <a href={pdfLink} target="_blank" rel="noreferrer" className={styles.pdfsList_itemDetailsLink} style={{color: theme.palette.secondary.main}}>{pdf.name}</a>
            </div>
            <div className={styles.pdfsList_itemActions}>
              <IconButton component='a' href={pdfLink} target="_blank" rel="noreferrer"><VisibilityOutlined fontSize='small' color="secondary"/></IconButton>
              <IconButton onClick={(e) => handleUpdatePdfs({type: 'REMOVE_PDF', payload: {value: index}})}><RemoveCircleOutline fontSize='small' color="primary" /></IconButton>
            </div>
          </li>
        )}) }
      </ul>

      <div className={listStyles.programmeDetailList_extension}>
        <Box display='flex' style={{gap: '0.5rem'}}>
          <Box flex={0.75}>
            <TextField required value={newPdf.name} size='small' label="Name" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='name' fullWidth />
          </Box>
          <Box flex={1}>
            <TextField required value={newPdf.url} size='small' label="Sam Content URL" variant="outlined" onChange={ e => dispatch({ type: 'HANDLE_TEXT_CHANGE', payload: {name: e.target.name, value: e.target.value } })} name='url' fullWidth />
            { urlError !== null && <small style={{color: 'var(--primary)'}}>{ urlError }</small> }
          </Box>
          <Box flex={0}>
            <Button variant="outlined" color="secondary" disabled={!isValid()} onClick={(e) => {
               handleUpdatePdfs({type:'ADD_PDF', payload: { value: {...newPdf}}});
               dispatch({type: 'RESET', payload: {name: '', value: ''}});
               }} >Add</Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default PdfsList;