// React
import React from "react";

// UI and Styling
import { Box, IconButton, makeStyles } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

// Other
import { getIcon } from "../../icon/icon";

const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: 8,
        "&:hover": {
          borderRadius: 8
        }
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  greyBorder: {
    borderColor: "rgba(0, 0, 0, 0.23)"
  },
  icon: {
    height: "16px"
  }
}));

export const OutlinedIconButton = ({ clickHandler, icon, display = true }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        border={1}
        borderRadius={8}
        className={classes.greyBorder}
        style={{
          display: display ? "inline-flex" : "none"
        }}
      >
        <IconButton onClick={clickHandler}>
          {getIcon(icon, classes.icon)}
        </IconButton>
      </Box>
    </MuiThemeProvider>
  );
};
