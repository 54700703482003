// React
import React from "react";

// UI and Styling
import {
  Checkbox,
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField
} from "@material-ui/core";
import styles from "./ListableStopsItem.module.scss";

// Other
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: "1px solid #333"
  }
}));

// COMPONENT
export default function ListableStopsItem(props) {
  const classes = useStyles();

  return (
    <ListItem disableGutters={true} className={classes.listItem}>
      {props.toolbarShowing && (
        <div className={styles.checkbox}>
          <Checkbox
            onClick={() => props.handleCheck(props.id)}
            checked={props.selectedItems.hasOwnProperty(props.id)}
          />
        </div>
      )}
      <ListItemText>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth={true}
              disabled={true}
              label="Stop Number"
              type="number"
              margin="dense"
              value={props.data.stopNumber}
              variant="outlined"
              className={styles.textField}
              onChange={(event) => {
                props.handleValueChange("StopNumber", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth={true}
              disabled={props.disabled}
              label="Minutes"
              type="number"
              margin="dense"
              value={props.data.minutes}
              variant="outlined"
              className={styles.textField}
              onChange={(event) => {
                props.handleValueChange("Minutes", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth={true}
              disabled={props.disabled}
              label="Seconds"
              type="number"
              margin="dense"
              value={props.data.seconds}
              variant="outlined"
              className={styles.textField}
              onChange={(event) => {
                props.handleValueChange("Seconds", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth={true}
              disabled={props.disabled}
              label="Milliseconds"
              type="number"
              margin="dense"
              value={props.data.milliseconds}
              variant="outlined"
              className={styles.textField}
              onChange={(event) => {
                props.handleValueChange("Milliseconds", event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </ListItemText>
      {props.toolbarShowing && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => props.handleDelete([props.data.id])}
            edge="end"
            aria-label="delete"
          >
            {getIcon("trash.svg", styles.icon)}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
