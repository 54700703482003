// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  TextField
} from "@material-ui/core";
import styles from "./MyccaTypesListPage.module.scss";

// Actions
import { getAllMyccaLookups } from "../../../state/actions/MyCCAResourceImageManagerActions";

// Components
import ExportExcel from "../../../components/export/ExportExcel";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import PageContainer from "../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../../components/table/Table";

// Other
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  }
}));

const MyccaTypesListPage = (props) => {
  const classes = useStyles();

  const lookups = useSelector(
    (state) => state.MyccaResourceImageManagerReducer.lookups
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [
    getAllMyccaLookupsCallMade,
    setgetAllMyccaLookupsCallMade
  ] = useState(false);

  useEffect(() => {
    setLoading(true);    //Get all dropdown lookup data

    dispatch(
      getAllMyccaLookups(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    setupData();
  }, [lookups]);

  const setupData = () => {
    const data = createData();

    setOriginalData(data);
    setSearchedData(data);
  };

  const createData = () => {
    return (lookups.data != undefined && lookups.data.types != undefined && Object.keys(lookups.data.types).length != 0) ? lookups.data.types.map((myccatype) => {
      return {
        id: myccatype.code,
        name: myccatype.value
      };
    }) : undefined;
  };

  const search = (searchText) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const foundData = originalData.filter((data) => {
      return (
        (data.id && data.id.toString().includes(text)) ||
        (data.name && data.name.toLowerCase().includes(text))
      );
    });

    setSearchedData(foundData);
  };

  const createHeaders = () => {
    return [
      {
        id: "id",
        clickable: true,
        action: {
          path: "/mycca-types/{id}",
          identifier: "id"
        },
        label: "Id",
        align: "left"
      },
      {
        id: "name",
        align: "left",
        label: "Name"
      }
    ];
  };

  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);
    const data =
      searchedData === undefined || searchedData.length === 0 || Object.keys(searchedData).length === 0 ? (
        <PagePlaceholder
          image={getIcon("frame-expand.svg")}
          text="No MyCCA Types found."
        />
      ) : (
          <SimpleTable
            handleSelection={null}
            isSelectable={false}
            dataId={"code"}
            cells={cells}
            headers={headers}
            rows={searchedData}
          />
        );
    return data;
  };

  return (
    <div>
      <HeaderBlock
        title="MyccaTypes"
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item xs={5}>
              <TextField
                id="outlined-name"
                label="Search MyccaTypes"
                value={searchText}
                onChange={(event) => search(event.target.value)}
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs>
              <ExportExcel
                csvData={searchedData}
                fileName="lookups"
                classes={classes.rightMargin}
              />
            </Grid>
            <Grid item xs>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("mycca-types/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  New Type
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(MyccaTypesListPage);

// EXPORT COMPONENT
export { hoc as MyccaTypesListPage };
