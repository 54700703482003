import Axios from "axios";
import fileDownload from "js-file-download";
import jwt from "jsonwebtoken";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";


import {
  GET_DISPLAY_GROUPS,
  GET_DISPLAY_GROUP,
  GET_DISPLAY_ACTIVITY_BY_ID,
  GET_DISPLAY_ACTIVITIES_BY_ID,
  GET_PRODUCT_FILTER_RULES,
  UPSERT_PRODUCT_FILTERS,
  RESET_DISPLAY_GROUP_ACTIVITES,
  DOWNLOAD_MYDISPLAYS_LIST,
  GET_ACTIVITY_GROUP_ID_FOR_SITE,
  ADD_TARGET_TO_DISPLAY_GROUP,
} from "../constants/action-types";

const FileDownload = require("js-file-download");
const API_CONFIG = getApiConfig();

//Get displayGroups

export const GetDisplayGroups = (siteId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/GetDisplayGroupsBySite?site=${siteId}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: GET_DISPLAY_GROUPS,
      payload: response.data,
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const GetDisplayGroupById = (id, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/GetDisplayGroup?id=${id}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: GET_DISPLAY_GROUP,
      payload: response.data,
    });

    callback(response.data);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const ResetDisplayGroupState = (callback) => async (dispatch) => {
  dispatch({
    type: RESET_DISPLAY_GROUP_ACTIVITES,
    payload: null,
  });
  callback(true);
};

export const UpsertDisplayGroup = (data, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/UpsertDisplayGroup`,
      {
        method: "post",
        data: data,
      }
    );

    dispatch({
      type: UpsertDisplayGroup,
      payload: response.data,
    });

    callback(true, response.data);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const getActivityById = (id, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/GetActivityById?Id=${id}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: GET_DISPLAY_ACTIVITY_BY_ID,
      payload: response.data,
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

export const getActivitiesById =
  (activityIds, callback) => async (dispatch) => {
    let promises = [];

    activityIds.forEach((id) => {
      promises.push(
        adalApiFetch(
          Axios,
          `${API_CONFIG.ACTIVITIES}/GetActivityById?Id=${id}`,
          {
            method: "get",
          }
        )
      );
    });

    Promise.all(promises)
      .then((responses) => {
        let activityResult = [];
        responses.forEach((response) => {
          activityResult.push(response.data);
        });

        dispatch({
          type: GET_DISPLAY_ACTIVITIES_BY_ID,
          payload: activityResult,
        });
        callback(true);
      })
      .catch((error) => {
        console.group(`Exception Occured: ${error.message}`);
        callback(false);
      });
  };

export const getProductFilterRules = (id, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/GetProductFilters?id=${id}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: GET_PRODUCT_FILTER_RULES,
      payload: response.data,
    });

    callback(true);
  } catch (error) {
    console.log(error.message);
    callback(false);
  }
};

export const upsertProductFilters = (id, data, callback) => (dispatch) => {
  try {
    const response = adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/UpsertProductFilters?id=${id}`,
      {
        method: "post",
        data: data,
      }
    );

      //debugger;
      dispatch({
        type: UPSERT_PRODUCT_FILTERS,
        payload: response.data,
      });

      callback(true);
    } catch (error) {
      console.log(error);
      callback(false);
    }
  };

export const getMyDisplaysAsCSV = (siteId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      `${API_CONFIG.ACTIVITIES}/DisplayGroupExportCSV?Site=${siteId}`,
      {
        method: "get",
      }
    );

    dispatch({
      type: DOWNLOAD_MYDISPLAYS_LIST,
      payload: {
        data: FileDownload(
          response.data,
          `myDisplaysMaintenance${siteId === 1 ? "AUS" : "NZ"}.csv`
        ),
      },
    });

    callback(true);
  } catch (error) {
    callback(false);
    console.log(error);
  }
};

export const getActivityGroupIdForSite =
  (site, callback) => async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.ACTIVITIES}/MydisplaysActivityGroupIdForSite?site=${site}`,
        {
          method: "get",
        }
      );

      dispatch({
        type: GET_ACTIVITY_GROUP_ID_FOR_SITE,
        payload: response.data,
      });

      callback(true);
    } catch (error) {
      if (error.response.status !== 404) {
        console.log("Exception Occured: ", error);
      }
      callback(false);
    }
  };

export const addTargetToDisplay =
  (targetId, displayId, data, callback) => async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        `${API_CONFIG.ACTIVITIES}/AddTargetGroupToDisplayGroup?activityTargetId=${targetId}&displayGroupId=${displayId}`,
        {
          method: "post",
          data: data,
        }
      );

      dispatch({
        type: ADD_TARGET_TO_DISPLAY_GROUP,
        payload: response.data,
      });

      callback(true);
    } catch (error) {
      console.log(error);
      callback(false);
    }
  };