import React, { useEffect, useState, useMemo, ChangeEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import DateFnsUtils from '@date-io/date-fns';
import { isValid } from "date-fns";
import { isEqual, cloneDeep } from 'lodash';
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getMappedTerritory } from '../../state/reducers/UserReducer';

import { StylesProvider } from "@material-ui/core";
import styles from "./styles/EditPlanModal.module.scss";
import { FixMeLater } from "../../types/FixMeLaterType";
import { MyDisplaysActivity, PlannedDisplay, SKU } from "../../state/reducers/MyDisplaysPlanningReducer";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const API_CONFIG = getApiConfig();

/**
 * Edit or create a Display Plan
 * 
 */

interface ValidProduct {
  SKU: string;
  Name: string
}

interface ValidLocationsAndSKUs {
  Locations?: Array<string>
  Products?: Array<ValidProduct>
  HasRules?: boolean
}

export default function EditPlanModal(props: FixMeLater) {
  const {
    open,
    isNew,
    planData,
    handleSave,
    handleClose,
    productList
  } = props;

  function setDefaults(){
    const defaultFormData = cloneDeep(planData);

    // Sets default dates if this is a new plan or no start/end dates are set
    if(defaultFormData.myDisplaysPlanning.StartDate === null){
      defaultFormData.myDisplaysPlanning.StartDate = new Date().toISOString();
    }
    if(defaultFormData.myDisplaysPlanning.EndDate === null){
      defaultFormData.myDisplaysPlanning.EndDate = new Date().toISOString();
    }
    return defaultFormData;
  }

  const userTerritory = useSelector((state: FixMeLater) => state.UserReducer.loggedInUser.territory);
  const userSite = getMappedTerritory(userTerritory);
  const [validDisplayTypeActivities, setValidDisplayTypeActivities] = useState<Array<MyDisplaysActivity>>([]);
  const [validDisplayTypeActivitiesByDate, setValidDisplayTypeActivitiesByDate] = useState<Array<MyDisplaysActivity>>([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [formData, setFormData] = useState(setDefaults());
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [dateErrorMessage, setDateErrorMessage] = useState<string | null>(null);
  const [validLocationsAndSKUs, setValidLocationsAndSKUs] = useState<ValidLocationsAndSKUs>({});
  const [isLoadingLocations, setIsLoadingLocations] = useState<boolean>(false);
  const customerValueInput = useRef<HTMLInputElement>(null);
  
  const formattedProductList = useMemo<Array<ValidProduct>>(()=>{
    return productList.map((product: any) => {
      return {
        SKU: product.Number,
        Name: product.Description
      }
    })
  }, [productList]);

  useEffect(() => {
    if(formData.myDisplaysPlanning.CustomerRule && formData.myDisplaysPlanning.Value && userSite) {
      getValidDisplayTypeActivities(formData.myDisplaysPlanning.CustomerRule,formData.myDisplaysPlanning.Value, userSite);
    }

    setValidDisplayTypeActivitiesByDate([...filterValidDisplayTypeActivitiesByDate()]);
  }, []);
  
  // Filter the activities by start and end date.
  useEffect(() => {
    setValidDisplayTypeActivitiesByDate([...filterValidDisplayTypeActivitiesByDate()]);
  }, [validDisplayTypeActivities, formData.myDisplaysPlanning.StartDate, formData.myDisplaysPlanning.EndDate]);

  // Reset the display activity when dates change
  useEffect(()=>{
    const displayInValidDisplays = validDisplayTypeActivitiesByDate.find((display: MyDisplaysActivity) => display.ActivityId === formData.myDisplaysPlanning.DisplayTypeActivityId && display.Name === formData.myDisplaysPlanning.DisplayType);
    if(validDisplayTypeActivitiesByDate.length > 0 && !displayInValidDisplays){
      const newFormData = cloneDeep(formData);
      newFormData.myDisplaysPlanning.DisplayType = null;
      newFormData.myDisplaysPlanning.DisplayTypeActivityId = null;
      setFormData({...newFormData});
    }
  }, [formData.myDisplaysPlanning.StartDate, formData.myDisplaysPlanning.EndDate]);

  useEffect(() => {
    let formHasChanged = false;
    if(!isEqual(formData, planData)) {
      formHasChanged = true;
    };
    setFormHasChanged(formHasChanged);
  }, [formData]);

  // Validate Form
  // TODO: This is pretty rudimentary, will look to improve with a validation library
  useEffect(() => {
    if(
      formData.myDisplaysPlanning.Description &&
      formData.myDisplaysPlanning.CustomerRule &&
      formData.myDisplaysPlanning.Value &&
      formData.myDisplaysPlanning.DisplayTypeActivityId &&
      formData.myDisplaysPlanning.PlannedDisplayType &&
      formData.SKUs.filter((sku: SKU) => {
        return (sku.MaterialNumber !== '') && sku.Quantity
      }).length === formData.SKUs.length
    ){
      setFormIsValid(true)
    } else {
      setFormIsValid(false)
    }
    validateDates();
  }, [formData]);


  // Get Valid Activities
  useEffect(()=>{
    getValidDisplayTypeActivities(formData.myDisplaysPlanning.CustomerRule,formData.myDisplaysPlanning.Value, userSite);
  }, [formData.myDisplaysPlanning.CustomerRule, formData.myDisplaysPlanning.Value, userSite]);

  // Get Valid Locations and SKUs
  useEffect(()=>{
    if(!formData.myDisplaysPlanning.DisplayTypeActivityId){
      setValidLocationsAndSKUs({})
      return;
    }
    setIsLoadingLocations(true)
    try {
      adalApiFetch(
        Axios,
        `${API_CONFIG.TARGETING}/ValidLocationsAndSKUs?ActivityId=${formData.myDisplaysPlanning.DisplayTypeActivityId}`,
        { method: "get", }
        ).then((res: any)=>{
          setValidLocationsAndSKUs(res.data)
          setIsLoadingLocations(false)
        }
        );
      } catch (error) {
      setIsLoadingLocations(false)
      console.log(error);
    }

  }, [ formData.myDisplaysPlanning.CustomerRule,
       formData.myDisplaysPlanning.Value,
       formData.myDisplaysPlanning.DisplayTypeActivityId ]);

  async function getValidDisplayTypeActivities(rule: string | undefined, value: string | undefined, site: 1 | 2 | null | undefined) {
    if(!rule || !value || !site) {
      // There's an empty field, reset the options
      setValidDisplayTypeActivities([]);
      setIsLoadingActivities(false);
      return
    }

    setIsLoadingActivities(true);
    try {
      const response = await adalApiFetch(
          Axios, `${API_CONFIG.TARGETING}/ValidDisplayTypeActivities`,
          {
              method: "post",
              data: {
                "Rule": rule,
                "Value": value,
                "Site": site,
              }
          }
      );
      setValidDisplayTypeActivities(response.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingActivities(false);
  };

  function filterValidDisplayTypeActivitiesByDate(){
    if(!formData.myDisplaysPlanning.StartDate
      || !formData.myDisplaysPlanning.EndDate
      || !formData.myDisplaysPlanning.Value
      || !formData.myDisplaysPlanning.CustomerRule){
      return [];
    }
    const planStart = new Date(Date.parse(formData.myDisplaysPlanning.StartDate));
    const planEnd = new Date(Date.parse(formData.myDisplaysPlanning.EndDate));
    const displayTypeActivities = [...validDisplayTypeActivities];

    displayTypeActivities.filter(activity => {
      const activityStart = new Date(Date.parse(activity.StartDate));
      const activityEnd = new Date(Date.parse(activity.EndDate));
      return activityStart >= planStart && activityEnd <= planEnd;
    });
    return displayTypeActivities;
  }

  function handleTextInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string | undefined; value: unknown; }>) {
    const { name, value } = e.target;
    if(!name || !value) return;
    const updatedFormData = {...formData};
    updatedFormData.myDisplaysPlanning[name] = value;
    setFormData(updatedFormData);
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>){
    const {name, checked} = e.target;
    const updatedFormData = {...formData};
    updatedFormData.myDisplaysPlanning[name] = checked;
    setFormData(updatedFormData);
  }

  function handleCurrencyInputChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = e.target;
    if(!name || !value) return;
    const valueAsCurrency = Number(parseFloat(value)); // Yay javascript!
    const updatedFormData = {...formData};
    updatedFormData.myDisplaysPlanning[name] = valueAsCurrency;
    setFormData(updatedFormData);
  }

  function handleDateChange(dateValue: MaterialUiPickersDate, name: string) {
    if(!dateValue || !isValid(dateValue)) return;
    setDateErrorMessage(null)
    const updatedFormData = {...formData};
    updatedFormData.myDisplaysPlanning[name] = dateValue.toISOString();
    setFormData(updatedFormData);
  }

  function validateDates(){
    const startDate = new Date(Date.parse(formData.myDisplaysPlanning.StartDate));
    const endDate = new Date(Date.parse(formData.myDisplaysPlanning.EndDate));
    if(endDate < startDate){
      setFormIsValid(false);
      setDateErrorMessage('Start date must be before end date');
    } else {
      setDateErrorMessage(null);
    }
  }

  function handleAddMaterial(){
    setFormData({
      ...formData,
      SKUs: [
        ...formData.SKUs,
        { MaterialNumber: '', Quantity: 0}
      ]
    });
  }

  function handleRemoveMaterial(index: number) {
      const newSKUs = [...formData.SKUs]
      newSKUs.splice(index, 1);
      setFormData({...formData, SKUs: newSKUs});
  }

  type SkuProperty = "MaterialNumber" | "Quantity";
  function handleMaterialChange(value: string | null, property: SkuProperty, index: number) {
    if(property === "Quantity" && !value) value = "0";
    if(property === "MaterialNumber" && !value) value = "";

    const updatedSKUs = [...formData.SKUs];
    updatedSKUs[index][property] = property === 'Quantity' ? Number(value) : value; // Quantity should be a number

    setFormData({
      ...formData,
      SKUs: updatedSKUs
    });
  }

  function handlePlannedDisplayTypeChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
    if(isNew){
      // If this is a new plan, CustomerConfirmed should default to true if the planned display type is compulsory, otherwise null.
      const { value } = e.target;
      const updatedFormData = {...formData};
      const isCompulsory = value === 'Compulsory';
  
      updatedFormData.myDisplaysPlanning.CustomerConfirmed = isCompulsory ? true : null;
      setFormData(updatedFormData);
    }

    handleTextInputChange(e);
  }

  function handleDisplayTypeChange(value: string) {
    if(!value) return;
    const parsedDisplayData = JSON.parse(value);
    const updatedFormData = {...formData};
    updatedFormData.myDisplaysPlanning = {
      ...formData.myDisplaysPlanning,
      DisplayType: parsedDisplayData.Name,
      DisplayTypeActivityId: parsedDisplayData.ActivityId,
    }
    setFormData(updatedFormData);
  }

  function resolveCurrentDisplayTypeValue(){
    if(!formData.myDisplaysPlanning.DisplayTypeActivityId || !formData.myDisplaysPlanning.DisplayType) return '';
    const resolvedValue = JSON.stringify({ActivityId: formData.myDisplaysPlanning.DisplayTypeActivityId, Name: formData.myDisplaysPlanning.DisplayType});
    return resolvedValue;
  }

  function renderDisplayTypes(){
    if(!validDisplayTypeActivitiesByDate || validDisplayTypeActivitiesByDate.length === 0) return null;
    return validDisplayTypeActivitiesByDate.map((activity: MyDisplaysActivity) => {
      return (
        <MenuItem data-value={JSON.stringify({ActivityId: activity.ActivityId, Name: activity.Name})} key={`${activity.ActivityId}-${activity.Name}`} value={JSON.stringify({ActivityId: activity.ActivityId, Name: activity.Name})}>{activity.Name}</MenuItem>
      );
    });
  }

  function renderLocations(){
    if(!validLocationsAndSKUs.Locations?.length) return null;

    return validLocationsAndSKUs.Locations.map((location: string) => 
      <MenuItem value={location} key={location}>{location}</MenuItem>
    )
  }

  function renderSKUs(SKUs: Array<SKU>){
    if(!SKUs || SKUs.length === 0) return null;
    
    const validProductsList = () => {
      if(!validLocationsAndSKUs) return [];

      let products: ValidProduct[] = [];
      if(validLocationsAndSKUs.HasRules !== true){
        // Use the full list of products if there are no rules specified
        products = formattedProductList;
      }
      else {
        if(!validLocationsAndSKUs.Products || !validLocationsAndSKUs.Products.length) return [];
        // Use the list of specified products
        products = validLocationsAndSKUs.Products;
      }
      
      // Filter out products that are already selected
      const filteredProducts = products.filter((product: ValidProduct) => {
        const inData = formData.SKUs.some((sku: SKU) => sku.MaterialNumber === product.SKU);
        return !inData;
      });

      return filteredProducts;
    }

    return SKUs.map((sku : SKU, index: number) => {
      return (
        <li key={`${sku.MaterialNumber}`}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
                <Autocomplete
                  // Don't disable the field if there's only 1 product and it has a value. Avoids it being disabled if it's the only option.
                  disabled={validProductsList().length === 0 && (!sku.MaterialNumber && !sku.Quantity)}
                  id={`material-sku-${sku.MaterialNumber}`}
                  onChange={(event: any, newValue: ValidProduct | null) => { handleMaterialChange(newValue?.SKU || null, 'MaterialNumber', index); }}
                  options={validProductsList()}
                  getOptionLabel={(option) => `${option.SKU} ${option.Name}` || ''}
                  blurOnSelect
                  fullWidth
                  getOptionSelected={(option, value) => option?.SKU === value?.SKU}
                  value={formattedProductList.length ? formattedProductList.find((product: ValidProduct) => product.SKU === sku.MaterialNumber) : null}
                  renderOption={(option) => {
                    return (
                    <>
                      <span style={{opacity: 0.65, width: '4em', marginRight: '0.5em' }}>{option.SKU}</span> {option.Name}
                    </>
                  )}}
                  renderInput={(params) => (
                    <TextField label="Material"
                      {...params} variant="outlined" margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      value={`${sku.MaterialNumber} ${validLocationsAndSKUs && validLocationsAndSKUs.Products ? validLocationsAndSKUs.Products.find((product: ValidProduct) => product.SKU === sku.MaterialNumber)?.Name : ''}`}
                    />
                  )}
                />
            </Grid>
            <Grid item sm={6}>
              <TextField
                // Don't disable the field if there's only 1 product and it has a value. Avoids it being disabled if it's the only option.
                disabled={validProductsList().length === 0  && (!sku.MaterialNumber && !sku.Quantity)}
                label="Quantity"
                required
                name={`material-qty-${sku.MaterialNumber}`}
                id={`material-qty-${sku.MaterialNumber}`}
                variant="outlined"
                margin="dense"
                fullWidth
                type="number"
                defaultValue={sku.Quantity || ''}
                inputProps={{step: "10"}}
                onChange={e => { handleMaterialChange(e.target.value, 'Quantity', index) }}
              />
            </Grid>
          </Grid>
          <IconButton aria-label="Remove Material" onClick={() => handleRemoveMaterial(index)}><RemoveCircleIcon color="primary" /></IconButton>
        </li>
      )
    })
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" disableBackdropClick>
      <StylesProvider injectFirst>
        <MuiDialogTitle className={styles.titleBar}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{ isNew ? 'New Plan' : 'Edit Plan' }</Typography>
            <Box ml="auto">
              <IconButton aria-label="Close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </MuiDialogTitle>

        <MuiDialogContent dividers>
          <form className={styles.editPlanForm} onSubmit={()=>{handleSave(formData, planData)}} autoComplete="off">
            <TextField
              fullWidth
              required
              label="Description"
              name="Description"
              placeholder="Description here"
              variant="outlined"
              autoFocus={isNew}
              InputLabelProps={{ shrink: true, }}
              defaultValue={formData.myDisplaysPlanning.Description || ''}
              onChange={e => { handleTextInputChange(e) }}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel htmlFor="CustomerRule">Customer Rule</InputLabel>
                  <Select name="CustomerRule"
                      value={formData.myDisplaysPlanning.CustomerRule || ''} 
                      label="Customer Rule"
                      id="CustomerRule"
                      onChange={(e) => {
                        handleTextInputChange(e);
                        if(customerValueInput && customerValueInput.current) {
                          // Reset the Customer Value when the rule is changed.
                          setFormData({
                            ...formData,
                            myDisplaysPlanning: {
                              ...formData.myDisplaysPlanning,
                              Value: null
                            }
                          })
                          customerValueInput.current.value = ''
                        };
                      }}
                    >
                    <MenuItem value={'CustomerNumber'}>Customer Number</MenuItem>
                    <MenuItem value={'TradeChannel'}>Trade Channel</MenuItem>
                    <MenuItem value={'SubTradeChannel'}>Sub Trade Channel</MenuItem>
                    <MenuItem value={'TradingChain'}>Trading Chain</MenuItem>
                    <MenuItem value={'BusinessOwner'}>Business Owner ID</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  inputRef={customerValueInput}
                  label="Customer Value(s)"
                  name="Value"
                  placeholder="Values..."
                  variant="outlined" 
                  InputLabelProps={{ shrink: true, }}
                  defaultValue={formData.myDisplaysPlanning.Value || ''} 
                  helperText="Comma separated values"
                  onBlur={e => { handleTextInputChange(e) }} // on blur to make sure we're not triggering API calls on each input event (eg. keyup)
                  />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} style={{marginTop: '-20px'}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth
                    required
                    autoOk={true}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start Date"
                    minDateMessage="Date must be in the future."
                    initialFocusedDate={new Date(Date.now())}
                    value={formData.myDisplaysPlanning.StartDate}
                    disablePast={true}
                    onChange={(value : MaterialUiPickersDate) => { handleDateChange(value, 'StartDate') }}
                    onAccept={validateDates}
                    KeyboardButtonProps={{
                      'aria-label': 'Start date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} style={{marginTop: '-20px'}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth
                    required
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="End Date"
                    minDateMessage="Date must be in the future."
                    value={formData.myDisplaysPlanning.EndDate}
                    disablePast={true}
                    onChange={(value : MaterialUiPickersDate) => { handleDateChange(value, 'EndDate') }}
                    onAccept={validateDates}
                    KeyboardButtonProps={{
                      'aria-label': 'End date',
                    }}
                    />
                </MuiPickersUtilsProvider>
              </Grid>
              {dateErrorMessage &&
                <Grid item><p style={{color:'var(--primary)', marginTop: '0'}}>{dateErrorMessage}</p></Grid>
              }
            </Grid>

            <TextField
              select
              name="PlannedDisplayType"
              required
              variant="outlined"
              value={formData.myDisplaysPlanning.PlannedDisplayType || ''} 
              label="Planned Display Type"
              id="plannedDisplayType"
              onChange={e => { handlePlannedDisplayTypeChange(e) }}
              >
              <MenuItem value={'Compulsory'}>Compulsory</MenuItem>
              <MenuItem value={'Over and Above'}>Over and Above</MenuItem>
            </TextField>
            
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={8}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel htmlFor="displayTypeActivityId">Display Type</InputLabel>
                  <Box display="flex" alignItems="center" style={{gap:'1em'}}>
                    <Select
                      name="DisplayTypeActivityId"
                      value={resolveCurrentDisplayTypeValue()}
                      label="Display Type"
                      style={{flex: 1}}
                      id="displayTypeActivityId"
                      disabled={ !validDisplayTypeActivitiesByDate.length || isLoadingActivities }
                      onChange={e => { handleDisplayTypeChange(e.target.value as string) }}
                      >
                      { renderDisplayTypes() }
                    </Select>
                    { isLoadingActivities && <CircularProgress size={20} /> }
                  </Box>
                </FormControl>
              </Grid>
            
              <Grid item xs={12} sm={4}>
                <FormGroup>
                  <Box pl={2}>
                    <FormControlLabel
                      label="Allow Display Type Changes"
                      control={
                        <Checkbox 
                          checked={formData.myDisplaysPlanning.AllowDisplayTypeChanges}
                          onChange={e => { handleCheckboxChange(e) }}
                          name="AllowDisplayTypeChanges"
                        />
                      }
                    />
                  </Box>
                </FormGroup>
              </Grid>
            </Grid>

            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="location">Location</InputLabel>
              <Box display="flex" alignItems="center" style={{gap:'1em'}}>
                <Select
                  name="LocationValue"
                  value={formData.myDisplaysPlanning.LocationValue || ""}
                  label="Location"
                  id="location"
                  style={{flex:1}}
                  disabled={!(validLocationsAndSKUs.Locations && validLocationsAndSKUs.Locations?.length > 0) || isLoadingLocations}
                  onChange={e => { handleTextInputChange(e) }}
                >
                  { renderLocations() }
                </Select>
                { isLoadingLocations && <CircularProgress size={20} /> }
              </Box>
            </FormControl>

            <Paper style={{padding: '0.5rem 1rem 1rem'}} elevation={0} variant="outlined">
              { validLocationsAndSKUs && (validLocationsAndSKUs.HasRules && validLocationsAndSKUs.Products?.length === 0) && formData.myDisplaysPlanning.DisplayTypeActivityId &&
                <Box display="flex" style={{gap: '0.5em'}} alignItems="center" py={0.5}>
                  <ErrorOutlineIcon />
                  <Typography color="textSecondary" variant="body2" style={{padding: '0.5em 0'}}>Couldn't find any valid materials for this display.<br/> If the product filters have recently changed or this is a new display, please wait until tomorrow.</Typography>
                </Box>
              }
              <ul className={styles.skusList}>
                {renderSKUs(formData.SKUs)}
              </ul>
              <Button variant="outlined" startIcon={<AddCircleIcon />} onClick={handleAddMaterial} disabled={!formData.myDisplaysPlanning.DisplayTypeActivityId}>Add Material</Button>
            </Paper>

            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField
                  fullWidth 
                  label="Co-Op Cost"
                  name="CoopCost"
                  type="number"
                  defaultValue={formData.myDisplaysPlanning.CoopCost || ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={e => { handleCurrencyInputChange(e) }}
                  />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  fullWidth 
                  label="Recommended Promotional Price"
                  name="RecommendedPromotionalPrice"
                  type="number"
                  defaultValue={formData.myDisplaysPlanning.RecommendedPromotionalPrice || ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  onChange={e => { handleCurrencyInputChange(e) }}
                />
              </Grid>
            </Grid>
            
            <TextField
              label="Notes"
              name="Notes"
              variant="outlined"
              fullWidth
              multiline
              defaultValue={formData.myDisplaysPlanning.Notes || ""}
              onChange={e => { handleTextInputChange(e) }}
            />
          </form>
        </MuiDialogContent>

        <MuiDialogActions>
          { (!formIsValid && formHasChanged) &&
            <span style={{color:'var(--primary)', margin: '0 auto 0 1em'}}>Please ensure all required fields are completed</span>
          }
          <Button disabled={!formHasChanged || !formIsValid} color="primary" onClick={()=>{ handleSave(formData, planData)}}>
            { isNew ? 'Create Plan' : 'Save Changes' }
          </Button>
        </MuiDialogActions>
      </StylesProvider>
    </Dialog>
  )
}