import {
  GET_RESOURCE_IMAGES,
  CLEAR_RESOURCE_IMAGES,
  GET_RESOURCE_IMAGE_URLS,
  SET_CURRENT_RESOURCE_IMAGE
} from "../constants/action-types";

import Axios from "axios";

import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
const API_CONFIG = getApiConfig();

/* 
Get all resource images 
*/
export const getAllResources = (contentType, territory, callback) => {
  return async (dispatch) => {
    try {
      const res = await adalApiFetch(
        Axios, `${API_CONFIG.RETRIEVAL}/GetAllResources?TargetType=${contentType}&Territory=${territory}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_RESOURCE_IMAGES,
        payload: res.data
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

export const downloadResourceImageBlob = (
  territory,
  size,
  targetType,
  targetName,
  callback
) => (dispatch) => {
  adalApiFetch(
    Axios, `${API_CONFIG.RETRIEVAL}/DownloadResource?Territory=${territory}&Size=${size}&TargetType=${targetType}&TargetName=${targetName}&isExact=false`,
    {
      method: "get",
      responseType: "arraybuffer"
    }
  )
    .then((res) => {
      const blob = new Buffer(res.data, "binary").toString("base64");

      callback(blob);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error.message}`);
      callback(null);
    });
};

export const getResourceUrls = (
  territory,
  targetType,
  targetName,
  callback
) => (dispatch) => {
  adalApiFetch(
    Axios, `${API_CONFIG.RETRIEVAL}/GetResourceUrls?Territory=${territory}&TargetType=${targetType}&TargetName=${targetName}`,
    {
      method: "get"
    }
  )
    .then((res) => {
      dispatch({
        type: GET_RESOURCE_IMAGE_URLS,
        payload: res.data
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    });
};

export const setCurrentResourceImage = (item, callback) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_RESOURCE_IMAGE,
    payload: item
  });

  callback(true);
};

export const clearResourceImages = (callback) => (dispatch) => {
  dispatch({
    type: CLEAR_RESOURCE_IMAGES,
    payload: null
  });

  callback(true);
};
