import {
  compareAsc,
  isAfter,
  isValid,
  isWithinInterval,
  parseISO,
  addDays,
  subDays
} from "date-fns";

/*
    Helpers used to validate form data
*/

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export const validateField = (
  required,
  value,
  type,
  fieldLabel,
  maxLength = 0
) => {
  if (type === "file" && !value) {
    return "Please add files before submitting";
  }

  if (type === "csv" && value.length === 0) {
    return "Please upload a csv file before submitting";
  }

  // Ensure any timestamps are not null and that they are a valid date
  if (type === "date" && required) {
    var asDate = new Date(value);
    if (value === null || (value === undefined && required)) {
      return `${fieldLabel} is required`;
    } else if (!isValid(asDate)) {
      return `${fieldLabel} is not a valid date`;
    }

    return "";
  }

  //Ensure required arrays have at least one value
  if (type === "array" && required) {
    if (value === undefined || value.length <= 0) {
      return `${fieldLabel} must contain at least one item`;
    }

    return "";
  }

  //Check to ensure strings are set
  if (type === "string" && required) {
    if (value === null || value === undefined || value === "") {
      return `${fieldLabel} is required`;
    }

    return "";
  }

  if (type === "number" && required) {
    if (value === null || value === undefined || value === "") {
      return `${fieldLabel} is required`;
    }

    return "";
  }

  // Ensure that if there is a maxLength value set that the value is not longer than this
  if (required && maxLength > 0 && value.length > maxLength) {
    return `${fieldLabel} should not be any longer than ${maxLength}`;
  }

  // Ensure all email addresses are valid
  if (type === "email" && !validEmailRegex.test(value)) {
    return "A valid email address is required";
  }

  // Ensure all select dropdowns have been changed from their default
  if (
    required &&
    type === "select" &&
    (value === undefined || value === "default" || value === "")
  ) {
    return `${fieldLabel} is required`;
  }

  return "";
};

// Compare two dates to ensure that date2 is after date1
export const compareDates = (
  date1,
  date2,
  dateOneLabel,
  dateTwoLabel,
  checkReverse
) => {
  let pd1 = parseISO(date1);
  let pd2 = parseISO(date2);
  
  if (compareAsc(pd1, pd2) === 0) {
    return `${dateTwoLabel} should be after the ${dateOneLabel}`;
  }
  if (!checkReverse && compareAsc(pd1, pd2) === 1) {
    return `${dateOneLabel} should be before the ${dateTwoLabel}`;
  }
  if (checkReverse && compareAsc(pd2, pd1) === -1) {
    return `${dateTwoLabel} should be after the ${dateOneLabel}`;
  }

  return "";
};

//Check to see if the child start date and the child end dates sit between the parent dates
export const compareDatesAreWithinRange = (
  parentStartDate,
  parentEndDate,
  childStartDate,
  childEndDate
) => {
  // Need to check one day less the campaign start date and one day past the campaign end date
  // as the compareDatesAreWithinRange returns false if the dates being compared are the same as the campaign dates
  const startDateOk = isWithinInterval(new Date(childStartDate), {
    start: subDays(new Date(parentStartDate), 1),
    end: addDays(new Date(parentEndDate), 1)
  });

  const endDateOk = isWithinInterval(new Date(childEndDate), {
    start: subDays(new Date(parentStartDate), 1),
    end: addDays(new Date(parentEndDate), 1)
  });

  return startDateOk && endDateOk;
};

// Check to see that a date is after the dateToCompare
export const dateIsAfter = (dateToCompare, date) => {
  return isAfter(dateToCompare, date)
}

// To validate an images size it needs to be loaded into memory
const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();

    img.onload = () =>
      resolve({ imageWidth: img.width, imageHeight: img.height });
    img.onerror = reject;
    img.src = src;
  });
};

// Check an image meets the correct size requirements
export const validateImageSize = async (fileToUpload, minimumImageSize) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.readAsDataURL(fileToUpload[0]);
    reader.onload = async function (e) {
      const result = await loadImage(e.target.result);

      if (
        result.imageWidth < minimumImageSize ||
        result.imageHeight !== result.imageWidth
      ) {
        resolve(
          `Material images must be square and be at least ${minimumImageSize}px's in width and height.`
        );
      } else {
        resolve("");
      }
    };
  });
};

export const validateStopSeconds = (stops) => {
  let errorMessage = "";

  stops.forEach((stop) => {
    if (stop.Seconds > 59) {
      errorMessage =
        "Please ensure all stops have their seconds value set to less than 60.";
    }
  });

  return errorMessage;
};

export const validateBeacons = (beacons, stops) => {
  let errorMessage = "";

  beacons.forEach((beacon) => {
    if (beacon.StepContentId === "" || beacon.StopNumber <= 0) {
      errorMessage =
        "Please ensure all beacons have a stop number and content item selected.";
    }

    const stop = stops.find((s) => s.StopNumber === beacon.StopNumber);

    if (stop === undefined) {
      errorMessage = "Please ensure all beacons have a stop number selected.";
    }
  });

  return errorMessage;
};
