// React
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Data and Setup
import Axios from "axios";
import packageJson from "../package.json";

// UI and Styling
import "./App.scss";
import "./index.css";
import "./Theme.scss";

// Actions
import { updateVersion } from "./state/actions/VersionActions";
import { getRolesForLoggedInUser } from "./state/actions/RoleActions";
import { getLoggedInUserByEmail } from "./state/actions/UserActions";

// Pages
import AuthPage from "./pages/auth/AuthPage";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/login/LoginPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import { ResourceImageDataListPage } from "./pages/resource-images/Uploader/ResourceImageDataListPage";
import { ResourceImageUploaderPage } from "./pages/resource-images/Uploader/ResourceImageUploaderPage";
import { SaMCNewLeadsUploaderPage } from "./pages/SaMCNewLeads/Uploader/SaMCNewLeadsUploaderPage";
import { ListSaMCNewLeadsContentPage } from "./pages/SaMCNewLeads/List/ListSaMCNewLeadsContentPage";
import { ImageMigrationPage } from "./pages/migration/ImageMigrationPage";
import { TargetedContentMigrationPage } from "./pages/migration/TargetedContentMigration";
import { LinkMigrationPage } from "./pages/migration/LinkMigrationPage";
import { SaMCNewLeadsMigrationPage } from "./pages/migration/SaMCNewLeadsMigrationPage";
import { MigrationCompletionPage } from "./pages/migration/MigrationCompletionPage";
import { ListRolesPage } from "./pages/roles/list/ListRolesPage";
import { ViewRolePage } from "./pages/roles/view/ViewRolePage";
import { UserPage } from "./pages/users/user/UserPage";
import { UsersPage } from "./pages/users/UsersPage";
import { ListCampaignsPage } from "./pages/targeting/campaigns/ListCampaignsPage";
import { ViewCampaignPage } from "./pages/targeting/campaigns/ViewCampaignPage";
import { ListTargetGroupsPage } from "./pages/targeting/target-groups/ListTargetGroupsPage";
import { TargetGroupPage } from "./pages/targeting/target-groups/TargetGroupPage";
import { ListTargetContentPage } from "./pages/targeting/targeted-content/ListTargetContentPage";
import { TargetedContentPage } from "./pages/targeting/targeted-content/TargetedContentPage";
import ResourceImageManagerPage from "./pages/resource-images/ResourceImageManagerPage";
import { ViewResourceImagePage } from "./pages/resource-images/view/ViewResourceImagePage";
import { ListThreeDimensionalPOSPage } from "./pages/three-dimensional-POS/ListThreeDimensionalPOSPage";
import { ThreeDimensionalPOSPage } from "./pages/three-dimensional-POS/ThreeDimensionalPOSPage";
import { ApplicationsListPage } from "./pages/targeting/maintenance/ApplicationsListPage";
import { SaMaProgrammesListPage } from "./pages/targeting/maintenance/sama-programmes/SaMaProgrammesListPage";
import { SaMaProgrammeDetailPage } from "./pages/targeting/maintenance/sama-programmes/SaMaProgrammeDetailPage";
import { AgreementSpecificationListPage } from "./pages/targeting/maintenance/AgreementSpecificationListPage";
import { ViewAgreementSpecificationPage } from "./pages/targeting/maintenance/ViewAgreementSpecificationPage";
import { ProgrammeDiscrepancyCheck } from "pages/targeting/maintenance/programmeDiscrepancyCheck/ProgrammeDiscrepancyCheck";
import { ViewApplicationPage } from "./pages/targeting/maintenance/ViewApplicationPage";
import { ActivityGroupsListPage } from "./pages/activities/ActivityGroupsListPage";
import { ViewActivityGroupPage } from "./pages/activities/ViewActivityGroupPage";
import { ViewActivityPage } from "./pages/activities/ViewActivityPage";
import { ViewChoicePage } from "./pages/activities/ViewChoicePage";
import { ViewRewardPage } from "./pages/activities/ViewRewardPage";
import { ViewTargetPage } from "./pages/activities/ViewTargetPage";
import { ViewPPPTargetPage } from "./pages/activities/ViewPPPTargetPage";
import { SFEActivationListPage } from "./pages/sfe-activation/SFEActivationListPage";
import { ViewSFEActivationPage } from "./pages/sfe-activation/ViewSFEActivationPage";
import { PPPMigrationPage } from "./pages/migration/PPPMigration";
import { CategoriesListPage } from "./pages/targeting/maintenance/CategoriesListPage";
import { ViewCategoryPage } from "./pages/targeting/maintenance/ViewCategoryPage";
import { MyccaResourceImageManagerPage } from "./pages/mycca-resource-images/MyccaResourceImageManagerPage";
import { MyccaResourceImageUploaderPage } from "./pages/mycca-resource-images/Uploader/MyccaResourceImageUploaderPage";
import { MyCCAContentPage } from "./pages/mycca-resource-images/MyCCAContentPage";
import { MyccaContentUploader } from "./pages/mycca-resource-images/MyCCAContentUploader";
import { MyccaTypesListPage } from "./pages/mycca-resource-images/maintenance/MyccaTypesListPage";
import { ViewMyccaTypePage } from "./pages/mycca-resource-images/maintenance/ViewMyccaTypePage";
import { NewLeadsMap } from "./pages/newLeads/Map";
import { BulkUpdateLeads } from "./pages/newLeads/BulkUpdateLeads";

// Components
import PrimarySearchAppBar from "./components/layout/SplitView";
import { PrivateRoute } from "./components/private-route/PrivateRoute";
import CustomizedSnackbars from "./components/snackbar/Snackbar";

// Other
import { UserRoles } from "./state/constants/UserRoles";
import { FormType } from "./state/constants/FormType.js";
import { CoreRangeList } from "./pages/core-range/CoreRangeList";
import { CoreRangeView } from "./pages/core-range/CoreRangeView";
import { CustomerDoorUploadPage } from "./pages/core-range/Uploader/CustomerDoorUploadPage";
import { SearchPage } from "./pages/wmd/SearchPage";
import { Map } from "pages/wmd/Map";

// view rrc order
import { ViewrrcOrdersPage } from "./pages/view-rrc-orders/ViewrrcOrdersPage";
import { MaintainrrcInventory } from "./pages/view-rrc-orders/MaintainrrcInventory"

// SEC
import { SECCustomerUploadPage } from "./pages/sec/SECCustomerUploadPage";
import { SECPointsUploadPage } from "./pages/sec/SECPointsUploadPage";
import { SECOrderPeriodsUploadPage } from "./pages/sec/SECOrderPeriodsUploadPage";
import { SaMConfigurationUploaderPage } from "./pages/sec/SaMConfigurationUploaderPage";

/**
 * My Displays
 */
// My Displays - Uload
import { StockweightTargetsUploadPage } from "./pages/myDisplaysUpload/stockweightTargetsUploadPage";

// My Displays - Planning
import { MyDisplaysPlanningPage } from "./pages/myDisplaysPlanning/MyDisplaysPlanningPage";

// My Displays - Maintenance
import { MyDisplaysList } from "./pages/myDisplaysMaintenance/myDisplaysList/MyDisplaysList";
import { MyDisplaysRedView } from "./pages/myDisplaysMaintenance/myDisplaysRedView/MyDisplaysRedView";
import { MakeNewMyDisplaysRedView } from "./pages/myDisplaysMaintenance/myDisplaysRedView/MakeNewMyDisplaysRedView";
import { MyDisplayTypeView } from "./pages/myDisplaysMaintenance/myDisplayTypeView/MyDisplayTypeView";
import { ChoicePage } from "./pages/myDisplaysMaintenance/ChoicePage/ChoicePage";
import { NewChoicePage } from "./pages/myDisplaysMaintenance/NewChoicePage/NewChoicePage";
import { RewardPage } from "./pages/myDisplaysMaintenance/RewardPage/RewardPage";
import { NewRewardPage } from "./pages/myDisplaysMaintenance/NewRewardPage/NewRewardPage";
import { DisplayTypePage } from "./pages/myDisplaysMaintenance/DisplayTypePage/DisplayTypePage";
import { ActivityTargetPage } from "./pages/myDisplaysMaintenance/ActivityTargetPage/ActivityTargetPage";
import { NewDisplayActivity } from "./pages/myDisplaysMaintenance/NewDisplayActivity/NewDisplayActivity";
import { NewDisplay } from "./pages/myDisplaysMaintenance/NewDisplay/NewDisplay";
import { Loading } from "./components/loading/Loading";

const App = () => {
  const version = useSelector((state) => state.VersionReducer.version);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [requestCount, setRequestCount] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [webRoutes, setWebRoutes] = useState(null);

  useEffect(() => {
    setLoading(true);
    const currenVersion = packageJson.version;

    setupInterceptor();

    if (version !== currenVersion) {
      dispatch(updateVersion(currenVersion));
    }

    if (!loggedInUser || !Object.keys(loggedInUser).length) {
      dispatch(getLoggedInUserByEmail(), () => {
        setLoading(false);
      });
    }

    dispatch(
      getRolesForLoggedInUser(() => {
        setLoading(false);
      })
    );
  }, []);

  useEffect(() => {
    if (loggedInUserRoles) {
      renderRoutes();
    }
  }, [loggedInUserRoles]);

  const setupInterceptor = () => {
    // Append the access token to all requests being sent...
    Axios.interceptors.request.use((config) => {
      setRequestCount(requestCount + 1);

      return config;
    });

    Axios.interceptors.response.use(
      (response) => {

        if (response.data === false || response.data === 'false') {
          // Sometimes we get a 200 response with a false body, indicating an error
          setRequestCount(requestCount - 1);
          setError("Something went wrong.");
          return Promise.reject(error);
        }

        setRequestCount(requestCount - 1);
        setSuccess("Success!");

        // Do something with response data...
        return response;
      },
      (error) => {
        var errorMessage = "Error";
        if (error.response && error.response.statusText) {
          errorMessage += `: ${error.response.statusText}`;

          if (error.response.data) {
            if (Array.isArray(error.response.data)) {
              error.response.data.forEach((error) => {
                if (error.error_reason) {
                  errorMessage += ` - ${error.error_reason}`;
                }
              });
            } else {
              if (error.response.data.error_message) {
                errorMessage += ` - ${error.response.data.error_message}`;
              }
            }
          }
          if (error.response.data.errors) {
            error.response.data.errors.forEach((error) => {
              errorMessage += ` - ${error}`;
            });
          }
        }
        setRequestCount(requestCount - 1);
        setError(errorMessage);

        // Do something with response error...
        return Promise.reject(error);
      }
    );
  };

  const renderRoutes = () => {
    const {
      SAM_CORE_ADMIN,
      CORE_RANGE_USER,
      CORE_RANGE_ADMIN,
      USER_MANAGER,
      CONTENT_MANAGER,
      CONTENT_ADMIN,
      CONTENT_TARGETING_MANAGER,
      ACTIVITY_MANAGER,
      MYCCA_CONTENT_ADMIN,
      MY_DISPLAYS_ADMIN,
      NEW_LEADS_ADMIN,
      SEC_ADMIN,
      SAMA_ADMIN,
      RRC_VIEW
    } = UserRoles;

    //User enters directly to wmd page doesn't requires roles after logged in
    if (!loggedInUserRoles.length && (window.location.pathname.startsWith('/wmd') || window.location.pathname.startsWith('/map'))) {
      setWebRoutes(
        <Switch>
          <PrivateRoute
            path="/wmd"
            component={SearchPage}
            exact
            type={FormType.VIEW}
          />
          <PrivateRoute
            path="/map"
            component={Map}
            exact
            type={FormType.VIEW}
          />
        </Switch>)
    } else {
      if (Object.keys(loggedInUserRoles).length > 0) {
        setWebRoutes(
          <Switch>
            {/* Public Routes */}
            <Route exact path="/" component={HomePage} />
            <Route path="/login" component={LoginPage} />
            <PrivateRoute path="/auth" component={AuthPage} />

            {/* Protected Routes */}
            <PrivateRoute
              path="/users"
              component={UsersPage}
              exact
              roles={[SAM_CORE_ADMIN, USER_MANAGER]}
              userRoles={loggedInUserRoles}
            />
            <PrivateRoute
              path="/users/:userId"
              component={UserPage}
              roles={[SAM_CORE_ADMIN, USER_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/roles"
              component={ListRolesPage}
              exact
              roles={[SAM_CORE_ADMIN, USER_MANAGER]}
              userRoles={loggedInUserRoles}
            />
            <PrivateRoute
              path="/roles/:roleId"
              component={ViewRolePage}
              roles={[SAM_CORE_ADMIN, USER_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            {/* Samcore image stuff */}
            <PrivateRoute
              path="/image-upload"
              component={ResourceImageDataListPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
            />
            <PrivateRoute
              path="/image-upload/:resourceImageId"
              component={ResourceImageUploaderPage}
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            {/* myCCA cms */}
            <PrivateRoute
              path="/mycca-content"
              component={MyccaResourceImageManagerPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
            />
            <PrivateRoute
              path="/mycca-content/:contentId"
              component={MyCCAContentPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />
            <PrivateRoute
              path="/mycca-image-upload"
              component={MyccaContentUploader}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />
            <PrivateRoute
              path="/mycca-image-upload/:resourceImageId"
              component={MyccaResourceImageUploaderPage}
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/image-migration"
              component={ImageMigrationPage}
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/content-migration"
              component={TargetedContentMigrationPage}
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/link-migration"
              component={LinkMigrationPage}
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/samc-newleads-migration"
              component={SaMCNewLeadsMigrationPage}
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/post-migration"
              component={MigrationCompletionPage}
              roles={[
                SAM_CORE_ADMIN,
                CONTENT_TARGETING_MANAGER,
                CONTENT_MANAGER,
                CONTENT_ADMIN,
              ]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/image-manager"
              component={ResourceImageManagerPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/image-manager/:imageId"
              component={ViewResourceImagePage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_MANAGER, CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/target-groups"
              component={ListTargetGroupsPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/target-groups/new"
              component={TargetGroupPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/target-groups/:targetGroupId"
              component={TargetGroupPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/samc-newleads-upload"
              component={ListSaMCNewLeadsContentPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER, CONTENT_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />
            <PrivateRoute
              path="/csamc-newleads-upload/new"
              component={SaMCNewLeadsUploaderPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER, CONTENT_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/samc-newleads-upload/:contentId"
              component={SaMCNewLeadsUploaderPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER, CONTENT_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/content-upload"
              component={ListTargetContentPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER, CONTENT_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/content-upload/new"
              component={TargetedContentPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER, CONTENT_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/content-upload/:targetContentId"
              component={TargetedContentPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER, CONTENT_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/campaigns"
              component={ListCampaignsPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/campaigns/new"
              component={ViewCampaignPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/campaigns/:campaignId"
              component={ViewCampaignPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/three-dimensional-pos/"
              component={ListThreeDimensionalPOSPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/three-dimensional-pos/:contentId"
              component={ThreeDimensionalPOSPage}
              exact
              roles={[SAM_CORE_ADMIN, CONTENT_TARGETING_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            {/* Activities */}
            <PrivateRoute
              path="/activity-groups"
              component={ActivityGroupsListPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/activity-groups/new"
              component={ViewActivityGroupPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId"
              component={ViewActivityGroupPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/new"
              component={ViewActivityPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId"
              component={ViewActivityPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/target/new"
              component={ViewPPPTargetPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/target/:targetId"
              component={ViewPPPTargetPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/target"
              component={[ViewActivityGroupPage, CONTENT_TARGETING_MANAGER]}
              exact
              roles={[SAM_CORE_ADMIN, ACTIVITY_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/choice/new"
              component={ViewChoicePage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/choice/:choiceId"
              component={ViewChoicePage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/choice"
              component={ViewActivityPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/target/new"
              component={ViewTargetPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/target/:targetId"
              component={ViewTargetPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/target"
              component={ViewActivityPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/reward/new"
              component={ViewRewardPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/reward/:upperRange"
              component={ViewRewardPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/activity-groups/:activityGroupId/:activityId/reward"
              component={ViewActivityPage}
              exact
              roles={[
                SAM_CORE_ADMIN,
                ACTIVITY_MANAGER,
                CONTENT_TARGETING_MANAGER,
              ]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            {/* SFE Activation Calculation Assignment */}
            <PrivateRoute
              path="/sfe-activation"
              component={SFEActivationListPage}
              exact
              roles={[SAM_CORE_ADMIN, ACTIVITY_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            {/* SFE Activation Calculation Assignment */}
            <PrivateRoute
              path="/sfe-activation/new"
              component={ViewSFEActivationPage}
              exact
              roles={[SAM_CORE_ADMIN, ACTIVITY_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            {/* SFE Activation Calculation Assignment */}
            <PrivateRoute
              path="/sfe-activation/:sfeActivationId"
              component={ViewSFEActivationPage}
              exact
              roles={[SAM_CORE_ADMIN, ACTIVITY_MANAGER]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/ppp-mass-upload"
              component={PPPMigrationPage}
              exact
              roles={[SAM_CORE_ADMIN, ACTIVITY_MANAGER]}
              userRoles={loggedInUserRoles}
            />

            {/* Maintenance Screens */}
            <PrivateRoute
              path="/categories"
              component={CategoriesListPage}
              exact
              roles={[SAM_CORE_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/categories/new"
              component={ViewCategoryPage}
              exact
              roles={[SAM_CORE_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/categories/:categoryId"
              component={ViewCategoryPage}
              exact
              roles={[SAM_CORE_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/applications"
              component={ApplicationsListPage}
              exact
              roles={[SAM_CORE_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/applications/new"
              component={ViewApplicationPage}
              exact
              roles={[SAM_CORE_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/applications/:ApplicationId"
              component={ViewApplicationPage}
              exact
              roles={[SAM_CORE_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/samaprogrammes"
              component={SaMaProgrammesListPage}
              exact
              roles={[SAMA_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/samaprogrammes/:ProgrammeId"
              component={SaMaProgrammeDetailPage}
              exact
              roles={[SAMA_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/agreementspecifications"
              component={AgreementSpecificationListPage}
              exact
              roles={[SAMA_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/agreementspecifications/new"
              component={ViewAgreementSpecificationPage}
              exact
              roles={[SAMA_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/agreementspecifications/:AgreementId"
              component={ViewAgreementSpecificationPage}
              exact
              roles={[SAMA_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/programme-discrepancy-check"
              component={ProgrammeDiscrepancyCheck}
              exact
              roles={[SAMA_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/core-range"
              component={CoreRangeList}
              exact
              roles={[SAM_CORE_ADMIN, CORE_RANGE_USER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/wmd"
              component={SearchPage}
              exact
              // roles={[SAM_CORE_ADMIN]}
              // userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/map"
              component={Map}
              exact
              // roles={[SAM_CORE_ADMIN]}
              // userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            {/* 
          <PrivateRoute
            path="/core-range/new"
            component={CoreRangeView}
            exact
            roles={[SAM_CORE_ADMIN, CORE_RANGE_USER]}
            userRoles={loggedInUserRoles}
          /> */}

            <PrivateRoute
              path="/view-regional-returns"
              component={ViewrrcOrdersPage}
              exact
              roles={[RRC_VIEW]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/maintain-regional-returns-inventory"
              component={MaintainrrcInventory}
              exact
              roles={[RRC_VIEW]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/core-range/:coreRangeId"
              component={CoreRangeView}
              // component={CoreRangePage}
              exact
              roles={[SAM_CORE_ADMIN, CORE_RANGE_USER]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path={"/customer-door-uploader"}
              component={CustomerDoorUploadPage}
              exact
              roles={[CORE_RANGE_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path={"/sec-customer-uploader"}
              component={SECCustomerUploadPage}
              exact
              roles={[SEC_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path={"/sec-points-uploader"}
              component={SECPointsUploadPage}
              exact
              roles={[SEC_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path={"/stockweight-targets-upload"}
              component={StockweightTargetsUploadPage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path={"/sam-configuration-upload"}
              component={SECOrderPeriodsUploadPage}
              exact
              roles={[SEC_ADMIN]}
              userRoles={loggedInUserRoles}
            />

            <PrivateRoute
              path="/sam-configuration-upload/new"
              component={SaMConfigurationUploaderPage}
              exact
              roles={[SEC_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/sam-configuration-upload/:contentId"
              component={SaMConfigurationUploaderPage}
              exact
              roles={[SEC_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/mycca-types"
              component={MyccaTypesListPage}
              exact
              roles={[SAM_CORE_ADMIN, MYCCA_CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/mycca-types/new"
              component={ViewMyccaTypePage}
              exact
              roles={[SAM_CORE_ADMIN, MYCCA_CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/mycca-types/:myccaTypeId"
              component={ViewMyccaTypePage}
              exact
              roles={[SAM_CORE_ADMIN, MYCCA_CONTENT_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            {/* My Displays */}

            <PrivateRoute
              path="/my-displays-planning"
              component={MyDisplaysPlanningPage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance"
              component={MyDisplaysList}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/new"
              component={MakeNewMyDisplaysRedView}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId"
              component={MyDisplaysRedView}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/target"
              component={MyDisplaysRedView}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/target/new"
              component={ActivityTargetPage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/target/:targetId"
              component={ActivityTargetPage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/new"
              component={NewDisplay}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex"
              component={MyDisplayTypeView}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/new"
              component={NewDisplayActivity}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.CREATE}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/:ActivityId"
              component={DisplayTypePage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/:ActivityId/choice"
              component={DisplayTypePage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/:ActivityId/choice/new"
              component={NewChoicePage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/:ActivityId/choice/:ChoiceName"
              component={ChoicePage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/:ActivityId/reward"
              component={DisplayTypePage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/:ActivityId/reward/new"
              component={NewRewardPage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/my-displays-maintenance/:DisplayGroupId/:DisplayIndex/:ActivityId/reward/:RewardId"
              component={RewardPage}
              exact
              roles={[SAM_CORE_ADMIN, MY_DISPLAYS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/new-leads-map"
              component={NewLeadsMap}
              exact
              roles={[SAM_CORE_ADMIN, NEW_LEADS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <PrivateRoute
              path="/bulk-update-leads"
              component={BulkUpdateLeads}
              exact
              roles={[SAM_CORE_ADMIN, NEW_LEADS_ADMIN]}
              userRoles={loggedInUserRoles}
              type={FormType.VIEW}
            />

            <Route path="*" component={NotFoundPage} />
          </Switch>
        );
      }
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div
        className={"content theme " + (true ? "theme--dark" : "theme--default")}
      >
        <Router basename="/">
          <PrimarySearchAppBar isLoading={requestCount > 0}>
            <CustomizedSnackbars
              open={requestCount > 0}
              showLoading
              message={"Loading..."}
            />
            <CustomizedSnackbars
              autoHideDuration={1000}
              handleClose={() => {
                setSuccess(null);
              }}
              open={success != null}
              variant="success"
              message={success}
            />
            <CustomizedSnackbars
              handleClose={() => {
                setError(null);
              }}
              open={error != null}
              variant="error"
              message={error}
            />
            {webRoutes}
          </PrimarySearchAppBar>
        </Router>
      </div>
    );
  }
};

const hoc = App;

// EXPORT COMPONENT

export default hoc;
