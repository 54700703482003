import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
  CREATE_CAMPAIGN,
  GET_ALL_CAMPAIGNS,
  GET_CURRENT_CAMPAIGN,
  GET_CAMPAIGNS_BY_TARGET_GROUP_ID,
  UPDATE_CURRENT_CAMPAIGN,
  RESET_CURRENT_CAMPAIGN,
  MASS_UPLOAD_CONTENT,
  GET_ALL_APPLICATIONS,
  GET_APPLICATION_BY_ID
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

/*
    Get Campaigns
*/
export const getCampaigns = (callback) => (dispatch) => {
  adalApiFetch(
    Axios, `${API_CONFIG.TARGETING}/GetAllCampaigns`,
    {
      method: "get"
    }
  )
    .then((res) => {
      dispatch({
        type: GET_ALL_CAMPAIGNS,
        payload: {
          data: res.data
        }
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    });
};

/*
    Get an individual campaign
*/
export const getCampaignById = (id, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING}/GetCampaignById?CampaignId=${id}`,
        {
          method: "get"
        }
      );
      dispatch({
        type: GET_CURRENT_CAMPAIGN,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
    Get a list of campaigns related to a specific target group
*/
export const getCampaignsByTargetGroupId = (id, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING}/GetCampaignsByTargetGroupId?TargetGroupId=${id}`,
        {
          method: "get"
        }
      );

      dispatch({
        type: GET_CAMPAIGNS_BY_TARGET_GROUP_ID,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

/*
  Mass Create Campaign and Target Groups
*/
export const MassCreation = (massModel, files, callback) => {
  return async (dispatch) => {
    let failures = [];
    var csvOnly = true;

    const headers = { "Content-Type": "application/json" };
    const formData = new FormData();

    files.forEach((file) => {
      formData.append(file.value.name, file.value);
    });

    if (files.length >= 1) { csvOnly = false };

    let uploadResponse = { data: { succeededUploads: [""], failedUploads: [""] }, status: 200 }
    if (!csvOnly) {
      // Upload the files into temp blob storage.
      uploadResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/MigrationUploader`,
        {
          method: "post",
          header: {
            "Content-Type": "multipart/form-data"
          },
          data: formData
        }
      ).catch(function (error) {
        failures.push(...formData);
      });
      if (uploadResponse.data.length === 0) {
        failures.push(...formData);
        throw `Error: Error uploading files to temp storage`
      }
    }

    if (uploadResponse.status === 200) {
      // Then upload files into *actual* blob storage. We have to do these 1 by 1 as we cannot include form data in the same request as a image.

      var migrationMapping = massModel.map(function (item) {
        return {
          territory: item.Campaign.Territory,
          path: item.Content.FileName,
          output: item.Campaign.Name
        }
      });

      const migrationResponse = await adalApiFetch(
        Axios, `${API_CONFIG.UPLOAD}/MassMigrateResources?TargetType=file&csvOnly=${csvOnly}`,
        {
          method: "post",
          header: {
            "Content-Type": "application/json"
          },
          data: migrationMapping
        }
      ).catch(function (error) {
        failures.push(...migrationMapping);
      });
      if (migrationResponse.status === 200 && Object.keys(migrationResponse.data.succeededUploads).length >= 1) {
        var succeededUploads = migrationResponse.data.succeededUploads;
        failures.push(...migrationResponse.data.failedUploads);

        // Append the new paths onto the content model
        massModel.forEach((model) => {
          if (succeededUploads[model.Campaign.Name]) {
            model.Content.ContentPath = succeededUploads[model.Campaign.Name][0]?.path;
          } else {
            const index = massModel.indexOf(model);
            if (index > -1) {
              massModel.splice(index, 1);
            }
          }
        });

        // Get Dropdown values for things that need to converted from strings to GUIDs
        const DropdownLookup = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/GetDropdownLookups?scope=all`,
          {
            method: "get"
          }
        );
        if (DropdownLookup.status === 200) {
          let DropdownData = DropdownLookup.data;
          massModel.forEach((row) => {
            // Language Guid/s
            row.Content.Languages = DropdownData.LanguageLookups.filter(
              function (item) {
                return (
                  item.DisplayName.toLowerCase() ===
                  row.Content.Languages.toLowerCase()
                );
              }
            ).map(function (selected) {
              return selected.Id;
            });

            // Retention Policy GUID
            row.Content.RetentionPolicyId =
              DropdownData.RetentionPolicyLookups.filter(function (item) {
                return (
                  item.DisplayName.toLowerCase() ===
                  row.Content.RetentionPolicyId.toLowerCase()
                );
              }).map(function (selected) {
                return selected.Id;
              })[0] || null;

            // Category Id
            var ContentAndType =
              DropdownData.CategoryLookups.filter(function (item) {
                return (
                  item.DisplayName.toLowerCase() ===
                  row.Content.CategoryId.toLowerCase()
                );
              }).map(function (selected) {
                return { Id: selected.Id, TypeId: selected.TypeId };
              })[0] || null;

            row.Content.CategoryId = ContentAndType.Id;

            row.Content.TypeId =
              DropdownData.TypeLookups.filter(function (item) {
                return item.DisplayName.toLowerCase() ===
                  row.Content.TypeId.toLowerCase();
              }).map(function (selected) {
                return selected.Id;
              })[0] || null;

            if (row.Content.TypeId !== ContentAndType.TypeId) {
              failures.push({ FailureReason: `Failed on TypeId ${row.Content.TypeId} not being equal to the content's type Id ${ContentAndType.TypeId}`, ...row.Content, ...row });
            }
          });

          massModel = massModel.filter(function (item) {
            let canReturn = true;
            failures.forEach(element => {
              if (item.Content.ContentPath === element.Content.ContentPath) {
                canReturn = false;
              }
            });
            if (canReturn) {
              return item;
            }
          });

          let TargetedContent = massModel.map(function (item) {
            return {
              TargetedContent: item.Content,
              Targets: [],
              UploadedBy: "Mass Upload",
              UploadedOn: new Date().toISOString(),
              TargetedMediaUrl: item.Content.ContentPath
            };
          });

          try {
            const fileResponse = await adalApiFetch(
              Axios, `${API_CONFIG.CONTENT}/MassCreateTargetedContent`,
              {
                method: "post",
                data: TargetedContent,
                headers: headers
              }
            );

            if (fileResponse.status === 200) {
              let CampaignAndTargets = massModel.map(function (item) {
                return {
                  TargetGroups: item.TargetGroups,
                  Campaign: {
                    ...item.Campaign,
                    Targets: {
                      TargetGroups: item.Campaign.Targets.TargetGroups,
                      TargetContentIds: fileResponse.data
                        .filter(function (returnedData) {
                          return returnedData.name === item.Content.Title;
                        })
                        .map(function (selected) {
                          return selected.id;
                        })
                    }
                  }
                };
              });

              const campaignResponse = await adalApiFetch(
                Axios, `${API_CONFIG.TARGETING}/MassCreateTargetGroupAndCampaign`,
                {
                  method: "post",
                  data: CampaignAndTargets,
                  headers: headers
                }
              ).catch(function (error) {
                console.log(error.message);
              });

              if (campaignResponse.status === 200) {
                let successes = CampaignAndTargets.map(function (item) {
                  return {
                    ...item.Campaign
                  };
                });
                dispatch({
                  type: MASS_UPLOAD_CONTENT,
                  failures: failures,
                  successes: successes
                });
                callback(true);

              } else {
                failures.push(CampaignAndTargets.map(function (item) {
                  return {
                    ...item.Campaign
                  };
                }));

                dispatch({
                  type: MASS_UPLOAD_CONTENT,
                  failures: failures,
                  successes: [],
                  message: "Error creating Campaigns and Target Group/s: Campaigns / target groups could not be created"
                });

                callback(true);
              }
            } else {
              failures.push(...TargetedContent);
              dispatch({
                type: MASS_UPLOAD_CONTENT,
                failures: failures,
                successes: [],
                message: "Error uploading targeted content: Targeted content could not be created"
              });
              callback(true);
            }
          } catch (error) {
            failures.push(...massModel.map(function (item) { return { ...item.Content, ...item.Campaign, ...item.TargetGroups } }));
            dispatch({
              type: MASS_UPLOAD_CONTENT,
              failures: failures,
              successes: [],
              message: "Error uploading targeted content: Targeted content could not be created"
            });
            callback(true);
          }
        } else {
          dispatch({
            type: MASS_UPLOAD_CONTENT,
            failures: [
              {
                Error: "",
                Message: ""
              },
              {
                Error: "DropdownLookup Error",
                Message:
                  "There was an error getting the GUID lookups. This may indicate that one of the backend services are down"
              }
            ],
            successes: [],
            message: "There was an error getting the GUID lookups. This may indicate that one of the backend services are down"
          });
          callback(true);
        }
      } else {
        failures.push(...migrationResponse.data.failedUploads);
        dispatch({
          type: MASS_UPLOAD_CONTENT,
          failures: failures,
          successes: [],
          message: "Error moving blobs from temporary storage into resource storage"
        })
        callback(true);
      }
    }
    else {
      dispatch({
        type: MASS_UPLOAD_CONTENT,
        failures: failures,
        successes: [],
        message: "Error uploading blobs into temp storage"
      });
      callback(true);
    };
  };
};


/*
  Mass Create Campaign and Target Groups
*/
export const MassCreateTargetedLinks = (massModel, callback) => {
  return async (dispatch) => {
    let failures = [];

    const headers = { "Content-Type": "application/json" };    // Get Dropdown values for things that need to converted from strings to GUIDs
    const DropdownLookup = await adalApiFetch(
      Axios, `${API_CONFIG.CONTENT}/GetDropdownLookups?scope=all`,
      {
        method: "get"
      }
    );
    if (DropdownLookup.status === 200) {
      let DropdownData = DropdownLookup.data;
      massModel.forEach((row) => {
        // Language Guid/s
        row.Content.Languages = DropdownData.LanguageLookups.filter(
          function (item) {
            return (
              item.DisplayName.toLowerCase() ===
              row.Content.Languages.toLowerCase()
            );
          }
        ).map(function (selected) {
          return selected.Id;
        });

        // Retention Policy GUID
        row.Content.RetentionPolicyId =
          DropdownData.RetentionPolicyLookups.filter(function (item) {
            return (
              item.DisplayName.toLowerCase() ===
              row.Content.RetentionPolicyId.toLowerCase()
            );
          }).map(function (selected) {
            return selected.Id;
          })[0] || null;


        // Category Id
        row.Content.CategoryId =
          DropdownData.CategoryLookups.filter(function (item) {
            return (
              item.DisplayName.toLowerCase() ===
              row.Content.CategoryId.toLowerCase()
            );
          }).map(function (selected) {
            return selected.Id;
          })[0] || null;

        // Type Id: File
        row.Content.TypeId =
          DropdownData.TypeLookups.filter(function (item) {
            return item.DisplayName.toLowerCase() === "links";
          }).map(function (selected) {
            return selected.Id;
          })[0] || null;


      });

      let TargetedContent = massModel.map(function (item) {
        return {
          TargetedContent: item.Content,
          Targets: [],
          UploadedBy: "Mass Upload",
          UploadedOn: new Date().toISOString(),
          TargetedMediaUrl: item.Content.FileName
        };
      });

      try {
        const fileResponse = await adalApiFetch(
          Axios, `${API_CONFIG.CONTENT}/MassCreateTargetedContent`,
          {
            method: "post",
            data: TargetedContent,
            headers: headers
          }
        );

        if (fileResponse.status === 200) {
          let CampaignAndTargets = massModel.map(function (item) {
            return {
              TargetGroups: item.TargetGroups,
              Campaign: {
                ...item.Campaign,
                Targets: {
                  TargetGroups: item.Campaign.Targets.TargetGroups,
                  TargetContentIds: fileResponse.data
                    .filter(function (returnedData) {
                      return returnedData.name === item.Content.Title;
                    })
                    .map(function (selected) {
                      return selected.id;
                    })
                }
              }
            };
          });

          const campaignResponse = await adalApiFetch(
            Axios, `${API_CONFIG.TARGETING}/MassCreateTargetGroupAndCampaign`,
            {
              method: "post",
              data: CampaignAndTargets,
              headers: headers
            }
          ).catch(function (error) {
            console.log(error.message);
          });

          if (campaignResponse.status === 200) {
            let successes = CampaignAndTargets.map(function (item) {
              return {
                ...item.Campaign
              };
            });
            dispatch({
              type: MASS_UPLOAD_CONTENT,
              failures: [],
              successes: successes
            });
            callback(true);

          } else {
            failures.push(CampaignAndTargets.map(function (item) {
              return {
                ...item.Campaign
              };
            }));

            dispatch({
              type: MASS_UPLOAD_CONTENT,
              failures: failures,
              successes: [],
              message: "Error creating Campaigns and Target Group/s: Campaigns / target groups could not be created"
            });

            callback(true);
          }
        } else {
          failures.push(...TargetedContent);
          dispatch({
            type: MASS_UPLOAD_CONTENT,
            failures: failures,
            successes: [],
            message: "Error uploading targeted content: Targeted content could not be created"
          });
          callback(true);
        }
      } catch (error) {
        failures.push(...massModel.map(function (item) { return { ...item.Content, ...item.Campaign, ...item.TargetGroups } }));
        dispatch({
          type: MASS_UPLOAD_CONTENT,
          failures: failures,
          successes: [],
          message: "Error uploading targeted content: Targeted content could not be created"
        });
        callback(true);
      }
    } else {
      dispatch({
        type: MASS_UPLOAD_CONTENT,
        failures: [
          {
            Error: "",
            Message: ""
          },
          {
            Error: "DropdownLookup Error",
            Message:
              "There was an error getting the GUID lookups. This may indicate that one of the backend services are down"
          }
        ],
        successes: [],
        message: "There was an error getting the GUID lookups. This may indicate that one of the backend services are down"
      });
      callback(true);
    }
  }
}

/*
  Create Target Group
*/
export const createCampaign = (campaign, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const startDate = new Date(campaign.StartDate);
      const endDate = new Date(campaign.EndDate);

      const data = {
        ...campaign,
        TargetGroups: campaign.TargetGroups,
        TargetContentIds: campaign.TargetContent
      };
      data.SelectedApplications = data.SelectedApplications.map(item => { return item.Id });

      await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING}/CreateCampaign?Name=${campaign.Name
          }&Description=${campaign.Description
          }&StartDate=${startDate.toISOString()}&EndDate=${endDate.toISOString()}`,
        {
          method: "post",
          data: data,
          headers: headers
        }
      );

      dispatch({
        type: CREATE_CAMPAIGN,
        payload: campaign
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
  Resets current Campaign to initial state
*/
export const resetLocalCampaign = (callback) => (dispatch) => {
  dispatch({
    type: RESET_CURRENT_CAMPAIGN,
    payload: null
  });

  callback(true);
};

// Update an individual campaign
export const updateCampaign = (campaignId, campaign, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };

      const startDate = new Date(campaign.StartDate);
      const endDate = new Date(campaign.EndDate);

      const targetGroupsData = campaign.TargetGroups.map((tg) => {
        return {
          TargetGroupId: tg.TargetGroupId,
          IsExcluding: tg.IsExcluding
        };
      });

      const data = {
        ...campaign,
        Targets: {
          TargetGroups: targetGroupsData,
          TargetContentIds: campaign.TargetContent
        }
      };
      data.SelectedApplications = data.SelectedApplications.map(item => { return item.Id });

      await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING
          }/UpdateCampaignById?CampaignId=${campaignId}&Name=${campaign.Name
          }&Description=${campaign.Description
          }&StartDate=${startDate.toISOString()}&EndDate=${endDate.toISOString()}`,
        {
          method: "patch",
          data: data,
          headers: headers
        }
      );

      dispatch({
        type: UPDATE_CURRENT_CAMPAIGN,
        payload: campaign
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};

export const getAllApplications = (callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING}/GetAllApplications`,
        { method: "get" }
      );

      dispatch({
        type: GET_ALL_APPLICATIONS,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const getApplicationById = (applicationId, callback) => {
  return async (dispatch) => {
    try {
      let response = null;
      if (applicationId != null) {
        response = await adalApiFetch(
          Axios, `${API_CONFIG.TARGETING}/GetApplicationById?ApplicationId=${applicationId}`,
          { method: "get" }
        );
      }
      dispatch({
        type: GET_APPLICATION_BY_ID,
        payload: response
      });

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const upsertApplication = (data, callback) => {
  return async (dispatch) => {
    try {
      const headers = { "Content-Type": "application/json" };

      await adalApiFetch(
        Axios, `${API_CONFIG.TARGETING}/UpsertApplication`,
        {
          method: "post",
          headers: headers,
          data: data
        }
      );

      callback(true);
    } catch (error) {
      console.log(`Exception Occurred: ${error.message}`);
      callback(false);
    }
  };
};
