import React from "react";
import { auth } from "../../auth/AuthProvider";
import { Redirect } from "react-router-dom";

class LoginPage extends React.Component {
  state = {
    redirectToReferrer: false
  };

  login = () => {
    auth.authenticate(() => {
      this.setState(() => ({
        redirectToReferrer: true
      }));
    });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state
    
    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <div>
        <h1>Login</h1>
        <div>
          <p>You must log in to view the page</p>
          <button onClick={this.login}>Log in</button>
        </div>
      </div>
    );
  }
}

export default LoginPage;
