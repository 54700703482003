// React
import React, { Fragment, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
// UI and Styling

import { Box, TextField, FormControl, Button, ButtonGroup, Grid, Paper, List, ListItem, ListItemText, ListItemIcon, IconButton, ListItemSecondaryAction, Typography, Modal, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import fileDownload from "js-file-download";

import Autocomplete from "@material-ui/lab/Autocomplete";
// Components
import HeaderBlock from "../../components/header-block/HeaderBlock";

// Segments
import PageContainer from "../../components/page-container/PageContainer";
import Axios from "axios";
import { adalApiFetch } from "../../config/azureConfig";
import { getApiConfig } from "../../config/apiConfig";
import { LoadingComponent } from "../../components/loading2/circleLoader";
import { UUID } from "../../helpers/uuid";
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import { Alert, AlertTitle } from "@material-ui/lab";

const API_CONFIG = getApiConfig();

const BulkUpdateLeads = (props) => {

  const [csvData, setCsvData] = useState({});
  const [tradeChannelIds, setTradeChannelIds] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [csvInvalidError, setCsvInvalidError] = useState(false);
  const [targetsEdited, setTargetsEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [selectedTradeChannelId, setSelectedTradeChannelId] = useState(null);
  
  //Use Effects
  useEffect(() => {
    adalApiFetch(Axios, `${API_CONFIG.ACTIVITIES}/NewLeadTradeChannelIds`,
      {
        method: "get"
    }).then((res) => {
      setTradeChannelIds(res.data);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
    });
  }, []);
    
  useEffect(() => {
    if (targetsEdited) {
      setSubmitEnabled(true);
    }
  }, [targetsEdited]);

  const csvErrorElement = csvInvalidError ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      The CSV file you have provided does not match the specification -{" "}
      <strong>Try Again Later.</strong>
    </Alert>
  ) : (
    <></>
  );

  const downloadCSV = async () => {
    try {
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.ACTIVITIES}/ExistingNewLeadsInTradeChannel?tradechannel=${selectedTradeChannelId}`,
        {
          method: "get"
        }
      );
  
      fileDownload(response.data, `NewLeads${selectedTradeChannelId}.csv`);
    } catch (error) {
      console.log(error);
    }
  };
  
  const renderSubmitAndCancelButtons = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={async () => {
          try {
              const response = await adalApiFetch(
                Axios, `${API_CONFIG.ACTIVITIES}/ImportBulkNewLeadsUpdate`,
                {
                  method: "post",
                  data: csvData
                }
              );
          } catch (error) {
            console.log(error);
          }
        }}
        disabled={!submitEnabled}
      >
        Submit
      </Button>,
    ];
  };

  return (
    <Box>
      <Fragment>
        {isUploading ? (
          <>
            <HeaderBlock
              title={"Please Wait: Data is being uploaded"}
            />
            <PageContainer>
              <LoadingComponent></LoadingComponent>
            </PageContainer>
          </>
        ) : (
          <>
            <HeaderBlock
              title={"New Leads Bulk Update"}
              right={
                renderSubmitAndCancelButtons() ? (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Grid item>
                      <ButtonGroup>
                        {renderSubmitAndCancelButtons()}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                ) : null
              }
            />

            <PageContainer>
              {csvErrorElement}
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      style={{margin: "16px"}}>
                      <Autocomplete
                        options={tradeChannelIds}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Trade Channel Id"
                            placeholder="Select a Trade Channel Id..."
                          />
                        )}
                        value={selectedTradeChannelId}
                        onChange={(event, tradeChannelId) => {
                          setSelectedTradeChannelId(tradeChannelId);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} justify="center">

                    <FormControl 
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        style={{margin: "16px"}}>
                      <Button
                        color='primary'
                        size='large'
                        style={{margin: '0 auto', display: "flex"}}
                        type='submit'
                        variant='contained'
                        disabled={!selectedTradeChannelId}
                        onClick={() => {
                          downloadCSV();
                        }}
                      >
                        Download CSV
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid
                    key={"migrationUploadSegment"}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    
                    <MigrationUploadSegment
                      csvData={csvData}
                      setCsvData={setCsvData}
                      setCSVInvalid={(option) => {
                        //TODO Show Error message if csv is invalid
                        if (option) {
                          setCsvInvalidError(true);
                          setTimeout(() => {
                            setCsvInvalidError(false);
                          }, 30000);
                        }
                      }}
                      sortProperties={['LeadId']}
                      setDataChanged={setDataChanged}
                      title="Upload New Leads Bulk Update"
                      targetsEdited={targetsEdited}
                      setTargetsEdited={setTargetsEdited}
                      isEditing={isEditing}
                      description="This will update New Leads with the same Lead ID as used in the CSV and overwrite all fields of the lead with the values from the CSV. This means, to remove a field (such as a phone number) then delete the number from the CSV and then upload it. If you leave the Lead ID field blank then a new lead will be created."
                    />
                  </Grid>
                </Grid>
              </div>
            </PageContainer>
          </>
        )}
      </Fragment>
    </Box>
  );
}

const hoc = withRouter(BulkUpdateLeads);

// EXPORT COMPONENT
export { hoc as BulkUpdateLeads };
