// React
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  makeStyles,
  TextField
} from "@material-ui/core";
import styles from "../CategoriesListPage.module.scss";

// Components
import ExportExcel from "../../../../components/export/ExportExcel";
import HeaderBlock from "../../../../components/header-block/HeaderBlock";
import { Loading } from "../../../../components/loading/Loading";
import PageContainer from "../../../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../../../components/page-placeholder/PagePlaceholder";
import SimpleTable from "../../../../components/table/Table";

// Other
import { getIcon } from "../../../../icon/icon";
import { adalApiFetch } from "../../../../config/azureConfig";
import Axios from "axios";
import { getApiConfig } from "../../../../config/apiConfig";

const FileDownload = require('js-file-download');
const API_CONFIG = getApiConfig();

const useStyles = makeStyles((theme) => ({
  rightMargin: {
    margin: "8px"
  },
  containerPadding: {
    padding: "16px"
  }
}));

const SaMaProgrammesListPage = (props: any) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [programmes, setProgrammes] = useState([]);

  async function fetchData(){
    const headers = { "Content-Type": "application/json" };
    const response = await adalApiFetch(
      Axios, `${API_CONFIG.SYNC}/ListSAMAProgrammes`,
      {
        method: "get",
        headers: headers
      }
    );

    let formattedData = response.data;

    if(Array.isArray(response.data)){
      formattedData.map((item: any, index: number) => {
        formattedData[index] = {
          ProgrammeId: item.Id,
          ProgrammeName: item.Name,
        };
      });
    } else {
      throw new Error("Invalid response from API. Data is not an array.");
    }
    setProgrammes(formattedData);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setupData();
  }, [programmes]);

  const setupData = () => {
    const data = programmes;

    setOriginalData(data);
    setSearchedData(data);
  };

  const search = (searchText: any) => {
    setSearchText(searchText);
    const text = searchText.toLowerCase();

    const foundData = originalData.filter((data: any) => {
      return (
        data.ProgrammeId.toLowerCase().includes(text) ||
        data.ProgrammeName.toLowerCase().includes(text)
      );
    });

    setSearchedData(foundData);
  };

  const changeHandler = async (event: any) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();


    const eventListener = async (event: any) => {
      let fileData = event.target.result;

      const headers = { "Content-Type": "application/json" };
      const response = await adalApiFetch(
        Axios, `${API_CONFIG.SYNC}/UpdateSAMAProgrammes`,
        {
          method: "post",
          headers: headers,
          data: fileData
        }
      );
      window.location.reload();
    };

    fileReader.addEventListener("load", eventListener);

    fileReader.readAsText(file);
  };
  const inputRef: any = useRef(null);

  const createHeaders = () => {
    return [
      {
        id: "ProgrammeName",
        clickable: true,
        action: {
          path: "/samaprogrammes/{id}",
          identifier: "ProgrammeId"
        },
        label: "Name",
        align: "left"
      },
      {
        id: "ProgrammeId",
        align: "left",
        label: "Programme Id"
      }
    ];
  };

  const createCells = (headers: any) => {
    let cells: any = [];

    headers.forEach((header: any) => {
      let cell: any = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event: any, row: any) => {
          let path = header.action.path.replace(
            "{id}",
            encodeURIComponent(row[header.action.identifier])
          );
          
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };

  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      searchedData.length === 0 ? (
        <PagePlaceholder
          image={getIcon("frame-expand.svg")}
          text="No Programmes found."
        />
      ) : (
        <SimpleTable
          handleSelection={null}
          isSelectable={false}
          dataId={"ProgrammeId"}
          cells={cells}
          headers={headers}
          rows={searchedData}
          />
      );
    return data;
  };


  return (
    <div>
      <input type="file" ref={inputRef} name="file" style={{display: 'none'}} onChange={changeHandler} />
      <HeaderBlock
        title="SaMa Programmes"
        right={
          <Box display='flex' alignItems="center" justifyContent="flex-end" style={{gap: '0.5rem'}}>
            <TextField
              fullWidth
              type="search"
              id="outlined-name"
              label="Search Programmes"
              value={searchText}
              onChange={(event) => search(event.target.value)}
              margin="dense"
              variant="outlined"
            />
            <Button
              style={{flexBasis: '12em', flexShrink: 0}}
              onClick={async () => {
                const headers = { "Content-Type": "application/json" };
                const response = await adalApiFetch(
                  Axios, `${API_CONFIG.SYNC}/GetSAMAProgrammes`,
                  {
                    method: "get",
                    headers: headers
                  }
                  );
                FileDownload(JSON.stringify(response.data, null, 2), `samaprogrammes.json`);
              }}
              variant="outlined"
              color="secondary"
            >
              Download JSON
            </Button>
            <Button
              style={{flexBasis: '12em', flexShrink: 0}}
              onClick={() => props.history.push("/samaprogrammes/new")}
              variant="contained"
              color="secondary"
            >
              New Programme
            </Button>
          </Box>
        }
      />
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
    </div>
  );
};

const hoc = withRouter(SaMaProgrammesListPage);

// EXPORT COMPONENT
export { hoc as SaMaProgrammesListPage };
