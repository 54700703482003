// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import styles from "./UserPage.module.scss";
import AddIcon from "@material-ui/icons/Add";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

// Actions
import {
  assignToRoles,
  createUser,
  getUser,
  getUserByEmail,
  removeFromRoles,
  updateUser,
  updateUserLocally,
} from "../../../state/actions/UserActions";
import { getRoles, getRolesForUser } from "../../../state/actions/RoleActions";

// Components
import AlertDialog from "../../../components/alert-dialog/AlertDialog";
import CustomCard from "../../../components/custom-card/CustomCard";
import HeaderBlock from "../../../components/header-block/HeaderBlock";
import { Loading } from "../../../components/loading/Loading";
import Modal from "../../../components/dialog/Modal";
import PageContainer from "../../../components/page-container/PageContainer";
import Search from "../../../components/search/Search";
import SimpleList from "../../../components/simple-list/SimpleList";
import TextFieldWithDomain from "../../../components/form-controls/TextFieldWithDomain";

// Models
import { UserInputModel } from "../../../models/UserModel";

// Other
import { UUID } from "../../../helpers/uuid";
import { validateField } from "../../../helpers/validation";
import { Territories } from "../../../state/constants/Territories";
import { getIcon } from "../../../icon/icon";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "25ch",
  },
  icon: {
    height: "22px",
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

const UserPage = (props) => {
  const classes = useStyles();

  const user = useSelector((state) => state.UserReducer.user);
  const rolesForUser = useSelector((state) => state.RoleReducer.rolesForUser);
  const roles = useSelector((state) => state.RoleReducer.roles);
  const existingUser = useSelector((state) => state.UserReducer.existingUser);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  const [originalUser, setOriginalUser] = useState({});
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingRolesForUser, setLoadingRolesForUser] = useState(false);
  const [rolesToDelete, setRolesToDelete] = useState([]);
  const [addRoleModalOpen, setAddRoleModalOpen] = useState(false);
  const [removeRoleModalOpen, setRemoveRoleModalOpen] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [originalRolesForUserData, setOriginalRolesForUserData] = useState([]);
  const [searchedRolesForUserData, setSearchedRolesForUserData] = useState([]);
  const [deleteAlertShowing, setDeleteAlertShowing] = useState(false);
  const [cancelAlertShowing, setCancelAlertShowing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [fetchUser, setFetchUser] = useState(true);
  const [fetchRolesForUser, setFetchRolesForUser] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [formData, setFormData] = useState(UserInputModel);
  const [errorsCount, setErrorsCount] = useState(0);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [createUserFound, setCreateUserFound] = useState(false);
  const [emailSearchComplete, setEmailSearchComplete] = useState(false);
  const [showNewUserError, setShowNewUserError] = useState(false);
  const [fetchRolesNotAssignedToUser, setFetchRolesNotAssignedToUser] =
    useState(false);
  const [ignoreAD, setIgnoreAD] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const userId = props.match.params.userId;

  // First get the user details
  useEffect(() => {
    setShowNewUserError(false);
    if (fetchUser) {
      if (userId === "new" || userId === "users") {
        setIsNewUser(true);
        setEditing(true);
      } else {
        setLoadingUser(true);
        setLoadingRolesForUser(true);
        dispatch(
          getUser(userId, (res) => {
            setLoadingUser(false);
            setFetchRolesForUser(true);
          })
        );
      }

      setFetchUser(false);
    }
  }, [fetchUser]);

  // Then get the roles the user is assigned to
  useEffect(() => {
    if (fetchRolesForUser) {
      if (!loadingRolesForUser) {
        setLoadingRolesForUser(true);
      }

      dispatch(
        getRolesForUser(userId, () => {
          setLoadingRolesForUser(false);
          setFetchRolesForUser(false);
        })
      );
    }
  }, [fetchRolesForUser]);

  // Whenever the roles for the user changes, update the roles for user search data
  // This data is used when performing a search within the remove role from user modal
  useEffect(() => {
    if (rolesForUser) {
      const rolesForUsersData = [];

      Object.keys(rolesForUser).forEach((roleForUserId) => {
        rolesForUsersData.push({
          id: rolesForUser[roleForUserId].id,
          value: rolesForUser[roleForUserId].name,
        });
      });

      setSearchedRolesForUserData(rolesForUsersData);
      setOriginalRolesForUserData(rolesForUsersData);
    }
  }, [rolesForUser]);

  // When assigning a role to a user we need to get all the roles that the user isn't assigned to
  useEffect(() => {
    if (fetchRolesNotAssignedToUser) {
      setLoadingRolesForUser(true);

      dispatch(
        getRoles(() => {
          setFetchRolesNotAssignedToUser(false);
        })
      );
    }
  }, [fetchRolesNotAssignedToUser]);

  // Whenever roles updates we need to update the roles search data to match
  useEffect(() => {
    if (roles && rolesForUser) {
      const roleIds = Object.keys(rolesForUser);
      const data = [];

      Object.keys(roles).forEach((key) => {
        let item = roles[key];

        if (!roleIds.includes(item.id)) {
          if (
            item.name !== "samcore\\SaM Core Admin" ||
            (item.name === "samcore\\SaM Core Admin" &&
              loggedInUserRoles !== undefined &&
              loggedInUserRoles.includes("SaM Core Admin"))
          ) {
            data.push({ id: item.id, value: item.name });
          }
        }
      });

      setLoadingRolesForUser(false);
      setSearchedData(data);
      setOriginalData(data);
    }
  }, [roles]);

  useEffect(() => {
    if (emailSearchComplete) {
      if (existingUser.existsInSitecore) {
        //User already exists - navigate to that users page
        props.history.push(`/users/${existingUser.userId}`);

        setEmailSearchComplete(false);
        setCreateUserFound(true);
        setEditing(false);
        setIsNewUser(false);
        setFetchUser(true);
      } else if (ignoreAD || existingUser.existsInActiveDirectory) {
        //The user exists in AD but not samcore - display the input fields and propagate email
        setCreateUserFound(true);
        setEditing(true);
        setSubmitEnabled(true);

        let localUser = Object.assign({}, user);
        localUser["email"] = newUserEmail;
        localUser["firstName"] =
          existingUser.firstName !== undefined ? existingUser.firstName : "";
        localUser["lastName"] =
          existingUser.surname !== undefined ? existingUser.surname : "";
        localUser["name"] =
          existingUser.displayName !== undefined
            ? existingUser.displayName
            : "";
        localUser["sapUsername"] = "";
        localUser["territory"] =
          existingUser.territory !== undefined
            ? (existingUser.territory = 1 ? "au" : "nz")
            : "";
        localUser["id"] = "";
        dispatch(updateUserLocally(localUser));
      } else {
        setShowNewUserError(true);
        setEmailSearchComplete(true);
      }
    }
  }, [emailSearchComplete]);

  const deleteRolesFromUser = (rolesToDeleteIds) => {
    dispatch(
      removeFromRoles(userId, rolesToDeleteIds, () => {
        setRolesToDelete([]);
        setDeleteAlertShowing(false);
        setFetchRolesForUser(true);
      })
    );
  };

  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }

    setSubmitEnabled(false);
    setShowNewUserError(false);
    let numErrors = 0;

    Object.values(formData).forEach((field) => {
      const errorText = checkFieldIsValid(
        field.required,
        user[field.name],
        field.type,
        field.name,
        field.label
      );

      if (errorText.length > 0) {
        numErrors++;
      }
    });

    if (numErrors > 0) {
      setErrorsCount(numErrors);

      setSubmitEnabled(true);
    } else {
      if (isNewUser) {
        const scope = {
          existsInActiveDirectory: existingUser.existsInActiveDirectory,
          existsInAuth: existingUser.existsInAuth,
          existsInSiteCore: existingUser.existsInSitecore,
        };
        dispatch(
          createUser(user, scope, () => {
            props.history.push(`/users/${user.id}`);

            setSelectedRoles([]);
            setEditing(false);
            setFormHasChanged(false);
            setIsNewUser(false);
          })
        );
      } else if (editing) {
        dispatch(
          updateUser(userId, user, () => {
            setSelectedRoles([]);
            setEditing(false);
            setFormHasChanged(false);
          })
        );
      }
    }
  };

  // This refers to the cancel button on the edit or create form
  const handleCancelEditButtonClicked = () => {
    if (formHasChanged) {
      setCancelAlertShowing(true);
    } else {
      setEditing(false);

      if (isNewUser) {
        props.history.push(`/users`);
      }
    }
  };

  const handleEditButtonClicked = () => {
    //Take a copy of the current user in case the app user cancels
    setOriginalUser(user);

    setEditing(true);
  };

  // This refers to the cancel button within the alert dialog
  const handleCancelButtonClicked = () => {
    if (isNewUser) {
      props.history.replace(`/users`);
    } else {
      dispatch(updateUserLocally(originalUser));
      setFormHasChanged(false);
    }

    setSelectedRoles([]);
    setEditing(false);
    setCancelAlertShowing(false);
  };

  // When creating a new user in sitecore they need to exist in AD before doing so.
  const handleSearchUserClicked = () => {
    if (emailSearchComplete) {
      setEmailSearchComplete(false);
    }

    if (showNewUserError) {
      setShowNewUserError(false);
    }

    const errorText = checkFieldIsValid(
      true,
      newUserEmail,
      "email",
      "email",
      "Email"
    );

    if (errorText.length > 0) {
      setErrorsCount(1);
    } else {
      dispatch(
        getUserByEmail(newUserEmail, () => {
          setEmailSearchComplete(true);
        })
      );
    }
  };

  const deleteAlertDialog = () => {
    const dialogRolesToDelete = rolesToDelete !== null ? rolesToDelete : [];
    let roleToDeleteName = "";
    let rolesToDeleteIds = [];

    if (dialogRolesToDelete.length > 1) {
      roleToDeleteName = `${dialogRolesToDelete.length} roles`;
    } else if (dialogRolesToDelete.length === 1) {
      roleToDeleteName = rolesForUser[dialogRolesToDelete[0]].name;
    }

    rolesToDeleteIds = rolesToDelete;

    return (
      <AlertDialog
        title={`Are you sure you want to remove ${user.name} from ${roleToDeleteName}?`}
        description={"This is permanent and can't be undone."}
        options={[
          {
            label: "Cancel",
            action: () => setDeleteAlertShowing(false),
          },
          {
            label: "Delete",
            action: () => deleteRolesFromUser(rolesToDeleteIds),
          },
        ]}
        isOpen={deleteAlertShowing}
      />
    );
  };

  const cancelAlertDialog = () => {
    const title = isNewUser
      ? "Are you sure you want to go back?"
      : "Are you sure you want to stop editing?";
    return (
      <AlertDialog
        title={title}
        description={"Any changes made will be lost."}
        options={[
          {
            label: "Cancel",
            action: () => setCancelAlertShowing(false),
          },
          {
            label: "Confirm",
            action: () => handleCancelButtonClicked(),
          },
        ]}
        isOpen={cancelAlertShowing}
      />
    );
  };

  const renderSubmitAndCancelButtons = () => {
    if (isNewUser && !createUserFound) {
      return [
        <Button
          key={UUID()}
          variant="outlined"
          onClick={() => handleCancelEditButtonClicked()}
        >
          Cancel
        </Button>,
      ];
    } else {
      return [
        <Button
          key={UUID()}
          variant="outlined"
          onClick={() => validateForm()}
          disabled={!formHasChanged && submitEnabled === false}
        >
          Submit
        </Button>,
        <Button
          key={UUID()}
          variant="outlined"
          onClick={() => handleCancelEditButtonClicked()}
        >
          Cancel
        </Button>,
      ];
    }
  };

  const renderEditButton = () => {
    return [
      <Button
        key={UUID()}
        variant="outlined"
        onClick={() => handleEditButtonClicked()}
      >
        Edit
      </Button>,
    ];
  };

  const addRolesToUser = () => {
    dispatch(
      assignToRoles(userId, selectedRoles, () => {
        setAddRoleModalOpen(false);
        setFetchUser(true);
      })
    );
  };

  const handleAddRoleModalOpening = () => {
    setAddRoleModalOpen(true);
    setFetchRolesNotAssignedToUser(true);
  };

  const addRoleModal = () => {
    return (
      <Modal
        title={"Add User to Roles"}
        open={addRoleModalOpen}
        fullWidth={true}
        fixedHeight={true}
        actions={
          <Fragment>
            <Button
              onClick={() => setAddRoleModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={() => addRolesToUser()} color="secondary">
              Add to Role
            </Button>
          </Fragment>
        }
      >
        {loadingRolesForUser ? (
          <Loading />
        ) : (
          <>
            <Search
              searchField={"value"}
              returnSearchMatches={(data) => {
                setSearchedData(data);
              }}
              data={originalData}
              searchTitle="Search Roles"
            />
            <SimpleList
              emptyPlaceholder={"All users have been assigned."}
              handleSelection={(selected) => setSelectedRoles(selected)}
              data={searchedData}
            />
          </>
        )}
      </Modal>
    );
  };

  const removeRoleModal = () => {
    return (
      <Modal
        title={"Unassign Roles from User"}
        open={removeRoleModalOpen}
        fullWidth={true}
        fixedHeight={true}
        actions={
          <Fragment>
            <Button
              onClick={() => setRemoveRoleModalOpen(false)}
              color="secondary"
            >
              Cancel
            </Button>
            <Button onClick={() => handleDeleteRoles()} color="secondary">
              {rolesToDelete.length <= 1
                ? "Unassign Role from User"
                : "Unassign Roles from User"}
            </Button>
          </Fragment>
        }
      >
        <Search
          searchField={"value"}
          returnSearchMatches={(data) => {
            setSearchedRolesForUserData(data);
          }}
          data={originalRolesForUserData}
          searchTitle="Search Roles"
        />
        {loadingRolesForUser ? (
          <Loading />
        ) : (
          <SimpleList
            emptyPlaceholder={"All users have been unassigned."}
            handleSelection={(selected) => setRolesToDelete(selected)}
            data={searchedRolesForUserData}
          />
        )}
      </Modal>
    );
  };

  const handleDeleteRoles = () => {
    setRemoveRoleModalOpen(false);
    setDeleteAlertShowing(true);
  };

  // Validate the field data then update the localFormData with any new error messages
  const checkFieldIsValid = (required, value, type, fieldName, fieldLabel) => {
    let localFormData = formData;

    const errorText = validateField(required, value, type, fieldLabel);

    localFormData[fieldName].errorText = errorText;

    setFormData(localFormData);

    return errorText;
  };

  // When the value of the input changes update the user state
  const handleTextInputChange = (event) => {
    const { name, value } = event.target;

    const formDataField = formData[name];

    checkFieldIsValid(
      formDataField.required,
      value,
      formDataField.type,
      name,
      formDataField.label
    );

    if (name === "email" && isNewUser && !createUserFound) {
      setNewUserEmail(value);
    } else {
      let localUser = Object.assign({}, user);
      localUser[name] = value;
      dispatch(updateUserLocally(localUser));

      if (!formHasChanged) {
        setFormHasChanged(true);
      }
      if (!submitEnabled) {
        setSubmitEnabled(true);
      }
    }
  };

  // Render the form fields
  const renderFormData = () => {
    return (
      <Fragment>
        <Grid
          item
          xs={12}
          style={{
            display:
              isNewUser && !createUserFound
                ? "none"
                : user.isLockedOut
                ? "inline-flex"
                : "none",
            marginTop: 16,
            marginRight: 16,
          }}
        >
          <LockOutlinedIcon color="error" />
          <Typography color="error" variant="h6" gutterBottom>
            Locked Out
          </Typography>
        </Grid>
        <TextFieldWithDomain
          domainValue="ausdom/"
          errorText={
            formData.email.errorText.length === 0
              ? false
              : editing
              ? true
              : false
          }
          disabled={!editing || !isNewUser}
          id={formData.email.id}
          name={formData.email.name}
          label={formData.email.label}
          placeholder={formData.email.placeholder}
          value={
            isNewUser && !createUserFound ? newUserEmail : user.email || ""
          }
          onChange={handleTextInputChange}
          helperText={editing ? formData.email.errorText : ""}
        />
        <Grid item>
          <Typography
            variant="subtitle1"
            color="primary"
            gutterBottom
            style={{
              display: showNewUserError ? "block" : "none",
              marginTop: 8,
              marginBottom: 8,
            }}
          >
            Could not find a user relating to this email.
          </Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{
              display: isNewUser && !createUserFound ? "flex" : "none",
            }}
          >
            <FormControlLabel
              labelPlacement="start"
              label="External User"
              style={{
                marginRight: 4,
                display: "none",
              }}
              control={
                <Checkbox
                  checked={ignoreAD}
                  onChange={() => setIgnoreAD(!ignoreAD)}
                  name="ignoreAD"
                  color="primary"
                />
              }
            />
            <Button
              onClick={() => handleSearchUserClicked()}
              variant="outlined"
              color="secondary"
              key={UUID()}
            >
              Search
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <TextField
            disabled={!editing}
            error={
              formData.firstName.errorText.length === 0
                ? false
                : editing
                ? true
                : false
            }
            id={formData.firstName.id}
            name={formData.firstName.name}
            label={formData.firstName.label}
            placeholder={formData.firstName.placeholder}
            value={user.firstName || ""}
            onChange={handleTextInputChange}
            variant="outlined"
            margin="dense"
            helperText={editing ? formData.firstName.errorText : ""}
            style={{
              display: isNewUser && !createUserFound ? "none" : "inline-flex",
              marginTop: 16,
              marginRight: 16,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            disabled={!editing}
            error={
              formData.lastName.errorText.length === 0
                ? false
                : editing
                ? true
                : false
            }
            id={formData.lastName.id}
            name={formData.lastName.name}
            label={formData.lastName.label}
            placeholder={formData.lastName.placeholder}
            value={user.lastName || ""}
            onChange={handleTextInputChange}
            variant="outlined"
            margin="dense"
            helperText={editing ? formData.lastName.errorText : ""}
            style={{
              display: isNewUser && !createUserFound ? "none" : "inline-flex",
              marginTop: 16,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            disabled={!editing}
            error={
              formData.sapUsername.errorText.length === 0
                ? false
                : editing
                ? true
                : false
            }
            id={formData.sapUsername.id}
            name={formData.sapUsername.name}
            label={formData.sapUsername.label}
            placeholder={formData.sapUsername.placeholder}
            value={user.sapUsername || ""}
            onChange={handleTextInputChange}
            variant="outlined"
            margin="dense"
            helperText={editing ? formData.sapUsername.errorText : ""}
            style={{
              display: isNewUser && !createUserFound ? "none" : "inline-flex",
              marginTop: 16,
              marginRight: 16,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            className={classes.select}
            disabled={!editing}
            error={
              formData.territory.errorText.length === 0
                ? false
                : editing
                ? true
                : false
            }
            id={formData.territory.id}
            name={formData.territory.name}
            select
            label={formData.territory.label}
            value={user.territory}
            onChange={handleTextInputChange}
            variant="outlined"
            margin="dense"
            helperText={editing ? formData.territory.errorText : ""}
            style={{
              display: isNewUser && !createUserFound ? "none" : "inline-flex",
              marginTop: 16,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem disabled value="default">
              Select territory
            </MenuItem>
            {Object.keys(Territories).map((key, index) => (
              <MenuItem key={key} value={key}>
                {Territories[key]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Fragment>
    );
  };

  const buttons =
    editing || isNewUser ? renderSubmitAndCancelButtons() : renderEditButton();

  // Get the markup for the main content
  const getContentGrid = () => {
    const userRoles =
      rolesForUser != null
        ? Object.keys(rolesForUser).map((key) => {
            return {
              name: rolesForUser[key].name,
              id: rolesForUser[key].id,
            };
          })
        : [];

    const userTableColumns = [
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: true,
        },
      },
    ];

    const userTableOptions = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 20,
      rowsPerPageOptions: [20, 50, 100],
      viewColumns: false,
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      download: true,
      downloadOptions: {
        filename: "RoleUsers.csv",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      customToolbar: () => {
        return (
          <Fragment>
            <Tooltip title={"Assign role to user"}>
              <IconButton onClick={() => handleAddRoleModalOpening()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={"Unassign role from user"}
              style={{
                display:
                  Object.keys(rolesForUser).length === 0
                    ? "none"
                    : "inline-flex",
              }}
            >
              <IconButton
                onClick={() => setRemoveRoleModalOpen(true)}
                edge="end"
                aria-label="delete"
              >
                {getIcon("trash.svg", classes.icon)}
              </IconButton>
            </Tooltip>
          </Fragment>
        );
      },
    };

    return (
      <div className={styles.contentGrid}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CustomCard
              title={
                isNewUser ? "Add User" : editing ? "Edit Details" : "Details"
              }
              showChip={false}
              actionButton={buttons}
            >
              {renderFormData()}
            </CustomCard>
          </Grid>
          {isNewUser ? null : (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {loadingRolesForUser ? (
                <Loading />
              ) : (
                <MUIDataTable
                  title="Assigned to Role"
                  data={userRoles}
                  columns={userTableColumns}
                  options={userTableOptions}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  if (user == null) {
    return (
      <Box>
        <Typography>User model incorrect</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {loadingUser ? (
        <Loading />
      ) : (
        <Fragment>
          <HeaderBlock
            title={
              isNewUser ? "New User" : `${user.firstName} ${user.lastName}`
            }
          />
          {deleteAlertDialog()}
          {cancelAlertDialog()}
          {addRoleModal()}
          {removeRoleModal()}

          <PageContainer>{getContentGrid()}</PageContainer>
        </Fragment>
      )}
    </Box>
  );
};

const hoc = withRouter(UserPage);

// EXPORT COMPONENT
export { hoc as UserPage };
