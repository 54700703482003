export const ChoiceModel = {
  nonRangingName: {
    type: "string",
    isRangingField: false,
    required: true,
    errorMessage: "",
    value: "nonRangingName",
    label: "Name"
  },
  nonRangingWeight: {
    type: "string",
    isRangingField: false,
    required: true,
    errorMessage: "",
    value: "nonRangingWeight",
    label: "Weight"
  },
  nonRangingActive: {
    type: "checkbox",
    isRangingField: false,
    required: false,
    errorMessage: "",
    value: "nonRangingActive",
    label: "Active"
  },
  name: {
    type: "string",
    isRangingField: true,
    required: true,
    errorMessage: "",
    value: "name",
    label: "Name"
  },
  rangingType: {
    type: "select",
    isRangingField: true,
    required: true,
    errorMessage: "",
    value: "rangingType",
    label: "Ranging Type"
  },
  active: {
    type: "checkbox",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "active",
    label: "Active"
  },
  brand: {
    type: "select",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "brand",
    label: "Brand"
  },
  material: {
    type: "select",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "material",
    label: "Material"
  },
  packType: {
    type: "select",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "packType",
    label: "Pack Type"
  },
  packSize: {
    type: "select",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "packSize",
    label: "Pack Size"
  },
  beverageCategory: {
    type: "select",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "beverageCategory",
    label: "Beverage Category"
  },
  minVariants: {
    type: "select",
    isRangingField: true,
    required: true,
    errorMessage: "",
    value: "minVariants",
    label: "Minimum Number of Variants"
  },
  subText: {
    type: "string",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "subText",
    label: "Sub Text"
  },
  imageOne: {
    type: "string",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "imageOne",
    label: "Image One"
  },
  imageTwo: {
    type: "string",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "imageTwo",
    label: "Image Two"
  },
  imageThree: {
    type: "string",
    isRangingField: true,
    required: false,
    errorMessage: "",
    value: "imageThree",
    label: "Image Three"
  },
  rangingWeighting: {
    type: "string",
    isRangingField: false,
    required: false,
    errorMessage: "",
    value: "rangingWeighting",
    label: "Weighting"
  }
};
