import React from 'react';
import { GenericDictionary } from 'types/GenericDictionary';
import { ProgrammeAction, ProgrammePriceBook } from '../../reducers/programme-reducer';
import { Box, Button, IconButton, TextField } from '@material-ui/core';
import { RemoveCircleOutline, AccountBalanceWalletOutlined } from '@material-ui/icons';
import styles from '../programmeDetailList.module.scss';

interface PriceBooksListProps {
  priceBooks: GenericDictionary<ProgrammePriceBook>;
  handleUpdatePriceBooks: (action: ProgrammeAction) => void;
}

function PriceBooksList(props: PriceBooksListProps) {
  const {
    priceBooks,
    handleUpdatePriceBooks,
  } = props;

  const [newPriceBookId, setNewPriceBookId] = React.useState<string | null>(null);

  function handleAddPriceBook(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    handleUpdatePriceBooks({type:'ADD_PRICE_BOOK', payload: { value: newPriceBookId}});
    setNewPriceBookId(null);
  }

  function renderPriceBooksList(priceBooks: GenericDictionary<ProgrammePriceBook>){
    const priceBooksArray = Object.entries(priceBooks);

    return (
      priceBooksArray.length && priceBooksArray.map(([pbKey, pbValue ], index) => {
        return (
          <li key={index}>
            <Box display='flex' flex={1} style={{gap: '0.5rem'}} alignItems='center' color='text.secondary'>
              <AccountBalanceWalletOutlined />
              <span className="email">{pbKey}</span>
            </Box>
            <Box flex='0'>
              <IconButton onClick={(e) => handleUpdatePriceBooks({type: 'REMOVE_PRICE_BOOK', payload: { value: pbKey }})}>
                <RemoveCircleOutline fontSize='small' color="primary" />
              </IconButton>
            </Box>
          </li>
        )
      })
    )
  }

  return (
    <div>
      <ul className={styles.programmeDetailList}>
        { Object.entries(priceBooks).length > 0 ? renderPriceBooksList(priceBooks) : <li><p style={{color: 'var(--secondary-font)'}}>No price books added</p></li> }
      </ul>

      <div className={styles.programmeDetailList_extension}>
        <Box display='flex'>
          <Box flex='1'>
            <TextField value={newPriceBookId || ''} size='small' label="Price Book ID" variant="outlined" onChange={ e => setNewPriceBookId(e.target.value) } name='priceBook' fullWidth />
          </Box>
          <Box flex='0'>
            <Button variant="outlined" color="secondary" onClick={handleAddPriceBook} style={{flex: 0, alignSelf: 'start', margin: '2px 0 0 0.75rem'}}>Add</Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default PriceBooksList;