// React
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "../../components/dialog/Modal";
// UI and Styling
import {
  Box, Button, ButtonGroup, Grid,
  Typography, Tooltip, IconButton
} from "@material-ui/core";
import styles from "./ActivityGroupsListPage.module.scss";
// Actions
import { getAllActivityGroups, updateBulkActivity } from "../../state/actions/ActivityActions";
import {
  getActivityGroupIdForSite,
} from "../../state/actions/MyDisplaysActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import HeaderBlock from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import PageContainer from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";
import AlertDialog from "../../components/alert-dialog/AlertDialog";
import { ActivityModel } from "./ActivityModel";
import { getFormat } from '../../state/constants/ModelType'

import { isValid, compareAsc, parseISO } from "date-fns";
import { compareDates, validateField } from "../../helpers/validation";


import { getIcon } from "../../icon/icon";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Models
import { ActivityMassModel } from "../../models/ActivityMassUpdate";
import ErrorIcon from "@material-ui/icons/Error";


// Start of main component body
const ActivityGroupsListPage = (props) => {
  const { activityGroups } = useSelector((state) => state.ActivityReducer);
  const activityGroupId = useSelector((state) => state.MyDisplaysReducer.activityGroupId);
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [ausId, setAusId] = useState('');
  const [nzId, setNzId] = useState('');
  // Mass Update
  const [isMassUpdatePopupOpen, setIsMassUpdatePopupOpen] = useState(false);
  const [formProperties, setFormProperties] = useState(ActivityMassModel);
  const [activity, setActivity] = useState({});
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [dataChanged, setDataChanged] = useState(false);
  const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
  const [submitEditingAlertOpen, setSubmitEditingAlertOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [activityGroupIds, setActivityGroupIds] = useState({});

  const getGroupIds = async () => {
    await dispatch(getActivityGroupIdForSite(1, (success) => {
      if (success) {
        setAusId(activityGroupId);
      }
      else {
        setAusId(null);
      }
    }))


    await dispatch(getActivityGroupIdForSite(2, (success) => {
      if (success) {
        setNzId(activityGroupId);
      }
      else {
        setNzId(null);
      }
    }))

  }

  useEffect(() => {
    setLoading(true);
    getGroupIds();

    dispatch(getLoggedInUserByEmail(() => { }));

    dispatch(getAllActivityGroups(() => { }));
  }, []);

  useEffect(() => {
    let filteredList = [];
    if (activityGroups !== undefined) {
      const data = createData(activityGroups);
      if (nzId !== null) {
        filteredList = data.filter((item) => item.id !== nzId);
      }
      else if (ausId !== null) {
        filteredList = data.filter((item) => item.id !== ausId);
      }
      else {
        filteredList = data.filter((item) => item.id !== ausId && item.id !== nzId);
      }


      setOriginalData(filteredList);
      setSearchedData(filteredList);
      if (loading) {
        setLoading(false);
      }
    }
  }, [activityGroups, nzId, ausId]);

  const createData = (activityGroups) => {
    const activityGroupKeys = Object.keys(activityGroups);

    return activityGroupKeys.map((activityGroupKey) => {
      const activityGroup = activityGroups[activityGroupKey];

      return {
        name: activityGroup.Name,
        id: activityGroup.Id,
        startDate: activityGroup.StartDate,
        endDate: activityGroup.EndDate,
        sequence: activityGroup.Sequence,
        isActive: activityGroup.IsActive ? "True" : "False",
        groupType: activityGroup.GroupType
      };
    });
  };

  // The headers of the table
  const createHeaders = () => {
    return [
      {
        id: "name",
        clickable: true,
        action: { path: "/activity-groups/{id}", identifier: "id" },
        align: "left",
        label: "Name",
      },
      {
        id: "startDate",
        align: "left",
        label: "Start Date",
      },
      {
        id: "endDate",
        align: "left",
        label: "End Date",
      },
      {
        id: "sequence",
        align: "left",
        label: "Sequence",
      },
      {
        id: "isActive",
        align: "left",
        label: "Is Active",
      },
      {
        id: "groupType",
        align: "left",
        label: "Red Category",
      },
    ];
  };

  // Create the cells for the table and configure them.
  const createCells = (headers) => {
    let cells = [];

    headers.forEach((header) => {
      let cell = {};

      cell.id = header.id;

      if (header.hasOwnProperty("action")) {
        cell.handleClick = (event, row) => {
          let path = header.action.path.replace(
            "{id}",
            row[header.action.identifier]
          );
          props.history.push(path);
        };
      }

      cell.align = header.align;
      cell.clickable = header.clickable;

      cells.push(cell);
    });

    return cells;
  };
  const handleSelection = (data) => {
    let resultArr = [];
    data.map((arr) => {
      let findData = originalData.find(item => item.id === arr);
      let obj = {
        id: findData?.id,
        name: findData?.name,
        startDate: findData?.startDate,
        endDate: findData?.endDate,
        sequence: findData?.sequence,
        isActive: findData?.isActive,
        groupType: findData?.groupType
      };

      resultArr.push(obj)
    })
    setActivityGroupIds(resultArr)
  }

  // Whenever a change to any of the form fields happens this method is called to update the state of the activity
  // This is so the values in the form fields updates correctly on change.
  const handleInputChange = (name, value) => {
    let localData = Object.assign({}, activity);
    if (name === 'active') {
      if (value === 'Active') {
        localData[name] = true;
      } else if (value === 'InActive') {
        localData[name] = false;
      } else {
        localData[name] = null;
      }
    } else {
      localData[name] = value;
    }

    setActivity(localData);
  };

  const getIsActive = (group) => {
    if (activity.active !== null) {
      return activity.active
    } else {
      return group.isActive;
    }
  }
  /* Transform the activity group data into the form that is required by the backend */
  const createRequestBody = () => {
    let requestBody = {
      ActivityGroups: activityGroupIds.map(group => {
        return {
          ActivityGroup: {
            Id: group.id,
            Name: group.name,
            StartDate: activity.startDate ? activity.startDate : group.startDate,
            EndDate: activity.endDate ? activity.endDate : group.endDate,
            Sequence: activity.sequence ? activity.sequence : group.sequence,
            IsActive: getIsActive(group),
            GroupType: activity.groupType ? activity.groupType : group.groupType,
            ModifiedBy: loggedInUser.userId
          }
        };
      })
    }
    return requestBody;
  };


  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitConfirmClicked = () => {
    dispatch(
      updateBulkActivity(createRequestBody(), (success) => {
        if (success) {
          setIsEditing(false);
          setIsMassUpdatePopupOpen(false)
          setSubmitEditingAlertOpen(false)
          setSubmitEnabled(true)
          window.location.reload(); // Hack to work around state management bugs not allowing a user to edit the same activity group twice.
        }
      })
    );
  };

  // When submit is clicked, validate the data and if ok call the create action
  const handleSubmitClicked = () => {
    if (validateForm()) {
      setSubmitEditingAlertOpen(true)
    }
  }
  /*
       Generic validation to perform on all field types
      */
  const checkGenericField = (key, required) => {
    return validateField(
      required,
      activity[key],
      formProperties[key].type,
      formProperties[key].label
    );
  };

  // Checking is Number or not
  const isNumeric = (str) => {
    return !isNaN(str - parseFloat(str));
  }

  const getNumber = (num) => {
    if (isNumeric(num) && num > 0) {
      return false
    } else {
      return true
    }
  }

  const validateForm = () => {
    if (!submitEnabled) {
      return;
    }
    //Prevent the user from button mashing
    setSubmitEnabled(false);

    let numErrors = 0;
    let localProperties = formProperties;

    Object.keys(formProperties).forEach((key) => {
      let errorMessage = "";

      if (key === "startDate" && (activity.startDate && !activity.endDate)) {
        errorMessage = checkGenericField(key, formProperties[key].required);
        if (errorMessage === "") {
          let latestDateString = ""
          // Find the earliest end date from selected activity group
         let resultActivity = activityGroupIds.sort((a, b) =>new Date(a.endDate) - new Date(b.endDate));
          
             
          if (activityGroupIds.length > 1) {
            latestDateString = resultActivity[0].endDate;
          } else {
            latestDateString = activityGroupIds[0].endDate
          }
          let pd1 = parseISO(new Date(activity["startDate"]).toISOString());
          let pd2 = parseISO(new Date(latestDateString).toISOString());
          if (compareAsc(pd1, pd2) === 0) {
            errorMessage = `${formProperties[key].label} should be earlier(lesser) than the earliest end date of the selected group`
          }
          else if (compareAsc(pd1, pd2) === 1) {
            errorMessage = `${formProperties[key].label} should be earlier(lesser) than the earliest end date of the selected group`
          }
        }
      } else if (key === "startDate" && (activity.startDate && activity.endDate)) {
        if (errorMessage === "") {
          let isD1Valid = isValid(new Date(activity["startDate"]));
          let isD2Valid = isValid(new Date(activity["endDate"]));
          if (isD1Valid && isD2Valid) {
            errorMessage = compareDates(
              activity["startDate"] && new Date(activity[key]).toISOString(),
              activity["endDate"] && new Date(activity["endDate"]).toISOString(),
              formProperties[key].label,
              formProperties["endDate"].label,
              false
            );
          }
        }
      }
      else if (key === "endDate" && (activity.endDate && !activity.startDate)) {
        errorMessage = checkGenericField(key, formProperties[key].required);
        if (errorMessage === "") {
          let latestDateString = ""
          // Find the latest start date from selected activity group
          let resultActivity = activityGroupIds.sort((a, b) =>new Date(b.startDate) - new Date(a.startDate));
          
             
          if (activityGroupIds.length > 1) {
            latestDateString = resultActivity[0].startDate;
          } else {
            latestDateString = activityGroupIds[0].startDate
          }
          
          let pd1 = parseISO(new Date(latestDateString).toISOString());
          let pd2 = parseISO(new Date(activity["endDate"]).toISOString());

          if (compareAsc(pd1, pd2) === 0) {
            errorMessage = `${formProperties[key].label} should be later(greater) than the latest start date of the selected group`
          }else if(compareAsc(pd1,pd2) ===1){
            errorMessage = `${formProperties[key].label} should be later(greater) than the latest start date of the selected group`
          }
        }
      } else if (key === "endDate" && (activity.startDate && activity.endDate)) {
        if (errorMessage === "") {
          let isD1Valid = isValid(new Date(activity["startDate"]));
          let isD2Valid = isValid(new Date(activity["endDate"]));
          if (isD1Valid && isD2Valid) {
            errorMessage = compareDates(
              activity["startDate"] && new Date(activity["startDate"]).toISOString(),
              activity["endDate"] && new Date(activity["endDate"]).toISOString(),
              formProperties["startDate"].label,
              formProperties[key].label,
              true
            );
          }
        }
      }
      else if (key === "sequence" && activity['sequence']) {
        errorMessage = checkGenericField(key, formProperties[key].required);
        if (errorMessage === "") {
          if (getNumber(activity["sequence"])) {
            errorMessage = `Sequence cannot be ${activity["sequence"]}`
          }
        }
      }

      if (errorMessage.length > 0) {
        numErrors++;
      }

      localProperties[key].errorMessage = errorMessage;
    });

    //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
    setDataChanged(!dataChanged);

    if (numErrors > 0) {
      setSubmitEnabled(true);
      return false;
    } else {
      return true;
    }
  };
  const renderData = () => {
    const headers = createHeaders();
    const cells = createCells(headers);

    const data =
      searchedData.length === 0 ? (
        <PagePlaceholder
          image={getIcon("zip.svg")}
          text="No Activities found."
        />
      ) : (
        <SimpleTable
          handleSelection={handleSelection}
          isSelectable={true}
          onClick={() => { }}
          dataId={"id"}
          cells={cells}
          headers={headers}
          rows={searchedData}
          isEditing={true}
        />
      );

    return data;
  };

  const checkValuesSaved = () => {
    for (let key in activity) {
      if (key === 'active' && activity[key] !== null) {
        return true;
      }
      else if (activity[key]) {
        return true;
      }
    }
    return false;
  }

  /*
    Functionality for when the user clicks the cancel button on the edit or create form
  */
  const handleCancelEditButtonClicked = () => {
    if (checkValuesSaved()) {
      setCancelEditingAlertOpen(true);
    } else {
      setIsEditing(false);
      setIsMassUpdatePopupOpen(false);
    }
  };

  /*
  Display the cancel editing alert dialog
  */
  const cancelEditingAlert = () => {
    return (
      <AlertDialog
        title={`Are you sure you want to stop editing?`}
        description={"This is permanent, and can't be undone."}
        options={[
          {
            label: "No",
            action: () => setCancelEditingAlertOpen(false),
          },
          {
            label: "Yes",
            action: () => {
              setIsEditing(false);
              setActivity({})
              setCancelEditingAlertOpen(false);
              setIsMassUpdatePopupOpen(false);
            },
          },
        ]}
        isOpen={cancelEditingAlertOpen}
      />
    );
  };

  const onEditClick = () => {
    setIsMassUpdatePopupOpen(true)
    let model = ActivityMassModel;

    Object.keys(model).forEach(key => {
      const value = model[key];
      value.errorMessage = ''
    });
    setFormProperties(model)
    const activityData = {
      endDate: null,
      startDate: null,
      active: null,
      sequence: null,
      groupType: null
    }
    setActivity(activityData)
  }

  /*
    Display the submit editing alert dialog
  */
  const submitEditingAlert = () => {
    return (
      <Modal
        title=""
        open={submitEditingAlertOpen}
        fullWidth={false}
        fixedHeight={false}
        actions={
          <Box>
            <Button
              color="secondary"
              // disabled={CSVInvalid}
              onClick={handleSubmitConfirmClicked}>
              {/* Absorb the onClick */}
              Submit
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setSubmitEnabled(true)
                setSubmitEditingAlertOpen(false)
              }}
            >
              Cancel
            </Button>
          </Box>
        }
      >
        <Grid className="parent-container" container spacing={3}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="subtitle1" gutterBottom>
                <Tooltip title="Error">
                  <IconButton style={{ color: '#c31d1d' }}>
                    <ErrorIcon />
                  </IconButton>
                </Tooltip>Confirm the changes?
              </Typography>
            </div>
            <Typography style={{ fontSize: '0.7rem' }} variant="subtitle1" gutterBottom>
              You're about to save the changes to the list. Please review your changes carefully before proceeding, as changes cannot be reverted once confirmed.
            </Typography>
          </Grid>
        </Grid>
      </Modal>
    );
  };

  return (
    <div>
      <HeaderBlock
        title={"Activity Groups"}
        right={
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
              <Search
                searchField={"name"}
                returnSearchMatches={(data) => {
                  setSearchedData(data);
                }}
                data={originalData}
                searchTitle="Search Activity Groups"
              />
            </Grid>
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  disabled={!activityGroupIds.length}
                  variant="outlined"
                  color="secondary"
                  onClick={onEditClick}
                >
                  Edit
                </Button>
              </ButtonGroup>
            </Grid>
            <ExportExcel csvData={searchedData} fileName="activity-groups" />
            <Grid item>
              <ButtonGroup classes={{ root: styles.buttonGroup }}>
                <Button
                  onClick={() => {
                    props.history.push("/activity-groups/new");
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  New Activity Group
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        }
      />
      {cancelEditingAlert()}
      {submitEditingAlert()}
      <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
      <ActivityModel isMassUpdatePopupOpen={isMassUpdatePopupOpen} formProperties={formProperties} activity={activity} handleInputChange={handleInputChange}
        handleCancelEditButtonClicked={handleCancelEditButtonClicked} handleSubmitClicked={handleSubmitClicked} />
    </div >
  );
};

const hoc = withRouter(ActivityGroupsListPage);

// EXPORT COMPONENT
export { hoc as ActivityGroupsListPage };
