// React
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

// UI and Styling
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

// Actions
// import { getAllTargetedContent } from "../../../state/actions/TargetedContentActions";
import { GetCorerangeBySite } from "../../state/actions/CoreRangeActions";
import { GetCoreRangeAsCSV } from "../../state/actions/CoreRangeActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Other
import { getIcon } from "../../icon/icon";
import MUIDataTable, { FilterType } from "mui-datatables";
import PageContainer from "../../components/page-container/PageContainer";
import Add from "@material-ui/icons/Add";
import { AddCircleRounded } from "@material-ui/icons";
import { format } from "date-fns";
import { Autocomplete } from "@material-ui/lab";

// Component entry point
const CoreRangeList = (props) => {
  const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
  const dispatch = useDispatch();
  const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);

  const CoreRangeList = useSelector(
    (state) => state.CoreRangeReducer.CoreRangeList
  );
  const coreRangeUserList = useSelector(
    (state) => state.UserReducer.usersInRole
  );

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(true);

    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      dispatch(
        getLoggedInUserByEmail(() => {
          setLoggedInUserCallMade(true);
        })
      );
    } else {
      setLoggedInUserCallMade(true);
    }
  }, []);

  useEffect(() => {
    if (loggedInUserCallMade) {
      setLoading(true);

      dispatch(
        GetCorerangeBySite(loggedInUser.territory === "au" ? 1 : 2, () => {
          setLoading(false);
        })
      );
    }
  }, [loggedInUserCallMade]);

  const handleDownloadCSVRequest = () => {
    dispatch(
      GetCoreRangeAsCSV(loggedInUser.territory === "au" ? 1 : 2, () => {
        //upstream function creates a file download
      })
    );
  };

  const columns = [
    {
      name: "Site",
      label: "Site",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value === 2 ? "NZ" : "AU"}</Typography>;
        },
      },
    },
    {
      name: "Description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "Owner",
      label: "Owner",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "IsActive",
      label: "Active",
      options: {
        filter: true,
        filterType: "dropdown",
        sort: false,
        customFilterListOptions: {
          render: (value) => {
            return value;
          },
        },
        filterOptions: {
          names: ["Active", "Inactive"],
          logic(active, filterVal) {
            const show =
              (filterVal.indexOf("Active") >= 0 &&
                (active === true || active === "true")) ||
              (filterVal.indexOf("Inactive") >= 0 &&
                (active === false || active === "false"));
            return !show;
          },
        },
        customBodyRender: (value) => {
          return <Typography>{value === true ? "Yes" : "No"}</Typography>;
        },
      },
    },
    {
      name: "CreatedBy",
      label: "Created By",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography>{format(new Date(value), "dd/MM/yyyy")}</Typography>
        ),
      },
    },
    {
      name: "ModifiedDate",
      label: "Last Modified",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography>
            {value ? format(new Date(value), "dd/MM/yyyy") : ""}
          </Typography>
        ),
      },
    },
    {
      name: "IsDraft",
      label: "Draft",
      options: {
        filter: true,
        filterType: "dropdown",
        sort: true,
        customFilterListOptions: {
          render: (value) => {
            return value;
          },
        },
        filterOptions: {
          names: ["Draft", "Ready"],
          logic(ready, filterVal) {
            const show =
              (filterVal.indexOf("Draft") >= 0 &&
                (ready === true || ready === "true")) ||
              (filterVal.indexOf("Ready") >= 0 &&
                (ready === false || ready === "false"));
            return !show;
          },
        },
        customBodyRender: (value) => {
          return <Typography>{value === true ? "Yes" : "No"}</Typography>;
        },
      },
    },
    {
      name: "ReadyToPublish",
      label: "Ready To Publish",
      options: {
        filter: true,
        filterType: "dropdown",
        sort: true,
        customFilterListOptions: {
          render: (value) => {
            return value;
          },
        },
        filterOptions: {
          names: ["Ready to Publish", "Not Ready to Publish"],
          logic(ready, filterVal) {
            const show =
              (filterVal.indexOf("Ready to Publish") >= 0 &&
                (ready === true || ready === "true")) ||
              (filterVal.indexOf("Not Ready to Publish") >= 0 &&
                (ready === false || ready === "false"));
            return !show;
          },
        },
        customBodyRender: (value) => {
          return <Typography>{value === true ? "Yes" : "No"}</Typography>;
        },
      },
    },
    {
      name: "PublishedDate",
      label: "Published Date",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography>
            {value ? format(new Date(value), "dd/MM/yyyy") : ""}
          </Typography>
        ),
      },
    },
  ];

  // const customToolbar = () => {
  //     return {
  //         component: <IconButton onClick={(event) => { debugger; }}><Add></Add></IconButton>,
  //         iconOrder: {
  //             search: 1,
  //             download: 3,
  //             print: 4,
  //             customComponent: 2
  //         }
  //     }
  // }

  const options = {
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    download: false,
    filterType: "textField",
    onCellClick: (a, b) => {},
    onRowClick: (a, b) => {
      //Retrieve the original object ID from the data list.
      var CoreRangeId = CoreRangeList[b.dataIndex].CoreRangeId;
      props.history.push(`/core-range/${CoreRangeId}`);
    },
    pagination: false,
    print: false,
    searchPlaceholder: "Search By Any Field",
    selectableRows: "none",
  };

  options.customToolbar = () => {
    return (
      <Fragment>
        <Tooltip title="Download CSV">
          <IconButton onClick={() => handleDownloadCSVRequest()}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={(event) => {
            props.history.push("/core-range/new");
          }}
        >
          <AddCircleRounded />
        </IconButton>
      </Fragment>
    );
  };

  return (
    <PageContainer>
      <MUIDataTable
        stickyHeader
        title={"Core Range Management"}
        data={CoreRangeList}
        columns={columns}
        options={options}
      />
    </PageContainer>
  );
};

const CustomToolbar = () => {
  return (
    <>
      <p>I'm a toolbar</p>
    </>
  );
};

const hoc = withRouter(CoreRangeList);

// EXPORT COMPONENT
export { hoc as CoreRangeList };
